/* tslint:disable */
/* eslint-disable */
/**
 * openapi
 * bales cloud
 *
 * The version of the OpenAPI document: 1.0
 * Contact: test@example.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AllShowSfReportImportTasks200Response } from '../models';
/**
 * SfReportImportTaskApi - axios parameter creator
 * @export
 */
export const SfReportImportTaskApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * sfのレポート履歴API
         * @summary sfのレポート履歴
         * @param {number} [currentPage] リードリストでの現在のページネーション
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allShowSfReportImportTasks: async (currentPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/salesforce/all_show_sf_report_import_tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)

            if (currentPage !== undefined) {
                localVarQueryParameter['currentPage'] = currentPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SfReportImportTaskApi - functional programming interface
 * @export
 */
export const SfReportImportTaskApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SfReportImportTaskApiAxiosParamCreator(configuration)
    return {
        /**
         * sfのレポート履歴API
         * @summary sfのレポート履歴
         * @param {number} [currentPage] リードリストでの現在のページネーション
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allShowSfReportImportTasks(currentPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllShowSfReportImportTasks200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allShowSfReportImportTasks(currentPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SfReportImportTaskApi - factory interface
 * @export
 */
export const SfReportImportTaskApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SfReportImportTaskApiFp(configuration)
    return {
        /**
         * sfのレポート履歴API
         * @summary sfのレポート履歴
         * @param {number} [currentPage] リードリストでの現在のページネーション
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allShowSfReportImportTasks(currentPage?: number, options?: any): AxiosPromise<AllShowSfReportImportTasks200Response> {
            return localVarFp.allShowSfReportImportTasks(currentPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SfReportImportTaskApi - interface
 * @export
 * @interface SfReportImportTaskApi
 */
export interface SfReportImportTaskApiInterface {
    /**
     * sfのレポート履歴API
     * @summary sfのレポート履歴
     * @param {number} [currentPage] リードリストでの現在のページネーション
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SfReportImportTaskApiInterface
     */
    allShowSfReportImportTasks(currentPage?: number, options?: AxiosRequestConfig): AxiosPromise<AllShowSfReportImportTasks200Response>;

}

/**
 * Request parameters for allShowSfReportImportTasks operation in SfReportImportTaskApi.
 * @export
 * @interface SfReportImportTaskApiAllShowSfReportImportTasksRequest
 */
export interface SfReportImportTaskApiAllShowSfReportImportTasksRequest {
    /**
     * リードリストでの現在のページネーション
     * @type {number}
     * @memberof SfReportImportTaskApiAllShowSfReportImportTasks
     */
    readonly currentPage?: number
}

/**
 * SfReportImportTaskApi - object-oriented interface
 * @export
 * @class SfReportImportTaskApi
 * @extends {BaseAPI}
 */
export class SfReportImportTaskApi extends BaseAPI implements SfReportImportTaskApiInterface {
    /**
     * sfのレポート履歴API
     * @summary sfのレポート履歴
     * @param {SfReportImportTaskApiAllShowSfReportImportTasksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SfReportImportTaskApi
     */
    public allShowSfReportImportTasks(requestParameters: SfReportImportTaskApiAllShowSfReportImportTasksRequest = {}, options?: AxiosRequestConfig) {
        return SfReportImportTaskApiFp(this.configuration).allShowSfReportImportTasks(requestParameters.currentPage, options).then((request) => request(this.axios, this.basePath));
    }
}
