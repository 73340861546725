<template>
  <div class="instance-list-container">
    <div class="instance-list-control">
      <BContainer
        class="instance-list-control-inner"
        size="large"
      >
        <BPaginationMetaText
          v-if="paginationMeta"
          :current-page="internalPage"
          :paging-meta="paginationMeta"
        />
        <BPagination
          v-if="paginationMeta"
          v-model="internalPage"
          class="ml-a"
          :length="paginationMeta.totalPages"
          :total-visible="5"
          circle
        />
      </BContainer>
    </div>
    <div class="instance-list ml-400">
      <VWait
        for="fetchSequenceInstancesWait"
      >
        <template #waiting>
          <BPlaceholder
            v-loading="true"
            element-loading-background="transparent"
          />
        </template>
        <InstanceListHeader
          :model-value="sequenceInstancesCheckAll"
          class="instance-list-header"
          @update:model-value="handleSequenceInstancesCheckAllUpdate"
        />
        <div class="instance-items">
          <LeadLink
            v-for="instance in sequenceInstances"
            :key="instance.id"
            :call-target-id="instance.callTarget.id"
            in-new-tab
            tag="div"
          >
            <InstanceListItem
              :model-value="findCheck(instance.id)"
              :sequence-instance="instance"
              @update:model-value="toggleSequenceInstanceCheck"
            />
          </LeadLink>
        </div>
      </VWait>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import { PaginationMeta, SequenceInstanceOverview } from '@/api/openapi';
import { SequenceMasterApiService } from '@/api/user/resources/sequence_master';
import LeadLink from '@/components/organisms/user/general/LeadLink.vue';
import InstanceListHeader from '@/components/organisms/user/sequenceLeads/InstanceListHeader.vue';
import InstanceListItem from '@/components/organisms/user/sequenceLeads/InstanceListItem.vue';
import { TCheck, useCheckAll } from '@/composable/check-all';
import { useWait } from '@/composable/vue-wait';

type TProps = {
  sequenceMasterId: number;
  page?: number;
};
type TEmits = {
  (event: 'after:movePage', afterPage: number): void;
  (event: 'update:selectedSequenceInstanceIds', values: number[]): void;
};

const props = withDefaults(defineProps<TProps>(), {
  page: 1,
});
const emit = defineEmits<TEmits>();

const internalPage = ref(props.page || 1);
watch(() => props.page, (value) => {
  internalPage.value = value || 1;
});
watch(() => internalPage.value, (value) => {
  fetchSequenceInstances();
  emit('after:movePage', value);
});

const sequenceInstances = ref<SequenceInstanceOverview[]>([]);
const paginationMeta = ref<PaginationMeta>(null);

const {
  checkAll: sequenceInstancesCheckAll,
  checkedValues: selectedSequenceInstanceIds,
  initializeChecks,
  findCheck,
  handleCheckAllUpdate: handleSequenceInstancesCheckAllUpdate,
  toggleCheck: toggleSequenceInstanceCheck,
} = useCheckAll();

watch(selectedSequenceInstanceIds, (values) => {
  emit('update:selectedSequenceInstanceIds', values);
});

const makeSequenceInstanceIdChecks = (): TCheck[] =>
  sequenceInstances.value.map((instance) => ({
    value: instance.id,
    checked: false,
  }));

const api = new SequenceMasterApiService();
const {
  doActionWithWait,
} = useWait();

const fetchSequenceInstances = async () => {
  await doActionWithWait('fetchSequenceInstancesWait', async () => {
    const { data: { sequenceInstances: sequenceInstancesRes, meta } } = await api.getSequenceInstanceOverviews({
      request: {
        sequenceMasterId: props.sequenceMasterId,
        page: internalPage.value,
      },
    });
    sequenceInstances.value = sequenceInstancesRes;
    paginationMeta.value = meta;

    initializeChecks(makeSequenceInstanceIdChecks());
  });
};
fetchSequenceInstances();
</script>

<style lang="scss" scoped>
.instance-list-container {
  height: 100%;
  display: flex;
  flex-direction: column;

  .instance-list-control {
    @include m-fixed-height(80px);
    display: flex;
    align-items: center;
    border-bottom: 1px solid $bdcolor-light;

    &-inner {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  .instance-list {
    flex-grow: 1;
    flex-basis: 0;
    overflow-y: auto;

    .instance-list-header {
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: $bgcolor-white;
    }

    .instance-items {
      flex-grow: 1;
      flex-basis: 0;
    }
  }
}
</style>
