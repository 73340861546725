import ApiBase from '@/api/base';

export default {
  getClients () {
    return ApiBase.get(`/admin/clients`);
  },
  getClient (args) {
    const { id } = args;
    return ApiBase.get(`/admin/clients/${id}`, args);
  },
  createClient (args) {
    return ApiBase.post(`/admin/clients`, args);
  },
  updateClient (args) {
    const { id } = args;
    return ApiBase.put(`/admin/clients/${id}`, args);
  },
  deleteClient (args) {
    const { id } = args;
    return ApiBase.delete(`/admin/clients/${id}`, args);
  },
};
