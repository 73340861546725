import ApiService from '@/api/api_service';
import {
  SfLeadSyncSettingsApi,
} from '@/api/openapi';

export class SfLeadSyncSettingService extends ApiService {
  api = new SfLeadSyncSettingsApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    // openapiで作ったやつはtrue
    super(true);
  }

  async getSfLeadSyncSettingStatus() {
    return this.api.getSfLeadSyncSettingStatus();
  }

  async disableSfLeadSyncSetting() {
    return this.api.postSfLeadSyncSettingDisable();
  }

  async enableSfLeadSyncSetting() {
    return this.api.postSfLeadSyncSettingEnable();
  }
}
