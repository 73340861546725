import Waiting from '@/store/waiting';

export default class BulkMailActions {
  constructor(Api, types) {
    const actions = {
      async getBulkMailsAction({ commit }, args) {
        await waiting.check('getBulkMailsAction', async () => {
          try {
            const res = await Api.getBulkMails(args);
            commit(types.SET_BULK_MAILS, res.data.bulkMails);
            commit(types.SET_BULK_MAILS_META, res.data.meta);
          } catch (err) {
            commit(types.CLEAR_BULK_MAILS);
            commit(types.CLEAR_BULK_MAILS_META);
            throw err;
          }
        });
      },
      async getBulkMailAction({ commit }, args) {
        await waiting.check('getBulkMailAction', async () => {
          try {
            const res = await Api.getBulkMail(args);
            commit(types.SET_BULK_MAIL, Object.values(res.data)[0]);
          } catch (err) {
            throw err;
          }
        });
      },
      async getBulkMailCampaignNamesAction({ commit }, args) {
        await waiting.check('getBulkMailCampaignNamesAction', async () => {
          try {
            const res = await Api.getBulkMailCampaignNames(args);
            commit(types.SET_BULK_MAIL_CAMPAIGN_NAMES, Object.values(res.data)[0]);
          } catch (err) {
            commit(types.CLEAR_BULK_MAIL_CAMPAIGN_NAMES);
            throw err;
          }
        });
      },
      createBulkMailAction({ dispatch, commit }, args) {
        return waiting.check('createBulkMailAction', async () => {
          try {
            const createBulkMailResponse = await Api.createBulkMail(args);
            const res = Object.values(createBulkMailResponse.data)[0];
            commit(types.SET_BULK_MAIL, res);
            await dispatch('getBulkMailsAction', args);
            return JSON.parse(JSON.stringify(res));
          } catch (err) {
            throw err;
          }
        });
      },
      async updateBulkMailAction({ dispatch, commit }, args) {
        await waiting.check('updateBulkMailAction', async () => {
          try {
            const updateBulkMailResponse = await Api.updateBulkMail(args);
            commit(types.SET_BULK_MAIL, Object.values(updateBulkMailResponse.data)[0]);
            await dispatch('getBulkMailsAction', args);
          } catch (err) {
            throw err;
          }
        });
      },
      async deleteBulkMailAction({ dispatch, commit }, args) {
        await waiting.check('deleteBulkMailAction', async () => {
          try {
            await Api.deleteBulkMail(args);
            await dispatch('getBulkMailsAction', args);
          } catch (err) {
            throw err;
          }
        });
      },
      clearBulkMailAction({ commit }) {
        commit(types.CLEAR_BULK_MAIL);
      },
    };
    const waiting = new Waiting(actions);
    this.actions = actions;
  }
}
