import Waiting from '@/store/waiting';

export default class ClientSendgridApActions {
  constructor(Api, types) {
    const actions = {
      async getClientSendgridApiKeyAction({ commit }, args) {
        await waiting.check('getClientSendgridApiKeyAction', async () => {
          try {
            const res = await Api.getClientSendgridApiKey(args);
            commit(types.SET_CLIENT_SENDGRID_API_KEY, Object.values(res.data)[0]);
          } catch (err) {
            commit(types.CLEAR_CLIENT_SENDGRID_API_KEY);
            throw err;
          }
        });
      },
      async createClientSendgridApiKeyAction({ dispatch }, args) {
        await waiting.check('createClientSendgridApiKeyAction', async () => {
          try {
            await Api.createClientSendgridApiKey(args);
            await dispatch('getClientSendgridAuthAction', args);
          } catch (err) {
            throw err;
          }
        });
      },
    };
    const waiting = new Waiting(actions);
    this.actions = actions;
  }
}
