import SalesForceApiService from '@/api/salesforce_api_service';

export default {
  searchSfAccountByName(name) {
    return new SalesForceApiService().get(`/user/salesforce/sf_accounts`, { params: { name } });
  },
  getSfAccount(args) {
    const { id } = args;
    return new SalesForceApiService().get(`/user/salesforce/sf_accounts/${id}`);
  },
};
