import { computed } from 'vue';
import { useStore } from 'vuex';
import type { FreeTag } from '@/api/openapi';
import type { TCandidates } from '@/components/organisms/user/leadList/filter/FilterItemCheckbox.vue';
import { useWait } from '@/composable/vue-wait';
import { key } from '@/store';

const useVuexFreeTags = () => {
  const store = useStore(key);
  const { doActionWithWait } = useWait();

  const freeTags = computed<FreeTag[]>(() => store.getters['user/freeTags'] ?? []);
  const freeTagsCandidates = computed<TCandidates>(() => freeTags.value.map((tag) => ({
    label: tag.text,
    value: tag.id,
  })));
  const fetchAll = () => doActionWithWait(
    'getFreeTagsWait',
    () => store.dispatch('user/getFreeTagsAction'),
  );

  return {
    freeTagsCandidates,
    fetchAll,
  };
};

export {
  useVuexFreeTags,
};
