import Waiting from '@/store/waiting';

export default class SalesforceLeadActions {
  constructor(Api, types) {
    const actions = {
      async requestSfLeadDataBulkCheckTaskAction({ commit }, args = {}) {
        return await waiting.check('requestSfLeadDataBulkCheckTaskAction', async () => {
          try {
            const result = await Api.requestSfLeadDataBulkCheckTask(args);
            return result;
          } catch (err) {
            throw err;
          }
        });
      },
      async getSfBulkCheckLeadDataTasksAction({ commit, state }, args = {}) {
        if (!state.availableFeatures.feature?.salesforce_link) return;

        return await waiting.check('getSfBulkCheckLeadDataTasksAction', async () => {
          try {
            const res = await Api.getSfBulkCheckLeadDataTasks(args);
            commit(types.SET_SF_LEAD_DATA_CHECK_TASKS, Object.values(res.data)[0]);
          } catch (err) {
            throw err;
          }
        });
      },
      async confirmSfBulkCheckLeadDataTaskAction({ dispatch }, args = {}) {
        return await waiting.check('confirmSfBulkCheckLeadDataTaskAction', async () => {
          try {
            await Api.confirmSfBulkCheckLeadDataTask(args);
            await dispatch('getSfBulkCheckLeadDataTasksAction', args);
          } catch (err) {
            throw err;
          }
        });
      },
      getSfStatusesAction({ commit }) {
        return waiting.check('getSfStatusesAction', async () => {
          try {
            const { data } = await Api.getSfStatuses();
            commit(types.SET_SF_STATUSES, Object.values(data)[0]);
          } catch (err) {
            commit(types.CLEAR_SF_STATUSES);
            throw err;
          }
        });
      },
    };
    const waiting = new Waiting(actions);
    this.actions = actions;
  }
}

