<template>
  <BLayout
    class="loader"
    justify-center
  >
    <div v-loading="true" />
  </BLayout>
</template>

<script lang="ts" setup>
import { onMounted, computed } from 'vue';
import { useQueryParameter } from '@/composable/query-parameter';

const { param: returnTo } = useQueryParameter<string>('return_to');

const domain = computed(() => {
  let domain = '';
  try {
    // NOTE: return_to パラメータからドメインを取り出す
    if (returnTo.value) {
      domain = new URL(returnTo.value).origin;
    }
  } catch (error) {
    console.error(error);
  }
  return domain;
});

onMounted(async () => {
  handleRedirect();
});

const isBc = (domain: string) => {
  if (process.env.NODE_ENV === 'production') {
    return domain.match('balescloud.jp');
  } else {
    // ステージング環境と開発環境のドメインをチェック
    return domain.match('balescloud.jp') || domain.match('lvh.me');
  }
};

// NOTE: ここは Sisense から SSO 周りのリダイレクトを受けるための endpoint
// Sisense の設定では slug 付きのドメインが指定できないので
// ここ(slug=support)で受けて、リクエストパラメータの slug 付きのドメインへリダイレクトする
const handleRedirect = () => {
  let redirectTo = '';

  // ユースケースは2つ
  if (isBc(domain.value)) {
    // 1. BC内から SisenseJS で呼ばれたケース -> リダイレクト先で firebase 認証情報を取得して SSO する
    redirectTo = `${domain.value}/app/sisense/sso`;
  } else {
    // 2. BC外から呼ばれたケース(BC内iframe含む) -> Sisense 側のログインURLへリダイレクト
    redirectTo = `${import.meta.env.VITE_SISENSE_URL}/app/account/login`;
  }
  if (returnTo.value) {
    redirectTo += `?return_to=${returnTo.value}`;
  }

  location.href = redirectTo;
};
</script>

<style lang="scss" scoped>
.loader {
  position: fixed;
  width: 100%;
  height: 100%;
}
</style>
