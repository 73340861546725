<template>
  <BContent>
    <template #navbar>
      <BLayout
        align-start
        justify-start
      >
        <span class="fs-400 fw-bold">{{ $t('sequence.title') }}</span>
      </BLayout>
    </template>
    <SequenceApplyModal
      :modal-name="SEQUENCE_APPLY_MODAL_NAME"
      :sequence-master-id="sequenceMasterId"
      @after-apply="handleAfterSequenceApply"
      @close="handleCloseApplySequenceModal"
    />
    <template #waiting>
      <BPlaceholder
        v-loading="true"
        element-loading-background="transparent"
      />
    </template>
    <SequenceMaster
      :sequence-master-id="sequenceMasterId"
      :editable="false"
    />
    <BTabs
      v-model="activeName"
      class="tabs"
      :class="{'with-footer': selectedSequenceInstanceIds.length > 0}"
      :label-items="tabLabelItems"
      :before-leave="handleTabBeforeLeave"
      @click:tab="handleTabClick"
    >
      <template #lead>
        <SequenceLeads
          v-if="renderLeads"
          :sequence-master-id="sequenceMasterId"
          :instance-list-page="parseInt(leadListPage, 10)"
          @update:selected-sequence-instance-ids="handleUpdateSelectedSequenceInstanceIds"
          @after:move-instance-list-page="handleAfterMoveLeadListPageUpdate"
          @click:apply-to-lead-button="handleClickApplySequence"
        />
      </template>
    </BTabs>
    <template #footer>
      <BulkActionsFooter
        :selected-sequence-instance-ids="selectedSequenceInstanceIds"
        @after-bulk-action="handleAfterBulkAction"
      />
    </template>
  </BContent>
</template>

<script lang="ts" setup>
import { inject, nextTick, ref } from 'vue';
import { useRoute } from 'vue-router';
import SequenceApplyModal from '@/components/organisms/user/sequence/modal/SequenceApplyModal.vue';
import BulkActionsFooter from '@/components/organisms/user/sequenceLeads/BulkActionsFooter.vue';
import SequenceMaster from '@/components/organisms/user/sequenceLeads/Sequence.vue';
import SequenceLeads from '@/components/organisms/user/sequenceLeads/SequenceLeads.vue';
import { useQueryParameter } from '@/composable/query-parameter';
import { useUrlLinkBtabsHandlers } from '@/composable/url-link-b-tabs';
import { i18nGlobal as i18n } from '@/i18n';
import { KEY_OF_MODAL } from '@/injection-keys';

const SEQUENCE_APPLY_MODAL_NAME = 'sequenceApplyModal';

const modal = inject(KEY_OF_MODAL);
const route = useRoute();
const sequenceMasterId = parseInt(route.params.id as string);

const { param: leadListPage } = useQueryParameter<string>('leadListPage');

const handleAfterMoveLeadListPageUpdate = (afterPage: number) => {
  leadListPage.value = `${afterPage || ''}`;
};

const tabLabelItems = [
  { label: i18n.t('sequence.step.title'), slotName: 'step', route: { name: 'Sequence', params: { id: sequenceMasterId } } },
  { label: i18n.t('sequence.lead'), slotName: 'lead' },
];

const { handleTabClick, handleTabBeforeLeave } = useUrlLinkBtabsHandlers(tabLabelItems);

const activeName = ref('lead');
const selectedSequenceInstanceIds = ref<number[]>([]);

const renderLeads = ref(true);
const reRenderLeads = async () => {
  renderLeads.value = false;
  await nextTick(() => renderLeads.value = true);
};

const handleUpdateSelectedSequenceInstanceIds = (sequenceInstanceIds: number[]) => {
  selectedSequenceInstanceIds.value = sequenceInstanceIds;
};
const handleAfterBulkAction = () => {
  reRenderLeads();
};
const handleAfterSequenceApply = () => {
  reRenderLeads();
};
const handleClickApplySequence = () => {
  modal.show(SEQUENCE_APPLY_MODAL_NAME);
};
const handleCloseApplySequenceModal = () => {
  modal.hide(SEQUENCE_APPLY_MODAL_NAME);
};
</script>

<style lang="scss" scoped>
.tabs {
  // NOTE: シーケンスマスタ分引く
  height: calc(100% - 50px);
  &.with-footer {
    // NOTE: フッター分引く
    height: calc(100% - 50px - 72px);
  }
}

:deep(.el-tabs__header) {
  margin-bottom: 0;

  .el-tabs__nav-scroll {
    padding: 0 $basespace-600;
  }
}

:deep(.el-tabs__content) {
  height: calc(100% - 40px);

  .el-tab-pane {
    height: 100%;
  }
}
</style>
