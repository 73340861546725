<template>
  <div class="b-editable-content">
    <template v-if="internalEditing">
      <div class="input-box">
        <BInputBox
          v-if="isInput"
          v-bind="inputBoxAttributes"
          v-model="internalValue"
          :data-test="dataTest"
          :input-component="inputComponent"
          :settle-type="settleType"
          v-on="boxFunctions"
        />
      </div>
      <div class="textarea-box">
        <BTextareaBox
          v-if="isTextarea"
          v-model="internalValue"
          :placeholder="placeholder"
          :loading="loading"
          :data-test="dataTest"
          :autosize="autosize"
          v-on="boxFunctions"
        />
      </div>
    </template>
    <template v-else>
      <div
        v-if="isInput || isTextarea"
        class="item"
        :class="displayClass"
        :data-test="dataTest"
        @click="handleClickEdit"
      >
        <template v-if="isDisplayValueOrPlaceHolder">
          <span
            v-if="shouldAlternate"
            class="placeholder"
          >{{ placeholder }}</span>
          <span v-else>{{ value }}</span>
        </template>
        <template v-else>
          {{ displayValue }}
        </template>
      </div>
    </template>
  </div>
</template>

<script>
// TODO: textareaの場合settleTypeに対応していないので、必要になったらBInputBoxと同じように実装してください
import { altText, shouldAlternate } from '@/utils/alt';

export default {
  props: {
    required: Boolean,
    hyphen: Boolean,
    loading: Boolean,
    editing: Boolean,
    placeholder: String,
    value: {
      type: [String, Number],
      default: null,
    },
    type: {
      type: String,
      default: 'input',
    },
    rules: {
      type: Array,
      default () {
        return [];
      },
    },
    maxLength: {
      type: Number,
      default: 255,
    },
    dataTest: String,
    inputComponent: {
      type: String,
      default: 'BInput',
    },
    allowFloat: {
      type: Boolean,
      default: false,
    },
    uneditable: {
      type: Boolean,
      default: false,
    },
    settleType: {
      type: String, // 'check' or 'blur'
      default: 'check',
    },
    isDisplayValueOrPlaceHolder: {
      type: Boolean,
      default: false,
    },
    autosize: {
      type: [Boolean, Object],
      default: null, // デフォルトは未指定（BTextareaBoxに任せる）
    },
  },
  emits: [
    'update:editing',
    'check',
    'cancel',
  ],
  data () {
    return {
      newValue: this.value,
      internalEditing: false,
    };
  },
  computed: {
    internalValue: {
      get () {
        return this.newValue;
      },
      set (newVal) {
        this.newValue = newVal;
      },
    },
    inputBoxAttributes () {
      return {
        'horiz': true,
        'placeholder': this.placeholder,
        'required': this.required,
        'rules': this.rules,
        'loading': this.loading,
        'max-length': this.maxLength,
        'allow-float': this.allowFloat,
      };
    },
    boxFunctions () {
      return {
        check: this.handleClickCheck,
        close: this.handleClickClose,
      };
    },
    isInput () {
      return this.type === 'input';
    },
    isTextarea () {
      return this.type === 'textarea';
    },
    displayClass() {
      if (this.isInput) {
        return {
          input: true,
          uneditable: this.uneditable,
        };
      } else if (this.isTextarea) {
        return {
          textarea: true,
        };
      } else {
        return null;
      }
    },
    displayValue () {
      return this.hyphen ? altText(this.value) : this.value;
    },
    shouldAlternate() {
      return shouldAlternate(this.value);
    },
  },
  watch: {
    loading (newVal) {
      // loadingを渡さないと、save時にフォームが終了しない
      if (!newVal) { this.internalEditing = false; }
    },
    editing (newVal) {
      this.internalEditing = newVal;
    },
    internalEditing (newVal) {
      this.$emit('update:editing', newVal);
    },
  },
  created () {
    this.internalEditing = this.editing;
  },
  methods: {
    handleClickEdit () {
      if (this.uneditable) return;
      this.newValue = this.value;
      this.internalEditing = true;
    },
    handleClickCheck () {
      this.$emit('check', this.newValue);
    },
    handleClickClose () {
      this.$emit('cancel');
      this.newValue = this.value;
      this.internalEditing = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  .b-editable-content {
    width: 100%;
  }

  .item {
    width: 100%;
    border: 1px solid transparent;
    padding: $basespace-50 $basespace-100;
    margin-left: -$basespace-100;

    &:hover {
      border-color: $bdcolor-light;
      cursor: pointer;
    }

    &.uneditable:hover {
      border-color: transparent;
      cursor: auto;
    }

    &.input:hover {
      border-radius: 4px;
    }

    &.textarea {
      white-space: pre-wrap;
      word-wrap: break-word;

      &:hover {
        border-radius: 6px;
      }
    }
  }

  .textarea-box, .input-box {
    margin-left: -$basespace-100;
  }

  .placeholder {
    color: $concrete-dark;
  }
</style>
