<template>
  <BColorPreview
    :color-code="colorCode"
    :size="size"
    @click="handleClick"
  />
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { PriorityPriorityEnum } from '@/api/openapi';
import { TSize } from '../../types';
import priorityColors from './priority-colors';

type TProps = {
  size?: TSize;
  priority: PriorityPriorityEnum;
};

const props = defineProps<TProps>();
const emit = defineEmits(['click']);

const colorCode = computed(() => priorityColors.find(c => c.value === props.priority)?.colorCode);
const handleClick = () => {
  emit('click');
};
</script>
