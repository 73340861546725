<template>
  <div
    v-loading="$wait.is('confirmLeadDeleteTaskWait')"
    class="lead-delete-status"
  >
    <ImportStatus
      v-for="task in leadDeleteTasks"
      :key="task.id"
      :target-task="task"
      :progress-message="$t('callTarget.destroyAll.status.progress')"
      :error-message="$t('callTarget.destroyAll.status.error')"
      :can-show-error-modal="false"
      :success-message="$t('callTarget.destroyAll.status.success')"
      @confirm="() => confirmTask(task.id)"
      @success="$emit('success')"
    />
  </div>
</template>

<script>
import { mapWaitingActions } from 'vue-wait';
import { mapGetters } from 'vuex';
import ImportStatus from '@/components/organisms/user/leadImport/ImportStatus.vue';

export default {
  components: {
    ImportStatus,
  },
  emits: [
    'success',
  ],
  computed: {
    ...mapGetters('user', ['leadDeleteTasks']),
  },
  created() {
    this.getLeadDeleteTasksAction();
    this.timeObj = this.$setInterval(() => {
      this.getLeadDeleteTasksAction();
    }, 30000);
  },
  beforeUnmount() {
    this.$clearInterval(this.timeObj);
  },
  methods: {
    ...mapWaitingActions('user', {
      getLeadDeleteTasksAction: 'getLeadDeleteTasksWait',
      confirmLeadDeleteTaskAction: 'confirmLeadDeleteTaskWait',
    }),
    confirmTask(taskId) {
      this.confirmLeadDeleteTaskAction({ leadDeleteTaskId: taskId });
    },
  },
};
</script>
