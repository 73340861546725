<template>
  <div class="table-area">
    <div v-if="isLoading">
      <BPlaceholder
        v-loading="true"
        height="70vh"
        element-loading-background="transparent"
      />
    </div>
    <div v-else>
      <ElTable
        :data="adjustedAdmins"
        height="65vh"
        :default-sort="{prop: 'id', order: 'ascending'}"
      >
        <ElTableColumn
          v-for="(header, index) in headers.slice(0,3)"
          :key="index"
          :prop="header.value"
          :label="header.text"
          sortable
          :width="columnsWidth[index]"
        />
        <ElTableColumn :label="headers[3].text">
          <template #default="adminProps1">
            <span>{{ displayRole(adminProps1.row.role) }}</span>
          </template>
        </ElTableColumn>
        <ElTableColumn :label="headers[4].text">
          <template #default="adminProps2">
            <span>{{ $t(`callStatus.enum.${adminProps2.row.status}`) }}</span>
          </template>
        </ElTableColumn>
        <ElTableColumn :label="headers[5].text">
          <template #default="adminProps3">
            <span>{{ $t(`accountStatus.enum.${adminProps3.row.activated}`) }}</span>
          </template>
        </ElTableColumn>
        <ElTableColumn
          :label="headers[6].text"
          width="60"
          header-align="center"
        >
          <template #default="adminProps4">
            <BBtn
              fab
              text
              @click="() => openAdminEditDialog(adminProps4.row)"
            >
              <BIcon>edit</BIcon>
            </BBtn>
          </template>
        </ElTableColumn>
      </ElTable>
      <BLayout class="pt-300">
        <BFlex
          :span="4"
          align-self-center
        >
          <BLayout justify-center>
            <span>{{ pageStart }} - {{ pageEnd }} / {{ totalCount }}</span>
          </BLayout>
        </BFlex>
        <BFlex :span="16">
          <BLayout justify-center>
            <BPagination
              v-model="currentPage"
              :length="allPageCount"
              :total-visible="9"
            />
          </BLayout>
        </BFlex>
        <BFlex :span="4">
          <BSelect
            v-model="pageSize"
            class="mx-600"
            :items="pageSizeChoice"
          />
        </BFlex>
      </BLayout>
    </div>
    <FormDialog
      :dialog="adminEditDialog"
      width="500"
    >
      <template #form>
        <AdminForm
          v-bind="adminProps"
          ref="adminEditForm"
          :loading="$wait.is('updateAdminWait')"
          :is-mine="false"
          @save="updateAdmin"
          @cancel="closeAdminEditDialog"
        >
          <template #title>
            <span class="headline">{{ $t('adminManagement.admin') + $t('general.edit.text') }}</span>
          </template>
        </AdminForm>
      </template>
    </FormDialog>
  </div>
</template>

<script>
import { mapWaitingActions } from 'vue-wait';
import { mapGetters } from 'vuex';
import errorHandler from '@/mixins/error_handler';

export default {
  name: 'AdminsList',
  mixins: [errorHandler],
  data () {
    return {
      adminEditDialog: false,
      selectedAdmin: {
        id: 0,
        name: '',
        email: '',
      },
      currentPage: 1,
      pageSize: 20,
      pageSizeChoice: [
        { text: '20', value: 20 },
        { text: '35', value: 35 },
        { text: '50', value: 50 },
      ],
      columnsWidth: [60, 180, 300],
    };
  },
  computed: {
    ...mapGetters('admin', [
      'admins',
    ]),
    isLoading () {
      return this.$wait.is(['getAdminsWait', 'createAdminWait', 'updateAdminWait', 'deleteAdminWait']);
    },
    adjustedAdmins () {
      return this.admins.slice(this.pageStart - 1, this.pageEnd);
    },
    pageStart () {
      return this.pageSize * (this.currentPage - 1) + 1;
    },
    pageEnd () {
      const defaultEnd = this.pageSize * this.currentPage;
      return Math.min(defaultEnd, this.totalCount);
    },
    totalCount () {
      return this.admins.length;
    },
    allPageCount () {
      return Math.ceil(this.totalCount / this.pageSize);
    },
    headers () {
      return Object.entries(this.$tm('adminManagement.headers')).map(([key, value]) => ({
        text: this.$rt(value),
        value: key,
      }));
    },
    adminProps () {
      return this.selectedAdmin;
    },
  },
  methods: {
    ...mapWaitingActions('admin', {
      updateAdminAction: 'updateAdminWait',
      deleteAdminAction: 'deleteAdminWait',
    }),
    openAdminEditDialog (admin) {
      this.selectedAdmin = admin;
      this.adminEditDialog = true;
    },
    closeAdminEditDialog () {
      this.selectedAdmin = {};
      this.adminEditDialog = false;
      this.$refs.adminEditForm.reset();
    },
    async updateAdmin (admin) {
      await this.updateAdminAction({
        id: this.selectedAdmin.id,
        body: { admin },
        errorHandlers: { 422: this.defaultHandler },
      });
      this.selectedAdmin = {};
      this.adminEditDialog = false;
      this.$refs.adminEditForm.reset();
    },
    async deleteAdmin (id) {
      const check = await this.$bitterAlert.show()({
        title: this.$t('adminManagement.admin'),
        text: this.$t('general.confirm.of', { action: this.$t('general.delete.text') }),
      });
      if (!check) { return; }
      this.deleteAdminAction({ id });
    },
    displayRole(role) {
      return this.$t(`adminManagement.roles.${role.replace('admin_', '')}`);
    },
  },
};
</script>

<style lang="scss" scoped>
  .table-area {
    height: 70vh;
  }
</style>
