<template>
  <VWait
    for="fetchDuplicateSequenceMaster"
  >
    <div class="sequence-notice">
      {{ $t('sequence.leadDetail.applyCaution') }}
    </div>
    <EditableSequence
      :sequence-body="sequenceBody"
      :owner-name="ownerName"
      :editable="false"
      for-sequence-instance
    />
  </VWait>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { SequenceBody } from '@/api/openapi';
import EditableSequence from '@/components/organisms/user/general/sequence/EditableSequence.vue';
import { useSequenceMaster } from '@/composable/user/sequence/sequence-master';

type TProps = {
  sequenceMasterId: number;
  ownerName: string | undefined;
};

const props = defineProps<TProps>();

const {
  sequenceMaster,
  fetchSequenceMaster,
} = useSequenceMaster(props.sequenceMasterId);

fetchSequenceMaster('fetchSequenceMaster');

const sequenceBody = computed<SequenceBody>(() => ({
  name: sequenceMaster.value?.name ?? '',
  ownerId: sequenceMaster.value?.owner?.id ?? null,
}));
</script>

<style lang="scss" scoped>
.sequence-notice {
  margin: 20px $basespace-600 10px;
  color: $textcolor-light;
}
</style>
