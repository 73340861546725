<template>
  <div>
    <div class="theader">
      <div class="table-row theader-row">
        <BSortText
          v-for="cell in headers"
          :key="cell.key"
          class="table-cell header-cell"
          :class="cell.class"
          :item-text="cell.name"
          is-display-only
        />
        <div class="table-cell menu" />
      </div>
    </div>
    <div class="tbody">
      <div
        v-for="trigger in triggers"
        :key="trigger.id"
        class="table-row tbody-row"
        @click="() => handleRowClick(trigger.id)"
      >
        <div
          v-for="column in headers"
          :key="column.key"
          class="table-cell"
          :class="column.class"
        >
          <template v-if="column.key === 'name'">
            {{ trigger.name }}
          </template>
          <template v-if="column.key === 'createdAt'">
            {{ formatShorterDateTime(trigger.createdAt) }}
          </template>
          <template v-if="column.key === 'updatedAt'">
            {{ formatShorterDateTime(trigger.updatedAt) }}
          </template>
          <template v-else-if="column.key === 'createdUser'">
            <BUserTooltip
              v-if="trigger.createdUser"
              :image-url="trigger.createdUser.avatarUrl"
              :name="trigger.createdUser.name"
              :user-id="trigger.createdUser.id"
              :size="40"
            />
          </template>
          <template v-else-if="column.key === 'owner'">
            <BUserTooltip
              v-if="trigger.owner"
              :image-url="trigger.owner.avatarUrl"
              :name="trigger.owner.name"
              :user-id="trigger.owner.id"
              :size="40"
            />
          </template>
        </div>
        <div
          class="table-cell menu"
          @click.stop
        >
          <BMoreMenu
            z-index100
          >
            <div>
              <BBtn
                flat
                fit
                size="small"
                :disabled="!$can('owner') || trigger?.owner?.id !== currentUser?.id"
                @click="() => handleDeleteButtonClick(trigger.id)"
              >
                {{ $t('general.delete.text') }}
              </BBtn>
            </div>
          </BMoreMenu>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { Trigger } from '@/api/openapi';
import { TriggerApiService } from '@/api/user/resources/trigger';
import { useDeleteConfirm } from '@/composable/message-dialog';
import { useCurrentUser } from '@/composable/user/user/users';
import { useWait } from '@/composable/vue-wait';
import { formatShorterDateTime } from '@/utils/date-time';

type TProps = {
  triggers: Trigger[];
};
type TEmit = {
  'afterDelete': [];
  'click:row': [id: number];
};

defineProps<TProps>();
const emit = defineEmits<TEmit>();

const i18n = useI18n();
const { deleteConfirm } = useDeleteConfirm();
const api = new TriggerApiService();
const { doActionWithWait } = useWait();
const { currentUser } = useCurrentUser();

const headers = [
  { key: 'name', name: i18n.t('trigger.name'), class: ['name-container'] },
  { key: 'createdAt', name: i18n.t('general.createdAt'), class: ['date-time'] },
  { key: 'updatedAt', name: i18n.t('general.updatedAt'), class: ['date-time'] },
  { key: 'createdUser', name: i18n.t('general.createdUser'), class: ['user'] },
  { key: 'owner', name: i18n.t('general.owner'), class: ['user'] },
];

const handleRowClick = (id: number) => emit('click:row', id);
const handleDeleteButtonClick = async (triggerId: number) => {
  const ok = await deleteConfirm();
  if (!ok) return;
  await doActionWithWait('deleteTriggerWait', async () => {
    await api.deleteTrigger({
      request: { triggerId },
    });
  });
  emit('afterDelete');
};
</script>

<style lang="scss" scoped>
.table-row {
  align-items: center;
  padding-right: $basespace-400;
  display: inline-flex;
  min-width: 100%;
}

.table-cell {
  @include m-fixed-width(50px);
  padding-right: $basespace-200;
  padding-left: 10px;
  background-color: white;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  &:first-child {
    padding-left: 30px;
  }

  &.name-container {
    @include m-fixed-width(400px);
  }

  &.date-time {
    @include m-fixed-width(10rem);
  }
  &.user {
    justify-content: center;
    @include m-fixed-width(80px);
    :deep(.b-avatar) {
      justify-content: center;
    }
  }
  &.menu {
    width: 100%;
  }
}

.theader {
  height: 50px;
  position: sticky;
  top: 0;
  z-index: 2;
  cursor: text;
  &-row {
    height: inherit;
    background-color: white;
    border-bottom: 1px solid $bdcolor-base;
    padding-bottom: $basespace-100;
    color: $textcolor-light;
    font-size: $fontsize-100;
  }
}

.tbody-row {
  border-bottom: 1px solid $bdcolor-base;
  padding-top: $basespace-200;
  padding-bottom: $basespace-200;

  cursor: pointer;
  &:hover {
    background-color: $bgcolor-base;

    .table-cell {
      background-color: $bgcolor-base;
    }
  }
}
</style>
