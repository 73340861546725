import ApiBase from '@/api/base';

export default {
  getClient (args) {
    const { id } = args;
    return ApiBase.get(`/user/clients/${id}`, args);
  },
  updateClient (args) {
    const { id } = args;
    return ApiBase.put(`/user/clients/${id}`, args);
  },
};
