/* tslint:disable */
/* eslint-disable */
/**
 * openapi
 * bales cloud
 *
 * The version of the OpenAPI document: 1.0
 * Contact: test@example.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GetSfLeadSyncSettingStatus200Response } from '../models';
/**
 * SfLeadSyncSettingsApi - axios parameter creator
 * @export
 */
export const SfLeadSyncSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Salesforce同期設定状態確認API
         * @summary Salesforce同期設定有状態確認API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSfLeadSyncSettingStatus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/salesforce/sf_lead_sync_setting_status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Salesforce同期設定無効化API
         * @summary Salesforce同期設定有効化API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSfLeadSyncSettingDisable: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/salesforce/sf_lead_sync_setting_disable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Salesforce同期設定有効化API
         * @summary Salesforce同期設定有効化API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSfLeadSyncSettingEnable: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/salesforce/sf_lead_sync_setting_enable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SfLeadSyncSettingsApi - functional programming interface
 * @export
 */
export const SfLeadSyncSettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SfLeadSyncSettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * Salesforce同期設定状態確認API
         * @summary Salesforce同期設定有状態確認API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSfLeadSyncSettingStatus(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSfLeadSyncSettingStatus200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSfLeadSyncSettingStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Salesforce同期設定無効化API
         * @summary Salesforce同期設定有効化API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSfLeadSyncSettingDisable(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSfLeadSyncSettingDisable(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Salesforce同期設定有効化API
         * @summary Salesforce同期設定有効化API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSfLeadSyncSettingEnable(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSfLeadSyncSettingEnable(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SfLeadSyncSettingsApi - factory interface
 * @export
 */
export const SfLeadSyncSettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SfLeadSyncSettingsApiFp(configuration)
    return {
        /**
         * Salesforce同期設定状態確認API
         * @summary Salesforce同期設定有状態確認API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSfLeadSyncSettingStatus(options?: any): AxiosPromise<GetSfLeadSyncSettingStatus200Response> {
            return localVarFp.getSfLeadSyncSettingStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * Salesforce同期設定無効化API
         * @summary Salesforce同期設定有効化API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSfLeadSyncSettingDisable(options?: any): AxiosPromise<boolean> {
            return localVarFp.postSfLeadSyncSettingDisable(options).then((request) => request(axios, basePath));
        },
        /**
         * Salesforce同期設定有効化API
         * @summary Salesforce同期設定有効化API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSfLeadSyncSettingEnable(options?: any): AxiosPromise<boolean> {
            return localVarFp.postSfLeadSyncSettingEnable(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SfLeadSyncSettingsApi - interface
 * @export
 * @interface SfLeadSyncSettingsApi
 */
export interface SfLeadSyncSettingsApiInterface {
    /**
     * Salesforce同期設定状態確認API
     * @summary Salesforce同期設定有状態確認API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SfLeadSyncSettingsApiInterface
     */
    getSfLeadSyncSettingStatus(options?: AxiosRequestConfig): AxiosPromise<GetSfLeadSyncSettingStatus200Response>;

    /**
     * Salesforce同期設定無効化API
     * @summary Salesforce同期設定有効化API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SfLeadSyncSettingsApiInterface
     */
    postSfLeadSyncSettingDisable(options?: AxiosRequestConfig): AxiosPromise<boolean>;

    /**
     * Salesforce同期設定有効化API
     * @summary Salesforce同期設定有効化API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SfLeadSyncSettingsApiInterface
     */
    postSfLeadSyncSettingEnable(options?: AxiosRequestConfig): AxiosPromise<boolean>;

}

/**
 * SfLeadSyncSettingsApi - object-oriented interface
 * @export
 * @class SfLeadSyncSettingsApi
 * @extends {BaseAPI}
 */
export class SfLeadSyncSettingsApi extends BaseAPI implements SfLeadSyncSettingsApiInterface {
    /**
     * Salesforce同期設定状態確認API
     * @summary Salesforce同期設定有状態確認API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SfLeadSyncSettingsApi
     */
    public getSfLeadSyncSettingStatus(options?: AxiosRequestConfig) {
        return SfLeadSyncSettingsApiFp(this.configuration).getSfLeadSyncSettingStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Salesforce同期設定無効化API
     * @summary Salesforce同期設定有効化API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SfLeadSyncSettingsApi
     */
    public postSfLeadSyncSettingDisable(options?: AxiosRequestConfig) {
        return SfLeadSyncSettingsApiFp(this.configuration).postSfLeadSyncSettingDisable(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Salesforce同期設定有効化API
     * @summary Salesforce同期設定有効化API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SfLeadSyncSettingsApi
     */
    public postSfLeadSyncSettingEnable(options?: AxiosRequestConfig) {
        return SfLeadSyncSettingsApiFp(this.configuration).postSfLeadSyncSettingEnable(options).then((request) => request(this.axios, this.basePath));
    }
}
