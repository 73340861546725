<template>
  <div class="sequence-call-action-container">
    <BDrawerMenu
      v-model="internalIsOpen"
      :title="$t('sequence.step.types.callAction')"
      @cancel="handleCancel"
    >
      <template
        v-if="internalSequenceStep"
      >
        <BListItem
          v-if="internalSequenceStep.immediately"
          class="mb-300"
        >
          <template #header>
            <span>{{ $t('sequence.step.dateInterval') }}</span>
          </template>
          <div class="mt-100 immediately">
            {{ $t('general.startImmediately') }}
          </div>
        </BListItem>
        <div
          v-else
          class="time-items"
        >
          <BListItem class="mb-300">
            <template #header>
              <span>{{ $t('sequence.step.dateInterval') }}</span>
            </template>
            <BInput
              v-model="internalSequenceStep.dateInterval"
              v-model:valid="isDateIntervalValid"
              type="number"
              :max="30"
              :min="0"
              validation
              required
              needs-parse-int
            />
          </BListItem>
          <BListItem class="mb-300">
            <template #header>
              <span>{{ $t('sequence.step.reservedTime') }}</span>
            </template>
            <BTimePicker
              v-model="internalSequenceStep.reservedTime"
              value-format="HH:mm"
              format="HH:mm"
              required
            />
          </BListItem>
        </div>
        <BListItem class="mb-300">
          <template #header>
            <span>{{ $t('general.priority.title') }}</span>
          </template>
          <BPrioritySelect
            v-model="internalSequenceStep.priority"
            fit
            required
          />
        </BListItem>
        <BListItem class="mb-300">
          <template #header>
            <span>{{ $t('nextAction.content') }}</span>
          </template>
          <BInput
            v-model="(internalSequenceStep as TPartialFormCallActionSequenceStep).nextActionContent"
            v-model:valid="isNextActionContentValid"
            type="textarea"
            :autosize="{ minRows: 12, maxRows: 12 }"
            :max-length="1000"
            :placeholder="$t('nextAction.content')"
            resize="none"
            validation
            copyable
          />
        </BListItem>
      </template>
      <template
        v-if="internalSequenceStep"
        #footer
      >
        <div
          class="footer"
        >
          <BLayout
            align-center
            justify-center
          >
            <BBtn
              class="mr-300"
              text
              :loading="loading"
              @click="handleCancel"
            >
              {{ $t('general.cancel.text') }}
            </BBtn>
            <BBtn
              :disabled="invalidSequenceStep"
              :loading="loading"
              class="ml-300"
              type="primary"
              @click="handleSaveClick"
            >
              {{ $t('general.save.text') }}
            </BBtn>
          </BLayout>
        </div>
      </template>
    </BDrawerMenu>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useStepModal } from '@/composable/user/sequence/step-form-modal';
import BInput from '@/plugins/biscuet-materials/components/atoms/BInput.vue';
import { TFormSequenceStep, TPartialFormCallActionSequenceStep } from './types';

type TProps = {
  sequenceStep?: TPartialFormCallActionSequenceStep;
  isOpen?: boolean;
  loading?: boolean;
};

type TEmits = {
  'click:save': [sequenceStep: TFormSequenceStep];
  'click:cancel': [];
};

const props = withDefaults(defineProps<TProps>(), {
  sequenceStep: null,
  isOpen: false,
  loading: false,
});

const emit = defineEmits<TEmits>();

const {
  internalIsOpen,
  internalSequenceStep,
} = useStepModal<TFormSequenceStep>(
  () => props.isOpen,
  () => ({
    sortOrder: 1,
    actionType: null,
    priority: 'middle',
    dateInterval: 1,
    reservedTime: null,
    nextActionContent: '',
    ...props.sequenceStep,
  }),
);

const isNextActionContentValid = ref(true);
const isDateIntervalValid = ref(false);

const invalidSequenceStep = computed(() => {
  return !isDateIntervalValid.value
    || !internalSequenceStep.value.reservedTime
    || !isNextActionContentValid.value;
});

const handleCancel = () => {
  emit('click:cancel');
};

const handleSaveClick = () => {
  emit('click:save', internalSequenceStep.value);
};
</script>

<style lang="scss" scoped>
.sequence-call-action-container {
  position: relative;
  height: 100%;

  .time-items {
    display: flex;
    gap: 10px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .footer {
    padding: 0 20px;
    height: 48px;
    background-color: $bgcolor-dark;
    display: flex;
  }

  .immediately {
    margin: 10px 0 20px;
  }
}
</style>
