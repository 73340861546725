<template>
  <BIconWithActionMenu
    :badge-count="mailRemindItems.length"
    :auto-fetch-interval="30000"
    @auto-fetch-interval-passed="fetchMailReminder"
    @after-open="handleAfterOpen"
  >
    <template #icon>
      <BCustomIcon icon-class="b-mail-draft" />
    </template>
    <div class="draft-reminder-header">
      <div class="draft-reminder-header-item header-title">
        {{ $t('draftMailReminder.title') }}
      </div>
      <div class="draft-reminder-header-item number-of-items">
        <span>{{ mailRemindItems.length }}件</span>
      </div>
      <div class="draft-reminder-header-item">
        <BSelect
          v-model="selectedUserId"
          class="type-select"
          :items="userItems"
          item-text="text"
          item-value="value"
          filterable
          :teleported="false"
          @change="handleSelectedUserChange"
        />
      </div>
    </div>
    <div class="draft-reminder-body">
      <DraftMailReminderTimeline
        :items="mailRemindItems"
      />
    </div>
  </BIconWithActionMenu>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref } from 'vue';
import { CallTargetApiGetMailRemindItemsRequest, MailRemindItem, User } from '@/api/openapi';
import apis from '@/api/user';
import { CallTargetApiService } from '@/api/user/resources/call_target';
import { useUsers } from '@/composable/user/user/users';
import { i18nGlobal as i18n } from '@/i18n';
import { TOptions } from '@/plugins/biscuet-materials/components/atoms/BSelect.vue';

const VALUE_ALL = 'all';

const mailRemindItems = ref<MailRemindItem[]>([]);
const selectedUserId = ref<number | string>();

const { activeUsers, fetchUsers } = useUsers();

const handleAfterOpen = () => {
  fetchMasterData();
};

onBeforeMount(async () => {
  await fetchCurrentUser();
  await fetchMasterData();
});

const userItems = computed(() => {
  const userItems: TOptions = [{ text: i18n.t('draftMailReminder.allOwner'), value: VALUE_ALL }];
  return userItems.concat(
    activeUsers.value.map((candidate) => {
      return { text: candidate.name, value: candidate.id };
    }),
  );
});

const handleSelectedUserChange = () => {
  fetchMailReminder();
};

const fetchMailReminder = async () => {
  const userId = selectedUserId.value === VALUE_ALL ? undefined : selectedUserId.value as undefined | number;
  const draft = true;
  const request: CallTargetApiGetMailRemindItemsRequest = {
    userId,
    draft,
  };
  const callTargetApi = new CallTargetApiService();
  mailRemindItems.value = (await callTargetApi.getMailRemindItems({ request })).data.mailRemindItems;
};

const fetchCurrentUser = async () => {
  const res = await apis.getCurrentUser();
  selectedUserId.value = res.data.currentUser.id;
};

const fetchMasterData = async () => {
  await fetchUsers();
  await fetchMailReminder();
};
</script>

<style lang="scss" scoped>
.draft-reminder-header {
  display: flex;
  width: 100%;
  height: 70px;
  border-bottom: 1px solid $bdcolor-base;
  padding: 0 $basespace-200;

  &-item {
    margin: $basespace-250 0;

    &.header-title {
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      margin: $basespace-500 0;
    }

    &.number-of-items {
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      margin: $basespace-500 0;

      span {
        display:inline-block;
        vertical-align: middle;
        text-align: center;
        width: 54px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin: 0 $basespace-400;
      }
    }

    .type-select {
      width: 160px;
      height: 36px;
    }
  }
}
 .draft-reminder-body {
    @include m-fixed-height(calc(580px - 70px - 60px));
    overflow-y: auto;
    overflow-x: auto;
  }
</style>
