<template>
  <VWait
    for="fetchSequenceStepMasters"
    class="sequence-steps-container"
  >
    <EditableSequenceSteps
      v-model:is-menu-opens="isMenuOpens"
      :sequence-steps="internalSequenceSteps"
      enabled-to-add-steps
      :steps-actions-with-disabled="stepsActionsWithDisabled"
      @click:add-first-step-direct-mail="handleAddFirstStepClick('direct_mail')"
      @click:add-first-step-call-action="handleAddFirstStepClick('call_action')"
      @click:add-first-step-next-action="handleAddFirstStepClick('next_action')"
      v-on="stepHandlers"
    />
  </VWait>
  <div class="side-menu-container">
    <SequenceNextActionForm
      :sequence-step="(formSelectedSequenceStep as TPartialFormNextActionSequenceStep)"
      :is-open="isNextActionFormOpen"
      @click:cancel="handleFormCancel"
      @click:save="handleFormSave"
    />
  </div>
  <div class="side-menu-container">
    <SequenceCallActionForm
      :sequence-step="(formSelectedSequenceStep as TPartialFormCallActionSequenceStep)"
      :is-open="isCallActionFormOpen"
      @click:cancel="handleFormCancel"
      @click:save="handleFormSave"
    />
  </div>
  <div class="side-menu-container">
    <SequenceDirectMailForm
      mode="form"
      :sequence-step="(formSelectedSequenceStep as TPartialFormDirectMailSequenceStep)"
      :is-open="isDirectMailFormOpen"
      :sender-id="sequenceOwnerId"
      @click:cancel="handleFormCancel"
      @click:save-as-draft="handleFormSaveAsDraft"
      @click:save-as-reserve="handleFormSaveAsReserve"
    />
  </div>
</template>

<script lang="ts" setup>
import { SequenceStepMaster } from '@/api/openapi';
import EditableSequenceSteps from '@/components/organisms/user/general/sequence/EditableSequenceSteps.vue';
import { isSequenceStepsChanged } from '@/composable/user/sequence/changed';
import { useSequenceStepMasterData } from '@/composable/user/sequence/sequence-step-masters';
import { useSequenceStepActions, useStepCreating, useStepDeleting, useStepEditing, useStepForm, useStepsOrder } from '@/composable/user/sequence/step-actions';
import { useSequenceStepMasterActions } from '@/composable/user/sequence/step-menu-actions';
import SequenceCallActionForm from './SequenceCallActionForm.vue';
import SequenceDirectMailForm from './SequenceDirectMailForm.vue';
import SequenceNextActionForm from './SequenceNextActionForm.vue';
import {
  TFormSequenceStep,
  TPartialFormDirectMailSequenceStep,
  TPartialFormNextActionSequenceStep,
  TPartialFormCallActionSequenceStep,
  TStepHandlers,
  TStepType,
} from './types';

// NOTE: step機能）編集・上下移動・削除・追加

type TProps = {
  sequenceStepMasters: SequenceStepMaster[];
  sequenceOwnerId: number | undefined;
};
type TEmit = {
  (e: 'update', sequenceSteps: TFormSequenceStep[]): void;
  (e: 'update:changed', changed: boolean): void;
};

const props = defineProps<TProps>();
const emit = defineEmits<TEmit>();

const {
  sequenceStepData,
} = useSequenceStepMasterData(() => props.sequenceStepMasters);

const {
  getStepsActions,
} = useSequenceStepMasterActions();

const handleUpdate = async (steps: TFormSequenceStep[]) => {
  emit('update', steps);
  emit('update:changed', await isSequenceStepsChanged(sequenceStepData.value, steps));
};
const {
  internalSequenceSteps,
  isMenuOpens,
  stepsActionsWithDisabled,
  closeMenu,
} = useSequenceStepActions(
  () => sequenceStepData.value,
  getStepsActions,
  handleUpdate,
);
const {
  isNextActionFormOpen,
  isCallActionFormOpen,
  isDirectMailFormOpen,
  openStepForm,
  closeStepForm,
  saveStep,
  saveStepAsDraft,
  saveStepAsReserve,
  formSelectedSequenceStep,
} = useStepForm();
const { openStepFormToEdit } = useStepEditing(internalSequenceSteps, openStepForm);
const { openStepFormToCreate } = useStepCreating(internalSequenceSteps, openStepForm);
const { deleteStep } = useStepDeleting(internalSequenceSteps);
const { changeStepsOrder } = useStepsOrder(internalSequenceSteps);

const stepHandlers: TStepHandlers = {
  'click:edit': (index: number) => handleEditClick(index),
  'click:moveUp': (index: number) => handleMoveUpClick(index),
  'click:moveDown': (index: number) => handleMoveDownClick(index),
  'click:delete': (index: number) => handleDeleteClick(index),
  'click:insertNextAction': (index: number) => handleInsertClick('next_action', index),
  'click:insertCallAction': (index: number) => handleInsertClick('call_action', index),
  'click:insertDirectMail': (index: number) => handleInsertClick('direct_mail', index),
};

const handleEditClick = (index: number) => {
  closeStepForm();
  closeMenu(index);
  openStepFormToEdit(index);
};
const handleMoveUpClick = (currentIndex: number) => {
  closeStepForm();
  changeStepsOrder(currentIndex, currentIndex - 1);
};
const handleMoveDownClick = (currentIndex: number) => {
  closeStepForm();
  changeStepsOrder(currentIndex, currentIndex + 1);
};
const handleDeleteClick = async (index: number) => {
  closeStepForm();
  deleteStep(index);
};

const handleAddFirstStepClick = (stepType: TStepType) => {
  closeStepForm();
  openStepFormToCreate(stepType, 0);
};
const handleInsertClick = (stepType: TStepType, index: number) => {
  closeStepForm();
  openStepFormToCreate(stepType, index + 1);
};

const handleFormCancel = () => {
  closeStepForm();
};

const handleFormSave = (item: TFormSequenceStep) => {
  saveStep(item);
  closeStepForm();
};
const handleFormSaveAsDraft = (item: TFormSequenceStep) => {
  saveStepAsDraft(item);
  closeStepForm();
};
const handleFormSaveAsReserve = (item: TFormSequenceStep) => {
  saveStepAsReserve(item);
  closeStepForm();
};
</script>

<style scoped lang="scss">
.sequence-steps-container {
  height: 100%;
  .side-menu-container {
    position: fixed;
    height: calc(100% - $header-height);
    right: 0;
    top: $header-height;
    z-index: 8;
  }
}
</style>
