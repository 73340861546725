import { RouteRecordRaw } from 'vue-router';
import AdminAdmins from '@/components/pages/admin/Admins.vue';
import AdminClient from '@/components/pages/admin/Client.vue';
import AdminClients from '@/components/pages/admin/Clients.vue';
import Admin from '@/components/pages/admin/Index.vue';

import AdminSendgridSubUsers from '@/components/pages/admin/SendgridSubUsers.vue';

export const adminRoutes: RouteRecordRaw[] = [
  {
    path: '/admin',
    component: Admin,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'clients',
        name: 'AdminClients',
        component: AdminClients,
        meta: {
          rule: 'isAdmin',
        },
      },
      {
        path: 'client/:id(\\d+)',
        name: 'AdminClient',
        component: AdminClient,
        props: route => ({ id: Number(route.params.id) }),
        meta: {
          rule: 'isAdmin',
        },
      },
      {
        path: 'admins',
        name: 'AdminAdmins',
        component: AdminAdmins,
        meta: {
          rule: 'isAdminOwner',
        },
      },
      {
        path: 'sendgrid-sub-users',
        name: 'AdminSendgridSubUsers',
        component: AdminSendgridSubUsers,
        meta: {
          rule: 'isAdmin',
        },
      },
      // 以下はリダイレクト設定なので必ず末尾に記述する
      {
        path: '/admin:pathMatch(.*)*',
        redirect: { name: 'AdminClients' },
        meta: {
          rule: 'isAdmin',
        },
      },
      {
        path: '/admin',
        redirect: { name: 'AdminClients' },
        meta: {
          rule: 'isAdmin',
        },
      },
    ],
  },
];
