<template>
  <BLayout align-center>
    <button @click.stop="refilter">
      <slot />
    </button>
  </BLayout>
</template>

<script>
import { mapWaitingActions } from 'vue-wait';
import filterService from '@/mixins/filter_service/index';

export default {
  name: 'DuplicateStateButton',
  mixins: [filterService],
  props: {
    duplicateKey: {
      type: String,
      require: true,
    },
    duplicateValue: {
      type: String,
      require: true,
    },
  },
  methods: {
    ...mapWaitingActions('userUi', {
      setLeadListSortKeyAction: 'setLeadListSortKeyAction',
      setLeadListSortOrderAction: 'setLeadListSortOrderAction',
      setLeadListSelectedColumnsAction: 'setLeadListSelectedColumnsAction',
    }),
    refilter() {

      this.setLeadListSortKeyAction('callTargetCreatedAt');
      this.setLeadListSortOrderAction('asc');
      this.setLeadListSelectedColumnsAction([
        'companyName',
        'targetName',
        'email',
        'isEmailDuplicated',
        'lastActivityAt',
        'lastActivityTypeText',
        'lastActivityComment',
        'nextActions',
        'callTargetCreatedAt',
        'operatorName',
      ]);

      const filterParams = {};
      filterParams[this.duplicateKey] = { inc: { or: this.duplicateValue } };
      this.setFilterAndMoveLeadList(filterParams);
    },
  },
};
</script>

<style lang="scss" scoped>
  button {
    background-color: $mars;
    border-radius: 14px;
    height: 28px;
    width: 84px;
    color: $textcolor-white;
  }

  button:hover {
    opacity: 0.7;
  }
</style>
