import { ActionTree } from 'vuex';
import { ApiArguments } from '@/api/api_service';
import { MiitelAuthApiService } from '@/api/user/resources/miitel_auth';
import { RootState, ZoomPhoneState } from '@/store/types';
import Waiting from '@/store/waiting';

// TODO: 適切な型付け
export default class MiitelAuthActions {
  constructor (Api: any, types: any) {
    const actions: ActionTree<ZoomPhoneState, RootState> = {
      async getMiitelAuthAction ({ commit }) {
        await waiting.check('getMiitelAuthAction', async () => {
          try {
            const res = await Api.getMiitelAuth();
            commit(types.SET_MIITEL_AUTH, Object.values(res.data)[0]);
          } catch (err) {
            commit(types.CLEAR_MIITEL_AUTH);
            throw err;
          }
        });
      },
      async updateMiitelAuthAction ({ commit }, args) {
        await waiting.check('updateMiitelAuthAction', async () => {
          await Api.updateMiitelAuth(args);
          try {
            const res = await Api.getMiitelAuth();
            commit(types.SET_MIITEL_AUTH, Object.values(res.data)[0]);
          } catch (err) {
            throw err;
          }
        });
      },
    };

    const openApiActions: ActionTree<ZoomPhoneState, RootState> = {
      deleteMiitelAuthAction: async (
        { commit },
        apiArguments: ApiArguments<undefined>,
      ): Promise<boolean> => {
        await waiting.check('deleteMiitelAuthAction', async () => {
          try {
            const api = new MiitelAuthApiService();
            const { data } = await api.deleteMiitelAuth(apiArguments);
            if (data) {
              commit(types.CLEAR_MIITEL_AUTH);
            } else {
              throw new Error('failed to delete MiitelAuth');
            }
          } catch (err) {
            throw err;
          }
        });
        return true;
      },
    };

    const mergedActions = {
      ...actions,
      ...openApiActions,
    };
    const waiting = new Waiting(mergedActions);
    this.actions = mergedActions;
  }
}
