<template>
  <div class="modal-content">
    <div class="sub-title">
      <p>{{ $t('duplicatedList.selectLeadItems.subTitle') }}</p>
    </div>
    <BModalBody :style="{ padding: 0 }">
      <VWait
        for="fetchingDataWait"
      >
        <template #waiting>
          <BPlaceholder
            v-loading="true"
            element-loading-background="transparent"
          />
        </template>
        <div class="lead-items">
          <div class="theader">
            <div class="table-row theader-row">
              <BSortText
                v-for="{key, name} in columnHeader"
                :key="key"
                class="table-cell header-cell"
                :item-text="name"
                is-display-only
              />
            </div>
          </div>
          <div class="tbody">
            <div
              v-for="{groupKey, key, label, radioValues} in leadItems"
              :key="`${groupKey}.${key}`"
              class="table-row tbody-row"
            >
              <div class="table-cell header-cell truncate-text">
                <BTooltip
                  v-if="groupKey === 'master' && key === 'id'"
                  right
                  :content="$t('duplicatedList.selectLeadItems.masterDescription')"
                >
                  <!-- NOTE: span着てないとlabelが表示されなくなります -->
                  <span>{{ label }}</span>
                </BTooltip>
                <template v-else>
                  {{ label }}
                </template>
              </div>
              <div
                v-for="{label, displayValue} in radioValues"
                :key="label"
                class="table-cell"
              >
                <BRadio
                  :label="label"
                  :model-value="getSelectedLeadItem(groupKey, key)"
                  class="radio-item"
                  @update:model-value="onChangeSelectLeadItem(groupKey, key, $event)"
                >
                  <span class="truncate-text">{{ displayValue || '-' }}</span>
                </BRadio>
              </div>
            </div>
          </div>
        </div>
      </VWait>
    </BModalBody>
    <BModalFooter>
      <BLayout
        class="mt-200"
        justify-center
      >
        <BBtn
          type="primary"
          :disabled="!isValidSelectedItems"
          @click="$emit('click-next')"
        >
          <span>{{ $t('general.toNext') }}</span>
        </BBtn>
      </BLayout>
    </BModalFooter>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';
import { mapWaitingActions } from 'vue-wait';
import { mapGetters } from 'vuex';
import { CallTarget } from '@/api/openapi';
import { CallTargetApiService } from '@/api/user/resources/call_target';
import * as arrayUtils from '@/utils/array';
import { TSelectedCallTargetIdsWithOrder } from '../modal/DuplicatedListModal.vue';
import { TLeadItem, TLeadItemSelectedValues, TSelectedLead, getDefaultLeadItemSelectedValues, makeLeadItems } from './selectLeadItem';

type TData = {
  internalValue: TLeadItemSelectedValues;
}

export default defineComponent({
  props: {
    selectedCallTargetIdsWithOrder: {
      type: Array as PropType<TSelectedCallTargetIdsWithOrder[]>,
    },
    selectedLeads: {
      type: Array as PropType<TSelectedLead[]>,
    },
    modelValue: {
      type: Object as PropType<TLeadItemSelectedValues>,
    },
  },
  emits: [
    'click-next',
    'update:selected-leads',
    'update:modelValue',
  ],
  data(): TData {
    return {
      internalValue: getDefaultLeadItemSelectedValues(),
    };
  },
  computed: {
    ...mapGetters('user', [
      'customFields',
    ]),
    internalSelectedLeads: {
      get(): TSelectedLead[] {
        return this.selectedLeads;
      },
      set(newVal: TSelectedLead[]) {
        this.$emit('update:selected-leads', newVal);
      },
    },
    orderedSelectedCallTargetValues(): TSelectedCallTargetIdsWithOrder[] {
      return [...this.selectedCallTargetIdsWithOrder].sort(({ order }) => order);
    },
    columnHeader(): { key: number; name: string }[] {
      return [{ key: 0, name: '' }].concat(
        this.orderedSelectedCallTargetValues
          .map(({ id }) => ({
            key: id,
            name: `ID：${id}`,
          })),
      );
    },
    leadItems(): TLeadItem[] {
      return makeLeadItems(this.internalSelectedLeads, this.customFields);
    },
    isValidSelectedItems() {
      // NOTE: 全項目選択されていればOK
      return this.leadItems.every(leadItem => this.getSelectedLeadItem(leadItem.groupKey, leadItem.key) !== undefined);
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      handler (newVal: TLeadItemSelectedValues) {
        this.internalValue = newVal;
      },
    },
    internalValue: {
      handler (newVal: TLeadItemSelectedValues) {
        this.$emit('update:modelValue', newVal);
      },
    },
  },
  async created() {
    this.$wait.start('fetchingDataWait');
    await this.getCustomFieldsAction();
    await this.resetSelectionsIfNeeded();
    this.appendCustomFieldIfNeeded();
    this.$wait.end('fetchingDataWait');
  },
  methods: {
    ...mapWaitingActions('user', {
      getCustomFieldsAction: 'getCustomFieldsWait',
    }),
    async getCallTarget(callTargetId: number): Promise<CallTarget> {
      const api = new CallTargetApiService();
      const res = await api.getCallTarget({ request: { callTargetId } });

      return res.data.callTarget;
    },
    async getSelectedLeads(): Promise<CallTarget[]> {
      const ids = this.orderedSelectedCallTargetValues.map(({ id }) => id);
      const result = await Promise.all(
        ids.map(id => this.getCallTarget(id)),
      );
      return result.sort(ct => ids.indexOf(ct.id));
    },
    getSelectedLeadItem(groupKey: string, key: string): void {
      return this.internalValue[groupKey][key];
    },
    onChangeSelectLeadItem(groupKey: string, key: string, newValue: number): void {
      this.internalValue[groupKey][key] = newValue;
    },
    makeDefaultValue(): TLeadItemSelectedValues {
      const id = this.orderedSelectedCallTargetValues[0].id;
      const leadItems = makeLeadItems(this.internalSelectedLeads, this.customFields);
      const makeDefaults = (groupKey: string) => leadItems.filter(l => l.groupKey === groupKey).map(l => ({ [l.key]: id }));
      return {
        master: { id },
        user: { id }, 
        callTargetInfo: Object.assign({}, ...makeDefaults('callTargetInfo')),
        companyInfo: Object.assign({}, ...makeDefaults('companyInfo')),
        customFields: Object.assign({}, ...makeDefaults('customFields')),
      };
    },
    async resetSelectionsIfNeeded() {
      // NOTE: 対象のリードが変わっている時だけデータをリロードする
      const loadedIds = this.internalSelectedLeads.map(l => l.id);
      if (arrayUtils.equals(loadedIds, this.orderedSelectedCallTargetValues.map(({ id }) => id))) {
        return;
      }
      this.internalSelectedLeads = await this.getSelectedLeads();
      this.internalValue = this.makeDefaultValue();
    },
    appendCustomFieldIfNeeded() {
      const latestIds: number[] = this.customFields.map(e => e.id);
      const internalIds: string[] = Object.keys(this.internalValue.customFields);
      const additions = latestIds.filter(e => !internalIds.includes(e.toString()));
      additions.forEach(e => this.internalValue.customFields[e] = this.internalValue.master.id);
    },
  },
});
</script>

<style lang="scss" scoped>
  .table-cell {
    flex: 1;
    padding: 4px 12px 4px 10px;
  }
  .radio-item {
    display: flex;
  }
  .table-row {
    padding: 12px 24px;
  }
  .theader-row {
    .header-cell {
      padding-left: 26px;
    }
  }
  :deep(.el-radio__label) {
    line-height: 1.2;
    width: 100%;
  }
</style>
