import ApiBase from '@/api/base';

export default {
  getCallTargetNotes (args) {
    const { callTargetId } = args;
    return ApiBase.get(`/user/call_targets/${callTargetId}/call_target_notes`);
  },
  createCallTargetNote (args) {
    const { callTargetId } = args;
    return ApiBase.post(`/user/call_targets/${callTargetId}/call_target_notes`, args);
  },
  updateCallTargetNote (args) {
    const { id } = args;
    return ApiBase.put(`/user/call_target_notes/${id}`, args);
  },
  deleteCallTargetNote (args) {
    const { id } = args;
    return ApiBase.delete(`/user/call_target_notes/${id}`, args);
  },
};
