import ApiService, { ApiArguments } from '@/api/api_service';
import {
  GetQuickText200Response,
  GetQuickTexts200Response,
  QuickTextApi,
  QuickTextApiDeleteQuickTextRequest,
  QuickTextApiGetQuickTextRequest,
  QuickTextApiPostQuickTextRequest,
  QuickTextApiPutQuickTextRequest,
  PostSequenceMaster201Response,
  QuickTextApiGetQuickTextsRequest,
} from '@/api/openapi';

export class QuickTextApiService extends ApiService {
  api = new QuickTextApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true);
  }

  async getQuickTexts(
    {
      request,
      options,
      errorHandlers,
    }: ApiArguments<QuickTextApiGetQuickTextsRequest>,
  ) {
    return this.checkResponse<GetQuickTexts200Response>(
      this.api.getQuickTexts(request, options),
      errorHandlers,
    );
  }

  async getQuickText(
    {
      request,
      options,
      errorHandlers,
    }: ApiArguments<QuickTextApiGetQuickTextRequest>,
  ) {
    return this.checkResponse<GetQuickText200Response>(
      this.api.getQuickText(request, options),
      errorHandlers,
    );
  }

  async postQuickText({
    request,
    options,
    errorHandlers,
  }: ApiArguments<QuickTextApiPostQuickTextRequest>) {
    return this.checkResponse<PostSequenceMaster201Response>(
      this.api.postQuickText(request, options),
      errorHandlers,
    );
  }

  async putQuickText({
    request,
    options,
    errorHandlers,
  }: ApiArguments<QuickTextApiPutQuickTextRequest>) {
    return this.checkResponse<void>(
      this.api.putQuickText(request, options),
      errorHandlers,
    );
  }

  async deleteQuickText({
    request,
    options,
    errorHandlers,
  }: ApiArguments<QuickTextApiDeleteQuickTextRequest>) {
    return this.checkResponse<void>(
      this.api.deleteQuickText(request, options),
      errorHandlers,
    );
  }
}
