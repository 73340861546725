<template>
  <div class="container">
    <div class="error-detail">
      <div>
        <img
          src="@/assets/error_page_image.svg"
          alt=""
        >
      </div>
      <p class="status-code">
        {{ error.statusCode }}
      </p>
      <p class="status-message">
        {{ error.message }}
      </p>
      <p class="message">
        {{ message }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    error: {
      type: Object,
      default () {
        return {
          statusCode: 404,
          message: 'NotFound',
        };
      },
    },
  },
  computed: {
    message() {
      const code = this.error.statusCode;
      if (code >= 400 && code < 500) {
        return 'クライアントエラー';
      } else if (code >= 500) {
        return 'サーバーエラー';
      } else {
        return '不明なエラー';
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.container {
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.error-detail {
  color: $charcoal;
  p {
    margin: 10px 0;
  }
  .status-code {
    font-size: 18px;
  }
  .message {
    font-size: 12px;
  }
}
</style>
