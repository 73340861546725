import { computed } from 'vue';

const isItSequenceAction = (nextAction: object) => nextAction.sequenceStepInstance != null;

const useNextActionComputed = (nextAction: object) => {
  const isSequenceAction = computed(() => {
    return isItSequenceAction(nextAction);
  });

  return {
    isSequenceAction,
  };
};

export {
  useNextActionComputed,
  isItSequenceAction,
};
