<template>
  <div
    class="b-avatar"
    @mouseenter="$emit('mouseenter')"
    @mouseleave="$emit('mouseleave')"
  >
    <ElAvatar
      :style="styles"
      :size="size"
      :class="classes"
    >
      <img
        v-if="currentImageUrl"
        :src="currentImageUrl"
        alt="avatar"
        @error="handleImageUrlError"
      >
      <img
        v-else-if="!name && !userId"
        src="@/assets/default_avatar.png"
        alt="avatar"
        @error="handleImageUrlError"
      >
      <span
        v-else
        style="color: white;"
      >{{ displayString }}</span>
    </ElAvatar>
  </div>
</template>

<script>
import { Random } from '@/utils/random';

export default {
  props: {
    imageUrl: String,
    defaultImageUrl: {
      type: String,
      default: '',
    },
    size: {
      type: [Number, String],
      default: 20,
    },
    shadow: Boolean,
    square: Boolean,
    name: {
      type: String,
      default: '',
    },
    userId: Number,
  },
  emits: [
    'mouseenter',
    'mouseleave',
  ],
  data () {
    return {
      currentImageUrl: '',
    };
  },
  computed: {
    classes () {
      return {
        'b-avatar-shadow': this.shadow,
        'b-avatar-square': this.square,
      };
    },
    styles () {
      return {
        'background-color': this.pickColor(),
      };
    },
    displayString () {
      if (typeof this.name !== 'string') return '';
      return this.name.substring(0, 2);
    },
  },
  watch: {
    imageUrl (value) {
      this.currentImageUrl = value;
    },
  },
  created () {
    this.currentImageUrl = this.imageUrl || this.defaultImageUrl;
  },
  methods: {
    handleImageUrlError () {
      this.currentImageUrl = this.defaultImageUrl;
    },
    pickColor() {
      // MEMO: BCのprimary colorの色相に合わせた色(https://mycolor.space/?hex=%23018485&sub=1)を候補として列挙
      const gaiaHue = ['#018485', '#349F9F', '#54BABA', '#72D6D6', '#86E7E5'];
      const uranusHue = ['#2CBFB7', '#009991', '#00746E', '#00504C', '#002F2C'];
      const neptuneHue = ['#9EA7DF', '#7983B8', '#556093', '#313F6F', '#0A224D'];
      const colorCandidates = gaiaHue.concat(uranusHue, neptuneHue);
      const pickPosition = this.pickPosition(0, colorCandidates.length - 1);
      return colorCandidates[pickPosition];
    },
    pickPosition(min, max) {
      const random = new Random(this.userId || 1);
      return random.nextInt(min, max);
    },
  },
};
</script>

<style lang="scss" scoped>
  .b-avatar {
    display: flex;
  }
  .b-avatar-square {
    border-radius: 6px;

    img {
      border-radius: 6px;
    }
  }

  .b-avatar-shadow {
    box-shadow: 0 3px 5px $shadowcolor-base;
  }
</style>
