import SalesForceApiService from '@/api/salesforce_api_service';

export default {
  createSfTask(args) {
    const { type, targetId } = args.body;
    return new SalesForceApiService().post(`/user/salesforce/sf_tasks/${type}/${targetId}`, args);
  },
  resyncSfTask(args) {
    const { type, targetId } = args.body;
    return new SalesForceApiService().put(`/user/salesforce/sf_tasks/${type}/${targetId}`, args);
  },
  checkSyncSfTask(args) {
    const { type, targetId, callTargetId } = args.body;
    return new SalesForceApiService().get(`/user/salesforce/sf_tasks/${type}/${targetId}?call_target_id=${callTargetId}`);
  },
};
