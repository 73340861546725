import { readonly, ref } from 'vue';
import { MfaSetting, MfaSettingApiCreateMfaSettingRequest } from '@/api/openapi';
import { MfaSettingService } from '@/api/user/resources/mfa_setting';

const useMfaSetting = () => {
  const api = new MfaSettingService();
  const mfaSetting = ref<MfaSetting>(null);

  const fetchMfaSetting = async () => {
    const res = await api.getMfaSetting();
    mfaSetting.value = res.data.mfaSetting;
  };

  const createMfaSetting = async (request: MfaSettingApiCreateMfaSettingRequest) => {
    await api.postMfaSetting({ request });
  };

  const deleteMfaSetting = async () => {
    await api.deleteMfaSetting();
  };

  return {
    mfaSetting: readonly(mfaSetting),
    fetchMfaSetting,
    createMfaSetting,
    deleteMfaSetting,
  };
};

export {
  useMfaSetting,
};
