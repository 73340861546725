<template>
  <div class="button-container">
    <BBtn
      type="none"
      :loading="loading"
      @click.stop="moveToSequences"
    >
      {{ $t('general.back.text') }}
    </BBtn>
    <BBtn
      :disabled="!canSave"
      :loading="loading"
      type="primary"
      @click.stop="handleClickSave"
    >
      {{ $t('general.save.text') }}
    </BBtn>
  </div>
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router';
import { i18nGlobal as i18n } from '@/i18n';
import { bitterAlert } from '@/plugins/BBitterAlert';

type TProps = {
  canSave: boolean;
  loading: boolean;
  needConfirmationToLeave: boolean;
};
const props = defineProps<TProps>();

const emit = defineEmits(['click:save']);

const router = useRouter();

const moveToSequences = async () => {
  if (props.needConfirmationToLeave) {
    const ok = await bitterAlert.show({
      title: i18n.t('general.confirm.text'),
      text: i18n.t('general.alert.of', { target: i18n.t('general.unsavedValue'), action: i18n.t('general.clear') }),
    });
    if (!ok) return;
  }

  router.push({ name: 'Sequences' });
};
const handleClickSave = () => {
  if (!props.canSave) return;

  emit('click:save');
};
</script>

<style lang="scss" scoped>
.button-container {
  margin: 0 (-$basespace-600);
  padding: 0 $basespace-600;
  height: 48px;

  display: flex;
  gap: 30px;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  button {
    width: 100px;
  }
}
</style>
