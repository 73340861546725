<template>
  <div>
    <BDrawerMenu
      v-model="drawer"
      :title="$t('freeAction.title')"
      @cancel="handleCancelCreateFreeAction"
    >
      <div>
        <BBtn
          text
          type="success"
          @click="handleQuickTextButtonClick"
        >
          <BIcon
            type="success"
            outlined
          >
            article
          </BIcon>
          <span class="ml-50 fw-bold">{{ $t('quickText.apply') }}</span>
        </BBtn>
        <BInput
          ref="memoTextarea"
          v-model="freeAction.content"
          :placeholder="$t('freeAction.content')"
          :rows="16"
          type="textarea"
          copyable
        />
      </div>
      <template #footer>
        <BLayout
          class="py-300"
          row
          align-center
          justify-center
        >
          <BBtn
            class="mr-300"
            text
            :disabled="waiting"
            @click="handleCancelCreateFreeAction"
          >
            {{ $t('general.cancel.text') }}
          </BBtn>
          <BBtn
            class="ml-300"
            type="primary"
            :loading="waiting"
            :disabled="disabled"
            @click="clickSaveButton"
          >
            {{ $t('general.save.text') }}
          </BBtn>
        </BLayout>
      </template>
    </BDrawerMenu>
    <QuickTextSelectModal
      modal-name="quickTextSelectInTargetFreeAction"
      :call-target-id="callTargetId"
      @select="handleQuickTextSelect"
    />
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { mapWaitingActions } from 'vue-wait';
import { mapGetters } from 'vuex';
import QuickTextSelectModal from '@/components/organisms/user/lead/modal/QuickTextSelectModal.vue';
import { useInsertText } from '@/composable/input';
import errorHandler from '@/mixins/error_handler';

export default {
  components: { QuickTextSelectModal },
  mixins: [errorHandler],
  props: {
    callTargetId: Number,
  },
  setup(props) {
    const initFreeAction = () => {
      return {
        content: null,
      };
    };
    const freeAction = ref(initFreeAction());
    const content = computed({
      get: () => freeAction.value.content,
      set: (value) => {
        freeAction.value.content = value;
      },
    });
    const memoTextarea = ref(null);
    const { insertText } = useInsertText(memoTextarea, content);
    return {
      insertText,
      freeAction, 
      memoTextarea,
      initFreeAction,
    };
  },
  data () {
    return this.initialState();
  },
  computed: {
    ...mapGetters('user', ['callTarget']),
    ...mapGetters('userUi', ['targetFreeActionFromVisible']),
    drawer: {
      get () {
        return this.targetFreeActionFromVisible;
      },
      set (newVal) {
        if (this.targetFreeActionFromVisible !== newVal) this.setTargetFreeActionFormVisibleAction(newVal);
      },
    },
    waiting () {
      return this.$wait.is('createFreeActionWait');
    },
    disabled () {
      return this.freeAction.content === null || this.freeAction.content.length === 0;
    },
  },
  methods: {
    ...mapWaitingActions('user', {
      createFreeActionAction: 'createFreeActionWait',
      getCallTargetsBackgroundAction: { action: 'getCallTargetsAction', loader: 'getCallTargetsBackgroundWait' },
      getLeadHistoriesBackgroundAction: { action: 'getLeadHistoriesAction', loader: 'getLeadHistoriesBackgroundWait' },
    }),
    ...mapWaitingActions('userUi', {
      setTargetFreeActionFormVisibleAction: 'setTargetFreeActionFormVisibleWait',
      setTargetHeaderMoveButtonDisabledAction: 'setTargetHeaderMoveButtonDisabledWait',
    }),
    initialState () {
      return {
      };
    },
    targetHeaderMoveButtonDisable () {
      this.setTargetHeaderMoveButtonDisabledAction(true);
    },
    clickSaveButton() {
      this.createFreeAction();
    },
    async createFreeAction () {
      if (this.disabled) return;

      try {
        await this.createFreeActionAction({
          callTargetId: this.callTargetId,
          body: { freeAction: this.freeAction },
          errorHandlers: {
            422: this.defaultHandler,
          },
        }).then(() => {
          this.closeDrawer();
        });
      } catch (e) {
        this.$bitterAlert.show({
          title: this.$t('freeAction.drawer.errorModalTitle'),
          text: e.response?.data?.errorMessage,
          buttonsCancel: false,
        });
      } finally {
        // calLTargetで変更反映
        this.getLeadHistoriesBackgroundAction({ callTargetId: this.callTargetId });
        // LeadListで変更反映
        this.getCallTargetsBackgroundAction();
      }
    },
    async handleCancelCreateFreeAction () {
      const check = await this.$bitterAlert.show({
        title: this.$t('general.confirm.text'),
        text: this.$t('general.alert.of', { target: this.$t('general.unsavedValue'), action: this.$t('general.clear') }),
      });
      if (!check) { return; }

      this.closeDrawer();
    },
    closeDrawer () {
      this.drawer = false;
      Object.assign(this.$data, this.initialState());
      this.freeAction = structuredClone(this.initFreeAction());
      this.setTargetHeaderMoveButtonDisabledAction(false);
    },
    handleQuickTextButtonClick() {
      this.$modal.show('quickTextSelectInTargetFreeAction');
    },
    handleQuickTextSelect(content) {
      this.insertText(content);
    },
  },
};
</script>

<style lang="scss" scoped>
  .disabledLabel{
    color: $textcolor-light;
  }
</style>
