/* tslint:disable */
/* eslint-disable */
/**
 * openapi
 * bales cloud
 *
 * The version of the OpenAPI document: 1.0
 * Contact: test@example.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GetTriggerTargets200Response } from '../models';
/**
 * TriggerTargetApi - axios parameter creator
 * @export
 */
export const TriggerTargetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * トリガー対象一覧取得API
         * @summary トリガー対象一覧取得API
         * @param {number} triggerId トリガーID
         * @param {number} [currentPage] リードリストでの現在のページネーション
         * @param {number} [ownerId] 所有者ID
         * @param {string} [status] 状態
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTriggerTargets: async (triggerId: number, currentPage?: number, ownerId?: number, status?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'triggerId' is not null or undefined
            assertParamExists('getTriggerTargets', 'triggerId', triggerId)
            const localVarPath = `/api/user/trigger_targets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)

            if (currentPage !== undefined) {
                localVarQueryParameter['currentPage'] = currentPage;
            }

            if (triggerId !== undefined) {
                localVarQueryParameter['triggerId'] = triggerId;
            }

            if (ownerId !== undefined) {
                localVarQueryParameter['ownerId'] = ownerId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TriggerTargetApi - functional programming interface
 * @export
 */
export const TriggerTargetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TriggerTargetApiAxiosParamCreator(configuration)
    return {
        /**
         * トリガー対象一覧取得API
         * @summary トリガー対象一覧取得API
         * @param {number} triggerId トリガーID
         * @param {number} [currentPage] リードリストでの現在のページネーション
         * @param {number} [ownerId] 所有者ID
         * @param {string} [status] 状態
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTriggerTargets(triggerId: number, currentPage?: number, ownerId?: number, status?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTriggerTargets200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTriggerTargets(triggerId, currentPage, ownerId, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TriggerTargetApi - factory interface
 * @export
 */
export const TriggerTargetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TriggerTargetApiFp(configuration)
    return {
        /**
         * トリガー対象一覧取得API
         * @summary トリガー対象一覧取得API
         * @param {number} triggerId トリガーID
         * @param {number} [currentPage] リードリストでの現在のページネーション
         * @param {number} [ownerId] 所有者ID
         * @param {string} [status] 状態
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTriggerTargets(triggerId: number, currentPage?: number, ownerId?: number, status?: string, options?: any): AxiosPromise<GetTriggerTargets200Response> {
            return localVarFp.getTriggerTargets(triggerId, currentPage, ownerId, status, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TriggerTargetApi - interface
 * @export
 * @interface TriggerTargetApi
 */
export interface TriggerTargetApiInterface {
    /**
     * トリガー対象一覧取得API
     * @summary トリガー対象一覧取得API
     * @param {number} triggerId トリガーID
     * @param {number} [currentPage] リードリストでの現在のページネーション
     * @param {number} [ownerId] 所有者ID
     * @param {string} [status] 状態
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TriggerTargetApiInterface
     */
    getTriggerTargets(triggerId: number, currentPage?: number, ownerId?: number, status?: string, options?: AxiosRequestConfig): AxiosPromise<GetTriggerTargets200Response>;

}

/**
 * Request parameters for getTriggerTargets operation in TriggerTargetApi.
 * @export
 * @interface TriggerTargetApiGetTriggerTargetsRequest
 */
export interface TriggerTargetApiGetTriggerTargetsRequest {
    /**
     * トリガーID
     * @type {number}
     * @memberof TriggerTargetApiGetTriggerTargets
     */
    readonly triggerId: number

    /**
     * リードリストでの現在のページネーション
     * @type {number}
     * @memberof TriggerTargetApiGetTriggerTargets
     */
    readonly currentPage?: number

    /**
     * 所有者ID
     * @type {number}
     * @memberof TriggerTargetApiGetTriggerTargets
     */
    readonly ownerId?: number

    /**
     * 状態
     * @type {string}
     * @memberof TriggerTargetApiGetTriggerTargets
     */
    readonly status?: string
}

/**
 * TriggerTargetApi - object-oriented interface
 * @export
 * @class TriggerTargetApi
 * @extends {BaseAPI}
 */
export class TriggerTargetApi extends BaseAPI implements TriggerTargetApiInterface {
    /**
     * トリガー対象一覧取得API
     * @summary トリガー対象一覧取得API
     * @param {TriggerTargetApiGetTriggerTargetsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TriggerTargetApi
     */
    public getTriggerTargets(requestParameters: TriggerTargetApiGetTriggerTargetsRequest, options?: AxiosRequestConfig) {
        return TriggerTargetApiFp(this.configuration).getTriggerTargets(requestParameters.triggerId, requestParameters.currentPage, requestParameters.ownerId, requestParameters.status, options).then((request) => request(this.axios, this.basePath));
    }
}
