import { CallTarget, CallTargetCustomField, LeadItemCallTargetInfo, LeadItemCompanyInfo, LeadItemSelectedValues } from '@/api/openapi';
import { i18nGlobal as i18n } from '@/i18n';

type TSelectedLead = CallTarget;
type PartialObjectValues<T> = {
  [Key in keyof T]: Partial<T[Key]>;
}
type TLeadItemSelectedValues = PartialObjectValues<LeadItemSelectedValues>
const leadItemSelectedCallTargetInfo: (keyof LeadItemCallTargetInfo)[] = [
  'department',
  'position',
  'lastName',
  'firstName',
  'lastNameKana',
  'firstNameKana',
  'salutation',
  'email',
  'personalTel',
];
const leadItemSelectedCompanyInfo: (keyof LeadItemCompanyInfo)[] = [
  'companyName',
  'companyCountry',
  'companyPostalCode',
  'companyPrefecture',
  'companyCity',
  'companyAddressLine',
  'companyTel1',
  'companyUrl',
  'businessCategory',
  'numberOfEmployees',
];

const getDefaultLeadItemSelectedValues = (): TLeadItemSelectedValues => ({
  master: {},
  user: {},
  callTargetInfo: {},
  companyInfo: {},
  customFields: {},
});

type TLeadItem = {
  groupKey: string | null;
  key: string | number;
  label: string;
  radioValues: {
    label: number;
    displayValue: unknown | undefined;
  }[]
}
const I18N_LABEL = 'duplicatedList.selectLeadItems.label';
const makeLeadItems = (selectedLeads: TSelectedLead[], customFields: CallTargetCustomField[]): TLeadItem[] => [
  {
    groupKey: 'master',
    key: 'id',
    label: i18n.t(`${I18N_LABEL}.master.id`),
    radioValues: selectedLeads.map(lead => ({
      label: lead.id,
      displayValue: i18n.t('duplicatedList.selectLeadItems.useAsMaster'),
    })),
  },
  {
    groupKey: 'user',
    key: 'id',
    label: i18n.t(`${I18N_LABEL}.user.id`),
    radioValues: selectedLeads.map(lead => ({
      label: lead.id,
      displayValue: lead.user?.name,
    })),
  },
  ...makeCompanyCallTargetLeadItems(selectedLeads, 'companyInfo', leadItemSelectedCompanyInfo),
  ...makeCompanyCallTargetLeadItems(selectedLeads, 'callTargetInfo', leadItemSelectedCallTargetInfo),
  ...
  customFields.map(customField => ({
    groupKey: 'customFields',
    key: customField.id,
    label: `${i18n.t(`${I18N_LABEL}.customFields.title`)}：${customField.name}`,
    radioValues: selectedLeads.map(lead => ({
      label: lead.id,
      // TODO: 型怒られしているので修正
      displayValue: [lead.customFields.find(cf => cf.id === customField.id)?.value].flat().join(', '),
    })),
  })),
  
];
const makeCompanyCallTargetLeadItems = (selectedLeads: TSelectedLead[], groupKey: string, keys: typeof leadItemSelectedCompanyInfo | typeof leadItemSelectedCallTargetInfo): TLeadItem[] =>
  keys.map(key => ({
    groupKey,
    key,
    label: `${i18n.t(`${I18N_LABEL}.${groupKey}.title`)}：${i18n.t(`${I18N_LABEL}.${groupKey}.${key}`)}`,
    radioValues: selectedLeads.map(lead => ({
      label: lead.id,
      displayValue: lead[groupKey][key],
    })),
  }));

export type {
  TSelectedLead,
  TLeadItemSelectedValues,
  TLeadItem,
};
export {
  getDefaultLeadItemSelectedValues,
  makeLeadItems,
};
