import lodash from 'lodash';
import { isEmptyValue } from '@/utils/emptyValue';

const isObject = (obj: unknown) => {
  return obj?.constructor === Object;
};

const isEmptyObject = (obj: unknown) => {
  return isObject(obj) && Object.keys(obj).length === 0;
};

/**
 * オブジェクト以外の値が見つかるまでオブジェクトを再帰的に処理する
 * NOTE: Object.keys(val) の0番目を取っているため、オブジェクトのプロパティが2つ以上ある場合は正常に動作しない
 * NOTE: OK： { inc: { or: '' } }
 * NOTE: NG： { inc: { or: '' }, exc: { or: ''} }
*/
const extractValueFromObject = (val) => {
  const child = isObject(val) ? val[Object.keys(val)[0]] : val;
  if (isObject(child)) {
    return extractValueFromObject(child);
  } else {
    return child;
  }
};

/**
 * 受け取ったオブジェクトのプロパティであるオブジェクトのうち値が空のものを削除して新たなオブジェクトを返す
 * NOTE: 対応するオブジェクトの形は { a: { b: '' }, c: { d: [] } } のようなもの
 */
const removePropertyHasEmptyValue = (obj) => {
  obj = lodash.cloneDeep(obj);
  for (const key in obj) {
    const value = extractValueFromObject(obj[key]);
    const isEmpty = isEmptyValue(value);
    if (isEmpty) delete obj[key];
  }
  return obj;
};

const camelcaseKeys = (obj: unknown, options?: { exclude: RegExp[] }) => {
  if (!isObject(obj)) return obj;

  const exclude = options?.exclude ?? [];

  const entries: [string, unknown][] = Object.entries(obj)
    .map(([key, value]) => {
      if (exclude.some((regex) => regex.test(key))) return [key, value];

      const camelCasedValue = camelcaseKeys(value as Record<string, unknown>, options);
      return [lodash.camelCase(key), camelCasedValue];
    });
  return Object.fromEntries(entries);
};

export {
  isObject,
  isEmptyObject,
  extractValueFromObject,
  removePropertyHasEmptyValue,
  camelcaseKeys,
};
