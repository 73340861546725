import { AxiosResponse } from 'axios';
import ApiService, { ApiArguments } from '@/api/api_service';
import ApiBase from '@/api/base';
import {
  UserApi,
  UserApiDeleteUserRequest,
  UserApiPostUserRequest,
  UserApiPutUserRequest,
  UserApiPostPrepareLoginEmailUpdateRequest,
  UserApiPostConfirmLoginEmailUpdateRequest,
} from '@/api/openapi';

export class UserApiService extends ApiService {
  api = new UserApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true);
  }

  async createUser({
    request,
    options,
    errorHandlers,
  }: ApiArguments<UserApiPostUserRequest>): Promise<AxiosResponse<void>> {
    return this.checkResponse<void>(
      this.api.postUser(request, options),
      errorHandlers,
    );
  }

  async updateUser({
    request,
    options,
    errorHandlers,
  }: ApiArguments<UserApiPutUserRequest>): Promise<AxiosResponse<void>> {
    return this.checkResponse<void>(
      this.api.putUser(request, options),
      errorHandlers,
    );
  }

  async deleteUser({
    request,
    options,
    errorHandlers,
  }: ApiArguments<UserApiDeleteUserRequest>): Promise<AxiosResponse<void>> {
    return this.checkResponse<void>(
      this.api.deleteUser(request, options),
      errorHandlers,
    );
  }

  async prepareLoginEmailUpdate({
    request,
    options,
    errorHandlers,
  }: ApiArguments<UserApiPostPrepareLoginEmailUpdateRequest>): Promise<AxiosResponse<void>> {
    return this.checkResponse<void>(
      this.api.postPrepareLoginEmailUpdate(request, options),
      errorHandlers,
    );
  }

  async confirmLoginEmailUpdate({
    request,
    options,
    errorHandlers,
  }: ApiArguments<UserApiPostConfirmLoginEmailUpdateRequest>): Promise<AxiosResponse<void>> {
    return this.checkResponse<void>(
      this.api.postConfirmLoginEmailUpdate(request, options),
      errorHandlers,
    );
  }
}

export default {
  getUsers () {
    return ApiBase.get(`/user/users`);
  },
  getUser (args) {
    const { id } = args;
    return ApiBase.get(`/user/users/${id}`, args);
  },
};
