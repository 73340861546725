<template>
  <BContentBox>
    <template #content>
      <div
        v-loading="miitelLoading"
      >
        <BLayout
          justify-center
          column
          class="form"
        >
          <div class="setting-title">
            {{ $t(`corpSettingApi.miitel.title`) }}
          </div>
          <BListItem class="mb-300">
            <template #header>
              <div>{{ $t('corpSettingApi.miitel.miitelCompanyId') }}</div>
            </template>
            <BInput
              v-model="internalMiitelCompanyId"
              class="w-input"
              :disabled="!$can('owner')"
            />
          </BListItem>
          <BListItem class="mb-300">
            <template #header>
              <div>{{ $t('corpSettingApi.miitel.miitelAccessKey') }}</div>
            </template>
            <BInput
              v-model="internalMiitelAccessKey"
              class="w-input"
              :disabled="!$can('owner')"
            />
          </BListItem>
          <BListItem class="mt-50">
            <BBtn
              type="primary"
              :disabled="miitelLoading || !$can('owner')"
              @click="saveMiitelAuth"
            >
              <span>{{ $t(`corpSettingApi.miitel.btnText`) }}</span>
            </BBtn>
          </BListItem>
        </BLayout>
      </div>
    </template>
  </BContentBox>
</template>

<script>
import { mapWaitingActions } from 'vue-wait';
import { mapGetters } from 'vuex';
import errorHandler from '@/mixins/error_handler';
import inputValidation from '@/mixins/input_validation';
import { unconnectAllExcept } from '@/services/cti_service';

export default {
  name: 'MiitelApi',
  mixins: [inputValidation, errorHandler],
  data () {
    return {
      internalMiitelCompanyId: '',
      internalMiitelAccessKey: '',
    };
  },
  computed: {
    ...mapGetters('user', [
      'currentUser',
      'miitelAuth',
    ]),
    miitelLoading () {
      return this.$wait.is(['getMiitelAuthWait', 'updateMiitelAuthWait']);
    },
  },
  async created () {
    await Promise.all([
      this.getMiitelAuthAction(),
    ]);

    this.internalMiitelCompanyId = !this.$can('owner') ? this.omittedText(this.miitelAuth.companyId) : this.miitelAuth.companyId;
    this.internalMiitelAccessKey = !this.$can('owner') ? this.omittedText(this.miitelAuth.accessKey) : this.miitelAuth.accessKey;
  },
  methods: {
    ...mapWaitingActions('user', {
      getMiitelAuthAction: 'getMiitelAuthWait',
      updateMiitelAuthAction: 'updateMiitelAuthWait',
    }),
    async saveMiitelAuth(){
      const isCreated = !this.miitelAuth.companyId && !this.miitelAuth.accessKey;
      await this.updateMiitelAuthAction({
        id: this.currentUser.clientId,
        body: {
          miitel_auth: {
            companyId: this.internalMiitelCompanyId,
            accessKey: this.internalMiitelAccessKey,
          },
        },
      });

      if (isCreated) {
        await unconnectAllExcept('miitel');
      }
    },
    omittedText(text) {
      if (typeof text !== 'string' || text?.length == 0) return;
      return text.slice(0, 3);
    },
  },
};
</script>

<style lang="scss" scoped>
.setting-title {
  font-weight: bold;
  margin-bottom: 10px;
}
.form {
  @media only screen and (min-width: 959px) {
    .w-input {
      width: 40%;
    }
  }
}
</style>
