<template>
  <Bar
    :chart-id="chartId"
    :chart-options="options"
    :chart-data="chartData"
    :width="width"
    :height="height"
  />
</template>

<script lang="ts">
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { defineComponent } from 'vue';
import { Bar } from 'vue-chartjs';

ChartJS.register(
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
);

export default defineComponent({
  components: { Bar },
  props: {
    chartData: Object,
    options: Object,
    chartId: {
      type: String,
      default: 'bar-chart',
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
  },
});
</script>
