/* tslint:disable */
/* eslint-disable */
/**
 * openapi
 * bales cloud
 *
 * The version of the OpenAPI document: 1.0
 * Contact: test@example.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GetGoogleAuthorizationUrl200Response } from '../models';
// @ts-ignore
import { GetGoogleSetting200Response } from '../models';
// @ts-ignore
import { GoogleSettingBody } from '../models';
/**
 * GoogleSettingApi - axios parameter creator
 * @export
 */
export const GoogleSettingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Google OAuth連携API
         * @summary Google OAuth連携API
         * @param {GoogleSettingBody} [googleSettingBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGoogleSetting: async (googleSettingBody?: GoogleSettingBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/google/google_settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(googleSettingBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Google OAuth連携解除API
         * @summary Google OAuth連携解除API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGoogleSetting: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/google/google_setting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Google OAuth URL取得API
         * @summary Google OAuth URL取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGoogleAuthorizationUrl: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/google/authorization_url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Google OAuth設定取得API
         * @summary Google OAuth設定取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGoogleSetting: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/google/google_setting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GoogleSettingApi - functional programming interface
 * @export
 */
export const GoogleSettingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GoogleSettingApiAxiosParamCreator(configuration)
    return {
        /**
         * Google OAuth連携API
         * @summary Google OAuth連携API
         * @param {GoogleSettingBody} [googleSettingBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createGoogleSetting(googleSettingBody?: GoogleSettingBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createGoogleSetting(googleSettingBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Google OAuth連携解除API
         * @summary Google OAuth連携解除API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteGoogleSetting(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGoogleSetting(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Google OAuth URL取得API
         * @summary Google OAuth URL取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGoogleAuthorizationUrl(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetGoogleAuthorizationUrl200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGoogleAuthorizationUrl(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Google OAuth設定取得API
         * @summary Google OAuth設定取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGoogleSetting(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetGoogleSetting200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGoogleSetting(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GoogleSettingApi - factory interface
 * @export
 */
export const GoogleSettingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GoogleSettingApiFp(configuration)
    return {
        /**
         * Google OAuth連携API
         * @summary Google OAuth連携API
         * @param {GoogleSettingBody} [googleSettingBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGoogleSetting(googleSettingBody?: GoogleSettingBody, options?: any): AxiosPromise<boolean> {
            return localVarFp.createGoogleSetting(googleSettingBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Google OAuth連携解除API
         * @summary Google OAuth連携解除API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGoogleSetting(options?: any): AxiosPromise<boolean> {
            return localVarFp.deleteGoogleSetting(options).then((request) => request(axios, basePath));
        },
        /**
         * Google OAuth URL取得API
         * @summary Google OAuth URL取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGoogleAuthorizationUrl(options?: any): AxiosPromise<GetGoogleAuthorizationUrl200Response> {
            return localVarFp.getGoogleAuthorizationUrl(options).then((request) => request(axios, basePath));
        },
        /**
         * Google OAuth設定取得API
         * @summary Google OAuth設定取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGoogleSetting(options?: any): AxiosPromise<GetGoogleSetting200Response> {
            return localVarFp.getGoogleSetting(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GoogleSettingApi - interface
 * @export
 * @interface GoogleSettingApi
 */
export interface GoogleSettingApiInterface {
    /**
     * Google OAuth連携API
     * @summary Google OAuth連携API
     * @param {GoogleSettingBody} [googleSettingBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoogleSettingApiInterface
     */
    createGoogleSetting(googleSettingBody?: GoogleSettingBody, options?: AxiosRequestConfig): AxiosPromise<boolean>;

    /**
     * Google OAuth連携解除API
     * @summary Google OAuth連携解除API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoogleSettingApiInterface
     */
    deleteGoogleSetting(options?: AxiosRequestConfig): AxiosPromise<boolean>;

    /**
     * Google OAuth URL取得API
     * @summary Google OAuth URL取得API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoogleSettingApiInterface
     */
    getGoogleAuthorizationUrl(options?: AxiosRequestConfig): AxiosPromise<GetGoogleAuthorizationUrl200Response>;

    /**
     * Google OAuth設定取得API
     * @summary Google OAuth設定取得API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoogleSettingApiInterface
     */
    getGoogleSetting(options?: AxiosRequestConfig): AxiosPromise<GetGoogleSetting200Response>;

}

/**
 * Request parameters for createGoogleSetting operation in GoogleSettingApi.
 * @export
 * @interface GoogleSettingApiCreateGoogleSettingRequest
 */
export interface GoogleSettingApiCreateGoogleSettingRequest {
    /**
     * 
     * @type {GoogleSettingBody}
     * @memberof GoogleSettingApiCreateGoogleSetting
     */
    readonly googleSettingBody?: GoogleSettingBody
}

/**
 * GoogleSettingApi - object-oriented interface
 * @export
 * @class GoogleSettingApi
 * @extends {BaseAPI}
 */
export class GoogleSettingApi extends BaseAPI implements GoogleSettingApiInterface {
    /**
     * Google OAuth連携API
     * @summary Google OAuth連携API
     * @param {GoogleSettingApiCreateGoogleSettingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoogleSettingApi
     */
    public createGoogleSetting(requestParameters: GoogleSettingApiCreateGoogleSettingRequest = {}, options?: AxiosRequestConfig) {
        return GoogleSettingApiFp(this.configuration).createGoogleSetting(requestParameters.googleSettingBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Google OAuth連携解除API
     * @summary Google OAuth連携解除API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoogleSettingApi
     */
    public deleteGoogleSetting(options?: AxiosRequestConfig) {
        return GoogleSettingApiFp(this.configuration).deleteGoogleSetting(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Google OAuth URL取得API
     * @summary Google OAuth URL取得API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoogleSettingApi
     */
    public getGoogleAuthorizationUrl(options?: AxiosRequestConfig) {
        return GoogleSettingApiFp(this.configuration).getGoogleAuthorizationUrl(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Google OAuth設定取得API
     * @summary Google OAuth設定取得API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoogleSettingApi
     */
    public getGoogleSetting(options?: AxiosRequestConfig) {
        return GoogleSettingApiFp(this.configuration).getGoogleSetting(options).then((request) => request(this.axios, this.basePath));
    }
}
