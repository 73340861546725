/* tslint:disable */
/* eslint-disable */
/**
 * openapi
 * bales cloud
 *
 * The version of the OpenAPI document: 1.0
 * Contact: test@example.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CallResult } from '../models';
/**
 * CallResultApi - axios parameter creator
 * @export
 */
export const CallResultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * コール結果取得API
         * @summary コール結果取得API
         * @param {number} callResultId コール結果ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCallResult: async (callResultId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'callResultId' is not null or undefined
            assertParamExists('getCallResult', 'callResultId', callResultId)
            const localVarPath = `/api/user/call_results/{callResultId}`
                .replace(`{${"callResultId"}}`, encodeURIComponent(String(callResultId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CallResultApi - functional programming interface
 * @export
 */
export const CallResultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CallResultApiAxiosParamCreator(configuration)
    return {
        /**
         * コール結果取得API
         * @summary コール結果取得API
         * @param {number} callResultId コール結果ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCallResult(callResultId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CallResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCallResult(callResultId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CallResultApi - factory interface
 * @export
 */
export const CallResultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CallResultApiFp(configuration)
    return {
        /**
         * コール結果取得API
         * @summary コール結果取得API
         * @param {number} callResultId コール結果ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCallResult(callResultId: number, options?: any): AxiosPromise<CallResult> {
            return localVarFp.getCallResult(callResultId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CallResultApi - interface
 * @export
 * @interface CallResultApi
 */
export interface CallResultApiInterface {
    /**
     * コール結果取得API
     * @summary コール結果取得API
     * @param {number} callResultId コール結果ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CallResultApiInterface
     */
    getCallResult(callResultId: number, options?: AxiosRequestConfig): AxiosPromise<CallResult>;

}

/**
 * Request parameters for getCallResult operation in CallResultApi.
 * @export
 * @interface CallResultApiGetCallResultRequest
 */
export interface CallResultApiGetCallResultRequest {
    /**
     * コール結果ID
     * @type {number}
     * @memberof CallResultApiGetCallResult
     */
    readonly callResultId: number
}

/**
 * CallResultApi - object-oriented interface
 * @export
 * @class CallResultApi
 * @extends {BaseAPI}
 */
export class CallResultApi extends BaseAPI implements CallResultApiInterface {
    /**
     * コール結果取得API
     * @summary コール結果取得API
     * @param {CallResultApiGetCallResultRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CallResultApi
     */
    public getCallResult(requestParameters: CallResultApiGetCallResultRequest, options?: AxiosRequestConfig) {
        return CallResultApiFp(this.configuration).getCallResult(requestParameters.callResultId, options).then((request) => request(this.axios, this.basePath));
    }
}
