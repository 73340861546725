import ApiService, { ApiArguments } from '@/api/api_service';
import {
  CustomFieldValueApi,
  CustomFieldValueApiDeleteCallTargetCustomFieldValueRequest,
  CustomFieldValueApiPostCallTargetCustomFieldValueRequest,
} from '@/api/openapi';

export class CustomFieldValueApiService extends ApiService {
  api = new CustomFieldValueApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true);
  }

  async postCallTargetCustomFieldValue({
    request,
    options,
    errorHandlers,
  }: ApiArguments<CustomFieldValueApiPostCallTargetCustomFieldValueRequest>) {
    return this.checkResponse<void>(
      this.api.postCallTargetCustomFieldValue(request, options),
      errorHandlers,
    );
  }

  async deleteCallTargetCustomFieldValue({
    request,
    options,
    errorHandlers,
  }: ApiArguments<CustomFieldValueApiDeleteCallTargetCustomFieldValueRequest>) {
    return this.checkResponse<void>(
      this.api.deleteCallTargetCustomFieldValue(request, options),
      errorHandlers,
    );
  }
}
