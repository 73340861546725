import { Ref } from 'vue';
import BInput from '@/plugins/biscuet-materials/components/atoms/BInput.vue';

const useInsertText = (bInputRef: Ref<InstanceType<typeof BInput> | null>, target: Ref<string>) => {
  const insertText = (text: string) => {
    const htmlInput: HTMLInputElement | HTMLTextAreaElement = bInputRef.value?.getInputRef();
    if (htmlInput == null) return;

    const selectionStart = htmlInput.selectionStart;
    const selectionEnd = htmlInput.selectionEnd;
    const replaced = `${target.value?.slice(0, selectionStart) || ''}${text}${target.value?.slice(selectionEnd) || ''}`;
    target.value = replaced;
  };

  return {
    insertText,
  };
};

export {
  useInsertText,
};
