<template>
  <DateTimeFilterCommon
    :is-custom="isCustom"
    :is-display-only="isDisplayOnly"
    :key-name="keyName"
    :title="title"
    :filter-param="filterParam"
  />
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import DateTimeFilterCommon, { TFilterParam, TCommonProps } from './DateTimeFilterCommon.vue';

type TProps = TCommonProps & {
  leadListFilterParams: {
    [key: string]: TFilterParam;
  };
};

const props = withDefaults(defineProps<TProps>(), {
  isDisplayOnly: false,
});

const filterParam = computed<TFilterParam>(() => {
  return props.leadListFilterParams[props.keyName];
});
</script>
