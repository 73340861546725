<template>
  <div class="miitel">
    <div
      v-show="internalMiitelWidgetVisible"
      id="miitelWindow"
      :class="classes"
    >
      <BLayout
        class="miitel-header"
        align-center
      >
        <BTooltip
          top
          :content="$t('general.close.text')"
          :disabled="isCalling"
        >
          <BBtn
            :disabled="isCalling"
            size="small"
            fab
            text
            @click="closeWidget"
          >
            <BIcon size="small">
              close
            </BIcon>
          </BBtn>
        </BTooltip>
        <BBtn
          size="small"
          fab
          text
          @click="minimizeWidget"
        >
          <BIcon
            v-if="!isMinimize"
            size="small"
          >
            minimize
          </BIcon>
          <BIcon
            v-else,
            size="small"
          >
            maximize
          </BIcon>
        </BBtn>
        <div
          v-if="callingTime"
          class="call-status"
        >
          {{ callStatusText }} : {{ callingTime }}
        </div>
      </BLayout>
      <div class="miitel-content" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapWaitingActions } from 'vue-wait';
import { mapGetters } from 'vuex';
import { format, getDiff, newDate } from '@/utils/date-time';

export default defineComponent({
  props: {
    companyId: {
      type: String,
      default: '',
    },
    accessKey: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      miitelScript: null,
      existIframe: false,
      isMinimize: false,
      sequenceId: null,
      callingTime: null,
      callTimer: null,
      callStatusText: null,
      timerObj: null,
    };
  },
  computed: {
    ...mapGetters('userUi', [
      'miitelWidgetVisible',
      'isTargetMenuVisible',
    ]),
    ...mapGetters('miitel', [
      'currentState',
      'authInfo',
      'isCalling',
      'callInfo',
      'isInbound',
    ]),
    currentWidgetStatus: {
      get () {
        return this.currentState.currentWidgetStatus;
      },
      set (newVal) {
        this.changeState({ currentWidgetStatus: newVal });
      },
    },
    internalMiitelWidgetVisible: {
      get () {
        return this.miitelWidgetVisible;
      },
      set (newVal) {
        if (this.miitelWidgetVisible !== newVal) this.setMiitelWidgetVisibleAction(newVal);
        if (newVal === true) {
          this.handleOpenWidget();
        }
      },
    },
    miitelElemIds () {
      return ['miitelPhoneIFrameOuter', 'miitelPhoneIFrameButton'];
    },
    isMiitelAuthInvalid () {
      return !this.companyId || !this.accessKey;
    },
    isMiitelDisabled () {
      return this.internalMiitelWidgetVisible || this.isMiitelAuthInvalid;
    },
    classes () {
      return {
        'menu-open': this.isTargetMenuVisible,
        'minimize': this.isMinimize,
      };
    },
  },
  watch: {
    isCalling (newVal) {
      if (newVal && !this.isInbound) {
        this.setTargetCallResultFormVisibleAction(true);
      }
    },
  },
  created () {
    // フィードバックURLの生成等で利用するため(BMiitelCallButton)
    this.setAuthInfoAction({ companyId: this.companyId, accessKey: this.accessKey });
    // miitelの初期化処理
    // 1. windowにmiitelWidgetを設定
    // 2. miitel jsライブラリを埋め込み(読み込み)
    // 3. widgetのコアであるiframeを移動
    window.miitelWidget = typeof window.miitelWidget === 'function' ? window.miitelWidget : function (k, v) {
      window.miitelWidget.conf = window.miitelWidget.conf || {};
      window.miitelWidget.conf[k] = v;
    };
    window.miitelWidget('company_id', this.companyId);
    window.miitelWidget('access_key', this.accessKey);
    window.miitelWidget('onReceiveMessage', () => {});

    this.miitelScript = document.createElement('script');
    this.miitelScript.setAttribute('src', 'https://api.miitel.jp/static/widget/v1.js');
    this.miitelScript.async = true;
    document.body.appendChild(this.miitelScript);

    this.setHandlers();
  },
  mounted () {
    this.timerObj = this.$setInterval(() => {
      const miitelIframe = document.getElementById('miitelPhoneIframe');
      if (miitelIframe !== null) {
        this.existIframe = true;
        miitelIframe.style.display = 'block';
        const miitelWindow = document.querySelector('#miitelWindow .miitel-content');
        if (miitelWindow) miitelWindow.appendChild(miitelIframe);
        this.$clearInterval(this.timerObj);
      }
    }, 100);
  },
  unmounted () {
    // このcomponentに影響を閉じるために、createdでページ全体に反映したものを削除
    if (typeof window.miitelWidget === 'function') {
      window.miitelWidget('onChangeReadyState', () => {});
      window.miitelWidget('onReceiveSequenceId', () => {});
      window.miitelWidget('onReceiveMessage', () => {});
      window.miitelWidget('adjustButtonPosition', () => {});
      window.miitelWidget('onDialBegin', () => {});
      window.miitelWidget('onCallBegin', () => {});
      window.miitelWidget('onCallEnd', () => {});
      window.miitelWidget('onReceiveMessage', () => {});
    }

    if (window.miitelWidget) delete window.miitelWidget;

    this.miitelElemIds.forEach(id => {
      this.removeChild(document.getElementById(id));
    });

    this.removeChild(this.miitelScript);

    this.setMiitelWidgetVisibleAction(false);
    this.setIsCallingAction(false);
  },
  methods: {
    ...mapWaitingActions('miitel', {
      setCurrentStateAction: 'setCurrentStateWait',
      setAuthInfoAction: 'setAuthInfoWait',
      setIsCallingAction: 'setIsCallingWait',
      setIsInboundAction: 'setIsInboundWait',
      setStartedAtAction: 'setStartedAtWait',
      setEndedAtAction: 'setEndedAtWait',
      setUrlAction: 'setUrlWait',
      setConnectedAction: 'setConnectedWait',
      setCallInfoResetAction: 'setCallInfoResetWait',
    }),
    ...mapWaitingActions('userUi', {
      setMiitelWidgetVisibleAction: 'setMiitelWidgetVisibleWait',
      setTargetCallResultFormVisibleAction: 'setTargetCallResultFormVisibleWait',
    }),
    removeChild (node) {
      if (node != null || node != undefined)  {
        try {
          document.body.removeChild(node);
        } catch (e) {
          console.error(e);
        }
      }
    },
    handleOpenWidget () {
      if (this.canOpenWidget()) this.internalMiitelWidgetVisible = true;
    },
    canOpenWidget () {
      if (this.existIframe === false) {
        this.$bitterAlert.show({
          title: this.$t('general.miitel'),
          text: this.$t('miitel.setupError'),
          closeOnClickOutside: true,
          buttonsCancel: false,
        });
        this.internalMiitelWidgetVisible = false;
        return false;
      } else {
        return true;
      }
    },
    closeWidget () {
      if (this.isCalling) return false;
      this.currentWidgetStatus = null;
      this.internalMiitelWidgetVisible = false;
      this.isMinimize = false;
      this.callingTime = null;
      this.callStatusText = null;
    },
    changeState ({ currentWidgetStatus }) {
      this.setCurrentStateAction({
        currentWidgetStatus,
      });
    },
    minimizeWidget () {
      this.isMinimize = !this.isMinimize;
    },
    dialBegin () {
      this.setIsCallingAction(true);
      if (this.isInbound) return;
      // 以下の処理でエラーが出る可能性あるため、一旦コメントアウト
      // this.callStatusText = this.$t('miitel.calling')
      this.callStatusText = '呼び出し中';
      this.setCallInfoResetAction();
      this.setStartedAtAction(new Date());
      this.callingTime = '00:00';
    },
    callBegin () {
      if (this.isInbound) return;
      // 以下の処理でエラーが出る可能性あるため、一旦コメントアウト
      // this.callStatusText = this.$t('miitel.busy')
      this.callStatusText = '通話中';
      this.setConnectedAction(true);
      const dateTo = newDate();
      this.callTimer = setInterval(this.getCallingTime, 1000, dateTo);
    },
    callEnd () {
      if (this.isInbound) {
        // NOTE: 受信モードをoffに戻しておく
        this.setIsInboundAction(false);
        this.setIsCallingAction(false);
      } else {
        // 以下の処理でエラーが出る可能性あるため、一旦コメントアウト
        // this.callStatusText = this.$t('miitel.end')
        this.callStatusText = '終了';
        const miitelUrl = this.sequenceId ? `https://${this.authInfo.companyId}.miitel.jp/app/calls/${this.sequenceId}` : 'エラーが発生しました';
        this.setUrlAction(miitelUrl);
        this.setEndedAtAction(new Date());
        this.setIsCallingAction(false);
        clearInterval(this.callTimer);
      }
    },
    getCallingTime (dateTo) {
      this.callingTime = format(getDiff(dateTo), 'mm:ss');
    },
    receiveSequenceId (e) {
      this.sequenceId = e.sequenceId;
    },
    onReceiveCall () {
      this.setCallInfoResetAction();
      this.setIsInboundAction(true);
    },
    changeReadyState (e) {
      this.currentWidgetStatus = e.state;
    },
    setHandlers () {
      // 発信可否の状態に変更が生じたとき
      window.miitelWidget('onChangeReadyState', this.changeReadyState);
      // 受信
      window.miitelWidget('onReceiveCall', this.onReceiveCall);
      // sequenceIdが一度取得できたらクリア
      window.miitelWidget('onReceiveSequenceId', this.receiveSequenceId);
      // コール開始
      window.miitelWidget('onDialBegin', this.dialBegin);
      // 着電
      window.miitelWidget('onCallBegin', this.callBegin);
      // コール終了
      window.miitelWidget('onCallEnd', this.callEnd);
    },
  },
});
</script>

<style lang="scss">
  #miitelPhoneIFrameOuter, #miitelPhoneIFrameButton {
    display: none !important;
  }

  #miitelPhoneIframe {
    transform-origin: top left;
    transform: scale(1);
  }

  #miitelWindow {
    position: absolute;
    top: calc(100% - 525px);
    left: -200px;
    z-index: 2;
    box-shadow: $boxshadow-base;
    transition: all 400ms $standard-easing;

    iframe {
      height: 480px;
    }

    &.menu-open {
      transition: all 600ms $standard-easing;
      left: -520px;
    }

    body {
      background: #fff;
    }

    &.minimize {
      top: calc(100% - 52px);

      .miitel {
        &-content {
          display: none;
        }
      }
    }

    .call-status {
      margin-left: $basespace-100;
      font-size: $fontsize-100;
    }
  }

  .miitel {
    &-header {
      width: 200px;
      background: rgba($concrete, 0.8);
      padding: $basespace-100;
    }

    &-content {
      background: $bgcolor-dark;
    }
  }
</style>
