/* global FS */
import AuthService from '@/services/auth_service';

const bugsnagConfig = {
  apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
  enabledReleaseStages: ['production'],
  releaseStage: process.env.NODE_ENV,
  logger: null,
  onError: event => {
    const as = new AuthService();
    event.addMetadata('account', {
      uid: as.currentUser().uid,
      role: as.currentRole(),
    });
  },
};

export default bugsnagConfig;
