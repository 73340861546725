<template>
  <BModal
    :modal-name="modalName"
    full
  >
    <LeadOverwriteModal
      :title="$t('overwrite.excel.title')"
      :modal-name="modalName"
      accept-data-type=".xlsx"
    />
  </BModal>
</template>

<script>
import LeadOverwriteModal from '@/components/organisms/user/leadList/modal/LeadOverwriteModal.vue';

export default {
  components: {
    LeadOverwriteModal,
  },
  computed: {
    modalName() {
      return 'ExcelOverwriteModal';
    },
  },
};
</script>
