<template>
  <div class="area-inner">
    <Transition name="el-fade-in-linear">
      <div
        v-if="!draftItemLoading && !nextActionLoading && !reserveItemLoading"
        class="task-area"
      >
        <DraftItemArea
          v-if="!draftItemLoading && draftItems.length > 0"
          class="mb-300"
          :draft-items="draftItems"
          @click:direct-mail-confirm="handleClickConfirmDirectMail"
          @click:sequence-action="handleClickSequenceAction"
          @after-delete-direct-mail="handleAfterDeleteDirectMail"
        />
        <NextActionArea
          v-if="!nextActionLoading && existNextAction"
          class="mb-300"
          v-bind="nextActionProps"
          @update="updateNextAction"
          v-on="nextActionFunctions"
          @click:direct-mail-confirm="handleClickConfirmDirectMail"
          @click:sequence-action="handleClickSequenceAction"
          @after-delete-next-action="handleAfterDeleteNextAction"
        />
        <ReserveItemArea
          v-if="!reserveItemLoading && reserveItems.length > 0"
          :reserve-items="reserveItems"
          :key-of-get-reserve-items-wait="keyOfGetReserveItemsWait"
          @click:direct-mail-confirm="handleClickConfirmDirectMail"
          @click:sequence-action="handleClickSequenceAction"
          @after-delete-direct-mail="handleAfterDeleteDirectMail"
        />
      </div>
    </Transition>
    <div class="history-area">
      <BPlaceholder
        v-if="historyLoading"
        v-loading="true"
        height="50vh"
        element-loading-background="transparent"
      />
      <template v-else>
        <TransitionGroup
          v-show="!historyLoading && existHistory"
          name="list"
          tag="div"
        >
          <div
            v-if="leadHistories.length > 0"
            v-infinite-scroll="load"
            class="column layout"
            infinite-scroll-disabled="scrollDisabled"
            infinite-scroll-distance="20"
          >
            <div
              v-for="(historyItem, index) in filterLeadHistories"
              :key="`${historyItem.type}-${historyItem.id}`"
            >
              <CallResultCard
                v-if="historyItem.type === 'callResult'"
                class="mb-50"
                :call-result="historyItem"
                :expand="index === 0"
                :data-test="`${historyItem.type}-${historyItem.id}`"
                v-on="callResultFunctions"
              />
              <NextActionCard
                v-if="historyItem.type === 'nextAction'"
                class="mb-50"
                :next-action="historyItem"
                v-on="nextActionFunctions"
              />
              <OpportunityCard
                v-if="historyItem.type === 'opportunity'"
                :key="'opportunity' + historyItem.id"
                class="mb-50"
                :call-target="callTarget"
                :opportunity="historyItem"
                :expand="index === 0"
                v-on="opportunityFunctions"
              />
              <FreeActionCard
                v-if="historyItem.type === 'freeAction'"
                class="mb-50"
                :free-action="historyItem"
                :expand="index === 0"
                v-on="freeActionFunctions"
              />
              <MailCard
                v-if="mailTypeHistory(historyItem)"
                class="mb-50"
                :mail="historyItem"
                :expand="index === 0"
                @reply-to-received-mail="handleReplyToReceivedMail($event)"
              />
              <UnsubscribedCard
                v-if="historyItem.type === 'unsubscribeCallTarget'"
                class="mb-50"
                :unsubscribe-item="historyItem"
                :expand="index === 0"
              />
              <MailEventCard
                v-if="historyItem.type === 'sendgridEvent'"
                class="mb-50"
                :sendgrid-event="historyItem"
                :expand="index === 0"
              />
              <FormResultCard
                v-if="historyItem.type === 'formResult'"
                class="mb-50"
                :form-result="historyItem"
                :expand="index === 0"
              />
              <BoxilLeadSurveyCard
                v-if="historyItem.type === 'boxilLeadSurvey'"
                class="mb-50"
                :boxil-lead-survey="historyItem"
                :expand="index === 0"
              />
              <BoxilLeadDownloadCard
                v-if="historyItem.type === 'boxilLeadDownload'"
                class="mb-50"
                :boxil-lead-download="historyItem"
                :expand="index === 0"
              />
              <LeadStageCard
                v-if="historyItem.type === 'leadStageHistory'"
                class="mb-50"
                :lead-stage-history="historyItem"
                :expand="index === 0"
              />
              <SequenceInstanceCard
                v-if="historyItem.type === 'sequenceInstanceHistory'"
                class="mb-50"
                :sequence-instance-history="historyItem"
                :expand="index === 0"
              />
              <SequenceStepInstanceCard
                v-if="historyItem.type === 'sequenceStepInstanceHistory'"
                class="mb-50"
                :sequence-step-instance-history="historyItem"
                :expand="index === 0"
              />
            </div>
          </div>
          <BLayout
            v-if="scrollDisabled && $wait.is('infiniteLoadingWait')"
            class="py-200"
            justify-center
          >
            <BElementIcon
              v-loading="true"
              name="loading"
            />
          </BLayout>
        </TransitionGroup>
        <div v-if="!pageLoading && !existHistory">
          <BEmptyBox
            class="pt-600 pb-600"
            display-only
          >
            <div>{{ $t('callHistory.emptyData') }}</div>
          </BEmptyBox>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapWaitingActions } from 'vue-wait';
import { mapGetters } from 'vuex';
import DraftItemArea from '@/components/organisms/user/lead/DraftItemArea.vue';
import BoxilLeadDownloadCard from '@/components/organisms/user/lead/history/BoxilLeadDownloadCard.vue';
import BoxilLeadSurveyCard from '@/components/organisms/user/lead/history/BoxilLeadSurveyCard.vue';
import CallResultCard from '@/components/organisms/user/lead/history/CallResultCard.vue';
import FormResultCard from '@/components/organisms/user/lead/history/FormResultCard.vue';
import FreeActionCard from '@/components/organisms/user/lead/history/FreeActionCard.vue';
import LeadStageCard from '@/components/organisms/user/lead/history/LeadStageCard.vue';
import MailCard from '@/components/organisms/user/lead/history/MailCard.vue';
import MailEventCard from '@/components/organisms/user/lead/history/MailEventCard.vue';
import NextActionCard from '@/components/organisms/user/lead/history/NextActionCard.vue';
import OpportunityCard from '@/components/organisms/user/lead/history/OpportunityCard.vue';
import SequenceInstanceCard from '@/components/organisms/user/lead/history/SequenceInstanceCard.vue';
import SequenceStepInstanceCard from '@/components/organisms/user/lead/history/SequenceStepInstanceCard.vue';
import UnsubscribedCard from '@/components/organisms/user/lead/history/UnsubscribedCard.vue';
import NextActionArea from '@/components/organisms/user/lead/NextActionArea.vue';
import ReserveItemArea from '@/components/organisms/user/lead/ReserveItemArea.vue';
import errorHandler from '@/mixins/error_handler';

export default {
  components: {
    BoxilLeadSurveyCard,
    BoxilLeadDownloadCard,
    DraftItemArea,
    NextActionArea,
    CallResultCard,
    NextActionCard,
    OpportunityCard,
    FreeActionCard,
    MailCard,
    MailEventCard,
    FormResultCard,
    UnsubscribedCard,
    LeadStageCard,
    SequenceInstanceCard,
    SequenceStepInstanceCard,
    ReserveItemArea,
  },
  mixins: [errorHandler],
  props: {
    callTarget: Object,
    callTargetId: Number,
    isEditing: Boolean,
    pageLoading: Boolean,
    draftItems: Array,
    reserveItems: Array,
    keyOfGetReserveItemsWait: String,
  },
  emits: [
    'selectDirectMail',
    'replyToReceivedMail',
    'afterDeleteDirectMail',
    'deleteLeadHistory',
    'fetchNextActionItems',
    'fetchSequenceItems',
    'clearSelectedSequenceInstance',
    'selectSequenceInstance',
    'click:callResultEdit',
  ],
  data() {
    return {
      timeObj: null,
      saveSfOpportunityErrors: [],
      displayCount: 20,
      increaseNumber: 20,
      scrollDisabled: false,
    };
  },
  computed: {
    ...mapGetters('user', [
      'nextActions',
      'leadHistories',
      'currentUser',
    ]),
    existNextAction () {
      return this.nextActions.length > 0;
    },
    existHistory () {
      return this.leadHistories.length > 0;
    },
    draftItemLoading () {
      return this.$wait.is('getDraftItemsWait');
    },
    nextActionLoading () {
      return this.$wait.is('getNextActionsWait');
    },
    reserveItemLoading () {
      return this.$wait.is(this.keyOfGetReserveItemsWait);
    },
    historyLoading () {
      return this.$wait.is([
        'getLeadHistoriesWait',
        'deleteCallResultWait',
        'deleteOpportunityWait',
        'deleteFreeActionWait',
      ]);
    },
    nextActionProps () {
      return {
        nextActions: this.nextActions,
        callTarget: this.callTarget,
      };
    },
    nextActionFunctions () {
      return {
        'done-cancel': this.handleDoneCancelNextAction,
        'click-edit': this.editNextAction,
        'click-delete': this.deleteNextAction,
        'click-done': this.handleDoneNextAction,
        'click-drawer-close': this.handleNextActionDrawerClose,
      };
    },
    callResultFunctions () {
      return {
        'click-edit': this.editCallResult,
        'click-delete': this.deleteCallResult,
      };
    },
    opportunityFunctions () {
      return {
        'click-edit': this.editOpportunity,
        'click-delete': this.deleteOpportunity,
      };
    },
    freeActionFunctions () {
      return {
        'click-delete': this.deleteFreeAction,
      };
    },
    filterLeadHistories() {
      return this.leadHistories.slice(0, this.displayCount);
    },
  },
  watch: {
    leadHistories() {
      this.displayCount = 20;
      this.scrollDisabled = false;
    },
  },
  created() {
    this.timeObj = this.$setInterval(() => {
      this.getLeadHistoriesBackgroundAction({ callTargetId: this.callTargetId });
    }, 90000);
  },
  beforeUnmount() {
    this.$clearInterval(this.timeObj);
  },
  unmounted() {
    this.displayCount = 20;
    this.scrollDisabled = false;
  },
  methods: {
    ...mapWaitingActions('user', {
      getCallTargetsBackgroundAction: { action: 'getCallTargetsAction', loader: 'getCallTargetsBackgroundWait' },
      getLeadHistoriesBackgroundAction: { action: 'getLeadHistoriesAction', loader: 'getLeadHistoriesBackgroundWait' },
      getNextActionsAction: 'getNextActionsWait',
      updateNextActionAction: 'updateNextActionWait',
      doneNextAction: 'doneNextActionWait',
      doneCancelNextAction: 'doneCancelNextActionWait',
      deleteCallResultAction: 'deleteCallResultWait',
      deleteOpportunityAction: 'deleteOpportunityWait',
      deleteFreeActionAction: 'deleteFreeActionWait',
      deleteNextActionAction: 'deleteNextActionWait',
    }),
    ...mapWaitingActions('userUi', {
      setSelectedOpportunityAction: 'setSelectedOpportunityWait',
      setSelectedNextActionAction: 'setSelectedNextActionWait',
      setTargetOpportunityFormVisibleAction: 'setTargetOpportunityFormVisibleWait',
      setTargetSingleNextActionFormVisibleAction: 'setTargetSingleNextActionFormVisibleWait',
    }),
    async updateNextAction (nextAction) {
      await this.updateNextActionAction({
        callTargetId: this.callTargetId,
        nextActionId: nextAction.id,
        body: { nextAction },
        errorHandlers: {
          422: this.defaultHandler,
        },
      });
      this.$emit('fetchNextActionItems');
    },
    async handleDoneNextAction (nextAction) {
      await this.doneNextAction({
        callTargetId: this.callTargetId,
        nextActionId: nextAction.id,
        errorHandlers: {
          422: this.defaultHandler,
        },
      });

      if (nextAction.sequenceStepInstance) {
        this.$emit('clearSelectedSequenceInstance');
        this.$emit('fetchSequenceItems');
      } else {
        this.$emit('fetchNextActionItems');
      }
    },
    async handleDoneCancelNextAction (nextAction) {
      await this.doneCancelNextAction({
        callTargetId: this.callTargetId,
        nextActionId: nextAction.id,
        errorHandlers: {
          422: this.defaultHandler,
        },
      });
      this.$emit('fetchNextActionItems');
    },
    editCallResult (callResult) {
      this.$emit('click:callResultEdit', callResult);
    },
    editOpportunity (opportunity) {
      this.setSelectedOpportunityAction(opportunity);
      this.setTargetOpportunityFormVisibleAction(true);
    },
    handleClickConfirmDirectMail(directMail) {
      this.$emit('selectDirectMail', directMail);
    },
    handleAfterDeleteDirectMail() {
      this.$emit('afterDeleteDirectMail');
    },
    handleReplyToReceivedMail(receivedMailId) {
      this.$emit('replyToReceivedMail', receivedMailId);
    },
    handleAfterDeleteNextAction() {
      this.$emit('fetchNextActionItems');
    },
    editNextAction (nextAction) {
      this.setSelectedNextActionAction(nextAction);
      this.setTargetSingleNextActionFormVisibleAction(true);
    },
    handleNextActionDrawerClose () {
      this.setSelectedNextActionAction({});
      this.setTargetSingleNextActionFormVisibleAction(false);
    },
    handleClickSequenceAction(sequenceInstanceId) {
      this.$emit('selectSequenceInstance', sequenceInstanceId);
    },
    async deleteCallResult (callResultId) {
      const check = await this.$bitterAlert.show({
        title: this.$t('callHistory.title'),
        text: this.$t('general.confirm.of', { action: this.$t('general.delete.text') }),
      });
      if (!check) { return; }
      await this.deleteCallResultAction({
        callResultId,
        callTargetId: this.callTargetId,
      });

      await this.getNextActionsAction({ callTargetId: this.callTargetId });
      this.$emit('deleteLeadHistory');
    },
    async deleteOpportunity (opportunityId) {
      const check = await this.$bitterAlert.show({
        title: this.$t('callHistory.title'),
        text: this.$t('general.confirm.of', { action: this.$t('general.delete.text') }),
      });
      if (!check) { return; }
      await this.deleteOpportunityAction({
        opportunityId,
        callTargetId: this.callTargetId,
      });

      this.$emit('deleteLeadHistory');
    },
    async deleteFreeAction (freeActionId) {
      const check = await this.$bitterAlert.show({
        title: this.$t('callHistory.title'),
        text: this.$t('general.confirm.of', { action: this.$t('general.delete.text') }),
      });
      if (!check) { return; }
      await this.deleteFreeActionAction({
        freeActionId,
        callTargetId: this.callTargetId,
      });

      this.$emit('deleteLeadHistory');
    },
    async deleteNextAction (nextActionId) {
      const check = await this.$bitterAlert.show({
        title: this.$t('nextAction.title'),
        text: this.$t('general.confirm.of', { action: this.$t('general.delete.text') }),
      });
      if (!check) { return; }
      await this.deleteNextActionAction({
        nextActionId,
        callTargetId: this.callTargetId,
      });
      this.$emit('fetchNextActionItems');
    },
    mailTypeHistory (historyItem) {
      return historyItem.type === 'sendMailBulk' || historyItem.type === 'sendMailDirect' || historyItem.type === 'receiveMail';
    },
    load() {
      if (this.leadHistories.length <= this.displayCount) return;
      this.$wait.start('infiniteLoadingWait');
      this.scrollDisabled = true;
      setTimeout(() => {
        this.displayCount += this.increaseNumber;
        this.$wait.end('infiniteLoadingWait');
        this.scrollDisabled = false;
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.area-inner {
  min-width: 820px;
  width: 100%;
  min-width: 600px;
  margin: 0 auto;
}
.history-area {
  padding: $basespace-300 $basespace-600 $basespace-600;
}

$tasks: 'next-action', 'reserve-item', 'draft-item';
@each $task in $tasks {
  .#{$task}-area {
    background-color: $bgcolor-white;
    padding: $basespace-100 0;

    :deep(.#{$task}-task) {
      transition: all 300ms $standard-easing;

      &:hover {
        background: $bgcolor-base;
        cursor: pointer;
      }
    }
  }
}
</style>
