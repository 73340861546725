import ApiBase from '@/api/base';

export default {
  getAdmins () {
    return ApiBase.get(`/admin/admins`);
  },
  createAdmin (args) {
    return ApiBase.post(`/admin/admins`, args);
  },
  updateAdmin (args) {
    const { id } = args;
    return ApiBase.put(`/admin/admins/${id}`, args);
  },
  deleteAdmin (args) {
    const { id } = args;
    return ApiBase.delete(`/admin/admins/${id}`, args);
  },
};
