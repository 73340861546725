import { readonly, ref } from 'vue';
import { SendgridSubUserApiService } from '@/api/admin/resources/sendgrid_sub_user';
import type { SendgridSubUser } from '@/api/openapi';
import { useWait } from '../vue-wait';

const useSendgridSubUser = (getId: () => number | undefined) => {
  const { doActionWithWait } = useWait();

  const sendgridSubUser = ref<SendgridSubUser | null>(null);

  const fetchSendgridSubUser = async () => {
    if (getId() == null) return;
    await doActionWithWait('fetchSendgridSubUserByIdWait', async () => {
      const api = new SendgridSubUserApiService();
      const { data } = await api.getSendgridSubUser({
        request: {
          sendgridSubUserId: getId(),
        },
      });
      sendgridSubUser.value = data;
    });
  };

  return {
    fetchSendgridSubUser,
    sendgridSubUser: readonly(sendgridSubUser),
  };
};

export {
  useSendgridSubUser,
};
