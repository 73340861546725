<template>
  <div class="b-quill-content-editor">
    <QuillEditor
      v-bind="editorOption"
      ref="myQuillEditor"
      v-model:content="internalContent"
      @blur="onEditorBlur($event)"
      @focus="onEditorFocus($event)"
      @ready="onEditorReady($event)"
    >
      <template #toolbar>
        <div id="toolbar">
          <span class="ql-formats">
            <select class="ql-size">
              <template v-for="fontSize in fontSizes">
                <option
                  v-if="fontSize == null"
                  :key="'A' + fontSize"
                  selected
                />
                <option
                  v-else
                  :key="'B' + fontSize"
                  :value="fontSize"
                />
              </template>
            </select>
          </span>
          <span class="ql-formats">
            <button
              type="button"
              class="ql-bold"
            />
            <button
              type="button"
              class="ql-italic"
            />
            <button
              type="button"
              class="ql-underline"
            />
            <button
              type="button"
              class="ql-strike"
            />
          </span>
          <span class="ql-formats">
            <select class="ql-color" />
            <select class="ql-background" />
          </span>
          <span class="ql-formats">
            <button
              type="button"
              class="ql-list"
              value="ordered"
            />
            <button
              type="button"
              class="ql-list"
              value="bullet"
            />
          </span>
          <span class="ql-formats">
            <select class="ql-align" />
          </span>
          <span class="ql-formats">
            <button
              type="button"
              class="ql-link"
            />
          </span>
          <span class="ql-formats">
            <button
              type="button"
              class="ql-divider"
            >
              <BCustomIcon
                size="medium"
                icon-class="b-horizontal-line"
              />
            </button>
          </span>
          <span class="ql-formats">
            <button
              type="button"
              class="ql-image"
            />
          </span>
          <span
            v-if="enableAttachment"
            class="ql-formats"
          >
            <BBtn @click="handleAttachFileClick">
              <BIcon>attach_file</BIcon>
            </BBtn>
          </span>
        </div>
      </template>
    </QuillEditor>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';

import { QuillEditor } from '@vueup/vue-quill';
import Quill from 'quill';

import { Range } from 'quill/core/selection';
import BlotFormatter, { Action, DeleteAction, ResizeAction, ImageSpec } from 'quill-blot-formatter';
import ApiBase from '@/api/base';
import Api from '@/api/user';
import errorHandler from '@/mixins/error_handler';

export const makeQuillContent = (plainText) => plainText.split('\n').map((line) => `<div>${line}</div>`).join('');

export default {
  name: 'QuillContentEditor',
  components: {
    QuillEditor,
  },
  mixins: [errorHandler],
  props: {
    content: {
      type: String,
      required: true,
    },
    enableAttachment: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'edit',
    'blue',
    'focus',
    'set-quill',
    'click-attach-file',
  ],
  setup() {
    const fontSizes = ['x-small', null, 'large', 'xx-large'];
    const maxAttachmentsByteSize = 10000000;
    const maxAttachmentsByteSizeInMega = maxAttachmentsByteSize / 1000000;

    return {
      fontSizes,
      maxAttachmentsByteSize,
      maxAttachmentsByteSizeInMega,
    };
  },
  computed: {
    editorOption() {
      const Keyboard = Quill.import('modules/keyboard');
      return {
        theme: 'snow',
        contentType: 'html',
        toolbar: '#toolbar',
        placeholder: this.$t('mail.contentPlaceholder'),
        readOnly: false,
        modules: [
          {
            name: 'blotFormatter',
            module: BlotFormatter,
            options: this.quillFormatterUploaderOptions(),
          },
          {
            name: 'imageUploader',
            module: ImageUploader,
            options: { upload: this.upload },
          },
          {
            name: 'keyboard',
            module: Keyboard,
            options: { bindings: this.quillKeyboardBindings() },
          },
        ],
        options: {
          bounds: '.b-quill-content-editor div:nth-child(2)',
        },
      };
    },
    editor() {
      return this.$refs.myQuillEditor?.getQuill();
    },
    internalContent: {
      get() {
        return this.content;
      },
      set(newVal) {
        this.$emit('edit', newVal);
      },
    },
  },
  created() {
    this.setupQuillEditor();
  },
  methods: {
    quillKeyboardBindings() {
      const Delta = Quill.import('delta');
      return {
        'divider enter': {
          key: 'Enter',
          collapsed: true,
          format: ['divider'],
          suffix: /^$/,
          handler (range, context) {
            const [line, offset] = this.quill.getLine(range.index);
            const delta = new Delta()
              .retain(range.index)
              .insert('\n', context.format)
              .retain(line.length() - offset - 1)
              .retain(1, { divider: null });
            this.quill.updateContents(delta, Quill.sources.USER);
            this.quill.setSelection(range.index + 1, Quill.sources.SILENT);
            this.quill.scrollIntoView();
          },
        },
      };
    },
    handleAttachFileClick() {
      this.$emit('click-attach-file');
    },
    async upload(file) {
      const totalFileSize = file.size + this.inlineImagesSize();
      if (totalFileSize >= this.maxAttachmentsByteSize) {
        this.showExceededInlineAttachmentLimitError();
        throw new Error();
      }
      const result = await Api.createMailImageFileStorageAccessUrl({
        body: { fileName: file.name },
      });
      await ApiBase.unauthorizedPut(result.data.url, {
        body: file,
        header: { 'Content-Type': file.type },
        errorHandlers: {
          403: this.fileUploadErrorHandler,
        },
      });
      return result.data.publicUrl;
    },
    showExceededInlineAttachmentLimitError() {
      this.$bitterAlert.show({
        title: this.$t('general.error'),
        text: this.$t('mail.tooLargeAttachmentsByteSize', { attachTypeText: this.$t('mail.inlineAttached'), sizeInMega: this.maxAttachmentsByteSizeInMega }),
        closeOnClickOutside: true,
        buttonsCancel: false,
      });
    },
    inlineImagesSize() {
      return this.editor.getContents().ops.reduce((prev, current) => {
        if (current?.insert?.image == null) return prev;
        return prev + parseInt(current.attributes['data-size']);
      }, 0);
    },
    quillFormatterUploaderOptions() {
      class InputAction extends Action {

        onCreate() {
          document.addEventListener('keyup', this.onKeyUp.bind(this), true);
          this.formatter.quill.root.addEventListener('input', this.onKeyUp.bind(this), true);
        }

        onDestroy() {
          document.removeEventListener('keyup', this.onKeyUp);
          this.formatter.quill.root.removeEventListener('input', this.onKeyUp);
        }

        onKeyUp(e) {
          if (!this.formatter.currentSpec) {
            return;
          }
          if (e.key !== 'Delete' && e.key !== 'Backspace') {
            this.formatter.hide();
          }
        }
      }
      class ScrollAction extends Action {
        initialScrollTop = 0;

        onCreate() {
          this.formatter.overlay.style.marginTop = 0;
          this.initialScrollTop = this.formatter.quill.root.scrollTop;
          this.formatter.quill.root.addEventListener('scroll', this.onScroll.bind(this));
        }

        onDestroy() {
          this.formatter.overlay.style.marginTop = 0;
          this.initialScrollTop = 0;
          this.formatter.quill.root.removeEventListener('scroll', this.onScroll);
        }

        onScroll() {
          this.formatter.repositionOverlay();
        }
      }
      class LinkAction extends Action {

        constructor(formatter) {
          super(formatter);
        }

        onCreate() {
          const blot = Quill.find(this.formatter.currentSpec.getTargetElement());
          const index = this.formatter.quill.getIndex(blot);
          const LinkBlot = Quill.import('formats/link');
          let preview = '';
          if (blot.parent instanceof LinkBlot) {
            preview = LinkBlot.formats(blot.parent.domNode);
          }
          this.formatter.quill.theme.tooltip.preview.textContent = preview;
          this.formatter.quill.theme.tooltip.preview.setAttribute('href', preview);
          this.formatter.quill.theme.tooltip.linkRange = new Range(index, 1);
          this.formatter.quill.theme.tooltip.show();
          this.formatter.quill.theme.tooltip.position(this.formatter.quill.getBounds(new Range(index, 0)));
        }

        onDestroy() {
          this.formatter.quill.theme.tooltip.hide();
        }
      }
      class CustomImageSpec extends ImageSpec {
        constructor(formatter) {
          super(formatter);
          formatter.quill.off('selection-change', this.onSelectionChange);
          formatter.quill.on('selection-change', this.onSelectionChange.bind(this));
        }
        getActions() {
          return [ResizeAction, DeleteAction, InputAction, ScrollAction, LinkAction];
        }
        setSelection() {
          const blot = Quill.find(this.formatter.currentSpec.getTargetElement());
          const index = this.formatter.quill.getIndex(blot);
          this.formatter.quill.setSelection(index, 0);
        }
        onSelectionChange(range, oldRange, source) {
          if (range === null) {
            this.formatter.hide();
          }
        }
      }
      return {
        specs: [
          CustomImageSpec,
        ],
      };
    },
    setupQuillEditor() {
      this.registerAlignStyle();
      this.registerFontSizes();
      this.registerDivBlot();
      this.registerDividerBlot();
    },
    registerAlignStyle() {
      const AlignStyle = Quill.import('attributors/style/align');
      Quill.register(AlignStyle, true);
    },
    registerFontSizes() {
      const Size = Quill.import('attributors/style/size');
      Size.whitelist = this.fontSizes;
      Quill.register(Size, true);
    },
    registerDivBlot() {
      const Block = Quill.import('blots/block');
      Block.tagName = 'DIV';
      Quill.register(Block, true);
    },
    registerDividerBlot() {
      const DividerBase = Quill.import('blots/block');
      class DividerBlot extends DividerBase {
        static tagName = 'div';
        static blotName = 'divider';
        static className = 'ql-bc-divider';

        static create(value) {
          const node = super.create(value);
          node.setAttribute('style', 'border-bottom: 1px solid #d9dce1; line-height: normal; display: block; padding-bottom: 1em; margin-bottom: 1em;');
          return node;
        }

        static formats() {
          return true;
        }
      }
      Quill.register(DividerBlot, true);
    },
    onEditorBlur() {
      this.$emit('blue');
    },
    onEditorFocus() {
      this.$emit('focus');
    },
    onEditorReady(quill) {
      this.$emit('set-quill', quill);
    },
  },
};

// 「quill-image-uploader」を移植してカスタマイズ
// （上記ライブラリをimportして使うと、
//   クラスを継承してカスタマイズした時にエラーとなってしまうため移植して使用。）
// 参考）
// https://github.com/NoelOConnell/quill-image-uploader
const ImageBlot = Quill.import('formats/image');
class CustomImageBlot extends ImageBlot {
  static additionalAttributes = ['data-size'];
  static formats(domNode) {
    const rootFormats = super.formats(domNode);
    return this.additionalAttributes.reduce((formats, attribute) => {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, rootFormats);
  }
  format(name, value) {
    if (this.statics.additionalAttributes.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}
Quill.register('formats/image', CustomImageBlot);
const InlineBlot = Quill.import('blots/block');
class LoadingImage extends InlineBlot {
  static length = 2;
  static blotName = 'imageBlot';
  static className = 'image-uploading';
  static tagName = 'span';

  static create(src) {
    const node = super.create(src);
    if (src === true) return node;

    const image = document.createElement('img');
    image.setAttribute('src', src);
    node.appendChild(image);
    return node;
  }
  deleteAt(index, length) {
    super.deleteAt(index, length);
    this.cache = {};
  }
  static value(domNode) {
    const { src, custom } = domNode.dataset;
    return { src, custom };
  }
}
class ImageUploader {
  constructor(quill, options) {
    this.quill = quill;
    this.range = null;

    this.acceptType = options?.acceptType || '.jpg, .gif, .jpeg, .png';
    if (typeof options?.upload === 'function') {
      this.upload = options?.upload;
    }
    if (typeof options?.typeErrorHandler === 'function') {
      this.typeErrorHandler = options?.typeErrorHandler;
    }

    const toolbar = this.quill.getModule('toolbar');
    toolbar.addHandler('image', this.selectLocalImage.bind(this));

    this.handleDrop = this.handleDrop.bind(this);
    this.handlePaste = this.handlePaste.bind(this);

    this.quill.root.addEventListener('drop', this.handleDrop, false);
    this.quill.root.addEventListener('paste', this.handlePaste, false);

    this.registerLoadingBlot();
  }

  registerLoadingBlot() {
    Quill.register({ 'formats/imageBlot': LoadingImage });
  }

  selectLocalImage() {
    this.quill.focus();
    this.range = this.quill.getSelection();
    this.fileHolder = document.createElement('input');
    this.fileHolder.setAttribute('type', 'file');
    this.fileHolder.setAttribute('accept', this.acceptType);
    this.fileHolder.setAttribute('style', 'visibility:hidden');

    this.fileHolder.onchange = this.fileChanged.bind(this);

    document.body.appendChild(this.fileHolder);

    this.fileHolder.click();

    window.requestAnimationFrame(() => {
      document.body.removeChild(this.fileHolder);
    });
  }

  handleDrop(event) {
    event.stopPropagation();
    event.preventDefault();
    const file = event?.dataTransfer?.files?.[0];
    const IMAGE_MIME_REGEX = new RegExp(`^image/(${this.acceptType.replaceAll(',', '|').replaceAll(/( |\.)/ig, '')})$`, 'i');
    if (
      file != null
        && IMAGE_MIME_REGEX.test(file.type)
    ) {
      if (document.caretRangeFromPoint) {
        const selection = document.getSelection();
        const range = document.caretRangeFromPoint(event.clientX, event.clientY);
        if (selection && range) {
          selection.setBaseAndExtent(
            range.startContainer,
            range.startOffset,
            range.startContainer,
            range.startOffset,
          );
        }
      } else {
        const selection = document.getSelection();
        const range = document.caretPositionFromPoint(event.clientX, event.clientY);
        if (selection && range) {
          selection.setBaseAndExtent(
            range.offsetNode,
            range.offset,
            range.offsetNode,
            range.offset,
          );
        }
      }

      this.quill.focus();
      this.range = this.quill.getSelection();

      setTimeout(() => {
        this.quill.focus();
        this.range = this.quill.getSelection();
        this.readAndUploadFile(file);
      }, 0);
    }
  }

  handlePaste(event) {
    try {
      const clipboard = event.clipboardData || window.clipboardData;

      // IE 11 is .files other browsers are .items
      const file = clipboard?.items?.[0]?.getAsFile() || clipboard?.files?.[0];
      if (file != null) this.handlePasteFile(event, file);
    } catch (e) {
      // ストレージにアップロードされずに画像が挿入されるのを防ぐために、何もしないようにする。
      event.stopPropagation();
      event.preventDefault();

      // bugsnagで検知できるように、エラーは出す
      throw e;
    }
  }

  handlePasteFile(event, file) {
    event.stopPropagation();
    event.preventDefault();

    const IMAGE_MIME_REGEX = new RegExp(`^image/(${this.acceptType.replaceAll(',', '|').replaceAll(/( |\.)/ig, '')})$`, 'i');
    if (IMAGE_MIME_REGEX.test(file.type)) {
      this.quill.focus();
      this.range = this.quill.getSelection();
      setTimeout(() => {
        this.quill.focus();
        this.range = this.quill.getSelection();
        this.readAndUploadFile(file);
      }, 0);
    }
  }

  readAndUploadFile(file) {
    let isUploadReject = false;

    const fileReader = new FileReader();

    fileReader.addEventListener(
      'load',
      () => {
        if (!isUploadReject) {
          const base64ImageSrc = fileReader.result;
          this.insertBase64Image(base64ImageSrc);
        }
      },
      false,
    );

    if (file) {
      fileReader.readAsDataURL(file);
    }

    this.upload(file)?.then(
      (imageUrl) => {
        this.insertToEditor(imageUrl, file);
      },
      (error) => {
        isUploadReject = true;
        this.removeBase64ImageIfNeeded();
      },
    );
  }

  fileChanged() {
    const file = this.fileHolder.files[0];
    this.readAndUploadFile(file);
  }

  insertBase64Image(url) {
    const range = this.range;
    this.quill.insertEmbed(
      range.index,
      LoadingImage.blotName,
      `${url}`,
      'user',
    );
  }

  insertToEditor(url, file) {
    const range = this.range;
    // Delete the placeholder image
    this.quill.deleteText(range.index, LoadingImage.length, 'user');
    // Insert the server saved image
    this.quill.insertEmbed(range.index, 'image', `${url}`, 'user');
    this.quill.formatText(range.index, 1, 'data-size', file.size);


    range.index++;
    this.quill.setSelection(range, 'user');
  }

  removeBase64ImageIfNeeded() {
    const [loadingImage, offset] = this.quill.scroll.descendant(
      LoadingImage,
      this.range.index,
    );
    if (loadingImage == null) return;
    this.quill.deleteText(this.range.index, LoadingImage.length, 'user');
  }
}
</script>

<style lang="scss" scoped>
:deep(.ql-container) {
  overflow-y: hidden;

  .ql-clipboard {
    white-space: pre-wrap;
    word-wrap: break-word;
  }
  a {
    color: #0066cc !important;
  }
  .ql-editor {
    overflow-y: scroll;
    padding-right: calc(15px - #{$scrollbar-width});
    img {
      max-width: none;
    }
  }
  .image-uploading {
    position: relative;
    display: inline-block;
    img {
      max-width: 98% !important;
      filter: blur(5px);
      opacity: 0.3;
    }
    &::before {
      content: "";
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 30px;
      height: 30px;
      margin-top: -15px;
      margin-left: -15px;
      border-radius: 50%;
      border: 3px solid #ccc;
      border-top-color: #1e986c;
      z-index: 1;
      animation: spinner 0.6s linear infinite;
    }
  }

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }
}

:deep(.ql-snow) {
  .ql-picker.ql-size {
    .ql-picker-label[data-value="x-small"]::before {
      content: 'Small';
    }
    .ql-picker-item[data-value="x-small"]::before {
      content: 'Small';
      font-size: x-small;
    }
    .ql-picker-label[data-value="large"]::before {
      content: 'Large';
    }
    .ql-picker-item[data-value="large"]::before {
      content: 'Large';
      font-size: large;
    }
    .ql-picker-label[data-value="xx-large"]::before {
      content: 'Huge';
    }
    .ql-picker-item[data-value="xx-large"]::before {
      content: 'Huge';
      font-size: xx-large;
    }
  }

  &.ql-toolbar {
    button.ql-active .b-icon,
    button .b-icon:hover {
      color: #06c;
    }
  }
}
</style>
