<template>
  <BLayout
    class="custom-action-creating-form-container"
    align-center
    justify-center
  >
    <BInput
      v-model="name"
      v-model:valid="valid"
      class="input-name mr-400"
      :placeholder="$t('customAction.name')"
      :rules="[requiredRule]"
      :max-length="255"
      @keypress-enter="handleSubmit"
    />
    <BBtn
      type="primary"
      :disabled="!valid"
      :loading="$wait.is('creatingCustomActionWait')"
      @click="handleSubmit"
    >
      <span>{{ $t('general.append.text') }}</span>
    </BBtn>
  </BLayout>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { CustomActionApiService } from '@/api/user/resources/custom_action';
import { useInputValidations } from '@/composable/input-validations';
import { useWait } from '@/composable/vue-wait';

type TEmit = {
  'afterCreate': [];
};
const emit = defineEmits<TEmit>();

const { doActionWithWait } = useWait();

const { requiredRule } = useInputValidations();

const name = ref('');
const valid = ref(false);

const clear = () => {
  name.value = '';
  valid.value = false;
};


const handleSubmit = async () => {
  await doActionWithWait('creatingCustomActionWait', async () => {
    const api = new CustomActionApiService();
    await api.createCustomAction(
      {
        request: {
          postCustomActionBody: {
            name: name.value,
          },
        },
      },
    );
  });
  emit('afterCreate');
  clear();
}; 
</script>

<style lang="scss" scoped>
.custom-action-creating-form-container { // BInputのwidth: 100%を上書きするためにポイントを稼いでいる。
  .input-name {
    width: $setting-input-width;
  }
}
</style>
