<template>
  <div class="b-date-range-form">
    <ElDatePicker
      v-model="date"
      :type="type"
      :start-placeholder="$t('general.start')"
      :end-placeholder="$t('general.end')"
      :default-time="defaultTime"
      :format="format"
      :teleported="teleported"
    />
  </div>
</template>

<script lang="ts" setup>
import { Dayjs, isDayjs } from 'dayjs';
import { computed } from 'vue';

type TDateType = 'daterange' | 'datetimerange';
type TFormat = null | 'YYYY-MM-DD HH:mm'
const props = withDefaults(defineProps<TProps>(), {
  type: 'daterange',
  defaultTime: () => {
    const from = new Date();
    const to = new Date();
    from.setHours(0, 0, 0);
    to.setHours(23, 59, 59);
    return [
      from,
      to,
    ];
  },
  teleported: true,
});

const emit = defineEmits<TEmits>();

const formats = {
  daterange: null,
  datetimerange: 'YYYY-MM-DD HH:mm',
};

type TProps = {
  modelValue: Date[];
  type?: TDateType;
  defaultTime?: Date[];
  teleported?: boolean;
};

type TEmits = {
  'update:modelValue': [date: Date[]];
};

const date = computed({
  get () {
    return props.modelValue;
  },
  set (v: Date[] | Dayjs[] | null) {
    // clearボタンを押下すると配列でなく突如`null`になるので空配列で上書きする
    if (v === null) v = [];

    // ElementPlusの内部でdayjsが使われており、直接入力をすると、dayjsオブジェクト（https://day.js.org/）が返却される（不具合？）。
    // この問題自体は「value-formatを指定する」でも解消可能だが、影響範囲が大きいのと、
    // Dateオブジェクトの方が扱いやすいことが多い気がするため、Dateオブジェクトへ変換する方針とした。
    // 変換方法は、「new Date(v)」でもいけたが、一応公式に従っておく（https://day.js.org/docs/en/display/as-javascript-date）。
    const converteds = v.map((e: Date | Dayjs): Date => {
      if (isDayjs(e)) return e.toDate();
      return e;
    });

    // ライブラリ側に全て任せると、以下問題により、いい感じにやってくれないケースがあるため、非表示桁以下の値は自前で調整する。
    // - Fromのミリ秒について、"000"や"999"ではなく、"現在のミリ秒"が設定される。
    // - カレンダーから選択した場合は、Toの非表示桁以下が"59"になるが、手動入力した場合は"00"となる。
    const from = converteds[0];
    const to = converteds[1];
    if (props.type === 'daterange') {
      converteds.length && clearTimes(from, to);
    }
    if (props.type === 'datetimerange') {
      converteds.length && clearMilliseconds(from, to);
    }
    if (format.value === 'YYYY-MM-DD HH:mm') {
      converteds.length && clearSeconds(from, to);
    }
    emit('update:modelValue', converteds);
  },
});

const format = computed((): TFormat => {
  return formats[props.type];
});

const clearMilliseconds = (fromDate: Date, toDate: Date) => {
  fromDate.setMilliseconds(0);
  toDate.setMilliseconds(999);
};

const clearSeconds = (fromDate: Date, toDate: Date) => {
  fromDate.setSeconds(0);
  toDate.setSeconds(59);
};

const clearTimes = (fromDate: Date, toDate: Date) => {
  fromDate.setHours(0);
  fromDate.setMinutes(0);
  toDate.setHours(23);
  toDate.setMinutes(59);
  clearSeconds(fromDate, toDate);
  clearMilliseconds(fromDate, toDate);
};
</script>

<style lang="scss" scoped>
  .b-date-range-form {
    :deep(.el-date-editor.el-input__wrapper) {
      --el-date-editor-width: 100%
    }
  }
</style>
