<template>
  <div class="instance-list-container">
    <div class="instance-list-control">
      <BContainer
        class="instance-list-control-inner"
        size="large"
      >
        <BPaginationMetaText
          v-if="paginationMeta"
          :current-page="page"
          :paging-meta="paginationMeta"
        />
        <BPagination
          v-if="paginationMeta"
          v-model="page"
          class="ml-a"
          :length="paginationMeta.totalPages"
          :total-visible="5"
          circle
        />
      </BContainer>
    </div>
    <div class="instance-list ml-400">
      <VWait
        for="fetchTriggerTargetsWait"
      >
        <template #waiting>
          <BPlaceholder
            v-loading="true"
            element-loading-background="transparent"
          />
        </template>
        <InstanceListHeader
          :model-value="triggerTargetsCheckAll"
          class="instance-list-header"
          @update:model-value="handleTriggerTargetsCheckAllUpdate"
        />
        <div class="instance-items">
          <LeadLink
            v-for="instance in triggerTargets"
            :key="instance.id"
            :call-target-id="instance.callTarget?.id"
            in-new-tab
            tag="div"
          >
            <InstanceListItem
              :model-value="findCheck(instance.id)"
              :trigger-target="instance"
              @update:model-value="toggleTriggerTargetCheck"
            />
          </LeadLink>
        </div>
      </VWait>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch, onMounted } from 'vue';
import { TriggerTarget, PaginationMeta } from '@/api/openapi';
import LeadLink from '@/components/organisms/user/general/LeadLink.vue';
import InstanceListHeader from '@/components/organisms/user/trigger/InstanceListHeader.vue';
import InstanceListItem from '@/components/organisms/user/trigger/InstanceListItem.vue';
import { TCheck, useCheckAll } from '@/composable/check-all';

type TEmits = {
  'after:movePage': [afterPage: number];
  'update:selectedTriggerTargetIds': [values: number[]];
};

const emit = defineEmits<TEmits>();
const triggerTargets = defineModel<TriggerTarget[]>();
const paginationMeta = defineModel<PaginationMeta>('paginationMeta');
const page = defineModel<number | null>('page', { default: 1 });

const {
  checkAll: triggerTargetsCheckAll,
  checkedValues: selectedTriggerTargetIds,
  initializeChecks,
  findCheck,
  handleCheckAllUpdate: handleTriggerTargetsCheckAllUpdate,
  toggleCheck: toggleTriggerTargetCheck,
} = useCheckAll();

const makeTriggerTargetIdChecks = (): TCheck[] =>
  triggerTargets.value.map((instance) => ({
    value: instance.id,
    checked: false,
  }));

watch(() => triggerTargets.value, (value) => {
  if (value) {
    initializeChecks(makeTriggerTargetIdChecks());
  }
}, { immediate: true });

watch(() => page.value, (value) => {
  emit('after:movePage', value);
});

watch(selectedTriggerTargetIds, (values) => {
  emit('update:selectedTriggerTargetIds', values);
});
</script>

<style lang="scss" scoped>
.instance-list-container {
  height: 100%;
  display: flex;
  flex-direction: column;

  .instance-list-control {
    @include m-fixed-height(80px);
    display: flex;
    align-items: center;
    border-bottom: 1px solid $bdcolor-light;

    &-inner {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  .instance-list {
    flex-grow: 1;
    flex-basis: 0;
    overflow-y: auto;

    .instance-list-header {
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: $bgcolor-white;
    }

    .instance-items {
      flex-grow: 1;
      flex-basis: 0;
    }
  }
}
</style>
