
import { useStore } from 'vuex';
import { useWait } from '@/composable/vue-wait';
import { key } from '@/store';

const useVuexCallTargets = () => {
  const store = useStore(key);
  const { doActionWithWait } = useWait();

  const fetchCallTargets = () => doActionWithWait(
    'getCallTargetsWait',
    () => store.dispatch('user/getCallTargetsAction'),
  );
  const fetchCallTargetsMeta = () => doActionWithWait(
    'getCallTargetsMetaWait',
    () => store.dispatch('user/getCallTargetsMetaAction'),
  );

  const fetchCallTargetsAndMeta = () => Promise.all([
    fetchCallTargets(),
    fetchCallTargetsMeta(),
  ]);

  return {
    fetchCallTargetsAndMeta,
  };
};

export {
  useVuexCallTargets,
};
