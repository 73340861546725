<template>
  <div class="b-height-adjuster">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
  .b-height-adjuster {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
</style>
