<template>
  <ElSwitch
    v-model="status"
    :loading="loading"
    :before-change="beforeAction"
    :class="classes()"
  />
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';

const props = withDefaults(defineProps<TProps>(), {
  color: 'default',
  loading: false,
  beforeChange: async () => {
    return true;
  },
});

const loading = ref(false);

type TProps = {
  status: boolean;
  beforeChange?: Function;
  color?: string;
};
const status = computed({
  get() {
    return props.status;
  },
  set(_v) {
    // beforeActionでactionしているので、更新後にやりたいことがあればここに記述
  },
});

const beforeAction = async () => {
  loading.value = true;
  await props.beforeChange();
  loading.value = false;
  return true;
};

const classes = () => {
  return {
    'b-switch-on-default': props.color === 'default',
    'b-switch-on-accent': props.color === 'accent',
  };
};
</script>

<style lang="scss" scoped>
.b-switch-on-default {
  --el-switch-on-color: #{$basecolor-base};
}
.b-switch-on-accent {
  --el-switch-on-color: #{$basecolor-accent};
}
</style>
