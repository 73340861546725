<template>
  <div
    class="b-drawer-menu"
    :style="styles"
    :class="classes"
  >
    <BHeightAdjuster>
      <div
        v-if="title"
        class="b-drawer-menu-header"
      >
        <BLayout
          class="menu-header"
          align-center
          justify-space-between
        >
          <div class="header-content">
            <div class="header-title truncate-text">
              {{ title }}
            </div>
            <div
              v-if="$slots.headerAddition"
              class="header-addition"
            >
              <slot name="headerAddition" />
            </div>
          </div>
          <BTooltip
            top
            :content="$t('general.close.text')"
          >
            <BBtn
              class="cancel-btn"
              size="small"
              fab
              flat
              :disabled="disabledClose"
              @click="handleCancelDrawer"
            >
              <BIcon size="small">
                clear
              </BIcon>
            </BBtn>
          </BTooltip>
        </BLayout>
      </div>
      <div
        v-if="$slots.header"
        class="b-drawer-menu-header"
      >
        <slot name="header" />
      </div>
      <div class="b-drawer-menu-body">
        <slot />
      </div>
      <div class="b-drawer-menu-footer">
        <slot name="footer" />
      </div>
    </BHeightAdjuster>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: Boolean,
    width: {
      type: String,
      default: '320px',
    },
    title: String,
    disabledClose: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:modelValue',
    'cancel',
  ],
  computed: {
    internalValue: {
      get () {
        return this.modelValue;
      },
      set (newVal) {
        if (this.modelValue !== newVal) this.$emit('update:modelValue', newVal);
      },
    },
    styles () {
      return {
        width: this.width,
      };
    },
    classes () {
      return {
        open: this.internalValue,
      };
    },
  },
  methods: {
    handleCancelDrawer () {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
  .b-drawer-menu {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    height: 100%;
    visibility: visible;
    opacity: 0;
    background-color: $paper;
    transition: all 300ms $standard-easing;

    &.open {
      left: -320px;
      opacity: 1;
      transition: all 400ms $standard-easing;
      box-shadow: rgba(0,0,0,0.15) -3px 0px 10px 1px;
    }
  }

  .b-drawer-menu-header {
    @include m-fixed-height(50px);
    display: flex;
    align-items: center;
    position: relative;
    border-bottom: 1px solid $bdcolor-light;
    padding: 0 $basespace-400;

    .header-content {
      display: flex;
      width: calc(100% - 32px);

      .header-title {
        font-size: $fontsize-400;
        font-weight: 600;
        flex-grow: 1;
      }
      .header-addition {
        margin-left: auto;
        margin-right: $basespace-100;
      }
    }
  }

  .b-drawer-menu-body {
    height: 100%;
    overflow-y: auto;
    padding: $basespace-400;
  }
</style>
