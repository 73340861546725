<template>
  <BColorSelect
    v-model="value"
    :colors="colors"
    :clearable="clearable"
  />
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import colors from './lead_stage_colors';

type TProps = {
  modelValue: string | null;
  clearable?: boolean;
};

const props = withDefaults(defineProps<TProps>(), {
  clearable: false,
});
const emit = defineEmits(['update:modelValue']);

const value = computed<string>({
  get() {
    return props.modelValue;
  },
  set(v) {
    emit('update:modelValue', v);
  },
});
</script>
