<template>
  <div id="inspire">
    <BToolbar
      v-model="drawer"
      :name="currentUser.name"
      :email="currentUser.email"
      :avatar-url="currentUser.avatarUrl"
    />
    <RouterView
      :key="$route.fullPath"
      style="height: 100%;"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapWaitingActions } from 'vue-wait';
import { mapGetters } from 'vuex';
import BToolbar from '@/components/organisms/admin/BToolbar.vue';

export default defineComponent({
  components: {
    BToolbar,
  },
  data() {
    return {
      drawer: false,
    };
  },
  computed: {
    ...mapGetters('admin', ['currentUser']),
  },
  created() {
    const setupCurrentUser = async () => {
      await this.getCurrentUserAction();
    };
    setupCurrentUser();
  },
  beforeUnmount() {
    this.$clearInterval(this.timerObj);
  },
  methods: {
    ...mapWaitingActions('admin', {
      getCurrentUserAction: 'getCurrentUserWait',
    }),
  },
});
</script>

<style lang="scss" scoped>
.block-content:not(:last-child) {
  margin-bottom: $basespace-300;
}
.link-text {
  color: $textcolor-base;
  text-decoration: none;
  &:hover {
    color: $textcolor-light;
  }
}
</style>
