<template>
  <Component
    :is="node"
    @click.stop.prevent="handleClick"
  >
    <slot />
  </Component>
</template>

<script lang="ts">
import { PropType, VNode, defineComponent, h } from 'vue';
import { mapWaitingActions } from 'vue-wait';
import queryParameter from '@/mixins/query_parameter';

// FIXME: 利用先が自由気ままなのでここもある程度自由度を残しています。最終的に a or something で自動的にrenderされ、固定されるのが美しいと思います
// component構造が見直されたら修正してください
type TTag = 'a' | 'div' | 'span'

export default defineComponent({
  mixins: [queryParameter],
  props: {
    callTargetId: {
      type: Number,
      required: true,
    },
    additionalQueryParams: {
      type: Object as PropType<Record<string, unknown>>,
      required: false,
    },
    tag: {
      type: String as PropType<TTag>,
      required: false,
      default: 'a',
    },
    inNewTab: {
      type: Boolean,
      required: false,
      default: false,
    },
    openByLeadList: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: [
    'beforeMove',
  ],
  computed: {
    isLeadPage(): boolean {
      return this.$route.name === 'LeadList';
    },
    node(): VNode {
      if (this.tag === 'a') {
        const path = this.$router.resolve({ name: 'LeadList', query: this.queryParams });
        return h(this.tag, { href: path.fullPath });
      } else {
        return h(this.tag);
      }
    },
    queryParams(): { callTargetId: number; [key: string]: unknown } {
      return {
        ...this.additionalQueryParams ?? {},
        callTargetId: this.callTargetId,
      };
    },
  },
  methods: {
    ...mapWaitingActions('userUi', {
      setCurrentCallTargetIdAction: 'setCurrentCallTargetIdActionWait',
      setIsLeadOpenByLeadListAction: 'setIsLeadOpenByLeadListActionWait',
    }),
    handleClick(): void {
      this.$emit('beforeMove');
      this.setIsLeadOpenByLeadListAction(this.openByLeadList);
      return this.isLeadPage ? this.openLeadModal() : this.moveToLead();
    },
    openLeadModal(): void {
      this.setCurrentCallTargetIdAction(this.callTargetId);
      this.setQueryParameter(this.queryParams);
      this.$modal.show('target');
    },
    moveToLead(): void {
      if (this.inNewTab) {
        window.open(this.$router.resolve({ name: 'LeadList', query: this.queryParams }).href, '_blank');
        return;
      }
      this.$router.push({
        name: 'LeadList',
        query: {
          ...this.getCurrentQueryParameter(),
          ...this.queryParams,
        },
      });
    },
  },
});
</script>
