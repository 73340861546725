// @see src/styles/_colors.scss
const COLOR_CODES = {
  MOON: '#969696',
  GAIA: '#018485',
  VENUS: '#F9F871',
  NEPTUNE: '#9EA7DF',
  URANUS: '#2CBFB7',
  MARS: '#FF6465',
  SUN: '#FF8749',
} as const;

export default COLOR_CODES;
