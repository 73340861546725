<template>
  <BDrawerMenu
    v-model="isShow"
  >
    <template #header>
      <BLayout
        class="menu-header"
        align-center
        justify-space-between
      >
        <div class="header-title">
          {{ title }}
        </div>
        <BTooltip
          top
          :content="$t('general.confirm.info')"
        >
          <BBtn
            size="small"
            type="primary"
            outline
            :loading="loading"
            :disabled="!isValid"
            @click="handleClickConfirm"
          >
            <span>{{ $t('general.confirm.moveTo') }}</span>
            <BIcon>keyboard_arrow_right</BIcon>
          </BBtn>
        </BTooltip>
      </BLayout>
    </template>
    <div v-loading="loading">
      <BListItem class="mb-300">
        <template #header>
          <span>{{ $t('quickText.name') }}</span>
        </template>
        <BInput
          v-model="name"
          v-model:valid="isValidName"
          :max-length="255"
          :placeholder="$t('quickText.name')"
          required
          validation
        />
      </BListItem>
      <BListItem class="mb-300 content-wrapper">
        <template #header>
          <span>{{ $t('quickText.content') }}</span>
          <PlaceholderMenu
            v-model="isPlaceHolderMenuShow"
            @insert-item="handleContentInsertPlaceholder"
          />
        </template>
        <BInput
          ref="contentRef"
          v-model="content"
          v-model:valid="isValidContent"
          :max-length="65535"
          :placeholder="$t('quickText.content')"
          :rows="16"
          type="textarea"
          required
          validation
        />
      </BListItem>
    </div>
    <template #footer>
      <BLayout
        class="py-300"
        row
        align-center
        justify-center
      >
        <BBtn
          class="mr-300"
          text
          :disabled="loading"
          @click="handleCancelCreateQuickText"
        >
          {{ $t('general.close.text') }}
        </BBtn>
        <BBtn
          class="ml-300"
          type="primary"
          :loading="loading"
          :disabled="!isValid"
          @click="handleClickDraftSave"
        >
          {{ $t('general.draftSave.text') }}
        </BBtn>
      </BLayout>
    </template>
  </BDrawerMenu>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import PlaceholderMenu from '@/components/organisms/user/general/PlaceholderMenu.vue';
import { useInsertText } from '@/composable/input';
import BInput from '@/plugins/biscuet-materials/components/atoms/BInput.vue';

type TProps = {
  title: string;
  loading: boolean;
};
type TEmit = {
  'click:draftSave': [];
  'click:confirm': [];
  'click:close': [];
};

defineProps<TProps>();
const emit = defineEmits<TEmit>();

const isShow = defineModel<boolean>('isShow', { default: false });

const name = defineModel<string>('name', { default: '' });
const content = defineModel<string>('content', { default: '' });
const isValidName = defineModel<boolean>('isValidName', { default: false });
const isValidContent = defineModel<boolean>('isValidContent', { default: false });

const contentRef = ref<typeof BInput | null>(null);
const isPlaceHolderMenuShow = ref<boolean>(false);

const { insertText: insertTextToContent } = useInsertText(contentRef, content);

const isValid = computed(() => {
  return isValidName.value && isValidContent.value;
});

const handleCancelCreateQuickText = async () => {
  emit('click:close');
};
const handleClickDraftSave = async () => {
  emit('click:draftSave');
};
const handleClickConfirm = async () => {
  emit('click:confirm');
};
const handleContentInsertPlaceholder = (placeholder: string) => {
  insertTextToContent(placeholder);
};
</script>

<style lang="scss" scoped>
.content-wrapper {
  :deep(.b-list-item-header) {
    position: relative;
    height: 38px;
    padding-top: 16px;
  }
  :deep(.placeholder-menu-button) {
    right: 0;
  }
}
</style>
