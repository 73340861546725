export class Rejectablizer<T> {
  innerPromise: Promise<T>;
  innerReject: (reason?: unknown) => void;
  constructor(promise: Promise<T>) {
    this.innerPromise = new Promise((resolve, reject) => {
      this.innerReject = reject;
      promise
        .then((value) => {
          resolve(value);
        }).catch((err) => {
          reject(err);
        });
    });
  }

  reject(reason?: unknown): void {
    this.innerReject(reason);
  }

  promise(): Promise<T> {
    return this.innerPromise;
  }
}

export const rejectablize = <T>(promise: Promise<T>) => {
  return new Rejectablizer(promise);
};
