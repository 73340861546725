<template>
  <VWait
    for="fetchSequenceMaster"
  >
    <EditableSequence
      :sequence-body="sequenceBody"
      :owner-name="sequenceMaster?.owner?.name"
      :enabled-to-add-steps="false"
      :editable="editable"
    />
  </VWait>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { SequenceBody } from '@/api/openapi';
import { useSequenceMaster } from '@/composable/user/sequence/sequence-master';
import EditableSequence from '../general/sequence/EditableSequence.vue';

type TProps = {
  sequenceMasterId: number;
  editable?: boolean;
};

const props = withDefaults(defineProps<TProps>(), {
  editable: true,
});

const {
  sequenceMaster,
  fetchSequenceMaster,
} = useSequenceMaster(props.sequenceMasterId);

fetchSequenceMaster('fetchSequenceMaster');

const sequenceBody = computed<SequenceBody>(() => ({
  name: sequenceMaster.value?.name ?? '',
}));
</script>
