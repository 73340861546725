export const types = {
  SET_AUTH_INFO: 'SET_AUTH_INFO',
  SET_CURRENT_STATE: 'SET_CURRENT_STATE',
  SET_IS_CALLING: 'SET_IS_CALLING',
  SET_IS_INBOUND: 'SET_IS_INBOUND',
  SET_STARTED_AT: 'SET_STARTED_AT',
  SET_ENDED_AT: 'SET_ENDED_AT',
  SET_URL: 'SET_URL',
  SET_CONNECTED: 'SET_CONNECTED',
  SET_CALL_INFO_RESET: 'SET_CALL_INFO_RESET',
};

export const mutations = {
  [types.SET_AUTH_INFO] (state, { companyId, accessKey }) {
    state.authInfo = { companyId, accessKey };
  },
  [types.SET_CURRENT_STATE] (state, { currentWidgetStatus }) {
    state.currentState = { currentWidgetStatus };
  },
  [types.SET_IS_CALLING] (state, isCalling) {
    state.isCalling = isCalling;
  },
  [types.SET_IS_INBOUND] (state, isInbound) {
    state.isInbound = isInbound;
  },
  [types.SET_STARTED_AT] (state, startedAt) {
    state.callInfo.startedAt = startedAt;
  },
  [types.SET_ENDED_AT] (state, endedAt) {
    state.callInfo.endedAt = endedAt;
  },
  [types.SET_URL] (state, url) {
    state.callInfo.url = url;
  },
  [types.SET_CONNECTED] (state, connected) {
    state.callInfo.connected = connected;
  },
  [types.SET_CALL_INFO_RESET] (state) {
    state.isInbound = false;
    state.callInfo = {
      startedAt: '',
      endedAt: '',
      url: '',
      connected: false,
    };
  },
};
