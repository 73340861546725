import { computed, ref } from 'vue';

type TCheck = {
  value: number;
  checked: boolean;
};

/**
 * @example
 * // script
 * const { checks, checkAll, initializeChecks, findCheck, handleCheckAllChange, handleCheckChange } = useCheckAll();
 * onMounted(() => initializeChecks([1, 2, 3].map((value) => ({ value, checked: false }))));
 * checkedValues.value // [1, 2, 3]
 * // template checkAll
 * <input type="checkbox" v-model="checkAll" @update:model-value="handleCheckAllUpdate" />
 * // template check
 * <input type="checkbox" v-model="findCheck(someValue).checked" @update:model-value="handleCheckChange(findCheck(someValue))" />
 */
const useCheckAll = () => {
  const isCheckAll = (checks: TCheck[]) => checks.every((check) => check.checked); 

  const checks = ref<TCheck[]>([]);
  const checkAll = ref<boolean>(false);

  const checkedValues = computed(() => checks.value
    .filter((check) => check.checked)
    .map((check) => check.value),
  );

  const initializeChecks = (initialChecks: TCheck[]): void => {
    checks.value = initialChecks;
    checkAll.value = isCheckAll(initialChecks);
  };
  const findCheck = (value: number): TCheck => checks.value.find((check) => check.value === value);

  const handleCheckAllUpdate = (checked: boolean): void => {
    checks.value.forEach((check) => check.checked = checked);
    checkAll.value = checked;
  };
  const toggleCheck = (check: TCheck): void => {
    check.checked = !check.checked;
    checkAll.value = isCheckAll(checks.value);
  };

  return {
    checks,
    checkAll,
    checkedValues,
    initializeChecks,
    findCheck,
    handleCheckAllUpdate,
    toggleCheck,
  };
};

export type {
  TCheck,
};
export {
  useCheckAll,
};
