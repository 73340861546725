<template>
  <div class="import-confirm-list">
    <div class="lead-import-confirm-header">
      <span>{{ `${$t('import.salesforce.targetCount')}${importCount}` }}</span>
    </div>
    <div class="lead-import-confirm-body">
      <ElTable
        :data="rows"
      >
        <ElTableColumn
          v-for="(header, index) in headers"
          :key="index"
          :prop="index"
          :label="header"
        />
      </ElTable>
    </div>
    <BLayout
      class="my-300"
      justify-start
    >
      <span>{{ $t('import.confirm.message') }}</span>
    </BLayout>
  </div>
</template>

<script>
export default {
  name: 'SalesforceImportList',
  props: {
    sfRecords: {
      type: Array,
      default() {
        return [];
      },
    },
    importCount: {
      type: String,
      default: '0',
    },
  },
  computed: {
    headers() {
      return this.sfRecords.headers;
    },
    rows() {
      return this.sfRecords.rows;
    },
  },
};
</script>
