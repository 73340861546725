/* tslint:disable */
/* eslint-disable */
/**
 * openapi
 * bales cloud
 *
 * The version of the OpenAPI document: 1.0
 * Contact: test@example.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { SfTodoSyncSettingBody } from '../models';
// @ts-ignore
import { SfTodoSyncSettings } from '../models';
/**
 * SfTodoSyncSettingsApi - axios parameter creator
 * @export
 */
export const SfTodoSyncSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * sfのTODO連携同期設定API
         * @summary sfのTODO連携同期設定
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSfTodoSyncSettings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/salesforce/sf_todo_sync_settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * sfのTODO連携同期設定API
         * @summary sfのTODO連携同期設定更新API
         * @param {SfTodoSyncSettingBody} [sfTodoSyncSettingBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putSfTodoSyncSettings: async (sfTodoSyncSettingBody?: SfTodoSyncSettingBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/salesforce/sf_todo_sync_settings/upsert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sfTodoSyncSettingBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SfTodoSyncSettingsApi - functional programming interface
 * @export
 */
export const SfTodoSyncSettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SfTodoSyncSettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * sfのTODO連携同期設定API
         * @summary sfのTODO連携同期設定
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSfTodoSyncSettings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SfTodoSyncSettings>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSfTodoSyncSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * sfのTODO連携同期設定API
         * @summary sfのTODO連携同期設定更新API
         * @param {SfTodoSyncSettingBody} [sfTodoSyncSettingBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putSfTodoSyncSettings(sfTodoSyncSettingBody?: SfTodoSyncSettingBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putSfTodoSyncSettings(sfTodoSyncSettingBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SfTodoSyncSettingsApi - factory interface
 * @export
 */
export const SfTodoSyncSettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SfTodoSyncSettingsApiFp(configuration)
    return {
        /**
         * sfのTODO連携同期設定API
         * @summary sfのTODO連携同期設定
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSfTodoSyncSettings(options?: any): AxiosPromise<Array<SfTodoSyncSettings>> {
            return localVarFp.getSfTodoSyncSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * sfのTODO連携同期設定API
         * @summary sfのTODO連携同期設定更新API
         * @param {SfTodoSyncSettingBody} [sfTodoSyncSettingBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putSfTodoSyncSettings(sfTodoSyncSettingBody?: SfTodoSyncSettingBody, options?: any): AxiosPromise<void> {
            return localVarFp.putSfTodoSyncSettings(sfTodoSyncSettingBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SfTodoSyncSettingsApi - interface
 * @export
 * @interface SfTodoSyncSettingsApi
 */
export interface SfTodoSyncSettingsApiInterface {
    /**
     * sfのTODO連携同期設定API
     * @summary sfのTODO連携同期設定
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SfTodoSyncSettingsApiInterface
     */
    getSfTodoSyncSettings(options?: AxiosRequestConfig): AxiosPromise<Array<SfTodoSyncSettings>>;

    /**
     * sfのTODO連携同期設定API
     * @summary sfのTODO連携同期設定更新API
     * @param {SfTodoSyncSettingBody} [sfTodoSyncSettingBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SfTodoSyncSettingsApiInterface
     */
    putSfTodoSyncSettings(sfTodoSyncSettingBody?: SfTodoSyncSettingBody, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * Request parameters for putSfTodoSyncSettings operation in SfTodoSyncSettingsApi.
 * @export
 * @interface SfTodoSyncSettingsApiPutSfTodoSyncSettingsRequest
 */
export interface SfTodoSyncSettingsApiPutSfTodoSyncSettingsRequest {
    /**
     * 
     * @type {SfTodoSyncSettingBody}
     * @memberof SfTodoSyncSettingsApiPutSfTodoSyncSettings
     */
    readonly sfTodoSyncSettingBody?: SfTodoSyncSettingBody
}

/**
 * SfTodoSyncSettingsApi - object-oriented interface
 * @export
 * @class SfTodoSyncSettingsApi
 * @extends {BaseAPI}
 */
export class SfTodoSyncSettingsApi extends BaseAPI implements SfTodoSyncSettingsApiInterface {
    /**
     * sfのTODO連携同期設定API
     * @summary sfのTODO連携同期設定
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SfTodoSyncSettingsApi
     */
    public getSfTodoSyncSettings(options?: AxiosRequestConfig) {
        return SfTodoSyncSettingsApiFp(this.configuration).getSfTodoSyncSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * sfのTODO連携同期設定API
     * @summary sfのTODO連携同期設定更新API
     * @param {SfTodoSyncSettingsApiPutSfTodoSyncSettingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SfTodoSyncSettingsApi
     */
    public putSfTodoSyncSettings(requestParameters: SfTodoSyncSettingsApiPutSfTodoSyncSettingsRequest = {}, options?: AxiosRequestConfig) {
        return SfTodoSyncSettingsApiFp(this.configuration).putSfTodoSyncSettings(requestParameters.sfTodoSyncSettingBody, options).then((request) => request(this.axios, this.basePath));
    }
}
