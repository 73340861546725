
import Api from '@/api/user';

import BulkMailActions from '@/store/common/resources/bulk_mail';
import CallAnalyticsActions from '@/store/common/resources/call_analytics';
import CallListActions from '@/store/common/resources/call_list';
import CallResultActions from '@/store/common/resources/call_result';
import CallResultTypeActions from '@/store/common/resources/call_result_type';
import CallTargetActions, { actions as typedCallTargetActions } from '@/store/common/resources/call_target';
import CallTargetFreeTagActions from '@/store/common/resources/call_target_free_tag';
import CallTargetLeadSourceActions from '@/store/common/resources/call_target_lead_source';
import CallTargetNoteActions from '@/store/common/resources/call_target_note';
import CallTargetUnsubscribeActions from '@/store/common/resources/call_target_unsubscribe';
import CurrentUserActions from '@/store/common/resources/current_user';
import CustomFieldActions from '@/store/common/resources/custom_field';
import DirectMailActions from '@/store/common/resources/direct_mail';
import FreeActionActions from '@/store/common/resources/free_action';
import FreeTagActions from '@/store/common/resources/free_tag';
import LeadSourceActions from '@/store/common/resources/lead_source';
import LeadStageActions from '@/store/common/resources/lead_stage';
import LeadViewActions from '@/store/common/resources/lead_view';
import MailActions from '@/store/common/resources/mail';
import MailNotificationActions from '@/store/common/resources/mail_notification';
import MailTemplateActions from '@/store/common/resources/mail_template';
import MiitelAuthActions from '@/store/common/resources/miitel_auth';
import NextActionActions from '@/store/common/resources/next_action';
import NextActionTypeActions from '@/store/common/resources/next_action_type';
import OpportunityActions from '@/store/common/resources/opportunity';
import ReminderActions from '@/store/common/resources/reminder';
import RevisionActions from '@/store/common/resources/revision';
import SalesforceSettingActions from '@/store/common/resources/salesforce/salesforce_setting';
import SfOpportunityActions from '@/store/common/resources/salesforce/sf_opportunity';
import SendgridEventActions from '@/store/common/resources/sendgrid_event';
import SequenceInstanceActions from '@/store/common/resources/sequence_instance';
import SequenceMasterActions from '@/store/common/resources/sequence_master';
import SfCampaignActions from '@/store/common/resources/sf_campaign';
import UserActions from '@/store/common/resources/user';
import AvailableFeatureActions from '@/store/user/resources/available_feature';
import BoxilLeadImportSettingActions from '../common/resources/boxil_lead_import_setting';
import OperatorActions from '../common/resources/operator';
import SfAccountActions from '../common/resources/salesforce/sf_account';
import SfContactActions from '../common/resources/salesforce/sf_contact';
import SfLeadActions from '../common/resources/salesforce/sf_lead';
import SfRelatedLeadActions from '../common/resources/salesforce/sf_related_lead';
import SfTaskActions from '../common/resources/salesforce/sf_task';
import SfUserActions from '../common/resources/salesforce/sf_user';
import { types } from './mutations';
import clientActions from './resources/client';
import ClientLimitSettingActions from './resources/client_limit_setting';

const currentUser = new CurrentUserActions(Api, types);
const clientLimitSetting = new ClientLimitSettingActions(Api, types);
const user = new UserActions(Api, types);
const freeTag = new FreeTagActions(Api, types);
const sfCampaign = new SfCampaignActions(Api, types);
const callList = new CallListActions(Api, types);
const customField = new CustomFieldActions(Api, types);
const callTarget = new CallTargetActions(Api, types);
const callTargetFreeTag = new CallTargetFreeTagActions(Api, types);
const callTargetNote = new CallTargetNoteActions(Api, types);
const callResult = new CallResultActions(Api, types);
const callResultType = new CallResultTypeActions(Api, types);
const nextAction = new NextActionActions(Api, types);
const nextActionType = new NextActionTypeActions(Api, types);
const opportunity = new OpportunityActions(Api, types);
const freeAction = new FreeActionActions(Api, types);
const reminder = new ReminderActions(Api, types);
const miitelAuth = new MiitelAuthActions(Api, types);
const callAnalytics = new CallAnalyticsActions(Api, types);
const revision = new RevisionActions(Api, types);
const leadSource = new LeadSourceActions(Api, types);
const callTargetLeadSource = new CallTargetLeadSourceActions(Api, types);
const leadView = new LeadViewActions(Api, types);
const mail = new MailActions(Api, types);
const bulkMail = new BulkMailActions(Api, types);
const callTargetUnsubscribe = new CallTargetUnsubscribeActions(Api, types);
const availableFeatures = new AvailableFeatureActions(Api, types);
const sendgridEvents = new SendgridEventActions(Api, types);
const mailNotificationActions = new MailNotificationActions(Api, types);
const operator = new OperatorActions(Api, types);
const sfOpportunityActions = new SfOpportunityActions(Api, types);
const salesforceSettingActions = new SalesforceSettingActions(Api, types);
const sfAccountActions = new SfAccountActions(Api, types);
const sfContactActions = new SfContactActions(Api, types);
const sfUserActions = new SfUserActions(Api, types);
const sfTaskActions = new SfTaskActions(Api, types);
const sfLeadActions = new SfLeadActions(Api, types);
const sfRelatedLeadActions = new SfRelatedLeadActions(Api, types);
const boxilLeadImportSettingActions = new BoxilLeadImportSettingActions(Api, types);

const actions = Object.assign(
  currentUser.actions,
  clientLimitSetting.actions,
  user.actions,
  freeTag.actions,
  sfCampaign.actions,
  callList.actions,
  customField.actions,
  callTarget.actions,
  callTargetFreeTag.actions,
  callTargetNote.actions,
  callResult.actions,
  callResultType.actions,
  nextAction.actions,
  nextActionType.actions,
  opportunity.actions,
  freeAction.actions,
  reminder.actions,
  miitelAuth.actions,
  callAnalytics.actions,
  revision.actions,
  clientActions,
  leadSource.actions,
  callTargetLeadSource.actions,
  leadView.actions,
  mail.actions,
  bulkMail.actions,
  callTargetUnsubscribe.actions,
  availableFeatures.actions,
  sendgridEvents.actions,
  mailNotificationActions.actions,
  operator.actions,
  sfOpportunityActions.actions,
  salesforceSettingActions.actions,
  sfAccountActions.actions,
  sfContactActions.actions,
  sfUserActions.actions,
  sfTaskActions.actions,
  sfLeadActions.actions,
  sfRelatedLeadActions.actions,
  boxilLeadImportSettingActions.actions,
);

export const typedActions = {
  ...typedCallTargetActions,
  ...MailTemplateActions,
  ...LeadStageActions,
  ...SequenceMasterActions,
  ...SequenceInstanceActions,
  ...DirectMailActions,
};

export default actions;
