<template>
  <div>
    <FormDrawer
      v-model="triggerEvent"
      v-model:is-open="isOpenForm"
      @click:cancel="handleCancelClick"
    />
    <ConfirmDrawer
      v-model="triggerEvent"
      v-model:is-open="isOpenConfirm"
      :editable="editable"
      @click:cancel="handleCancelClick"
      @click:trigger-event="handleEditClick"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { TriggerEventBody } from '@/api/openapi';
import ConfirmDrawer from '@/components/organisms/user/trigger/triggerEventForm/ConfirmDrawer.vue';
import FormDrawer from '@/components/organisms/user/trigger/triggerEventForm/FormDrawer.vue';
import { TTriggerEventFormMode } from '../types';

type TProps = {
  editable: boolean;
};
type TEmits = {
  'click:cancel': [];
  'click:triggerEvent': [triggerEvent: TriggerEventBody, menu: TTriggerEventFormMode];
};

defineProps<TProps>();
const emit = defineEmits<TEmits>();
const triggerEvent = defineModel<TriggerEventBody | null>();
const isOpen = defineModel<boolean>('isOpen', { default: false });
const mode = defineModel<TTriggerEventFormMode>('mode');

const isOpenForm = computed(() => {
  return mode.value === 'form' && isOpen.value;
});

const isOpenConfirm = computed(() => {
  return mode.value === 'confirm' && isOpen.value;
});

const handleCancelClick = () => {
  emit('click:cancel');
};

const handleEditClick = () => {
  emit('click:triggerEvent', triggerEvent.value, 'form');
};
</script>

<style lang="scss" scoped>
:deep(.trigger-event-form-container) {
  :deep(.el-descriptions__label.el-descriptions__cell.is-bordered-label) {
    font-weight: normal;
    width: 170px;
  }

  &.b-drawer-menu {
    &.open {
      left: -600px !important;
    }
  }

  .trigger-event-form-body-container {
    padding: 24px 20px;

    .list-group {
      padding: 14px;
      margin-bottom: 20px;
      border: 1px solid $bdcolor-base;
      border-radius: 4px;

      .list-item {
        margin-bottom: 0;
      }
    }

    .lead-view-select-panel {
      width: 100%;
    }

    .event-detail-container {
      &.form {
        padding: 20px;
        background-color: $bgcolor-base;
      }

      &.confirm {
        .list-item {
          padding: 8px 12px;
          margin-bottom: 5px;
          background-color: $bgcolor-base;
        }
      }
    }

    .panel-body {
      padding: 0;
      overflow: auto;

      :deep(.filter-card) {
        margin-left: 0;
        cursor: auto;
      }
    }
  }

  .footer {
    padding: 0 20px;
    height: 48px;
    background-color: $bgcolor-dark;
    display: flex;

    .btn {
      width: 100px;
    }
  }

  .list-item {
    margin-bottom: 20px;

    .header {
      font-size: $fontsize-100;
      color: $textcolor-light;
      margin-bottom: $basespace-50;
    }
  }

  .radio-group {
    display: flex;
    justify-content: space-between;

    .radio-item {
      width: 100%;
      display: flex;
      align-items: center;
      position: relative;
      box-sizing: border-box;
      cursor: pointer;
      text-align: left;
      font-size: 14px;
      padding: 4px 12px;
      gap: 6px;
      min-height: 40px;
      line-height: 24px;
      border-radius: var(--el-border-radius-base);
      background-color: var(--el-fill-color-blank);
      transition: var(--el-transition-duration);
      box-shadow: 0 0 0 1px var(--el-border-color) inset;
    }
  }
}
</style>
