<template>
  <div
    v-if="ownerName"
    class="sequence-owner"
  >
    <div class="label">
      <span>
        {{ $t('general.owner') }}
        <BHelp
          v-if="ownerInfo"
          :content="ownerInfo"
        />
      </span>
    </div>
    <div class="content truncate-text">
      {{ ownerName }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

type TProps = {
  ownerName: string | undefined;
  forSequenceInstance: boolean;
};

const props = defineProps<TProps>();
const i18n = useI18n();

const ownerInfo = computed(() => {
  if (!props.forSequenceInstance) return '';

  return i18n.t('sequence.ownerInfo.instance');
});
</script>

<style lang="scss" scoped>
.sequence-owner {
  max-width: 200px;
  display: flex;
  margin-left: $basespace-400;
  align-items: center;
  .label {
    @include m-fixed-width(5rem);
    font-size: $fontsize-100;
    color: $textcolor-light;
    margin-right: $basespace-100;
  }
}
</style>
