export default {
  isTargetMenuVisible(state) {
    return (
      state.targetCallResultFormVisible
      || state.targetOpportunityFormVisible
      || state.targetFreeActionFromVisible
      || state.targetSequenceInstanceFormVisible
      || state.targetSingleNextActionFormVisible
      || state.targetSequenceStepFormVisible
      || state.targetMailFormVisible
    );
  },
  isClosingAndMovingLeadEnabled(_state, _getters, _rootState, rootGetters) {
    // NOTE: Miitelの場合、通話中はモーダルを閉じない。通話が切れるのを防止するため
    return !rootGetters['miitel/isCalling'] && !rootGetters['zoomPhone/isOutboundCalling'];
  },
  isCtiCalling(_state, _getters, _rootState, rootGetters) {
    return rootGetters['miitel/isCalling'] || rootGetters['zoomPhone/isCalling'];
  },
  isCtiOutboundCalling(_state, _getters, _rootState, rootGetters) {
    return rootGetters['miitel/isOutboundCalling'] || rootGetters['zoomPhone/isOutboundCalling'];
  },
  isTargetEditing(_, getters) {
    // ZoomPhone通話中 or ミーテル通話中 or コメント or コール結果 or 商談化 or 次のアクション のメニューが開いていたら、編集中とする
    return getters.isCtiOutboundCalling || getters.isTargetMenuVisible;
  },
  selectedCallTargetIds(state) {
    return state.selectedCallTargetIds;
  },
  selectedFreeTagInfo(state) {
    return state.selectedFreeTagInfo;
  },
  isUpdateCallList(state) {
    return state.isUpdateCallList;
  },
  selectedCallResult(state) {
    return state.selectedCallResult;
  },
  selectedOpportunity(state) {
    return state.selectedOpportunity;
  },
  selectedNextAction(state) {
    return state.selectedNextAction;
  },
  selectedSequenceInstanceId(state) {
    return state.selectedSequenceInstanceId;
  },
  zoomPhoneWidgetVisible(state) {
    return state.zoomPhoneWidgetVisible;
  },
  miitelWidgetVisible(state) {
    return state.miitelWidgetVisible;
  },
  targetCallResultFormVisible(state) {
    return state.targetCallResultFormVisible;
  },
  targetCallResultFormNextActionId(state) {
    return state.targetCallResultFormNextActionId;
  },
  targetCallResultFormPhoneNumberKey(state) {
    return state.targetCallResultFormPhoneNumberKey;
  },
  targetSequenceInstanceFormVisible(state) {
    return state.targetSequenceInstanceFormVisible;
  },
  targetSingleNextActionFormVisible(state) {
    return state.targetSingleNextActionFormVisible;
  },
  targetSequenceStepForm(state) {
    return state.targetSequenceStepFormVisible;
  },
  targetOpportunityFormVisible(state) {
    return state.targetOpportunityFormVisible;
  },
  targetFreeActionFromVisible(state) {
    return state.targetFreeActionFromVisible;
  },
  targetMailFormVisible(state) {
    return state.targetMailFormVisible;
  },
  targetHeaderMoveButtonDisabled(state) {
    return state.targetHeaderMoveButtonDisabled;
  },
  leadListFilterParams(state) {
    return state.leadListFilterParams;
  },
  leadListPageParams(state) {
    return state.leadListPageParams;
  },
  isLeadOpenByLeadList(state) {
    return state.isLeadOpenByLeadList;
  },
  leadListSelectedColumns(state) {
    return state.leadListSelectedColumns;
  },
  leadListSortableColumns(state) {
    return state.leadListSortableColumns;
  },
  leadListColumnWidths(state) {
    return state.leadListColumnWidths;
  },
  filterControlPanelVisible(state) {
    return state.filterControlPanelVisible;
  },
  selectedFilterDetailKeyName(state) {
    return state.selectedFilterDetailKeyName;
  },
  leadListSortKey(state) {
    return state.leadListSortKey;
  },
  leadListSortOrder(state) {
    return state.leadListSortOrder;
  },
  pendingCsvImportTaskIds(state) {
    return state.pendingCsvImportTaskIds;
  },
  pendingCsvOverwriteTaskIds(state) {
    return state.pendingCsvOverwriteTaskIds;
  },
  pendingExcelImportTaskIds(state) {
    return state.pendingExcelImportTaskIds;
  },
  pendingExcelOverwriteTaskIds(state) {
    return state.pendingExcelOverwriteTaskIds;
  },
  pendingSfImportTaskIds(state) {
    return state.pendingSfImportTaskIds;
  },
  doReloadLeadMergeStatus(state) {
    return state.doReloadLeadMergeStatus;
  },
  currentCallTargetId(state) {
    return state.currentCallTargetId;
  },
  isDisabledBIconWithActionMenuClose(state) {
    return state.isDisabledBIconWithActionMenuClose;
  },
};
