import lodash from 'lodash';
import { mapWaitingActions } from 'vue-wait';
import { mapGetters } from 'vuex';
import { isCustomFieldKey } from '@/composable/user/leadList/lead-filter';
import { TFilterType, filterKeyTypes, getInitialValue, multipleFilterKey } from '@/mixins/filter_service/constants';
import queryParameter from '@/mixins/query_parameter';

const mixin = {
  mixins: [queryParameter],
  computed: {
    ...mapGetters('user', ['customFields']),
    ...mapGetters('userUi', [
      'leadListFilterParams',
      'filterControlPanelVisible',
      'leadListPageParams',
    ]),
  },
  methods: {
    ...mapWaitingActions('user', {
      getCallTargetsAction: 'getCallTargetsWait',
      getCallTargetsMetaAction: 'getCallTargetsMetaWait',
    }),
    ...mapWaitingActions('userUi', {
      setLeadListFilterParamsAction: 'setLeadListFilterParamsWait',
      setLeadListPageParamsAction: 'setLeadListPageParamsWait',
      setFilterControlPanelVisibleAction: 'setFilterControlPanelVisibleWait',
      setSelectedFilterDetailKeyNameAction: 'setSelectedFilterDetailKeyNameWait',
    }),
    getFilterType(key) {
      return isCustomFieldKey(key)
        ? this.findCustomFilterType(key)
        : this.findNormalFilterType(key);
    },
    findNormalFilterType(key) {
      if (filterKeyTypes[key]) return filterKeyTypes[key];
      console.error(`couldn't not found type. key =`, key);
      return 'typeString';
    },
    findCustomFilterType(key): TFilterType {
      const customField = this.customFields.find((cf) => cf.fieldKey === key);
      if (customField == null) {
        console.error(`invalid fieldKey. fieldKey = `, key);
        return 'typeString';
      }
      switch (customField.dataType) {
        case 'text':
          return 'typeString';
        case 'datetime':
          return 'typeArray';
        case 'option':
          return 'typeArray';
        case 'number':
          return 'typeNumber';
        case 'multi_option':
          return 'typeArray';
        default:
          console.error(`invalid dataType. dataType = `, customField.dataType);
          return 'typeString';
      }
    },
    addFilterItem(shouldAddKey) {
      const newLeadListFilterParams = lodash.cloneDeep(
        this.leadListFilterParams,
      );
      const keyType = this.getFilterType(shouldAddKey);
      const param = {
        inc: { or: getInitialValue(keyType) },
      };
      if (isCustomFieldKey(shouldAddKey)) {
        if (newLeadListFilterParams.hasOwnProperty('customFieldItems')) {
          // 既に customFieldItems 自体は存在する場合
          newLeadListFilterParams.customFieldItems[shouldAddKey] = param;
        } else {
          // customFieldItems 自体が存在しない場合は初期化して定義する
          newLeadListFilterParams.customFieldItems = {
            [shouldAddKey]: param,
          };
        }
      } else {
        newLeadListFilterParams[shouldAddKey] = param;
      }

      this.setLeadListFilterParamsAction(newLeadListFilterParams);
    },
    addMultipleFilterItem(shouldAddKeys) {
      shouldAddKeys.forEach((key) => {
        this.addFilterItem(key);
      });
    },
    getMultipleFilterKey(key) {
      return key ? multipleFilterKey[key] : multipleFilterKey;
    },
    setFilterAndMoveLeadList(filterParams = {}) {
      if (this.$route.name !== 'LeadList') {
        this.$router.push({ name: 'LeadList' });
      }
      this.setLeadListFilterParamsAction(filterParams);
      // 現在の表示数設定で1ページ目を表示する
      this.setLeadListPageParamsAction(Object.assign(lodash.cloneDeep(this.leadListPageParams), { currentPage: 1 }));
      this.setFilterControlPanelVisibleAction(true);
      this.getCallTargetsAction();
    },
  },
};

export default mixin;
