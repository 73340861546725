<template>
  <div class="b-time-select">
    <ElTimeSelect
      v-bind="attributes"
      v-model="internalValue"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { format, newDateByTime } from '@/utils/date-time';

export default defineComponent({
  props: {
    modelValue: [Date, String, Array, Number],
    placeholder: String,
    pickerOptions: {
      type: Object,
      default: () => {
        return {
          start: '00:00',
          step: '00:05',
          end: '23:30',
        };
      },
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:modelValue',
  ],
  computed: {
    internalValue: {
      get() {
        return this.modelValue == null ? null : format(this.modelValue, 'HH:mm');
      },
      set(newVal) {
        if (newVal === '' || newVal == null) {
          this.$emit('update:modelValue', null);
          return;
        }
        const [hour, minute] = newVal.split(':').map((v) => Number(v));
        this.$emit(
          'update:modelValue',
          newDateByTime(hour, minute, 0, this.modelValue),
        );
      },
    },
    attributes() {
      return {
        placeholder: this.placeholder,
        clearable: this.clearable,
        ...this.pickerOptions,
      };
    },
  },
});
</script>

<style lang="scss" scoped>
  .b-time-select {
    width: 100%;

    :deep(.el-input__inner) {
      padding-right: $basespace-200;
    }
  }
</style>
