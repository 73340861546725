<template>
  <div
    class="b-connector-wrap"
    :class="size"
  >
    <BPopover
      placement="top"
      width="240"
      trigger="hover"
      :disabled="!disabledAddButton"
      :content="addButtonDisabledTooltip"
    >
      <template #reference>
        <BMoreMenu
          v-if="needsAddButton"
          v-model="isMenuOpen"
          placement="right-start"
        >
          <template #activator>
            <BEmptyBox
              class="add-button-container"
              :class="addButtonPosition"
              :display-only="disabledAddButton"
            > 
              <BIcon
                size="large"
                class="add"
                :type="iconType"
              >
                add
              </BIcon>
            </BEmptyBox>
          </template>
          <BBtn
            v-for="menu in menus"
            :key="menu.key"
            flat
            fit
            size="small"
            @click="() => handleAddButtonClick(menu.key)"
          >
            {{ menu.label }}
          </BBtn>
        </BMoreMenu>
      </template>
    </BPopover>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { TMenu } from './connector';

type TProps = {
  menus: TMenu,
  addButtonPosition?: TAddButtonPosition;
  disabledAddButton?: boolean;
  addButtonDisabledTooltip?: string;
  size?: TConnectorSize;
};
type TEmits = {
  'click:addButton': [menuKey: string];
};

const props = withDefaults(defineProps<TProps>(), {
  menus: [],
  addButtonPosition: 'none',
  disabledAddButton: false,
  addButtonDisabledTooltip: '',
  size: 'medium',
});
const emit = defineEmits<TEmits>();

type TConnectorSize = 'small' | 'medium';
type TAddButtonPosition = 'top' | 'middle' | 'none';

const isMenuOpen = ref(false);

const needsAddButton = computed(() => props.addButtonPosition !== 'none');
const iconType = computed(() => props.disabledAddButton ? 'gray' : 'default');

const handleAddButtonClick = (menuKey: string) => {
  if (props.disabledAddButton) return;
  emit('click:addButton', menuKey);
  isMenuOpen.value = false;
};
</script>

<style lang="scss" scoped>
.b-connector-wrap {
  width: 4px;
  height: 80px;
  background-color: $basecolor-success;
  position: relative;

  &.small {
    height: 40px;
  }

  .add-button-container {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 4px;
    border: 1px solid $bdcolor-base;
    position: absolute;
    top: calc(50% - 15px);
    left: -13px;
    background-color: $bgcolor-white;

    &.top {
      top: -15px;
    }

    &.is-display-only {
      cursor: default;
    }
  }
}
</style>
