<template>
  <BHeightAdjuster>
    <BModalBody>
      <div class="setting-message">
        {{ $t('nextAction.message') }}
      </div>
      <div class="list-content">
        <div v-loading="loading">
          <div class="item-content">
            <BEmptyBox
              v-if="!$wait.is('getNextActionTypesInModalWait') && !isExistNextActionTypes"
              class="py-400"
              display-only
            >
              <slot name="text">
                {{ $t('nextAction.blank') }}
              </slot>
            </BEmptyBox>
            <template v-else>
              <div
                v-for="type in defaultNextActionTypesSelectableByUser"
                :key="`default${type.id}`"
                class="next-action"
              >
                <BLayout
                  class="ml-100 py-100"
                  align-cente
                >
                  <BTooltip
                    top
                    :content="$t('nextAction.defaultConfirm')"
                  >
                    <BIcon
                      class="mr-50"
                      size="small"
                      type="primary"
                    >
                      star
                    </BIcon>
                  </BTooltip><span>{{ type.name }}</span>
                </BLayout>
              </div>
              <div
                v-for="type in nextActionTypes"
                :key="`nextAction${type.id}`"
                class="item-row next-action"
              >
                <BLayout
                  class="py-100"
                  align-center
                  justify-space-between
                >
                  <BLayout
                    class="mr-100"
                    align-center
                  >
                    <BEditableContent
                      :value="type.name"
                      :placeholder="$t('nextAction.name')"
                      :loading="updateLoading"
                      :rules="[requiredRule]"
                      :max-length="30"
                      @check="handleUpdate($event, type.id)"
                    />
                  </BLayout>
                  <BMoreMenu>
                    <BPopover
                      v-if="type.isUsed"
                      placement="top"
                      width="240"
                      trigger="hover"
                    >
                      <template #reference>
                        <div>
                          <BBtn
                            flat
                            fit
                            size="small"
                            disabled
                          >
                            {{ $t('general.delete.do') }}
                          </BBtn>
                        </div>
                      </template>
                      <div>{{ $t('nextAction.actionType.alreadyUsed') }}</div>
                    </BPopover>
                    <BBtn
                      v-else
                      flat
                      fit
                      size="small"
                      @click="() => deleteNextActionType(type)"
                    >
                      {{ $t('general.delete.do') }}
                    </BBtn>
                  </BMoreMenu>
                </BLayout>
              </div>
            </template>
          </div>
        </div>
      </div>
    </BModalBody>
    <BModalFooter>
      <BLayout
        class="footer"
        align-center
        justify-center
      >
        <BInput
          v-model="inputName"
          v-model:valid="createValid"
          class="input-name mr-400"
          :placeholder="$t('nextAction.name')"
          :rules="[requiredRule]"
          :disabled="createLoading"
          :max-length="30"
          @keypress-enter="handleCreate"
        />
        <BBtn
          type="primary"
          :disabled="!createValid"
          :loading="$wait.is('createNextActionTypeWait')"
          @click="handleCreate"
        >
          <span>{{ $t('general.create.text') }}</span>
        </BBtn>
      </BLayout>
    </BModalFooter>
  </BHeightAdjuster>
</template>

<script>
import { mapWaitingActions } from 'vue-wait';
import { mapGetters } from 'vuex';
import errorHandler from '@/mixins/error_handler';
import inputValidation from '@/mixins/input_validation';

export default {
  mixins: [inputValidation, errorHandler],
  data () {
    return {
      inputName: null,
      createValid: false,
      waitNames: ['getNextActionTypesWait', 'updateNextActionTypeWait', 'deleteNextActionTypeWait'],
    };
  },
  computed: {
    ...mapGetters('user', [
      // TODO: src/api/user/models/next_action_types.tsに実装を統一する
      'nextActionTypes',
      'defaultNextActionTypesSelectableByUser',
    ]),
    isExistNextActionTypes () {
      return this.nextActionTypes.length > 0 || this.defaultNextActionTypesSelectableByUser.length > 0;
    },
    loading () {
      return this.$wait.is(['getNextActionTypesInModalWait', 'createNextActionTypeInModalWait', 'updateNextActionTypeInModalWait', 'deleteNextActionTypeInModalWait']);
    },
    createLoading () {
      return this.$wait.is(['createNextActionTypeInModalWait', 'getNextActionTypesInModalWait']);
    },
    updateLoading () {
      return this.$wait.is(['updateNextActionTypeInModalWait', 'getNextActionTypesInModalWait']);
    },
    deleteLoading () {
      return this.$wait.is(['deleteNextActionTypeInModalWait', 'deleteNextActionTypeInModalWait']);
    },
  },
  created () {
    this.getNextActionTypesAction();
  },
  methods: {
    ...mapWaitingActions('user', {
      getNextActionTypesAction: 'getNextActionTypesInModalWait',
      createNextActionTypeAction: 'createNextActionTypeInModalWait',
      updateNextActionTypeAction: 'updateNextActionTypeInModalWait',
      deleteNextActionTypeAction: 'deleteNextActionTypeInModalWait',
    }),
    async handleCreate () {
      await this.createNextActionTypeAction({
        body: { nextActionType: { name: this.inputName } },
        errorHandlers: {
          422: this.defaultHandler,
        },
      });
      this.inputName = null;
    },
    async handleUpdate (newTypeName, nextActionTypeId) {
      await this.updateNextActionTypeAction({
        id: nextActionTypeId,
        name: newTypeName,
        errorHandlers: {
          422: this.defaultHandler,
        },
      });
    },
    async deleteNextActionType (nextActionType) {
      const check = await this.$bitterAlert.show({
        title: this.$t('nextAction.title'),
        text: this.$t('general.confirm.actionWithTarget', { target: nextActionType.name, action: this.$t('general.delete.text') }),
        closeOnClickOutside: false,
        buttons: { cancel: true, confirm: true },
      });
      if (!check) { return; }
      await this.deleteNextActionTypeAction({
        id: nextActionType.id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.b-modal-body) {
  margin-top: 20px;
  padding: 0 22px 20px;
  .list-content {
    overflow-y: auto;
    .item-content {
      height: calc(100vh - 320px);
    }
    .item-row {
      margin: 0;
      @media only screen and (min-width: 959px) {
        width: 50%;
      }
      .item {
        margin: 0;
      }
      .b-input-box {
        margin-left: 8px;
      }
    }
  }
}

.setting-message {
  color: $textcolor-light;
  margin-bottom: $basespace-400;
  white-space: pre-line;
  margin-left: $basespace-100;
}

.handle {
  cursor: grab;
  margin-right: $basespace-50;
}

.form-box {
  background: $bgcolor-base;
  padding: $basespace-200;
}

.footer {
  padding-top: $basespace-300;
  .input-name {
    width: $setting-input-width;
  }
}
</style>
