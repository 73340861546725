import {
  getAuth,
  RecaptchaVerifier,
} from 'firebase/auth';
import { ref, computed } from 'vue';

const useFirebaseRecaptcha = (
  recaptchaContainerId: string,
) => {
  const recaptchaCounter = ref<number>(0);
  const recaptchaCounterId = computed<string>(() =>
    `${recaptchaContainerId}-${recaptchaCounter.value}`,
  );
  const recaptchaVerifier = ref<RecaptchaVerifier>();

  const setRecaptcha = async (): Promise<RecaptchaVerifier> => {
    const auth = getAuth();

    // NOTE: reCapthaの埋め込み先を生成、再生成毎に別のdivにする必要があるので id をカウントアップする
    recaptchaCounter.value += 1;
    const div = document.createElement('div');
    div.id = recaptchaCounterId.value;
    const container = document.getElementById(recaptchaContainerId);
    container.appendChild(div);

    // NOTE: reCapthaを埋め込む
    recaptchaVerifier.value = new RecaptchaVerifier(auth, recaptchaCounterId.value, {
      size: 'invisible',
    });
    await recaptchaVerifier.value.render();

    return recaptchaVerifier.value;
  };

  const resetRecaptcha = () => {
    if (recaptchaVerifier.value) {
      recaptchaVerifier.value.clear();
      document.getElementById(recaptchaCounterId.value).innerHTML = '';
    }
  };

  return {
    setRecaptcha,
    resetRecaptcha,
  };
};

export {
  useFirebaseRecaptcha,
};
