/* tslint:disable */
/* eslint-disable */
/**
 * openapi
 * bales cloud
 *
 * The version of the OpenAPI document: 1.0
 * Contact: test@example.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CustomAction } from '../models';
// @ts-ignore
import { PostCustomActionBody } from '../models';
// @ts-ignore
import { PutCustomActionBody } from '../models';
/**
 * CustomActionApi - axios parameter creator
 * @export
 */
export const CustomActionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * カスタムアクション削除API
         * @summary カスタムアクション削除API
         * @param {number} customActionId アクション項目ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomAction: async (customActionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customActionId' is not null or undefined
            assertParamExists('deleteCustomAction', 'customActionId', customActionId)
            const localVarPath = `/api/user/custom_actions/{customActionId}`
                .replace(`{${"customActionId"}}`, encodeURIComponent(String(customActionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * カスタムアクション一覧取得API
         * @summary カスタムアクション一覧取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomActions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/custom_actions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * カスタムアクション作成API
         * @summary カスタムアクション作成API
         * @param {PostCustomActionBody} [postCustomActionBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCustomAction: async (postCustomActionBody?: PostCustomActionBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/custom_actions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postCustomActionBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * カスタムアクション更新API
         * @summary カスタムアクション更新API
         * @param {number} customActionId アクション項目ID
         * @param {PutCustomActionBody} [putCustomActionBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCustomAction: async (customActionId: number, putCustomActionBody?: PutCustomActionBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customActionId' is not null or undefined
            assertParamExists('putCustomAction', 'customActionId', customActionId)
            const localVarPath = `/api/user/custom_actions/{customActionId}`
                .replace(`{${"customActionId"}}`, encodeURIComponent(String(customActionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putCustomActionBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomActionApi - functional programming interface
 * @export
 */
export const CustomActionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomActionApiAxiosParamCreator(configuration)
    return {
        /**
         * カスタムアクション削除API
         * @summary カスタムアクション削除API
         * @param {number} customActionId アクション項目ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCustomAction(customActionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCustomAction(customActionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * カスタムアクション一覧取得API
         * @summary カスタムアクション一覧取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomActions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomAction>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomActions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * カスタムアクション作成API
         * @summary カスタムアクション作成API
         * @param {PostCustomActionBody} [postCustomActionBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCustomAction(postCustomActionBody?: PostCustomActionBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomAction>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCustomAction(postCustomActionBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * カスタムアクション更新API
         * @summary カスタムアクション更新API
         * @param {number} customActionId アクション項目ID
         * @param {PutCustomActionBody} [putCustomActionBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCustomAction(customActionId: number, putCustomActionBody?: PutCustomActionBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCustomAction(customActionId, putCustomActionBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomActionApi - factory interface
 * @export
 */
export const CustomActionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomActionApiFp(configuration)
    return {
        /**
         * カスタムアクション削除API
         * @summary カスタムアクション削除API
         * @param {number} customActionId アクション項目ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomAction(customActionId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCustomAction(customActionId, options).then((request) => request(axios, basePath));
        },
        /**
         * カスタムアクション一覧取得API
         * @summary カスタムアクション一覧取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomActions(options?: any): AxiosPromise<Array<CustomAction>> {
            return localVarFp.getCustomActions(options).then((request) => request(axios, basePath));
        },
        /**
         * カスタムアクション作成API
         * @summary カスタムアクション作成API
         * @param {PostCustomActionBody} [postCustomActionBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCustomAction(postCustomActionBody?: PostCustomActionBody, options?: any): AxiosPromise<CustomAction> {
            return localVarFp.postCustomAction(postCustomActionBody, options).then((request) => request(axios, basePath));
        },
        /**
         * カスタムアクション更新API
         * @summary カスタムアクション更新API
         * @param {number} customActionId アクション項目ID
         * @param {PutCustomActionBody} [putCustomActionBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCustomAction(customActionId: number, putCustomActionBody?: PutCustomActionBody, options?: any): AxiosPromise<void> {
            return localVarFp.putCustomAction(customActionId, putCustomActionBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomActionApi - interface
 * @export
 * @interface CustomActionApi
 */
export interface CustomActionApiInterface {
    /**
     * カスタムアクション削除API
     * @summary カスタムアクション削除API
     * @param {number} customActionId アクション項目ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomActionApiInterface
     */
    deleteCustomAction(customActionId: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * カスタムアクション一覧取得API
     * @summary カスタムアクション一覧取得API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomActionApiInterface
     */
    getCustomActions(options?: AxiosRequestConfig): AxiosPromise<Array<CustomAction>>;

    /**
     * カスタムアクション作成API
     * @summary カスタムアクション作成API
     * @param {PostCustomActionBody} [postCustomActionBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomActionApiInterface
     */
    postCustomAction(postCustomActionBody?: PostCustomActionBody, options?: AxiosRequestConfig): AxiosPromise<CustomAction>;

    /**
     * カスタムアクション更新API
     * @summary カスタムアクション更新API
     * @param {number} customActionId アクション項目ID
     * @param {PutCustomActionBody} [putCustomActionBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomActionApiInterface
     */
    putCustomAction(customActionId: number, putCustomActionBody?: PutCustomActionBody, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * Request parameters for deleteCustomAction operation in CustomActionApi.
 * @export
 * @interface CustomActionApiDeleteCustomActionRequest
 */
export interface CustomActionApiDeleteCustomActionRequest {
    /**
     * アクション項目ID
     * @type {number}
     * @memberof CustomActionApiDeleteCustomAction
     */
    readonly customActionId: number
}

/**
 * Request parameters for postCustomAction operation in CustomActionApi.
 * @export
 * @interface CustomActionApiPostCustomActionRequest
 */
export interface CustomActionApiPostCustomActionRequest {
    /**
     * 
     * @type {PostCustomActionBody}
     * @memberof CustomActionApiPostCustomAction
     */
    readonly postCustomActionBody?: PostCustomActionBody
}

/**
 * Request parameters for putCustomAction operation in CustomActionApi.
 * @export
 * @interface CustomActionApiPutCustomActionRequest
 */
export interface CustomActionApiPutCustomActionRequest {
    /**
     * アクション項目ID
     * @type {number}
     * @memberof CustomActionApiPutCustomAction
     */
    readonly customActionId: number

    /**
     * 
     * @type {PutCustomActionBody}
     * @memberof CustomActionApiPutCustomAction
     */
    readonly putCustomActionBody?: PutCustomActionBody
}

/**
 * CustomActionApi - object-oriented interface
 * @export
 * @class CustomActionApi
 * @extends {BaseAPI}
 */
export class CustomActionApi extends BaseAPI implements CustomActionApiInterface {
    /**
     * カスタムアクション削除API
     * @summary カスタムアクション削除API
     * @param {CustomActionApiDeleteCustomActionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomActionApi
     */
    public deleteCustomAction(requestParameters: CustomActionApiDeleteCustomActionRequest, options?: AxiosRequestConfig) {
        return CustomActionApiFp(this.configuration).deleteCustomAction(requestParameters.customActionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * カスタムアクション一覧取得API
     * @summary カスタムアクション一覧取得API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomActionApi
     */
    public getCustomActions(options?: AxiosRequestConfig) {
        return CustomActionApiFp(this.configuration).getCustomActions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * カスタムアクション作成API
     * @summary カスタムアクション作成API
     * @param {CustomActionApiPostCustomActionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomActionApi
     */
    public postCustomAction(requestParameters: CustomActionApiPostCustomActionRequest = {}, options?: AxiosRequestConfig) {
        return CustomActionApiFp(this.configuration).postCustomAction(requestParameters.postCustomActionBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * カスタムアクション更新API
     * @summary カスタムアクション更新API
     * @param {CustomActionApiPutCustomActionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomActionApi
     */
    public putCustomAction(requestParameters: CustomActionApiPutCustomActionRequest, options?: AxiosRequestConfig) {
        return CustomActionApiFp(this.configuration).putCustomAction(requestParameters.customActionId, requestParameters.putCustomActionBody, options).then((request) => request(this.axios, this.basePath));
    }
}
