<template>
  <BLayout
    class="instance-list-row"
    align-center
  >
    <div
      class="instance-list-item checkbox"
      @click.stop
    >
      <BCheckbox
        class="mt-0"
        :model-value="modelValue.checked"
        :value="modelValue.value"
        @change="handleCheckboxChange"
      />
    </div>
    <div
      v-for="column in columns"
      :key="column.key"
      class="instance-list-item"
      :style="{
        'width': column.width,
        'min-width': column.width,
        'max-width': column.width,
      }"
    >
      <span
        v-if="column.key == 'companyName'"
        class="truncate-text"
      >
        {{ sequenceInstance.callTarget.companyName }}
      </span>
      <span
        v-else-if="column.key == 'fullName'"
        class="truncate-text"
      >
        {{ altText(sequenceInstance.callTarget.fullName) }}
      </span>
      <span
        v-else-if="column.key == 'registrationDate'"
      >
        {{ toYYYYMMDD(sequenceInstance.createdAt) }}
      </span>
      <div
        v-else-if="column.key == 'status'"
      >
        <SequenceInstanceStatusPreview
          :value="(sequenceInstance.status as TSequenceStatus)"
          size="small"
          class="mr-50"
        />
      </div>
      <div
        v-else-if="column.key == 'isOpportunityAssociated'"
      >
        <BCustomIcon
          icon-class="b-fire"
          :type="sequenceInstance.isOpportunityAssociated ? 'primary' : 'gray'"
        />
      </div>
      <div
        v-else-if="column.key == 'lastLeadStage'"
      >
        <BLeadStageColorPreview
          :value="sequenceInstance.callTarget.leadStage.colorName"
          size="small"
          class="mr-50"
        />
        {{ sequenceInstance.callTarget.leadStage.name }}
      </div>
      <span
        v-else-if="column.key == 'numberOfDoneSteps'"
      >
        {{ numberOfDoneStepsText }}
      </span>
      <span
        v-else-if="column.key == 'numberOfCurrentStepStays'"
      >
        {{ sequenceInstance.numberOfCurrentStepStays }}
      </span>
      <div
        v-else-if="column.key == 'owner'"
      >
        <BUserTooltip
          v-if="sequenceInstance.callTarget.user"
          :image-url="sequenceInstance.callTarget.user.avatarUrl"
          :name="sequenceInstance.callTarget.user.name"
          :user-id="sequenceInstance.callTarget.user.id"
          :size="40"
        />
        <span v-else>{{ altText('') }}</span>
      </div>
    </div>
    <div class="instance-list-item end-column" />
  </BLayout>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { SequenceInstanceOverview } from '@/api/openapi';
import SequenceInstanceStatusPreview from '@/components/organisms/user/general/sequence/SequenceInstanceStatusPreview.vue';
import { TCheck } from '@/composable/check-all';
import { toYYYYMMDD } from '@/services/date-format-service';
import { TSequenceStatus } from '@/types';
import { altText } from '@/utils/alt';
import { columns } from './instance-list-header';

type TProps = {
  modelValue: TCheck;
  sequenceInstance: SequenceInstanceOverview;
};
type TEmit = (event: 'update:modelValue', check: TCheck) => void;

const props = defineProps<TProps>();
const emit = defineEmits<TEmit>();

const handleCheckboxChange = () => emit('update:modelValue', props.modelValue);

const numberOfDoneStepsText = computed(() => {
  return `${props.sequenceInstance.numberOfDoneSteps} / ${props.sequenceInstance.numberOfSteps}`;
});
</script>

<style lang="scss" scoped>
.checkbox {
  @include m-fixed-width(40px);
  z-index: 0;
}

.instance-list-row {
  transition: $transition-base;
  cursor: pointer;
  @include m-fixed-height(60px);

  .instance-list-item {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 $basespace-200;
    border-bottom: 1px solid $bdcolor-light;
  }

  .end-column {
    width: 100%;
  }

  &:hover {
    background-color: $bgcolor-base;

    .instance-list-item {
      background-color: $bgcolor-base;
    }
  }
}
</style>
