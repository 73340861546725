import ApiBase from '@/api/base';
import SalesForceApiService from '@/api/salesforce_api_service';

export default {
  searchSfReportByName(name) {
    return new SalesForceApiService().get(`/user/salesforce/sf_reports`, { params: { name } });
  },
  getSfReport(args) {
    const { id, errorHandlers } = args;
    return new SalesForceApiService().get(`/user/salesforce/sf_reports/${id}`, { errorHandlers });
  },
  importSfReports(args) {
    return new SalesForceApiService().post(`/user/salesforce/import_sf_report`, args);
  },
  getSfReportImportTasks(args) {
    return ApiBase.pollingGet(`/user/salesforce/show_sf_report_import_tasks`, args);
  },
  confirmSfReportImportTask(args) {
    const { SfReportImportTaskId } = args;
    return new SalesForceApiService().put(`/user/salesforce/confirm_sf_report_import_tasks/${SfReportImportTaskId}`, args);
  },
};
