import Waiting from '@/store/waiting';

export default class ReminderActions {
  constructor (Api, types) {
    const actions = {
      async getReminderAction ({ commit }, args) {
        await waiting.check('getReminderAction', async () => {
          try {
            const res = await Api.getReminder(args);
            commit(types.SET_REMINDER, Object.values(res.data)[0]);
          } catch (err) {
            throw err;
          }
        });
      },
    };
    const waiting = new Waiting(actions);
    this.actions = actions;
  }
}
