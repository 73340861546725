<template>
  <BSelect
    ref="refSelect"
    v-model="value"
    :items="leadStages"
    :loading="loading"
    :required="required"
    :fit="fit"
    :automatic-dropdown="automaticDropdown"
    item-text="name"
    item-value="id"
  >
    <template #prefix>
      <BLeadStageColorPreview
        :value="selectedLeadStageColorName"
        size="small"
      />
    </template>
    <template #optionTemplate="{ item }">
      <BLeadStagePreview :lead-stage="item" />
    </template>
  </BSelect>
</template>

<script lang="ts" setup>
import { computed, nextTick, ref } from 'vue';
import { LeadStage } from '@/api/openapi';
import { TSymbolColor } from '../../types';

type TProps = {
  // NOTE: leadStage.id
  modelValue: number | null;
  leadStages: LeadStage[];
  // for BSelect
  loading?: boolean;
  required?: boolean;
  fit?: boolean;
};

const props = withDefaults(defineProps<TProps>(), {
  loading: false,
  required: false,
  fit: false,
});

const emit = defineEmits(['update:modelValue']);

const automaticDropdown = ref(false);
const refSelect = ref<HTMLElement>(null);
const value = computed<number | null>({
  get() {
    return props.modelValue;
  },
  set(v) {
    emit('update:modelValue', v);
  },
});

const selectedLeadStageColorName = computed<TSymbolColor | undefined>(() => 
  props.leadStages.find(ls => ls.id === value.value)?.colorName as TSymbolColor,
);
const focus = async () => {
  // NOTE: automaticDropdown=trueにしないとfocus時にdropdownが開かないが、ONのままにしておくとclick時にdropdownが開かなくなってしまう（Element Plusのバグかも）
  automaticDropdown.value = true;
  refSelect.value.focus();
  await nextTick();
  automaticDropdown.value = false;
};

defineExpose({
  focus,
});
</script>

<style lang="scss" scoped>
.lead-stage-preview {
  display: flex;
  align-items: center;

  .color-preview {
    margin-right: 10px;
  }
  .placeholder {
    color: $concrete-dark;
  }
}
.el-select-dropdown__item {
  .lead-stage-preview {
    // NOTE: 標準のoptionと同様に40文字でtruncateされるようにする
    max-width: 600px;
  }
}
</style>
