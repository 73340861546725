<template>
  <LeadListHeader
    :model-value="callTargetIdsCheckAll"
    class="instance-list-header"
    @update:model-value="handleCallTargetsCheckAllUpdate"
  />
  <VWait
    v-if="callTargets.length > 0"
    for="getCallTargetsWait"
  >
    <template #waiting>
      <BPlaceholder
        v-loading="true"
        height="50vh"
        element-loading-background="transparent"
      />
    </template>
    <div class="ml-400">
      <Component
        v-bind="getLeadComponentAttrs(callTarget.callTargetId)"
        :is="leadComponent"
        v-for="(callTarget, index) in callTargets"
        :key="callTarget.callTargetId"
      >
        <LeadListItem
          :model-value="findCheck(callTarget.callTargetId)"
          :call-target="callTarget"
          :data-test="`leadListItem-${index}`"
          @open-set-operator-modal="handleOpenSetOperatorModal"
          @open-sf-modal="handleOpenSfModal"
          @update:model-value="toggleCallTargetCheck"
        />
      </Component>
    </div>
  </VWait>
  <VWait
    v-else
    for="none"
    :visible="$wait.is(['initLeadListPage', 'getCallTargetsWait'])"
  >
    <template #waiting>
      <BPlaceholder
        v-loading="true"
        height="50vh"
        element-loading-background="transparent"
      />
    </template>
    <template v-if="callTargetsMeta?.isEmpty">
      <BEmptyBox
        class="mt-500 mb-600 pt-500 pb-500"
        display-only
      >
        <BIconText icon="add">
          {{ $t('callTarget.empty') }}
        </BIconText>
      </BEmptyBox>
    </template>
    <template v-else>
      <BEmptyBox
        class="mt-500 mb-600 pt-500 pb-500"
        display-only
      >
        {{ $t('callTarget.notMatched') }}
      </BEmptyBox>
    </template>
  </VWait>
</template>

<script lang="ts" setup>
import { computed, watch } from 'vue';
import LeadLink from '@/components/organisms/user/general/LeadLink.vue';
import LeadListHeader from '@/components/organisms/user/leadList/LeadListHeader.vue';
import LeadListItem from '@/components/organisms/user/leadList/LeadListItem.vue';
import { TCheck, useCheckAll } from '@/composable/check-all';

type TProps = {
  // FIXME: CallTarget型, CallTargetMeta型がないので定義が必要。適当にobjectにしている
  callTargets: {
    callTargetId: number;
    [sKey: string]: unknown;
  }[];
  callTargetsMeta: {
    isEmpty?: boolean;
  };
  enabledToDisplayLead?: boolean;
  leadOpenByLeadList?: boolean;
};
type TEmit = {
  (event: 'openSetOperatorModal', callTargetId: number, operatorId: number): void;
  (event: 'openSfModal', sfRelatedLinkInfo: string): void;
  (event: 'update:selectedCallTargetIds', selectedCallTargetIds: number[]): void;
};

const props = withDefaults(defineProps<TProps>(), {
  enabledToDisplayLead: false,
  leadOpenByLeadList: false,
});
const emit = defineEmits<TEmit>();

const {
  checkAll: callTargetIdsCheckAll,
  checkedValues: selectedCallTargetIds,
  initializeChecks,
  findCheck,
  handleCheckAllUpdate: handleCallTargetsCheckAllUpdate,
  toggleCheck: toggleCallTargetCheck,
} = useCheckAll();

const handleOpenSetOperatorModal = (callTargetId: number, operatorId: number) => {
  emit('openSetOperatorModal', callTargetId, operatorId);
};
const handleOpenSfModal = (sfRelatedLinkInfo: string) => {
  emit('openSfModal', sfRelatedLinkInfo);
};

watch(selectedCallTargetIds, (values) => {
  emit('update:selectedCallTargetIds', values);
});
watch(() => props.callTargets, () => {
  const checks: TCheck[] = props.callTargets.map(callTarget => ({
    value: callTarget.callTargetId,
    checked: false,
  }));
  initializeChecks(checks);
}, { immediate: true });

const leadComponent = computed(() => props.enabledToDisplayLead ? LeadLink : 'div');
const getLeadComponentAttrs = (callTargetId: number) => props.enabledToDisplayLead
  ? {
    callTargetId,
    openByLeadList: props.leadOpenByLeadList,
    tag: 'div',
  }
  : undefined;
</script>

<style lang="scss" scoped>
:deep(.el-loading-mask) {
  // NOTE: リードモーダルを貫通しないよう、z-indexを下げる（defaultのZ-indexは2000）
  z-index: 3;
}
</style>
