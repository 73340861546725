<template>
  <BTooltip
    bottom
    :content="tooltipContent"
    :disabled="!tooltipContent"
  >
    <div
      class="audio-wrap"
      :class="{'in-progress': zoomPhoneRecording?.inProgress}"
    >
      <BPlaceholder
        v-loading="zoomPhoneRecording?.inProgress"
        height="0"
        width="0"
        element-loading-background="transparent"
      />
      <audio
        :style="styles"
        controls
        :src="zoomPhoneRecordingUrl"
      />
    </div>
  </BTooltip>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { FileStorageAccessApiService } from '@/api/user/resources/file_storage_access';

export default defineComponent({
  props: {
    zoomPhoneRecording: Object,
    width: Number,
  },
  data () {
    return {
      zoomPhoneRecordingUrl: '',
    };
  },
  computed: {
    tooltipContent() {
      if (this.zoomPhoneRecording?.inProgress) return this.$t('zoomPhoneRecording.inProgress');
      if (this.zoomPhoneRecording?.expired) return this.$t('zoomPhoneRecording.expired');
      return '';
    },
    styles () {
      return {
        'width': this.width,
      };
    },
  },
  created () {
    this.setZoomPhoneRecordingUrlIfNeeded();
  },
  methods: {
    async setZoomPhoneRecordingUrlIfNeeded(): Promise<void> {
      const zoomPhoneRecordingId: number = this.zoomPhoneRecording?.id;
      if (!zoomPhoneRecordingId) return;
      const apiService = new FileStorageAccessApiService();
      const { data } = await apiService.createZoomPhoneRecordingPresignedUrl({ 
        request: {
          createZoomPhoneRecordingPresignedUrlRequest: {
            zoomPhoneRecordingId,
          },
        },
      });
      this.zoomPhoneRecordingUrl = data.url;
    },
  },
});
</script>

<style lang="scss" scoped>
  audio {
    width: 500px;
    margin-bottom: 10px;
  }
  .audio-wrap.in-progress {
    --el-loading-spinner-size: 24px;

    .placeholder {
      top: 27px;
      left: 14px;
    }

    audio::-webkit-media-controls-play-button {
      -webkit-appearance: none;
    }
  }
</style>
