import axios from 'axios';
import ApiService from '@/api/api_service';

export default class UnauthorizedApiService extends ApiService {
  protected setHttpInstance() {
    this.httpInstance = axios.create({
      baseURL: this.baseUrl,
      headers: {
        'Cache-Control': 'no-cache, no-store',
        'Pragma': 'no-cache',
        'Expires': 0,
        'X-Requested-With': 'XMLHttpRequest',
      },
      cancelToken: this.cancelSource.token,
    });
  }
}
