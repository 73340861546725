import ApiBase from '@/api/base';

export default {
  getLeadSources (args) {
    return ApiBase.get(`/user/lead_sources`, args);
  },
  getLeadSource (args) {
    const { id } = args;
    return ApiBase.get(`/user/lead_sources/${id}`, args);
  },
  createLeadSource (args) {
    return ApiBase.post(`/user/lead_sources`, args);
  },
  updateLeadSource (args) {
    const { id } = args;
    return ApiBase.put(`/user/lead_sources/${id}`, args);
  },
  deleteLeadSource (args) {
    const { id } = args;
    return ApiBase.delete(`/user/lead_sources/${id}`, args);
  },
};
