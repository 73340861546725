import ApiService from '@/api/api_service';
import {
  SfCampaignsApi,
} from '@/api/openapi';

export class SfCampaignService extends ApiService {
  api = new SfCampaignsApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    // openapiで作ったやつはtrue
    super(true);
  }

  async getSfCampaigns() {
    return this.api.getSfCampaigns();
  }

  async getSfOpportunityRequiredObjectFields() {
    return this.api.getSfOpportunityRequiredObjectFields();
  }
}
