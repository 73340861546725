/* tslint:disable */
/* eslint-disable */
/**
 * openapi
 * bales cloud
 *
 * The version of the OpenAPI document: 1.0
 * Contact: test@example.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CallTargetCustomFieldValueBody } from '../models';
/**
 * CustomFieldValueApi - axios parameter creator
 * @export
 */
export const CustomFieldValueApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * カスタム情報複数選択削除API
         * @summary カスタム情報複数選択削除API
         * @param {number} callTargetId リードID
         * @param {CallTargetCustomFieldValueBody} [callTargetCustomFieldValueBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCallTargetCustomFieldValue: async (callTargetId: number, callTargetCustomFieldValueBody?: CallTargetCustomFieldValueBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'callTargetId' is not null or undefined
            assertParamExists('deleteCallTargetCustomFieldValue', 'callTargetId', callTargetId)
            const localVarPath = `/api/user/call_targets/${callTargetId}/custom_field_value/multi_option`
                .replace(`{${"callTargetId"}}`, encodeURIComponent(String(callTargetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(callTargetCustomFieldValueBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * カスタム情報複数選択登録API
         * @summary カスタム情報複数選択登録API
         * @param {number} callTargetId リードID
         * @param {CallTargetCustomFieldValueBody} [callTargetCustomFieldValueBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCallTargetCustomFieldValue: async (callTargetId: number, callTargetCustomFieldValueBody?: CallTargetCustomFieldValueBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'callTargetId' is not null or undefined
            assertParamExists('postCallTargetCustomFieldValue', 'callTargetId', callTargetId)
            const localVarPath = `/api/user/call_targets/${callTargetId}/custom_field_value/multi_option`
                .replace(`{${"callTargetId"}}`, encodeURIComponent(String(callTargetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(callTargetCustomFieldValueBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomFieldValueApi - functional programming interface
 * @export
 */
export const CustomFieldValueApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomFieldValueApiAxiosParamCreator(configuration)
    return {
        /**
         * カスタム情報複数選択削除API
         * @summary カスタム情報複数選択削除API
         * @param {number} callTargetId リードID
         * @param {CallTargetCustomFieldValueBody} [callTargetCustomFieldValueBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCallTargetCustomFieldValue(callTargetId: number, callTargetCustomFieldValueBody?: CallTargetCustomFieldValueBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCallTargetCustomFieldValue(callTargetId, callTargetCustomFieldValueBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * カスタム情報複数選択登録API
         * @summary カスタム情報複数選択登録API
         * @param {number} callTargetId リードID
         * @param {CallTargetCustomFieldValueBody} [callTargetCustomFieldValueBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCallTargetCustomFieldValue(callTargetId: number, callTargetCustomFieldValueBody?: CallTargetCustomFieldValueBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCallTargetCustomFieldValue(callTargetId, callTargetCustomFieldValueBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomFieldValueApi - factory interface
 * @export
 */
export const CustomFieldValueApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomFieldValueApiFp(configuration)
    return {
        /**
         * カスタム情報複数選択削除API
         * @summary カスタム情報複数選択削除API
         * @param {number} callTargetId リードID
         * @param {CallTargetCustomFieldValueBody} [callTargetCustomFieldValueBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCallTargetCustomFieldValue(callTargetId: number, callTargetCustomFieldValueBody?: CallTargetCustomFieldValueBody, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCallTargetCustomFieldValue(callTargetId, callTargetCustomFieldValueBody, options).then((request) => request(axios, basePath));
        },
        /**
         * カスタム情報複数選択登録API
         * @summary カスタム情報複数選択登録API
         * @param {number} callTargetId リードID
         * @param {CallTargetCustomFieldValueBody} [callTargetCustomFieldValueBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCallTargetCustomFieldValue(callTargetId: number, callTargetCustomFieldValueBody?: CallTargetCustomFieldValueBody, options?: any): AxiosPromise<void> {
            return localVarFp.postCallTargetCustomFieldValue(callTargetId, callTargetCustomFieldValueBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomFieldValueApi - interface
 * @export
 * @interface CustomFieldValueApi
 */
export interface CustomFieldValueApiInterface {
    /**
     * カスタム情報複数選択削除API
     * @summary カスタム情報複数選択削除API
     * @param {number} callTargetId リードID
     * @param {CallTargetCustomFieldValueBody} [callTargetCustomFieldValueBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomFieldValueApiInterface
     */
    deleteCallTargetCustomFieldValue(callTargetId: number, callTargetCustomFieldValueBody?: CallTargetCustomFieldValueBody, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * カスタム情報複数選択登録API
     * @summary カスタム情報複数選択登録API
     * @param {number} callTargetId リードID
     * @param {CallTargetCustomFieldValueBody} [callTargetCustomFieldValueBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomFieldValueApiInterface
     */
    postCallTargetCustomFieldValue(callTargetId: number, callTargetCustomFieldValueBody?: CallTargetCustomFieldValueBody, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * Request parameters for deleteCallTargetCustomFieldValue operation in CustomFieldValueApi.
 * @export
 * @interface CustomFieldValueApiDeleteCallTargetCustomFieldValueRequest
 */
export interface CustomFieldValueApiDeleteCallTargetCustomFieldValueRequest {
    /**
     * リードID
     * @type {number}
     * @memberof CustomFieldValueApiDeleteCallTargetCustomFieldValue
     */
    readonly callTargetId: number

    /**
     * 
     * @type {CallTargetCustomFieldValueBody}
     * @memberof CustomFieldValueApiDeleteCallTargetCustomFieldValue
     */
    readonly callTargetCustomFieldValueBody?: CallTargetCustomFieldValueBody
}

/**
 * Request parameters for postCallTargetCustomFieldValue operation in CustomFieldValueApi.
 * @export
 * @interface CustomFieldValueApiPostCallTargetCustomFieldValueRequest
 */
export interface CustomFieldValueApiPostCallTargetCustomFieldValueRequest {
    /**
     * リードID
     * @type {number}
     * @memberof CustomFieldValueApiPostCallTargetCustomFieldValue
     */
    readonly callTargetId: number

    /**
     * 
     * @type {CallTargetCustomFieldValueBody}
     * @memberof CustomFieldValueApiPostCallTargetCustomFieldValue
     */
    readonly callTargetCustomFieldValueBody?: CallTargetCustomFieldValueBody
}

/**
 * CustomFieldValueApi - object-oriented interface
 * @export
 * @class CustomFieldValueApi
 * @extends {BaseAPI}
 */
export class CustomFieldValueApi extends BaseAPI implements CustomFieldValueApiInterface {
    /**
     * カスタム情報複数選択削除API
     * @summary カスタム情報複数選択削除API
     * @param {CustomFieldValueApiDeleteCallTargetCustomFieldValueRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomFieldValueApi
     */
    public deleteCallTargetCustomFieldValue(requestParameters: CustomFieldValueApiDeleteCallTargetCustomFieldValueRequest, options?: AxiosRequestConfig) {
        return CustomFieldValueApiFp(this.configuration).deleteCallTargetCustomFieldValue(requestParameters.callTargetId, requestParameters.callTargetCustomFieldValueBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * カスタム情報複数選択登録API
     * @summary カスタム情報複数選択登録API
     * @param {CustomFieldValueApiPostCallTargetCustomFieldValueRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomFieldValueApi
     */
    public postCallTargetCustomFieldValue(requestParameters: CustomFieldValueApiPostCallTargetCustomFieldValueRequest, options?: AxiosRequestConfig) {
        return CustomFieldValueApiFp(this.configuration).postCallTargetCustomFieldValue(requestParameters.callTargetId, requestParameters.callTargetCustomFieldValueBody, options).then((request) => request(this.axios, this.basePath));
    }
}
