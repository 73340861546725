<template>
  <BCard
    class="next-action-card"
    small
    background
  >
    <BTooltip
      top
      :content="$t('general.delete.text')"
    >
      <BBtn
        class="close-btn"
        size="small"
        fab
        flat
        @click="handleCloseNextAction"
      >
        <BIcon size="small">
          close
        </BIcon>
      </BBtn>
    </BTooltip>
    <NextActionFormBody
      v-model="internalCommonNextAction"
      v-model:is-valid="isCommonNextActionValid"
      :call-target-id="callTargetId"
      is-short
    >
      <template #dateTime>
        <BListItem class="mb-200">
          <template #header>
            <span>{{ $t('nextAction.reservedAt') }}</span>
          </template>
          <BInputDateTime
            v-model:date="reservedDate"
            v-model:time="reservedTime"
            is-step
            clearable
          />
        </BListItem>
      </template>
    </NextActionFormBody>
  </BCard>
</template>

<script lang="ts">
import NextActionFormBody, { TCommonNextAction } from '@/components/organisms/user/general/NextActionFormBody.vue';
import { formatShorterDateTime, mergeDateAndTime } from '@/utils/date-time';

export default {
  components: {
    NextActionFormBody,
  },
  props: {
    nextAction: {
      type: Object,
      default () {
        return {};
      },
    },
    isValid: {
      type: Boolean,
      default: false,
    },
    callTargetId: {
      type: Number,
      default: null,
    },
  },
  emits: [
    'update:nextAction',
    'update:isValid',
    'close',
  ],
  data(): { isCommonNextActionValid: boolean } {
    return {
      isCommonNextActionValid: false,
    };
  },
  computed: {
    internalCommonNextAction: {
      get(): TCommonNextAction {
        return {
          actionType: this.internalNextAction.actionType,
          priority: this.internalNextAction.priority,
          ownerId: this.internalNextAction.ownerId,
          content: this.internalNextAction.content,
        };
      },
      set(newVal: TCommonNextAction) {
        this.internalNextAction = {
          ...this.internalNextAction,
          ...newVal,
        };
      },
    },
    internalNextAction: {
      get () {
        return this.nextAction;
      },
      set (newVal) {
        this.$emit('update:nextAction', newVal);
      },
    },
    reservedDate: {
      get () {
        if (!this.nextAction.reservedAt) return;
        return new Date(this.nextAction.reservedAt);
      },
      set (newVal) {
        const date = newVal;
        const time = this.reservedTime ? this.reservedTime : newVal;
        const reservedAt = mergeDateAndTime(date, time);
        this.internalNextAction.reservedAt = reservedAt;
      },
    },
    reservedTime: {
      get () {
        if (!this.nextAction.reservedAt) return;
        return new Date(this.nextAction.reservedAt);
      },
      set (newVal) {
        const date = this.reservedDate ? this.reservedDate : newVal;
        const time = newVal;
        const reservedAt = mergeDateAndTime(date, time);
        this.internalNextAction.reservedAt = reservedAt;
      },
    },
    reservedAt () {
      return formatShorterDateTime(this.nextAction.reservedAt);
    },
  },
  watch: {
    isCommonNextActionValid(val) {
      this.$emit('update:isValid', val && this.nextAction.reservedAt != null);
    },
    'nextAction.reservedAt'(val) {
      this.$emit('update:isValid', this.isCommonNextActionValid && val != null);
    },
  },
  methods: {
    handleCloseNextAction() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
  .next-action-card {
    border: 1px solid $basecolor-warning;
  }

  .close-btn {
    position: absolute;
    top: $basespace-50;
    right: $basespace-100;
  }
</style>
