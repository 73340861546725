<template>
  <BHeightAdjuster>
    <BModalHeader
      :title="title"
      center
      @modal-close="hide"
    />
    <BModalBody
      v-loading="$wait.is(waitName)"
      :element-loading-text="loadingText"
    >
      <BContainer>
        <template v-if="currentState === state.importForm">
          <div class="import-message mb-400">
            <p>{{ $t('leadList.import.modalMessage') }}</p>
            <p class="sheet-name-description">
              {{ $t('leadList.import.sheetName') }}
            </p>
          </div>
          <BFileUploadArea
            ref="fileUploadArea"
            class="mt-400"
            :accept-data-type="acceptDataType"
            @upload="checkImportData"
          />
          <ExcelTemplateDownload
            v-if="acceptDataType === '.xlsx'"
            class="mt-600"
          />
        </template>
        <LeadImportConfirm
          v-if="currentState === state.confirm"
          :invalid-headers="invalidHeaders"
          :new-candidate-targets="newCandidateTargets"
          :import-count="importCount"
          @save="importData"
        />
        <template v-if="currentState === state.completed">
          <div class="done">
            <div class="loader" />
            <div class="title">
              {{ $t(`callList.upload.accept.title`) }}
            </div>
          </div>
        </template>
      </BContainer>
    </BModalBody>
    <BModalFooter
      v-if="currentState === state.confirm"
      class="modal-footer"
    >
      <BLayout
        class="mt-100"
        justify-center
        align-center
      >
        <BListItem class="additional-attr">
          <template #header>
            <span>{{ $t('leadList.import.linkedInformation') }}</span>
          </template>
          <BLayout>
            <BSelect
              v-bind="selectAttributes"
              v-model="selectedLeadSourceId"
              class="mr-400"
              :items="leadSources"
              :placeholder="$t('leadSource.title')"
            />
          </BLayout>
        </BListItem>
        <BBtn
          type="primary"
          :loading="$wait.is(waitName)"
          :disabled="newCandidateTargets.headers.length === 0 || newCandidateTargets.records.length === 0"
          class="import-btn"
          @click="importData"
        >
          {{ $t(`callList.upload.dataImport`) }}
        </BBtn>
      </BLayout>
    </BModalFooter>
  </BHeightAdjuster>
</template>

<script>
import { mapWaitingActions } from 'vue-wait';
import { mapGetters } from 'vuex';
import ApiBase from '@/api/base';
import Api from '@/api/user';
import ExcelTemplateDownload from '@/components/organisms/user/leadList/modal/ExcelTemplateDownload.vue';
import LeadImportConfirm from '@/components/organisms/user/leadList/modal/LeadImportConfirm.vue';
import errorHandler from '@/mixins/error_handler';
import EncodingService from '@/services/encoding_service';

export default {
  components: {
    LeadImportConfirm,
    ExcelTemplateDownload,
  },
  mixins: [errorHandler],
  props: {
    title: {
      type: String,
      default: '',
    },
    modalName: {
      type: String,
      default: '',
    },
    acceptDataType: {
      type: String,
      default: '.csv',
    },
  },
  data() {
    return {
      currentState: 0,
      selectedLeadSourceId: null,
      newCandidateTargets: {},
      importCount: 0,
      invalidHeaders: [],
      importCallTargetCount: 0,
      validCallTargetCount: 0,
      formData: null,
      loadingText: '',
    };
  },
  computed: {
    ...mapGetters('user', ['leadSources']),
    state() {
      return {
        importForm: 0,
        comfirm: 1,
        completed: 2,
      };
    },
    waitName() {
      return 'waiting';
    },
    selectAttributes() {
      return {
        'item-value': 'id',
        'item-text': 'name',
        'clearable': true,
      };
    },
  },
  watch: {
    currentState(value) {
      if (value === this.state.completed) {
        setTimeout(() => this.hide(), 2000);
      }
    },
  },
  created() {
    this.getLeadSourcesAction();
  },
  methods: {
    ...mapWaitingActions('user', {
      getLeadCsvImportTasksAction: 'getLeadCsvImportTasksWait',
      getLeadExcelImportTasksAction: 'getLeadExcelImportTasksWait',
      getLeadSourcesAction: 'getLeadSourcesWait',
    }),
    ...mapWaitingActions('userUi', {
      addPendingCsvImportTaskIdAction: 'addPendingCsvImportTaskIdWait',
      addPendingExcelImportTaskIdAction: 'addPendingExcelImportTaskIdWait',
    }),
    show() {
      this.$modal.show(this.modalName);
    },
    hide() {
      this.currentState = this.state.importForm;
      this.$modal.hide(this.modalName);
    },
    changeLoadingText(changeText) {
      this.loadingText = changeText;
    },
    async checkImportData(file) {
      this.changeLoadingText(this.$t(`callList.upload.uploadMessage`));
      const separatedFileName = file.name.split('.');
      const importFileExtension = `.${separatedFileName[separatedFileName.length - 1]}`;

      if (importFileExtension !== this.acceptDataType) {
        this.$bitterAlert.show({
          text: this.$t('import.errors.extension'),
          buttonsCancel: false,
        });
        return;
      }
      this.$wait.start(this.waitName);

      const reader = new FileReader();
      reader.onload = async (e) => {
        const blob = new EncodingService().encodeArrayBufferToBlob(e.target.result);
        // 単位はbyte, 2MBを指定
        const MAX_FILE_SIZE = 2000000;
        if (blob.size > MAX_FILE_SIZE) {
          this.onFileUploadFailed();
          this.$wait.end(this.waitName);
          this.overFileSizeError(this.$t('general.maxFileSize'));
          return false;
        }

        const result = await Api.createImportFileStorageAccessUrl({
          body: { fileName: file.name },
        }).catch((err) => {
          this.onFileUploadFailed();
          this.$wait.end(this.waitName);
          throw err;
        });

        const formData = new FormData();
        formData.append('file', file);
        formData.append('key', result.data.key);
        await ApiBase.unauthorizedPut(result.data.url, {
          body: file,
          errorHandlers: {
            403: this.fileUploadErrorHandler,
          },
        }).catch((err) => {
          this.onFileUploadFailed();
          this.$wait.end(this.waitName);
          throw err;
        });

        this.changeLoadingText(this.$t(`callList.upload.fileCheckMessage`));
        const checkImportDataApi = this.acceptDataType === '.csv' ? Api.checkImportCsvData : Api.checkImportExcelData;

        this.formData = formData;
        checkImportDataApi({
          body: formData,
          errorHandlers: {
            400: this.fileLengthErrorHandler,
            422: this.defaultHandler,
            500: this.defaultHandler,
          },
        })
          .then((res) => {
            const jobManagerId = res.data.jobManagerId;
            this.checkImportDataResult(jobManagerId);
            this.timeId = this.$setInterval(() => {
              this.checkImportDataResult(jobManagerId);
            }, 1000);
          })
          .catch((err) => {
            this.onFileUploadFailed();
            this.$wait.end(this.waitName);
          });
      };
      reader.readAsArrayBuffer(file);
    },
    importData() {
      this.$wait.start(this.waitName);
      const importDataApi = this.acceptDataType === '.csv' ? Api.importCsvData : Api.importExcelData;
      this.appendAdditionalData();
      importDataApi({ body: this.formData })
        .then((res) => {
          if (this.acceptDataType === '.csv') {
            this.addPendingCsvImportTaskIdAction(res.data.taskId);
            return this.getLeadCsvImportTasksAction();
          } else {
            this.addPendingExcelImportTaskIdAction(res.data.taskId);
            return this.getLeadExcelImportTasksAction();
          }
        })
        .then(() => {
          this.currentState = this.state.completed;
        })
        .finally(() => {
          this.$wait.end(this.waitName);
        });
    },
    async checkImportDataResult(jobManagerId) {
      try {
        const checkImportDataApi
          = this.acceptDataType === '.csv' ? Api.getLeadCsvCheckImportTasks : Api.getLeadExcelCheckImportTasks;
        const res = await checkImportDataApi({
          jobManagerId,
        });

        if (res?.data?.status == 'success') {
          const result = res.data.result;
          this.newCandidateTargets = {
            headers: result.valid_header,
            records: result.new_candidate_targets,
          };
          this.importCount = result.import_count;
          this.invalidHeaders = result.invalid_header;
          this.currentState = this.state.confirm;

          this.$clearInterval(this.timeId);
          this.$wait.end(this.waitName);
        } else if (res?.data?.status == 'error') {
          this.$bitterAlert.show({
            title: this.$t('general.error'),
            text: res?.data?.result?.message || this.$t('apiError.badRequest'),
            closeOnClickOutside: true,
            buttonsCancel: false,
          });
          this.$clearInterval(this.timeId);
          this.onFileUploadFailed();
          this.$wait.end(this.waitName);
        }
      } catch (e) {
        this.$wait.end(this.waitName);
        this.$clearInterval(this.timeId);
        this.onFileUploadFailed();
      }
    },
    appendAdditionalData() {
      this.formData.append('leadSourceId', this.selectedLeadSourceId);
    },
    onFileUploadFailed() {
      this.$refs.fileUploadArea.clear();
      this.formData = null;
    },
    overFileSizeError(fileSize) {
      this.$bitterAlert.show({
        title: this.$t('general.error'),
        text: this.$t('apiError.message.overFileSize', { size: fileSize }),
        closeOnClickOutside: true,
        buttonsCancel: false,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.import-message {
  text-align: center;
  color: $textcolor-light;
  background-color: $bgcolor-base;
  padding: $basespace-300 $basespace-200;
  border-radius: 4px;
  white-space: pre-wrap;
}

.lead-import-image {
  display: block;
  width: 280px;
  margin: 60px auto 0;
}

.modal-footer {
  background-color: $bgcolor-base;
}

.done {
  .title {
    text-align: center;
    margin-bottom: $basespace-400;
  }
  .loader,
  .loader:before,
  .loader:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: load7 1.8s infinite ease-in-out;
    animation: load7 1.8s infinite ease-in-out;
  }
  .loader {
    color: $basecolor-primary;
    font-size: 10px;
    margin: 80px auto;
    position: relative;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  .loader:before,
  .loader:after {
    content: '';
    position: absolute;
    top: 0;
  }
  .loader:before {
    left: -3.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  .loader:after {
    left: 3.5em;
  }
  @-webkit-keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }
  @keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }
}
.additional-attr {
  width: 500px !important;
}
.import-btn {
  margin-top: 18px;
}
.sheet-name-description {
  margin-bottom: 0;
}
</style>
