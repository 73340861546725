/* tslint:disable */
/* eslint-disable */
/**
 * openapi
 * bales cloud
 *
 * The version of the OpenAPI document: 1.0
 * Contact: test@example.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GetSequenceExecutionSetting200Response } from '../models';
// @ts-ignore
import { SequenceExecutionSetting } from '../models';
/**
 * SequenceSettingApi - axios parameter creator
 * @export
 */
export const SequenceSettingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * シーケンス実行日設定 取得API
         * @summary シーケンス実行日設定 取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSequenceExecutionSetting: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/sequence_execution_setting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * シーケンス実行日設定 更新API
         * @summary シーケンス実行日設定 更新API
         * @param {SequenceExecutionSetting} [sequenceExecutionSetting] シーケンス実行設定更新リクエストボディ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchSequenceExecutionSetting: async (sequenceExecutionSetting?: SequenceExecutionSetting, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/sequence_execution_setting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sequenceExecutionSetting, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SequenceSettingApi - functional programming interface
 * @export
 */
export const SequenceSettingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SequenceSettingApiAxiosParamCreator(configuration)
    return {
        /**
         * シーケンス実行日設定 取得API
         * @summary シーケンス実行日設定 取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSequenceExecutionSetting(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSequenceExecutionSetting200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSequenceExecutionSetting(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * シーケンス実行日設定 更新API
         * @summary シーケンス実行日設定 更新API
         * @param {SequenceExecutionSetting} [sequenceExecutionSetting] シーケンス実行設定更新リクエストボディ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchSequenceExecutionSetting(sequenceExecutionSetting?: SequenceExecutionSetting, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchSequenceExecutionSetting(sequenceExecutionSetting, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SequenceSettingApi - factory interface
 * @export
 */
export const SequenceSettingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SequenceSettingApiFp(configuration)
    return {
        /**
         * シーケンス実行日設定 取得API
         * @summary シーケンス実行日設定 取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSequenceExecutionSetting(options?: any): AxiosPromise<GetSequenceExecutionSetting200Response> {
            return localVarFp.getSequenceExecutionSetting(options).then((request) => request(axios, basePath));
        },
        /**
         * シーケンス実行日設定 更新API
         * @summary シーケンス実行日設定 更新API
         * @param {SequenceExecutionSetting} [sequenceExecutionSetting] シーケンス実行設定更新リクエストボディ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchSequenceExecutionSetting(sequenceExecutionSetting?: SequenceExecutionSetting, options?: any): AxiosPromise<void> {
            return localVarFp.patchSequenceExecutionSetting(sequenceExecutionSetting, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SequenceSettingApi - interface
 * @export
 * @interface SequenceSettingApi
 */
export interface SequenceSettingApiInterface {
    /**
     * シーケンス実行日設定 取得API
     * @summary シーケンス実行日設定 取得API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceSettingApiInterface
     */
    getSequenceExecutionSetting(options?: AxiosRequestConfig): AxiosPromise<GetSequenceExecutionSetting200Response>;

    /**
     * シーケンス実行日設定 更新API
     * @summary シーケンス実行日設定 更新API
     * @param {SequenceExecutionSetting} [sequenceExecutionSetting] シーケンス実行設定更新リクエストボディ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceSettingApiInterface
     */
    patchSequenceExecutionSetting(sequenceExecutionSetting?: SequenceExecutionSetting, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * Request parameters for patchSequenceExecutionSetting operation in SequenceSettingApi.
 * @export
 * @interface SequenceSettingApiPatchSequenceExecutionSettingRequest
 */
export interface SequenceSettingApiPatchSequenceExecutionSettingRequest {
    /**
     * シーケンス実行設定更新リクエストボディ
     * @type {SequenceExecutionSetting}
     * @memberof SequenceSettingApiPatchSequenceExecutionSetting
     */
    readonly sequenceExecutionSetting?: SequenceExecutionSetting
}

/**
 * SequenceSettingApi - object-oriented interface
 * @export
 * @class SequenceSettingApi
 * @extends {BaseAPI}
 */
export class SequenceSettingApi extends BaseAPI implements SequenceSettingApiInterface {
    /**
     * シーケンス実行日設定 取得API
     * @summary シーケンス実行日設定 取得API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceSettingApi
     */
    public getSequenceExecutionSetting(options?: AxiosRequestConfig) {
        return SequenceSettingApiFp(this.configuration).getSequenceExecutionSetting(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * シーケンス実行日設定 更新API
     * @summary シーケンス実行日設定 更新API
     * @param {SequenceSettingApiPatchSequenceExecutionSettingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceSettingApi
     */
    public patchSequenceExecutionSetting(requestParameters: SequenceSettingApiPatchSequenceExecutionSettingRequest = {}, options?: AxiosRequestConfig) {
        return SequenceSettingApiFp(this.configuration).patchSequenceExecutionSetting(requestParameters.sequenceExecutionSetting, options).then((request) => request(this.axios, this.basePath));
    }
}
