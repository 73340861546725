<template>
  <CommonTask
    :content="content"
    :owner="directMail.owner"
    :title="$t('sendMailDirect.title')"
    :date-time="dateTime"
    :is-sequence="isSequenceDirectMail"
    :is-deletable="isDeletable"
    :is-content-editable="false"
    :link-to-text="$t('general.confirm.do')"
    @click:delete="handleDelete"
  >
    <template #status>
      <BStatus
        :status="directMail.status"
        :status-name="$t(`mail.status.${directMail.status}`)"
      />
    </template>
  </CommonTask>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { DirectMail, ReserveDirectMail } from '@/api/openapi';
import { useCheckRelationToSequence } from '@/composable/user/directMail/direct-mail';
import { i18nGlobal as i18n } from '@/i18n';
import { bitterAlert } from '@/plugins/BBitterAlert';
import { key } from '@/store/index';
import CommonTask from './CommonTask.vue';

type TProps = {
  directMail: DirectMail | ReserveDirectMail;
};

const props = defineProps<TProps>();
const emit = defineEmits(['afterDelete']);

const { isSequenceDirectMail } = useCheckRelationToSequence(() => props.directMail);
const store = useStore(key);

const isDeletable = computed(() => !isSequenceDirectMail.value);
const content = computed(() => `${i18n.t('mail.titlePlaceholder')}：${props.directMail.subject}`);
const displayDatetimeColumn = computed(() => props.directMail.status === 'draft' ? 'updatedAt' : 'reservedAt');
const dateTime = computed(() => props.directMail[displayDatetimeColumn.value]);

const handleDelete = async () => {
  if (!props.directMail.id) { return; }
  const check = await bitterAlert.show({
    title: i18n.t('sfTaskParams.directMail.subject'),
    text: i18n.t('general.confirm.of', { action: i18n.t('general.delete.text') }),
  });
  if (!check) { return; }
  try {
    await store.dispatch('user/deleteDirectMailAction', {
      request: { directMailId: props.directMail.id },
    });
  } finally {
    emit('afterDelete');
  }
};
</script>
