import ApiBase from '@/api/base';

export default {
  searchSalesforceReport (args) {
    const clientId = args.clientId;
    const reportId = args.reportId;
    return ApiBase.get(`/admin/clients/${clientId}/salesforce/reports?report_id=${reportId}`);
  },
  searchSalesforceObject (args) {
    const clientId = args.clientId;
    const objectId = args.objectId;
    const targetObject = args.targetObject;
    return ApiBase.get(`/admin/clients/${clientId}/salesforce/object?id=${objectId}&target_object=${targetObject}`);
  },
  searchSalesforceDescribe (args) {
    const clientId = args.clientId;
    const targetObject = args.targetObject;
    return ApiBase.get(`/admin/clients/${clientId}/salesforce/describe?target_object=${targetObject}`);
  },
};
