import Waiting from '@/store/waiting';

export default class CallResultActions {
  constructor (Api, types) {
    const actions = {
      async createCallResultAction ({ commit, dispatch, state }, args) {
        await waiting.check('createCallResultAction', async () => {
          const res = await Api.createCallResult(args);

          await dispatch('createCallResultSfTaskAction', {
            body: {
              callTargetId: args.callTargetId,
              targetId: res.data.id,
            },
          });

          // TODO NextAction時には一旦Taskを作成しない方針に(2021/11)
          // for await (const nextAction of (res.data.nextActions || [])) {
          //   await dispatch('createNextActionSfTaskAction', {
          //     body: {
          //       callTargetId: args.callTargetId,
          //       targetId: nextAction.id,
          //     },
          //   })
          // }

          try {
            const res = await Api.getLeadHistories(args);
            commit(types.SET_LEAD_HISTORIES, Object.values(res.data)[0]);
          } catch (err) {
            throw err;
          }
        });
      },
      async updateCallResultAction ({ commit, dispatch }, args) {
        await waiting.check('updateCallResultAction', async () => {
          await Api.updateCallResult(args);
          try {
            const res = await Api.getLeadHistories(args);
            commit(types.SET_LEAD_HISTORIES, Object.values(res.data)[0]);
          } catch (err) {
            throw err;
          }
        });
      },
      async deleteCallResultAction ({ commit }, args) {
        await waiting.check('deleteCallResultAction', async () => {
          await Api.deleteCallResult(args);
          try {
            const res = await Api.getLeadHistories(args);
            commit(types.SET_LEAD_HISTORIES, Object.values(res.data)[0]);
          } catch (err) {
            throw err;
          }
        });
      },
    };
    const waiting = new Waiting(actions);
    this.actions = actions;
  }
}
