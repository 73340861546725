<template>
  <div
    class="signin-container"
    :style="styles"
  >
    <div class="signin-card">
      <div class="signin-card-inner">
        <div class="signin-symbol-icon">
          <img
            :src="symbolIconSrc"
            height="103"
          >
        </div>
        <div class="signin-logo">
          <img
            :src="logoSrc"
            style="width: 100%; height: 60px"
          >
        </div>
        <div class="signin-message">
          {{ $t('auth.resetPageMessage') }}
        </div>
        <div class="signin-form">
          <div class="mb-600">
            <BInput
              v-model="email"
              :placeholder="$t('auth.email')"
              color="focus"
            />
          </div>
          <BBtn
            class="mb-400"
            :loading="$wait.is('USER_RESET_PASSWORD_WAIT')"
            :disabled="!isValidEmail"
            type="primary"
            @click.prevent="resetPassword"
          >
            <span>{{ $t('auth.submit') }}</span>
          </BBtn>
          <BLayout
            align-center
            justify-center
          >
            <RouterLink :to="{ name: 'UserSignin' }">
              {{ $t('auth.returnSignin') }}
            </RouterLink>
          </BLayout>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import symbolIconSrc from '@/assets/bales_cloud_fire_signin.svg';
import logoSrc from '@/assets/brand_logo.svg';
import backgroundImage from '@/assets/signin-background_2.png';
import inputValidation from '@/mixins/input_validation';
import AuthService from '@/services/auth_service';

export default defineComponent({
  mixins: [inputValidation],
  setup() {
    return {
      symbolIconSrc,
      logoSrc,
      backgroundImage,
    };
  },
  data() {
    return {
      email: '',
    };
  },
  computed: {
    isValidEmail() {
      return this.email.length > 0;
    },
    styles() {
      return {
        backgroundImage: `url('${this.backgroundImage}')`,
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover',
      };
    },
  },
  created() {
    this.authService = new AuthService();
  },
  methods: {
    async resetPassword() {
      this.$wait.start('USER_RESET_PASSWORD_WAIT');
      let bitterAlertTitle = this.$t('auth.resetPassword');
      let bitterAlertText = this.$t('auth.resetPasswordMessage');
      try {
        await this.authService.resetPassword(this.email);
        this.$router.push({ name: 'UserSignin' });
      } catch (error) {
        bitterAlertTitle = this.$t('auth.error.resetPasswordError');
        switch (error.code) {
          case 'auth/invalid-email':
          case 'auth/user-not-found':
            bitterAlertText = this.$t('auth.errorMessage.wrongEmail');
            break;
          case 'auth/user-disabled':
            bitterAlertText = this.$t('auth.errorMessage.userDisabled');
            break;
          case 'auth/too-many-requests':
            bitterAlertText = this.$t('auth.errorMessage.tooManyRequests');
            break;
          default:
            bitterAlertText = error.message;
        }
      } finally {
        this.$bitterAlert.show({
          title: bitterAlertTitle,
          text: bitterAlertText,
          closeOnClickOutside: true,
          buttonsCancel: false,
        });
        this.$wait.end('USER_RESET_PASSWORD_WAIT');
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.signin-card {
  background-color: rgba($paper, 0.9);
  width: 480px;
  height: 100%;
  margin: 0 auto;
  box-shadow: $boxshadow-base;
  display: flex;
  padding-top: 60px;
  justify-content: center;
}

.signin-card-inner {
  display: flex;
  flex-direction: column;
  width: 280px;
}

.signin-message {
  text-align: center;
  font-weight: bold;
  font-size: $fontsize-300;
  margin-bottom: $basespace-400;
}

.signin-symbol-icon {
  margin: 0 auto;
}

.signin-logo {
  margin: $basespace-800 auto $basespace-300 auto;
}

.signin-container {
  background-color: #f3fdff;
  height: 100vh;
}

.signin-form {
  text-align: center;
}
</style>
