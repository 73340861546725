import { AxiosPromise } from 'axios';
import ApiService, { ApiArguments } from '@/api/api_service';
import {
  PartialSequenceStepMaster,
  SequenceStepMaster,
  SequenceStepMasterApi,
  SequenceStepMasterApiGetSequenceStepMastersRequest,
} from '@/api/openapi';

type SequenceStepMasterApiDefaultGetSequenceStepMastersRequest = Exclude<SequenceStepMasterApiGetSequenceStepMastersRequest, 'fields'>;

export class SequenceStepMasterApiService extends ApiService {
  api = new SequenceStepMasterApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true);
  }

  async getSequenceStepMasters({
    request,
    options,
    errorHandlers,
  }: ApiArguments<SequenceStepMasterApiDefaultGetSequenceStepMastersRequest>) {
    const { fields, ...rest } = request;
    return this.checkResponse<SequenceStepMaster[]>(
      this.api.getSequenceStepMasters(rest, options) as AxiosPromise<SequenceStepMaster[]>,
      errorHandlers,
    );
  }

  async getSequenceStepMastersBySpecifyingFields({
    request,
    options,
    errorHandlers,
  }: ApiArguments<SequenceStepMasterApiGetSequenceStepMastersRequest>) {
    return this.checkResponse<PartialSequenceStepMaster[]>(
      this.api.getSequenceStepMasters(request, options),
      errorHandlers,
    );
  }
}
