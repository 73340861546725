<template>
  <BContentBox>
    <template #content>
      <div class="content">
        <div class="pl-600 pb-100">
          <span class="header-text">{{ $t("sfObjectMappings.targetObjectLabel") }}</span>
        </div>
        <div class="hr" />
        <div
          v-loading="salesforceSettingLoading"
        >
          <div
            v-for="(mappingKey, index) in sfObjectMappingsKeys"
            :key="index"
            class="py-300 pl-600 select-object"
            @click="() => selectObject(mappingKey)"
          >
            <span class="mapping-key-text">{{ $t(`sfObjectMappings.${mappingKey}`) }}</span>
          </div>
        </div>
        <div class="hr" />
      </div>
    </template>
  </BContentBox>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { SfObjectMappingService } from '@/api/user/resources/salesforce/sf_object_mapping';

const emit = defineEmits(['selectObject']);
const apiService = new SfObjectMappingService();
const salesforceSettingLoading = ref(true);
const sfObjectMappingsKeys = ref([]);
const selectObject = (object: string) => {
  emit('selectObject', object);
};

onMounted(async () => {
  const result = await apiService.getSfObjectMappingsKeys();
  sfObjectMappingsKeys.value = result.data.sfObjectMappingsKeys;
  salesforceSettingLoading.value = false;
});
</script>

<style lang="scss" scoped>
.content {
  position: absolute;
  top: auto;
  left: 0;
  width: 100%;
}

.header-text {
  color: $textcolor-light;
}

.mapping-key-text {
  font-weight: bold;
}

.select-object {
  cursor: pointer;

  &:first-child {
    margin-top: -8px;
  }
  &:not(:last-child) {
    border-bottom: 1px solid $bdcolor-base;
  }
}

.hr {
  border-bottom: 1px solid $bdcolor-base;
  margin-bottom: 8px;
}
</style>
