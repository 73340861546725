<template>
  <div class="icon-text">
    <template v-if="tooltip">
      <BTooltip top>
        <BIcon
          :class="iconClasses"
          :size="iconSize"
        >
          {{ icon }}
        </BIcon>
        <template #content>
          <span>{{ tooltip }}</span>
        </template>
      </BTooltip>
    </template>
    <template v-else>
      <BIcon
        :class="iconClasses"
        :size="iconSize"
      >
        {{ icon }}
      </BIcon>
    </template>
    <div :class="textClasses">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BIconText',
  props: {
    icon: {
      type: String,
      required: true,
    },
    tooltip: {
      type: String,
      default: '',
    },
    small: Boolean,
    large: Boolean,
    primary: Boolean,
  },
  computed: {
    textClasses () {
      return {
        'inner-text': true,
        'inner-text-small': this.small,
        'inner-text-large': this.large,
        'inner-text-primary': this.primary,
        'inner-text-tooltip': this.tooltip,
        'inner-text-small-tooltip': this.tooltip && this.small,
        'inner-text-large-tooltip': this.tooltip && this.large,
      };
    },
    iconClasses () {
      return {
        'b-icon': true,
        'icon-primary': this.primary,
        'icon-secondary': this.secondary,
      };
    },
    iconSize () {
      if (this.small) return 'small';
      if (this.large) return 'large';
      return 'medium';
    },
  },
};
</script>

<style lang="scss" scoped>
  .icon-text {
    display: flex;
    align-items: center;
    & > *+* {
      margin-left: 4px;
    }
  }
  .inner-text {
    line-height: $fontsize-200;
    vertical-align: middle;
  }
  .inner-text-tooltip {
    padding-bottom: 2px;
  }
  .inner-text-small-tooltip, .inner-text-large-tooltip {
    padding-bottom: 3px;
  }
  .inner-text-small {
    font-size: $fontsize-100;
    line-height: $fontsize-100;
  }
  .inner-text-large {
    font-size: $fontsize-300;
    line-height: $fontsize-300;
  }
  .inner-text-primary {
    color: $basecolor-primary;
  }
  .b-icon {
    color: $textcolor-base;
  }
  .icon-primary {
    color: $basecolor-primary;
  }
</style>
