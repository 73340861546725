<template>
  <div class="select-user">
    <BSelect
      v-model="model"
      :items="userItems"
      :placeholder="$t('sequence.selectOwner.placeholder')"
      item-text="text"
      item-value="value"
      filterable
      class="type-select"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useUsers } from '@/composable/user/user/users';
import { TGroups } from '@/plugins/biscuet-materials/components/atoms/BSelect.vue';

export type TSequenceOwnerValue = number | 'leadOwner' | 'sequenceOwner' | undefined;

const model = defineModel<TSequenceOwnerValue>({
  default: 'sequenceOwner',
  set(value) {
    return value == null ? 'sequenceOwner' : value;
  },
});

const i18n = useI18n();

const { activeUsers } = useUsers();
const userItems = computed<TGroups>(() => {
  return [
    {
      key: 'owners',
      label: i18n.t('sequence.selectOwner.owners'),
      options: [
        { text: i18n.t('sequence.selectOwner.leadOwner'), value: 'leadOwner' },
        { text: i18n.t('sequence.selectOwner.sequenceOwner'), value: 'sequenceOwner' },
      ],
    },
    {
      key: 'users',
      label: i18n.t('sequence.selectOwner.users'),
      options: activeUsers.value.map((candidate) => (
        { text: candidate.name, value: candidate.id }
      )),
    },
  ];
});
</script>

<style lang="scss" scoped>
.select-user {
  position: sticky;
  left: 0;
  .type-select {
    width: 160px;
  }
}
</style>
