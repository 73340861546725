export default {
  currentUser(state) {
    return state.currentUser;
  },
  admin(state) {
    return state.admin;
  },
  user(state) {
    return state.user;
  },
  users(state) {
    return state.users;
  },
  consultantUsers(state) {
    return state.consultantUsers;
  },
  client(state) {
    return state.client;
  },
  freeTags(state) {
    return state.freeTags;
  },
  sfCampaigns(state) {
    return state.sfCampaigns;
  },
  callList(state) {
    return state.callList;
  },
  callLists(state) {
    return state.callLists;
  },
  allCallLists(state) {
    return state.allCallLists;
  },
  leadCsvImportTask(state) {
    return state.leadCsvImportTask;
  },
  leadCsvImportTasks(state) {
    return state.leadCsvImportTasks;
  },
  leadExcelImportTask(state) {
    return state.leadExcelImportTask;
  },
  leadExcelImportTasks(state) {
    return state.leadExcelImportTasks;
  },
  leadCsvOverwriteTask(state) {
    return state.leadCsvOverwriteTask;
  },
  leadCsvOverwriteTasks(state) {
    return state.leadCsvOverwriteTasks;
  },
  leadExcelOverwriteTask(state) {
    return state.leadExcelOverwriteTask;
  },
  leadExcelOverwriteTasks(state) {
    return state.leadExcelOverwriteTasks;
  },
  leadExportTasks(state) {
    return state.leadExportTasks;
  },
  leadDeleteTasks(state) {
    return state.leadDeleteTasks;
  },
  sfLeadDataCheckTasks(state) {
    return state.sfLeadDataCheckTasks;
  },
  customFields(state) {
    return state.customFields;
  },
  callTarget(state) {
    return state.callTarget;
  },
  callTargets(state) {
    return state.callTargets;
  },
  callTargetsMeta(state) {
    return state.callTargetsMeta;
  },
  fulltextSearchResult(state) {
    return state.fulltextSearchResult;
  },
  callTargetFreeTags(state) {
    return state.callTargetFreeTags;
  },
  callTargetNotes(state) {
    return state.callTargetNotes;
  },
  callResultTypes(state) {
    return state.callResultTypes;
  },
  defaultCallResultTypes(state) {
    return state.defaultCallResultTypes.filter((type) => type.connected);
  },
  notConnectedCallResultTypes(state) {
    return state.defaultCallResultTypes.filter((type) => !type.connected);
  },
  nextActions(state) {
    return state.nextActions;
  },
  nextActionTypes(state) {
    return state.nextActionTypes;
  },
  defaultNextActionTypes(state) {
    return state.defaultNextActionTypes;
  },
  defaultNextActionTypesSelectableByUser(state) {
    return state.defaultNextActionTypes.filter(e => !e.isNotSelectableByUser);
  },
  defaultNextActionTypesNotSelectableByUser(state) {
    return state.defaultNextActionTypes.filter(e => e.isNotSelectableByUser);
  },
  opportunities(state) {
    return state.opportunities;
  },
  reminder(state) {
    return state.reminder;
  },
  mailNotifications(state) {
    return state.mailNotifications;
  },
  mailNotificationCount(state) {
    return state.mailNotificationCount;
  },
  mailTemplate(state) {
    return state.mailTemplate;
  },
  mailTemplates(state) {
    return state.mailTemplates;
  },
  todayActions(state) {
    return state.reminder.todayActions || [];
  },
  miitelEnabled(state) {
    return !!state.miitelAuth.companyId && !!state.miitelAuth.accessKey;
  },
  miitelAuth(state) {
    return state.miitelAuth;
  },
  miitelCompanyId(state) {
    return state.miitelAuth.companyId;
  },
  miitelAccessKey(state) {
    return state.miitelAuth.accessKey;
  },
  callAnalytics(state) {
    return state.callAnalytics;
  },
  allUserCallAnalytics(state) {
    return state.allUserCallAnalytics;
  },
  callResultCallAnalytics(state) {
    return state.callResultCallAnalytics;
  },
  revisionCheck(state) {
    // MEMO: revisionId === 0 はデフォルト値 または アクション失敗時
    if (state.revisionId === 0) return true;
    return state.revisionId === import.meta.env.VITE_REVISION_ID;
  },
  leadSources(state) {
    return state.leadSources;
  },
  leadStages(state) {
    return state.leadStages;
  },
  callTargetLeadSources(state) {
    return state.callTargetLeadSources;
  },
  leadHistories(state) {
    return state.leadHistories;
  },
  businessCategoryCandidates(state) {
    return state.businessCategoryCandidates;
  },
  positionCandidates(state) {
    return state.positionCandidates;
  },
  departmentCandidates(state) {
    return state.departmentCandidates;
  },
  assignedUserCandidates(state) {
    return state.assignedUserCandidates;
  },
  leadViews(state) {
    return state.leadViews;
  },
  leadView(state) {
    return state.leadView;
  },
  callTargetUnsubscribe(state) {
    return state.callTargetUnsubscribe;
  },
  mailLeadCount(state) {
    return state.mailLeadCount;
  },
  bulkMails(state) {
    return state.bulkMails;
  },
  bulkMailsMeta(state) {
    return state.bulkMailsMeta;
  },
  bulkMail(state) {
    return state.bulkMail;
  },
  bulkMailCampaignNames(state) {
    return state.bulkMailCampaignNames;
  },
  availableFeatures(state) {
    return state.availableFeatures;
  },
  sendgridEvents(state) {
    return state.sendgridEvents;
  },
  callTargetsCount(state) {
    return state.callTargetsCount;
  },
  operator(state) {
    return state.operator;
  },
  salesforceSetting(state) {
    return state.salesforceSetting;
  },
  sfUsers(state) {
    return state.sfUsers;
  },
  sfOpportunity(state) {
    return state.sfOpportunity;
  },
  boxilLeadImportSetting(state) {
    return state.boxilLeadImportSetting;
  },
  sfReportImportTask(state) {
    return state.sfReportImportTask;
  },
  sfReportImportTasks(state) {
    return state.sfReportImportTasks;
  },
  sfStatuses(state) {
    return state.sfStatuses;
  },
  sfAvailableMappingFields(state) {
    return state.sfAvailableMappingFields;
  },
};
