<template>
  <CtiOpenButton
    :tooltip-message="$t('callTarget.header.zoomPhone')"
    :disabled="disable"
    @click="handleClick"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapWaitingActions } from 'vue-wait';
import { mapGetters } from 'vuex';
import CtiOpenButton from './CtiOpenButton.vue';

export default defineComponent({
  components: {
    CtiOpenButton,
  },
  computed: {
    ...mapGetters('userUi', [
      'zoomPhoneWidgetVisible',
    ]),
    disable () {
      return this.zoomPhoneWidgetVisible;
    },
  },
  methods: {
    ...mapWaitingActions('userUi', {
      setZoomPhoneWidgetVisibleAction: 'setZoomPhoneWidgetVisibleWait',
    }),
    handleClick () {
      this.setZoomPhoneWidgetVisibleAction(true);
    },
  },
});
</script>
