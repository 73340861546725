import { ActionTree } from 'vuex';
import { ApiArguments } from '@/api/api_service';
import {
  SequenceInstanceApiGetSequenceInstanceRequest,
  SequenceInstanceApiGetSequenceInstancesRequest,
} from '@/api/openapi';
import { SequenceInstanceApiService } from '@/api/user/resources/sequence_instance';
import { RootState, UserState } from '@/store/types';
import Waiting from '@/store/waiting';

const actions: ActionTree<UserState, RootState> = {
  getSequenceInstancesAction: async (
    _context,
    apiArguments: ApiArguments<SequenceInstanceApiGetSequenceInstancesRequest>,
  ) => {
    return await waiting.checkWithArg('getSequenceInstancesAction', apiArguments, async () => {
      const api = new SequenceInstanceApiService();
      return await api.getSequenceInstances(apiArguments);
    });
  },
  getSequenceInstanceAction: async (
    _context,
    apiArguments: ApiArguments<SequenceInstanceApiGetSequenceInstanceRequest>,
  ) => {
    return await waiting.check('getSequenceInstanceAction', async () => {
      const api = new SequenceInstanceApiService();
      const res = await api.getSequenceInstance(apiArguments);
      return res.data;
    });
  },
};

const waiting = new Waiting(actions);

export default actions;
