<template>
  <div
    v-loading="$wait.is(['getQuickTextsWait', 'deleteQuickTextWait'])"
    class="quick-texts"
  >
    <div class="navbar">
      <div class="description">
        {{ $t('quickText.description') }}
      </div>
      <div class="navbar-left">
        <BPopover
          :disabled="canCreate"
          :content="$t('quickText.exceededQuickTextLimit')"
        >
          <template #reference>
            <button
              type="button"
              class="create-button"
              :disabled="!canCreate"
              @click="handleClickNew"
            >
              <span>{{ $t('general.create.to', { item: $t('quickText.title') }) }}</span>
            </button>
          </template>
        </BPopover>
      </div>
    </div>
    <div class="content mt-50">
      <QuickTextList
        :quick-texts="quickTexts"
        @click:row="handleClickEdit"
        @after-delete="handleChangeQuickTextList"
      /> 
    </div>
    <QuickTextFormDrawer
      v-model:is-show="isFormShow"
      :quick-text-id="selectedId"
      @after-save="handleChangeQuickTextList"
      @update:is-changed="handleIsChangedUpdate"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref, onBeforeMount, computed, nextTick } from 'vue';
import { PaginationMeta, QuickText } from '@/api/openapi';
import { QuickTextApiService } from '@/api/user/resources/quick_text';
import { useAlert, useUnsavedConfirm } from '@/composable/message-dialog';
import { useWait } from '@/composable/vue-wait';
import { i18nGlobal as i18n } from '@/i18n';
import QuickTextFormDrawer from './quickTexts/QuickTextFormDrawer.vue';
import QuickTextList from './quickTexts/QuickTextList.vue';

// NOTE: 現状固定
const NUMBER_OF_QUICK_TEXT_LIMIT = 10;
const { doActionWithWait } = useWait();
const { alert } = useAlert();
const { unsavedConfirm } = useUnsavedConfirm();
const api = new QuickTextApiService();

const quickTexts = ref<QuickText[]>([]);
const currentPage = ref(1);
const meta = ref<PaginationMeta>({
  totalPages: 1,
  totalCount: 0,
  limitValue: 0,
  currentPage: 0,
  isEmpty: false,
});
const isFormShow = ref<boolean>(false);
const selectedId = ref<number>(undefined);
const isFormValueChanged = ref<boolean>(false);

const canCreate = computed(() => {
  return meta.value.totalCount < NUMBER_OF_QUICK_TEXT_LIMIT;
});

onBeforeMount(() => {
  loadQuickTexts(currentPage.value);
});

const loadQuickTexts = async (currentPage: number) => {
  await doActionWithWait('getQuickTextsWait', async () => {
    const { data } = await api.getQuickTexts({ request: { currentPage } });
    quickTexts.value = data.quickTexts;
    meta.value = data.meta;
  });
};

// NOTE: 現状上限数10固定なのでページング不要。ページング機能を除いておく
// watch(() => currentPage.value, (newValue) => {
//   loadQuickTexts(newValue);
// });

const handleClickNew = async () => {
  if (!canCreate.value) {
    await alert({
      title: i18n.t('general.error'),
      message: i18n.t('quickText.exceededQuickTextLimit'),
    });
    
    return;
  }

  showForm();
};
const handleClickEdit = (id: number) => {
  showForm(id);
};
const showForm = async (id?: number) => {
  if (isFormShow.value && isFormValueChanged.value) {
    const ok = await unsavedConfirm();
    if (!ok) return;
  }
  isFormShow.value = false;
  await nextTick();

  selectedId.value = id;
  isFormShow.value = true;
};

const handleChangeQuickTextList = () => {
  loadQuickTexts(currentPage.value);
};
const handleIsChangedUpdate = (isChanged: boolean) => {
  isFormValueChanged.value = isChanged;
};
</script>

<style lang="scss" scoped>
.content {
  height: calc(100% - 103px);
  overflow: auto;
}
.navbar {
  padding: 10px 32px;
  .description {
    color: $textcolor-light;
    padding: 10px 0 16px 0;
  }
  .navbar-left {
    display: flex;
    .create-button {
      position: relative;
      width: 160px;
      height: 37px;
      color: $textcolor-white;
      background-color: $basecolor-primary;
      border-radius: 20px;
      outline: none;
    }
  }
}
</style>

