<template>
  <LeadLink
    :call-target-id="callTargetId"
    tag="div"
    class="mail-notification-item"
  >
    <BTooltip top>
      <BLabel
        type="primary"
        class="event ml-400"
        :style="getColumnStyles(columnWidth[0])"
        round
      >
        <BIcon
          type="white"
          size="small"
        >
          {{ icon }}
        </BIcon>
      </BLabel>
      <template #content>
        <span>{{ $t('mailNotificationEvent.eventCount', {count: item.eventCount}) }}</span>
      </template>
    </BTooltip>
    <span
      class="ml-400"
      :style="getColumnStyles(columnWidth[1])"
    > {{ item.event }} </span>
    <span
      class="truncate-text truncate-item ml-400"
      :style="getColumnStyles(columnWidth[2])"
    >{{ item.companyName }}</span>
    <div
      v-if="item.isBulkMail"
      class="ml-400"
      :style="getColumnStyles(columnWidth[3])"
    >
      <BLabel
        class="type-label bulk"
        round
      >
        <div class="truncate-text">
          {{ item.name }}
        </div>
      </BLabel>
    </div>
    <div
      v-else
      class="ml-400"
      :style="getColumnStyles(columnWidth[3])"
    >
      <BLabel
        class="type-label"
        type="default"
        round
      >
        <div class="truncate-text truncate-item type-text">
          {{ item.name }}
        </div>
      </BLabel>
    </div>
    <div
      class="time ml-400"
      :style="getColumnStyles(columnWidth[5])"
    >
      {{ historiedAt }}
    </div>
    <BUserTooltip
      v-if="isExistUser"
      :image-url="item.sentUserAvatarUrl"
      :name="item.sentUserName"
      :user-id="item.sentUserId"
      class="user-icon"
      :size="40"
    />
  </LeadLink>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import LeadLink from '@/components/organisms/user/general/LeadLink.vue';
import { formatShorterTimeOrDateTime } from '@/utils/date-time';

export default defineComponent({
  components: {
    LeadLink,
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      columnWidth: [56, 100, 150, 280, 80, 100],
    };
  },
  computed: {
    historiedAt() {
      return formatShorterTimeOrDateTime(this.item.historiedAt);
    },
    isExistUser() {
      return this.item.sentUserAvatarUrl || this.item.sentUserName;
    },
    listStyle() {
      const totalWidth = this.columnWidth.reduce((accumulator, currentValue) => accumulator + currentValue);
      return `width: ${totalWidth}px; min-width: 100%; max-width: ${totalWidth}px;`;
    },
    icon() {
      if (this.item == null || this.item.localEvent == null) return 'remove';
      switch (this.item.localEvent) {
        case 'open':
          return 'drafts';
        case 'click':
          return 'touch_app';
        case 'delivered':
          return 'mail';
        case 'unsubscribe':
          return 'unsubscribe';
        default:
          return 'mail';
      }
      // FIXME: 到達し得ないコード。なぜこうなっているか不明
      // return 'remove'
    },
    callTargetId() {
      return this.item.callTargetId;
    },
  },
  methods: {
    getColumnStyles(width) {
      return `width: ${width}px; min-width: ${width}px; max-width: ${width}px;`;
    },
  },
});
</script>

<style lang="scss" scoped>
  .mail-notification-item {
    display: flex;
    color: $textcolor-base;
    text-decoration: none;
    padding: $basespace-200;
    height: 48px;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid $bdcolor-light;

    &:hover {
      background-color: $bgcolor-base;
    }
  }

  .confirmed-item {
    height: 8px;
    width: 8px;
  }

  .time {
    text-align: right;
    color: $textcolor-light;
    font-size: $fontsize-100;
    margin: 0 $basespace-400 0 $basespace-600;
  }

  .event {
    color: $textcolor-white;
    height: 19px;
    padding: 10px 20px 12px 20px;
  }

  .type-text {
    text-align: left;
  }

  .type-label {
    text-align: left;
    min-width: 62px;
    max-width: 100%;
    &.bulk {
      color: $textcolor-white;
      background-color: $basecolor-success;
    }
  }

  .company-name {
    max-width: 266px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .truncate-item {
    display: block;
    width: 100%;
  }
</style>
