<template>
  <BContentBox>
    <template #content>
      <BLayout
        v-loading="$wait.is(['updateProfile', 'prepareLoginEmailUpdate'])"
        column
        class="form"
      >
        <BListItem class="title">
          <span>{{ $t('user.userInfo') }}</span>
        </BListItem>
        <BListItem class="mt-400">
          <template #header>
            <span>{{ $t('user.avatarImage') }}</span>
          </template>
          <BAvatarUpload
            v-model="avatarImage"
            :image-url="currentUser?.avatarUrl"
          />
        </BListItem>
        <BListItem class="mt-400">
          <template #header>
            <span>{{ $t('user.name') }}</span>
          </template>
          <BInput
            v-model="inputName"
            v-model:valid="nameValid"
            class="w-input"
            :rules="[requiredRule]"
            clearable
          />
        </BListItem>
        <BListItem class="mt-400">
          <template #header>
            <span>{{ $t('user.lastName') }}</span>
          </template>
          <BInput
            v-model="inputLastName"
            v-model:valid="lastNameValid"
            class="w-input"
            :rules="[requiredRule]"
            clearable
          />
        </BListItem>
        <BListItem class="mt-200">
          <template #header>
            <span>{{ $t('user.firstName') }}</span>
          </template>
          <BInput
            v-model="inputFirstName"
            v-model:valid="firstNameValid"
            class="w-input"
            :rules="[requiredRule]"
            clearable
          />
        </BListItem>
        <BListItem class="mt-400">
          <BBtn
            :disabled="!isUserInfoValid"
            type="primary"
            @click="updateProfile"
          >
            {{ $t('general.save.text') }}
          </BBtn>
        </BListItem>
        <BListItem class="title mt-900">
          <span>{{ $t('user.loginEmail') }}</span>
        </BListItem>
        <BListItem class="mt-400">
          <template #header>
            <span>{{ $t('user.email') }}</span>
          </template>
          <BInput
            v-model="inputEmail"
            v-model:valid="emailValid"
            :rules="[requiredRule, emailRule]"
            class="w-input"
          />
        </BListItem>
        <BListItem class="title mt-400">
          <BBtn
            :disabled="!isEmailValid"
            type="primary"
            @click="handleUpdateEmailClick"
          >
            {{ $t('general.save.text') }}
          </BBtn>
        </BListItem>
      </BLayout>
    </template>
  </BContentBox>
</template>

<script lang="ts" setup>
import { onBeforeMount, ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { CurrentUserApiService } from '@/api/user/resources/current_user';
import { UserApiService } from '@/api/user/resources/user';
import { useErrorHandler } from '@/composable/error-handler';
import { useInputValidations } from '@/composable/input-validations';
import { useCurrentUser } from '@/composable/user/user/users';
import { useWait } from '@/composable/vue-wait';
import { bitterAlert } from '@/plugins/BBitterAlert';
import { key } from '@/store/index';

const { currentUser, fetchCurrentUser } = useCurrentUser();
const { doActionWithWait } = useWait();
const { requiredRule, emailRule } = useInputValidations();
const { defaultHandler } = useErrorHandler();
const store = useStore(key);
const i18n = useI18n();

const inputName = ref<string | null>(null);
const inputLastName = ref<string | null>(null);
const inputFirstName = ref<string | null>(null);
const nameValid = ref<boolean>(false);
const lastNameValid = ref<boolean>(false);
const firstNameValid = ref<boolean>(false);
const avatarImage = ref<Blob | null>(null);
const inputEmail = ref<string | null>(null);
const emailValid = ref<boolean>(false);

const isUserInfoValid = computed(() => nameValid.value && lastNameValid.value && firstNameValid.value);
const isEmailValid = computed(() => emailValid.value);

onBeforeMount(async () => {
  await fetchCurrentUser();

  const { name, lastName, firstName, email } = currentUser.value;
  inputName.value = name;
  inputLastName.value = lastName;
  inputFirstName.value = firstName;
  inputEmail.value = email;
});

const updateProfile = async () => {
  const api = new CurrentUserApiService();
  doActionWithWait('updateProfile', async () => {
    if (avatarImage.value != null) {
      const fd = new FormData();
      fd.append('image', avatarImage.value, 'avatar.png');
      await api.updateCurrentUserAvatarImage({
        request: {
          image: fd.get('image'),
        },
        errorHandlers: {
          422: defaultHandler,
        },
      });
    }
    await api.updateCurrentUser({
      request: {
        putCurrentUserRequest: {
          name: inputName.value,
          lastName: inputLastName.value,
          firstName: inputFirstName.value,
        },
      },
    });
    // NOTE: Vuexで持っているユーザ情報を取り直す（この処理は後々消したい）
    store.dispatch('user/getCurrentUserAction');
  });
};

const handleUpdateEmailClick = async () => {
  const ok = await bitterAlert.show({
    title: i18n.t('changeEmail.confirmModal.title'),
    text: i18n.t('changeEmail.confirmModal.explain', { itemName: i18n.t('user.loginEmail') }),
    buttonsCancel: i18n.t('general.cancel.text'),
    className: 'swal-modal-large',
  });
  if (!ok) return;

  await doActionWithWait('prepareLoginEmailUpdate', async () => {
    const api = new UserApiService();
    await api.prepareLoginEmailUpdate({
      request: {
        prepareLoginEmailUpdateBody: {
          email: inputEmail.value,
        },
      },
    });
  });
};
</script>

<style lang="scss" scoped>
.form {
  @media only screen and (min-width: 959px) {
    .w-input {
      width: 40%;
    }
  }
}
.title {
  font-size: $fontsize-400;
  font-weight: bold;
}
.content-box {
  :deep(.footer) {
    padding-bottom: 0 !important;
    height: 49px !important;
    min-height: 49px;

    display: flex;
    gap: 30px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: $bgcolor-dark;

    button {
      width: 100px;
    }
  }
}
</style>
