<template>
  <BBtn
    type="default"
    size="mini"
    class="copy-button"
    outline
    @click="handleCopyClick"
  >
    <BIcon
      size="mini"
    >
      content_copy
    </BIcon>
    {{ buttonName }}
  </BBtn>
</template>

<script lang="ts" setup>
import { computed, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { KEY_OF_TOAST } from '@/injection-keys';

type TProps = {
  text: string;
  title: string;
  isDetailedName?: boolean;
};
const props = withDefaults(defineProps<TProps>(), {
  isDetailedName: false,
});
const toast = inject(KEY_OF_TOAST);
const i18n = useI18n();

const buttonName = computed(() => props.isDetailedName ? i18n.t('general.copyAction.to', { item: props.title }) : i18n.t('general.copyAction.text'));

const handleCopyClick = () => {
  navigator.clipboard.writeText(props.text);
  toast.show({
    message: i18n.t('general.copyAction.done', { item: props.title }),
    type: 'success',
  });
};
</script>

<style lang="scss" scoped>
.b-btn {
  margin-left: auto;
  padding-left: $basespace-200;
  padding-right: $basespace-200;
  .b-icon {
    margin-right: $basespace-50;
  }
}
</style>
