<template>
  <div class="b-time-picker">
    <ElTimePicker
      v-bind="attributes"
      v-model="internalValue"
    />
    <Transition name="el-fade-in-linear">
      <div
        v-if="required"
        class="check-icon"
      >
        <BTooltip
          v-if="!isValid"
          top
          :value="false"
        >
          <BIcon
            size="small"
            class="invalid"
          >
            check_circle_outline
          </BIcon>
          <template #content>
            <div>{{ $t('validation.required') }}</div>
          </template>
        </BTooltip>
        <BIcon
          v-else
          size="small"
          class="valid"
        >
          check_circle
        </BIcon>
      </div>
    </Transition>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    modelValue: {
      type: [Date, String, Array, Number],
      default: null,
    },
    readonly: Boolean,
    disabled: Boolean,
    editable: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      validator (val) {
        return ['small', 'default', 'large'].includes(val);
      },
      default: 'default',
    },
    placeholder: {
      type: String,
      default: null,
    },
    startPlaceholder: {
      type: String,
      default: null,
    },
    endPlaceholder: {
      type: String,
      default: null,
    },
    isRange: Boolean,
    rangeSeparator: {
      type: String,
      default: '-',
    },
    arrowControl: Boolean,
    format: {
      type: String,
      default: null,
    },
    valueFormat: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:modelValue',
  ],
  computed: {
    internalValue: {
      get () {
        return this.modelValue;
      },
      set (newVal) {
        this.$emit('update:modelValue', newVal);
      },
    },
    attributes () {
      return {
        'readonly': this.readonly,
        'disabled': this.disabled,
        'editable': this.editable,
        'clearable': this.clearable,
        'size': this.size,
        'placeholder': this.placeholder,
        'start-placeholder': this.startPlaceholder,
        'end-placeholder': this.endPlaceholder,
        'is-range': this.isRange,
        'range-separator': this.rangeSeparator,
        'arrow-control': this.arrowControl,
        'format': this.format,
        'value-format': this.valueFormat,
      };
    },
    isValid() {
      if (!this.required) return true;
      return this.internalValue != null;
    },
  },
};
</script>

<style lang="scss" scoped>
  .b-time-picker {
    width: 100%;
    position: relative;

    :deep(.el-input__inner) {
      padding-right: $basespace-200;
    }
  }

  :deep(.el-date-editor) {
    --el-date-editor-width: 100%
  }

  :deep(.el-input__wrapper) {
    padding: 0 8px;
    .el-input__inner {
      padding: 0;
    }
    .clear-icon {
      margin: 0;
    }
  }
  .check-icon {
    position: absolute;
    top: 0;
    right: $basespace-600;
    bottom: 0;
    display: flex;
    align-items: center;
    height: var(--el-component-size);

    .valid {
      color: $basecolor-success;
    }

    .invalid {
      color: $basecolor-error;
    }
  }
</style>
