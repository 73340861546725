<template>
  <BCard
    shadow
    border
    full
  >
    <div class="header">
      <BLayout
        align-center
        class="row"
      >
        <BCustomIcon
          class="mr-100 custom-icon todo-icon"
          :class="{
            'done': isDone,
          }"
          :icon-class="icon"
        />
        <span class="content">{{ title }}</span>
        <slot name="sub-content" />
        <BUserTooltip
          v-if="owner"
          :image-url="owner.avatarUrl"
          :name="owner.name"
          :user-id="owner.id"
          :size="30"
        />
        <div
          class="pr-100 pl-50 more-menu"
        >
          <BMoreMenu
            v-if="actions.length"
            v-model="internalIsMenuOpen"
          >
            <BTooltip
              v-for="action in actions"
              :key="action.event"
              right
              :disabled="!action.disabled || !action.disabledContent"
              :content="action.disabledContent"
            >
              <BBtn
                :disabled="action.disabled"
                flat
                fit
                size="small"
                @click="() => handleActionButtonClick(action)"
              >
                {{ action.label }}
              </BBtn>
            </BTooltip>
          </BMoreMenu>
        </div>
      </BLayout>
    </div>
    <div
      v-if="expandableHtmlContent"
      class="body"
      @click="isHtmlContentVisible = !isHtmlContentVisible"
    >
      <div class="body-inner">
        <div class="content">
          {{ content }}
        </div>
        <BIcon
          class="mr-110"
          :class="{ 'is-open': isHtmlContentVisible }"
        >
          arrow_drop_up
        </BIcon>
      </div>
      <div
        v-show="isHtmlContentVisible"
        class="html-content"
        v-html="expandableHtmlContent"
      />
    </div>
    <div
      v-else
      class="body"
      @click="isAllContentVisible = !isAllContentVisible"
    >
      <div class="body-inner">
        <div
          class="content expandable"
          :class="{ 'overview': !isAllContentVisible }"
        >
          {{ content }}
        </div>
        <BIcon
          class="mr-110"
          :class="{ 'is-open': isAllContentVisible }"
        >
          arrow_drop_up
        </BIcon>
      </div>
    </div>
  </BCard>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { UserForDisplay } from '@/api/openapi';
import { TActionWithDisabled, TStepCardEventType } from '@/components/organisms/user/general/sequence/types';

type TProps = {
  isDone: boolean;
  title: string;
  owner?: UserForDisplay;
  content: string;
  icon?: 'b-next-action' | 'b-mail';
  actions?: TActionWithDisabled[];
  isMenuOpen: boolean;
  expandableHtmlContent: string | null;
};

type TEmits = {
  (event: TStepCardEventType): void;
  (event: 'update:isMenuOpen', value: boolean): void;
};

const props = withDefaults(defineProps<TProps>(), {
  owner: undefined,
  actions: () => [],
  icon: 'b-next-action',
});
const emit = defineEmits<TEmits>();

const isHtmlContentVisible = ref(false);
const isAllContentVisible = ref(false);

const internalIsMenuOpen = computed({
  get() {
    return props.isMenuOpen;
  },
  set(newValue) {
    emit('update:isMenuOpen', newValue);
  },
});

const handleActionButtonClick = ({ event, disabled }: TActionWithDisabled) => {
  if (disabled) return;

  emit(event);
};
</script>

<style lang="scss" scoped>
.header {
  padding: 5px 10px;
  border-bottom: 1px solid $bdcolor-base;
  .row {
    min-height: 26px;
  }
  .todo-icon {
    color: $bdcolor-dark;
    line-height: normal;

    &.done {
      color: $basecolor-success;
    }
  }
  .content {
    margin-right: auto;
  }
}
.body {
  &-inner {
    padding: 10px 10px;
    display: flex;
    cursor: pointer;
    transition: $transition-base;
    .content {
      width: calc(100% - 30px);
      &.expandable {
        white-space: pre-wrap;
        &.overview {
          white-space: normal;
          line-height: 1.4;
          max-height: calc(#{$fontsize-200} * 1.4 * 2);
          overflow: hidden;
        }
      }
    }
  }

  &:hover {
    background-color: $bgcolor-dark;
  }
  .is-open {
    transform: rotate(180deg);
  }

  .html-content {
    padding: 8px 10px;
    line-height: 1.5;
    border-top: 1px solid $concrete;
  }
}
</style>
