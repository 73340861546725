import { Module } from 'vuex';
import { RootState, UserState } from '../types';
import actions, { typedActions } from './actions';
import getters from './getters';
import { mutations } from './mutations';

const store: Module<UserState, RootState> = {
  namespaced: true,
  state: {
    currentUser: {},
    admin: {},
    user: {},
    users: [],
    client: {},
    freeTags: [],
    sfCampaigns: [],
    leadCsvImportTask: {},
    leadCsvImportTasks: [],
    leadExcelImportTask: {},
    leadExcelImportTasks: [],
    leadCsvOverwriteTask: {},
    leadCsvOverwriteTasks: [],
    leadExcelOverwriteTask: {},
    leadExcelOverwriteTasks: [],
    leadDeleteTasks: [],
    leadExportTasks: {},
    sfLeadDataCheckTasks: [],
    customFields: [],
    callTarget: {
      id: 0,
      number: 0,
      companyInfo: {},
      callTargetInfo: {},
      customFields: [],
      nextId: 0,
      previousId: 0,
    },
    callTargets: [],
    callTargetsMeta: {},
    fulltextSearchResult: [],
    callTargetFreeTags: [],
    callTargetNotes: [],
    callResultTypes: [],
    defaultCallResultTypes: [],
    leadHistories: [],
    nextActions: [],
    nextActionTypes: [],
    defaultNextActionTypes: [],
    reminder: {},
    mailNotifications: [],
    mailNotificationCount: 0,
    mailTemplate: null,
    mailTemplates: [],
    miitelAuth: {
      companyId: null,
      accessKey: null,
    },
    callAnalytics: [],
    allUserCallAnalytics: {
      graph: [],
      graphMaxValue: {},
    },
    callResultCallAnalytics: [],
    revisionId: 0,
    leadSources: [],
    leadStages: [],
    callTargetLeadSources: [],
    businessCategoryCandidates: [],
    positionCandidates: [],
    departmentCandidates: [],
    assignedUserCandidates: [],
    leadViews: [],
    leadView: {},
    callTargetUnsubscribe: {},
    mailLeadCount: {
      leadCount: 0,
      canSendMailCount: 0,
    },
    bulkMails: [],
    bulkMailsMeta: {},
    bulkMail: {},
    bulkMailCampaignNames: [],
    sendgridEvents: [],
    availableFeatures: {
      feature: {},
      betaFeature: {},
    },
    callTargetsCount: 0,
    operator: {},
    salesforceSetting: {},
    sfUsers: [],
    sfOpportunity: {},
    boxilLeadImportSetting: {},
    sfReportImportTask: {},
    sfReportImportTasks: [],
    sfStatuses: [],
    sfAvailableMappingFields: [],
  },
  mutations,
  actions: {
    ...actions,
    ...typedActions,
  },
  getters,
};

export default store;
