import { onMounted, onUnmounted, ref } from 'vue';

const SHORTCUT_KEY = {
  Ctrl: 'Ctrl',
  k: 'k',
} as const;
type TShortcutKey = typeof SHORTCUT_KEY[keyof typeof SHORTCUT_KEY];

/**
 * @example
 * == Option API (duplicated) ==
 * setup() {
 *   const { setCallback: setShortcutKeyCallback } = useShortcutKey([SHORTCUT_KEY.Ctrl, SHORTCUT_KEY.k]);
 *   return { setShortcutKeyCallback };
 * },
 * onMounted(() => {
 *  setShortcutKeyCallback(() => {
 *   this.doSomething();
 *  });
 * })
 * 
 * == Composition API ==
 * useShortcutKey([SHORTCUT_KEY.Ctrl, SHORTCUT_KEY.k], doSomething);
 * // or
 * useShortcutKey([SHORTCUT_KEY.Ctrl, SHORTCUT_KEY.k], () => { doSomething() });
 */
const useShortcutKey = (keys: TShortcutKey[], callback?: () => void) => {
  const callbackRef = ref(callback || (() => {
    console.error('[useShortcutKey] callback is not defined');
  }));

  const isShortcutKeyPressed = (key: TShortcutKey, e: KeyboardEvent) => {
    if (key === SHORTCUT_KEY.Ctrl) {
      return e.ctrlKey;
    }
    return e.key === key;
  };

  /**
   * @duplicated Option APIで使用し、コールバック内でComponent内の要素を操作したい場合はこれを適当な箇所で呼び出す
   */
  const setCallback = (cb: () => void) => {
    callbackRef.value = cb;
  };

  const keydownEvent = (e: KeyboardEvent) => {
    if (keys.every(key => isShortcutKeyPressed(key, e))) {
      e.preventDefault();
      callbackRef.value();
    }
  };

  onMounted(() => {
    window.addEventListener('keydown', keydownEvent);
  });
  onUnmounted(() => {
    window.removeEventListener('keydown', keydownEvent);
  });

  return {
    setCallback,
  };
};

export {
  SHORTCUT_KEY,
  useShortcutKey,
};
