<template>
  <div class="attachments">
    <MailAttachment
      v-for="mailAttachment in mailAttachments"
      :id="mailAttachment.id"
      :key="mailAttachment.id"
      :file-name="mailAttachment.fileName"
      :enable-link="false"
      :show-cross-button="showCrossButton"
      @click-cross-icon="handleAttachmentCrossButtonClick"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { MailAttachment as TMailAttachment } from '@/api/openapi';
import { MailAttachmentApiService } from '@/api/user/resources/mail_attachment';
import MailAttachment from '@/components/organisms/user/general/MailAttachment.vue';


type TProps = {
  mailAttachmentIds: number[];
  showCrossButton?: boolean;
};

type TEmit = (event: 'editMailAttachmentIds', mailAttachmentIds: number[]) => void;

const props = withDefaults(defineProps<TProps>(), {
  showCrossButton: true,
});
const emit = defineEmits<TEmit>();

const mailAttachments = ref<TMailAttachment[]>([]);

const internalMailAttachmentIds = computed({
  get() {
    return props.mailAttachmentIds;
  },
  set(newValue) {
    emit('editMailAttachmentIds', newValue);
  },
});

const fetchMailAttachments = async () => {
  if (!internalMailAttachmentIds.value.length) {
    mailAttachments.value = [];
    return;
  }
  const apiService =  new MailAttachmentApiService();
  const { data } = await apiService.getMailAttachments({
    request: {
      ids: internalMailAttachmentIds.value,
    },
  });
  mailAttachments.value = data;
};

watch(() => props.mailAttachmentIds, (newValue) => {
  fetchMailAttachments();
}, { immediate: true });

const handleAttachmentCrossButtonClick = (mailAttachmentId: number) => {
  internalMailAttachmentIds.value = internalMailAttachmentIds.value.filter(id => id !== mailAttachmentId);
};

const totalByteSize = computed(() => {
  return mailAttachments.value.reduce((sum, mailAttachment) => {
    return sum + mailAttachment.fileByteSize;
  }, 0);
});

defineExpose({
  totalByteSize,
});
</script>

<style lang="scss" scoped>
.attachments {
  padding: 10px 15px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
</style>
