import { AxiosResponse } from 'axios';
import ApiService, { ApiArguments } from '@/api/api_service';
import {
  MailAttachment,
  MailAttachmentApi,
  MailAttachmentApiGetMailAttachmentsRequest,
  MailAttachmentApiPostMailAttachmentRequest,
} from '@/api/openapi';

export class MailAttachmentApiService extends ApiService {
  api = new MailAttachmentApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true);
  }

  async getMailAttachments(
    {
      request,
      options,
      errorHandlers,
    }: ApiArguments<MailAttachmentApiGetMailAttachmentsRequest>,
  ) {
    return this.checkResponse<MailAttachment[]>(
      this.api.getMailAttachments(request, options),
      errorHandlers,
    );
  }

  async postMailAttachment(
    {
      request,
      options,
      errorHandlers,
    }: ApiArguments<MailAttachmentApiPostMailAttachmentRequest>,
  ) {
    return this.checkResponse<MailAttachment>(
      this.api.postMailAttachment(request, options),
      errorHandlers,
    );
  }
}
