<template>
  <BModal
    :modal-name="modalName"
    height="auto"
  >
    <BHeightAdjuster>
      <BModalHeader
        :title="$t('customField.exportTitle')"
        center
        @modal-close="hide"
      />
      <BModalBody>
        <BLayout
          justify-center
          align-center
          is-fit
        >
          <BFileUploadArea
            ref="fileUploadArea"
            class="mt-400 w-full"
            :accept-data-type="acceptDataType"
            is-fit
            @upload="upload"
          />
        </BLayout>
      </BModalBody>
      <BModalFooter>
        <BLayout justify-center>
          <div class="message">
            <span class="desc">{{ $t(`customField.importTemplate.desc`) }}</span>
            <div class="dl-btn">
              <BBtn
                type="primary"
                @click="dlButton"
              >
                <span>{{ $t(`customField.importTemplate.download`) }}</span>
              </BBtn>
            </div>
            <BBtn
              :loading="loading"
              :disabled="!save"
              type="primary"
              @click="saveButton"
            >
              <span>{{ $t(`customField.import`) }}</span>
            </BBtn>
          </div>
        </BLayout>
      </BModalFooter>
    </BHeightAdjuster>
  </BModal>
</template>

<script lang="ts" setup>
import { inject, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Api from '@/api/user';
import { CustomFieldsApiService } from '@/api/user/resources/custom_fields';
import { KEY_OF_MODAL } from '@/injection-keys';
import { bitterAlert } from '@/plugins/BBitterAlert';
import EncodingService from '@/services/encoding_service';

type TEmit = (event: 'refresh') => void;

const emit = defineEmits<TEmit>();
const acceptDataType = '.xlsx';
const i18n = useI18n();
const modal = inject(KEY_OF_MODAL);
const modalName = ref('customImportModal');
const loading = ref(false);
const save = ref(false);
const fileBlob = ref('');

const upload = async (file: string | Blob) => {
  loading.value = true;
  const separatedFileName = file.name.split('.');
  const importFileExtension = `.${separatedFileName[separatedFileName.length - 1]}`;

  if (importFileExtension !== acceptDataType) {
    await bitterAlert.show({
      text: i18n.t('import.errors.extension'),
      buttonsCancel: false,
    });
    loading.value = false;
    return;
  }
  const reader = new FileReader();
  reader.onload = async (e) => {
    const blob = new EncodingService().encodeArrayBufferToBlob(e.target.result);
    // 単位はbyte, 100kbを指定
    const MAX_FILE_SIZE = 100000;
    if (blob.size > MAX_FILE_SIZE) {
      onFileUploadFailed();
      await bitterAlert.show({
        text: i18n.t('import.errors.maxFileSize', { max_file_size: `${MAX_FILE_SIZE / 1000}KB` }),
        buttonsCancel: false,
      });
      return false;
    }
    const formData = new FormData();
    formData.append('file', file);
    fileBlob.value = formData;
    save.value = true;
  };
  reader.readAsArrayBuffer(file);
  loading.value = false;
};

const saveButton = async () => {
  if (!fileBlob.value) {
    await bitterAlert.show({
      text: i18n.t('apiError.message.fileUploadError'),
      buttonsCancel: false,
    });
    loading.value = false;
    save.value = false;
    return;
  }
  const customFieldsApi = new CustomFieldsApiService();
  customFieldsApi.import({
    options: {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: fileBlob.value,
    },
  }).then((result) => {
    save.value = false;
    loading.value = false;
    emit('refresh');
    hide();
  }).catch(async (err) => {
    await bitterAlert.show({
      title: i18n.t('import.excel.errorTitle'),
      text: err.response?.data?.errorMessage,
      buttonsCancel: false,
    });
    loading.value = false;
    return;
  });
};

const dlButton = async () => {
  const fileName = 'custom_field_import_template.xlsx';
  Api.customFieldsImportTemplate({})
    .then((res: any) => {
      const blob = new Blob([res.data], { type: 'application/octet-stream' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    });
};

const onFileUploadFailed = () => {
  save.value = false;
  loading.value = false;
};

const hide = () => {
  modal.hide(modalName.value);
};
</script>

<style lang="scss" scoped>
.message {
  padding: $basespace-100 0;
  text-align: center;
  color: $basecolor-primary;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .desc {
    padding-bottom: $basespace-200;
    color: $concrete-dark;
  }
  .dl-btn {
    padding-bottom: $basespace-500;
  }
}
</style>
