/* tslint:disable */
/* eslint-disable */
/**
 * openapi
 * bales cloud
 *
 * The version of the OpenAPI document: 1.0
 * Contact: test@example.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BulkUpdateSequenceInstancesStatusBody } from '../models';
// @ts-ignore
import { SequenceInstance } from '../models';
/**
 * SequenceInstanceApi - axios parameter creator
 * @export
 */
export const SequenceInstanceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * シーケンスインスタンス取得API
         * @summary シーケンスインスタンス取得API
         * @param {number} sequenceInstanceId シーケンスインスタンスID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSequenceInstance: async (sequenceInstanceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sequenceInstanceId' is not null or undefined
            assertParamExists('getSequenceInstance', 'sequenceInstanceId', sequenceInstanceId)
            const localVarPath = `/api/user/sequence/instances/{sequenceInstanceId}`
                .replace(`{${"sequenceInstanceId"}}`, encodeURIComponent(String(sequenceInstanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * シーケンスインスタンス一覧取得API
         * @summary シーケンスインスタンス一覧取得API
         * @param {number} [callTargetId] リードID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSequenceInstances: async (callTargetId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/sequence/instances`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)

            if (callTargetId !== undefined) {
                localVarQueryParameter['callTargetId'] = callTargetId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * シーケンスインスタンス一括登録解除API
         * @summary シーケンスインスタンス一括登録解除API
         * @param {BulkUpdateSequenceInstancesStatusBody} [bulkUpdateSequenceInstancesStatusBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restartSequenceInstances: async (bulkUpdateSequenceInstancesStatusBody?: BulkUpdateSequenceInstancesStatusBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/sequence/instances/restart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkUpdateSequenceInstancesStatusBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * シーケンスインスタンス一括停止API
         * @summary シーケンスインスタンス一括停止API
         * @param {BulkUpdateSequenceInstancesStatusBody} [bulkUpdateSequenceInstancesStatusBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopSequenceInstances: async (bulkUpdateSequenceInstancesStatusBody?: BulkUpdateSequenceInstancesStatusBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/sequence/instances/stop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkUpdateSequenceInstancesStatusBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * シーケンスインスタンス一括登録解除API
         * @summary シーケンスインスタンス一括登録解除API
         * @param {BulkUpdateSequenceInstancesStatusBody} [bulkUpdateSequenceInstancesStatusBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unregisterSequenceInstances: async (bulkUpdateSequenceInstancesStatusBody?: BulkUpdateSequenceInstancesStatusBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/sequence/instances/unregister`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkUpdateSequenceInstancesStatusBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SequenceInstanceApi - functional programming interface
 * @export
 */
export const SequenceInstanceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SequenceInstanceApiAxiosParamCreator(configuration)
    return {
        /**
         * シーケンスインスタンス取得API
         * @summary シーケンスインスタンス取得API
         * @param {number} sequenceInstanceId シーケンスインスタンスID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSequenceInstance(sequenceInstanceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SequenceInstance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSequenceInstance(sequenceInstanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * シーケンスインスタンス一覧取得API
         * @summary シーケンスインスタンス一覧取得API
         * @param {number} [callTargetId] リードID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSequenceInstances(callTargetId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SequenceInstance>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSequenceInstances(callTargetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * シーケンスインスタンス一括登録解除API
         * @summary シーケンスインスタンス一括登録解除API
         * @param {BulkUpdateSequenceInstancesStatusBody} [bulkUpdateSequenceInstancesStatusBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restartSequenceInstances(bulkUpdateSequenceInstancesStatusBody?: BulkUpdateSequenceInstancesStatusBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restartSequenceInstances(bulkUpdateSequenceInstancesStatusBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * シーケンスインスタンス一括停止API
         * @summary シーケンスインスタンス一括停止API
         * @param {BulkUpdateSequenceInstancesStatusBody} [bulkUpdateSequenceInstancesStatusBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stopSequenceInstances(bulkUpdateSequenceInstancesStatusBody?: BulkUpdateSequenceInstancesStatusBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stopSequenceInstances(bulkUpdateSequenceInstancesStatusBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * シーケンスインスタンス一括登録解除API
         * @summary シーケンスインスタンス一括登録解除API
         * @param {BulkUpdateSequenceInstancesStatusBody} [bulkUpdateSequenceInstancesStatusBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unregisterSequenceInstances(bulkUpdateSequenceInstancesStatusBody?: BulkUpdateSequenceInstancesStatusBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unregisterSequenceInstances(bulkUpdateSequenceInstancesStatusBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SequenceInstanceApi - factory interface
 * @export
 */
export const SequenceInstanceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SequenceInstanceApiFp(configuration)
    return {
        /**
         * シーケンスインスタンス取得API
         * @summary シーケンスインスタンス取得API
         * @param {number} sequenceInstanceId シーケンスインスタンスID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSequenceInstance(sequenceInstanceId: number, options?: any): AxiosPromise<SequenceInstance> {
            return localVarFp.getSequenceInstance(sequenceInstanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * シーケンスインスタンス一覧取得API
         * @summary シーケンスインスタンス一覧取得API
         * @param {number} [callTargetId] リードID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSequenceInstances(callTargetId?: number, options?: any): AxiosPromise<Array<SequenceInstance>> {
            return localVarFp.getSequenceInstances(callTargetId, options).then((request) => request(axios, basePath));
        },
        /**
         * シーケンスインスタンス一括登録解除API
         * @summary シーケンスインスタンス一括登録解除API
         * @param {BulkUpdateSequenceInstancesStatusBody} [bulkUpdateSequenceInstancesStatusBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restartSequenceInstances(bulkUpdateSequenceInstancesStatusBody?: BulkUpdateSequenceInstancesStatusBody, options?: any): AxiosPromise<void> {
            return localVarFp.restartSequenceInstances(bulkUpdateSequenceInstancesStatusBody, options).then((request) => request(axios, basePath));
        },
        /**
         * シーケンスインスタンス一括停止API
         * @summary シーケンスインスタンス一括停止API
         * @param {BulkUpdateSequenceInstancesStatusBody} [bulkUpdateSequenceInstancesStatusBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopSequenceInstances(bulkUpdateSequenceInstancesStatusBody?: BulkUpdateSequenceInstancesStatusBody, options?: any): AxiosPromise<void> {
            return localVarFp.stopSequenceInstances(bulkUpdateSequenceInstancesStatusBody, options).then((request) => request(axios, basePath));
        },
        /**
         * シーケンスインスタンス一括登録解除API
         * @summary シーケンスインスタンス一括登録解除API
         * @param {BulkUpdateSequenceInstancesStatusBody} [bulkUpdateSequenceInstancesStatusBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unregisterSequenceInstances(bulkUpdateSequenceInstancesStatusBody?: BulkUpdateSequenceInstancesStatusBody, options?: any): AxiosPromise<void> {
            return localVarFp.unregisterSequenceInstances(bulkUpdateSequenceInstancesStatusBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SequenceInstanceApi - interface
 * @export
 * @interface SequenceInstanceApi
 */
export interface SequenceInstanceApiInterface {
    /**
     * シーケンスインスタンス取得API
     * @summary シーケンスインスタンス取得API
     * @param {number} sequenceInstanceId シーケンスインスタンスID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceInstanceApiInterface
     */
    getSequenceInstance(sequenceInstanceId: number, options?: AxiosRequestConfig): AxiosPromise<SequenceInstance>;

    /**
     * シーケンスインスタンス一覧取得API
     * @summary シーケンスインスタンス一覧取得API
     * @param {number} [callTargetId] リードID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceInstanceApiInterface
     */
    getSequenceInstances(callTargetId?: number, options?: AxiosRequestConfig): AxiosPromise<Array<SequenceInstance>>;

    /**
     * シーケンスインスタンス一括登録解除API
     * @summary シーケンスインスタンス一括登録解除API
     * @param {BulkUpdateSequenceInstancesStatusBody} [bulkUpdateSequenceInstancesStatusBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceInstanceApiInterface
     */
    restartSequenceInstances(bulkUpdateSequenceInstancesStatusBody?: BulkUpdateSequenceInstancesStatusBody, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * シーケンスインスタンス一括停止API
     * @summary シーケンスインスタンス一括停止API
     * @param {BulkUpdateSequenceInstancesStatusBody} [bulkUpdateSequenceInstancesStatusBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceInstanceApiInterface
     */
    stopSequenceInstances(bulkUpdateSequenceInstancesStatusBody?: BulkUpdateSequenceInstancesStatusBody, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * シーケンスインスタンス一括登録解除API
     * @summary シーケンスインスタンス一括登録解除API
     * @param {BulkUpdateSequenceInstancesStatusBody} [bulkUpdateSequenceInstancesStatusBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceInstanceApiInterface
     */
    unregisterSequenceInstances(bulkUpdateSequenceInstancesStatusBody?: BulkUpdateSequenceInstancesStatusBody, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * Request parameters for getSequenceInstance operation in SequenceInstanceApi.
 * @export
 * @interface SequenceInstanceApiGetSequenceInstanceRequest
 */
export interface SequenceInstanceApiGetSequenceInstanceRequest {
    /**
     * シーケンスインスタンスID
     * @type {number}
     * @memberof SequenceInstanceApiGetSequenceInstance
     */
    readonly sequenceInstanceId: number
}

/**
 * Request parameters for getSequenceInstances operation in SequenceInstanceApi.
 * @export
 * @interface SequenceInstanceApiGetSequenceInstancesRequest
 */
export interface SequenceInstanceApiGetSequenceInstancesRequest {
    /**
     * リードID
     * @type {number}
     * @memberof SequenceInstanceApiGetSequenceInstances
     */
    readonly callTargetId?: number
}

/**
 * Request parameters for restartSequenceInstances operation in SequenceInstanceApi.
 * @export
 * @interface SequenceInstanceApiRestartSequenceInstancesRequest
 */
export interface SequenceInstanceApiRestartSequenceInstancesRequest {
    /**
     * 
     * @type {BulkUpdateSequenceInstancesStatusBody}
     * @memberof SequenceInstanceApiRestartSequenceInstances
     */
    readonly bulkUpdateSequenceInstancesStatusBody?: BulkUpdateSequenceInstancesStatusBody
}

/**
 * Request parameters for stopSequenceInstances operation in SequenceInstanceApi.
 * @export
 * @interface SequenceInstanceApiStopSequenceInstancesRequest
 */
export interface SequenceInstanceApiStopSequenceInstancesRequest {
    /**
     * 
     * @type {BulkUpdateSequenceInstancesStatusBody}
     * @memberof SequenceInstanceApiStopSequenceInstances
     */
    readonly bulkUpdateSequenceInstancesStatusBody?: BulkUpdateSequenceInstancesStatusBody
}

/**
 * Request parameters for unregisterSequenceInstances operation in SequenceInstanceApi.
 * @export
 * @interface SequenceInstanceApiUnregisterSequenceInstancesRequest
 */
export interface SequenceInstanceApiUnregisterSequenceInstancesRequest {
    /**
     * 
     * @type {BulkUpdateSequenceInstancesStatusBody}
     * @memberof SequenceInstanceApiUnregisterSequenceInstances
     */
    readonly bulkUpdateSequenceInstancesStatusBody?: BulkUpdateSequenceInstancesStatusBody
}

/**
 * SequenceInstanceApi - object-oriented interface
 * @export
 * @class SequenceInstanceApi
 * @extends {BaseAPI}
 */
export class SequenceInstanceApi extends BaseAPI implements SequenceInstanceApiInterface {
    /**
     * シーケンスインスタンス取得API
     * @summary シーケンスインスタンス取得API
     * @param {SequenceInstanceApiGetSequenceInstanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceInstanceApi
     */
    public getSequenceInstance(requestParameters: SequenceInstanceApiGetSequenceInstanceRequest, options?: AxiosRequestConfig) {
        return SequenceInstanceApiFp(this.configuration).getSequenceInstance(requestParameters.sequenceInstanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * シーケンスインスタンス一覧取得API
     * @summary シーケンスインスタンス一覧取得API
     * @param {SequenceInstanceApiGetSequenceInstancesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceInstanceApi
     */
    public getSequenceInstances(requestParameters: SequenceInstanceApiGetSequenceInstancesRequest = {}, options?: AxiosRequestConfig) {
        return SequenceInstanceApiFp(this.configuration).getSequenceInstances(requestParameters.callTargetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * シーケンスインスタンス一括登録解除API
     * @summary シーケンスインスタンス一括登録解除API
     * @param {SequenceInstanceApiRestartSequenceInstancesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceInstanceApi
     */
    public restartSequenceInstances(requestParameters: SequenceInstanceApiRestartSequenceInstancesRequest = {}, options?: AxiosRequestConfig) {
        return SequenceInstanceApiFp(this.configuration).restartSequenceInstances(requestParameters.bulkUpdateSequenceInstancesStatusBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * シーケンスインスタンス一括停止API
     * @summary シーケンスインスタンス一括停止API
     * @param {SequenceInstanceApiStopSequenceInstancesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceInstanceApi
     */
    public stopSequenceInstances(requestParameters: SequenceInstanceApiStopSequenceInstancesRequest = {}, options?: AxiosRequestConfig) {
        return SequenceInstanceApiFp(this.configuration).stopSequenceInstances(requestParameters.bulkUpdateSequenceInstancesStatusBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * シーケンスインスタンス一括登録解除API
     * @summary シーケンスインスタンス一括登録解除API
     * @param {SequenceInstanceApiUnregisterSequenceInstancesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceInstanceApi
     */
    public unregisterSequenceInstances(requestParameters: SequenceInstanceApiUnregisterSequenceInstancesRequest = {}, options?: AxiosRequestConfig) {
        return SequenceInstanceApiFp(this.configuration).unregisterSequenceInstances(requestParameters.bulkUpdateSequenceInstancesStatusBody, options).then((request) => request(this.axios, this.basePath));
    }
}
