<template>
  <BModalHeader
    :title="$t('sequence.title')"
    @modal-close="handleClose"
  />
  <BModalBody
    v-loading="loading"
    full
  >
    <template #waiting>
      <BPlaceholder
        v-loading="true"
        height="100%"
        element-loading-background="transparent"
      />
    </template>
    <SequenceMaster
      v-loading="loading"
      :sequence-master-id="sequenceMasterId"
      :owner-name="displayOwnerName"
    />
    <BTabs
      class="tabs"
      :label-items="tabLabelItems"
    />
    <SequenceSteps
      :sequence-step-master-data="sequenceStepMasterData"
      @update="handleUpdateSequenceSteps"
    />
  </BModalBody>
  <BModalFooter>
    <BLayout
      class="mt-200"
      justify-center
    >
      <BBtn
        class="mr-100"
        flat
        @click="handleBackToSequenceList"
      >
        {{ $t('general.back.text') }}
      </BBtn>
      <BBtn
        type="primary"
        :loading="loading"
        @click="handleApply"
      >
        {{ $t('sequence.leadDetail.apply') }}
      </BBtn>
    </BLayout>
  </BModalFooter>
</template>

<script lang="ts" setup>
import { computed, inject, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { ApplySequenceMasterBodySequenceStepsInner, FailedCallTarget, SequenceStepMaster } from '@/api/openapi';
import { SequenceMasterApiService } from '@/api/user/resources/sequence_master';
import { TFormSequenceStep } from '@/components/organisms/user/general/sequence/types';
import { getActionDetail } from '@/composable/user/sequence/post-put-body';
import { useSequenceMaster } from '@/composable/user/sequence/sequence-master';
import { useSequenceStepMasterData, useSequenceStepMasters } from '@/composable/user/sequence/sequence-step-masters';
import { useUsers } from '@/composable/user/user/users';
import { useWait } from '@/composable/vue-wait';
import { KEY_OF_TOAST } from '@/injection-keys';
import { bitterAlert } from '@/plugins/BBitterAlert';
import { addMinutesToCurrentDateTime, isDateAfter, newDateByTime } from '@/utils/date-time';
import SequenceMaster from '../../lead/modal/sequenceApply/SequenceMaster.vue';
import SequenceSteps from '../../lead/modal/sequenceApply/SequenceSteps.vue';
import { TSequenceOwnerValue } from './SelectSequenceOwner.vue';

type TProps = {
  callTargetIds: number[];
  sequenceMasterId: number;
  ownerId?: TSequenceOwnerValue;
};

const props = defineProps<TProps>();
const emit = defineEmits(['clickClose', 'afterApply', 'backToSequenceList']);

const {
  doActionWithWait,
} = useWait();

const i18n = useI18n();
const { wait } = useWait();
const toast = inject(KEY_OF_TOAST);
const tabLabelItems = [
  { label: i18n.t('sequence.step.title'), slotName: 'step' },
];

const sequenceStepsBodyData = ref<ApplySequenceMasterBodySequenceStepsInner[]>([]);
const loading = computed(() => wait.is(['fetchSequenceMaster', 'fetchSequenceStepMasters', 'applySequenceMaster']));

const selectedOwner = computed(() => {
  if (props.ownerId == null || props.ownerId === 'sequenceOwner') return sequenceMaster.value?.owner;
  if (props.ownerId === 'leadOwner') return null;

  return users.value.find(user => user.id === props.ownerId);
});
const selectedOwnerId = computed(() => selectedOwner.value?.id);
const displayOwnerName = computed(() => props.ownerId === 'leadOwner' ? i18n.t('sequence.selectOwner.leadOwnerDisplayName') : selectedOwner.value?.name);

const settingLeadOwnerAsOwner = computed(() => props.ownerId === 'leadOwner');

const {
  sequenceMaster,
  fetchSequenceMaster,
} = useSequenceMaster(props.sequenceMasterId);
const {
  sequenceStepMasters,
  fetchSequenceStepMasters,
} = useSequenceStepMasters(props.sequenceMasterId);
fetchSequenceMaster('fetchSequenceMaster');
fetchSequenceStepMasters('fetchSequenceStepMasters');
const { users } = useUsers();

const { sequenceStepData: sequenceStepMasterData } = useSequenceStepMasterData(() => sequenceStepMasters.value as SequenceStepMaster[]);

const handleClose = async () => {
  emit('clickClose');
};
const handleBackToSequenceList = () => {
  emit('backToSequenceList');
};

const handleUpdateSequenceSteps = (sequenceSteps: TFormSequenceStep[]) => {
  sequenceStepsBodyData.value = sequenceSteps.map((step) => {
    const { id, sortOrder, actionType, dateInterval, reservedTime, priority, immediately } = step;
    return {
      id,
      sortOrder,
      actionType,
      dateInterval,
      reservedTime,
      priority,
      immediately,
      actionDetail: getActionDetail(step),
    } as ApplySequenceMasterBodySequenceStepsInner;
  });
};

const handleApply = async () => {
  if (!await confirmPastReserveTime(sequenceStepsBodyData.value)) return;

  const api = new SequenceMasterApiService();
  try {
    let failedCallTargets: FailedCallTarget[] = [];
    await doActionWithWait<void>('applySequenceMaster', async () => {
      const { data } = await api.applySequenceMaster({
        request: {
          applySequenceMasterBody: {
            sequenceMasterId: props.sequenceMasterId,
            callTargetIds: props.callTargetIds,
            ownerId: selectedOwnerId.value,
            settingLeadOwnerAsOwner: settingLeadOwnerAsOwner.value,
            sequenceSteps: sequenceStepsBodyData.value,
          },
        },
      });
      failedCallTargets = data.failedCallTargets;
    });
    emit('afterApply', failedCallTargets);
  } catch (e) {
    toast.show({
      message: i18n.t('general.fail.to', { action: i18n.t('sequence.apply') }),
      type: 'error',
    });
  }
};

const shouldShowPastReserveTimeAlert = (sequenceStepData: ApplySequenceMasterBodySequenceStepsInner[]) => {
  const reservedTime = sequenceStepData[0].reservedTime.split(':') as unknown as [number, number];
  return sequenceStepData.length > 0
    && !sequenceStepData[0].immediately
    && sequenceStepData[0].dateInterval === 0
    && !isDateAfter(newDateByTime(reservedTime[0] || 0, reservedTime[1] || 0, 0), addMinutesToCurrentDateTime(5));
};

const confirmPastReserveTime = async (sequenceStepData: ApplySequenceMasterBodySequenceStepsInner[]) => {
  if (shouldShowPastReserveTimeAlert(sequenceStepData)) {
    return await bitterAlert.show({
      title: i18n.t('general.confirm.text'),
      text: i18n.t('sequence.pastSequenceReserveTime'),
    });
  }
  return true;
};
</script>

<style lang="scss" scoped>
.b-modal-header {
  border-bottom: 1px solid $bdcolor-light;
}
.b-modal-body {
  overflow: hidden;
  position: relative;
}
.b-modal-footer {
  background-color: $bgcolor-dark;
}
:deep(.el-tabs__header) {
  margin-bottom: 0;

  .el-tabs__nav-scroll {
    padding: 0 $basespace-600;
  }
}
:deep(.sequence-steps-container) {
  // NOTE: footer分を除く
  height: calc(100% - 88px);
}
</style>
