<template>
  <div class="theader">
    <div class="table-row theader-row">
      <div class="col-group col-group-name">
        <BSortText
          key="name"
          class="table-cell header-cell name-container"
          :item-text="$t('sequence.name')"
          is-display-only
        />
      </div>
      <div class="col-group col-group-summary">
        <div class="columns-header columns-header-summary">
          {{ $t('sequence.summary.columnHeader') }}
        </div>
        <BSortText
          v-for="headerSummary in headerSummaries"
          :key="`summary.${headerSummary}`"
          class="table-cell header-cell summary"
          :item-text="$t(`sequence.summary.${headerSummary}`)"
          is-display-only
        />
      </div>
      <div class="col-group col-group-mail-aggregate">
        <div class="columns-header columns-header-mail-aggregate">
          {{ $t('sequence.mailAggregates.columnHeader') }}
        </div>
        <BSortText
          v-for="headerMailAggregate in headerMailAggregates"
          :key="`summary.${headerMailAggregate}`"
          class="table-cell header-cell summary aggregate"
          :item-text="$t(`sequence.mailAggregates.${headerMailAggregate}`)"
          :info="$t(`sequence.mailAggregates.${headerMailAggregate}Info`)"
          is-display-only
        />
      </div>
      <div
        v-if="isAvailableCallStepFeature"
        class="col-group col-group-call-aggregate"
      >
        <div class="columns-header columns-header-call-aggregate">
          {{ $t('sequence.callAggregates.columnHeader') }}
        </div>
        <BSortText
          v-for="headerCallAggregate in headerCallAggregates"
          :key="`summary.${headerCallAggregate}`"
          class="table-cell header-cell summary aggregate"
          :item-text="$t(`sequence.callAggregates.${headerCallAggregate}`)"
          :info="$t(`sequence.callAggregates.${headerCallAggregate}Info`)"
          is-display-only
        />
      </div>
      <div class="col-group col-group-user">
        <BSortText
          v-for="headerUser in headerUsers"
          :key="`user.${headerUser}`"
          class="table-cell header-cell user"
          :item-text="$t(`sequence.${headerUser}`)"
          is-display-only
        />
      </div>
      <div class="table-cell header-cell menu" />
    </div>
  </div>
  <div class="tbody">
    <div
      v-for="sequenceMaster in sequenceMasters"
      :key="sequenceMaster.id"
      class="table-row tbody-row"
      @click="() => moveToEdit(sequenceMaster.id)"
    >
      <div class="col-group col-group-name">
        <div class="table-cell name-container">
          <div class="name">
            {{ sequenceMaster.name }}
          </div>
          <BTooltip
            top
            :content="$t('general.duplicate.do')"
          >
            <BCustomIcon
              size="small"
              icon-class="b-clone"
              hover
              @click.stop="() => moveToDuplicate(sequenceMaster.id)"
            />
          </BTooltip>
        </div>
      </div>
      <div class="col-group col-group-summary">
        <div class="table-cell summary">
          {{ sequenceMaster.numberOfInstances }}
        </div>
        <div class="table-cell summary">
          {{ sequenceMaster.numberOfRegistered }}
        </div>
        <div class="table-cell summary">
          {{ sequenceMaster.numberOfStopped }}
        </div>
        <div class="table-cell summary">
          {{ sequenceMaster.numberOfDone }}
        </div>
        <div class="table-cell summary">
          {{ sequenceMaster.numberOfOpportunities }}
        </div>
        <div class="table-cell summary">
          {{ sequenceMaster.numberOfUnregistered }}
        </div>
      </div>
      <div class="col-group col-group-mail-aggregate">
        <div class="table-cell summary aggregate">
          {{ sequenceMaster.mailAggregates.numberOfAll }}
        </div>
        <AggregateCell
          :count="sequenceMaster.mailAggregates.numberOfSent"
          :rate="sequenceMaster.mailAggregates.sentPerAll"
        />
        <AggregateCell
          :count="sequenceMaster.mailAggregates.numberOfOpened"
          :rate="sequenceMaster.mailAggregates.openedPerSent"
        />
        <AggregateCell
          :count="sequenceMaster.mailAggregates.numberOfClicked"
          :rate="sequenceMaster.mailAggregates.clickedPerSent"
        />
        <AggregateCell
          :count="sequenceMaster.mailAggregates.numberOfReplied"
          :rate="sequenceMaster.mailAggregates.repliedPerSent"
        />
        <AggregateCell
          :count="sequenceMaster.mailAggregates.numberOfFailed"
          :rate="sequenceMaster.mailAggregates.failedPerAll"
        />
      </div>
      <div
        v-if="isAvailableCallStepFeature"
        class="col-group col-group-call-aggregate"
      >
        <div class="table-cell summary aggregate">
          {{ sequenceMaster.callAggregates.numberOfAll }}
        </div>
        <AggregateCell
          :count="sequenceMaster.callAggregates.numberOfConnected"
          :rate="sequenceMaster.callAggregates.connectedPerAll"
        />
        <AggregateCell
          :count="sequenceMaster.callAggregates.numberOfAssignedConnected"
          :rate="sequenceMaster.callAggregates.assignedConnectedPerAll"
        />
        <AggregateCell
          :count="sequenceMaster.callAggregates.numberOfHearingSuccess"
          :rate="sequenceMaster.callAggregates.hearingSuccessPerAll"
        />
      </div>
      <div class="col-group col-group-user">
        <div class="table-cell user">
          <BUserTooltip
            v-if="sequenceMaster.createdUser"
            :image-url="sequenceMaster.createdUser.avatarUrl"
            :name="sequenceMaster.createdUser.name"
            :user-id="sequenceMaster.createdUser.id"
            :size="40"
          />
        </div>
        <div class="table-cell user">
          <BUserTooltip
            v-if="sequenceMaster.updatedUser"
            :image-url="sequenceMaster.updatedUser.avatarUrl"
            :name="sequenceMaster.updatedUser.name"
            :user-id="sequenceMaster.updatedUser.id"
            :size="40"
          />
        </div>
        <div class="table-cell user">
          <BUserTooltip
            v-if="sequenceMaster.owner"
            :image-url="sequenceMaster.owner.avatarUrl"
            :name="sequenceMaster.owner.name"
            :user-id="sequenceMaster.owner.id"
            :size="40"
          />
        </div>
      </div>
      <div
        class="table-cell menu"
        @click.stop
      >
        <BMoreMenu
          :z-index100="true"
        >
          <BPopover
            placement="top"
            width="240"
            trigger="hover"
            :disabled="sequenceMaster.deletable"
            :content="$t('general.alreadyUsed')"
          >
            <template #reference>
              <div>
                <BBtn
                  flat
                  fit
                  size="small"
                  :disabled="!sequenceMaster.deletable"
                  @click="() => handleDeleteButtonClick(sequenceMaster)"
                >
                  {{ $t('general.delete.text') }}
                </BBtn>
              </div>
            </template>
          </BPopover>
          <div>
            <BBtn
              flat
              fit
              size="small"
              @click="() => handleSetOperatorModal(sequenceMaster.id)"
            >
              {{ $t('sequence.owner') }}
            </BBtn>
          </div>
        </BMoreMenu>
        <BModal
          modal-name="setOperatorInSequenceInMasterList"
          width="380px"
          height="100px"
        >
          <SetOperator
            :current-operator-id="sequenceMaster.owner?.id"
            :operator-users="activeUsers"
            :needs-withdraw-option="false"
            @assign-operator="assignOperator"
          />
        </BModal>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { inject, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { SequenceMaster } from '@/api/openapi';
import { SequenceMasterApiService } from '@/api/user/resources/sequence_master';
import { useAvailableFeatureCheck } from '@/composable/available-feature-check';
import { useUsers } from '@/composable/user/user/users';
import { useWait } from '@/composable/vue-wait';
import { KEY_OF_MODAL } from '@/injection-keys';
import { bitterAlert } from '@/plugins/BBitterAlert';
import AggregateCell from './AggregateCell.vue';

type TProps = {
  sequenceMasters: SequenceMaster[];
};
type TEmits = {
  'afterDelete': [];
  'afterChangeOwner': [];
};

defineProps<TProps>();
const emit = defineEmits<TEmits>();

const router = useRouter();

const modal = inject(KEY_OF_MODAL);
const i18n = useI18n();
const api = new SequenceMasterApiService();
const selectedSequenceMasterId = ref<number>();
const {
  doActionWithWait,
} = useWait();

const headerSummaries = ['all', 'registered', 'stopped', 'done', 'associatedOpportunity', 'unregistered'];
const headerMailAggregates = ['all', 'sent', 'opened', 'clicked', 'replied', 'failed'];
const headerUsers = ['createdUser', 'updatedUser', 'owner'];
const headerCallAggregates = ['all', 'connected', 'assignedConnected', 'hearingSuccess'];

const moveToEdit = (id: number) => router.push({ name: 'Sequence', params: { id } });
const moveToDuplicate = (duplicateId: number) => router.push({ name: 'SequenceNew', query: { duplicateId } });
const { activeUsers } = useUsers();
const { isAvailableCallStepFeature } = useAvailableFeatureCheck();
const handleDeleteButtonClick = async (sequenceMaster: SequenceMaster) => {
  const ok = await bitterAlert.show({
    text: i18n.t('sequence.message.confirmWhetherToDelete', { target: sequenceMaster.name }),
  });
  if (!ok) return;
  await deleteSequenceMaster(sequenceMaster.id);
  emit('afterDelete');
};

const deleteSequenceMaster = async (sequenceMasterId: number) => {
  await doActionWithWait(KEY_OF_DELETE_SEQUENCE_MASTER_WAIT, async () => {
    await api.deleteSequenceMaster({
      request: { sequenceMasterId },
    });
  });
};

const handleSetOperatorModal = async (sequenceMasterId: number) => {
  selectedSequenceMasterId.value = sequenceMasterId;
  modal.show('setOperatorInSequenceInMasterList');
};

const assignOperator = async (ownerId: number) => {
  const ok = await bitterAlert.show({
    text: i18n.t('sequence.message.confirmChangeSequenceOwner'),
  });
  if (!ok) {
    modal.hide('setOperatorInSequenceInMasterList');
    return;
  }
  
  await doActionWithWait(KEY_OF_CHANGE_SEQUENCE_MASTER_OWNER_WAIT, async () => {
    await api.changeSequenceMasterOwner({
      request: {
        changeSequenceMasterOwnerBody: {
          sequenceMasterId: selectedSequenceMasterId.value,
          ownerId,
        },
      },
    });
  });
  modal.hide('setOperatorInSequenceInMasterList');
  emit('afterChangeOwner');
};
</script>

<script lang="ts">
export const KEY_OF_DELETE_SEQUENCE_MASTER_WAIT = 'deleteSequenceMasterWait';
export const KEY_OF_CHANGE_SEQUENCE_MASTER_OWNER_WAIT = 'changeSequenceMasterOwnerWait';
</script>

<style lang="scss" scoped>
$name-width: 250px;
$summary-width: 64px;
$aggregate-width: 78px;
$user-width: 80px;

.table-row {
  align-items: center;
  padding-right: $basespace-400;
  display: inline-flex;
  min-width: 100%;
}

.col-group {
  @include m-flex-align-center;
  background-color: $bgcolor-white;
  height: 100%;
  border-right: 1px solid $bdcolor-base;

  &-name {
    position: sticky;
    left: 0;
    z-index: 1;
    padding-left: 30px;
  }
  // NOTE: 1pxはborderの幅
  &-summary {
    @include m-fixed-width(calc(#{$summary-width} * 6 + 1px));
  }
  &-mail-aggregate {
    @include m-fixed-width(calc(#{$aggregate-width} * 6 + 1px));
  }
  &-call-aggregate {
    @include m-fixed-width(calc(#{$aggregate-width} * 4 + 1px));
  }
  &-user {
    @include m-fixed-width(calc(#{$user-width} * 3 + 1px));
  }
}

.table-cell {
  @include m-fixed-width(50px);
  @include m-flex-align-center;
  padding-top: $basespace-200;
  padding-bottom: $basespace-200;
  padding-right: $basespace-200;
  padding-left: 10px;
  background-color: white;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height: 100%;

  &.name-container {
    @include m-fixed-width($name-width);
    display: flex;
    align-items: center;
    .name {
      @include m-truncate-text-multi-line(2);
      flex: 1;
    }
  }
  &.summary {
    @include m-flex-center;
    @include m-fixed-width($summary-width);
  }
  &.aggregate {
    @include m-flex-center;
    @include m-fixed-width($aggregate-width);
  }
  &.user {
    justify-content: center;
    @include m-fixed-width($user-width);
    :deep(.b-avatar) {
      justify-content: center;
    }
  }
  &.menu {
    width: 100%;
  }
}

.theader {
  position: sticky;
  top: 0;
  z-index: 2;
  width: 100%;
  cursor: text;
  background-color: $bgcolor-white;
  color: $textcolor-light;
  font-size: $fontsize-100;

  &-row {
    height: 100%;
    background-color: $bgcolor-white;
    color: $textcolor-light;
    font-size: $fontsize-100;
    border-top: 1px solid $bdcolor-base;
    .table-cell {
      height: calc(50px + 2 * #{$basespace-200});
    }
  }
  .header-cell {
    display: flex;
    height: calc(18px + 2 * #{$basespace-200});
    padding: $basespace-100;
    text-align: center;
    &.aggregate {
      padding: $basespace-50;
    }
    &.name-container {
      text-align: left;
    }
    :deep(span) {
      flex: 1;
      white-space: normal;
    }
  }
  .col-group {
    @include m-flex-align-bottom;
    height: 75px;
  }
  .columns-header {
    background-color: $bgcolor-base;
    padding: $basespace-100 $basespace-200;
    width: 100%;
    text-align: center;
  }
}

.tbody-row {
  border-top: 1px solid $bdcolor-base;
  height: calc(40px + 2 * #{$basespace-200});
  cursor: pointer;

  &:hover {
    background-color: $bgcolor-base;

    .col-group, .table-cell {
      background-color: $bgcolor-base;
    }
  }
  .col-group {
    @include m-flex-align-center;
  }
  .table-cell {
    &.summary {
      font-weight: bold;
      font-size: $fontsize-300;
      text-align: center;

      :deep(.rate) {
        font-weight: normal;
        color: $textcolor-light;
        font-size: $fontsize-100;
        width: 100%;
      }
    }
  }
}

:deep(.b-modal) {
  background: rgba(0, 0, 0, 0.1) !important;
}
</style>
