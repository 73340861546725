<template>
  <div class="table-cell summary aggregate">
    {{ count }}
    <div class="rate">
      {{ displayRate }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

type TProps = {
  count: number;
  rate: number;
};
const props = defineProps<TProps>();

const displayRate = computed(() => props.rate != null ? `(${props.rate.toFixed(1)}%)` : '');
</script>
