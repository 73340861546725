<template>
  <div class="trigger-action-container">
    <div class="b-connector">
      <BConnector
        size="medium"
        add-button-position="middle"
        :menus="menus"
        :disabled-add-button="!editable"
        @click:add-button="handleAddBtnClick"
      />
    </div>
    <TriggerActionCard
      v-if="triggerAction"
      :trigger-action="triggerAction"
      :editable="editable"
      @click:setting="handleUpdateBtnClick"
    />
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { TriggerActionBody } from '@/api/openapi';
import TriggerActionCard from '@/components/organisms/user/trigger/TriggerActionCard.vue';
import { TMenu } from '@/plugins/biscuet-materials/components/molecules/connector';
import { TTriggerActionFormMode, TTriggerActionMenu, TriggerActionBodyExt } from './types';

type TProps = {
  triggerAction: TriggerActionBody | null;
  editable: boolean;
};
type TEmit = {
  'click:triggerAction': [triggerAction: TriggerActionBodyExt, mode: TTriggerActionFormMode];
};
const props = defineProps<TProps>();
const emit = defineEmits<TEmit>();

const i18n = useI18n();
const menus: TMenu<TTriggerActionMenu> = [
  { key: 'trigger_action', label: i18n.t('trigger.actionSetting.types.triggerAction') },
];

const handleAddBtnClick = (menu: TTriggerActionMenu) => {
  if (menu === 'trigger_action') {
    emit('click:triggerAction', {
      approveUserId: null,
      actionType: null,
      actionDetail: {},
      sortOrder: null,
      appendIndex: props.triggerAction?.sortOrder,
    }, 'form');
  }
};

const handleUpdateBtnClick = (mode: TTriggerActionFormMode) => {
  emit('click:triggerAction', props.triggerAction, mode);
};
</script>

<style lang="scss" scoped>
.trigger-action-container {
  .b-connector {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
