import { AxiosResponse } from 'axios';
import ApiService, { ApiArguments } from '@/api/api_service';
import ApiBase from '@/api/base';
import { CallTargetSfSyncBody, CreateTask200Response } from '@/api/openapi';
import {
  CallTargetApi,
  CallTargetApiCallTargetSfSyncRequest,
  CallTargetApiGetCallTargetRequest,
  CallTargetApiGetMailRemindItemsRequest,
  CallTargetApiGetNextPageFirstIdRequest,
  CallTargetApiGetPreviousPageLastIdRequest,
  CallTargetApiMergeCallTargetsRequest,
} from '@/api/openapi/apis/call-target-api';
import { GetCallTarget200Response } from '@/api/openapi/models/get-call-target200-response';
import { GetMailRemindItems200Response } from '@/api/openapi/models/get-mail-remind-items200-response';
import { GetNextPageFirstId200Response } from '@/api/openapi/models/get-next-page-first-id200-response';

export class CallTargetApiService extends ApiService {
  api = new CallTargetApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true);
  }

  async getCallTarget({
    request,
    options,
    errorHandlers,
  }: ApiArguments<CallTargetApiGetCallTargetRequest>): Promise<AxiosResponse<GetCallTarget200Response>> {
    return this.checkResponse<GetCallTarget200Response>(
      this.api.getCallTarget(request, options),
      errorHandlers,
    );
  }

  async mergeCallTarget({
    request,
    options,
    errorHandlers,
  }: ApiArguments<CallTargetApiMergeCallTargetsRequest>): Promise<AxiosResponse<CreateTask200Response>> {
    return this.checkResponse<CreateTask200Response>(
      this.api.mergeCallTargets(request, options),
      errorHandlers,
    );
  }

  async getMailRemindItems({
    request,
    options,
    errorHandlers,
  }: ApiArguments<CallTargetApiGetMailRemindItemsRequest>): Promise<AxiosResponse<GetMailRemindItems200Response>> {
    return this.checkResponse<GetMailRemindItems200Response>(
      this.api.getMailRemindItems(request, options),
      errorHandlers,
    );
  }

  async callTargetSfSync({
    request,
    options,
    errorHandlers,
  }: ApiArguments<CallTargetApiCallTargetSfSyncRequest>): Promise<AxiosResponse<CallTargetSfSyncBody>> {
    return this.checkResponse<CallTargetSfSyncBody>(
      this.api.callTargetSfSync(request, options),
      errorHandlers,
    );
  }

  async getPreviousPageLastId({
    request,
    options,
    errorHandlers,
  }: ApiArguments<CallTargetApiGetPreviousPageLastIdRequest>): Promise<AxiosResponse<GetNextPageFirstId200Response>> {
    return this.checkResponse<GetNextPageFirstId200Response>(
      this.api.getPreviousPageLastId(request, options),
      errorHandlers,
    );
  }

  async getNextPageFirstId({
    request,
    options,
    errorHandlers,
  }: ApiArguments<CallTargetApiGetNextPageFirstIdRequest>): Promise<AxiosResponse<GetNextPageFirstId200Response>> {
    return this.checkResponse<GetNextPageFirstId200Response>(
      this.api.getNextPageFirstId(request, options),
      errorHandlers,
    );
  }
}

export default {
  getCallTargets(args) {
    return ApiBase.get(`/user/call_targets`, args);
  },
  getCallTargetsMeta(args) {
    return ApiBase.get(`/user/call_targets/meta`, args);
  },
  getLeadHistories(args) {
    const { callTargetId } = args;
    return ApiBase.get(`/user/call_targets/${callTargetId}/histories`);
  },
  createCallTarget(args) {
    return ApiBase.post(`/user/call_targets`, args);
  },
  updateCallTarget(args) {
    const { id } = args;
    return ApiBase.put(`/user/call_targets/${id}`, args);
  },
  deleteCallTarget(args) {
    const { callTargetId } = args;
    return ApiBase.delete(`/user/call_targets/${callTargetId}`, args);
  },
  duplicateCallTarget(args) {
    const { callTargetId } = args;
    return ApiBase.post(`/user/call_targets/${callTargetId}/duplicate`, args);
  },
  deleteAllCallTargets(args: any = {}) {
    args.timeout = 300000;
    return ApiBase.delete(`/user/call_targets/delete_some_leads_or_all`, args);
  },
  getReminder(args) {
    return ApiBase.pollingGet(`/user/reminder`, args);
  },
  assignOperator(args) {
    return ApiBase.post(`/user/call_targets/assign_operator`, args);
  },
  withdrawOperators(args) {
    return ApiBase.post(`/user/call_targets/withdraw_operator`, args);
  },
  fulltextSearch(args) {
    const { textQuery } = args;
    return ApiBase.get(`/user/call_targets/fulltext_search?q=${encodeURIComponent(textQuery)}`, args);
  },
  updateCustomFieldValue(args) {
    const { id } = args;
    return ApiBase.put(`/user/call_targets/${id}/custom_field_value`, args);
  },
  getCallTargetFreeTags(args) {
    const { id } = args;
    return ApiBase.get(`/user/call_targets/${id}/free_tags`, args);
  },
  appendCallTargetFreeTag(args) {
    const { id } = args;
    return ApiBase.post(`/user/call_targets/${id}/append_free_tag`, args);
  },
  deleteCallTargetFreeTag(args) {
    const { id } = args;
    return ApiBase.post(`/user/call_targets/${id}/delete_free_tag`, args);
  },
  getCallTargetLeadSources(args) {
    const { id } = args;
    return ApiBase.get(`/user/call_targets/${id}/lead_sources`, args);
  },
  appendCallTargetLeadSource(args) {
    const { id } = args;
    return ApiBase.post(`/user/call_targets/${id}/append_lead_source`, args);
  },
  deleteCallTargetLeadSource(args) {
    const { id } = args;
    return ApiBase.post(`/user/call_targets/${id}/delete_lead_source`, args);
  },
  getBusinessCategoryCandidates(args) {
    return ApiBase.get(`/user/call_targets/business_category_candidates`, args);
  },
  getPositionCandidates(args) {
    return ApiBase.get(`/user/call_targets/position_candidates`, args);
  },
  getDepartmentCandidates(args) {
    return ApiBase.get(`/user/call_targets/department_candidates`, args);
  },
  getAssignedUserCandidates() {
    return ApiBase.get(`/user/call_targets/assigned_user_candidates`);
  },
  getCallTargetUnsubscribe(args) {
    const { id } = args;
    return ApiBase.get(`/user/call_targets/${id}/unsubscribe`, args);
  },
  createCallTargetUnsubscribe(args) {
    const { id } = args;
    return ApiBase.post(`/user/call_targets/${id}/unsubscribe`, args);
  },
  deleteCallTargetUnsubscribe(args) {
    const { id } = args;
    return ApiBase.delete(`/user/call_targets/${id}/unsubscribe`, args);
  },
  getCallTargetsCount(args) {
    return ApiBase.get(`/user/call_targets/count`, args);
  },
};
