/* tslint:disable */
/* eslint-disable */
/**
 * openapi
 * bales cloud
 *
 * The version of the OpenAPI document: 1.0
 * Contact: test@example.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PostSequenceStepInstanceBody } from '../models';
// @ts-ignore
import { SequenceStepInstance } from '../models';
/**
 * SequenceStepInstanceApi - axios parameter creator
 * @export
 */
export const SequenceStepInstanceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * シーケンスステップインスタンス 個別メール下書き保存API
         * @summary シーケンスステップインスタンス 個別メール下書き保存API
         * @param {number} sequenceInstanceId シーケンスインスタンスID
         * @param {number} sequenceStepInstanceId シーケンスステップインスタンスID
         * @param {PostSequenceStepInstanceBody} [postSequenceStepInstanceBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        draftDirectMailSequenceStepInstance: async (sequenceInstanceId: number, sequenceStepInstanceId: number, postSequenceStepInstanceBody?: PostSequenceStepInstanceBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sequenceInstanceId' is not null or undefined
            assertParamExists('draftDirectMailSequenceStepInstance', 'sequenceInstanceId', sequenceInstanceId)
            // verify required parameter 'sequenceStepInstanceId' is not null or undefined
            assertParamExists('draftDirectMailSequenceStepInstance', 'sequenceStepInstanceId', sequenceStepInstanceId)
            const localVarPath = `/api/user/sequence/instances/{sequenceInstanceId}/steps/{sequenceStepInstanceId}/draft_direct_mail`
                .replace(`{${"sequenceInstanceId"}}`, encodeURIComponent(String(sequenceInstanceId)))
                .replace(`{${"sequenceStepInstanceId"}}`, encodeURIComponent(String(sequenceStepInstanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postSequenceStepInstanceBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * シーケンスステップインスタンス即時実行API
         * @summary シーケンスステップインスタンス即時実行API
         * @param {number} sequenceInstanceId シーケンスインスタンスID
         * @param {number} sequenceStepInstanceId シーケンスステップインスタンスID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeSequenceStepInstance: async (sequenceInstanceId: number, sequenceStepInstanceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sequenceInstanceId' is not null or undefined
            assertParamExists('executeSequenceStepInstance', 'sequenceInstanceId', sequenceInstanceId)
            // verify required parameter 'sequenceStepInstanceId' is not null or undefined
            assertParamExists('executeSequenceStepInstance', 'sequenceStepInstanceId', sequenceStepInstanceId)
            const localVarPath = `/api/user/sequence/instances/{sequenceInstanceId}/steps/{sequenceStepInstanceId}/execute`
                .replace(`{${"sequenceInstanceId"}}`, encodeURIComponent(String(sequenceInstanceId)))
                .replace(`{${"sequenceStepInstanceId"}}`, encodeURIComponent(String(sequenceStepInstanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 現在のシーケンスステップインスタンス取得API
         * @summary 現在のシーケンスステップインスタンス取得API
         * @param {number} sequenceInstanceId シーケンスインスタンスID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentSequenceStepInstance: async (sequenceInstanceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sequenceInstanceId' is not null or undefined
            assertParamExists('getCurrentSequenceStepInstance', 'sequenceInstanceId', sequenceInstanceId)
            const localVarPath = `/api/user/sequence/instances/{sequenceInstanceId}/steps/current`
                .replace(`{${"sequenceInstanceId"}}`, encodeURIComponent(String(sequenceInstanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * シーケンスステップインスタンス一覧取得API
         * @summary シーケンスステップインスタンス一覧取得API
         * @param {number} sequenceInstanceId シーケンスインスタンスID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSequenceStepInstances: async (sequenceInstanceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sequenceInstanceId' is not null or undefined
            assertParamExists('getSequenceStepInstances', 'sequenceInstanceId', sequenceInstanceId)
            const localVarPath = `/api/user/sequence/instances/{sequenceInstanceId}/steps`
                .replace(`{${"sequenceInstanceId"}}`, encodeURIComponent(String(sequenceInstanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * シーケンスステップインスタンス 個別メール詳細取得API
         * @summary シーケンスステップインスタンス 個別メール詳細取得API
         * @param {number} sequenceInstanceId シーケンスインスタンスID
         * @param {number} sequenceStepInstanceId シーケンスステップインスタンスID
         * @param {PostSequenceStepInstanceBody} [postSequenceStepInstanceBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        previewSequenceStepInstance: async (sequenceInstanceId: number, sequenceStepInstanceId: number, postSequenceStepInstanceBody?: PostSequenceStepInstanceBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sequenceInstanceId' is not null or undefined
            assertParamExists('previewSequenceStepInstance', 'sequenceInstanceId', sequenceInstanceId)
            // verify required parameter 'sequenceStepInstanceId' is not null or undefined
            assertParamExists('previewSequenceStepInstance', 'sequenceStepInstanceId', sequenceStepInstanceId)
            const localVarPath = `/api/user/sequence/instances/{sequenceInstanceId}/steps/{sequenceStepInstanceId}/preview`
                .replace(`{${"sequenceInstanceId"}}`, encodeURIComponent(String(sequenceInstanceId)))
                .replace(`{${"sequenceStepInstanceId"}}`, encodeURIComponent(String(sequenceStepInstanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postSequenceStepInstanceBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * シーケンスステップインスタンス更新API
         * @summary シーケンスステップインスタンス更新API
         * @param {number} sequenceInstanceId シーケンスインスタンスID
         * @param {number} sequenceStepInstanceId シーケンスステップインスタンスID
         * @param {PostSequenceStepInstanceBody} [postSequenceStepInstanceBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putSequenceStepInstance: async (sequenceInstanceId: number, sequenceStepInstanceId: number, postSequenceStepInstanceBody?: PostSequenceStepInstanceBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sequenceInstanceId' is not null or undefined
            assertParamExists('putSequenceStepInstance', 'sequenceInstanceId', sequenceInstanceId)
            // verify required parameter 'sequenceStepInstanceId' is not null or undefined
            assertParamExists('putSequenceStepInstance', 'sequenceStepInstanceId', sequenceStepInstanceId)
            const localVarPath = `/api/user/sequence/instances/{sequenceInstanceId}/steps/{sequenceStepInstanceId}`
                .replace(`{${"sequenceInstanceId"}}`, encodeURIComponent(String(sequenceInstanceId)))
                .replace(`{${"sequenceStepInstanceId"}}`, encodeURIComponent(String(sequenceStepInstanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postSequenceStepInstanceBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * シーケンスステップインスタンス 個別メール配信予約API
         * @summary シーケンスステップインスタンス 個別メール配信予約API
         * @param {number} sequenceInstanceId シーケンスインスタンスID
         * @param {number} sequenceStepInstanceId シーケンスステップインスタンスID
         * @param {PostSequenceStepInstanceBody} [postSequenceStepInstanceBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reserveDirectMailSequenceStepInstance: async (sequenceInstanceId: number, sequenceStepInstanceId: number, postSequenceStepInstanceBody?: PostSequenceStepInstanceBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sequenceInstanceId' is not null or undefined
            assertParamExists('reserveDirectMailSequenceStepInstance', 'sequenceInstanceId', sequenceInstanceId)
            // verify required parameter 'sequenceStepInstanceId' is not null or undefined
            assertParamExists('reserveDirectMailSequenceStepInstance', 'sequenceStepInstanceId', sequenceStepInstanceId)
            const localVarPath = `/api/user/sequence/instances/{sequenceInstanceId}/steps/{sequenceStepInstanceId}/reserve_direct_mail`
                .replace(`{${"sequenceInstanceId"}}`, encodeURIComponent(String(sequenceInstanceId)))
                .replace(`{${"sequenceStepInstanceId"}}`, encodeURIComponent(String(sequenceStepInstanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postSequenceStepInstanceBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * シーケンスステップインスタンススキップAPI
         * @summary シーケンスステップインスタンススキップAPI
         * @param {number} sequenceInstanceId シーケンスインスタンスID
         * @param {number} sequenceStepInstanceId シーケンスステップインスタンスID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipSequenceStepInstance: async (sequenceInstanceId: number, sequenceStepInstanceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sequenceInstanceId' is not null or undefined
            assertParamExists('skipSequenceStepInstance', 'sequenceInstanceId', sequenceInstanceId)
            // verify required parameter 'sequenceStepInstanceId' is not null or undefined
            assertParamExists('skipSequenceStepInstance', 'sequenceStepInstanceId', sequenceStepInstanceId)
            const localVarPath = `/api/user/sequence/instances/{sequenceInstanceId}/steps/{sequenceStepInstanceId}/skip`
                .replace(`{${"sequenceInstanceId"}}`, encodeURIComponent(String(sequenceInstanceId)))
                .replace(`{${"sequenceStepInstanceId"}}`, encodeURIComponent(String(sequenceStepInstanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SequenceStepInstanceApi - functional programming interface
 * @export
 */
export const SequenceStepInstanceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SequenceStepInstanceApiAxiosParamCreator(configuration)
    return {
        /**
         * シーケンスステップインスタンス 個別メール下書き保存API
         * @summary シーケンスステップインスタンス 個別メール下書き保存API
         * @param {number} sequenceInstanceId シーケンスインスタンスID
         * @param {number} sequenceStepInstanceId シーケンスステップインスタンスID
         * @param {PostSequenceStepInstanceBody} [postSequenceStepInstanceBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async draftDirectMailSequenceStepInstance(sequenceInstanceId: number, sequenceStepInstanceId: number, postSequenceStepInstanceBody?: PostSequenceStepInstanceBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.draftDirectMailSequenceStepInstance(sequenceInstanceId, sequenceStepInstanceId, postSequenceStepInstanceBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * シーケンスステップインスタンス即時実行API
         * @summary シーケンスステップインスタンス即時実行API
         * @param {number} sequenceInstanceId シーケンスインスタンスID
         * @param {number} sequenceStepInstanceId シーケンスステップインスタンスID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async executeSequenceStepInstance(sequenceInstanceId: number, sequenceStepInstanceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.executeSequenceStepInstance(sequenceInstanceId, sequenceStepInstanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 現在のシーケンスステップインスタンス取得API
         * @summary 現在のシーケンスステップインスタンス取得API
         * @param {number} sequenceInstanceId シーケンスインスタンスID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentSequenceStepInstance(sequenceInstanceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SequenceStepInstance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentSequenceStepInstance(sequenceInstanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * シーケンスステップインスタンス一覧取得API
         * @summary シーケンスステップインスタンス一覧取得API
         * @param {number} sequenceInstanceId シーケンスインスタンスID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSequenceStepInstances(sequenceInstanceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SequenceStepInstance>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSequenceStepInstances(sequenceInstanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * シーケンスステップインスタンス 個別メール詳細取得API
         * @summary シーケンスステップインスタンス 個別メール詳細取得API
         * @param {number} sequenceInstanceId シーケンスインスタンスID
         * @param {number} sequenceStepInstanceId シーケンスステップインスタンスID
         * @param {PostSequenceStepInstanceBody} [postSequenceStepInstanceBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async previewSequenceStepInstance(sequenceInstanceId: number, sequenceStepInstanceId: number, postSequenceStepInstanceBody?: PostSequenceStepInstanceBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SequenceStepInstance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.previewSequenceStepInstance(sequenceInstanceId, sequenceStepInstanceId, postSequenceStepInstanceBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * シーケンスステップインスタンス更新API
         * @summary シーケンスステップインスタンス更新API
         * @param {number} sequenceInstanceId シーケンスインスタンスID
         * @param {number} sequenceStepInstanceId シーケンスステップインスタンスID
         * @param {PostSequenceStepInstanceBody} [postSequenceStepInstanceBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putSequenceStepInstance(sequenceInstanceId: number, sequenceStepInstanceId: number, postSequenceStepInstanceBody?: PostSequenceStepInstanceBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putSequenceStepInstance(sequenceInstanceId, sequenceStepInstanceId, postSequenceStepInstanceBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * シーケンスステップインスタンス 個別メール配信予約API
         * @summary シーケンスステップインスタンス 個別メール配信予約API
         * @param {number} sequenceInstanceId シーケンスインスタンスID
         * @param {number} sequenceStepInstanceId シーケンスステップインスタンスID
         * @param {PostSequenceStepInstanceBody} [postSequenceStepInstanceBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reserveDirectMailSequenceStepInstance(sequenceInstanceId: number, sequenceStepInstanceId: number, postSequenceStepInstanceBody?: PostSequenceStepInstanceBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reserveDirectMailSequenceStepInstance(sequenceInstanceId, sequenceStepInstanceId, postSequenceStepInstanceBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * シーケンスステップインスタンススキップAPI
         * @summary シーケンスステップインスタンススキップAPI
         * @param {number} sequenceInstanceId シーケンスインスタンスID
         * @param {number} sequenceStepInstanceId シーケンスステップインスタンスID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async skipSequenceStepInstance(sequenceInstanceId: number, sequenceStepInstanceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.skipSequenceStepInstance(sequenceInstanceId, sequenceStepInstanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SequenceStepInstanceApi - factory interface
 * @export
 */
export const SequenceStepInstanceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SequenceStepInstanceApiFp(configuration)
    return {
        /**
         * シーケンスステップインスタンス 個別メール下書き保存API
         * @summary シーケンスステップインスタンス 個別メール下書き保存API
         * @param {number} sequenceInstanceId シーケンスインスタンスID
         * @param {number} sequenceStepInstanceId シーケンスステップインスタンスID
         * @param {PostSequenceStepInstanceBody} [postSequenceStepInstanceBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        draftDirectMailSequenceStepInstance(sequenceInstanceId: number, sequenceStepInstanceId: number, postSequenceStepInstanceBody?: PostSequenceStepInstanceBody, options?: any): AxiosPromise<void> {
            return localVarFp.draftDirectMailSequenceStepInstance(sequenceInstanceId, sequenceStepInstanceId, postSequenceStepInstanceBody, options).then((request) => request(axios, basePath));
        },
        /**
         * シーケンスステップインスタンス即時実行API
         * @summary シーケンスステップインスタンス即時実行API
         * @param {number} sequenceInstanceId シーケンスインスタンスID
         * @param {number} sequenceStepInstanceId シーケンスステップインスタンスID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeSequenceStepInstance(sequenceInstanceId: number, sequenceStepInstanceId: number, options?: any): AxiosPromise<void> {
            return localVarFp.executeSequenceStepInstance(sequenceInstanceId, sequenceStepInstanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 現在のシーケンスステップインスタンス取得API
         * @summary 現在のシーケンスステップインスタンス取得API
         * @param {number} sequenceInstanceId シーケンスインスタンスID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentSequenceStepInstance(sequenceInstanceId: number, options?: any): AxiosPromise<SequenceStepInstance> {
            return localVarFp.getCurrentSequenceStepInstance(sequenceInstanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * シーケンスステップインスタンス一覧取得API
         * @summary シーケンスステップインスタンス一覧取得API
         * @param {number} sequenceInstanceId シーケンスインスタンスID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSequenceStepInstances(sequenceInstanceId: number, options?: any): AxiosPromise<Array<SequenceStepInstance>> {
            return localVarFp.getSequenceStepInstances(sequenceInstanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * シーケンスステップインスタンス 個別メール詳細取得API
         * @summary シーケンスステップインスタンス 個別メール詳細取得API
         * @param {number} sequenceInstanceId シーケンスインスタンスID
         * @param {number} sequenceStepInstanceId シーケンスステップインスタンスID
         * @param {PostSequenceStepInstanceBody} [postSequenceStepInstanceBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        previewSequenceStepInstance(sequenceInstanceId: number, sequenceStepInstanceId: number, postSequenceStepInstanceBody?: PostSequenceStepInstanceBody, options?: any): AxiosPromise<SequenceStepInstance> {
            return localVarFp.previewSequenceStepInstance(sequenceInstanceId, sequenceStepInstanceId, postSequenceStepInstanceBody, options).then((request) => request(axios, basePath));
        },
        /**
         * シーケンスステップインスタンス更新API
         * @summary シーケンスステップインスタンス更新API
         * @param {number} sequenceInstanceId シーケンスインスタンスID
         * @param {number} sequenceStepInstanceId シーケンスステップインスタンスID
         * @param {PostSequenceStepInstanceBody} [postSequenceStepInstanceBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putSequenceStepInstance(sequenceInstanceId: number, sequenceStepInstanceId: number, postSequenceStepInstanceBody?: PostSequenceStepInstanceBody, options?: any): AxiosPromise<void> {
            return localVarFp.putSequenceStepInstance(sequenceInstanceId, sequenceStepInstanceId, postSequenceStepInstanceBody, options).then((request) => request(axios, basePath));
        },
        /**
         * シーケンスステップインスタンス 個別メール配信予約API
         * @summary シーケンスステップインスタンス 個別メール配信予約API
         * @param {number} sequenceInstanceId シーケンスインスタンスID
         * @param {number} sequenceStepInstanceId シーケンスステップインスタンスID
         * @param {PostSequenceStepInstanceBody} [postSequenceStepInstanceBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reserveDirectMailSequenceStepInstance(sequenceInstanceId: number, sequenceStepInstanceId: number, postSequenceStepInstanceBody?: PostSequenceStepInstanceBody, options?: any): AxiosPromise<void> {
            return localVarFp.reserveDirectMailSequenceStepInstance(sequenceInstanceId, sequenceStepInstanceId, postSequenceStepInstanceBody, options).then((request) => request(axios, basePath));
        },
        /**
         * シーケンスステップインスタンススキップAPI
         * @summary シーケンスステップインスタンススキップAPI
         * @param {number} sequenceInstanceId シーケンスインスタンスID
         * @param {number} sequenceStepInstanceId シーケンスステップインスタンスID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipSequenceStepInstance(sequenceInstanceId: number, sequenceStepInstanceId: number, options?: any): AxiosPromise<void> {
            return localVarFp.skipSequenceStepInstance(sequenceInstanceId, sequenceStepInstanceId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SequenceStepInstanceApi - interface
 * @export
 * @interface SequenceStepInstanceApi
 */
export interface SequenceStepInstanceApiInterface {
    /**
     * シーケンスステップインスタンス 個別メール下書き保存API
     * @summary シーケンスステップインスタンス 個別メール下書き保存API
     * @param {number} sequenceInstanceId シーケンスインスタンスID
     * @param {number} sequenceStepInstanceId シーケンスステップインスタンスID
     * @param {PostSequenceStepInstanceBody} [postSequenceStepInstanceBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceStepInstanceApiInterface
     */
    draftDirectMailSequenceStepInstance(sequenceInstanceId: number, sequenceStepInstanceId: number, postSequenceStepInstanceBody?: PostSequenceStepInstanceBody, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * シーケンスステップインスタンス即時実行API
     * @summary シーケンスステップインスタンス即時実行API
     * @param {number} sequenceInstanceId シーケンスインスタンスID
     * @param {number} sequenceStepInstanceId シーケンスステップインスタンスID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceStepInstanceApiInterface
     */
    executeSequenceStepInstance(sequenceInstanceId: number, sequenceStepInstanceId: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 現在のシーケンスステップインスタンス取得API
     * @summary 現在のシーケンスステップインスタンス取得API
     * @param {number} sequenceInstanceId シーケンスインスタンスID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceStepInstanceApiInterface
     */
    getCurrentSequenceStepInstance(sequenceInstanceId: number, options?: AxiosRequestConfig): AxiosPromise<SequenceStepInstance>;

    /**
     * シーケンスステップインスタンス一覧取得API
     * @summary シーケンスステップインスタンス一覧取得API
     * @param {number} sequenceInstanceId シーケンスインスタンスID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceStepInstanceApiInterface
     */
    getSequenceStepInstances(sequenceInstanceId: number, options?: AxiosRequestConfig): AxiosPromise<Array<SequenceStepInstance>>;

    /**
     * シーケンスステップインスタンス 個別メール詳細取得API
     * @summary シーケンスステップインスタンス 個別メール詳細取得API
     * @param {number} sequenceInstanceId シーケンスインスタンスID
     * @param {number} sequenceStepInstanceId シーケンスステップインスタンスID
     * @param {PostSequenceStepInstanceBody} [postSequenceStepInstanceBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceStepInstanceApiInterface
     */
    previewSequenceStepInstance(sequenceInstanceId: number, sequenceStepInstanceId: number, postSequenceStepInstanceBody?: PostSequenceStepInstanceBody, options?: AxiosRequestConfig): AxiosPromise<SequenceStepInstance>;

    /**
     * シーケンスステップインスタンス更新API
     * @summary シーケンスステップインスタンス更新API
     * @param {number} sequenceInstanceId シーケンスインスタンスID
     * @param {number} sequenceStepInstanceId シーケンスステップインスタンスID
     * @param {PostSequenceStepInstanceBody} [postSequenceStepInstanceBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceStepInstanceApiInterface
     */
    putSequenceStepInstance(sequenceInstanceId: number, sequenceStepInstanceId: number, postSequenceStepInstanceBody?: PostSequenceStepInstanceBody, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * シーケンスステップインスタンス 個別メール配信予約API
     * @summary シーケンスステップインスタンス 個別メール配信予約API
     * @param {number} sequenceInstanceId シーケンスインスタンスID
     * @param {number} sequenceStepInstanceId シーケンスステップインスタンスID
     * @param {PostSequenceStepInstanceBody} [postSequenceStepInstanceBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceStepInstanceApiInterface
     */
    reserveDirectMailSequenceStepInstance(sequenceInstanceId: number, sequenceStepInstanceId: number, postSequenceStepInstanceBody?: PostSequenceStepInstanceBody, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * シーケンスステップインスタンススキップAPI
     * @summary シーケンスステップインスタンススキップAPI
     * @param {number} sequenceInstanceId シーケンスインスタンスID
     * @param {number} sequenceStepInstanceId シーケンスステップインスタンスID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceStepInstanceApiInterface
     */
    skipSequenceStepInstance(sequenceInstanceId: number, sequenceStepInstanceId: number, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * Request parameters for draftDirectMailSequenceStepInstance operation in SequenceStepInstanceApi.
 * @export
 * @interface SequenceStepInstanceApiDraftDirectMailSequenceStepInstanceRequest
 */
export interface SequenceStepInstanceApiDraftDirectMailSequenceStepInstanceRequest {
    /**
     * シーケンスインスタンスID
     * @type {number}
     * @memberof SequenceStepInstanceApiDraftDirectMailSequenceStepInstance
     */
    readonly sequenceInstanceId: number

    /**
     * シーケンスステップインスタンスID
     * @type {number}
     * @memberof SequenceStepInstanceApiDraftDirectMailSequenceStepInstance
     */
    readonly sequenceStepInstanceId: number

    /**
     * 
     * @type {PostSequenceStepInstanceBody}
     * @memberof SequenceStepInstanceApiDraftDirectMailSequenceStepInstance
     */
    readonly postSequenceStepInstanceBody?: PostSequenceStepInstanceBody
}

/**
 * Request parameters for executeSequenceStepInstance operation in SequenceStepInstanceApi.
 * @export
 * @interface SequenceStepInstanceApiExecuteSequenceStepInstanceRequest
 */
export interface SequenceStepInstanceApiExecuteSequenceStepInstanceRequest {
    /**
     * シーケンスインスタンスID
     * @type {number}
     * @memberof SequenceStepInstanceApiExecuteSequenceStepInstance
     */
    readonly sequenceInstanceId: number

    /**
     * シーケンスステップインスタンスID
     * @type {number}
     * @memberof SequenceStepInstanceApiExecuteSequenceStepInstance
     */
    readonly sequenceStepInstanceId: number
}

/**
 * Request parameters for getCurrentSequenceStepInstance operation in SequenceStepInstanceApi.
 * @export
 * @interface SequenceStepInstanceApiGetCurrentSequenceStepInstanceRequest
 */
export interface SequenceStepInstanceApiGetCurrentSequenceStepInstanceRequest {
    /**
     * シーケンスインスタンスID
     * @type {number}
     * @memberof SequenceStepInstanceApiGetCurrentSequenceStepInstance
     */
    readonly sequenceInstanceId: number
}

/**
 * Request parameters for getSequenceStepInstances operation in SequenceStepInstanceApi.
 * @export
 * @interface SequenceStepInstanceApiGetSequenceStepInstancesRequest
 */
export interface SequenceStepInstanceApiGetSequenceStepInstancesRequest {
    /**
     * シーケンスインスタンスID
     * @type {number}
     * @memberof SequenceStepInstanceApiGetSequenceStepInstances
     */
    readonly sequenceInstanceId: number
}

/**
 * Request parameters for previewSequenceStepInstance operation in SequenceStepInstanceApi.
 * @export
 * @interface SequenceStepInstanceApiPreviewSequenceStepInstanceRequest
 */
export interface SequenceStepInstanceApiPreviewSequenceStepInstanceRequest {
    /**
     * シーケンスインスタンスID
     * @type {number}
     * @memberof SequenceStepInstanceApiPreviewSequenceStepInstance
     */
    readonly sequenceInstanceId: number

    /**
     * シーケンスステップインスタンスID
     * @type {number}
     * @memberof SequenceStepInstanceApiPreviewSequenceStepInstance
     */
    readonly sequenceStepInstanceId: number

    /**
     * 
     * @type {PostSequenceStepInstanceBody}
     * @memberof SequenceStepInstanceApiPreviewSequenceStepInstance
     */
    readonly postSequenceStepInstanceBody?: PostSequenceStepInstanceBody
}

/**
 * Request parameters for putSequenceStepInstance operation in SequenceStepInstanceApi.
 * @export
 * @interface SequenceStepInstanceApiPutSequenceStepInstanceRequest
 */
export interface SequenceStepInstanceApiPutSequenceStepInstanceRequest {
    /**
     * シーケンスインスタンスID
     * @type {number}
     * @memberof SequenceStepInstanceApiPutSequenceStepInstance
     */
    readonly sequenceInstanceId: number

    /**
     * シーケンスステップインスタンスID
     * @type {number}
     * @memberof SequenceStepInstanceApiPutSequenceStepInstance
     */
    readonly sequenceStepInstanceId: number

    /**
     * 
     * @type {PostSequenceStepInstanceBody}
     * @memberof SequenceStepInstanceApiPutSequenceStepInstance
     */
    readonly postSequenceStepInstanceBody?: PostSequenceStepInstanceBody
}

/**
 * Request parameters for reserveDirectMailSequenceStepInstance operation in SequenceStepInstanceApi.
 * @export
 * @interface SequenceStepInstanceApiReserveDirectMailSequenceStepInstanceRequest
 */
export interface SequenceStepInstanceApiReserveDirectMailSequenceStepInstanceRequest {
    /**
     * シーケンスインスタンスID
     * @type {number}
     * @memberof SequenceStepInstanceApiReserveDirectMailSequenceStepInstance
     */
    readonly sequenceInstanceId: number

    /**
     * シーケンスステップインスタンスID
     * @type {number}
     * @memberof SequenceStepInstanceApiReserveDirectMailSequenceStepInstance
     */
    readonly sequenceStepInstanceId: number

    /**
     * 
     * @type {PostSequenceStepInstanceBody}
     * @memberof SequenceStepInstanceApiReserveDirectMailSequenceStepInstance
     */
    readonly postSequenceStepInstanceBody?: PostSequenceStepInstanceBody
}

/**
 * Request parameters for skipSequenceStepInstance operation in SequenceStepInstanceApi.
 * @export
 * @interface SequenceStepInstanceApiSkipSequenceStepInstanceRequest
 */
export interface SequenceStepInstanceApiSkipSequenceStepInstanceRequest {
    /**
     * シーケンスインスタンスID
     * @type {number}
     * @memberof SequenceStepInstanceApiSkipSequenceStepInstance
     */
    readonly sequenceInstanceId: number

    /**
     * シーケンスステップインスタンスID
     * @type {number}
     * @memberof SequenceStepInstanceApiSkipSequenceStepInstance
     */
    readonly sequenceStepInstanceId: number
}

/**
 * SequenceStepInstanceApi - object-oriented interface
 * @export
 * @class SequenceStepInstanceApi
 * @extends {BaseAPI}
 */
export class SequenceStepInstanceApi extends BaseAPI implements SequenceStepInstanceApiInterface {
    /**
     * シーケンスステップインスタンス 個別メール下書き保存API
     * @summary シーケンスステップインスタンス 個別メール下書き保存API
     * @param {SequenceStepInstanceApiDraftDirectMailSequenceStepInstanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceStepInstanceApi
     */
    public draftDirectMailSequenceStepInstance(requestParameters: SequenceStepInstanceApiDraftDirectMailSequenceStepInstanceRequest, options?: AxiosRequestConfig) {
        return SequenceStepInstanceApiFp(this.configuration).draftDirectMailSequenceStepInstance(requestParameters.sequenceInstanceId, requestParameters.sequenceStepInstanceId, requestParameters.postSequenceStepInstanceBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * シーケンスステップインスタンス即時実行API
     * @summary シーケンスステップインスタンス即時実行API
     * @param {SequenceStepInstanceApiExecuteSequenceStepInstanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceStepInstanceApi
     */
    public executeSequenceStepInstance(requestParameters: SequenceStepInstanceApiExecuteSequenceStepInstanceRequest, options?: AxiosRequestConfig) {
        return SequenceStepInstanceApiFp(this.configuration).executeSequenceStepInstance(requestParameters.sequenceInstanceId, requestParameters.sequenceStepInstanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 現在のシーケンスステップインスタンス取得API
     * @summary 現在のシーケンスステップインスタンス取得API
     * @param {SequenceStepInstanceApiGetCurrentSequenceStepInstanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceStepInstanceApi
     */
    public getCurrentSequenceStepInstance(requestParameters: SequenceStepInstanceApiGetCurrentSequenceStepInstanceRequest, options?: AxiosRequestConfig) {
        return SequenceStepInstanceApiFp(this.configuration).getCurrentSequenceStepInstance(requestParameters.sequenceInstanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * シーケンスステップインスタンス一覧取得API
     * @summary シーケンスステップインスタンス一覧取得API
     * @param {SequenceStepInstanceApiGetSequenceStepInstancesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceStepInstanceApi
     */
    public getSequenceStepInstances(requestParameters: SequenceStepInstanceApiGetSequenceStepInstancesRequest, options?: AxiosRequestConfig) {
        return SequenceStepInstanceApiFp(this.configuration).getSequenceStepInstances(requestParameters.sequenceInstanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * シーケンスステップインスタンス 個別メール詳細取得API
     * @summary シーケンスステップインスタンス 個別メール詳細取得API
     * @param {SequenceStepInstanceApiPreviewSequenceStepInstanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceStepInstanceApi
     */
    public previewSequenceStepInstance(requestParameters: SequenceStepInstanceApiPreviewSequenceStepInstanceRequest, options?: AxiosRequestConfig) {
        return SequenceStepInstanceApiFp(this.configuration).previewSequenceStepInstance(requestParameters.sequenceInstanceId, requestParameters.sequenceStepInstanceId, requestParameters.postSequenceStepInstanceBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * シーケンスステップインスタンス更新API
     * @summary シーケンスステップインスタンス更新API
     * @param {SequenceStepInstanceApiPutSequenceStepInstanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceStepInstanceApi
     */
    public putSequenceStepInstance(requestParameters: SequenceStepInstanceApiPutSequenceStepInstanceRequest, options?: AxiosRequestConfig) {
        return SequenceStepInstanceApiFp(this.configuration).putSequenceStepInstance(requestParameters.sequenceInstanceId, requestParameters.sequenceStepInstanceId, requestParameters.postSequenceStepInstanceBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * シーケンスステップインスタンス 個別メール配信予約API
     * @summary シーケンスステップインスタンス 個別メール配信予約API
     * @param {SequenceStepInstanceApiReserveDirectMailSequenceStepInstanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceStepInstanceApi
     */
    public reserveDirectMailSequenceStepInstance(requestParameters: SequenceStepInstanceApiReserveDirectMailSequenceStepInstanceRequest, options?: AxiosRequestConfig) {
        return SequenceStepInstanceApiFp(this.configuration).reserveDirectMailSequenceStepInstance(requestParameters.sequenceInstanceId, requestParameters.sequenceStepInstanceId, requestParameters.postSequenceStepInstanceBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * シーケンスステップインスタンススキップAPI
     * @summary シーケンスステップインスタンススキップAPI
     * @param {SequenceStepInstanceApiSkipSequenceStepInstanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceStepInstanceApi
     */
    public skipSequenceStepInstance(requestParameters: SequenceStepInstanceApiSkipSequenceStepInstanceRequest, options?: AxiosRequestConfig) {
        return SequenceStepInstanceApiFp(this.configuration).skipSequenceStepInstance(requestParameters.sequenceInstanceId, requestParameters.sequenceStepInstanceId, options).then((request) => request(this.axios, this.basePath));
    }
}
