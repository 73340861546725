import { computed } from 'vue';
import { DirectMail, ReserveDirectMail } from '@/api/openapi';

const isRelatedToSequence = (directMail: DirectMail | ReserveDirectMail) => {
  if ('sequenceInstanceId' in directMail) {
    return directMail.sequenceInstanceId != null;
  }
  return false;
};

const useCheckRelationToSequence = (getDirectMail: () => DirectMail | ReserveDirectMail) => {
  const isSequenceDirectMail = computed(() => {
    return isRelatedToSequence(getDirectMail());
  });

  return {
    isSequenceDirectMail,
  };
};

export {
  useCheckRelationToSequence,
  isRelatedToSequence,
};
