import ApiBase from '@/api/base';

export default {
  getBoxilLeadImportSetting () {
    return ApiBase.get(`/user/boxil_lead_import_settings/`);
  },
  createBoxilLeadImportSetting (args) {
    return ApiBase.post(`/user/boxil_lead_import_settings/`, args);
  },
  testBoxilLeadImportSetting (args) {
    return ApiBase.post(`/user/boxil_lead_import_settings/test`, args);
  },
  deleteBoxilLeadImportSetting () {
    return ApiBase.delete(`/user/boxil_lead_import_settings/`);
  },
};
