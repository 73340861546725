<template>
  <BModal
    :modal-name="modalName"
    height="auto"
    width="auto"
    class="error-mapping-modal"
    scroll
  >
    <BHeightAdjuster>
      <BModalHeader
        :title="$t('apiError.salesforce.validation.required.necessaryMappingMissing')"
        center
        @modal-close="close"
      />
      <BModalBody>
        <BLayout
          justify-center
          align-center
          is-fit
        >
          <div class="modal-body">
            <div
              v-for="(error, group) in importMappingErrors"
              :key="group"
              class="error-row"
            >
              <div>{{ group }}</div>
              <div class="error-value">
                <div
                  v-for="value in error"
                  :key="value"
                >
                  {{ value }}
                </div>
              </div>
            </div>
          </div>
        </BLayout>
      </BModalBody>
    </BHeightAdjuster>
  </BModal>
</template>

<script setup lang="ts">
import { inject, ref } from 'vue';
import { KEY_OF_MODAL } from '@/injection-keys';

withDefaults(defineProps<TProps>(), {});
const modalName = ref('salesforceReportErrorMappingModal');
type TProps = {
  importMappingErrors: string[];
};
const modal = inject(KEY_OF_MODAL);
const close = () => {
  modal.hide('salesforceReportErrorMappingModal');
};
</script>

<style lang="scss" scoped>
.error-row {
  display: flex;
  border-bottom: 1px solid #E3E3E3;
  justify-content: flex-start;
  padding: 20px 0;
  font-size: 14px;
  > div {
    width: 200px;
    text-align: left;
  }
}
</style>
