<template>
  <div class="multi-input-container">
    <div
      v-for="(value, index) in values"
      :key="index"
    >
      <BInputBox
        v-model="values[index]"
        :rules="rules"
        :placeholder="placeholder"
        :closeable="index !== 0"
        class="mb-50"
        settle-type="blur"
        horiz
        required
        @update:valid="(value) => handleUpdateValid(index, value)"
        @close="() => handleInputCloseClick(index)"
      />
    </div>
    <BBtn
      v-if="additionable"
      type="primary"
      text
      size="small"
      @click="handleAppendClick"
    >
      <BIcon
        type="primary"
        size="small"
      >
        add
      </BIcon>
      <span class="ml-50">
        {{ $t('general.append.of', {item: $t('general.target')}) }}
      </span>
    </BBtn>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch, computed, onMounted } from 'vue';

const props = defineProps<TProps>();
const emit = defineEmits<TEmits>();
const valid = defineModel<boolean>('valid', { default: true });

type TProps = {
  modelValue: string[] | null | undefined,
  rules: string[],
  placeholder: string,
  minInputSize: number,
  maxInputSize: number,
};
type TEmits = {
  'update:modelValue': [values: string[]];
};

const values = ref<string[]>([]);
const valids = ref<boolean[]>([]);

onMounted(() => {
  if (props.modelValue) {
    values.value = props.modelValue;
  } else {
    values.value = Array(props.minInputSize).fill('');
  }
  valids.value = Array(values.value.length).fill(true);
});

const additionable = computed(() => valids.value.length < props.maxInputSize);

watch(values,
  value => {
    if (valid.value) {
      emit('update:modelValue', values.value);
    }
  },
  { deep: true },
);

watch(valids,
  value => {
    valid.value = valids.value.every((v) => v);
  },
  { deep: true },
);

const handleAppendClick = () => {
  if (additionable.value) {
    values.value.push('');
    valids.value.push(false);
  }
};

const handleInputCloseClick = (index: number) => {
  values.value.splice(index, 1);
  valids.value.splice(index, 1);
};

const handleUpdateValid = (index: number, value: boolean) => {
  valids.value[index] = value;
};
</script>
