<template>
  <div class="b-sort-select">
    <BLayout align-center>
      <BFlex :span="20">
        <BSelect
          v-bind="attributes"
          v-model="internalValue"
          @change="changeItem"
        />
      </BFlex>
      <BFlex :span="4">
        <BBtn
          size="small"
          fab
          flat
          @click="changeSort"
        >
          <BIcon
            class="b-sort-icon"
            size="small"
            :class="classes"
          >
            arrow_downward
          </BIcon>
        </BBtn>
      </BFlex>
    </BLayout>
  </div>
</template>

<script>
export default {
  name: 'BSortSelect',
  props: {
    items: Array,
    value: [String, Number],
    itemText: String,
    itemValue: String,
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'input',
    'change',
    'order-by',
  ],
  data () {
    return {
      orderBy: 'asc',
    };
  },
  computed: {
    internalValue: {
      get () {
        return this.value;
      },
      set (newVal) {
        if (this.value !== newVal) this.$emit('input', newVal);
      },
    },
    attributes () {
      return {
        'items': this.items,
        'item-text': this.itemText,
        'item-value': this.itemValue,
        'clearable': this.clearable,
        'flat': true,
      };
    },
    classes () {
      return {
        'b-sort-desc': this.orderBy === 'desc',
      };
    },
  },
  methods: {
    changeItem (item) {
      this.$emit('input', item);
      this.$emit('change', item);
    },
    changeSort () {
      if (this.orderBy === 'asc') {
        this.orderBy = 'desc';
      } else {
        this.orderBy = 'asc';
      }
      this.$emit('order-by', this.orderBy);
    },
  },
};
</script>

<style lang="scss" scoped>
  .b-sort-icon {
    transform: rotate(-180deg);
  }

  .b-sort-desc {
    transform: rotate(0);
  }
</style>
