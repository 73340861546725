<template>
  <BModal
    :modal-name="modalName"
    :width="modalWidth"
    :height="modalHeight"
  >
    <BHeightAdjuster>
      <BModalHeader
        :title="$t('leadView.title')"
        @modal-close="$modal.hide(modalName)"
      />
      <BModalBody v-loading="$wait.is('updateLeadViewWait')">
        <BLayout
          class="mt-600"
          column
        >
          <BListItem>
            <template #header>
              <div>{{ $t('leadView.name' ) }}</div>
            </template>
            <BInput
              v-model="leadViewName"
              v-model:valid="valid"
              autofocus
              :rules="[requiredRule]"
            />
          </BListItem>
          <BListItem class="mt-200">
            <template #header>
              <div>{{ $t('leadView.description' ) }}</div>
            </template>
            <BTextarea
              v-model="leadViewDescription"
              resize="none"
              :autosize="{ minRows: 4, maxRows: 10 }"
            />
          </BListItem>
        </BLayout>
      </BModalBody>
      <BModalFooter>
        <BLayout
          class="mt-200"
          justify-center
        >
          <BBtn
            type="primary"
            :disabled="!valid"
            :loading="$wait.is('updateLeadViewWait')"
            @click="handleUpdateLeadView"
          >
            {{ $t('general.update') }}
          </BBtn>
        </BLayout>
      </BModalFooter>
    </BHeightAdjuster>
  </BModal>
</template>

<script>
import { mapWaitingActions } from 'vue-wait';
import errorHandler from '@/mixins/error_handler';
import inputValidation from '@/mixins/input_validation';

export default {
  mixins: [inputValidation, errorHandler],
  props: {
    leadView: Object,
  },
  data () {
    return {
      valid: false,
      leadViewName: '',
      leadViewDescription: '',
      modalWidth: '580px',
      modalHeight: '580px',
    };
  },
  computed: {
    modalName () {
      return 'leadViewUpdateModal';
    },
  },
  watch: {
    leadView(value) {
      if (!value) return;
      this.leadViewName = value.name;
      this.leadViewDescription = value.description;
    },
  },
  methods: {
    ...mapWaitingActions('user', {
      updateLeadViewAction: 'updateLeadViewWait',
    }),
    handleUpdateLeadView () {
      this.updateLeadViewAction({
        leadViewId: this.leadView.id,
        body: { name: this.leadViewName, description: this.leadViewDescription },
        errorHandlers: {
          422: this.defaultHandler,
        },
      }).then(() => {
        this.$modal.hide(this.modalName);
      });
    },
  },
};
</script>
