<template>
  <div class="bulk-mail-form">
    <FormDrawer
      v-if="currentState === state.form"
      v-bind="formAttr"
      @change-current-state-to-confirm="toConfirm"
      @save-draft="saveDraft"
      @cancel="closeDrawer"
      @edit-form="editForm"
      @delete-draft="deleteDraft"
      @duplicate="$emit('duplicate', bulkMail)"
    />
    <ConfirmDrawer
      v-else-if="currentState === state.confirm"
      v-bind="confirmAttr"
      ref="confirmDrawer"
      @cancel="closeDrawer"
      @change-current-state-to-form="toForm"
      @send="send"
      @reserve="reserve"
    />
    <SendingDrawer
      v-else-if="currentState === state.sending"
      @cancel="closeDrawer"
      @sending-animation-completed="onSendingAnimationCompleted"
    />
    <SentDrawer
      v-else-if="currentState === state.sent"
      v-bind="confirmAttr"
      @cancel="closeDrawer"
      @duplicate="$emit('duplicate', bulkMail)"
    />
    <MailTemplateSelectModal
      v-if="isAvailableMailTemplateFeature"
      @click="applyMailTemplate"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapWaitingActions } from 'vue-wait';
import MailTemplateSelectModal from '@/components/organisms/user/general/modal/MailTemplateSelectModal.vue';
import ConfirmDrawer from '@/components/organisms/user/mail/bulk/drawer/state/confirm/ConfirmDrawer.vue';
import FormDrawer from '@/components/organisms/user/mail/bulk/drawer/state/form/FormDrawer.vue';
import SentDrawer from '@/components/organisms/user/mail/bulk/drawer/state/sent/SentDrawer.vue';
import { CONTENT_DEFAULT } from '@/components/organisms/user/mail/common/drawer/state/form/form-values';
import SendingDrawer from '@/components/organisms/user/mail/common/drawer/state/sending/SendingDrawer.vue';
import { useAvailableFeatureCheck } from '@/composable/available-feature-check';
import MAIL_STATUSES from '@/const/mail_statuses';
import errorHandler from '@/mixins/error_handler';
import { addMinutesToCurrentDateTime, isDateAfter } from '@/utils/date-time';

export default defineComponent({
  components: {
    FormDrawer,
    ConfirmDrawer,
    SendingDrawer,
    SentDrawer,
    MailTemplateSelectModal,
  },
  mixins: [errorHandler],
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    bulkMail: {
      type: Object,
      required: false,
      default: () => {},
    },
    canDuplicate: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'duplicate',
    'bulk-mail-change',
    'change',
    'cancel',
  ],
  setup() {
    const { isAvailableMailTemplateFeature } = useAvailableFeatureCheck();
    return {
      isAvailableMailTemplateFeature,
    };
  },
  data() {
    return {
      currentState: 0,
      isDisabledReplyForm: false,
      isSendNow: false,
      internalBulkMail: Object.keys(this.bulkMail).length > 0 ? this.bulkMail : this.defaultBulkMail(),
    };
  },
  computed: {
    state() {
      return {
        form: 0,
        confirm: 1,
        sending: 2,
        sent: 3,
        pending: 4,
      };
    },
    formAttr() {
      return {
        bulkMail: this.internalBulkMail,
        isDisabledReplyForm: this.isDisabledReplyForm,
        isSendNow: this.isSendNow,
        isValidSendAt: this.isValidSendAt,
        canDuplicate: !!this.bulkMail.id && this.canDuplicate,
      };
    },
    confirmAttr() {
      return {
        ...this.formAttr,
        canDuplicate: false,
      };
    },
    isValidSendAt() {
      if (this.isSendNow) return true;
      return isDateAfter(this.bulkMail.sendAt, addMinutesToCurrentDateTime(5));
    },
  },
  watch: {
    internalBulkMail: {
      handler(newVal) {
        this.$emit('bulk-mail-change', newVal);
      },
      deep: true,
    },
    isDisabledReplyForm() {
      this.resetReply();
    },
    isSendNow() {
      this.resetSendAt();
    },
  },
  created() {
    this.getLeadViewsAction();

    if (!this.bulkMail.id) return;

    this.setBulkMailData();
    if (this.bulkMail.status === MAIL_STATUSES.PENDING) this.saveDraft();
  },
  beforeUnmount() {
    this.clearBulkMailAction();
  },
  methods: {
    ...mapWaitingActions('user', {
      getLeadViewsAction: 'getLeadViewsWait',
      getBulkMailsAction: 'getBulkMailsWait',
      getBulkMailAction: 'getBulkMailWait',
      createBulkMailAction: 'createBulkMailWait',
      updateBulkMailAction: 'updateBulkMailWait',
      deleteBulkMailAction: 'deleteBulkMailWait',
      mailLeadCountAction: 'mailLeadCountWait',
      clearBulkMailAction: 'clearBulkMailWait',
      sendBulkAction: 'sendBulkWait',
      reserveBulkAction: 'reserveBulkWait',
    }),
    defaultBulkMail() {
      return {
        campaignName: '',
        subject: '',
        content: `${CONTENT_DEFAULT}<div>{{Unsubscribe:${this.$t('mail.optOutLink')}}}</div>`,
        sendAt: this.getDefaultSendAt(),
      };
    },
    getDefaultSendAt() {
      return addMinutesToCurrentDateTime(30);
    },
    setBulkMailData() {
      this.currentState = this.statusToCurrentState(this.bulkMail.status);
      this.isDisabledReplyForm = !this.bulkMail.replyAddress && !this.bulkMail.replyName;
      this.isSendNow = this.bulkMail.status === MAIL_STATUSES.DRAFT && !this.bulkMail.sendAt;
    },
    resetReply () {
      this.internalBulkMail.replyName = null;
      this.internalBulkMail.replyAddress = null;
    },
    resetSendAt () {
      this.internalBulkMail.sendAt = this.isSendNow ? null : this.getDefaultSendAt();
    },
    async saveDraft() {
      if (this.bulkMail.id) {
        await this.updateBulkMailAction({
          bulkMailId: this.bulkMail.id,
          body: this.makeRequestBody(),
          errorHandlers: {
            422: this.defaultHandler,
          },
          currentPage: this.currentPage,
        });
      } else {
        const savedMailValue = await this.createBulkMailAction({
          body: this.makeRequestBody(),
          errorHandlers: {
            422: this.defaultHandler,
          },
          currentPage: this.currentPage,
        });
        this.internalBulkMail.id = savedMailValue.id;
      }
      this.$nextTick(() => {
        this.$emit('change');
        this.$toast.show(this.$t('general.success.to', { action: this.$t('mail.saveDraft') }));
      });
    },
    async sendBulkMailFromModal(selectedSfUserId) {
      this.selectedSfUserId = selectedSfUserId;
      if (this.isSendNow) {
        await this.send();
      } else {
        await this.reserve();
      }
    },
    async deleteDraft() {
      await this.deleteBulkMailAction({
        bulkMailId: this.bulkMail.id,
        errorHandlers: {
          422: this.defaultHandler,
        },
        currentPage: this.currentPage,
      });
      this.closeDrawer();

      this.$toast.show(this.$t('general.success.to', { action: this.$t('mail.deleteDraft') }));
    },
    toForm() {
      this.currentState = this.state.form;
    },
    async toConfirm() {
      await this.saveDraft();
      await this.mailLeadCountAction({
        leadViewId: this.internalBulkMail.leadViewId,
        errorHandlers: {
          404: this.defaultHandler,
        },
      });
      this.currentState = this.state.confirm;
    },
    async reserve() {
      if (!this.isValidSendAt) {
        return this.toForm();
      }

      await this.reserveBulkAction({
        body: { bulkMailId: this.bulkMail.id, selectedSfUserId: this.selectedSfUserId || undefined },
        errorHandlers: {
          404: this.defaultHandler,
          422: this.defaultHandler,
        },
      });

      this.currentState = this.state.pending;
      this.onReservationCompleted();
    },
    async send() {
      await this.sendBulkAction({
        body: { bulkMailId: this.bulkMail.id, selectedSfUserId: this.selectedSfUserId || undefined },
        errorHandlers: {
          404: this.defaultHandler,
          422: this.defaultHandler,
        },
      });

      this.currentState = this.state.sending;
    },
    onSendingAnimationCompleted() {
      this.getBulkMailsAction({ currentPage: this.currentPage });
      this.closeDrawer();
    },
    onReservationCompleted() {
      this.$toast.show(`【${this.internalBulkMail.campaignName}】${this.$t('mail.reserveCompleted')}`);
      this.getBulkMailsAction({ currentPage: this.currentPage });
      this.closeDrawer();
    },
    editForm({
      isDisabledReplyForm,
      isSendNow,
    }) {
      this.isDisabledReplyForm = isDisabledReplyForm;
      this.isSendNow = isSendNow;
    },
    makeRequestBody() {
      return {
        campaignName: this.internalBulkMail.campaignName,
        leadViewId: this.internalBulkMail.leadViewId,
        fromAddress: this.internalBulkMail.fromAddress,
        fromName: this.internalBulkMail.fromName,
        subject: this.internalBulkMail.subject,
        content: this.internalBulkMail.content,
        replyName: this.internalBulkMail.replyName,
        replyAddress: this.internalBulkMail.replyAddress,
        open: this.internalBulkMail.open,
        click: this.internalBulkMail.click,
        sendAt: !this.isSendNow ? this.internalBulkMail.sendAt : null,
      };
    },
    statusToCurrentState(status) {
      switch (status) {
        case MAIL_STATUSES.DRAFT:
        case MAIL_STATUSES.PENDING:
          return this.state.form;
        case MAIL_STATUSES.SENT:
        case MAIL_STATUSES.SENDING:
        case MAIL_STATUSES.SENDING_FAILED:
          return this.state.sent;
        default:
          console.error('invalid status.', status);
          break;
      }
    },
    closeDrawer() {
      this.$emit('cancel');
    },
    applyMailTemplate (mailTemplate) {
      this.internalBulkMail.subject = mailTemplate.subject;
      this.internalBulkMail.content = mailTemplate.content;
      this.isDisabledReplyForm = !mailTemplate.replyName && !mailTemplate.replyAddress;
      this.$nextTick(() => {
        this.internalBulkMail.replyName = mailTemplate.replyName;
        this.internalBulkMail.replyAddress = mailTemplate.replyAddress;
      });
      this.$modal.hide('MailTemplateSelectModal');
    },
  },
});
</script>

<style lang="scss" scoped>
  .bulk-mail-form {
    height: 100%
  }
</style>
