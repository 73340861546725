<template>
  <div class="sidebar-content">
    <BMenu v-model="isMenuVisible">
      <template #activator>
        <div class="sidebar-profile">
          <div
            v-if="isSettingErrorVisible"
            class="setting-error truncate-text"
          >
            <BIcon
              class="mr-50"
              size="small"
              type="error"
            >
              info
            </BIcon>
            {{ $t('general.settingError') }}
          </div>
          <div
            v-else
            class="user-name truncate-text"
          >
            {{ currentUser.name }}
          </div>
          <BAvatar
            class="ml-100"
            :size="36"
            :image-url="currentUser.avatarUrl"
            :name="currentUser.name"
            :user-id="currentUser.id"
          />
        </div>
      </template>
      <BCard
        class="sidebar-card"
        full
      >
        <div class="pt-300 pb-100">
          <BTooltip
            top
            :content="$t('general.signOut')"
          >
            <BCustomIcon
              class="logut-button"
              icon-class="b-logout"
              hover
              @click="confirmSignOut"
            />
          </BTooltip>
          <div class="mx-400 pr-300">
            <div class="fw-bold truncate-text">
              {{ currentUser.clientName }}
            </div>
            <div class="user-name truncate-text">
              {{ currentUser.name }}
            </div>
            <div class="user-email truncate-text">
              {{ currentUser.email }}
            </div>
          </div>
          <BDivider class="divider-margin" />
          <a
            class="sc-external-transmission-link"
            target="_blank"
            rel="noopener noreferrer"
            href="https://smartcamp.co.jp/external_transmission_tool"
          >{{ $t('org.externalTransmissionMessage') }}</a>
          <a
            class="sc-term-of-use"
            target="_blank"
            rel="noopener noreferrer"
            href="https://help.balescloud.jp/ja/articles/8529255-%E5%88%A9%E7%94%A8%E8%A6%8F%E7%B4%84%E5%A4%89%E6%9B%B4%E3%81%AE%E3%81%8A%E7%9F%A5%E3%82%89%E3%81%9B-%E6%94%B9%E5%AE%9A%E6%97%A52023%E5%B9%B411%E6%9C%881%E6%97%A5"
          >{{ $t('org.NoticeOfRevisionOfTermsOfUse') }}</a>
          <GoogleNotificationMessage
            @update:setting-error="handleUpdateSettingError"
            @click:setting="handleClickSetting"
          />
          <SfNotificationMessage
            @update:setting-error="handleUpdateSettingError"
            @click:setting="handleClickSetting"
          />
        </div>
      </BCard>
    </BMenu>
  </div>
</template>

<script>
import { mapWaitingActions } from 'vue-wait';
import { mapGetters } from 'vuex';
import GoogleNotificationMessage from '@/components/organisms/user/GoogleNotificationMessage.vue';
import SfNotificationMessage from '@/components/organisms/user/SfNotificationMessage.vue';
import SalesforceConst from '@/const/salesforce';
import BDivider from '@/plugins/biscuet-materials/components/atoms/BDivider.vue';
import UserService from '@/services/user_service';

export default {
  name: 'Settings',
  components: {
    BDivider,
    SfNotificationMessage,
    GoogleNotificationMessage,
  },
  data() {
    return {
      isMenuVisible: false,
      isSettingErrorVisible: false,
    };
  },
  computed: {
    ...mapGetters('user', [
      'currentUser',
    ]),
  },
  created () {
    this.userService = new UserService();
  },
  methods: {
    ...mapWaitingActions('user', {
      getCurrentUserAction: 'getCurrentUserWait',
    }),
    signOut () {
      this.userService.signout().then(() => {
        this.$router.push({ name: 'UserSignin' });
      });
    },
    async confirmSignOut () {
      const check = await this.$bitterAlert.show({
        title: this.$t('general.signOut'),
        text: this.$t('general.confirm.of', { action: this.$t('general.signOut') }),
      });
      if (!check) { return; }
      this.signOut();
    },
    showModal (modal) {
      this.isMenuVisible = false;
      this.$modal.show(modal);
    },
    handleClickSetting () {
      this.isMenuVisible = false;
    },
    handleUpdateSettingError(visible) {
      this.isSettingErrorVisible = visible;
    },
  },
};
</script>

<style scoped lang="scss">
  .sidebar-card {
    width: 320px;
  }

  .logut-button {
    position: absolute;
    top: $basespace-200;
    right: $basespace-200;
  }

  .user-name {
    margin-top: 9px;
    font-size: 12px;
  }

  .user-email {
    color: $textcolor-light;
    margin-top: 3px;
    font-size: 11px;
  }

  .sidebar-profile {
    display: flex;
    transition: $transition-base;
    margin-right: 8px;
    padding: 2px 20px;
    cursor: pointer;

    &:hover {
      background-color: $bgcolor-dark;
    }

    &:has(.setting-error) {
      color: $basecolor-error;
      background: rgba($basecolor-error, 0.1);
      border-radius: 4px;
    }

    .setting-error {
      margin-top: 9px;
      font-size: 12px;
    }
  }

  .sidebar-content {
    display: flex;
    position: relative;
    align-items: center;
  }

  .divider-margin {
    margin-top: 10px;
  }

  .sc-external-transmission-link {
    padding: 10px 0px 1px 18px;
    text-decoration: none;
    font-size: 12px;
    display: block;
    color: var(--el-text-color-regular);
  }

   .sc-term-of-use {
    padding: 10px 0px 1px 18px;
    text-decoration: none;
    font-size: 12px;
    display: block;
    color: var(--el-text-color-regular);
  }
</style>
