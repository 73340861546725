<template>
  <OAuthCallback
    :service-name="$t('oauthCallback.service.zoom')"
    wait-loader="createZoomSettingWait"
    :success="success"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ZoomSettingApiService } from '@/api/user/resources/zoom_setting';
import { useQueryParameter } from '@/composable/query-parameter';
import { unconnectAllExcept } from '@/services/cti_service';
import OAuthCallback from './OAuthCallback.vue';

export default defineComponent({
  components: {
    OAuthCallback,
  },
  setup() {
    const { param: code } = useQueryParameter<string>('code');

    return { code };
  },
  data() {
    return {
      loading: true,
      success: true,
    };
  },
  created() {
    this.handleCreateSetting(this.code);
  },
  methods: {
    async handleCreateSetting(code: string): Promise<void> {
      this.$wait.start('createZoomSettingWait');
      const apiService = new ZoomSettingApiService();
      try {
        const { data } = await apiService.createZoomSetting({ 
          request: {
            zoomSettingBody: {
              code,
              origin: location.host,
            },
          }, 
        });
        this.success = data;
        if (!this.success) {
          throw new Error('Zoom connection failed');
        }

        await unconnectAllExcept('zoom');
      } catch (error) {
        console.error(error);
      } finally {
        this.$wait.end('createZoomSettingWait');
      }
    },
  },
});
</script>

