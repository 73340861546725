<template>
  <BDrawerMenu
    v-model="isShow"
  >
    <template #header>
      <BLayout
        class="menu-header"
        align-center
        justify-space-between
      >
        <div class="header-title">
          {{ title }}
        </div>
        <BBtn
          size="small"
          type="primary"
          outline
          :loading="loading"
          @click="handleClickSave"
        >
          <span>{{ $t('general.save.text') }}</span>
          <BIcon>keyboard_arrow_right</BIcon>
        </BBtn>
      </BLayout>
    </template>
    <div v-loading="loading">
      <BListItem class="mb-300">
        <template #header>
          <span>{{ $t('quickText.name') }}</span>
        </template>
        <BInput
          :model-value="name"
          readonly
        />
      </BListItem>
      <BListItem class="mb-300">
        <template #header>
          <span>{{ $t('quickText.content') }}</span>
        </template>
        <BInput
          :model-value="content"
          type="textarea"
          :rows="16"
          readonly
        />
      </BListItem>
    </div>
    <template #footer>
      <BLayout
        class="py-300"
        row
        align-center
        justify-center
      >
        <BBtn
          outline
          :loading="loading"
          @click="handleClickEdit"
        >
          <BIcon>keyboard_arrow_left</BIcon>
          <span>{{ $t('general.edit.moveTo') }}</span>
        </BBtn>
      </BLayout>
    </template>
  </BDrawerMenu>
</template>

<script lang="ts" setup>
type TProps = {
  title: string;
  loading: boolean;
  name: string;
  content: string;
};
type TEmit = {
  'click:publish': [];
  'click:edit': [];
};

defineProps<TProps>();
const emit = defineEmits<TEmit>();

const isShow = defineModel<boolean>('isShow', { default: false });

const handleClickSave = async () => {
  emit('click:publish');
};
const handleClickEdit = async () => {
  emit('click:edit');
};
</script>

