<template>
  <FilterItem
    ref="popover"
    v-model:filter-type="filterType"
    :title="title"
    :readonly="readonly"
    :filter-item-key="filterItemKey"
    :display-value="appliedDisplayValue"
    :filter-param="filterParam"
    @search="handleSearch"
    @cancel="handleCancel"
  >
    <div class="multiple-select-container">
      <FilterItemCheckbox
        v-model:checked-item="selectedItems"
        :candidate-items="options"
        no-blank-item
        @update:display-value="handleDisplayValueUpdate"
      />
    </div>
  </FilterItem>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import type { TFilterParam as TFilterParamOrg } from '@/composable/user/leadList/lead-filter';
import { useFilterParam } from '@/composable/user/leadList/lead-filter';
import FilterItem from '../FilterItem.vue';
import FilterItemCheckbox from '../FilterItemCheckbox.vue';
import type { TCandidates } from '../FilterItemCheckbox.vue';

export type TFilterValue = string[] | number[];
export type TFilterParam = TFilterParamOrg<TFilterValue>;
export type TPropsCommon = {
  title: string;
  options: TCandidates;
};
  
type TProps = TPropsCommon & {
  filterItemKey: string;
  readonly?: boolean;
  filterParam: TFilterParam;
};
type TEmit = {
  hide: [TFilterParam];
};

const props = defineProps<TProps>();
const emit = defineEmits<TEmit>();

const selectedItems = ref<TFilterValue>([]);
const displayValue = ref<string>('');
const appliedDisplayValue = ref<string>('');

const { filterType, filterValue, makeFilterParam } = useFilterParam<TFilterValue>(() => props.filterParam);

const setSelectedItems = (newVal: TFilterValue) => {
  selectedItems.value = newVal ?? [];
};
watch(filterValue, (newVal) => {
  setSelectedItems(newVal);
}, { immediate: true });

const handleDisplayValueUpdate = (newVal: string) => {
  // NOTE: まだ選択肢が処理されていない時は無視
  if (newVal === '') return;

  if (appliedDisplayValue.value === '') appliedDisplayValue.value = newVal;
  displayValue.value = newVal;
};

const handleSearch = () => {
  emit('hide', makeFilterParam(selectedItems.value));
  appliedDisplayValue.value = displayValue.value;
};
const handleCancel = () => {
  // NOTE: キャンセル時は選択していた値を復元
  setSelectedItems(filterValue.value);
};
</script>
