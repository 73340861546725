<template>
  <LeadHistoryCard v-bind="attributes">
    <template #date>
      <div>{{ historiedAt }}</div>
    </template>
    <BError 
      v-if="isFail"
      :error-title="failedTitle"
      :error-info="failedReason"
    />
  </LeadHistoryCard>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import LeadHistoryCard from '@/components/organisms/user/lead/history/LeadHistoryCard.vue';
import { SequenceStepInstanceHistory } from '@/store/types';
import { formatShorterDateTime } from '@/utils/date-time';

type TProps = {
  sequenceStepInstanceHistory: SequenceStepInstanceHistory;
};

const props = withDefaults(defineProps<TProps>(), {});
const i18n = useI18n();

const attributes = computed(() => ({
  text: title(),
  // TODO: user が必要？システムユーザーとして表示？
  // user: props.sequenceInstanceHistory.user,
  isDisplaySystemUser: true,
  iconComponent: 'BCustomIcon',
  customIconClass: 'b-sequence',
  iconType: 'hot',
  toggle: isFail.value,
}));
const historiedAt = computed<string>(() => formatShorterDateTime(props.sequenceStepInstanceHistory.historiedAt));
const title = () => {
  return `${props.sequenceStepInstanceHistory.actionTypeText} - ${props.sequenceStepInstanceHistory?.name} - ${props.sequenceStepInstanceHistory?.sortOrder + 1}`;
};
const isFail = computed<boolean>(() => props.sequenceStepInstanceHistory.status === 'fail');

const failedTitle = computed(() => {
  if (!isFail.value) return '';
  if (props.sequenceStepInstanceHistory.failedReason === 'systemError') {
    return i18n.t('sequence.step.failedTitle.error');
  } else {
    return i18n.t('sequence.step.failedTitle.sentError');
  }
});
const failedReason = computed(() => {
  if (!isFail.value) return '';
  return i18n.t(`sequence.step.failedReason.${props.sequenceStepInstanceHistory.failedReason}`);
});
</script>

<style lang="scss" scoped>
:deep(.lead-history-expand-indicator) {
  height: 0;
}
</style>
