<template>
  <CallButton
    :tooltip-message="tooltipMessage"
    :disabled="callDisabled"
    @click="handleCall"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { makeCall } from '@/services/zoom_phone_service';
import CallButton from './CallButton.vue';

export default defineComponent({
  components: {
    CallButton,
  },
  props: {
    phoneNumber: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('zoomPhone', [
      'isCallable',
      'isCalling',
    ]),
    ...mapGetters('userUi', [
      'zoomPhoneWidgetVisible',
    ]),
    tooltipMessage () {
      if (!this.isValidPhoneNumber) {
        return this.$t('cti.invalidPhoneNumber');
      } else if (this.isCalling) {
        return this.$t('zoomPhone.busy');
      } else if (this.callDisabled) {
        return this.$t('zoomPhone.notStarted');
      } else {
        return this.$t('zoomPhone.call');
      }
    },
    isValidPhoneNumber(): boolean {
      return typeof this.phoneNumber === 'string' && this.phoneNumber.length > 0;
    },
    callDisabled(): boolean {
      if (this.disabled) return true;
      return !this.isValidPhoneNumber || !this.zoomPhoneWidgetVisible || !this.isCallable;
    },
  },
  methods: {
    handleCall(): void {
      if (this.callDisabled) return;

      makeCall(this.phoneNumber);
    },
  },
});
</script>
