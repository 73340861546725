<template>
  <div
    class="b-input-box"
    :class="classes"
  >
    <BInput
      v-if="inputComponent === 'BInput'"
      v-model="internalValue"
      v-model:valid="internalValid"
      class="input-area"
      :placeholder="placeholder"
      :rules="mergedRules"
      :disabled="loading"
      :max-length="maxLength"
      :data-test="dataTest"
      :type="type"
      @keypress-enter="handleCheck"
      @blur="handleBlur"
    />
    <BNumberInput
      v-if="inputComponent === 'BNumberInput'"
      v-model="internalValue"
      class="number-input"
      :placeholder="$t('customField.number.validate')"
      :disabled="loading"
      :allow-float="allowFloat"
      @keypress-enter="handleCheck"
      @blur="handleBlur"
    />
    <div
      v-if="isSettleByCheck || closeable"
      class="btn-area"
    >
      <BLayout align-center>
        <template v-if="isSettleByCheck">
          <BBtn
            v-if="valueChanged"
            class="mr-50"
            size="small"
            fab
            flat
            :disabled="!internalValid"
            :loading="loading"
            :data-test="`${dataTest}Btn`"
            @click="handleCheck"
          >
            <BIcon
              class="check-icon"
              size="small"
            >
              check
            </BIcon>
          </BBtn>
        </template>
        <BBtn
          v-if="closeable"
          size="small"
          fab
          flat
          :disabled="loading"
          @click="handleClose"
        >
          <BIcon
            class="close-icon"
            size="small"
          >
            close
          </BIcon>
        </BBtn>
      </BLayout>
    </div>
  </div>
</template>

<script>
import inputValidation from '@/mixins/input_validation';

export default {
  mixins: [inputValidation],
  props: {
    modelValue: [String, Number],
    placeholder: String,
    vert: Boolean,
    horiz: Boolean,
    required: Boolean,
    loading: Boolean,
    rules: {
      type: Array,
      default () {
        return [];
      },
    },
    maxLength: {
      type: Number,
      default: 255,
    },
    dataTest: String,
    type: {
      type: String,
      default: 'text',
    },
    inputComponent: {
      type: String,
      default: 'BInput',
    },
    allowFloat: {
      type: Boolean,
      default: false,
    },
    settleType: {
      type: String, // 'check' or 'blur'
      default: 'check',
    },
    closeable: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    'update:modelValue',
    'update:valid',
    'check',
    'close',
  ],
  data () {
    return {
      valid: false,
      valueChanged: false,
    };
  },
  computed: {
    classes () {
      return {
        'b-input-box-horiz': this.horiz,
        'b-input-box-vert': this.vert,
      };
    },
    internalValue: {
      get () {
        return this.modelValue;
      },
      set (newVal) {
        if (this.modelValue !== newVal) {
          this.valueChanged = true;
          this.$emit('update:modelValue', newVal);
        }
      },
    },
    internalValid: {
      get() {
        return this.valid;
      },
      set(newVal) {
        this.valid = newVal;
        this.$emit('update:valid', newVal);
      },
    },
    mergedRules () {
      const array = [];
      if (this.required) array.push(this.requiredRule);
      return array.concat(this.rules);
    },
    isSettleByCheck() {
      return this.settleType === 'check';
    },
    isSettleByBlur() {
      return this.settleType === 'blur';
    },
  },
  created () {
    this.internalValid = this.inputComponent === 'BNumberInput';  // BNumberInputは内部でバリデーションを持っているため、初期値でtrueにしてしまう
  },
  methods: {
    handleCheck() {
      this.$emit('check', this.internalValue);
    },
    handleClose() {
      this.$emit('close');
    },
    handleBlur() {
      if (!this.isSettleByBlur) return;
      this.handleCheck();
    },
  },
};
</script>

<style lang="scss" scoped>
  .b-input-box-vert {
    .input-area {
      margin-bottom: $basespace-200;
    }

    .btn-area {
      display: flex;
      justify-content: flex-end
    }
  }

  .b-input-box-horiz {
    display: flex;
    align-items: center;

    .btn-area {
      margin-left: $basespace-200;
    }
  }

  .check-icon {
    color: $basecolor-primary;
  }

  .close-icon {
    color: $textcolor-light;
  }

  .number-input {
    width: 100%;
    height: 40px;
    line-height: 40px;
    margin-right: 12px;
    &::placeholder {
      color: $concrete-dark;
    }
  }
</style>
