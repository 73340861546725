<template>
  <div class="pop-up-box">
    <BBtn
      text
      class="template-button"
      @click="onTemplateButtonClick"
    >
      <BCustomIcon
        icon-class="b-template"
        type="accent"
      />
      <span class="ml-50 fw-bold">{{ $t('mail.applyMailTemplate') }}</span>
    </BBtn>
    <BBtn
      v-if="isAvailableAiFeatureToBeReleased"
      text
      class="openai-button"
      @click="handleOpenAiModalShow"
    >
      <BIcon
        type="success"
        class="openai-icon"
        size="large"
      >
        psychology
      </BIcon>
      <span class="fw-bold">{{ $t('openaiGenarate.mailGenerate') }}</span>
    </BBtn>
  </div>
  <BListItem
    v-if="senderData != null"
    class="mb-600"
  >
    <template #header>
      <span>{{ $t('mail.sender') }}</span>
    </template>
    <div class="sender-group">
      <BUnchangedItem
        class="name"
        :content="senderData.senderName"
        :help-content="senderNameDescription"
      />
      <BUnchangedItem
        class="address"
        :content="senderData.senderEmail"
        :help-content="senderFromAddressDescription"
      />
    </div>
  </BListItem>
  <MailAddressList
    v-model="sendCc"
    :label="$t('mail.cc')"
  />
  <MailAddressList
    v-model="sendBcc"
    :label="$t('mail.bcc')"
  />
  <MailContentEditor
    v-loading="$wait.is(['generateContentByOpenSearchActionWait', 'generateReplyContentByOpenSearch'])"
    class="mb-600"
    :subject="subject"
    :content="content"
    :mail-attachment-ids="mailAttachmentIds"
    :enable-attachment="true"
    :unsubscribe-visible="false"
    :is-reply="isReply"
    @edit-subject="subject = $event"
    @edit-content="content = $event"
    @edit-mail-attachment-ids="mailAttachmentIds = $event"
  />
  <!-- start of custom items -->
  <slot />
  <!-- end of custom items -->
  <BListItem>
    <template #header>
      <span>
        <div class="header-group">
          <span class="text mr-100">{{ $t('mailNotificationEvent.formTitle') }}</span>
          <BTooltip top>
            <BIcon size="small">help</BIcon>
            <template #content>
              <span>{{ $t('mailNotificationEvent.description') }}</span>
            </template>
          </BTooltip>
        </div>
      </span>
    </template>
    <BCheckbox
      v-model="notificationSettingOpen"
      class="ml-100 mt-100"
      :label="$t('mailNotificationEvent.open')"
    />
    <BCheckbox
      v-model="notificationSettingClick"
      class="ml-100 mt-100"
      :label="$t('mailNotificationEvent.click')"
    />
  </BListItem>
  <slot name="sfSync" />
  <!-- modals -->
  <MailTemplateSelectModal
    v-if="isAvailableMailTemplateFeature"
    height="85%"
    @click="applyMailTemplate"
  />
  <OpenaiContentGenerate
    ref="openai"
    :set-content="setOpenAiContent"
    @close-modal="handleOpenAiCloseModal"
  />
</template>

<script lang="ts" setup>
import { computed, getCurrentInstance, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import MailTemplateSelectModal from '@/components/organisms/user/general/modal/MailTemplateSelectModal.vue';
import OpenaiContentGenerate from '@/components/organisms/user/mail/bulk/drawer/state/form/OpenaiContentGenerate.vue';
import MailContentEditor from '@/components/organisms/user/mail/common/drawer/state/form/MailContentEditor.vue';
import { makeQuillContent } from '@/components/organisms/user/mail/common/drawer/state/form/QuillContentEditor.vue';
import { useAvailableFeatureCheck } from '@/composable/available-feature-check';
import { bitterAlert } from '@/plugins/BBitterAlert';
import { TPartialFormDirectMailCommon, SenderData } from '../types';
import MailAddressList from './MailAddressList.vue';

type TProps = {
  modelValue: TPartialFormDirectMailCommon;
  isAvailableMailTemplateFeature: boolean;
  senderData: SenderData | null;
  sendToAddress?: string | null;
};
type TEmit = {
  'update:modelValue': [value: TPartialFormDirectMailCommon];
  'update:isValid': [valid: boolean];
  'closeOpenai': [cancel: () => void];
};

const props = defineProps<TProps>();
const emit = defineEmits<TEmit>();

const i18n = useI18n();

const app = getCurrentInstance();
const $modal = app.appContext.config.globalProperties.$modal;

const senderNameDescription = i18n.t('mail.senderNameDescription');
const senderFromAddressDescription = i18n.t('mail.senderAddressDescription');
const { isAvailableAiFeatureToBeReleased } = useAvailableFeatureCheck();

const isReply = computed(() =>
  props.modelValue.replyToReceiveMailId != null,
);

const subject = computed({
  get: () => props.modelValue.subject,
  set: (v) => updateFormValues('subject', v),
});
const content = computed({
  get: () => props.modelValue.content,
  set: (v) => updateFormValues('content', v),
});
const mailAttachmentIds = computed({
  get: () => props.modelValue.mailAttachmentIds,
  set: (v) => updateFormValues('mailAttachmentIds', v),
});
const notificationSettingClick = computed({
  get: () => props.modelValue.click,
  set: (v) => updateFormValues('click', v),
});
const notificationSettingOpen = computed({
  get: () => props.modelValue.open,
  set: (v) => updateFormValues('open', v),
});
const sendCc = computed({
  get: () => props.modelValue.sendCc,
  set: (v) => updateFormValues('sendCc', v),
});
const sendBcc = computed({
  get: () => props.modelValue.sendBcc,
  set: (v) => updateFormValues('sendBcc', v),
});
const duplicateMailAddress = computed(() => {
  const mails = sendCc.value.concat(sendBcc.value).map(person => person.email);
  if (props.sendToAddress) mails.push(props.sendToAddress);
  const uniqEmails = new Set(mails);
  return uniqEmails.size !== mails.length;
});

watch(duplicateMailAddress, (newVal) => {
  if (newVal) {
    bitterAlert.show({
      title: i18n.t('general.error'),
      text: i18n.t('mail.sameAddressError'),
      closeOnClickOutside: true,
      buttonsCancel: false,
    });
  }
});

const isValid = computed(() =>
  props.modelValue.subject.length > 0
  && props.modelValue.content.length > 0
  && !duplicateMailAddress.value,
);

watch(isValid, (newVal) => {
  emit('update:isValid', newVal);
}, { immediate: true });

const updateFormValues = (key: keyof TPartialFormDirectMailCommon, value: unknown) => {
  emit('update:modelValue', {
    ...props.modelValue,
    [key]: value,
  });
};
const applyMailTemplate = (mailTemplate: { subject: string, content: string }) => {
  const subject = isReply.value ? props.modelValue.subject : mailTemplate.subject;
  emit('update:modelValue', {
    ...props.modelValue,
    subject,
    content: mailTemplate.content,
  });
  $modal.hide('MailTemplateSelectModal');
};
const setOpenAiContent = (data: string) => {
  updateFormValues('content', makeQuillContent(data));
};

const onTemplateButtonClick = () => {
  if (!props.isAvailableMailTemplateFeature) {
    bitterAlert.show({
      title: i18n.t('general.error'),
      text: i18n.t('mail.mailTemplateIsNotAvailable'),
      closeOnClickOutside: true,
      buttonsCancel: false,
    });
    return;
  }
  $modal.show('MailTemplateSelectModal');
};
const handleOpenAiModalShow = () => {
  $modal.show('openai-modal');
};
const handleOpenAiCloseModal = (cancel: () => void) => {
  emit('closeOpenai', cancel);
};
</script>

<style lang="scss" scoped>
.sender-group {
  display: flex;
  margin-top: 16px;
  .name {
    flex: 1.2;
    margin-right: 10px;
  }
  .address {
    flex: 2;
    margin-top: 4px;
  }
}
.pop-up-box {
  border-bottom: 1px solid $bdcolor-base;
  margin: -20px -20px 16px -20px;
  padding: 4px 20px;
  .template-button {
    color: $basecolor-accent
  }
  .openai-button {
    color: $basecolor-accent;
    margin-left: 15px;
  }
}
.openai-icon {
  transform: scale(-1, 1);
  color: $basecolor-success;
}
</style>
