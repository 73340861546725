import { inject } from 'vue';
import { KEY_OF_WAIT } from '@/injection-keys';

/**
 * NOTE: vue-waitをComposition APIに対応させるための暫定共通化処理です。
 * 公式が対応したらそちらに実装を変更する
 * or ずっと対応されない場合、代替moduleを探してください
 */
const useWait = () => {
  const wait = inject(KEY_OF_WAIT);

  const doActionWithWait = async <T = void>(keyOfWait: string, action: () => Promise<T>): Promise<T> => {
    wait.start(keyOfWait);
    try {
      return await action();
    } finally {
      wait.end(keyOfWait);
    }
  };

  return {
    wait,
    doActionWithWait,
  };
};

export {
  useWait,
};
