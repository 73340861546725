<template>
  <LeadHistoryCard v-bind="attributes">
    <template #date>
      <div>{{ historiedAt }}</div>
    </template>
    <BListItem class="mb-400">
      <template #header>
        <div>{{ $t('mailEvent.unsubscribedEmail') }}</div>
      </template>
      <div>{{ historiedEmail }}</div>
    </BListItem>
  </LeadHistoryCard>
</template>

<script>
import LeadHistoryCard from '@/components/organisms/user/lead/history/LeadHistoryCard.vue';
import { formatShorterDateTime } from '@/utils/date-time';

export default {
  name: 'UnsubscribedCard',
  components: {
    LeadHistoryCard,
  },
  props: {
    unsubscribeItem: Object,
    expand: Boolean,
  },
  computed: {
    attributes() {
      return {
        'text': this.unsubscribeItem.title,
        'icon': this.icon.fileName,
        'icon-type': this.icon.type,
        'expand': this.expand,
        'eventEmotion': this.icon.eventEmotion,
        'isEvent': true,
      };
    },
    icon() {
      return {
        fileName: 'unsubscribe',
        type: 'cold',
        eventEmotion: 'negative',
      };
    },
    historiedEmail () {
      return this.unsubscribeItem?.email;
    },
    historiedAt() {
      return formatShorterDateTime(this.unsubscribeItem.historiedAt);
    },
  },
};
</script>
