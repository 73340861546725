/* tslint:disable */
/* eslint-disable */
/**
 * openapi
 * bales cloud
 *
 * The version of the OpenAPI document: 1.0
 * Contact: test@example.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * シーケンス実行設定更新リクエストボディ
 * @export
 * @interface SequenceExecutionSetting
 */
export interface SequenceExecutionSetting {
    /**
     * シーケンス実行タイプ
     * @type {string}
     * @memberof SequenceExecutionSetting
     */
    'sequenceExecutionType': SequenceExecutionSettingSequenceExecutionTypeEnum;
}

export const SequenceExecutionSettingSequenceExecutionTypeEnum = {
    OnlyOnWeekdays: 'only_on_weekdays',
    Everyday: 'everyday'
} as const;

export type SequenceExecutionSettingSequenceExecutionTypeEnum = typeof SequenceExecutionSettingSequenceExecutionTypeEnum[keyof typeof SequenceExecutionSettingSequenceExecutionTypeEnum];


