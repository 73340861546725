<template>
  <BContent
    v-loading="loading"
  >
    <template #navbar>
      <BLayout
        align-start
        justify-start
      >
        <span class="fs-400 fw-bold">{{ $t('sequence.title') }}</span>
      </BLayout>
    </template>
    <template #footer>
      <SequenceFooter
        :can-save="canSave"
        :loading="loading"
        :need-confirmation-to-leave="needConfirmationToLeave"
        @click:save="handleClickSave"
      />
    </template>
    <template #waiting>
      <BPlaceholder
        v-loading="true"
        element-loading-background="transparent"
      />
    </template>
    <SequenceMaster
      :sequence-master="sequenceMaster"
      :wait-for="WAIT_FETCH_SEQUENCE_MASTER"
      @update="handleUpdateSequence"
      @update:changed="handleUpdateSequenceChanged"
    />
    <BTabs
      v-model="activeName"
      class="tabs"
      :label-items="tabLabelItems"
      :before-leave="handleTabBeforeLeave"
      @click:tab="handleTabClick"
    >
      <template #step>
        <SequenceSteps
          v-if="renderComponents && !waitingForSequenceMaster"
          :sequence-master-id="sequenceMasterId"
          :sequence-owner-id="sequenceMaster?.owner?.id"
          editable
          @update="handleUpdateSequenceSteps"
          @update:changed="handleUpdateSequenceStepsChanged"
        />
      </template>
    </BTabs>
  </BContent>
</template>

<script lang="ts" setup>
import { computed, nextTick, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { SequenceMasterApiService } from '@/api/user/resources/sequence_master';
import SequenceFooter from '@/components/organisms/user/general/sequence/SequenceFooter.vue';
import SequenceMaster from '@/components/organisms/user/sequence/Sequence.vue';
import SequenceSteps from '@/components/organisms/user/sequence/SequenceSteps.vue';
import { useUrlLinkBtabsHandlers } from '@/composable/url-link-b-tabs';
import { useChanged } from '@/composable/user/sequence/changed';
import { usePostPutBody } from '@/composable/user/sequence/post-put-body';
import { useSequenceMaster } from '@/composable/user/sequence/sequence-master';
import { useWait } from '@/composable/vue-wait';

const WAIT_FETCH_SEQUENCE_MASTER = 'fetchSequenceMaster';
const route = useRoute();
const sequenceMasterId = parseInt(route.params.id as string);

const waitingForSequenceMaster = computed(() => sequenceMaster.value == null);

const activeName = ref('step');
const loading = computed(() => wait.is([WAIT_FETCH_SEQUENCE_MASTER, 'fetchSequenceStepMasters', 'putSequenceMaster']));

const api = new SequenceMasterApiService();
const i18n = useI18n();
const {
  wait,
  doActionWithWait,
} = useWait();

const tabLabelItems = [
  { label: i18n.t('sequence.step.title'), slotName: 'step' },
  { label: i18n.t('sequence.lead'), slotName: 'lead', route: { name: 'SequenceLeads', params: { id: sequenceMasterId } } },
];

const {
  sequenceBodyData,
  sequenceStepsBodyData,
  canSave,
  handleUpdateSequence,
  handleUpdateSequenceSteps,
} = usePostPutBody();
const {
  handleUpdateSequenceChanged,
  handleUpdateSequenceStepsChanged,
  needConfirmationToLeave,
} = useChanged();
const {
  sequenceMaster,
  fetchSequenceMaster,
} = useSequenceMaster(sequenceMasterId);
const { handleTabClick, handleTabBeforeLeave } = useUrlLinkBtabsHandlers(tabLabelItems, needConfirmationToLeave);

onMounted(async () => {
  await fetchSequenceMaster(WAIT_FETCH_SEQUENCE_MASTER);
});

const renderComponents = ref(true);
const reRenderComponents = async () => {
  renderComponents.value = false;
  await nextTick();
  renderComponents.value = true;
};

const handleClickSave = async () => {
  if (!canSave.value) return;

  await doActionWithWait('putSequenceMaster', async () => {
    await api.putSequenceMaster({
      request: {
        sequenceMasterId,
        postSequenceMasterBody: {
          sequence: sequenceBodyData.value,
          sequenceSteps: sequenceStepsBodyData.value,
        },
      },
    });
  });

  // NOTE: fetchSequenceMasterの後に実行するとステップのComponentが正しく再描画されないため、先に実行（nextTickがうまく動かなくなるものと思われる）
  reRenderComponents();
  fetchSequenceMaster(WAIT_FETCH_SEQUENCE_MASTER);
};
</script>

<style lang="scss" scoped>
.tabs {
  // NOTE: シーケンスマスタ、フッター分引く
  height: calc(100% - 50px - 48px);
}

:deep(.el-tabs__header) {
  margin-bottom: 0;

  .el-tabs__nav-scroll {
    padding: 0 $basespace-600;
  }
}

:deep(.el-tabs__content) {
  // NOTE: header分引く
  height: calc(100% - 40px);

  .el-tab-pane {
    height: 100%;
  }
}
</style>
