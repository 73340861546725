import ApiBase from '@/api/base';

export default {
  createFreeAction (args) {
    const { callTargetId } = args;
    return ApiBase.post(`/user/call_targets/${callTargetId}/free_actions`, args);
  },
  updateFreeAction (args) {
    const { freeActionId } = args;
    return ApiBase.put(`/user/free_actions/${freeActionId}`, args);
  },
  deleteFreeAction (args) {
    const { freeActionId } = args;
    return ApiBase.delete(`/user/free_actions/${freeActionId}`, args);
  },
};
