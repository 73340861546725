<template>
  <div class="draft-reminder-timeline-container">
    <BEmptyBox
      v-if="!existItems"
      class="empty"
      display-only
    >
      {{ $t('draftMailReminder.empty') }}
    </BEmptyBox>
    <DraftMailReminderItem
      v-for="timelineItems in items"
      :key="timelineItems.directMailId"
      :item="timelineItems"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { MailRemindItem } from '@/api/openapi';

type TProps = {
  items: MailRemindItem[];
}

const props = defineProps<TProps>();

const existItems = computed(() => {
  return props.items.length > 0;
});
</script>

<style lang="scss" scoped>
.draft-reminder-timeline-container {
  width: 100%;
  min-width: max-content;
}
.empty {
  color: $textcolor-light;
  padding: $basespace-600;
  margin: $basespace-600;
  height: 90px;
  font-size: 12px;
}
</style>
