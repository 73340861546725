<template>
  <span>
    <BTooltip top>
      <BBtn
        :size="btnSize"
        fab
        @click="openLinkAnotherTab"
      >
        <BIcon
          :size="iconSize"
        >open_in_new</BIcon>
      </BBtn>
      <template #content>
        <span>{{ $t('callTarget.search.url') }}</span>
      </template>
    </BTooltip>
  </span>
</template>

<script>
export default {
  name: 'BLinkBtn',
  props: {
    value: {
      type: String,
      required: true,
    },
    btnSize: {
      type: String,
      default: 'mini',
    },
    iconSize: {
      type: String,
      default: 'small',
    },
  },
  methods: {
    openLinkAnotherTab (e) {
      e.stopPropagation();
      window.open(this.value, '_blank');
    },
  },
};
</script>
