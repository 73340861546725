<template>
  <div>
    <FormDrawer
      v-model="triggerAction"
      v-model:is-open="isOpenForm"
      @click:cancel="handleCancelClick"
    />
    <ConfirmDrawer
      v-model="triggerAction"
      v-model:is-open="isOpenConfirm"
      :editable="editable"
      @click:cancel="handleCancelClick"
      @click:trigger-action="handleEditClick"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import ConfirmDrawer from '@/components/organisms/user/trigger/triggerActionForm/ConfirmDrawer.vue';
import FormDrawer from '@/components/organisms/user/trigger/triggerActionForm/FormDrawer.vue';
import { TTriggerActionFormMode, TriggerActionBodyExt } from '../types';

type TProps = {
  editable: boolean;
};
type TEmits = {
  'click:cancel': [];
  'click:triggerAction': [triggerAction: TriggerActionBodyExt, menu: TTriggerActionFormMode];
};

defineProps<TProps>();
const emit = defineEmits<TEmits>();
const triggerAction = defineModel<TriggerActionBodyExt | null>();
const isOpen = defineModel<boolean>('isOpen', { default: false });
const mode = defineModel<TTriggerActionFormMode>('mode');

const isOpenForm = computed(() => {
  return mode.value === 'form' && isOpen.value;
});

const isOpenConfirm = computed(() => {
  return mode.value === 'confirm' && isOpen.value;
});

const handleCancelClick = () => {
  emit('click:cancel');
};

const handleEditClick = () => {
  emit('click:triggerAction', triggerAction.value, 'form');
};
</script>

<style lang="scss" scoped>
:deep(.trigger-action-form-container) {
  &.b-drawer-menu {
    &.open {
      left: -600px !important;
    }
  }

  .trigger-action-form-body-container {
    padding: 24px 20px;

    .list-group {
      padding: 14px;
      margin-bottom: 20px;
      border: 1px solid $bdcolor-base;
      border-radius: 4px;

      .list-item {
        margin-bottom: 0;
      }
    }

    .action-detail-container {
      &.form {
        padding: 20px;
        background-color: $bgcolor-base;
      }
      &.confirm {
        .list-item {
          padding: 8px 12px;
          margin-bottom: 5px;
          background-color: $bgcolor-base;
        }
      }
    }
  }

  .footer {
    padding: 0 20px;
    height: 48px;
    background-color: $bgcolor-dark;
    display: flex;

    .btn {
      width: 100px;
    }
  }

  .list-item {
    margin-bottom: 20px;

    .header {
      font-size: $fontsize-100;
      color: $textcolor-light;
      margin-bottom: $basespace-50;
    }
  }
}

:deep(.select-user .type-select) {
  width: 100%;
}
</style>
