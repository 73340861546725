/* tslint:disable */
/* eslint-disable */
/**
 * openapi
 * bales cloud
 *
 * The version of the OpenAPI document: 1.0
 * Contact: test@example.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GetZoomSetting200Response } from '../models';
// @ts-ignore
import { ZoomSettingBody } from '../models';
/**
 * ZoomSettingApi - axios parameter creator
 * @export
 */
export const ZoomSettingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Zoom auth連携API
         * @summary Zoom auth連携API
         * @param {ZoomSettingBody} [zoomSettingBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createZoomSetting: async (zoomSettingBody?: ZoomSettingBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/zoom/zoom_settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(zoomSettingBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Zoom auth連携解除API
         * @summary Zoom auth連携解除API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteZoomSetting: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/zoom/zoom_setting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Zoom auth設定取得API
         * @summary Zoom auth設定取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZoomSetting: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/zoom/zoom_setting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ZoomSettingApi - functional programming interface
 * @export
 */
export const ZoomSettingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ZoomSettingApiAxiosParamCreator(configuration)
    return {
        /**
         * Zoom auth連携API
         * @summary Zoom auth連携API
         * @param {ZoomSettingBody} [zoomSettingBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createZoomSetting(zoomSettingBody?: ZoomSettingBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createZoomSetting(zoomSettingBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Zoom auth連携解除API
         * @summary Zoom auth連携解除API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteZoomSetting(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteZoomSetting(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Zoom auth設定取得API
         * @summary Zoom auth設定取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getZoomSetting(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetZoomSetting200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getZoomSetting(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ZoomSettingApi - factory interface
 * @export
 */
export const ZoomSettingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ZoomSettingApiFp(configuration)
    return {
        /**
         * Zoom auth連携API
         * @summary Zoom auth連携API
         * @param {ZoomSettingBody} [zoomSettingBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createZoomSetting(zoomSettingBody?: ZoomSettingBody, options?: any): AxiosPromise<boolean> {
            return localVarFp.createZoomSetting(zoomSettingBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Zoom auth連携解除API
         * @summary Zoom auth連携解除API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteZoomSetting(options?: any): AxiosPromise<boolean> {
            return localVarFp.deleteZoomSetting(options).then((request) => request(axios, basePath));
        },
        /**
         * Zoom auth設定取得API
         * @summary Zoom auth設定取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZoomSetting(options?: any): AxiosPromise<GetZoomSetting200Response> {
            return localVarFp.getZoomSetting(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ZoomSettingApi - interface
 * @export
 * @interface ZoomSettingApi
 */
export interface ZoomSettingApiInterface {
    /**
     * Zoom auth連携API
     * @summary Zoom auth連携API
     * @param {ZoomSettingBody} [zoomSettingBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZoomSettingApiInterface
     */
    createZoomSetting(zoomSettingBody?: ZoomSettingBody, options?: AxiosRequestConfig): AxiosPromise<boolean>;

    /**
     * Zoom auth連携解除API
     * @summary Zoom auth連携解除API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZoomSettingApiInterface
     */
    deleteZoomSetting(options?: AxiosRequestConfig): AxiosPromise<boolean>;

    /**
     * Zoom auth設定取得API
     * @summary Zoom auth設定取得API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZoomSettingApiInterface
     */
    getZoomSetting(options?: AxiosRequestConfig): AxiosPromise<GetZoomSetting200Response>;

}

/**
 * Request parameters for createZoomSetting operation in ZoomSettingApi.
 * @export
 * @interface ZoomSettingApiCreateZoomSettingRequest
 */
export interface ZoomSettingApiCreateZoomSettingRequest {
    /**
     * 
     * @type {ZoomSettingBody}
     * @memberof ZoomSettingApiCreateZoomSetting
     */
    readonly zoomSettingBody?: ZoomSettingBody
}

/**
 * ZoomSettingApi - object-oriented interface
 * @export
 * @class ZoomSettingApi
 * @extends {BaseAPI}
 */
export class ZoomSettingApi extends BaseAPI implements ZoomSettingApiInterface {
    /**
     * Zoom auth連携API
     * @summary Zoom auth連携API
     * @param {ZoomSettingApiCreateZoomSettingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZoomSettingApi
     */
    public createZoomSetting(requestParameters: ZoomSettingApiCreateZoomSettingRequest = {}, options?: AxiosRequestConfig) {
        return ZoomSettingApiFp(this.configuration).createZoomSetting(requestParameters.zoomSettingBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Zoom auth連携解除API
     * @summary Zoom auth連携解除API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZoomSettingApi
     */
    public deleteZoomSetting(options?: AxiosRequestConfig) {
        return ZoomSettingApiFp(this.configuration).deleteZoomSetting(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Zoom auth設定取得API
     * @summary Zoom auth設定取得API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZoomSettingApi
     */
    public getZoomSetting(options?: AxiosRequestConfig) {
        return ZoomSettingApiFp(this.configuration).getZoomSetting(options).then((request) => request(this.axios, this.basePath));
    }
}
