<template>
  <div class="call-result-table">
    <div class="call-result-table-title fw-bold">
      {{ $t('callAnalytics.callResultTableTitle') }}
    </div>
    <ElTable
      :data="tableData"
      show-summary
      row-class-name="table-row"
      style="width: 100%;"
      :height="600"
    >
      <ElTableColumn
        width="200"
        prop="name"
        :label="$t('callAnalytics.callResultName')"
      />
      <ElTableColumn
        width="120"
        sortable
        prop="count"
        :label="$t('callAnalytics.count')"
      />
    </ElTable>
  </div>
</template>

<script>
export default {
  name: 'CallResultTable',
  props: {
    tableData: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getSummaries (param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = 'Total Cost';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sums[index] = `$ ${values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0)}`;
        } else {
          sums[index] = 'N/A';
        }
      });

      return sums;
    },
  },
};
</script>

<style scoped lang="scss">
  .call-result-table {
    &-title {
      font-size: $fontsize-400;
      font-weight: bold;
    }
  }
  :deep(.el-table) {
    .table-row {
      background-color: #fff;
    }
  }
</style>
