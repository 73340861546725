<template>
  <LeadHistoryCard v-bind="attributes">
    <template #date>
      <div>{{ historiedAt }}</div>
    </template>
    <template #icon>
      <BIcon
        type="white"
        size="medium"
      >
        feed
      </BIcon>
    </template>
    <div
      v-for="(resultObj, key) in formResult.result"
      :key="key"
      class="form-result-card"
    >
      <BListItem class="mb-400">
        <template #header>
          <div>{{ $t('form.question') }}</div>
        </template>
        <div class="result-text break-word-pre-wrap">
          {{ resultObj.question_text }}
        </div>
      </BListItem>
      <BListItem class="mb-400">
        <template #header>
          <div>{{ $t('form.answer') }}</div>
        </template>
        <div class="result-text break-word-pre-wrap">
          {{ answer_text(resultObj.answer_text) }}
        </div>
      </BListItem>
      <div v-if="resultObj.lead_column.length > 0">
        <BLabel
          round
          color="light-blue"
        >
          <span class="result-text break-word-pre-wrap">{{ resultObj.name }}</span>
        </BLabel>
      </div>
      <div v-else>
        <BLabel
          round
          color="light-green"
          :disabled="resultObj.is_delete"
        >
          <span class="result-text break-word-pre-wrap">{{ resultObj.name }}</span>
        </BLabel>
      </div>
    </div>
  </LeadHistoryCard>
</template>

<script>
import LeadHistoryCard from '@/components/organisms/user/lead/history/LeadHistoryCard.vue';
import { formatShorterDateTime } from '@/utils/date-time';

export default {
  name: 'FormResultCard',
  components: {
    LeadHistoryCard,
  },
  props: {
    formResult: Object,
    expand: Boolean,
  },
  computed: {
    attributes() {
      return {
        'text': this.name,
        'icon-type': 'hot',
        'toggle': true,
        'expand': this.expand,
        'isEvent': true,
        'eventEmotion': 'positive',
      };
    },
    historiedAt() {
      return formatShorterDateTime(this.formResult.historiedAt);
    },
    name() {
      if (this.formResult.name) {
        return  `${this.formResult.actionTypeText}：${this.formResult.name}`;
      } else {
        return `${this.formResult.actionTypeText}：-`;
      }
    },
  },
  methods: {
    answer_text(text){
      return text ? text : '-';
    },
  },
};
</script>

<style lang="scss" scoped>
.form-result-card {
  display: grid;
  grid-template-rows: 70px auto 22px;
  grid-template-columns: 1fr;
  padding: 24px 26px;
  border: 1px solid $bdcolor-light;
  margin-bottom: 19px;

  .result-text {
    word-break: break-all
  }
}
</style>
