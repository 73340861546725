/* tslint:disable */
/* eslint-disable */
/**
 * openapi
 * bales cloud
 *
 * The version of the OpenAPI document: 1.0
 * Contact: test@example.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PutCurrentUserRequest } from '../models';
/**
 * UserProfileApi - axios parameter creator
 * @export
 */
export const UserProfileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ユーザーアバター画像更新API
         * @summary ユーザーアバター画像更新API
         * @param {any} image 画像ファイル（Blob)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCurrentUserAvatarImage: async (image: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'image' is not null or undefined
            assertParamExists('postCurrentUserAvatarImage', 'image', image)
            const localVarPath = `/api/user/update_avatar_image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザープロファイル更新API
         * @summary ユーザープロファイル更新API
         * @param {PutCurrentUserRequest} [putCurrentUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCurrentUser: async (putCurrentUserRequest?: PutCurrentUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/update_me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putCurrentUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserProfileApi - functional programming interface
 * @export
 */
export const UserProfileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserProfileApiAxiosParamCreator(configuration)
    return {
        /**
         * ユーザーアバター画像更新API
         * @summary ユーザーアバター画像更新API
         * @param {any} image 画像ファイル（Blob)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCurrentUserAvatarImage(image: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCurrentUserAvatarImage(image, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ユーザープロファイル更新API
         * @summary ユーザープロファイル更新API
         * @param {PutCurrentUserRequest} [putCurrentUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCurrentUser(putCurrentUserRequest?: PutCurrentUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCurrentUser(putCurrentUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserProfileApi - factory interface
 * @export
 */
export const UserProfileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserProfileApiFp(configuration)
    return {
        /**
         * ユーザーアバター画像更新API
         * @summary ユーザーアバター画像更新API
         * @param {any} image 画像ファイル（Blob)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCurrentUserAvatarImage(image: any, options?: any): AxiosPromise<void> {
            return localVarFp.postCurrentUserAvatarImage(image, options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザープロファイル更新API
         * @summary ユーザープロファイル更新API
         * @param {PutCurrentUserRequest} [putCurrentUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCurrentUser(putCurrentUserRequest?: PutCurrentUserRequest, options?: any): AxiosPromise<void> {
            return localVarFp.putCurrentUser(putCurrentUserRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserProfileApi - interface
 * @export
 * @interface UserProfileApi
 */
export interface UserProfileApiInterface {
    /**
     * ユーザーアバター画像更新API
     * @summary ユーザーアバター画像更新API
     * @param {any} image 画像ファイル（Blob)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApiInterface
     */
    postCurrentUserAvatarImage(image: any, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * ユーザープロファイル更新API
     * @summary ユーザープロファイル更新API
     * @param {PutCurrentUserRequest} [putCurrentUserRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApiInterface
     */
    putCurrentUser(putCurrentUserRequest?: PutCurrentUserRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * Request parameters for postCurrentUserAvatarImage operation in UserProfileApi.
 * @export
 * @interface UserProfileApiPostCurrentUserAvatarImageRequest
 */
export interface UserProfileApiPostCurrentUserAvatarImageRequest {
    /**
     * 画像ファイル（Blob)
     * @type {any}
     * @memberof UserProfileApiPostCurrentUserAvatarImage
     */
    readonly image: any
}

/**
 * Request parameters for putCurrentUser operation in UserProfileApi.
 * @export
 * @interface UserProfileApiPutCurrentUserRequest
 */
export interface UserProfileApiPutCurrentUserRequest {
    /**
     * 
     * @type {PutCurrentUserRequest}
     * @memberof UserProfileApiPutCurrentUser
     */
    readonly putCurrentUserRequest?: PutCurrentUserRequest
}

/**
 * UserProfileApi - object-oriented interface
 * @export
 * @class UserProfileApi
 * @extends {BaseAPI}
 */
export class UserProfileApi extends BaseAPI implements UserProfileApiInterface {
    /**
     * ユーザーアバター画像更新API
     * @summary ユーザーアバター画像更新API
     * @param {UserProfileApiPostCurrentUserAvatarImageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public postCurrentUserAvatarImage(requestParameters: UserProfileApiPostCurrentUserAvatarImageRequest, options?: AxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).postCurrentUserAvatarImage(requestParameters.image, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ユーザープロファイル更新API
     * @summary ユーザープロファイル更新API
     * @param {UserProfileApiPutCurrentUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public putCurrentUser(requestParameters: UserProfileApiPutCurrentUserRequest = {}, options?: AxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).putCurrentUser(requestParameters.putCurrentUserRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
