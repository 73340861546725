<template>
  <div class="custom-field-option">
    <div
      v-for="option in customFieldOption"
      :key="option.id"
      class="option-item"
    >
      <BEditableContent
        :value="option.label"
        required
        :editing="isEditing(option)"
        @update:editing="updateEditing($event, option)"
        @check="updateCustomFieldOptionLabel($event, option)"
      />
      <BBtn text>
        <BIcon
          class="px-200"
          size="small"
          type="gray"
          @click="() => deleteCustomFieldOption(option)"
        >
          close
        </BIcon>
      </BBtn>
    </div>
    <BInputBox
      v-if="isOpenOptionInput"
      v-model="newOption"
      horiz
      required
      :loading="loading"
      @check="appendOption"
      @close="closeOptionInput"
    />
    <BBtn
      type="primary"
      text
      size="small"
      @click="openOptionInput"
    >
      <span class="append-option">{{ $t('general.append.of', {item: $t('general.option')}) }}</span>
    </BBtn>
  </div>
</template>

<script>
import lodash from 'lodash';
import { mapWaitingActions } from 'vue-wait';
import errorHandler from '@/mixins/error_handler';

export default {
  mixins: [errorHandler],
  props: {
    customFieldOption: Array,
    customFieldId: Number,
    customFieldName: String,
    loading: Boolean,
  },
  data () {
    return this.initialState();
  },
  methods: {
    ...mapWaitingActions('user', {
      updateCustomFieldAction: 'updateCustomFieldWait',
      deleteCustomFieldOptionAction: 'deleteCustomFieldOptionWait',
    }),
    initialState () {
      return {
        isOpenOptionInput: false,
        newOption: '',
        editingOptionId: null,
      };
    },
    initialize () {
      Object.assign(this.$data, this.initialState());
    },
    openOptionInput () {
      this.isOpenOptionInput = true;
    },
    closeOptionInput () {
      this.initialize();
    },
    async appendOption () {
      const options = [
        ...lodash.cloneDeep(this.customFieldOption),
        { label: this.newOption },
      ];
      await this.updateCustomFieldOption(options);
      this.initialize();
    },
    updateEditing (editing, option) {
      this.editingOptionId = editing ? option.id : null;
    },
    isEditing (option) {
      return this.editingOptionId === option.id;
    },
    async updateCustomFieldOptionLabel (newOptionLabel, currentOption) {
      const options = lodash.cloneDeep(this.customFieldOption).map(option => {
        if (option.id === currentOption.id) option.label = newOptionLabel;
        return option;
      });
      await this.updateCustomFieldOption(options);
      this.initialize();
    },
    async updateCustomFieldOption (options) {
      await this.updateCustomFieldAction({
        customFieldId: this.customFieldId,
        body: { customField: { options } },
        errorHandlers: {
          422: this.defaultHandler,
        },
      });
    },
    async deleteCustomFieldOption (option) {
      const check = await this.$bitterAlert.show({
        title: `「 ${option.label} 」${this.$t('general.delete.message')}`,
        text: this.$t('customField.option.deleteMessage', { item: this.customFieldName }),
      });
      if (!check) return;
      await this.deleteCustomFieldOptionAction({
        customFieldOptionId: option.id,
      });
      this.initialize();
    },
  },
};
</script>

<style lang="scss" scoped>
  .custom-field-option {
    width: 420px;
    margin-left: 60px;
  }

  .option-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $bdcolor-light;
    line-height: 36px;
    font-size: $fontsize-100;
  }

  .append-option {
    color: $basecolor-primary;
  }
</style>
