<template>
  <div
    class="trigger-require-approval-notification-item"
  >
    <LeadLink
      :call-target-id="props.item.callTarget.id"
      tag="div"
      class="company-name"
    >
      {{ item.callTarget.companyName }}
    </LeadLink>
    <div
      class="trigger-name"
      @click="handleTriggerClick"
    >
      {{ item.trigger.name }}
    </div>
    <div class="date">
      {{ formatShorterDateTime(item.createdAt) }}
    </div>
    <div
      class="actions"
    >
      <BBtn
        class="mr-100"
        flat
        type="primary"
        :loading="loading"
        @click="handleApproveClick"
      >
        {{ $t('general.approve.text') }}
      </BBtn>
      <BBtn
        flat
        type="primary"
        outline
        :loading="loading"
        @click="handleRejectClick"
      >
        {{ $t('general.reject.text') }}
      </BBtn>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { TriggerTargetAction } from '@/api/openapi';
import { TriggerTargetActionApiService } from '@/api/user/resources/trigger_target_action';
import { useWait } from '@/composable/vue-wait';
import { formatShorterDateTime } from '@/utils/date-time';
import LeadLink from '../general/LeadLink.vue';

type TProps = {
  item: TriggerTargetAction;
  loading: boolean;
};
type TEmit = {
  'after:update': [];
};

const props = withDefaults(defineProps<TProps>(), {});
const emit = defineEmits<TEmit>();

const { doActionWithWait } = useWait();
const router = useRouter();

const handleTriggerClick = () => {
  const { href } = router.resolve({
    name: 'Trigger',
    params: { id: props.item.trigger.id },
  });
  window.open(href, '_blank');
};

const api = new TriggerTargetActionApiService();
const handleApproveClick = async () => {
  await doActionWithWait('triggerApproveWait', async () => {
    await api.approveTriggerTargetAction({
      request: {
        triggerTargetActionId: props.item.id,
      },
    });
    emit('after:update');
  });
};
const handleRejectClick = async () => {
  await doActionWithWait('triggerRejectWait', async () => {
    await api.rejectTriggerTargetAction({
      request: {
        triggerTargetActionId: props.item.id,
      },
    });
    emit('after:update');
  });
};
</script>

<style lang="scss" scoped>
.company-name, .trigger-name {
  cursor: pointer;
}
</style>
