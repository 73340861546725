/* tslint:disable */
/* eslint-disable */
/**
 * openapi
 * bales cloud
 *
 * The version of the OpenAPI document: 1.0
 * Contact: test@example.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CustomFieldOptionDestroyTask } from '../models';
/**
 * CustomFieldOptionDestroyTaskApi - axios parameter creator
 * @export
 */
export const CustomFieldOptionDestroyTaskApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * カスタム項目オプション削除タスク取得API
         * @summary カスタム項目オプション削除タスク取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomFieldOptionDestroyTasks: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/custom_field_option_destroy_tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * カスタム項目オプション削除タスクconfirm API
         * @summary カスタム項目オプション削除タスクconfirm API
         * @param {number} customFieldOptionDestroyTaskId カスタム項目オプション削除タスクのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCustomFieldOptionDestroyTask: async (customFieldOptionDestroyTaskId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customFieldOptionDestroyTaskId' is not null or undefined
            assertParamExists('putCustomFieldOptionDestroyTask', 'customFieldOptionDestroyTaskId', customFieldOptionDestroyTaskId)
            const localVarPath = `/api/user/custom_field_option_destroy_tasks/{customFieldOptionDestroyTaskId}`
                .replace(`{${"customFieldOptionDestroyTaskId"}}`, encodeURIComponent(String(customFieldOptionDestroyTaskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomFieldOptionDestroyTaskApi - functional programming interface
 * @export
 */
export const CustomFieldOptionDestroyTaskApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomFieldOptionDestroyTaskApiAxiosParamCreator(configuration)
    return {
        /**
         * カスタム項目オプション削除タスク取得API
         * @summary カスタム項目オプション削除タスク取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomFieldOptionDestroyTasks(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomFieldOptionDestroyTask>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomFieldOptionDestroyTasks(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * カスタム項目オプション削除タスクconfirm API
         * @summary カスタム項目オプション削除タスクconfirm API
         * @param {number} customFieldOptionDestroyTaskId カスタム項目オプション削除タスクのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCustomFieldOptionDestroyTask(customFieldOptionDestroyTaskId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCustomFieldOptionDestroyTask(customFieldOptionDestroyTaskId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomFieldOptionDestroyTaskApi - factory interface
 * @export
 */
export const CustomFieldOptionDestroyTaskApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomFieldOptionDestroyTaskApiFp(configuration)
    return {
        /**
         * カスタム項目オプション削除タスク取得API
         * @summary カスタム項目オプション削除タスク取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomFieldOptionDestroyTasks(options?: any): AxiosPromise<Array<CustomFieldOptionDestroyTask>> {
            return localVarFp.getCustomFieldOptionDestroyTasks(options).then((request) => request(axios, basePath));
        },
        /**
         * カスタム項目オプション削除タスクconfirm API
         * @summary カスタム項目オプション削除タスクconfirm API
         * @param {number} customFieldOptionDestroyTaskId カスタム項目オプション削除タスクのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCustomFieldOptionDestroyTask(customFieldOptionDestroyTaskId: number, options?: any): AxiosPromise<void> {
            return localVarFp.putCustomFieldOptionDestroyTask(customFieldOptionDestroyTaskId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomFieldOptionDestroyTaskApi - interface
 * @export
 * @interface CustomFieldOptionDestroyTaskApi
 */
export interface CustomFieldOptionDestroyTaskApiInterface {
    /**
     * カスタム項目オプション削除タスク取得API
     * @summary カスタム項目オプション削除タスク取得API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomFieldOptionDestroyTaskApiInterface
     */
    getCustomFieldOptionDestroyTasks(options?: AxiosRequestConfig): AxiosPromise<Array<CustomFieldOptionDestroyTask>>;

    /**
     * カスタム項目オプション削除タスクconfirm API
     * @summary カスタム項目オプション削除タスクconfirm API
     * @param {number} customFieldOptionDestroyTaskId カスタム項目オプション削除タスクのID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomFieldOptionDestroyTaskApiInterface
     */
    putCustomFieldOptionDestroyTask(customFieldOptionDestroyTaskId: number, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * Request parameters for putCustomFieldOptionDestroyTask operation in CustomFieldOptionDestroyTaskApi.
 * @export
 * @interface CustomFieldOptionDestroyTaskApiPutCustomFieldOptionDestroyTaskRequest
 */
export interface CustomFieldOptionDestroyTaskApiPutCustomFieldOptionDestroyTaskRequest {
    /**
     * カスタム項目オプション削除タスクのID
     * @type {number}
     * @memberof CustomFieldOptionDestroyTaskApiPutCustomFieldOptionDestroyTask
     */
    readonly customFieldOptionDestroyTaskId: number
}

/**
 * CustomFieldOptionDestroyTaskApi - object-oriented interface
 * @export
 * @class CustomFieldOptionDestroyTaskApi
 * @extends {BaseAPI}
 */
export class CustomFieldOptionDestroyTaskApi extends BaseAPI implements CustomFieldOptionDestroyTaskApiInterface {
    /**
     * カスタム項目オプション削除タスク取得API
     * @summary カスタム項目オプション削除タスク取得API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomFieldOptionDestroyTaskApi
     */
    public getCustomFieldOptionDestroyTasks(options?: AxiosRequestConfig) {
        return CustomFieldOptionDestroyTaskApiFp(this.configuration).getCustomFieldOptionDestroyTasks(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * カスタム項目オプション削除タスクconfirm API
     * @summary カスタム項目オプション削除タスクconfirm API
     * @param {CustomFieldOptionDestroyTaskApiPutCustomFieldOptionDestroyTaskRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomFieldOptionDestroyTaskApi
     */
    public putCustomFieldOptionDestroyTask(requestParameters: CustomFieldOptionDestroyTaskApiPutCustomFieldOptionDestroyTaskRequest, options?: AxiosRequestConfig) {
        return CustomFieldOptionDestroyTaskApiFp(this.configuration).putCustomFieldOptionDestroyTask(requestParameters.customFieldOptionDestroyTaskId, options).then((request) => request(this.axios, this.basePath));
    }
}
