import { AxiosResponse } from 'axios';
import { ActionTree } from 'vuex';
import { ApiArguments } from '@/api/api_service';
import {
  DirectMail,
  DirectMailApiCancelReservationDirectMailRequest,
  DirectMailApiDeleteDirectMailRequest,
  DirectMailApiGetDirectMailRequest,
  DirectMailApiGetDraftItemsRequest,
  DirectMailApiPostDirectMailRequest,
  DirectMailApiPutDirectMailRequest,
  DirectMailApiReserveDirectMailRequest,
} from '@/api/openapi';
import { DirectMailApiService } from '@/api/user/resources/direct_mail';
import { RootState, UserState } from '@/store/types';
import { types } from '@/store/user/mutations';
import Waiting from '@/store/waiting';

const actions: ActionTree<UserState, RootState> = {
  getDraftItemsAction: async (
    _context,
    apiArguments: ApiArguments<DirectMailApiGetDraftItemsRequest>,
  ) => {
    return await waiting.checkWithArg('getDraftItemsAction', apiArguments, async () => {
      const api = new DirectMailApiService();
      return await api.getDraftItems(apiArguments);
    });
  },
  getDirectMailAction: async (
    _context,
    apiArguments: ApiArguments<DirectMailApiGetDirectMailRequest>,
  ) => {
    return await waiting.check('getDirectMailAction', async () => {
      const api = new DirectMailApiService();
      return await api.getDirectMail(apiArguments);
    });
  },
  createDirectMailAction: async (
    _context,
    apiArguments: ApiArguments<DirectMailApiPostDirectMailRequest>,
  ): Promise<AxiosResponse<DirectMail>> => {
    return await waiting.check('createDirectMailAction', async () => {
      const api = new DirectMailApiService();
      return await api.createDirectMail(apiArguments);
    });
  },
  updateDirectMailAction: async (
    _context,
    apiArguments: ApiArguments<DirectMailApiPutDirectMailRequest>,
  ): Promise<AxiosResponse<DirectMail>> => {
    return await waiting.check('updateDirectMailAction', async () => {
      const api = new DirectMailApiService();
      return await api.updateDirectMail(apiArguments);
    });
  },
  reserveDirectMailAction: async (
    _context,
    apiArguments: ApiArguments<DirectMailApiReserveDirectMailRequest>,
  ): Promise<AxiosResponse<DirectMail>> => {
    return await waiting.check('reserveDirectMailAction', async () => {
      const api = new DirectMailApiService();

      return await api.reserveDirectMail(apiArguments);
    });
  },
  cancelReservationDirectMailAction: async (
    _context,
    apiArguments: ApiArguments<DirectMailApiCancelReservationDirectMailRequest>,
  ): Promise<AxiosResponse<DirectMail>> => {
    return await waiting.check('cancelReservationDirectMailAction', async () => {
      const api = new DirectMailApiService();
      return await api.cancelReservationDirectMail(apiArguments);
    });
  },
  deleteDirectMailAction: async (
    _context,
    apiArguments: ApiArguments<DirectMailApiDeleteDirectMailRequest>,
  ): Promise<AxiosResponse<DirectMail>> => {
    return await waiting.check('deleteDirectMailAction', async () => {
      const api = new DirectMailApiService();
      return await api.deleteDirectMail(apiArguments);
    });
  },
};

const waiting = new Waiting(actions);

export default actions;
