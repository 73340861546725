<template>
  <div class="sequence-apply-modal">
    <BModal
      :modal-name="modalName"
      full
      @open-modal-event="handleOpen"
    >
      <BHeightAdjuster>
        <BModalHeader
          @modal-close="handleCloseClick"
        />
        <BModalBody>
          <BLayout
            v-loading="wait.is(['reAuthWait', 'sendVerificationCodeWait', 'resolveVerificationCodeWait'])"
            column
            class="form"
          >
            <BListItem>
              <span class="title">{{ i18n.t('mfaSetting.title') }}</span>
            </BListItem>

            <BAlert
              v-if="errorMessage"
              :text="errorMessage"
              class="mt-400 mb-400"
              type="error"
              effect="light"
            />

            <template v-if="!signin && !reAuthed">
              <BListItem class="mt-400">
                <span class="explain">{{ i18n.t('userSecurity.mfaSetting.inputPassword') }}</span>
              </BListItem>
              <BListItem class="mt-400">
                <template #header>
                  <span>{{ i18n.t('general.password') }}</span>
                </template>
                <BInput
                  v-model="password"
                  v-model:valid="passwordValid"
                  type="password"
                  class="w-input"
                  color="focus"
                  :rules="[requiredRule]"
                  :disabled="wait.is('reAuthWait')"
                />
              </BListItem>
              <BListItem class="mt-600">
                <BBtn
                  type="primary"
                  class="btn-wide"
                  :disabled="!passwordValid"
                  :loading="wait.is('reAuthWait')"
                  @click="handleReAuthClick"
                >
                  {{ i18n.t('general.authorize.do') }}
                </BBtn>
              </BListItem>
            </template>

            <template v-else-if="!signin && !sentVerificationCode">
              <BListItem class="mt-400">
                <span class="explain">{{ i18n.t('userSecurity.mfaSetting.inputPhoneNumber') }}</span>
              </BListItem>
              <BListItem class="mt-400">
                <template #header>
                  <span>{{ i18n.t('general.tel') }}</span>
                </template>
                <BInput
                  v-model="phoneNumber"
                  v-model:valid="phoneNumberValid"
                  class="w-input"
                  :rules="[requiredRule, phoneRule]"
                  :disabled="wait.is('sendVerificationCodeWait')"
                  clearable
                />
              </BListItem>
              <BListItem class="mt-600">
                <BBtn
                  type="primary"
                  class="btn-wide"
                  :disabled="!phoneNumberValid"
                  :loading="wait.is('sendVerificationCodeWait')"
                  @click="handleSendVerificationCodeClick"
                >
                  {{ i18n.t('general.toNext') }}
                </BBtn>
              </BListItem>
            </template>

            <template v-else-if="signin || sentVerificationCode">
              <BListItem
                v-if="!signin && !errorMessage"
                class="mt-400"
              >
                <span class="explain">{{ i18n.t('userSecurity.mfaSetting.inputVerificationCodeWhenRegistor') }}</span>
              </BListItem>
              <BListItem
                v-if="signin && !errorMessage"
                class="mt-400"
              >
                <span class="explain">{{ i18n.t('userSecurity.mfaSetting.inputVerificationCodeWhenSignin') }}</span>
              </BListItem>
              <BListItem class="mt-200">
                <template #header>
                  <span>{{ i18n.t('mfaSetting.verificationCode') }}</span>
                </template>
                <BInput
                  v-model="verificationCode"
                  v-model:valid="verificationCodeValid"
                  :min-length="6"
                  :max-length="6"
                  :rules="[requiredRule, numberRule]"
                  :disabled="wait.is('resolveVerificationCodeWait')"
                  class="w-input"
                  clearable
                />
              </BListItem>
              <BListItem>
                <BBtn
                  type="primary"
                  text
                  size="small"
                  @click="handleReSendClick"
                >
                  <span class="append-option">{{ i18n.t('userSecurity.mfaSetting.reSendVerificationCode') }}</span>
                </BBtn>
              </BListItem>
              <BListItem v-if="!signin && errorMessage">
                <BBtn
                  type="primary"
                  text
                  size="small"
                  @click="handleChangePhoneNumberClick"
                >
                  <span class="append-option">{{ i18n.t('userSecurity.mfaSetting.changePhoneNumber') }}</span>
                </BBtn>
              </BListItem>
              <BListItem class="mt-600">
                <BBtn
                  type="primary"
                  class="btn-wide"
                  :disabled="!verificationCodeValid"
                  :loading="wait.is('resolveVerificationCodeWait')"
                  @click="handleResolveVerificationCodeClick"
                >
                  {{ i18n.t('general.authorize.do') }}
                </BBtn>
              </BListItem>
            </template>
          </BLayout>
          <div id="recaptcha-container" />
        </BModalBody>
      </BHeightAdjuster>
    </BModal>
  </div>
</template>

<script lang="ts" setup>
import { MultiFactorError } from 'firebase/auth';
import { ref, computed, nextTick } from 'vue';
import { useI18n } from 'vue-i18n';
import { useInputValidations } from '@/composable/input-validations';
import { useFirebaseAuth } from '@/composable/user/mfaSetting/firebase-auth';
import { useFirebaseRecaptcha } from '@/composable/user/mfaSetting/firebase-recaptcha';
import { useWait } from '@/composable/vue-wait';

type TProps = {
  modalName: string;
  multiFactorError: MultiFactorError;
};

const props = defineProps<TProps>();
const emit = defineEmits(['close', 'authenticated']);

const i18n = useI18n();
const { wait } = useWait();
const { requiredRule, phoneRule, numberRule } = useInputValidations();

const password = ref<string | null>(null);
const passwordValid = ref<boolean>();
const phoneNumber = ref<string | null>(null);
const phoneNumberValid = ref<boolean>();
const verificationCode = ref<string | null>(null);
const verificationCodeValid = ref<boolean>();

const signin = computed<boolean>(() =>
  !!props.multiFactorError,
);
const internalMultiFactorError = computed<MultiFactorError>(() =>
  props.multiFactorError,
);

const {
  setRecaptcha,
  resetRecaptcha,
} = useFirebaseRecaptcha(
  'recaptcha-container',
);

const {
  reAuth,
  reAuthed,
  sendVerificationCode,
  sentVerificationCode,
  resolveVerificationCode,
  errorMessage,
  resetErrorMessage,
  resetVerification,
} = useFirebaseAuth(
  signin,
  internalMultiFactorError,
  setRecaptcha,
);

const handleOpen = async () => {
  if (signin.value) {
    nextTick(() => {
      sendVerificationCode(phoneNumber.value);
    });
  }
};

const handleCloseClick = () => {
  password.value = null;
  phoneNumber.value = null;
  verificationCode.value = null;
  resetErrorMessage();
  resetVerification();
  emit('close');
};

const handleReAuthClick = async () => {
  resetErrorMessage();
  await reAuth(password.value);
};

const handleSendVerificationCodeClick = async () => {
  resetErrorMessage();
  await sendVerificationCode(phoneNumber.value);
};

const handleReSendClick = async () => {
  verificationCode.value = null;
  resetErrorMessage();
  resetRecaptcha();
  await sendVerificationCode(phoneNumber.value);
};

const handleChangePhoneNumberClick = () => {
  verificationCode.value = null;
  resetErrorMessage();
  resetVerification();
};

const handleResolveVerificationCodeClick = async () => {
  resetErrorMessage();
  await resolveVerificationCode(phoneNumber.value, verificationCode.value);
  if (!errorMessage.value) {
    emit('authenticated');
  }
};
</script>

<style lang="scss" scoped>
.form {
  margin: auto;
  max-width: 800px;

  @media only screen and (min-width: 959px) {
    .w-input {
      width: 80%;
    }
  }
}
.title {
  font-size: $fontsize-400;
  font-weight: bold;
}
.explain {
  color: $concrete-dark;
}
.btn-wide {
  width: 300px;
}
</style>
