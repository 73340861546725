<template>
  <BDrawerMenu
    v-model="drawer"
    :title="$t('general.nextAction')"
    @cancel="handleCancel"
  >
    <div>
      <NextActionFormBody
        v-model="internalCommonNextAction"
        v-model:is-valid="isFormValid"
        :call-target-id="callTargetId"
      >
        <template #dateTime>
          <BListItem class="mb-300">
            <template #header>
              <span>{{ $t('nextAction.reservedAt') }}</span>
            </template>
            <BInputDateTime
              v-model:date="reservedDate"
              v-model:time="reservedTime"
              is-step
              clearable
            />
          </BListItem>
        </template>
      </NextActionFormBody>
      <BListItem v-if="nextAction.id">
        <template #header>
          <span>{{ $t('nextAction.createdAt') }}</span><span>{{ firstSetUpAt }}</span>
        </template>
      </BListItem>
    </div>
    <template #footer>
      <div
        class="footer"
      >
        <BLayout
          align-center
          justify-center
        >
          <BBtn
            class="mr-300"
            text
            :disabled="waiting"
            @click="handleCancel"
          >
            {{ $t('general.cancel.text') }}
          </BBtn>
          <BBtn
            :disabled="!isValid"
            class="ml-300"
            type="primary"
            :loading="waiting"
            @click="saveNextAction"
          >
            {{ $t('general.save.text') }}
          </BBtn>
        </BLayout>
      </div>
    </template>
  </BDrawerMenu>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapWaitingActions } from 'vue-wait';
import { mapGetters } from 'vuex';
import { makeNextActionType } from '@/api/user/models/next_action_types';
import NextActionFormBody, { TCommonNextAction } from '@/components/organisms/user/general/NextActionFormBody.vue';
import { formatShorterDateTime, mergeDateAndTime, newDateByTime } from '@/utils/date-time';

type TData = {
  isEdit: boolean;
  isFormValid: boolean;
  nextAction: {
    id: number | null;
    callTargetId: number | null;
    reservedAt: Date;
  } & TCommonNextAction;
};

export default defineComponent({
  components: {
    NextActionFormBody,
  },
  props: {
    callTargetId: {
      type: Number,
      required: true,
    },
    currentUserId: {
      type: Number,
      required: true,
    },
  },
  data(): TData {
    return this.initialState();
  },
  computed: {
    ...mapGetters('userUi', [
      'targetSingleNextActionFormVisible',
      'selectedNextAction',
    ]),
    drawer: {
      get () {
        return this.targetSingleNextActionFormVisible;
      },
      set (newVal: boolean) {
        if (this.targetSingleNextActionFormVisible !== newVal) this.setTargetSingleNextActionFormVisibleAction(newVal);
      },
    },
    internalCommonNextAction: {
      get(): TCommonNextAction {
        return {
          actionType: this.nextAction.actionType,
          priority: this.nextAction.priority,
          ownerId: this.nextAction.ownerId,
          content: this.nextAction.content,
        };
      },
      set(newVal: TCommonNextAction) {
        this.nextAction = {
          ...this.nextAction,
          ...newVal,
        };
      },
    },
    reservedDate: {
      get () {
        if (!this.nextAction.reservedAt) return;
        return new Date(this.nextAction.reservedAt);
      },
      set (newVal: unknown) {
        const date = newVal;
        const time = this.reservedTime ? this.reservedTime : newVal;
        const reservedAt = mergeDateAndTime(date, time);
        this.nextAction.reservedAt = reservedAt;
      },
    },
    reservedTime: {
      get () {
        if (!this.nextAction.reservedAt) return;
        return new Date(this.nextAction.reservedAt);
      },
      set (newVal: unknown) {
        const date = this.reservedDate ? this.reservedDate : newVal;
        const time = newVal;
        const reservedAt = mergeDateAndTime(date, time);
        this.nextAction.reservedAt = reservedAt;
      },
    },
    waiting () {
      return this.$wait.is(['createNextActionWait', 'updateNextActionWait']);
    },
    firstSetUpAt () {
      return formatShorterDateTime(this.nextAction.firstSetUpAt);
    },
    isValid() {
      return this.isFormValid && this.nextAction.reservedAt != null;
    },
  },
  watch: {
    targetSingleNextActionFormVisible(visible: boolean) {
      if (!visible) return;
      if (Object.keys(this.selectedNextAction).length > 0) {
        this.isEdit = true;
        this.nextAction = {
          ...this.initialState().nextAction,
          id: this.selectedNextAction.id,
          callTargetId: this.selectedNextAction.callTargetId,
          reservedAt: this.selectedNextAction.reservedAt,
          content: this.selectedNextAction.content,
          firstSetUpAt: this.selectedNextAction.firstSetUpAt,
          ownerId: this.selectedNextAction.owner?.id,
          actionType: makeNextActionType(this.selectedNextAction.defaultActionTypeId, this.selectedNextAction.actionTypeId),
          priority: this.selectedNextAction.priority,
        };
      }
    },
    currentUserId(newVal: number) {
      this.nextAction.ownerId = newVal;
    },
  },
  methods: {
    ...mapWaitingActions('user', {
      createNextActionAction: 'createNextActionWait',
      updateNextActionAction: 'updateNextActionWait',
      getCallTargetsBackgroundAction: { action: 'getCallTargetsAction', loader: 'getCallTargetsBackgroundWait' },
      getLeadHistoriesBackgroundAction: { action: 'getLeadHistoriesAction', loader: 'getLeadHistoriesBackgroundWait' },
    }),
    ...mapWaitingActions('userUi', {
      setTargetSingleNextActionFormVisibleAction: 'settargetSingleNextActionFormVisibleWait',
      setTargetHeaderMoveButtonDisabledAction: 'setTargetHeaderMoveButtonDisabledWait',
      setSelectedNextActionAction: 'setsetSelectedNextActionWait',
    }),
    saveNextAction() {
      if (this.isEdit) {
        this.updateNextAction();
      } else {
        this.createNextAction();
      }
    },
    async createNextAction() {
      await this.createNextActionAction({
        callTargetId: this.callTargetId,
        body: { nextAction: this.nextAction },
      });

      this.closeDrawer();
      this.reloadCallTarget();
    },
    async updateNextAction() {
      await this.updateNextActionAction({
        nextActionId: this.nextAction.id,
        callTargetId: this.callTargetId,
        body: { nextAction: this.nextAction },
        errorHandlers: {
          422: this.defaultHandler,
        },
      });

      this.closeDrawer();
      this.reloadCallTarget();
    },
    async handleCancel() {
      const check = await this.$bitterAlert.show({
        title: this.$t('general.confirm.text'),
        text: this.$t('general.alert.of', { target: this.$t('general.unsavedValue'), action: this.$t('general.clear') }),
      });
      if (!check) { return; }

      this.closeDrawer();
    },
    closeDrawer() {
      this.drawer = false;
      this.setSelectedNextActionAction({});
      this.initializeState();
      this.setTargetHeaderMoveButtonDisabledAction(false);
    },
    reloadCallTarget() {
      this.getLeadHistoriesBackgroundAction({ callTargetId: this.callTargetId });
      this.getCallTargetsBackgroundAction();
    },
    initializeState() {
      Object.assign(this.$data, this.initialState());
    },
    initialState(): TData {
      return {
        isEdit: false,
        isFormValid: false,
        nextAction: {
          id: null,
          callTargetId: null,
          actionType: 'default1',
          priority: 'middle',
          ownerId: this.currentUserId,
          reservedAt: newDateByTime(12, 0, 0),
          content: '',
        },
      };
    },
  },
});
</script>

<style lang="scss" scoped>
  .footer {
    background-color: $bgcolor-base;
    padding: $basespace-200 $basespace-400;
    border-top: 1px solid $bdcolor-light;
  }
</style>
