export default {
  TIMEOUT_ERROR_CODE: 'TIMEOUT',
  NETWORK_ERROR_CODE: 'NETWORK',
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  NOT_ACCEPTABLE: 406,
  UNPROCESSABLE_ENTITY: 422,
  INTERNAL_SERVER_ERROR: 500,
  SF_TOKEN_EXPIRED: 512,
  SERVICE_UNAVAILABLE: 503,
};
