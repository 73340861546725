<template>
  <BColorPreview
    class="mr-50"
    :color-code="colorCode"
    size="small"
  />
  {{ $t(`trigger.target.status.enum.${props.value}`) }}
</template>

<script lang="ts" setup>
import { ValueOf } from 'element-plus/es/components/table/src/table-column/defaults';
import { computed } from 'vue';
import { TriggerTargetStatus } from '@/api/openapi';
import COLOR_CODES from '@/const/color_codes';

type TColor = { status: TriggerTargetStatus, colorCode: ValueOf<typeof COLOR_CODES> };

type TProps = {
  value: TriggerTargetStatus;
};

const props = defineProps<TProps>();

defineEmits(['click']);

const colors: TColor[] = [
  {
    status: 'ready',
    colorCode: COLOR_CODES.GAIA,
  },
  {
    status: 'executing',
    colorCode: COLOR_CODES.VENUS,
  },
  {
    status: 'completed',
    colorCode: COLOR_CODES.URANUS,
  },
  {
    status: 'failed',
    colorCode: COLOR_CODES.MARS,
  },
  {
    status: 'stopped',
    colorCode: COLOR_CODES.NEPTUNE,
  },
];
const colorCode = computed(() => colors.find(c => c.status === props.value)?.colorCode);
</script>
