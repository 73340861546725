<template>
  <div class="table-area">
    <div v-if="isLoading">
      <BPlaceholder
        v-loading="true"
        height="70vh"
        element-loading-background="transparent"
      />
    </div>
    <div v-else>
      <ElTable
        :data="adjustedSendgridSubUsers"
        height="65vh"
        :default-sort="{prop: 'id', order: 'ascending'}"
      >
        <template
          v-for="(header, index) in headers"
          :key="index"
        >
          <ElTableColumn
            v-if="header.value === 'clients'"
            :label="header.text"
            sortable
            :width="columnsWidth[index]"
          >
            <template #default="scope">
              <div
                v-for="(client, clientIndex) in scope.row.clients"
                :key="clientIndex"
              >
                <RouterLink :to="{ name: 'AdminClient', params: { id: client.id } }">
                  {{ client.name }}
                </RouterLink>
              </div>
            </template>
          </ElTableColumn>
          <ElTableColumn
            v-else
            :prop="header.value"
            :label="header.text"
            sortable
            :width="columnsWidth[index]"
          />
        </template>
        <ElTableColumn v-if="isAdminDeveloper">
          <template #default="scope">
            <BPopover
              v-if="scope.row.clientIds.length > 0"
              placement="top"
              width="240"
              trigger="hover"
            >
              <template #reference>
                <div>
                  <BBtn
                    class="mr-500"
                    type="primary"
                    disabled
                  >
                    <span>{{ $t('general.delete.text') }}</span>
                  </BBtn>
                </div>
              </template>
              <div>{{ $t('general.alreadyUsed') }}</div>
            </BPopover>
            <!--
              <b-btn
              v-else
              class="mr-500"
              type="primary"
              @click="handleDelete(scope.row.id)"
              >
              <span>{{ $t('general.delete.text') }}</span>
              </b-btn> 
            -->
          </template>
        </ElTableColumn>
      </ElTable>
      <BLayout class="pt-300">
        <BFlex
          :span="4"
          align-self-center
        >
          <BLayout justify-center>
            <span>{{ pageStart }} - {{ pageEnd }} / {{ totalCount }}</span>
          </BLayout>
        </BFlex>
        <BFlex :span="16">
          <BLayout justify-center>
            <BPagination
              v-model="currentPage"
              :length="allPageCount"
              :total-visible="9"
            />
          </BLayout>
        </BFlex>
        <BFlex :span="4">
          <BSelect
            v-model="pageSize"
            class="mx-600"
            :items="pageSizeChoice"
          />
        </BFlex>
      </BLayout>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import errorHandler from '@/mixins/error_handler';

export default {
  name: 'SendgridSubUsersList',
  mixins: [errorHandler],
  props: {
    isAdminDeveloper: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'exec-delete',
  ],
  data () {
    return {
      currentPage: 1,
      pageSize: 20,
      pageSizeChoice: [
        { text: '20', value: 20 },
        { text: '35', value: 35 },
        { text: '50', value: 50 },
      ],
      columnsWidth: [180, 250, 100, 180, ''],
    };
  },
  computed: {
    ...mapGetters('admin', [
      'sendgridSubUsers',
    ]),
    isLoading () {
      return this.$wait.is(['postSendgridApiSettingWait', 'getSendgridSubUsersWait', 'getClientsWait']);
    },
    adjustedSendgridSubUsers () {
      return this.sendgridSubUsers.slice(this.pageStart - 1, this.pageEnd);
    },
    pageStart () {
      return this.pageSize * (this.currentPage - 1) + 1;
    },
    pageEnd () {
      const defaultEnd = this.pageSize * this.currentPage;
      return Math.min(defaultEnd, this.totalCount);
    },
    totalCount () {
      return this.sendgridSubUsers.length;
    },
    allPageCount () {
      return Math.ceil(this.totalCount / this.pageSize);
    },
    headers () {
      return Object.entries(this.$tm('sendgridSubUserManagement.headers')).map(([key, value]) => ({
        text: this.$rt(value),
        value: key,
      }));
    },
  },
  methods: {
    async handleDelete (id) {
      const check = await this.$bitterAlert.show({
        title: this.$t('general.confirm.text'),
        text: this.$t('general.confirm.of', { action: this.$t('general.delete.text') }),
      });
      if (!check) { return; }
      this.$emit('exec-delete', { id });
    },
  },
};
</script>

<style lang="scss" scoped>
  .table-area {
    height: 70vh;
  }
</style>
