import ApiService, { ApiArguments } from '@/api/api_service';
import {
  MailTemplateApi,
  MailTemplateApiDeleteMailTemplateRequest,
  MailTemplateApiGetMailTemplateRequest,
  MailTemplateApiPostMailTemplateRequest,
  MailTemplateApiPutMailTemplateRequest,
} from '@/api/openapi/apis/mail-template-api';
import { MailTemplate } from '@/api/openapi/models/mail-template';

export class MailTemplatesApiService extends ApiService {
  api = new MailTemplateApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true);
  }

  async getMailTemplate({
    request,
    options,
    errorHandlers,
  }: ApiArguments<MailTemplateApiGetMailTemplateRequest>) {
    return this.checkResponse<MailTemplate>(
      this.api.getMailTemplate(request, options),
      errorHandlers,
    );
  }

  async getMailTemplates(
    apiArguments?: ApiArguments<undefined>,
  ) {
    return this.checkResponse<MailTemplate[]>(
      this.api.getMailTemplates(apiArguments?.options),
      apiArguments?.errorHandlers,
    );
  }

  async postMailTemplate({
    request,
    options,
    errorHandlers,
  }: ApiArguments<MailTemplateApiPostMailTemplateRequest>) {
    return this.checkResponse<MailTemplate>(
      this.api.postMailTemplate(request, options),
      errorHandlers,
    );
  }

  async putMailTemplate({
    request,
    options,
    errorHandlers,
  }: ApiArguments<MailTemplateApiPutMailTemplateRequest>) {
    return this.checkResponse<void>(
      this.api.putMailTemplate(request, options),
      errorHandlers,
    );
  }

  async deleteMailTemplate({
    request,
    options,
    errorHandlers,
  }: ApiArguments<MailTemplateApiDeleteMailTemplateRequest>) {
    return this.checkResponse<void>(
      this.api.deleteMailTemplate(request, options),
      errorHandlers,
    );
  }
}
