<template>
  <div :class="attributes">
    <BToast />
    <RouterView />
  </div>
</template>

<script>
export default {
  name: 'App',
  computed: {
    attributes () {
      return {
        'dark-mode': import.meta.env.VITE_ENABLE_DARK_MODE,
      };
    },
  },
  created () {
    if (import.meta.env.VITE_ELASTIC_APM_SECRET_TOKEN) {
      this.span = this.$apm.startSpan();
    }
  },
  mounted () {
    if (import.meta.env.VITE_ELASTIC_APM_SECRET_TOKEN) {
      this.span && this.span.end();
    }
  },
};
</script>

<style lang="scss">
  html {
    font-size: 14px;
    overflow: hidden;
    box-sizing: border-box;
    overflow-y: scroll; /* All browsers without overlaying scrollbars */
    -webkit-text-size-adjust: 100%; /* Prevent adjustments of font size after orientation changes in iOS */
    word-break: normal;
    -moz-tab-size: 4;
    tab-size: 4;
  }

  *,
  ::before,
  ::after {
    background-repeat: no-repeat; /* Set `background-repeat: no-repeat` to all elements and pseudo elements */
    box-sizing: inherit;
  }

  ::before,
  ::after {
    text-decoration: inherit; /* Inherit text-decoration and vertical align to ::before and ::after pseudo elements */
    vertical-align: inherit;
  }

  * {
    padding: 0; /* Reset `padding` and `margin` of all elements */
    margin: 0;
  }

  #app.dark-mode {
    filter: invert(1) hue-rotate(180deg);
  }

  ::-webkit-scrollbar {
    width: $scrollbar-width;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
    box-shadow: inset 0 0 0 2px $bgcolor-white;
  }

  #app {
    font-family: 'Rubik', 'Noto Sans JP', "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  /* font */
  .application, .display-1, .display-2, .display-3, .display-4, .headline, .title, .v-application {
    font-family: 'Rubik', 'Noto Sans JP' !important;
  }

  .text-selectable * {
    user-select: text;
  }

  .no-decoration {
    text-decoration: none
  }

  /* vuetify margin init */
  .v-messages,
  .v-text-field .v-messages {
    min-height: auto;
  }

  .v-list__tile {
    height: auto;
  }

  .v-text-field,
  .v-input,
  .v-input__slot,
  .v-input--selection-controls:not(.v-input--hide-details) .v-input__slot,
  .v-input--selection-controls__input,
  // .v-list,
  // .v-list__tile,
  .v-select__selection--comma {
    margin: 0;
    padding: 0;
  }

  p {
    margin-bottom: 16px
  }

  a {
    color: $basecolor-success
  }

  button {
    border-style: none;
    cursor: pointer;
  }

  button, input, select, textarea {
    background-color: transparent;
    border-style: none;
  }

  /* sweetalert */
  .swal-modal {
    border-radius: 12px;
    overflow: hidden;
    &.swal-modal-large {
      width: 650px;
      .swal-footer {
        display: flex;
        justify-content: center;
        .swal-button-container:nth-child(n+2) {
          margin-left: 50px;
        }
      }
    }
  }

  .swal-title {
    font-size: 20px;
    color: $textcolor-base;
    background-color: $bgcolor-white;
    padding: $basespace-500;
    margin: 0 !important;
  }

  .swal-text {
    display: block;
    text-align: center;
    width: 100%;
    color: $textcolor-base;
    background-color: $bgcolor-white;
    padding: $basespace-600;
    margin: 0 !important;
    overflow-y: scroll;
    max-height: 300px;
  }

  .swal-button {
    font-size: 12px;
    border-radius: 2px;
    background-color: $basecolor-primary;
    padding: 7px 19px;
  }

  .swal-button--cancel {
    background-color: $bgcolor-white;
  }

  .swal-button--optional {
    background-color: $bgcolor-base;
    color: $basecolor-primary;
    border: 1px solid $basecolor-primary;
  }

  // Space
  .w-full {
    width: 100%;
  }

  $space-array: (
    50: $basespace-50,
    100: $basespace-100,
    200: $basespace-200,
    300: $basespace-300,
    400: $basespace-400,
    500: $basespace-500,
    600: $basespace-600,
    700: $basespace-700,
    800: $basespace-800,
    900: $basespace-900,
  );

  @each $i, $space-name in $space-array {
    .mt-#{$i} {
      margin-top: $space-name;
    }

    .mr-#{$i} {
      margin-right: $space-name;
    }

    .mb-#{$i} {
      margin-bottom: $space-name;
    }

    .ml-#{$i} {
      margin-left: $space-name;
    }

    .my-#{$i} {
      margin-top: $space-name;
      margin-bottom: $space-name;
    }

    .mx-#{$i} {
      margin-right: $space-name;
      margin-left: $space-name;
    }

    .pt-#{$i} {
      padding-top: $space-name;
    }

    .pr-#{$i} {
      padding-right: $space-name;
    }

    .pb-#{$i} {
      padding-bottom: $space-name;
    }

    .pl-#{$i} {
      padding-left: $space-name;
    }

    .py-#{$i} {
      padding-top: $space-name;
      padding-bottom: $space-name;
    }

    .px-#{$i} {
      padding-right: $space-name;
      padding-left: $space-name;
    }
  }

  .mr-a {
    margin-right: auto;
  }

  .ml-a {
    margin-left: auto;
  }

  // FontSize
  .fs-700 {
    font-size: $fontsize-700;
  }

  .fs-600 {
    font-size: $fontsize-600;
  }

  .fs-500 {
    font-size: $fontsize-500;
  }

  .fs-400 {
    font-size: $fontsize-400;
  }

  .fs-300 {
    font-size: $fontsize-300;
  }

  .fs-200 {
    font-size: $fontsize-200;
  }

  .fs-100 {
    font-size: $fontsize-100;
  }

  .fw-bold {
    font-weight: 700!important;
  }

  // Color
  .fc-primary {
    color: $basecolor-primary;
  }

  .fc-success {
    color: $basecolor-success;
  }

  .fc-warning {
    color: $basecolor-warning;
  }

  // text
  .text-annotation {
    font-size: $fontsize-100;
    color: $textcolor-light;
  }

  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }

  .text-left {
    text-align: left;
  }

  .break-word-pre-wrap {
    word-wrap: break-word;
    white-space: pre-wrap;
  }

  // line-height
  .lh-1 {
    line-height: 1;
  }

  // Transition
  .dropdown-enter-from, .dropdown-leave-to {
    opacity: 0;
    transform: translateY(-30px);
  }

  .dropdown-enter-active, .dropdown-leave-active {
    transition: all 300ms $standard-easing;
  }

  .list {
    &-enter-active, &-leave-active {
      transition: all 400ms $standard-easing;
    }

    &-enter-from, &-leave-to {
      opacity: 0;
      transform: translateY(30px);
    }

    &-move {
      transition: all 400ms $standard-easing;
    }
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 400ms;
  }
  .fade-enter-from, .fade-leave-to {
    opacity: 0;
  }

  .slide-y {
    &-enter-active {
      animation: slide-y 300ms;
    }

    &-leave-active {
      animation: slide-y 300ms reverse;
    }
  }

  @keyframes slide-y {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    50% {
      transform: translateY(6px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  .slide-y-reverse {
    &-enter-active {
      animation: slide-y-reverse 300ms;
    }

    &-leave-active {
      animation: slide-y-reverse 300ms reverse;
    }
  }

  @keyframes slide-y-reverse {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    50% {
      transform: translateY(-6px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  .slide-x {
    &-enter-active {
      animation: slide-x 300ms;
    }

    &-leave-active {
      animation: slide-x 300ms reverse;
    }
  }

  @keyframes slide-x {
    0% {
      opacity: 0;
      transform: translateX(-20px);
    }
    50% {
      transform: translateX(6px);
    }
    100% {
      opacity: 1;
      transform: translateX(0px);
    }
  }

  .slide-x-reverse {
    &-enter-active {
      animation: slide-x-reverse 300ms;
    }

    &-leave-active {
      animation: slide-x-reverse 300ms reverse;
    }
  }

  @keyframes slide-x-reverse {
    0% {
      opacity: 0;
      transform: translateX(20px);
    }
    50% {
      transform: translateX(-6px);
    }
    100% {
      opacity: 1;
      transform: translateX(0px);
    }
  }

  // flex position
  .align-start {
    align-items: start;
  }
  .align-end {
    align-items: end;
  }
  .align-center {
    align-items: center;
  }
  .justify-start {
    justify-content: start;
  }
  .justify-end {
    justify-content: end;
  }
  .justify-center {
    justify-content: center;
  }
  .justify-space-between {
    justify-content: space-between;
  }
  .justify-space-around {
    justify-content: space-around;
  }

  // Other
  .truncate-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .hover-effect {
    cursor: pointer;
    transition: background-color 300ms $standard-easing;

    &:hover {
      background-color: $bgcolor-base;
    }
  }

  .display-none {
    display: none;
  }
</style>
