<template>
  <BCard>
    <slot name="title">
      <span class="headline">{{ $t(`adminManagement.title`) }}</span>
    </slot>
    <div class="container">
      <div class="mb-500">
        <BInput
          v-model="inputName"
          v-model:valid="valid"
          :label="$t(`adminManagement.name`)"
          :rules="[requiredRule]"
        />
      </div>
      <div class="mb-500">
        <BSelect
          v-model="inputRole"
          v-model:valid="roleValid"
          :items="roles"
          required
        />
      </div>
      <div class="mb-500">
        <BCheckbox
          v-model="inputActivated"
          :label="$t(`adminManagement.activated`)"
          color="focus"
        />
      </div>
    </div>
    <slot name="actions">
      <BLayout
        align-center
        justify-center
      >
        <BBtn
          class="mr-400"
          text
          @click="cancel"
        >
          {{ $t(`general.cancel.text`) }}
        </BBtn>
        <BBtn
          class="ml-400"
          type="primary"
          :disabled="!isValid"
          :loading="loading"
          @click="save"
        >
          {{ $t(`general.save.text`) }}
        </BBtn>
      </BLayout>
    </slot>
  </BCard>
</template>

<script>
import inputValidation from '@/mixins/input_validation';

export default {
  mixins: [inputValidation],
  props: {
    id: {
      type: Number,
      default: 0,
    },
    name: String,
    role: String,
    activated: String,
    loading: Boolean,
  },
  emits: [
    'save',
    'cancel',
  ],
  data () {
    return {
      valid: false,
      roleValid: false,
      inputName: null,
      inputRole: 'admin_member',
      inputActivated: null,
    };
  },
  computed: {
    roles() {
      return ['owner', 'member', 'developer'].map(role => ({ text: this.$t(`adminManagement.roles.${role}`), value: `admin_${role}` }));
    },
    isValid() {
      return this.valid && this.roleValid;
    },
  },
  watch: {
    id () {
      this.reset();
    },
    name (newVal) {
      this.inputName = newVal;
    },
    activated (newVal) {
      this.inputActivated = this.convertActivated(newVal);
    },
  },
  created () {
    this.reset();
  },
  methods: {
    save () {
      if (!this.valid) return;
      const admin = {
        name: this.inputName,
        role: this.inputRole,
        activated: this.inputActivated,
      };
      this.$emit('save', admin);
    },
    cancel () {
      this.$emit('cancel');
    },
    reset () {
      this.inputName = this.name;
      this.inputRole = this.role;
      this.inputActivated = this.convertActivated(this.activated);
    },
    convertActivated (activated) {
      if (activated === 'disabled') {
        return false;
      } else if (activated === 'enabled') {
        return true;
      } else {
        return null;
      }
    },
  },
};
</script>
