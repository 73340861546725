import { AxiosResponse } from 'axios';
import ApiService, { ApiArguments } from '@/api/api_service';
import { GetSequenceExecutionSetting200Response, SequenceSettingApi, SequenceSettingApiPatchSequenceExecutionSettingRequest } from '@/api/openapi';

export class SequenceSettingApiService extends ApiService {
  api = new SequenceSettingApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true);
  }

  async getSequenceExecutionSetting(
    {
      options,
      errorHandlers,
    }: ApiArguments<SequenceSettingApiPatchSequenceExecutionSettingRequest> = {},
  ) {
    return this.checkResponse<GetSequenceExecutionSetting200Response>(
      this.api.getSequenceExecutionSetting(options),
      errorHandlers,
    );
  }

  async patchSequenceExecutionSetting({
    request,
    options,
    errorHandlers,
  }: ApiArguments<SequenceSettingApiPatchSequenceExecutionSettingRequest>): Promise<AxiosResponse<void>> {
    return this.checkResponse<void>(
      this.api.patchSequenceExecutionSetting(request, options),
      errorHandlers,
    );
  }
}
