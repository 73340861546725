<template>
  <LeadLink
    :call-target-id="item.callTarget.id"
    tag="div"
    class="received-mail-notification-item"
    @before-move="handleBeforeMoveToLead"
  >
    <div class="date">
      {{ receivedAt }}
    </div>
    <div class="company-name">
      {{ item.callTarget.companyName }}
    </div>
    <div class="subject">
      {{ $t('receivedMailNotification.item.subject', {subject: item.subject}) }}
    </div>
  </LeadLink>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { PartialReceivedMail } from '@/api/openapi';
import { ReceivedMailApiService } from '@/api/user/resources/received_mail';
import LeadLink from '@/components/organisms/user/general/LeadLink.vue';
import { formatDate } from '@/utils/date-time';

type TProps = {
  item: PartialReceivedMail;
};
type TEmit = (e: 'afterClickEventSave') => void;

const props = withDefaults(defineProps<TProps>(), {});
const emit = defineEmits<TEmit>();

const receivedAt = computed(() => {
  return formatDate(props.item.receiveAt);
});

const handleBeforeMoveToLead = async () => {
  const api = new ReceivedMailApiService();
  await api.postReceivedMailClick({
    request: {
      receivedMailId: props.item.id,
    },
  });
  emit('afterClickEventSave');
};
</script>

<style lang="scss" scoped>
.received-mail-notification-item {
  display: flex;
  color: $textcolor-base;
  text-decoration: none;
  padding: $basespace-200;
  height: 74px;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid $bdcolor-light;
  width: 100%;

  &:hover {
    background-color: $bgcolor-base;
  }
}
.date {
  color: $textcolor-light;
  font-size: $fontsize-200;
  width: 100px;
  min-width: 100px;
  margin: 0 $basespace-400 0 $basespace-200;
}
.company-name {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: $basespace-700;
}
.subject {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: $basespace-500;
}
</style>
