<template>
  <BColorPreview
    :color-code="colorCode"
    :size="size"
    @click="$emit('click')"
  />
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { TSize, TSymbolColor } from '../../types';
import leadStageColors from './lead_stage_colors';

type TProps = {
  size?: TSize;
  value: TSymbolColor;
};

const props = defineProps<TProps>();
defineEmits(['click']);

const colorCode = computed(() => leadStageColors.find(c => c.value === props.value)?.colorCode);
</script>
