import ApiService from '@/api/api_service';

export default class PollingApiService extends ApiService {
  defaultErrorHandlers = {
    '000' () { },
    '400' () { },
    '401' () { },
    '403' () { },
    '404' () { },
    '406' () { },
    '422' () { },
    '500' () { },
    '503' () { },
  };
}
