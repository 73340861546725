import store from '@/store/index';
import { TCallConnectedEventData, TCallEndedEventData, TCallRingingEventData, TEventData } from './zoom_phone_types';

// 設定系

const zoomPhoneContainer = () => document.querySelector<HTMLIFrameElement>('iframe#zoom-embeddable-phone-iframe');
const zoomPhone = () => zoomPhoneContainer().contentWindow;

const initZoomPhone = (): void => {
  window.removeEventListener('message', handleEvents);
  window.addEventListener('message', handleEvents);
};

/**
 * 発着信
 */
const handleCallRinging = (data: TCallRingingEventData): void => {
  const { callId: id, direction, dateTime: startedAt } = data;
  store.dispatch('zoomPhone/setCallStartedInfoAction', { id, direction, startedAt });
};

/**
 * call connected
 */
const handleCallConnected = (data: TCallConnectedEventData): void => {
  const { callId: id, direction, dateTime: connectedAt } = data;
  store.dispatch('zoomPhone/setCallConnectedInfoAction', { id, direction, connectedAt });
};

/**
 * call終了
 */
const handleCallEnded = (data: TCallEndedEventData): void => {
  const { callId: id, direction, dateTime: endedAt, result } = data;
  store.dispatch('zoomPhone/setCallEndedInfoAction', { id, direction, endedAt, result });
};

/**
 * event handle（分岐）
 */
const handleEvents = (e: MessageEvent<TEventData>) => {
  if (!e.data) return;

  const { type, data } = e.data;
  switch (type) { 
    case 'zp-call-ringing-event': // 発信・着信時
      handleCallRinging(data as TCallRingingEventData);
      break;
    case 'zp-call-connected-event': // call connected
      handleCallConnected(data as TCallConnectedEventData);
      break;
    case 'zp-call-ended-event': // call終了
      handleCallEnded(data as TCallEndedEventData);
      break;
  }
};

// 呼び出し

/**
 * 発信
 */
const makeCall = (phoneNumber: string): void => {
  // NOTE: この時点で「通話中」にする
  store.dispatch('zoomPhone/setOutboundCallingAction');

  zoomPhone().postMessage({
    type: 'zp-make-call',
    data: { number: phoneNumber },
  }, 'https://applications.zoom.us');
};

export {
  initZoomPhone,
  makeCall,
};
