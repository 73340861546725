<template>
  <BLayout align-center>
    <Search />
    <Reminder class="mr-50" />
    <ReservedMailReminder class="mr-50" />
    <MailNotification class="mr-50" />
    <DraftMailReminder class="mr-50" />
    <ReceivedMailNotification />
    <TriggerRequireApprovalNotification v-if="isAvailableTriggerFeature" />
  </BLayout>
</template>

<script lang="ts" setup>
import DraftMailReminder from '@/components/organisms/DraftMailReminder.vue';
import MailNotification from '@/components/organisms/MailNotification.vue';
import ReceivedMailNotification from '@/components/organisms/ReceivedMailNotification.vue';
import Reminder from '@/components/organisms/Reminder.vue';
import ReservedMailReminder from '@/components/organisms/ReservedMailReminder.vue';
import Search from '@/components/organisms/user/general/Search.vue';
import { useAvailableFeatureCheck } from '@/composable/available-feature-check';
import TriggerRequireApprovalNotification from './TriggerRequireApprovalNotification.vue';

const { isAvailableTriggerFeature } = useAvailableFeatureCheck();
</script>
