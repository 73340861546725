import ApiBase from '@/api/base';

export default {
  getOpportunities (args) {
    const { callTargetId } = args;
    return ApiBase.get(`/user/call_targets/${callTargetId}/opportunities`);
  },
  createOpportunity (args) {
    const { callTargetId } = args;
    return ApiBase.post(`/user/call_targets/${callTargetId}/opportunities`, args);
  },
  updateOpportunity (args) {
    const { opportunityId } = args;
    return ApiBase.put(`/user/opportunities/${opportunityId}`, args);
  },
  deleteOpportunity (args) {
    const { opportunityId } = args;
    return ApiBase.delete(`/user/opportunities/${opportunityId}`, args);
  },
};
