import ApiService from '@/api/api_service';
import {
  CallTargetSfReferenceInfoApi,
} from '@/api/openapi';

export class SfCallTargetReferenceService extends ApiService {
  api = new CallTargetSfReferenceInfoApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    // openapiで作ったやつはtrue
    super(true);
  }

  async getCallTargetSfReferenceInfo(args) {
    return this.api.getCallTargetSfReferenceInfo(args);
  }

}
