import Api from '@/api/admin';
import Waiting from '@/store/waiting';
import { types } from '../mutations';

const actions = {
  async getClientsAction ({ commit }, args) {
    await waiting.check('getClientsAction', async () => {
      try {
        const res = await Api.getClients(args);
        commit(types.SET_CLIENTS, Object.values(res.data)[0]);
      } catch (err) {
        commit(types.CLEAR_CLIENTS);
        throw err;
      }
    });
  },
  async getClientAction ({ commit }, args) {
    await waiting.check('getClientAction', async () => {
      try {
        const res = await Api.getClient(args);
        commit(types.SET_CLIENT, Object.values(res.data)[0]);
      } catch (err) {
        commit(types.CLEAR_CLIENT);
        throw err;
      }
    });
  },
  async createClientAction ({ commit }, args) {
    await waiting.check('createClientAction', async () => {
      await Api.createClient(args);
      try {
        const res = await Api.getClients(args);
        commit(types.SET_CLIENTS, Object.values(res.data)[0]);
      } catch (err) {
        commit(types.CLEAR_CLIENTS);
        throw err;
      }
    });
  },
  // 単体Reload
  async updateClientAction ({ commit }, args) {
    return await waiting.check('updateClientAction', async () => {
      const result = await Api.updateClient(args);
      try {
        const res = await Api.getClient(args);
        commit(types.SET_CLIENT, Object.values(res.data)[0]);
      } catch (err) {
        commit(types.CLEAR_CLIENT);
        throw err;
      }
      return result;
    });
  },
  async deleteClientAction ({ commit }, args) {
    await waiting.check('deleteClientAction', async () => {
      await Api.deleteClient(args);
      try {
        const res = await Api.getClients(args);
        commit(types.SET_CLIENTS, Object.values(res.data)[0]);
      } catch (err) {
        commit(types.CLEAR_CLIENTS);
        throw err;
      }
    });
  },
};

const waiting = new Waiting(actions);

export default actions;
