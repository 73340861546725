import { AxiosResponse } from 'axios';
import ApiService, { ApiArguments } from '@/api/api_service';
import {
  LeadStage,
  LeadStageApi,
  LeadStageApiDeleteLeadStageRequest,
  LeadStageApiPostCallTargetLeadStageRequest,
  LeadStageApiPostLeadStageRequest,
  LeadStageApiPutLeadStageRequest,
} from '@/api/openapi';

export class LeadStageApiService extends ApiService {
  api = new LeadStageApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true);
  }

  async getLeadStages(
    apiArguments?: ApiArguments<undefined>,
  ): Promise<AxiosResponse<LeadStage[]>> {
    return this.checkResponse<LeadStage[]>(
      this.api.getLeadStages(apiArguments?.options),
      apiArguments?.errorHandlers,
    );
  }

  async createLeadStage({
    request,
    options,
    errorHandlers,
  }: ApiArguments<LeadStageApiPostLeadStageRequest>): Promise<AxiosResponse<LeadStage>> {
    return this.checkResponse<LeadStage>(
      this.api.postLeadStage(request, options),
      errorHandlers,
    );
  }

  async updateLeadStage({
    request,
    options,
    errorHandlers,
  }: ApiArguments<LeadStageApiPutLeadStageRequest>): Promise<AxiosResponse<LeadStage>> {
    return this.checkResponse<LeadStage>(
      this.api.putLeadStage(request, options),
      errorHandlers,
    );
  }

  async deleteLeadStage({
    request,
    options,
    errorHandlers,
  }: ApiArguments<LeadStageApiDeleteLeadStageRequest>): Promise<AxiosResponse<void>> {
    return this.checkResponse<void>(
      this.api.deleteLeadStage(request, options),
      errorHandlers,
    );
  }

  async postCallTargetLeadStage({
    request,
    options,
    errorHandlers,
  }: ApiArguments<LeadStageApiPostCallTargetLeadStageRequest>): Promise<AxiosResponse<void>> {
    return this.checkResponse<void>(
      this.api.postCallTargetLeadStage(request, options),
      errorHandlers,
    );
  }
}
