<template>
  <VWait
    :for="waitFor"
  >
    <div class="trigger">
      <div class="trigger-title">
        <div
          v-if="isDisplayOnly"
          class="my-50"
        >
          {{ triggerBody.name }}
        </div>
        <BEditableContent
          v-else
          v-model:editing="isNameEditing"
          :uneditable="!props.editable"
          :value="triggerBody.name"
          :placeholder="$t('trigger.name')"
          :max-length="80"
          size="small"
          settle-type="blur"
          required
          is-display-value-or-place-holder
          @check="handleUpdateName"
        />
      </div>
    </div>
  </VWait>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { TriggerBody } from '@/api/openapi';

type TProps = {
  waitFor: string;
  editable?: boolean;
  isDisplayOnly?: boolean;
};

const props = withDefaults(defineProps<TProps>(), {
  editable: true,
  isDisplayOnly: false,
});
const triggerBody = defineModel<TriggerBody>();

const isNameEditing = ref(false);

const handleUpdateName = async (name: string) => {
  isNameEditing.value = false;
  triggerBody.value.name = name;
};
</script>

<style lang="scss" scoped>
.trigger {
  @include m-fixed-width(100%);
  padding: 10px $basespace-600 10px;

  &-title {
    @include m-fixed-width(100%);

    :deep(.input-box) {
      width: 650px;
    }
  }
}
</style>
