/* tslint:disable */
/* eslint-disable */
/**
 * openapi
 * bales cloud
 *
 * The version of the OpenAPI document: 1.0
 * Contact: test@example.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
/**
 * CustomFieldsApi - axios parameter creator
 * @export
 */
export const CustomFieldsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * カスタム項目のインポートAPI
         * @summary カスタム項目のインポート
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customFieldsImport: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/custom_fields_import_excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomFieldsApi - functional programming interface
 * @export
 */
export const CustomFieldsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomFieldsApiAxiosParamCreator(configuration)
    return {
        /**
         * カスタム項目のインポートAPI
         * @summary カスタム項目のインポート
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customFieldsImport(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customFieldsImport(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomFieldsApi - factory interface
 * @export
 */
export const CustomFieldsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomFieldsApiFp(configuration)
    return {
        /**
         * カスタム項目のインポートAPI
         * @summary カスタム項目のインポート
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customFieldsImport(options?: any): AxiosPromise<void> {
            return localVarFp.customFieldsImport(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomFieldsApi - interface
 * @export
 * @interface CustomFieldsApi
 */
export interface CustomFieldsApiInterface {
    /**
     * カスタム項目のインポートAPI
     * @summary カスタム項目のインポート
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomFieldsApiInterface
     */
    customFieldsImport(options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * CustomFieldsApi - object-oriented interface
 * @export
 * @class CustomFieldsApi
 * @extends {BaseAPI}
 */
export class CustomFieldsApi extends BaseAPI implements CustomFieldsApiInterface {
    /**
     * カスタム項目のインポートAPI
     * @summary カスタム項目のインポート
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomFieldsApi
     */
    public customFieldsImport(options?: AxiosRequestConfig) {
        return CustomFieldsApiFp(this.configuration).customFieldsImport(options).then((request) => request(this.axios, this.basePath));
    }
}
