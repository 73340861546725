import { TObject } from '@/types';
import { hashToSha256 } from './hash-service';

/**
 * @examples
 * ** objectの場合
 * isSameObject(ojbA, ojbB)
 * ** interfaceの場合
 * isSameObject(ojbA as unknown as TObject, ojbB as unknown as TObject
 */
const isSameObject = async (a: TObject, b: TObject, convertEmptyValueToNull = false): Promise<boolean> => {
  let aHashPromise: Promise<string>, bHashPromise: Promise<string>;
  if (convertEmptyValueToNull) {
    // MEMO: NULLと空文字を同等の扱いにする
    aHashPromise = hashToSha256(stringifyReplacingEmptyToNull(a));
    bHashPromise = hashToSha256(stringifyReplacingEmptyToNull(b));
  } else {
    aHashPromise = hashToSha256(JSON.stringify(a));
    bHashPromise = hashToSha256(JSON.stringify(b));
  }
  const [aHash, bHash] = await Promise.all([aHashPromise, bHashPromise]);
  return aHash === bHash;
};

const stringifyReplacingEmptyToNull = (object: TObject): string => {
  const replacer = (_, value) => value === '' ? null : value;
  return JSON.stringify(object, replacer);
};

export {
  isSameObject,
};
