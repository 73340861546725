<template>
  <div
    class="b-avatar-text"
    :class="classes"
  >
    <BLayout align-center>
      <BAvatar
        class="mr-100"
        :size="avatarSize"
        :image-url="imageUrl"
        :name="text"
        :user-id="userId"
      />
      <div class="text-area">
        <div
          class="text"
          :class="textClasses"
        >
          {{ text }}
        </div>
        <div
          v-if="subText"
          class="sub-text"
          :class="subTextClasses"
        >
          {{ subText }}
        </div>
      </div>
    </BLayout>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
    imageUrl: String,
    small: Boolean,
    subText: {
      type: String,
      default: '',
    },
    truncate: Boolean,
    userId: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    classes () {
      return {
        'is-small': this.small,
      };
    },
    textClasses () {
      return {
        'truncate-text': this.truncate,
      };
    },
    subTextClasses () {
      return {
        'truncate-text': this.truncate,
      };
    },
    avatarSize () {
      if (this.small) {
        return 24;
      } else {
        return 36;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .b-avatar-text {
    .text {
      font-size: $fontsize-200;
    }

    .sub-text {
      font-size: $fontsize-100;
      color: $textcolor-light;
    }

    .text-area {
      width: calc(100% - 36px);
    }

    &.is-small {
      width: calc(100% - 24px);
    }
  }
</style>
