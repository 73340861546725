<template>
  <BContent
    v-loading="$wait.is(['getMailTemplatesWait'])"
    class="content"
  >
    <template #navbar>
      <div class="navbar-left">
        <span class="page-title fs-400 fw-bold">{{
          $t("mailTemplate.pageTitle")
        }}</span>
        <button
          class="create-btn"
          @click="showNewForm"
        >
          <BCustomIcon
            class="template-icon"
            icon-class="b-template"
          />
          <span class="ml-100">{{ $t("mailTemplate.create") }}</span>
        </button>
      </div>
    </template>
    <div class="mt-50">
      <MailTemplateList
        :mail-templates="mailTemplates"
        @click="showEditForm"
        @duplicate="showDuplicatedNewForm"
      />
      <MailFormTransition :is-visible="formVisible">
        <MailTemplateForm
          v-model:mail-template="mailTemplate"
          :is-creating="isCreating"
          @cancel="cancel"
          @close="close"
          @change="onChange"
          @duplicate="showDuplicatedNewForm"
          @mail-template-change="mailTemplate = $event"
        />
      </MailFormTransition>
      <OpenaiContentGenerate
        ref="openai"
        :set-content="setOpenAiContent"
      />
    </div>
  </BContent>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapWaitingActions } from 'vue-wait';
import { mapGetters } from 'vuex';
import OpenaiContentGenerate from '@/components/organisms/user/mail/bulk/drawer/state/form/OpenaiContentGenerate.vue';
import MailFormTransition from '@/components/organisms/user/mail/common/drawer/MailFormTransition.vue';
import { makeQuillContent } from '@/components/organisms/user/mail/common/drawer/state/form/QuillContentEditor.vue';
import MailTemplateForm from '@/components/organisms/user/mailTemplate/MailTemplateForm.vue';
import MailTemplateList from '@/components/organisms/user/mailTemplate/MailTemplateList.vue';
import Intercom from '@/mixins/intercom';
import { isSameObject } from '@/services/object-compare-service';

export default defineComponent({
  name: 'MailTemplate',
  components: {
    MailTemplateList,
    MailTemplateForm,
    MailFormTransition,
    OpenaiContentGenerate,
  },
  mixins: [Intercom],
  async beforeRouteLeave(to, from, next) {
    const confirmResult = await this.showConfirm();
    if (!confirmResult) return false;
    next();
  },
  setup(props) {
    return { ...Intercom.setup(props) };
  },
  data() {
    return {
      formVisible: false,
      isCreating: true,
      mailTemplate: null,
      originalMailTemplate: null,
    };
  },
  computed: {
    ...mapGetters('user', ['mailTemplates']),
  },
  created() {
    this.getMailTemplatesAction();
  },
  methods: {
    ...mapWaitingActions('user', {
      getMailTemplatesAction: 'getMailTemplatesWait',
    }),
    async showNewForm() {
      this.isCreating = true;
      this.showForm(null);
    },
    async showDuplicatedNewForm(mailTemplate) {
      this.isCreating = true;
      this.showForm(mailTemplate);
    },
    async showEditForm(mailTemplate) {
      this.isCreating = false;
      this.showForm(mailTemplate);
    },
    async showForm(mailTemplate) {
      const confirmResult = await this.showConfirm();
      if (!confirmResult) return;

      this.hideForm();
      this.mailTemplate = JSON.parse(JSON.stringify(mailTemplate));
      this.originalMailTemplate = JSON.parse(JSON.stringify(mailTemplate));
      setTimeout(() => this.formVisible = true, 200);
    },
    async cancel() {
      const confirmResult = await this.showConfirm();
      if (!confirmResult) return false;
      this.$refs.openai.cancel();
      this.hideForm();
    },
    close() {
      this.hideForm();
    },
    hideForm() {
      this.mailTemplate = null;
      this.originalMailTemplate = null;
      this.formVisible = false;
    },
    async showConfirm() {
      let result = true;
      const isSame = await isSameObject(this.originalMailTemplate, this.mailTemplate, true);
      if (!isSame) {
        result = await this.$bitterAlert.show({
          text: this.$t('mail.confirmShowForm'),
        });
      }
      return result;
    },
    onChange() {
      this.originalMailTemplate = JSON.parse(JSON.stringify(this.mailTemplate));
      this.getMailTemplatesAction();
    },
    setOpenAiContent(data) {
      this.mailTemplate.content = makeQuillContent(data);
    },
  },
});
</script>

<style lang="scss" scoped>
.navbar-left {
  display: flex;
  height: 100%;
  margin-top: 3px;
  .create-btn {
    position: relative;
    width: 220px;
    height: 37px;
    margin-top: 5px;
    margin-left: 30px;
    color: $textcolor-white;
    background-color: $basecolor-primary;
    border-radius: 20px;
    outline: none;
    .template-icon {
      position: absolute;
      top: 10px;
      left: 15px;
      color: $textcolor-white;
    }
  }
  .page-title {
    display: block;
    margin-top: 12px;
  }
}

.content {
  position: relative;
}
</style>
