<template>
  <BModal
    :modal-name="modalName"
    :delegate="bModalDelegate"
    width="650px"
    height="385px"
    @open-modal-event="handleOpen"
  >
    <BHeightAdjuster>
      <BModalHeader
        center
        class="modal-header"
        :title="$t('callTarget.sfCheckLead.headerTitle')"
        @modal-close="handleClose"
      />
      <BModalBody
        v-if="!completed"
        v-loading="
          $wait.is('getCallTargetsCountWait') ||
            $wait.is('requestSfLeadDataBulkCheckTaskWait')
        "
        class="modal-body"
      >
        <div class="message mt-300 mb-400">
          <span>{{ $t('callTarget.sfCheckLead.message') }}</span>
        </div>
        <div class="count">
          <p>
            {{ $t('callTarget.sfCheckLead.count') }}
            <span class="target">{{
              `${target}${$t('callTarget.sfCheckLead.unit')}`
            }}</span>
            / {{ `${callTargetsCount}${$t('callTarget.sfCheckLead.unit')}` }}
          </p>
        </div>
        <div class="btn-group mb-600">
          <p>{{ $t(`callTarget.sfCheckLead.stausSelect`) }}</p>
          <BRadio
            v-model="checkStatus"
            class="mr-600"
            label="0"
          >
            {{ $t(`callTarget.sfCheckLead.status.unsynced`) }}
          </BRadio>
          <BRadio
            v-model="checkStatus"
            label="2"
          >
            {{ $t(`callTarget.sfCheckLead.status.syncFailed`) }}
          </BRadio>
        </div>
        <BBtn
          type="primary"
          :loading="
            $wait.is('getCallTargetsCountWait') ||
              $wait.is('requestSfLeadDataBulkCheckTaskWait')
          "
          @click="handleSync"
        >
          {{ $t('general.sync.do') }}
        </BBtn>
      </BModalBody>
      <BModalBody
        v-else
        class="modal-body"
      >
        <p class="completed-text">
          {{ $t('callTarget.sfCheckLead.completed') }}
        </p>
      </BModalBody>
    </BHeightAdjuster>
  </BModal>
</template>

<script>
import { mapWaitingActions } from 'vue-wait';
import { mapGetters } from 'vuex';

export default {
  name: 'SfLeadDataCheckModal',
  data() {
    return {
      checkStatus: '0',
      completed: false,
      bModalDelegate: {
        beforeClose: this.beforeClose,
      },
    };
  },
  computed: {
    ...mapGetters('user', [
      'callTargets',
      'callTargetsCount',
      'callTargetsMeta',
    ]),
    ...mapGetters('userUi', ['leadListFilterParams']),
    modalName() {
      return 'SfLeadDataCheckModal';
    },
    target() {
      return this.callTargetsMeta.totalCount;
    },
  },
  methods: {
    ...mapWaitingActions('user', {
      getCallTargetsCountAction: 'getCallTargetsCountWait',
      requestSfLeadDataBulkCheckTaskAction: 'requestSfLeadDataBulkCheckTaskWait',
      getSfBulkCheckLeadDataTasksAction: 'getSfBulkCheckLeadDataTasksWait',
    }),
    async handleOpen() {
      await this.getCallTargetsCountAction();
    },
    beforeClose() {
      this.completed = false;
    },
    handleClose() {
      this.$modal.hide(this.modalName);
    },
    async handleSync() {
      this.requestSfLeadDataBulkCheckTaskAction({
        body: {
          lf: this.leadListFilterParams,
          status: this.checkStatus,
        },
      })
        .then((res) => {
          this.getSfBulkCheckLeadDataTasksAction();
          this.completed = true;
        })
        .catch((e) => {
          this.$bitterAlert.show({
            title: this.$t('general.error'),
            text: this.$t('apiError.internalServerError'),
            buttonsCancel: false,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  .modal-body {
    text-align: center;
  }

  .count {
    background-color: $concrete-light;
    border-radius: $basespace-100;
    p {
      font-size: larger;
      padding: 14px 0;
    }
  }

  .target {
    font-weight: bolder;
  }

  .caution {
    margin-bottom: 0px;
    color: $charcoal;
  }

  .completed-text {
    margin-top: 35px;
    font-size: larger;
    white-space: pre;
    line-height: 3;
  }
</style>
