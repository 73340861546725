/* tslint:disable */
/* eslint-disable */
/**
 * openapi
 * bales cloud
 *
 * The version of the OpenAPI document: 1.0
 * Contact: test@example.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { MailTemplate } from '../models';
// @ts-ignore
import { MailTemplateBody } from '../models';
/**
 * MailTemplateApi - axios parameter creator
 * @export
 */
export const MailTemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * メールテンプレート削除API
         * @summary メールテンプレート削除API
         * @param {number} mailTemplateId メールテンプレートID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMailTemplate: async (mailTemplateId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailTemplateId' is not null or undefined
            assertParamExists('deleteMailTemplate', 'mailTemplateId', mailTemplateId)
            const localVarPath = `/api/user/mail_templates/{mail_template_id}`
                .replace(`{${"mail_template_id"}}`, encodeURIComponent(String(mailTemplateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * メールテンプレート取得API
         * @summary メールテンプレート取得API
         * @param {number} mailTemplateId メールテンプレートID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailTemplate: async (mailTemplateId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailTemplateId' is not null or undefined
            assertParamExists('getMailTemplate', 'mailTemplateId', mailTemplateId)
            const localVarPath = `/api/user/mail_templates/{mail_template_id}`
                .replace(`{${"mail_template_id"}}`, encodeURIComponent(String(mailTemplateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * メールテンプレート一覧取得API
         * @summary メールテンプレート一覧取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailTemplates: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/mail_templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * メールテンプレート作成API
         * @summary メールテンプレート作成API
         * @param {MailTemplateBody} [mailTemplateBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMailTemplate: async (mailTemplateBody?: MailTemplateBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/mail_templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mailTemplateBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * メールテンプレート更新API
         * @summary メールテンプレート更新API
         * @param {number} mailTemplateId メールテンプレートID
         * @param {MailTemplateBody} [mailTemplateBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putMailTemplate: async (mailTemplateId: number, mailTemplateBody?: MailTemplateBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailTemplateId' is not null or undefined
            assertParamExists('putMailTemplate', 'mailTemplateId', mailTemplateId)
            const localVarPath = `/api/user/mail_templates/{mail_template_id}`
                .replace(`{${"mail_template_id"}}`, encodeURIComponent(String(mailTemplateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mailTemplateBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MailTemplateApi - functional programming interface
 * @export
 */
export const MailTemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MailTemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * メールテンプレート削除API
         * @summary メールテンプレート削除API
         * @param {number} mailTemplateId メールテンプレートID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMailTemplate(mailTemplateId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMailTemplate(mailTemplateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * メールテンプレート取得API
         * @summary メールテンプレート取得API
         * @param {number} mailTemplateId メールテンプレートID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMailTemplate(mailTemplateId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMailTemplate(mailTemplateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * メールテンプレート一覧取得API
         * @summary メールテンプレート一覧取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMailTemplates(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MailTemplate>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMailTemplates(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * メールテンプレート作成API
         * @summary メールテンプレート作成API
         * @param {MailTemplateBody} [mailTemplateBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postMailTemplate(mailTemplateBody?: MailTemplateBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postMailTemplate(mailTemplateBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * メールテンプレート更新API
         * @summary メールテンプレート更新API
         * @param {number} mailTemplateId メールテンプレートID
         * @param {MailTemplateBody} [mailTemplateBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putMailTemplate(mailTemplateId: number, mailTemplateBody?: MailTemplateBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putMailTemplate(mailTemplateId, mailTemplateBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MailTemplateApi - factory interface
 * @export
 */
export const MailTemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MailTemplateApiFp(configuration)
    return {
        /**
         * メールテンプレート削除API
         * @summary メールテンプレート削除API
         * @param {number} mailTemplateId メールテンプレートID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMailTemplate(mailTemplateId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteMailTemplate(mailTemplateId, options).then((request) => request(axios, basePath));
        },
        /**
         * メールテンプレート取得API
         * @summary メールテンプレート取得API
         * @param {number} mailTemplateId メールテンプレートID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailTemplate(mailTemplateId: number, options?: any): AxiosPromise<MailTemplate> {
            return localVarFp.getMailTemplate(mailTemplateId, options).then((request) => request(axios, basePath));
        },
        /**
         * メールテンプレート一覧取得API
         * @summary メールテンプレート一覧取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailTemplates(options?: any): AxiosPromise<Array<MailTemplate>> {
            return localVarFp.getMailTemplates(options).then((request) => request(axios, basePath));
        },
        /**
         * メールテンプレート作成API
         * @summary メールテンプレート作成API
         * @param {MailTemplateBody} [mailTemplateBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMailTemplate(mailTemplateBody?: MailTemplateBody, options?: any): AxiosPromise<MailTemplate> {
            return localVarFp.postMailTemplate(mailTemplateBody, options).then((request) => request(axios, basePath));
        },
        /**
         * メールテンプレート更新API
         * @summary メールテンプレート更新API
         * @param {number} mailTemplateId メールテンプレートID
         * @param {MailTemplateBody} [mailTemplateBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putMailTemplate(mailTemplateId: number, mailTemplateBody?: MailTemplateBody, options?: any): AxiosPromise<void> {
            return localVarFp.putMailTemplate(mailTemplateId, mailTemplateBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MailTemplateApi - interface
 * @export
 * @interface MailTemplateApi
 */
export interface MailTemplateApiInterface {
    /**
     * メールテンプレート削除API
     * @summary メールテンプレート削除API
     * @param {number} mailTemplateId メールテンプレートID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailTemplateApiInterface
     */
    deleteMailTemplate(mailTemplateId: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * メールテンプレート取得API
     * @summary メールテンプレート取得API
     * @param {number} mailTemplateId メールテンプレートID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailTemplateApiInterface
     */
    getMailTemplate(mailTemplateId: number, options?: AxiosRequestConfig): AxiosPromise<MailTemplate>;

    /**
     * メールテンプレート一覧取得API
     * @summary メールテンプレート一覧取得API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailTemplateApiInterface
     */
    getMailTemplates(options?: AxiosRequestConfig): AxiosPromise<Array<MailTemplate>>;

    /**
     * メールテンプレート作成API
     * @summary メールテンプレート作成API
     * @param {MailTemplateBody} [mailTemplateBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailTemplateApiInterface
     */
    postMailTemplate(mailTemplateBody?: MailTemplateBody, options?: AxiosRequestConfig): AxiosPromise<MailTemplate>;

    /**
     * メールテンプレート更新API
     * @summary メールテンプレート更新API
     * @param {number} mailTemplateId メールテンプレートID
     * @param {MailTemplateBody} [mailTemplateBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailTemplateApiInterface
     */
    putMailTemplate(mailTemplateId: number, mailTemplateBody?: MailTemplateBody, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * Request parameters for deleteMailTemplate operation in MailTemplateApi.
 * @export
 * @interface MailTemplateApiDeleteMailTemplateRequest
 */
export interface MailTemplateApiDeleteMailTemplateRequest {
    /**
     * メールテンプレートID
     * @type {number}
     * @memberof MailTemplateApiDeleteMailTemplate
     */
    readonly mailTemplateId: number
}

/**
 * Request parameters for getMailTemplate operation in MailTemplateApi.
 * @export
 * @interface MailTemplateApiGetMailTemplateRequest
 */
export interface MailTemplateApiGetMailTemplateRequest {
    /**
     * メールテンプレートID
     * @type {number}
     * @memberof MailTemplateApiGetMailTemplate
     */
    readonly mailTemplateId: number
}

/**
 * Request parameters for postMailTemplate operation in MailTemplateApi.
 * @export
 * @interface MailTemplateApiPostMailTemplateRequest
 */
export interface MailTemplateApiPostMailTemplateRequest {
    /**
     * 
     * @type {MailTemplateBody}
     * @memberof MailTemplateApiPostMailTemplate
     */
    readonly mailTemplateBody?: MailTemplateBody
}

/**
 * Request parameters for putMailTemplate operation in MailTemplateApi.
 * @export
 * @interface MailTemplateApiPutMailTemplateRequest
 */
export interface MailTemplateApiPutMailTemplateRequest {
    /**
     * メールテンプレートID
     * @type {number}
     * @memberof MailTemplateApiPutMailTemplate
     */
    readonly mailTemplateId: number

    /**
     * 
     * @type {MailTemplateBody}
     * @memberof MailTemplateApiPutMailTemplate
     */
    readonly mailTemplateBody?: MailTemplateBody
}

/**
 * MailTemplateApi - object-oriented interface
 * @export
 * @class MailTemplateApi
 * @extends {BaseAPI}
 */
export class MailTemplateApi extends BaseAPI implements MailTemplateApiInterface {
    /**
     * メールテンプレート削除API
     * @summary メールテンプレート削除API
     * @param {MailTemplateApiDeleteMailTemplateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailTemplateApi
     */
    public deleteMailTemplate(requestParameters: MailTemplateApiDeleteMailTemplateRequest, options?: AxiosRequestConfig) {
        return MailTemplateApiFp(this.configuration).deleteMailTemplate(requestParameters.mailTemplateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * メールテンプレート取得API
     * @summary メールテンプレート取得API
     * @param {MailTemplateApiGetMailTemplateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailTemplateApi
     */
    public getMailTemplate(requestParameters: MailTemplateApiGetMailTemplateRequest, options?: AxiosRequestConfig) {
        return MailTemplateApiFp(this.configuration).getMailTemplate(requestParameters.mailTemplateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * メールテンプレート一覧取得API
     * @summary メールテンプレート一覧取得API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailTemplateApi
     */
    public getMailTemplates(options?: AxiosRequestConfig) {
        return MailTemplateApiFp(this.configuration).getMailTemplates(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * メールテンプレート作成API
     * @summary メールテンプレート作成API
     * @param {MailTemplateApiPostMailTemplateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailTemplateApi
     */
    public postMailTemplate(requestParameters: MailTemplateApiPostMailTemplateRequest = {}, options?: AxiosRequestConfig) {
        return MailTemplateApiFp(this.configuration).postMailTemplate(requestParameters.mailTemplateBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * メールテンプレート更新API
     * @summary メールテンプレート更新API
     * @param {MailTemplateApiPutMailTemplateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailTemplateApi
     */
    public putMailTemplate(requestParameters: MailTemplateApiPutMailTemplateRequest, options?: AxiosRequestConfig) {
        return MailTemplateApiFp(this.configuration).putMailTemplate(requestParameters.mailTemplateId, requestParameters.mailTemplateBody, options).then((request) => request(this.axios, this.basePath));
    }
}
