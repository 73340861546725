<template>
  <div class="b-btn-set">
    <BBtn
      class="mr-50"
      size="small"
      fab
      flat
      :disabled="disabledCheck"
      :loading="loading"
      @click="$emit('check')"
    >
      <BIcon
        class="check-icon"
        size="small"
      >
        check
      </BIcon>
    </BBtn>
    <BBtn
      size="small"
      fab
      flat
      :disabled="disabledClose || loading"
      @click="$emit('close')"
    >
      <BIcon
        class="close-icon"
        size="small"
      >
        close
      </BIcon>
    </BBtn>
  </div>
</template>

<script>
export default {
  name: 'BBtnSet',
  props: {
    disabledClose: Boolean,
    disabledCheck: Boolean,
    loading: Boolean,
  },
  emits: [
    'check',
    'close',
  ],
};
</script>

<style lang="scss" scoped>
  .b-btn-set {
    display: flex;
    align-items: center;
  }

  .check-icon {
    color: $basecolor-primary;
  }

  .close-icon {
    color: $textcolor-light;
  }
</style>
