<template>
  <BContentBox v-loading="$wait.is('fetchSequenceExecutionSetting')">
    <template #content>
      <div class="setting-title">
        {{ $t('sequenceSetting.executionType.title') }}
      </div>
      <div class="explain">
        {{ $t('sequenceSetting.executionType.explain') }}
      </div>
      <div
        v-for="item in executionTypes"
        :key="item.label"
        class="mb-100"
      >
        <BRadio
          v-model="executionTypeValue"
          :label="item.label"
        >
          {{ item.text }}
        </BRadio>
      </div>
      <BListItem class="mt-400">
        <BBtn
          :disabled="!isValid"
          type="primary"
          :loading="$wait.is('patchSequenceExecutionSetting')"
          @click="handleSaveExecutionSettingButtonClick"
        >
          {{ $t('general.save.text') }}
        </BBtn>
      </BListItem>
    </template>
  </BContentBox>
</template>

<script setup lang="ts">
import { camelCase } from 'lodash';
import { computed, inject, onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { SequenceExecutionSettingSequenceExecutionTypeEnum } from '@/api/openapi';
import { SequenceSettingApiService } from '@/api/user/resources/sequence_setting';
import { useWait } from '@/composable/vue-wait';
import { KEY_OF_TOAST } from '@/injection-keys';

const i18n = useI18n();
const { doActionWithWait } = useWait();
const toast = inject(KEY_OF_TOAST);
const api = new SequenceSettingApiService();

const executionTypes = Object.values(SequenceExecutionSettingSequenceExecutionTypeEnum).map(label => ({
  label,
  text: i18n.t(`sequenceSetting.executionType.executionTypes.${camelCase(label)}`),
}));

const executionTypeValue = ref<SequenceExecutionSettingSequenceExecutionTypeEnum>('only_on_weekdays');
const isValid = computed(() => executionTypeValue.value != null);

const fetchSequenceExecutionSetting = () => {
  doActionWithWait('fetchSequenceExecutionSetting', async () => {
    const { data: { sequenceExecutionSetting } } = await api.getSequenceExecutionSetting();
    executionTypeValue.value = sequenceExecutionSetting.sequenceExecutionType;
  });
};
onBeforeMount(async () => {
  await fetchSequenceExecutionSetting();
});

const handleSaveExecutionSettingButtonClick = () => {
  const api = new SequenceSettingApiService();
  doActionWithWait('patchSequenceExecutionSetting', async () => {
    await api.patchSequenceExecutionSetting({
      request: {
        sequenceExecutionSetting: {
          sequenceExecutionType: executionTypeValue.value,
        },
      },
    });
  });
  toast.show(
    i18n.t('general.done.withTarget', {
      action: i18n.t('general.save.text'),
      target: i18n.t('sequenceSetting.executionType.title'),
    }),
  );
};
</script>

<style lang="scss" scoped>
.setting-title {
  font-size: $fontsize-400;
  font-weight: bold;
  margin-bottom: $basespace-150;

  &:not(:first-child) {
    margin-top: 80px;
  }
}
.explain {
  color: $textcolor-light;
  white-space: pre-wrap;
  margin-bottom: $basespace-400;
}
.form {
  @media only screen and (min-width: 959px) {
    .w-input {
      width: 40%;
    }
  }
}
</style>
