<template>
  <div class="error-confirm">
    <BContainer>
      <div class="error-message mb-300">
        <span class="break-word-pre-wrap">{{ $t('import.errorMessage') }}</span>
      </div>
      <div
        v-if="importError != null"
        class="pt-200"
      >
        {{ $t('import.errors.systemColon') }} {{ importError }}
      </div>
      <ElTable
        v-if="importErrors != null"
        :data="importErrors"
        size="small"
      >
        <ElTableColumn type="expand">
          <template #default="props">
            <div
              v-for="[key, val] in Object.entries(props.row.record)"
              :key="key"
              class="row-data"
            >
              <BLayout
                :key="`layout-${key}`"
                align-center
              >
                <div
                  class="row-header"
                  :class="{ 'error-text': val.errors.length > 0 }"
                >
                  {{ val.header }}
                </div>
                <div :class="{ 'error-text': val.errors.length > 0 }">
                  {{ getPropertyFallBackAltText(val, 'value') }}
                </div>
              </BLayout>
            </div>
          </template>
        </ElTableColumn>
        <ElTableColumn
          v-for="(header, index) in importErrorTargetHeaders"
          :key="index"
          :label="header.label"
        >
          <template #default="props">
            <div>{{ getTargetTitle(props.row.record, header.prop) }}</div>
          </template>
        </ElTableColumn>
        <ElTableColumn :label="$t('import.errors.content')">
          <template #default="props">
            <div
              v-for="error in summaryErrors(props.row.record)"
              :key="error"
            >
              {{ error }}
            </div>
          </template>
        </ElTableColumn>
      </ElTable>
    </BContainer>
  </div>
</template>

<script>
import { altText } from '@/utils/alt';

export default {
  name: 'ErrorConfirm',
  props: {
    // モデル関連エラー
    importErrors: Array,
    // その他のエラー
    importError: String,
  },
  computed: {
    importErrorTargetHeaders () {
      return [
        { label: this.$t('import.columns.call_target_company_name'), prop: 'call_target_company_name' },
      ];
    },
  },
  methods: {
    // MEMO: レコードに存在するエラーを「会社名が不正です」のような形式に変更し収集
    summaryErrors (record) {
      return Object.values(record).flatMap(value => {
        return value.errors.map(e => `${value.header} - ${e}`);
      });
    },
    getPropertyFallBackAltText (obj, property) {
      return altText(obj?.[property]);
    },
    getTargetTitle(record, target) {
      return Object.values(record).find((r) => r.label === target)?.value || '-';
    },
  },
};
</script>

<style lang="scss" scoped>
  .error-confirm {
    padding-bottom: 70px;
  }

  .error-message {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $basecolor-error;
    font-size: $fontsize-200;
    padding: $basespace-200 $basespace-600;
    background-color: rgba($basecolor-error, 0.1);
    border-radius: 4px;
  }

  .row-data {
    padding: $basespace-100 0;
  }

  .row-header {
    color: $textcolor-light;
    @include m-fixed-width(200px);
    padding-right: $basespace-400;
  }

  .error-text {
    color: $basecolor-error;
  }
</style>
