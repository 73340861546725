<template>
  <div
    v-loading="$wait.is(['updateNextActionWait', 'getNextActionsWait', 'doneNextActionWait'])"
    class="draft-item-area"
  >
    <div
      v-for="directMail in draftItems"
      :key="`draft-${directMail.id}`"
      class="draft-item-task"
      @click="() => handleDirectMailConfirm(directMail)"
    >
      <DirectMail
        :direct-mail="directMail"
        @after-delete="handleDirectMailReload"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { DraftDirectMail } from '@/api/openapi';
import { isRelatedToSequence } from '@/composable/user/directMail/direct-mail';
import DirectMail from './task/DirectMail.vue';

// NOTE: directMailしか含まれていない前提の構造なので、objectの種類が増えた時は修正する
type TProps = {
  draftItems: DraftDirectMail[];
};
type TEmits = {
  'click:sequenceAction': [sequenceInstanceId: number];
  'click:directMailConfirm': [directMail: DraftDirectMail];
  'afterDeleteDirectMail': [];
};

defineProps<TProps>();

const emit = defineEmits<TEmits>();

const handleDirectMailConfirm = (directMail: DraftDirectMail) => {
  if (isRelatedToSequence(directMail)) {
    emit('click:sequenceAction', directMail.sequenceInstanceId);
  } else {
    emit('click:directMailConfirm', directMail);
  }
};
const handleDirectMailReload = () => { emit('afterDeleteDirectMail'); };
</script>
