<template>
  <BLayout
    class="loader"
    justify-center
  >
    <div v-loading="true" />
  </BLayout>
</template>

<script lang="ts" setup>
import { onMounted } from 'vue';
import { SisenseSSOApiService } from '@/api/user/resources/sisense';
import { useQueryParameter } from '@/composable/query-parameter';

const api = new SisenseSSOApiService();
const { param: returnTo } = useQueryParameter<string>('return_to');

onMounted(async () => {
  handleSSO();
});

const handleSSO = async () => {
  const { data } = await api.getSisenseSSOToken();
  let redirectUrl = `${import.meta.env.VITE_SISENSE_URL}/jwt?jwt=${data.token}`;
  // NOTE:
  // sso.newReturnToValidationBehavior (Sisense 設定) が ON の場合
  // 戻り先のドメインは allowed domains (Sisense 設定) で許可されたドメインであれば指定可能
  if (returnTo.value) {
    redirectUrl += `&return_to=${returnTo.value}`;
  }

  location.href = redirectUrl;
};
</script>

<style lang="scss" scoped>
.loader {
  position: fixed;
  width: 100%;
  height: 100%;
}
</style>
