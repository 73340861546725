<template>
  <div class="toast-container">
    <TransitionGroup
      name="toast"
      tag="div"
    >
      <template
        v-for="toast in toasts"
        :key="toast.id"
      >
        <ToastItem
          :message="toast.message"
          :title="toast.title"
          :type="toast.type"
        />
      </template>
    </TransitionGroup>
  </div>
</template>

<script lang="ts" setup>
import { Ref, inject } from 'vue';
import ToastItem from './ToastItem.vue';
import { TToastOptions } from './types';

const toasts = inject<Readonly<Ref<TToastOptions[]>>>('toasts');
</script>

<style lang="scss" scoped>
.toast-container {
  position: fixed;
  width: 250px;
  height:0;
  overflow:visible;
  top: $basespace-200;
  right: $basespace-400;
  z-index: 9999;
}

.toast-enter-active {
  transition: all 400ms;
}

.toast-leave-active {
  transition: all 300ms $decelerate-easing;
  position: absolute;
}

.toast-enter-from {
  opacity: 0;
  transform: translate3d(300px, 0, 0);
}

.toast-leave-to {
  opacity: 0;
}

.toast-move {
  transition: all 300ms $standard-easing;
}
</style>
