<template>
  <MailDrawerTemplate
    :type="type"
    :title="$t('mail.sending')"
    :width="width"
  >
    <template #drawerBody>
      <MailSending @complete="onSendingAnimationCompleted" />
    </template>
  </MailDrawerTemplate>
</template>

<script>
import MailDrawerTemplate from '@/components/organisms/user/mail/common/drawer/MailDrawerTemplate.vue';
import MailSending from '@/components/organisms/user/mail/common/drawer/state/sending/MailSending.vue';

export default {
  name: 'SendingDrawer',
  components: {
    MailDrawerTemplate,
    MailSending,
  },
  props: {
    type: {
      type: String,
      default: 'default',
    },
    width: {
      type: Number,
      default: 900,
    },
  },
  emits: [
    'sending-animation-completed',
  ],
  methods: {
    onSendingAnimationCompleted () {
      console.log('[SendingDrawer]');
      this.$emit('sending-animation-completed');
    },
  },
};
</script>
