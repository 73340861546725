import { computed, getCurrentInstance } from 'vue';
import { useI18n } from 'vue-i18n';
import { CallTarget } from '@/api/openapi';
import SalesforceConst from '@/const/salesforce';
import { useAvailableFeatureCheck } from './available-feature-check';
import { useAlert } from './message-dialog';

const useSfFeature = (modalName: string, getIsSfSyncValue: () => boolean) => {
  const { isAvailableSalesforceApiFeature } = useAvailableFeatureCheck();
  const isSfSync = computed<boolean>(() => getIsSfSyncValue() && isAvailableSalesforceApiFeature.value);
  const app = getCurrentInstance();
  const handleOpenModal = () => {
    app.appContext.config.globalProperties.$modal.show(modalName);
  };

  /**
   * @return boolean SF連携モーダルを開いたかどうか
   */
  const openSfModalIfNecessary = () => {
    if (isSfSync.value) {
      handleOpenModal();
      return true;
    }
    return false;
  };

  return {
    openSfModalIfNecessary,
    isSfSync,
  };
};

const useSfSync = (getCallTarget: () => CallTarget | null) => {
  const { alert } = useAlert();
  const i18n = useI18n();

  const isLeadSfSync = computed<boolean>(() =>
    // NOTE: CallTargetの型定義上sfRelatedLinkInfoは必ず存在するが、getCallTarget()で取れる値が空objectの場合があるためオプショナルチェーンを使用
    // @see src/store/user/mutations.js::[CLEAR_CALL_TARGET]()
    getCallTarget()?.sfRelatedLinkInfo?.sfRelatedLinks?.[0]?.status == SalesforceConst.Synced,
  );

  const canOpenCallResultFrom = computed<boolean>(() => {
    const sfSyncSettingInfo = getCallTarget()?.sfSyncSettingInfo;
    return sfSyncSettingInfo?.callResultSfSync === true && sfSyncSettingInfo?.sfUserId != null
      || sfSyncSettingInfo?.callResultSfSync !== true;
  });

  const validateCallResultSyncState = async () => {
    if (!canOpenCallResultFrom.value) {
      await alert({
        title: i18n.t('callTarget.errors.salesforceUnableToLinkUser.title'),
        message: i18n.t('callTarget.errors.salesforceUnableToLinkUser.text'),
      });
      return false;
    }
    return true;
  };

  return {
    isLeadSfSync,
    canOpenCallResultFrom,
    validateCallResultSyncState,
  };
};

export {
  useSfFeature,
  useSfSync,
};

