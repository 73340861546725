<template>
  <VWait
    for="fetchSequenceStepMasters"
    class="sequence-steps-container"
  >
    <EditableSequenceStepMasters
      :sequence-step-masters="(sequenceStepMasters as SequenceStepMaster[])"
      :sequence-owner-id="sequenceOwnerId"
      @update="handleUpdate"
      @update:changed="handleUpdateChanged"
    />
  </VWait>
</template>

<script lang="ts" setup>
import { SequenceStepMaster } from '@/api/openapi';
import { useSequenceStepMasters } from '@/composable/user/sequence/sequence-step-masters';
import EditableSequenceStepMasters from '../general/sequence/EditableSequenceStepMasters.vue';
import { TFormSequenceStep } from '../general/sequence/types';

type TProps = {
  sequenceMasterId: number;
  editable: boolean;
  sequenceOwnerId: number | undefined;
};
type TEmit = {
  (event: 'update', value: TFormSequenceStep[]): void;
  (e: 'update:changed', changed: boolean): void;
};

const props = defineProps<TProps>();
const emit = defineEmits<TEmit>();

const {
  sequenceStepMasters,
  fetchSequenceStepMasters,
} = useSequenceStepMasters(props.sequenceMasterId);
fetchSequenceStepMasters('fetchSequenceStepMasters');

const handleUpdate = (steps: TFormSequenceStep[]) => {
  emit('update', steps);
};
const handleUpdateChanged = (changed: boolean) => {
  emit('update:changed', changed);
};
</script>

<style scoped lang="scss">
.sequence-steps-container {
  height: 100%;
}
</style>
