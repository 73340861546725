import axios from 'axios';
import ApiBase from '@/api/base';

export default {
  testSend(args) {
    return ApiBase.post(`/user/mails/test_send`, args);
  },
  sendBulk(args) {
    return ApiBase.post(`/user/mails/send_bulk`, args);
  },
  reserveBulk(args) {
    return ApiBase.post(`/user/mails/reserve_bulk`, args);
  },
  sendDirect(args) {
    return ApiBase.post(`/user/mails/send_direct`, args);
  },
  generateContentByOpenai(args) {
    const { token, text } = args;
    const httpInstance = axios.create({
      baseURL: 'https://api.biscuet.jp',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
      },
      cancelToken: token,
    });
    return httpInstance.post(`/generate-text`, { query: text }, {
      timeout: 120000,
    });
  },
  generateContentByOpenSearch(args) {
    const { token, query } = args;
    const httpInstance = axios.create({
      baseURL: 'https://api.biscuet.jp',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
      },
      cancelToken: token,
    });
    return httpInstance.post(`/mail_generate`, { query }, {
      timeout: 120000,
    });
  },
  confirmBulkMailTask(args) {
    const { mailId } = args;
    return ApiBase.pollingGet(`/user/mail_tasks/confirm_bulk/${mailId}`, args);
  },
  mailLeadCount(args) {
    const { leadViewId } = args;
    return ApiBase.get(`/user/mails/mail_lead_count/${leadViewId}`, args);
  },
};
