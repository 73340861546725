<template>
  <div class="setting-title">
    {{ $t('senderEmail.title') }}
  </div>
  <div class="explain">
    {{ $t('senderEmail.explain') }}
  </div>
  <BLayout
    v-loading="$wait.is(['updateSenderEmail', 'updateReceiveMailAddressAsCc'])"
    column
    class="form"
  >
    <BListItem>
      <template #header>
        <span>{{ $t('user.senderEmail') }}</span>
      </template>
      <BInput
        v-model="inputSenderEmail"
        v-model:valid="senderEmailValid"
        class="w-input"
        :rules="[requiredRule, emailRule]"
        clearable
        :disabled="hasMailApiConnection"
      />
    </BListItem>
    <BListItem class="mt-200">
      <BCheckbox
        v-model="inputIsReceiveMailAddressAsCc"
        :label="$t('user.isReceiveMailAddressAsCc')"
        :disabled="hasMailApiConnection"
      />
    </BListItem>
    <BListItem class="mt-300">
      <BBtn
        type="primary"
        :disabled="!isSaveEnabled"
        @click="save"
      >
        {{ $t('general.save.text') }}
      </BBtn>
    </BListItem>
  </BLayout>
</template>

<script lang="ts" setup>
import { ref, computed, inject, watch } from 'vue';
import { User } from '@/api/openapi';
import { CurrentUserApiService } from '@/api/user/resources/current_user';
import { UserSenderEmailApiService } from '@/api/user/resources/user_sender_email';
import { useInputValidations } from '@/composable/input-validations';
import { useWait } from '@/composable/vue-wait';
import { i18nGlobal as i18n } from '@/i18n';
import { KEY_OF_TOAST } from '@/injection-keys';
import { bitterAlert } from '@/plugins/BBitterAlert';

type TProps = {
  user: User | null;
};
const props = defineProps<TProps>();
const emit = defineEmits(['update:user']);

const { doActionWithWait } = useWait();
const { requiredRule, emailRule } = useInputValidations();
const toast = inject(KEY_OF_TOAST);

const inputSenderEmail = ref<string | null>(null);
const savedSenderEmail = ref<string | null>(null);
const senderEmailValid = ref<boolean>(false);

const inputIsReceiveMailAddressAsCc = ref<boolean>(true);
const hasMailApiConnection = ref<boolean>(false);

const isSenderEmailChanged = computed(() => savedSenderEmail.value !== inputSenderEmail.value);
const isSaveEnabled = computed(() => !hasMailApiConnection.value && (!isSenderEmailChanged.value || senderEmailValid.value));

const setData = (user: User | null) => {
  if (user == null) return;

  const { senderEmail, isReceiveMailAddressAsCc, mailProvider } = user;
  const existsMailApiConnection = mailProvider !== 'sendgrid';
  // NOTE: senderEmailは「保存」時点ではDBの値が更新されない。入力値を保持するため、初回とメール連携状態変更時のみDBの値を設定する
  if (inputSenderEmail.value == null || hasMailApiConnection.value !== existsMailApiConnection) {
    savedSenderEmail.value = senderEmail;
    inputSenderEmail.value = senderEmail;
  }
  inputIsReceiveMailAddressAsCc.value = isReceiveMailAddressAsCc;
  hasMailApiConnection.value = existsMailApiConnection;
};

watch(() => props.user, (newVal) => {
  setData(newVal);
}, { immediate: true });

const save = async () => {
  await updateSenderEmail();
  await updateReceiveMailAddressAsCc();

  emit('update:user');
};

const userSenderEmailApi = new UserSenderEmailApiService();
const updateSenderEmail = async () => {
  if (!isSenderEmailChanged.value || !senderEmailValid.value) return;

  const ok = await bitterAlert.show({
    title: i18n.t('changeEmail.confirmModal.title'),
    text: i18n.t('changeEmail.confirmModal.explain', { itemName: i18n.t('senderEmail.title') }),
    buttonsCancel: i18n.t('general.cancel.text'),
    className: 'swal-modal-large',
  });
  if (!ok) return;

  await doActionWithWait('updateSenderEmail', async () => {
    await userSenderEmailApi.prepareUserSenderEmail({
      request: {
        userSenderEmailPrepareBody: {
          senderEmail: inputSenderEmail.value,
        },
      },
    });

    toast.show(
      i18n.t('general.done.withTarget', {
        action: i18n.t('general.send.text'),
        target: i18n.t('senderEmail.confirmationMail'),
      }),
    );

    savedSenderEmail.value = inputSenderEmail.value;
  });
};

const currentUserApi = new CurrentUserApiService();
const updateReceiveMailAddressAsCc = async () => {
  await doActionWithWait('updateReceiveMailAddressAsCc', async () => {
    await currentUserApi.updateCurrentUser({
      request: {
        putCurrentUserRequest: {
          isReceiveMailAddressAsCc: inputIsReceiveMailAddressAsCc.value,
        },
      },
    });
  });
};
</script>
