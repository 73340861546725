import ApiBase from '@/api/base';

export default {
  getClientAvailableFeatures (args) {
    const { clientId } = args;
    return ApiBase.get(`/admin/clients/${clientId}/available_features`, args);
  },
  updateClientAvailableFeature (args) {
    const { clientId } = args;
    return ApiBase.put(`/admin/clients/${clientId}/available_features`, args);
  },
};
