<template>
  <VWait
    :for="waitFor"
  >
    <EditableSequence
      :sequence-body="sequenceBody"
      :owner-name="sequenceMaster?.owner?.name"
      @update="handleUpdate"
    />
  </VWait>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { SequenceBody, SequenceMaster } from '@/api/openapi';
import { isSequenceChanged } from '@/composable/user/sequence/changed';
import EditableSequence from '../general/sequence/EditableSequence.vue';

type TProps = {
  sequenceMaster: SequenceMaster;
  waitFor: string;
};
type TEmit = {
  (e: 'update', sequenceBody: SequenceBody): void;
  (e: 'update:changed', changed: boolean): void;
};

const props = defineProps<TProps>();
const emit = defineEmits<TEmit>();

const sequenceBody = computed<SequenceBody>(() => ({
  name: props.sequenceMaster?.name ?? '',
  ownerId: props.sequenceMaster?.owner?.id ?? null,
}));

const handleUpdate = async (updatedValue: SequenceBody) => {
  emit('update:changed', await isSequenceChanged(sequenceBody.value, updatedValue));
  emit('update', updatedValue);
};
</script>
