import { StoreDataFetchManager } from '@/store/store-data-fetch-manager';
import Waiting from '@/store/waiting';

export default class CallTargetLeadSourceActions {
  constructor (Api, types) {
    const actions = {
      async getCallTargetLeadSourcesAction ({ commit }, args) {
        await waiting.checkWithArg('getCallTargetLeadSourcesAction', args, async () => {
          try {
            const res = await storeDataFetchManager.fetch('getCallTargetLeadSourcesAction', async () => await Api.getCallTargetLeadSources(args));
            commit(types.SET_CALL_TARGET_LEAD_SOURCES, Object.values(res.data)[0]);
          } catch (err) {
            commit(types.CLEAR_CALL_TARGET_LEAD_SOURCES);
            throw err;
          }
        });
      },
      async appendCallTargetLeadSourceAction ({ commit }, args) {
        await waiting.check('appendCallTargetLeadSourceAction', async () => {
          await Api.appendCallTargetLeadSource(args);
          try {
            const res = await Api.getCallTargetLeadSources(args);
            commit(types.SET_CALL_TARGET_LEAD_SOURCES, Object.values(res.data)[0]);
          } catch (err) {
            throw err;
          }
        });
      },
      async deleteCallTargetLeadSourceAction ({ commit }, args) {
        await waiting.check('deleteCallTargetLeadSourceAction', async () => {
          await Api.deleteCallTargetLeadSource(args);
          try {
            const res = await Api.getCallTargetLeadSources(args);
            commit(types.SET_CALL_TARGET_LEAD_SOURCES, Object.values(res.data)[0]);
          } catch (err) {
            throw err;
          }
        });
      },
    };
    const waiting = new Waiting(actions);
    const storeDataFetchManager = new StoreDataFetchManager();
    this.actions = actions;
  }
}
