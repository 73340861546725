<template>
  <div
    class="b-empty-box"
    :class="classes"
    @click="handleClick"
  >
    <BLayout
      align-center
      justify-center
    >
      <slot />
    </BLayout>
  </div>
</template>

<script>
export default {
  props: {
    displayOnly: Boolean,
  },
  emits: [
    'click',
  ],
  computed: {
    classes () {
      return {
        'is-display-only': this.displayOnly,
      };
    },
  },
  methods: {
    handleClick (e) {
      this.$emit('click', e);
    },
  },
};
</script>

<style lang="scss" scoped>
  .b-empty-box {
    color: $textcolor-light;
    border: 1px dashed $bdcolor-base;
    border-radius: 4px;
    transition: all 300ms $standard-easing;

    .b-icon {
      color: $textcolor-light;
    }

    &:not(.is-display-only) {
      cursor: pointer;
    }

    &:not(.is-display-only):hover {
      color: $textcolor-base;
      border-style: solid;
      border-color: $bdcolor-dark;

      .b-icon {
        color: $textcolor-base;
      }
    }
  }
</style>
