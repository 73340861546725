/* tslint:disable */
/* eslint-disable */
/**
 * openapi
 * bales cloud
 *
 * The version of the OpenAPI document: 1.0
 * Contact: test@example.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 優先度
 * @export
 * @interface Priority
 */
export interface Priority {
    /**
     * 
     * @type {string}
     * @memberof Priority
     */
    'priority': PriorityPriorityEnum;
}

export const PriorityPriorityEnum = {
    High: 'high',
    Middle: 'middle',
    Low: 'low',
    NotSet: 'not_set'
} as const;

export type PriorityPriorityEnum = typeof PriorityPriorityEnum[keyof typeof PriorityPriorityEnum];


