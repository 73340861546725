<template>
  <div>
    <div
      v-show="success != ''"
      class="notice success"
    >
      <span>{{ success }}</span>
      <BBtn
        text
        position="right"
        class="close"
      >
        <BIcon
          class="px-200"
          size="small"
          type="white"
          @click="success = ''"
        >
          close
        </BIcon>
      </BBtn>
    </div>
    <BContent
      class="content"
    >
      <template #navbar>
        <div class="navbar-left">
          <span class="page-title fs-400 fw-bold mr-400">{{ settingPageTitle }}</span>
        </div>
        <div
          v-show="page === 'settingPage'"
          class="navbar-right"
        >
          <BPagination
            v-model="currentPage"
            :length="allPageCount"
            :total-visible="5"
          />
        </div>
      </template>
      <template v-if="page === 'settingPage'">
        <div class="theader">
          <div class="table-row theader-row">
            <BSortText
              v-for="cell in headers"
              :key="cell.key"
              class="table-cell header-cell"
              :class="cell.class"
              :item-text="cell.name"
              is-display-only
            />
          </div>
        </div>
        <div class="tbody">
          <div
            v-for="(sfImportTask, index) in sfReportImportTasks"
            :key="index"
            class="table-row tbody-row"
          >
            <div
              v-for="column in headers"
              :key="column.key"
              class="table-cell"
              :class="column.class"
            >
              <template v-if="column.key === 'reportName'">
                <div class="sf-report">
                  {{ reportName[sfImportTask.sfReportId] }}
                  <BTooltip top>
                    <BIcon
                      class="pointer"
                      @click="() => open(sfImportTask.sfReportUrl)"
                    >
                      open_in_new
                    </BIcon>
                    <template #content>
                      <div>{{ $t('salesforceScheduleImport.sfLink') }}</div>
                    </template>
                  </BTooltip>
                </div>
              </template>
              <template v-if="column.key === 'status'">
                <div
                  v-if="sfImportTask.finishedAt == null"
                  class="status-area"
                >
                  <div>
                    <Icon class="import-icon import-icon-progress" />
                    {{ i18n.t('salesforceScheduleImport.list.import.progress') }}
                  </div>
                </div>
                <div
                  v-else-if="sfImportTask.errorData && sfImportTask.errorData.length >= 1"
                  class="status-area"
                >
                  <div>
                    <Icon class="import-icon import-icon-error" />
                    {{ i18n.t('salesforceScheduleImport.list.import.error') }}
                  </div>
                </div>
                <div
                  v-else
                  class="status-area"
                >
                  <div>
                    <Icon class="import-icon import-icon-success" />
                    {{ i18n.t('salesforceScheduleImport.list.import.success') }}
                  </div>
                </div>
              </template>
              <template v-if="column.key === 'errorMessage'">
                <div
                  v-if="Array.isArray(sfImportTask.errorData)"
                  class="error-message"
                >
                  <template v-for="data in sfImportTask.errorData">
                    <template v-for="(value, key) in data.record">
                      <span
                        v-for="error in value.errors"
                        :key="`${data.row_number}-${key}-${error}`"
                      >
                        <span v-show="data.row_number > 0">{{ data.row_number }}{{ i18n.t('salesforceScheduleImport.list.import.errorColumn') }}</span>{{ value.header }}{{ error }}。
                      </span>
                    </template>
                  </template>
                </div>
                <div
                  v-else-if="sfImportTask.singleErrorData"
                >
                  <span>{{ sfImportTask.singleErrorData }}</span>
                </div>
                <div
                  v-else-if="sfImportTask.covertErrorData"
                >
                  <div
                    v-for="(errorData, index) in sfImportTask.covertErrorData"
                    :key="`convert-${index}`"
                  >
                    <span>{{ errorData }}</span>
                  </div>
                </div>
                <div v-show="sfImportTask.errorData.length">
                  <BBtn
                    size="small"
                    outline
                    @click="() => clickErrorDetail(sfImportTask)"
                  >
                    {{ i18n.t('salesforceScheduleImport.list.import.errorDetail') }}
                  </BBtn>
                </div>
              </template>
              <template v-if="column.key === 'lastExecAt'">
                <div>
                  {{ formatShorterDateTime(sfImportTask.finishedAt) }}
                </div>
              </template>
              <template v-if="column.key === 'execSetting'">
                <BSelect
                  v-model="selectedScheduleImportMethod[sfImportTask.id]"
                  class="owner-select"
                  :items="selectExecSettingItems"
                  @change="() => sfReportImportSettingFunc(
                    sfImportTask.id,
                    selectedScheduleImportMethod[sfImportTask.id],
                    sfImportTask.sfReportId
                  )"
                />
              </template>
              <template v-if="column.key === 'owner'">
                <div>
                  <BAvatar
                    class="ml-100"
                    :size="36"
                    :image-url="sfImportTask.userAvatarUrl"
                    :name="sfImportTask.userName"
                  />
                </div>
              </template>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="page === 'errorReportPage'">
        <ErrorDetail
          :model-id="internalModelId"
          :import-errors="internalImportErrors"
          :import-error="internalImportError"
          :import-mapping-errors="internalMappingErrors"
          :sf-link="internalSfLink"
          @back="backAction"
        />
      </template>
    </BContent>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { PaginationMeta } from '@/api/openapi/models/pagination-meta';
import { SfReportImportTasks } from '@/api/openapi/models/sf-report-import-tasks';
import { SfReportImportSettingsService } from '@/api/user/resources/salesforce/sf_report_import_settings';
import { SfReportImportTaskService } from '@/api/user/resources/salesforce/sf_report_import_tasks';
import ErrorDetail from '@/components/organisms/user/setting/api/SalesforceScheduleImport/ErrorDetail.vue';
import { bitterAlert } from '@/plugins/BBitterAlert';
import { formatShorterDateTime } from '@/utils/date-time';

const i18n = useI18n();
const currentPage = ref(1);
const sfReportImportTasks = ref<SfReportImportTasks[]>([]);
const reportName = ref({});
const meta = ref<PaginationMeta>({
  totalPages: 1,
  totalCount: 0,
  limitValue: 0,
});
const sfApi = new SfReportImportSettingsService();

const settingPageTitle = ref(i18n.t('salesforceScheduleImport.title'));
const page = ref('settingPage');

const selectExecSettingItems = [
  { text: i18n.t('salesforceScheduleImport.selectExecSettings.stop'), value: 'disable' },
  { text: i18n.t('salesforceScheduleImport.selectExecSettings.start'), value: 'enable' },
  { text: i18n.t('salesforceScheduleImport.selectExecSettings.destory'), value: 'destory' },
];
const selectedScheduleImportMethod = ref([]);
const internalImportError = ref('');
const internalImportErrors = ref([]);
const internalMappingErrors = ref([]);
const internalSfLink = ref('');
const internalModelId = ref(0);
const success = ref('');

const allPageCount = computed(() => meta.value.totalPages);
const loadSfScheduleImport = async (currentPage: number) => {
  // リクエスト送るところ
  const api = new SfReportImportTaskService();
  const res = await api.allShowSfReportImportTasks({ request: { currentPage } });
  res.data.report.forEach((r) => {
    reportName.value[r.id] = r.name;
  });
  sfReportImportTasks.value = res.data.sfReportImportTasks;
  
  sfReportImportTasks.value.forEach((v) => {
    if (v.isEnabled) {
      selectedScheduleImportMethod.value[v.id] = 'enable';
    } else {
      selectedScheduleImportMethod.value[v.id] = 'disable';
    }
  });
  meta.value = res.data.meta;
};
watch(() => currentPage.value, (newValue) => {
  loadSfScheduleImport(newValue);
}, { immediate: true });

const open = (url: string) => {
  window.open(url, '_blank');
};

const sfReportImportSettingFunc = async (modelId: number, value: string, sfReportId: string) => {
  success.value = '';
  switch (value) {
    case 'disable': {
      const disableResult = await sfApi.sfReportImportSettingsDisable(
        { request: { sfReportImportSettingId: modelId } },
      );
      if (disableResult.status === 204) {
        success.value = '更新に成功しました';
      }
      break;
    }
    case 'enable': {
      const denableResult = await sfApi.sfReportImportSettingsEnable(
        { request: { sfReportImportSettingId: modelId } },
      );
      if (denableResult.status === 204) {
        success.value = '更新に成功しました';
      }
      loadSfScheduleImport(currentPage.value);
      break;
    }
    case 'destory': {
      const ok = await bitterAlert.show({
        closeOnClickOutside: true,
        title: i18n.t('salesforceScheduleImport.delete.title'),
        text: i18n.t('salesforceScheduleImport.delete.desc', { reportName: reportName.value[sfReportId] }),
      });
      if (ok) {
        await sfApi.sfReportImportSettingsDestory(
          { request: { sfReportImportSettingId: modelId } },
        );
        sfReportImportTasks.value = sfReportImportTasks.value.filter((v) => {
          return v.id !== modelId;
        });
      } else {
        await sfApi.sfReportImportSettingsDisable(
          { request: { sfReportImportSettingId: modelId } },
        );
        selectedScheduleImportMethod.value[modelId] = 'disable';
      }
      break;
    }
    default:
      console.error(`not found ${value}`);
  }
};

const headers = [
  { key: 'reportName', name: i18n.t('salesforceScheduleImport.list.reportName'), class: [] },
  { key: 'status', name: i18n.t('salesforceScheduleImport.list.status'), class: ['short-container'] },
  { key: 'errorMessage', name: i18n.t('salesforceScheduleImport.list.errorMessage'), class: ['long-container'] },
  { key: 'lastExecAt', name: i18n.t('salesforceScheduleImport.list.lastExecAt'), class: ['short-container'] },
  { key: 'execSetting', name: i18n.t('salesforceScheduleImport.list.execSetting'), class: ['mini-container'] },
  { key: 'owner', name: i18n.t('salesforceScheduleImport.list.owner'), class: ['mini-container'] },
];

const clickErrorDetail = (sfImportTask: SfReportImportTasks) => {
  internalImportError.value = sfImportTask.singleErrorData;
  internalImportErrors.value = sfImportTask.errorData;
  internalMappingErrors.value = sfImportTask.covertErrorData;
  internalModelId.value = sfImportTask.id;
  internalSfLink.value = sfImportTask.sfReportUrl;
  page.value = 'errorReportPage';
  settingPageTitle.value = reportName.value[sfImportTask.sfReportId];
};

const backAction = () => {
  page.value = 'settingPage';
  internalImportError.value = null;
  internalImportErrors.value = null;
  internalSfLink.value = null;
  settingPageTitle.value = i18n.t('salesforceScheduleImport.title');
};
</script>

<style lang="scss" scoped>
.content {
  margin-top: 12px !important;
  height: 100% !important;
  max-height: none !important;
  min-height: none !important;
}
.pointer {
  cursor: pointer;
}
.table-row {
  align-items: center;
  padding-right: $basespace-400;
  display: inline-flex;
  min-width: 100%;
}

.header-cell {
  justify-content: center;
}

.table-cell {
  @include m-fixed-width(240px);
  padding-right: $basespace-200;
  padding-left: 10px;
  background-color: white;
  text-overflow: ellipsis;
  overflow: hidden;
  &:first-child {
    padding-left: 30px;
  }
  &.short-container {
    @include m-fixed-width(160px);
  }
  &.mini-container {
    @include m-fixed-width(140px);
  }

  &.long-container {
    @include m-fixed-width(300px);
    left: 0;
    z-index: 1;
    min-height: 40px;
    flex-direction: column;
    > .error-message {
      margin-bottom: 8px;
      max-height: 120px;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }
}

.theader {
  height: 50px;
  position: sticky;
  top: 0;
  z-index: 2;
  cursor: text;
  &-row {
    height: inherit;
    background-color: white;
    border-bottom: 1px solid $bdcolor-base;
    padding-bottom: $basespace-100;
    color: $textcolor-light;
    font-size: $fontsize-100;
  }
}

.tbody {
  // BContentが内部で下記のような高さを取ってるので、110px分画面からはみ出てる
  // .content-wrapper.is-navbar-visible .content {
  //   height: calc(calc(100vh - 50px) - 60px);
  // }
  margin-bottom: 110px;
}

.tbody-row {
  border-bottom: 1px solid $bdcolor-base;
  padding-top: $basespace-200;
  padding-bottom: $basespace-200;
}

.import-icon {
  width: 12px;
  height: 12px;
  border-radius: 11px;
  padding: 4px;
  display: inline-block;
  &-success {
    background-color: #2CBFB7;
  }
  &-error {
    background-color: #FF6465;
  }
  &-progress {
    background-color: #f2ff64;
  }
}
.status-area {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  text-align: center;
}
.error-block {
  padding: 2px 8px;
  margin: 2px 0;
  background-color: #FFEEEE;
  text-align: center;
  color: #FF6465;
}

.sf-report {
  display: flex;
  justify-content: space-between;
}

.notice {
  position: relative;
  width: 100%;
  height: 60px;
  padding: 12px;
  margin-top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFF;
}

.close {
  position: absolute;
  right: 0;
}

.success {
  background-color: $basecolor-success;
}
</style>
