import { computed } from 'vue';
import { useStore } from 'vuex';
import { key } from '@/store/index';
import { TCtiType } from '@/types';

const useActiveCti = (skipFetching = false) => {
  const store = useStore(key);
  let promiseByFetch: Promise<unknown> | null = null;
  if (!skipFetching) {
    promiseByFetch = store.dispatch('user/getMiitelAuthAction');
  }
  const activeCti = computed<TCtiType>(() => store.getters['user/miitelEnabled'] ? 'miitel' : 'zoom');

  return {
    promiseByFetch,
    activeCti,
  };
};

export {
  useActiveCti,
};
