/* tslint:disable */
/* eslint-disable */
/**
 * openapi
 * bales cloud
 *
 * The version of the OpenAPI document: 1.0
 * Contact: test@example.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ConfirmLoginEmailUpdateBody } from '../models';
// @ts-ignore
import { CreateUserBody } from '../models';
// @ts-ignore
import { PrepareLoginEmailUpdateBody } from '../models';
// @ts-ignore
import { UpdateUserBody } from '../models';
/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ユーザー削除API
         * @summary ユーザー削除API
         * @param {number} userId ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUser', 'userId', userId)
            const localVarPath = `/api/user/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ログインメールアドレス確認API
         * @summary ログインメールアドレス確認API
         * @param {ConfirmLoginEmailUpdateBody} [confirmLoginEmailUpdateBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postConfirmLoginEmailUpdate: async (confirmLoginEmailUpdateBody?: ConfirmLoginEmailUpdateBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/confirm_login_email_update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(confirmLoginEmailUpdateBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ログインメールアドレス更新API
         * @summary ログインメールアドレス更新API
         * @param {PrepareLoginEmailUpdateBody} [prepareLoginEmailUpdateBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPrepareLoginEmailUpdate: async (prepareLoginEmailUpdateBody?: PrepareLoginEmailUpdateBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/prepare_login_email_update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(prepareLoginEmailUpdateBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザー作成API
         * @summary ユーザー作成API
         * @param {CreateUserBody} [createUserBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUser: async (createUserBody?: CreateUserBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザー更新API
         * @summary ユーザー更新API
         * @param {number} userId ユーザーID
         * @param {UpdateUserBody} [updateUserBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUser: async (userId: number, updateUserBody?: UpdateUserBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('putUser', 'userId', userId)
            const localVarPath = `/api/user/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * ユーザー削除API
         * @summary ユーザー削除API
         * @param {number} userId ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ログインメールアドレス確認API
         * @summary ログインメールアドレス確認API
         * @param {ConfirmLoginEmailUpdateBody} [confirmLoginEmailUpdateBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postConfirmLoginEmailUpdate(confirmLoginEmailUpdateBody?: ConfirmLoginEmailUpdateBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postConfirmLoginEmailUpdate(confirmLoginEmailUpdateBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ログインメールアドレス更新API
         * @summary ログインメールアドレス更新API
         * @param {PrepareLoginEmailUpdateBody} [prepareLoginEmailUpdateBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPrepareLoginEmailUpdate(prepareLoginEmailUpdateBody?: PrepareLoginEmailUpdateBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPrepareLoginEmailUpdate(prepareLoginEmailUpdateBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ユーザー作成API
         * @summary ユーザー作成API
         * @param {CreateUserBody} [createUserBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUser(createUserBody?: CreateUserBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUser(createUserBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ユーザー更新API
         * @summary ユーザー更新API
         * @param {number} userId ユーザーID
         * @param {UpdateUserBody} [updateUserBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putUser(userId: number, updateUserBody?: UpdateUserBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putUser(userId, updateUserBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * ユーザー削除API
         * @summary ユーザー削除API
         * @param {number} userId ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(userId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * ログインメールアドレス確認API
         * @summary ログインメールアドレス確認API
         * @param {ConfirmLoginEmailUpdateBody} [confirmLoginEmailUpdateBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postConfirmLoginEmailUpdate(confirmLoginEmailUpdateBody?: ConfirmLoginEmailUpdateBody, options?: any): AxiosPromise<void> {
            return localVarFp.postConfirmLoginEmailUpdate(confirmLoginEmailUpdateBody, options).then((request) => request(axios, basePath));
        },
        /**
         * ログインメールアドレス更新API
         * @summary ログインメールアドレス更新API
         * @param {PrepareLoginEmailUpdateBody} [prepareLoginEmailUpdateBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPrepareLoginEmailUpdate(prepareLoginEmailUpdateBody?: PrepareLoginEmailUpdateBody, options?: any): AxiosPromise<void> {
            return localVarFp.postPrepareLoginEmailUpdate(prepareLoginEmailUpdateBody, options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザー作成API
         * @summary ユーザー作成API
         * @param {CreateUserBody} [createUserBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUser(createUserBody?: CreateUserBody, options?: any): AxiosPromise<void> {
            return localVarFp.postUser(createUserBody, options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザー更新API
         * @summary ユーザー更新API
         * @param {number} userId ユーザーID
         * @param {UpdateUserBody} [updateUserBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUser(userId: number, updateUserBody?: UpdateUserBody, options?: any): AxiosPromise<void> {
            return localVarFp.putUser(userId, updateUserBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - interface
 * @export
 * @interface UserApi
 */
export interface UserApiInterface {
    /**
     * ユーザー削除API
     * @summary ユーザー削除API
     * @param {number} userId ユーザーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    deleteUser(userId: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * ログインメールアドレス確認API
     * @summary ログインメールアドレス確認API
     * @param {ConfirmLoginEmailUpdateBody} [confirmLoginEmailUpdateBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    postConfirmLoginEmailUpdate(confirmLoginEmailUpdateBody?: ConfirmLoginEmailUpdateBody, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * ログインメールアドレス更新API
     * @summary ログインメールアドレス更新API
     * @param {PrepareLoginEmailUpdateBody} [prepareLoginEmailUpdateBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    postPrepareLoginEmailUpdate(prepareLoginEmailUpdateBody?: PrepareLoginEmailUpdateBody, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * ユーザー作成API
     * @summary ユーザー作成API
     * @param {CreateUserBody} [createUserBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    postUser(createUserBody?: CreateUserBody, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * ユーザー更新API
     * @summary ユーザー更新API
     * @param {number} userId ユーザーID
     * @param {UpdateUserBody} [updateUserBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    putUser(userId: number, updateUserBody?: UpdateUserBody, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * Request parameters for deleteUser operation in UserApi.
 * @export
 * @interface UserApiDeleteUserRequest
 */
export interface UserApiDeleteUserRequest {
    /**
     * ユーザーID
     * @type {number}
     * @memberof UserApiDeleteUser
     */
    readonly userId: number
}

/**
 * Request parameters for postConfirmLoginEmailUpdate operation in UserApi.
 * @export
 * @interface UserApiPostConfirmLoginEmailUpdateRequest
 */
export interface UserApiPostConfirmLoginEmailUpdateRequest {
    /**
     * 
     * @type {ConfirmLoginEmailUpdateBody}
     * @memberof UserApiPostConfirmLoginEmailUpdate
     */
    readonly confirmLoginEmailUpdateBody?: ConfirmLoginEmailUpdateBody
}

/**
 * Request parameters for postPrepareLoginEmailUpdate operation in UserApi.
 * @export
 * @interface UserApiPostPrepareLoginEmailUpdateRequest
 */
export interface UserApiPostPrepareLoginEmailUpdateRequest {
    /**
     * 
     * @type {PrepareLoginEmailUpdateBody}
     * @memberof UserApiPostPrepareLoginEmailUpdate
     */
    readonly prepareLoginEmailUpdateBody?: PrepareLoginEmailUpdateBody
}

/**
 * Request parameters for postUser operation in UserApi.
 * @export
 * @interface UserApiPostUserRequest
 */
export interface UserApiPostUserRequest {
    /**
     * 
     * @type {CreateUserBody}
     * @memberof UserApiPostUser
     */
    readonly createUserBody?: CreateUserBody
}

/**
 * Request parameters for putUser operation in UserApi.
 * @export
 * @interface UserApiPutUserRequest
 */
export interface UserApiPutUserRequest {
    /**
     * ユーザーID
     * @type {number}
     * @memberof UserApiPutUser
     */
    readonly userId: number

    /**
     * 
     * @type {UpdateUserBody}
     * @memberof UserApiPutUser
     */
    readonly updateUserBody?: UpdateUserBody
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI implements UserApiInterface {
    /**
     * ユーザー削除API
     * @summary ユーザー削除API
     * @param {UserApiDeleteUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteUser(requestParameters: UserApiDeleteUserRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).deleteUser(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ログインメールアドレス確認API
     * @summary ログインメールアドレス確認API
     * @param {UserApiPostConfirmLoginEmailUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public postConfirmLoginEmailUpdate(requestParameters: UserApiPostConfirmLoginEmailUpdateRequest = {}, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).postConfirmLoginEmailUpdate(requestParameters.confirmLoginEmailUpdateBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ログインメールアドレス更新API
     * @summary ログインメールアドレス更新API
     * @param {UserApiPostPrepareLoginEmailUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public postPrepareLoginEmailUpdate(requestParameters: UserApiPostPrepareLoginEmailUpdateRequest = {}, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).postPrepareLoginEmailUpdate(requestParameters.prepareLoginEmailUpdateBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ユーザー作成API
     * @summary ユーザー作成API
     * @param {UserApiPostUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public postUser(requestParameters: UserApiPostUserRequest = {}, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).postUser(requestParameters.createUserBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ユーザー更新API
     * @summary ユーザー更新API
     * @param {UserApiPutUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public putUser(requestParameters: UserApiPutUserRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).putUser(requestParameters.userId, requestParameters.updateUserBody, options).then((request) => request(this.axios, this.basePath));
    }
}
