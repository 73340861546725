import { createRouter, createWebHistory } from 'vue-router';
import ApiBase from '@/api/base';
import AuthService from '@/services/auth_service';
import store from '../store';
import { adminRoutes } from './admin';
import { authRoutes } from './auth';
import { userRoutes } from './user';

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0 };
    }
  },
  routes: [
    ...authRoutes,
    ...userRoutes,
    ...adminRoutes,
    {
      path: '/*',
      redirect: '/error',
      meta: {
        rule: 'all',
      },
    },
  ],
});

const authService = new AuthService();

const clearCondition = async () => {
  await store.dispatch('userUi/clearLeadListFilterParamsAction');
  await store.dispatch('userUi/clearLeadListSortOrderAction');
  await store.dispatch('userUi/clearLeadListSortKeyAction');
  await store.dispatch('user/clearLeadViewAction');
};

const globalRules: Record<string, string[]>= {
  isNoSignIn: ['nosignin'],
  isAdmin: ['admin_owner', 'admin_member', 'admin_developer'],
  isAdminDeveloper: ['admin_owner', 'admin_developer'],
  isAdminOwner: ['admin_owner'],
  isUser: ['owner', 'member'],
  all: ['nosignin', 'admin_owner', 'admin_member', 'owner', 'member'],
};

const canRoute = (rule: string) => {
  // TODO: firebaseに未ログインならnosigninにする
  const role = localStorage.getItem('role') || 'nosignin';
  return globalRules[rule].includes(role);
};

const setPageTitle = (title: string | undefined) => {
  document.title = title ? `${title} - BALES CLOUD` : 'BALES CLOUD';
};

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  ApiBase.cancel('Request canceled by route change.');

  // firebaseの認証を待つ
  await authService.authAsync();
  const currentUser = authService.currentUser();

  // リードリストから遷移する際はフィルタをクリアする
  if (from.name === 'LeadList' && to.name !== 'LeadList' && !to.query.hasOwnProperty('notClearFilter')) {
    await clearCondition();
  }

  // アナリティクスから遷移する際はフィルタをクリアする
  if (from.name === 'CallAnalytics' && !to.query.hasOwnProperty('notClearFilter')) {
    await clearCondition();
  }

  if (requiresAuth && !currentUser) {
    const basePath = /^\/(app|admin)\/*/.exec(to.path);
    const appOrAdmin = Array.isArray(basePath) && basePath.length >= 2 ? basePath[1] : 'app';
    next({
      path: `/${appOrAdmin}/login`,
      query: {
        dest_location: to.fullPath,
      },
    });
  } else if (to.meta.rule && canRoute(to.meta.rule)) {
    next();
  } else {
    next({
      path: '/error',
    });
  }
  setPageTitle(to.meta?.title);
});

export default router;
