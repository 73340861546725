<template>
  <div class="drawer-body">
    <div class="mail-confirm mb-600">
      <div class="subject-confirm">
        {{ subject }}
      </div>
      <div class="content-confirm">
        <div
          class="quill-confirm"
          v-html="content"
        />
        <MailAttachments
          :mail-attachment-ids="directMail.mailAttachmentIds || []"
          :show-cross-button="false"
        />
      </div>
    </div>
    <slot name="mailTo" />
    <BListItem class="list-item mb-600">
      <template #header>
        <div>{{ $t('mail.sender') }}</div>
      </template>
      <span>{{ `${senderData?.senderName} <${senderData?.senderEmail}>` }}</span>
    </BListItem>
    <BListItem class="list-item mb-600">
      <template #header>
        <div>{{ $t('mail.cc') }}</div>
      </template>
      <div
        v-for="{ email } in directMail.sendCc"
        :key="email"
      >
        {{ email }}
      </div>
    </BListItem>
    <BListItem class="list-item mb-600">
      <template #header>
        <div>{{ $t('mail.bcc') }}</div>
      </template>
      <div
        v-for="{ email } in directMail.sendBcc"
        :key="email"
      >
        {{ email }}
      </div>
    </BListItem>
    <BListItem class="list-item mb-600">
      <template #header>
        <div>{{ $t('mailNotificationEvent.formTitle') }}</div>
      </template>
      <div>{{ displayMailEventOpen }}</div>
      <div class="mt-100">
        {{ displayMailEventClick }}
      </div>
    </BListItem>
    <BListItem
      v-if="isAvailableSalesforceApiFeature"
      class="list-item mb-600"
    />
    <slot />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { CommonDirectMail } from '@/api/openapi';
import MailAttachments from '@/components/organisms/user/general/MailAttachments.vue';
import { i18nGlobal as i18n } from '@/i18n';
import { SenderData } from '../types';

type TProps = {
  directMail: CommonDirectMail;
  isAvailableSalesforceApiFeature: boolean;
  senderData: SenderData | null;
  useSentData?: boolean;
}
const props = defineProps<TProps>();

const displayMailEventOpen = computed<string>(() => {
  const message = props.directMail.notificationSetting.open ? 'receive' : 'unreceive';
  return i18n.t(`mailNotificationEvent.${message}`, { event: i18n.t(`mailNotificationEvent.open`) });
});

const displayMailEventClick = computed<string>(() => {
  const message = props.directMail.notificationSetting.click ? 'receive' : 'unreceive';
  return i18n.t(`mailNotificationEvent.${message}`, { event: i18n.t(`mailNotificationEvent.click`) });
});

const subject = computed<string>(() => {
  return props.useSentData ? props.directMail.sentSubject : props.directMail.subject;
});

const content = computed<string>(() => {
  return props.useSentData ? props.directMail.sentContent : props.directMail.content;
});
</script>

<style lang="scss" scoped>
.mail-confirm {
  border: 1px solid $concrete;
  border-radius: 5px;

  .subject-confirm {
    min-height: 32px;
    max-height: 80px;
    overflow-y: scroll;
    border-bottom: 1px solid $concrete;
    padding: $basespace-100;
    font-weight: 600;
  }

  .content-confirm {
    max-height: 600px;
    overflow-y: scroll;
    padding: $basespace-100;

    .opt-out-link {
      margin: $basespace-400 0 $basespace-50;
      width: 160px;
      color: $basecolor-secondary;
      text-decoration-line: underline;
      font-size: $fontsize-100;
    }
  }

  .attachments {
    padding: 10px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
}
.quill-confirm {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
  line-height: 1.42;
  tab-size: 4;
  text-align: left;
  white-space: pre-wrap;

  // Biscuetの標準リンク色を上書き
  :deep(a) {
    color: #06c;
  }

  :deep(p) {
    margin: 0 !important;
    padding: 0 !important;
  }

  :deep(ol), :deep(ul) {
    padding-left: 3em;
  }
  :deep(ul) {
    > li {
      list-style-type: none;
      &::before {
        content: '\2022';
      }
      &:not(.ql-direction-rtl)::before {
        margin-left: -0.5em;
        margin-right: 0.3em;
        text-align: right;
      }
    }
  }
}
</style>
