<template>
  <BIconWithActionMenu
    class="received-mail-notification-container"
    :badge-count="paginationMeta?.totalCount || 0"
    :auto-fetch-interval="30000"
    @auto-fetch-interval-passed="fetchLoadedPage"
    @after-open="handleAfterOpen"
    @after-close="clear"
  >
    <template #icon>
      <BCustomIcon icon-class="b-mail-return" />
    </template>
    <div class="header">
      <div class="header-item header-title">
        {{ $t('receivedMailNotification.title') }}
      </div>
      <div class="header-item number-of-items">
        <span>{{ paginationMeta?.totalCount || 0 }}件</span>
      </div>
    </div>
    <div
      class="body"
    >
      <div
        v-infinite-scroll="loadMore"
        class="body-inner"
        :infinite-scroll-disabled="infiniteScrollDisabled"
        infinite-scroll-distance="20"
      >
        <BEmptyBox
          v-if="!$wait.is(KEY_OF_INFINITE_LOADING_WAIT) && !items.length"
          class="empty"
          display-only
        >
          {{ $t('receivedMailNotification.empty') }}
        </BEmptyBox>
        <ReceivedMailNotificationItem
          v-for="item in items"
          :key="item.id"
          :item="item"
          @after-click-event-save="fetchLoadedPage"
        />
        <BLayout
          v-if="$wait.is(KEY_OF_INFINITE_LOADING_WAIT)"
          class="py-200"
          justify-center
        >
          <BElementIcon name="Loading" />
        </BLayout>
      </div>
    </div>
  </BIconWithActionMenu>
</template>

<script setup lang="ts">
import { PartialReceivedMail } from '@/api/openapi';
import { ReceivedMailApiService } from '@/api/user/resources/received_mail';
import ReceivedMailNotificationItem from '@/components/organisms/user/receivedMailNotification/ReceivedMailNotificationItem.vue';
import { useInfiniteLoading } from '@/composable/infinite-loading';

const KEY_OF_INFINITE_LOADING_WAIT = 'receiveMailInfiniteLoadingWait';

const fetchItems = async (page = 1) => {
  const api = new ReceivedMailApiService();
  const res = await api.getReceivedMails({
    request: {
      page,
    },
  });
  return {
    items: res.data.receivedMails,
    paginationMeta: res.data.meta,
  };
};

const {
  items,
  paginationMeta,
  infiniteScrollDisabled,
  loadMore,
  clear,
  handleAfterOpen,
  fetchLoadedPage,
} = useInfiniteLoading<PartialReceivedMail>(fetchItems, KEY_OF_INFINITE_LOADING_WAIT);
</script>

<style lang="scss" scoped>
.received-mail-notification-container {
  .header {
    display: flex;
    width: 100%;
    height: 70px;
    border-bottom: 1px solid $bdcolor-base;
    padding: 0 $basespace-200;

    &-item {
      margin: $basespace-250 0;

      &.header-title {
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        margin: $basespace-500 0;
      }

      &.number-of-items {
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        margin: $basespace-500 0;

        span {
          display:inline-block;
          vertical-align: middle;
          text-align: center;
          width: 54px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          margin: 0 $basespace-400;
        }
      }
    }
  }

  .body {
    @include m-fixed-height(calc(580px - 70px - 60px));
    overflow-y: auto;
    overflow-x: auto;

    .body-inner {
      width: 100%;
      min-width: max-content;
    }

    .empty {
      color: $textcolor-light;
      padding: $basespace-600;
      margin: $basespace-600;
      height: 90px;
      font-size: 12px;
    }
  }
}
</style>
