/* tslint:disable */
/* eslint-disable */
/**
 * openapi
 * bales cloud
 *
 * The version of the OpenAPI document: 1.0
 * Contact: test@example.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateMfaSetting201Response } from '../models';
// @ts-ignore
import { GetQuickText200Response } from '../models';
// @ts-ignore
import { GetQuickTexts200Response } from '../models';
// @ts-ignore
import { QuickTextBody } from '../models';
// @ts-ignore
import { QuickTextStatus } from '../models';
/**
 * QuickTextApi - axios parameter creator
 * @export
 */
export const QuickTextApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * クイックテキスト削除API
         * @summary クイックテキスト削除API
         * @param {number} quickTextId クイックテキストID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQuickText: async (quickTextId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'quickTextId' is not null or undefined
            assertParamExists('deleteQuickText', 'quickTextId', quickTextId)
            const localVarPath = `/api/user/quick_texts/{quickTextId}`
                .replace(`{${"quickTextId"}}`, encodeURIComponent(String(quickTextId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クイックテキスト取得API
         * @summary クイックテキスト取得API
         * @param {number} quickTextId クイックテキストID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuickText: async (quickTextId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'quickTextId' is not null or undefined
            assertParamExists('getQuickText', 'quickTextId', quickTextId)
            const localVarPath = `/api/user/quick_texts/{quickTextId}`
                .replace(`{${"quickTextId"}}`, encodeURIComponent(String(quickTextId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クイックテキスト一覧取得API
         * @summary クイックテキスト一覧取得API
         * @param {number} [currentPage] リードリストでの現在のページネーション
         * @param {number} [sourceCallTargetId] リードID
         * @param {Array<QuickTextStatus>} [statuses] ステータス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuickTexts: async (currentPage?: number, sourceCallTargetId?: number, statuses?: Array<QuickTextStatus>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/quick_texts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)

            if (currentPage !== undefined) {
                localVarQueryParameter['currentPage'] = currentPage;
            }

            if (sourceCallTargetId !== undefined) {
                localVarQueryParameter['sourceCallTargetId'] = sourceCallTargetId;
            }

            if (statuses) {
                localVarQueryParameter['statuses[]'] = statuses;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クイックテキスト作成API
         * @summary クイックテキスト作成API
         * @param {QuickTextBody} [quickTextBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postQuickText: async (quickTextBody?: QuickTextBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/quick_texts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(quickTextBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クイックテキスト更新API
         * @summary クイックテキスト更新API
         * @param {number} quickTextId クイックテキストID
         * @param {QuickTextBody} [quickTextBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putQuickText: async (quickTextId: number, quickTextBody?: QuickTextBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'quickTextId' is not null or undefined
            assertParamExists('putQuickText', 'quickTextId', quickTextId)
            const localVarPath = `/api/user/quick_texts/{quickTextId}`
                .replace(`{${"quickTextId"}}`, encodeURIComponent(String(quickTextId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(quickTextBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QuickTextApi - functional programming interface
 * @export
 */
export const QuickTextApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QuickTextApiAxiosParamCreator(configuration)
    return {
        /**
         * クイックテキスト削除API
         * @summary クイックテキスト削除API
         * @param {number} quickTextId クイックテキストID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteQuickText(quickTextId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteQuickText(quickTextId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クイックテキスト取得API
         * @summary クイックテキスト取得API
         * @param {number} quickTextId クイックテキストID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQuickText(quickTextId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetQuickText200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQuickText(quickTextId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クイックテキスト一覧取得API
         * @summary クイックテキスト一覧取得API
         * @param {number} [currentPage] リードリストでの現在のページネーション
         * @param {number} [sourceCallTargetId] リードID
         * @param {Array<QuickTextStatus>} [statuses] ステータス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQuickTexts(currentPage?: number, sourceCallTargetId?: number, statuses?: Array<QuickTextStatus>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetQuickTexts200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQuickTexts(currentPage, sourceCallTargetId, statuses, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クイックテキスト作成API
         * @summary クイックテキスト作成API
         * @param {QuickTextBody} [quickTextBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postQuickText(quickTextBody?: QuickTextBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateMfaSetting201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postQuickText(quickTextBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クイックテキスト更新API
         * @summary クイックテキスト更新API
         * @param {number} quickTextId クイックテキストID
         * @param {QuickTextBody} [quickTextBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putQuickText(quickTextId: number, quickTextBody?: QuickTextBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putQuickText(quickTextId, quickTextBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * QuickTextApi - factory interface
 * @export
 */
export const QuickTextApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QuickTextApiFp(configuration)
    return {
        /**
         * クイックテキスト削除API
         * @summary クイックテキスト削除API
         * @param {number} quickTextId クイックテキストID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQuickText(quickTextId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteQuickText(quickTextId, options).then((request) => request(axios, basePath));
        },
        /**
         * クイックテキスト取得API
         * @summary クイックテキスト取得API
         * @param {number} quickTextId クイックテキストID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuickText(quickTextId: number, options?: any): AxiosPromise<GetQuickText200Response> {
            return localVarFp.getQuickText(quickTextId, options).then((request) => request(axios, basePath));
        },
        /**
         * クイックテキスト一覧取得API
         * @summary クイックテキスト一覧取得API
         * @param {number} [currentPage] リードリストでの現在のページネーション
         * @param {number} [sourceCallTargetId] リードID
         * @param {Array<QuickTextStatus>} [statuses] ステータス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuickTexts(currentPage?: number, sourceCallTargetId?: number, statuses?: Array<QuickTextStatus>, options?: any): AxiosPromise<GetQuickTexts200Response> {
            return localVarFp.getQuickTexts(currentPage, sourceCallTargetId, statuses, options).then((request) => request(axios, basePath));
        },
        /**
         * クイックテキスト作成API
         * @summary クイックテキスト作成API
         * @param {QuickTextBody} [quickTextBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postQuickText(quickTextBody?: QuickTextBody, options?: any): AxiosPromise<CreateMfaSetting201Response> {
            return localVarFp.postQuickText(quickTextBody, options).then((request) => request(axios, basePath));
        },
        /**
         * クイックテキスト更新API
         * @summary クイックテキスト更新API
         * @param {number} quickTextId クイックテキストID
         * @param {QuickTextBody} [quickTextBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putQuickText(quickTextId: number, quickTextBody?: QuickTextBody, options?: any): AxiosPromise<void> {
            return localVarFp.putQuickText(quickTextId, quickTextBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QuickTextApi - interface
 * @export
 * @interface QuickTextApi
 */
export interface QuickTextApiInterface {
    /**
     * クイックテキスト削除API
     * @summary クイックテキスト削除API
     * @param {number} quickTextId クイックテキストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuickTextApiInterface
     */
    deleteQuickText(quickTextId: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * クイックテキスト取得API
     * @summary クイックテキスト取得API
     * @param {number} quickTextId クイックテキストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuickTextApiInterface
     */
    getQuickText(quickTextId: number, options?: AxiosRequestConfig): AxiosPromise<GetQuickText200Response>;

    /**
     * クイックテキスト一覧取得API
     * @summary クイックテキスト一覧取得API
     * @param {number} [currentPage] リードリストでの現在のページネーション
     * @param {number} [sourceCallTargetId] リードID
     * @param {Array<QuickTextStatus>} [statuses] ステータス
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuickTextApiInterface
     */
    getQuickTexts(currentPage?: number, sourceCallTargetId?: number, statuses?: Array<QuickTextStatus>, options?: AxiosRequestConfig): AxiosPromise<GetQuickTexts200Response>;

    /**
     * クイックテキスト作成API
     * @summary クイックテキスト作成API
     * @param {QuickTextBody} [quickTextBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuickTextApiInterface
     */
    postQuickText(quickTextBody?: QuickTextBody, options?: AxiosRequestConfig): AxiosPromise<CreateMfaSetting201Response>;

    /**
     * クイックテキスト更新API
     * @summary クイックテキスト更新API
     * @param {number} quickTextId クイックテキストID
     * @param {QuickTextBody} [quickTextBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuickTextApiInterface
     */
    putQuickText(quickTextId: number, quickTextBody?: QuickTextBody, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * Request parameters for deleteQuickText operation in QuickTextApi.
 * @export
 * @interface QuickTextApiDeleteQuickTextRequest
 */
export interface QuickTextApiDeleteQuickTextRequest {
    /**
     * クイックテキストID
     * @type {number}
     * @memberof QuickTextApiDeleteQuickText
     */
    readonly quickTextId: number
}

/**
 * Request parameters for getQuickText operation in QuickTextApi.
 * @export
 * @interface QuickTextApiGetQuickTextRequest
 */
export interface QuickTextApiGetQuickTextRequest {
    /**
     * クイックテキストID
     * @type {number}
     * @memberof QuickTextApiGetQuickText
     */
    readonly quickTextId: number
}

/**
 * Request parameters for getQuickTexts operation in QuickTextApi.
 * @export
 * @interface QuickTextApiGetQuickTextsRequest
 */
export interface QuickTextApiGetQuickTextsRequest {
    /**
     * リードリストでの現在のページネーション
     * @type {number}
     * @memberof QuickTextApiGetQuickTexts
     */
    readonly currentPage?: number

    /**
     * リードID
     * @type {number}
     * @memberof QuickTextApiGetQuickTexts
     */
    readonly sourceCallTargetId?: number

    /**
     * ステータス
     * @type {Array<QuickTextStatus>}
     * @memberof QuickTextApiGetQuickTexts
     */
    readonly statuses?: Array<QuickTextStatus>
}

/**
 * Request parameters for postQuickText operation in QuickTextApi.
 * @export
 * @interface QuickTextApiPostQuickTextRequest
 */
export interface QuickTextApiPostQuickTextRequest {
    /**
     * 
     * @type {QuickTextBody}
     * @memberof QuickTextApiPostQuickText
     */
    readonly quickTextBody?: QuickTextBody
}

/**
 * Request parameters for putQuickText operation in QuickTextApi.
 * @export
 * @interface QuickTextApiPutQuickTextRequest
 */
export interface QuickTextApiPutQuickTextRequest {
    /**
     * クイックテキストID
     * @type {number}
     * @memberof QuickTextApiPutQuickText
     */
    readonly quickTextId: number

    /**
     * 
     * @type {QuickTextBody}
     * @memberof QuickTextApiPutQuickText
     */
    readonly quickTextBody?: QuickTextBody
}

/**
 * QuickTextApi - object-oriented interface
 * @export
 * @class QuickTextApi
 * @extends {BaseAPI}
 */
export class QuickTextApi extends BaseAPI implements QuickTextApiInterface {
    /**
     * クイックテキスト削除API
     * @summary クイックテキスト削除API
     * @param {QuickTextApiDeleteQuickTextRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuickTextApi
     */
    public deleteQuickText(requestParameters: QuickTextApiDeleteQuickTextRequest, options?: AxiosRequestConfig) {
        return QuickTextApiFp(this.configuration).deleteQuickText(requestParameters.quickTextId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * クイックテキスト取得API
     * @summary クイックテキスト取得API
     * @param {QuickTextApiGetQuickTextRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuickTextApi
     */
    public getQuickText(requestParameters: QuickTextApiGetQuickTextRequest, options?: AxiosRequestConfig) {
        return QuickTextApiFp(this.configuration).getQuickText(requestParameters.quickTextId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * クイックテキスト一覧取得API
     * @summary クイックテキスト一覧取得API
     * @param {QuickTextApiGetQuickTextsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuickTextApi
     */
    public getQuickTexts(requestParameters: QuickTextApiGetQuickTextsRequest = {}, options?: AxiosRequestConfig) {
        return QuickTextApiFp(this.configuration).getQuickTexts(requestParameters.currentPage, requestParameters.sourceCallTargetId, requestParameters.statuses, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * クイックテキスト作成API
     * @summary クイックテキスト作成API
     * @param {QuickTextApiPostQuickTextRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuickTextApi
     */
    public postQuickText(requestParameters: QuickTextApiPostQuickTextRequest = {}, options?: AxiosRequestConfig) {
        return QuickTextApiFp(this.configuration).postQuickText(requestParameters.quickTextBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * クイックテキスト更新API
     * @summary クイックテキスト更新API
     * @param {QuickTextApiPutQuickTextRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuickTextApi
     */
    public putQuickText(requestParameters: QuickTextApiPutQuickTextRequest, options?: AxiosRequestConfig) {
        return QuickTextApiFp(this.configuration).putQuickText(requestParameters.quickTextId, requestParameters.quickTextBody, options).then((request) => request(this.axios, this.basePath));
    }
}
