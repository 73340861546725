import SalesForceApiService from '@/api/salesforce_api_service';

export default {
  getSfUsers() {
    return new SalesForceApiService().get(`/user/salesforce/sf_users`);
  },
  getSfUsersByName(name) {
    return new SalesForceApiService().get(`/user/salesforce/sf_users`, { params: { name } });
  },
};
