<template>
  <div
    class="b-breadcrumbs"
    :class="classes"
  >
    <div
      v-for="(item, index) in items"
      :key="item"
      class="b-breadcrumbs-section"
      :style="breadcrumbsStyle"
    >
      <RouterLink
        class="b-breadcrumbs-item"
        :to="item.path"
      >
        {{ item.text }}
      </RouterLink>
      <BIcon
        v-if="visibleIcon(index)"
        size="small"
      >
        chevron_right
      </BIcon>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: Array,
    dark: Boolean,
    light: Boolean,
  },
  computed: {
    classes () {
      return {
        'b-breadcrumbs-dark': this.dark,
        'b-breadcrumbs-light': this.light,
      };
    },
    breadcrumbsStyle () {
      return { maxWidth: `${300}px` };
    },
  },
  methods: {
    visibleIcon (index) {
      return this.items.length - 1 > index;
    },
  },
};
</script>

<style lang="scss" scoped>
  .b-breadcrumbs {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
  }

  .b-breadcrumbs-section {
    display: flex;
    align-items: center;
  }

  .b-breadcrumbs-item {
    text-decoration: none;
    color: $textcolor-base;
    border-radius: 4px;
    transition: all 300ms $standard-easing;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: $basespace-100;

    &:not(.router-link-exact-active) {
      cursor: pointer;
      &:hover {
        background-color: $bgcolor-dark;
      }
    }
  }

  .b-breadcrumbs-light {
    .b-breadcrumbs-item {
      color: $paper;
      &:not(.router-link-exact-active):hover {
        background-color: rgba($bgcolor-white, .3);
      }
    }
  }
</style>
