<template>
  <div>
    <div
      v-show="success != ''"
      class="notice success"
    >
      <span>{{ success }}</span>
      <BBtn
        text
        position="right"
        class="close"
      >
        <BIcon
          class="px-200"
          size="small"
          type="white"
          @click="success = ''"
        >
          close
        </BIcon>
      </BBtn>
    </div>
    <div
      v-show="error != ''"
      class="notice error"
    >
      <span>{{ error }}</span>
      <BBtn
        text
        position="right"
        class="close"
      >
        <BIcon
          class="px-200"
          size="small"
          type="white"
          @click="error = ''"
        >
          close
        </BIcon>
      </BBtn>
    </div>
    <div
      v-loading="salesforceSettingLoading"
      class="content"
    >
      <div class="pl-600 pb-200 target-object-name">
        {{ $t(`sfObjectMappings.${selectedObject}`) }}
      </div>
      <div
        v-show="innerColumnList"
        class="modal-tab-box"
      >
        <div class="tab-header">
          <div
            v-for="(objectName, currentTab) in innerColumnList"
            :key="currentTab"
            class="tab-item"
            :class="{ active: isCurrentTab(currentTab) }"
            @click="() => setSelectTab(currentTab, objectName)"
          >
            <span>{{ $t(`import.salesforce.mappings.${currentTab}`) }}</span>
          </div>
        </div>
      </div>
      <BLayout
        class="pl-600 pb-200 header"
        justify-center
        align-center
      >
        <BListItem
          v-show="useChangeImport"
          class="column-content non-border radio-priority-area"
        >
          <span>SF優先</span>
        </BListItem>
        <BListItem
          class="column-content non-border"
        >
          <span>Salesforce項目</span>
        </BListItem>
        <div class="icon" />
        <BListItem
          v-show="useChangeImport"
          class="column-content non-border radio-priority-area"
        >
          <span>BC優先</span>
        </BListItem>
        <BListItem
          class="column-content non-border"
        >
          <span>BALES CLOUD項目</span>
        </BListItem>
        <BListItem
          v-show="useChangeImport"
          class="column-content non-border check-area"
        >
          <span>SF→BC</span>
        </BListItem>
        <BListItem
          v-show="useChangeImport"
          class="column-content non-border check-area"
        >
          <span>SF←BC</span>
        </BListItem>
      </BLayout>
      <BLayout
        v-for="(value, bcKey) in mapping"
        :key="`${bcKey}-${changeBoxCounter}`"
        class="pl-600 pb-200"
        justify-center
        align-center
      >
        <BListItem
          v-show="useChangeImport"
          class="radio-priority-area"
        >
          <div
            v-show="sharedObjectMapping[bcKey] == null"
            :class="`column-content non-border radio-box
              radio-box-${value['bc_priority'] != true && value['sf_import'] ? 'active' : 'non-active'}`"
            @click="() => changePriority('sf_import', bcKey)"
          >
            <BIcon
              class="radio"
              size="b-icon-small"
            >
              done
            </BIcon>
          </div>
        </BListItem>
        <BListItem>
          <div
            v-if="value['lock']"
            class="column-content non-border select-column"
          >
            {{ sfFieldsMap[value['sf_column']] }}
          </div>
          <BSelect
            v-else
            v-model="sfFieldsMap[value['sf_column']]"
            filterable
            clearable
            class="select-column"
            :items="selectItems(bcKey)"
            item-text="label"
            item-value="value"
            fit
            :disabled="sharedObjectMapping[bcKey] != null"
            :placeholder="sharedObjectMapping[bcKey] == null ? $t('general.select.please') : $t('sfObjectMappings.alreadyUsed')"
            @clear="() => reset(bcKey)"
            @change="changed"
          >
            <template #optionTemplate="{ item }">
              <span
                class="b-select"
                :class="{'b-selected': item['value']['sfKey'] && value['sf_column'] == item['value']['sfKey']}"
              >
                {{ item['label'] }}
              </span>
            </template>
          </BSelect>
        </BListItem>
        <div class="icon">
          <BIcon
            class="icon-sync"
            size="b-icon-medium"
          >
            swap_horiz
          </BIcon>
        </div>
        <BListItem
          v-show="useChangeImport"
          class="radio-priority-area"
        >
          <div
            v-show="!('sf_import_only' in value && value['sf_import_only'] == true) && sharedObjectMapping[bcKey] == null"
            :class="`column-content non-border radio-box
              radio-box-${value['bc_priority'] ? 'active' : 'non-active'}`"
            @click="() => changePriority('bc_import', bcKey)"
          >
            <BIcon
              class="radio"
              size="b-icon-small"
            >
              done
            </BIcon>
          </div>
        </BListItem>
        <BListItem>
          <div
            class="column-content non-border bc-label"
          >
            <span v-if="customFieldsMap[bcKey] != null">
              {{ customFieldsMap[bcKey]['name'] }}
            </span>
            <span v-else-if="customActionsMap[bcKey] != null">
              {{ customActionsMap[bcKey]['name'] }}
            </span>
            <span v-else-if="value['label'] != null">
              {{ value['label'] }}
            </span>
            <span v-else>
              {{ $t(`import.salesforce.mappings.${bcKey}`) }}
            </span>
          </div>
        </BListItem>
        <BListItem
          v-show="useChangeImport"
          class="check-area"
        >
          <div
            v-show="sharedObjectMapping[bcKey] == null"
            :class="`column-content non-border check-box
              check-box-${value['sf_import'] ? 'active' : 'non-active'}`"
            @click="() => changeSyncMode('sf_import', bcKey)"
          >
            <BIcon
              class="check"
              size="b-icon-small"
            >
              done
            </BIcon>
          </div>
        </BListItem>
        <BListItem
          v-show="useChangeImport"
          class="check-area"
        >
          <div
            v-show="!('sf_import_only' in value && value['sf_import_only'] == true) && sharedObjectMapping[bcKey] == null"
            :class="`column-content non-border check-box
              check-box-${value['bc_import'] ? 'active' : 'non-active'}`"
            @click="() => changeSyncMode('bc_import', bcKey)"
          >
            <BIcon
              class="check"
              size="b-icon-small"
            >
              done
            </BIcon>
          </div>
        </BListItem>
      </BLayout>
    </div>
    <div class="footer">
      <div
        class="btn-back"
        @click="backAction"
      >
        {{ $t("sfObjectMappings.back") }}
      </div>
      <div
        class="btn-save"
        @click="upsertMapping"
      >
        {{ $t("sfObjectMappings.save") }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { SfObjectMappingService } from '@/api/user/resources/salesforce/sf_object_mapping';
import { i18nGlobal as i18n } from '@/i18n';

type TProps = {
  selectedObject?: string;
};
type TItem = {
  label: string;
  value: {
    bcKey: string;
    sfKey: string;
    dataType: string;
  };
};

const props = withDefaults(defineProps<TProps>(), {
  selectedObject: '',
});
const emit = defineEmits(['back']);
const api = new SfObjectMappingService();
const mapping = ref({});
const sfFields = ref({});
const sfFieldsMap = ref({});
const sfMapInfo = ref({});
const sfColumnDataTypes = ref({});
const customFieldsDataTypes = ref({});
const sharedObjectMapping = ref({});
const innerColumnList = ref({});
const customFieldsMap = ref({});
const customActionsMap = ref({});
const useChangeImport = ref(false);
const currentTab = ref('');
const salesforceSettingLoading = ref(true);
const success = ref('');
const error = ref('');
// v-forで選択したときに、同一のkeyの場合に書き換わらないケースががあるため
const changeBoxCounter = ref(0);

const backAction = () => {
  emit('back');
};

const reset = (key: string) => {
  mapping.value[key].sf_column = '';
  // 変更が反映されないことがあるため、v-forのkeyを更新
  changeBoxCounter.value++;
};

const changed = (item: TItem['value'] | undefined) => {
  // clearのときもchangedが動くのでitemが空になったときは返す
  if (item == null) {
    return;
  }
  success.value = '';
  error.value = '';
  // selectItemsのvalueが帰ってくる
  mapping.value[item.bcKey].sf_column = item.sfKey;
  // 実装上の都合で選択されたものの値がsfFieldsMapと異なるフォーマットで更新されてしまうので、
  // データ更新後にリストを初期化しなおす
  loadSfFieldsMap();
  // 変更が反映されないことがあるため、v-forのkeyを更新
  changeBoxCounter.value++;
};

const selectItems = (bcKey): TItem[] => {
  const sfKeys = Object.values(mapping.value).map((v) => {
    return v.sf_column;
  });
  let mapDataType = sfColumnDataTypes.value[bcKey] ?? 'string';
  if (bcKey.indexOf('custom') === 0) {
    mapDataType = customFieldsDataTypes.value[mapping.value[bcKey].data_type] ?? 'string';
    if (mapDataType == 'picklist') {
      mapDataType = 'string';
    }
  }
  const selectFilterItems = sfMapInfo.value[mapDataType];
  if (selectFilterItems == null) {
    return [];
  }
  const sfMap = [];
  for (const sfKey of Object.keys(selectFilterItems)) {
    // {'Company': {'label': '会社名', 'data_type': 'string'}}を{label: '会社名', options: 'Company', data_type: 'string'}のように変換
    if (sfKey !== 'Id'
      && (!sfKeys.includes(sfKey) || mapping.value[bcKey].sf_column === sfKey)
    ) {
      sfMap.push({ label: selectFilterItems[sfKey].sf_label, value: { bcKey, sfKey, dataType: selectFilterItems[sfKey].data_type } });
    }
  }
  return sfMap;
};

const loadSfFieldsMap = () => {
  const sfMap = [];
  for (const sfKey of Object.keys(sfFields.value)) {
    if (sfKey !== 'Id') {
      sfMap[sfKey] = sfFields.value[sfKey].label;
    }
  }
  sfFieldsMap.value = sfMap;
};

const upsertMapping = async () => {
  salesforceSettingLoading.value = true;
  api.upsertSfObjectMappingBody(
    {
      upsertSfObjectMappingBodyRequest: {
        targetObject: props.selectedObject,
        mapping: JSON.stringify(mapping.value),
      },
    },
  ).then(() => {
    success.value = i18n.t('general.success.to', { action: i18n.t('general.update') });
  }).catch(() => {
    error.value = i18n.t('general.fail.to', { action: i18n.t('general.update') });
  });
  salesforceSettingLoading.value = false;
};

const changeSyncMode = (target, bcKey) => {
  mapping.value[bcKey][target] = !mapping.value[bcKey][target];

  // importがfalseの場合はpriorityの設定を見直す
  if (
    target === 'bc_import'
    && mapping.value[bcKey][target] == false
  ) {
    mapping.value[bcKey].bc_priority = false;
  } else if (
    target === 'bc_import'
    && mapping.value[bcKey][target] == true
    && mapping.value[bcKey].sf_import == false
  ) {
    mapping.value[bcKey].bc_priority = true;
  } else if (
    target === 'sf_import'
    && mapping.value[bcKey].sf_import == false
    && mapping.value[bcKey].bc_import == true
  ) {
    mapping.value[bcKey].bc_priority = true;
  }
};

const changePriority = (targetImport, bcKey) => {
  // 対象のimportがtrueじゃなければ変えない
  if (!mapping.value[bcKey][targetImport]) return;

  if (targetImport === 'bc_import') {
    mapping.value[bcKey].bc_priority = true;
  } else if (targetImport === 'sf_import') {
    mapping.value[bcKey].bc_priority = false;
  }
};

const isCurrentTab = (tab) => {
  return currentTab.value == tab;
};

const setSelectTab = (tab, objectName) => {
  currentTab.value = tab;
  changeCurrentTab(objectName);
};

const changeCurrentTab = (objectName) => {
  Object.keys(mapping.value).forEach((key) => {
    mapping.value[key].bc_object = objectName;
  });
};

onMounted(async () => {
  const result = await api.getSfObjectMappings({ targetObject: props.selectedObject });
  mapping.value = result.data.sfObjectMappings.objectMappings;
  sfFields.value = result.data.sfObjectMappings.objectFields;
  sfMapInfo.value = result.data.sfObjectMappings.sfMapInfo;
  sfColumnDataTypes.value = result.data.sfObjectMappings.sfColumnDataTypes;
  customFieldsDataTypes.value = result.data.sfObjectMappings.customFieldsDataTypes;
  sharedObjectMapping.value = result.data.sfObjectMappings.sharedObjectMapping;
  innerColumnList.value = result.data.sfObjectMappings.innerColumnList;
  customFieldsMap.value = result.data.sfObjectMappings.customFieldsMap ?? {};
  customActionsMap.value = result.data.sfObjectMappings.customActionsMap ?? {};
  useChangeImport.value = innerColumnList.value == null;
  if (innerColumnList.value != null) {
    currentTab.value = Object.keys(innerColumnList.value)[0];
    changeCurrentTab(innerColumnList.value[currentTab.value]);
  }
  loadSfFieldsMap();
  salesforceSettingLoading.value = false;
});
</script>

<style lang="scss" scoped>
.content {
  position: absolute;
  top: auto;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding-bottom: 290px;
}

.object-name {
  font-weight: bold;
}

.column-content {
  width: 100%;
  padding: 0 10px;
  height: 40px;
  line-height: 40px;
  border-radius: 5px;
  border: 1px solid #E3E3E3;
}

.bc-label {
  overflow-y: scroll;
}

.select-column {
  width: 100%;
}

.non-border {
  border-width: 0;
}

.icon {
  display: flex;
  width: 20%;
  align-items: center;
  justify-content: center;
  font-size: 32px !important;
  color: $concrete-dark !important;
  padding: 0 12px;

  .icon-sync {
    color: $concrete-dark !important;
  }
}

.hr {
  border-bottom: 1px solid $bdcolor-base;
  margin-bottom: 8px;
}

.footer {
  position: fixed;
  bottom: 0;
  right: 0;
  width: calc(100% - 310px);
  height: 63px;
  background-color: #F6F6F6;;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-back {
  color: #231815;
  font-size: 18px;
  margin: 0 12px;
  padding: 4px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btn-save {
  width: 101px;
  height: 37px;
  border-radius: 4px;
  background: #018485;
  margin: 0 12px;
  color: #FFF;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.notice {
  position: relative;
  width: 100%;
  height: 60px;
  padding: 12px 0;
  margin: 12px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFF;
}

.close {
  position: absolute;
  right: 0;
}

.success {
  background-color: $basecolor-success;
}

.error {
  background-color: $basecolor-error;
}

.header {
  color: $concrete-dark;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.target-object-name {
  font-weight: bold;
  font-size: 20px;
}

.check-area {
  width: 80px !important;
  margin: 0 20px;
  flex-shrink: 0;
  text-align: center;
}

.radio-priority-area {
  width: 60px !important;
  margin: 0;
  padding: 0;
  flex-shrink: 0;
  text-align: center;
}

.check-box {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  cursor: pointer;

  > .check {
    color: $textcolor-white;
  }

  &.check-box-active {
    background-color: $basecolor-success;
  }

  &.check-box-non-active {
    background-color: $concrete-dark;
  }
}

.radio-box {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  cursor: pointer;
  border-radius: 50%;

  > .radio {
    color: $textcolor-white;
  }

  &.radio-box-active {
    background-color: $basecolor-success;
  }

  &.radio-box-non-active {
    background-color: $concrete-dark;
  }
}

.b-select {
  color: #231815;
  font-weight: normal;
}
.b-selected {
  color: var(--el-color-primary);
  font-weight: bold;
}

.modal-tab-box {
  margin: $basespace-200 0;

  .tab-header {
    height: 23px;
    border-bottom: 1px solid $bdcolor-base;

    .tab-item {
      margin: 0 $basespace-600;
      position: relative;
      top: 1px;
      transition: $transition-base;
      font-size: $fontsize-200;
      line-height: 1;
      text-align: center;
      padding: 0 0 6px;
      color: $textcolor-base;
      border-bottom: 2px solid transparent;
      cursor: pointer;
      font-weight: 600;
      min-width: 56px;
      width: fit-content;

      &:hover {
        color: $basecolor-primary;
      }

      &.active {
        color: $basecolor-primary;
        border-color: $basecolor-primary;
      }
    }
  }
}
</style>
