import { TCallResultType, TDirection } from '@/services/zoom_phone_types';
import { ZoomPhoneState } from '../types';

export const types = {
  SET_IS_CALLING: 'SET_IS_CALLING',
  SET_DIRECTION: 'SET_DIRECTION',
  SET_CALL_STARTED_INFO: 'SET_CALL_STARTED_INFO',
  SET_CALL_ENDED_INFO: 'SET_CALL_ENDED_INFO',
  SET_CALL_CONNECTED_INFO: 'SET_CALL_CONNECTED_INFO',
  RESET_CALL_INFO: 'RESET_CALL_INFO',
  SET_ZOOM_SETTING: 'SET_ZOOM_SETTING',
  CLEAR_ZOOM_SETTING: 'CLEAR_ZOOM_SETTING',
};

export const mutations = {
  [types.SET_ZOOM_SETTING](state: ZoomPhoneState, data: ZoomPhoneState['zoomSetting']): void {
    state.zoomSetting = data;
  },
  [types.CLEAR_ZOOM_SETTING](state: ZoomPhoneState): void {
    state.zoomSetting = null;
  },
  [types.SET_IS_CALLING] (state: ZoomPhoneState, isCalling: boolean): void {
    state.isCalling = isCalling;
  },
  [types.SET_DIRECTION] (state: ZoomPhoneState, direction: TDirection): void {
    state.callInfo.direction = direction;
  },
  [types.SET_CALL_STARTED_INFO] (state: ZoomPhoneState, { id, startedAt }: { id: string, startedAt: string }): void {
    state.callInfo.id = id;
    state.callInfo.startedAt = startedAt;
  },
  [types.SET_CALL_ENDED_INFO] (state: ZoomPhoneState, { id, endedAt, result }: { id: string, endedAt: string, result: TCallResultType }): void {
    state.callInfo.id = id;
    state.callInfo.endedAt = endedAt;
    state.callInfo.result = result;
  },
  [types.SET_CALL_CONNECTED_INFO] (state: ZoomPhoneState, { id, connectedAt }: { id: string, connectedAt: string }): void {
    state.callInfo.id = id;
    state.callInfo.connectedAt = connectedAt;
  },
  [types.RESET_CALL_INFO] (state: ZoomPhoneState): void {
    state.callInfo = {
      id: null,
      direction: null,
      startedAt: null,
      connectedAt: null,
      endedAt: null,
      result: null,
    };
  },
};
