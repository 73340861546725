<template>
  <BContentBox v-loading="$wait.is('fetchCurrentUser')">
    <template #content>
      <div class="setting-title">
        {{ $t('userEmail.apiConnections.title') }}
      </div>
      <div class="explain">
        {{ $t('userEmail.apiConnections.explain') }}
      </div>
      <GoogleApi
        :user="currentUser"
        @update:user="fetch"
      />
      <SenderEmail
        :user="currentUser"
        @update:user="fetch"
      />
      <MailSignature />
    </template>
  </BContentBox>
</template>

<script lang="ts" setup>
import { onBeforeMount } from 'vue';
import { useCurrentUser } from '@/composable/user/user/users';
import { useWait } from '@/composable/vue-wait';
import GoogleApi from './userEmail/GoogleApi.vue';
import MailSignature from './userEmail/MailSignature.vue';
import SenderEmail from './userEmail/SenderEmail.vue';

const { doActionWithWait } = useWait();
const { currentUser, fetchCurrentUser } = useCurrentUser();
onBeforeMount(async () => {
  await fetch();
});

const fetch = () => {
  doActionWithWait('fetchCurrentUser', async () => {
    await fetchCurrentUser();
  });
};
</script>

<style lang="scss" scoped>
:deep(.setting-title) {
  font-size: $fontsize-400;
  font-weight: bold;
  margin-bottom: $basespace-150;

  &:not(:first-child) {
    margin-top: 80px;
  }
}
:deep(.explain) {
  color: $textcolor-light;
  white-space: pre-wrap;
  margin-bottom: $basespace-400;
}

@media only screen and (min-width: 959px) {
  :deep(.form .w-input), :deep(.api-connection) {
    width: 40%;
  }
}
:deep(.api-connection) {
  border: 1px solid $concrete;
  border-radius: 5px;
  padding: 20px 30px;

  .title {
    font-size: $fontsize-300;
    font-weight: bold;
    height: 40px;
    display: flex;
    align-items: center;
    margin-bottom: $basespace-300;

    img {
      height: 30px;
      margin-right: 15px;
    }
    .status {
      border-radius: 2px;
      padding: 4px 8px;
      background-color: $concrete-dark;
      color: $paper;
      font-size: 12px;
      font-weight: normal;
      line-height: 12px;
      margin-left: $basespace-100;
    }
  }
  .explain {
    color: $textcolor-base;
    line-height: 2;
  }
  .connected-email {
    margin-right: auto;
  }
  .more-menu {
    display: flex;
    align-items: center;
  }
  .b-btn {
    width: 130px;
  }
}
.content-box {
  :deep(.footer) {
    padding-bottom: 0 !important;
    height: 49px !important;
    min-height: 49px;

    display: flex;
    gap: 30px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: $bgcolor-dark;
  }
}
</style>
