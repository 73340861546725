import ApiBase from '@/api/base';

export default {
  getDownloadCallTargets(args) {
    const { id } = args;
    return ApiBase.get(`/user/call_lists/${id}/download_call_targets`, args);
  },
  checkImportExcelData(args) {
    return ApiBase.post(`/user/check_import_excel_data`, args);
  },
  checkImportCsvData(args) {
    return ApiBase.post(`/user/check_import_csv_data`, args);
  },
  importCsvData(args) {
    return ApiBase.post(`/user/import_csv_data`, args);
  },
  importExcelData(args) {
    return ApiBase.post(`/user/import_excel_data`, args);
  },
  checkOverwriteExcelData(args) {
    return ApiBase.post(`/user/check_overwrite_excel_data`, args);
  },
  checkOverwriteCsvData(args) {
    return ApiBase.post(`/user/check_overwrite_csv_data`, args);
  },
  overwriteCsvData(args) {
    return ApiBase.post(`/user/overwrite_csv_data`, args);
  },
  overwriteExcelData(args) {
    return ApiBase.post(`/user/overwrite_excel_data`, args);
  },
  downloadExcelTemplate(args) {
    args.responseType = 'blob';
    return ApiBase.get(`/user/downloads/excel_template`, args);
  },
  customFieldsImportTemplate(args) {
    args.responseType = 'blob';
    return ApiBase.get(`/user/downloads/custom_fields_import_template`, args);
  },
  checkOverwriteExistingData(args) {
    return ApiBase.post(`/user/check_overwrite_existing_data`, args);
  },
  checkOverwriteDataResult(args) {
    const { jobManagerId } = args;
    return ApiBase.get(`/user/check_overwrite_data_result/${jobManagerId}`, args);
  },
  overwriteExistingData(args) {
    return ApiBase.post(`/user/overwrite_existing_data`, args);
  },
  getLeadCsvCheckImportTasks(args) {
    const { jobManagerId } = args;
    return ApiBase.pollingGet(`/user/lead_csv_check_import_tasks/${jobManagerId}`, args);
  },
  getLeadExcelCheckImportTasks(args) {
    const { jobManagerId } = args;
    return ApiBase.pollingGet(`/user/lead_excel_check_import_tasks/${jobManagerId}`, args);
  },
  getLeadCsvCheckOverwriteTasks(args) {
    const { jobManagerId } = args;
    return ApiBase.pollingGet(`/user/lead_csv_check_overwrite_tasks/${jobManagerId}`, args);
  },
  getLeadExcelCheckOverwriteTasks(args) {
    const { jobManagerId } = args;
    return ApiBase.pollingGet(`/user/lead_excel_check_overwrite_tasks/${jobManagerId}`, args);
  },
  getLeadCsvImportTasks(args) {
    return ApiBase.pollingGet(`/user/show_csv_import_tasks`, args);
  },
  confirmLeadCsvImportTask(args) {
    const { leadCsvImportTaskId } = args;
    return ApiBase.put(`/user/confirm_csv_import_task/${leadCsvImportTaskId}`, args);
  },
  getLeadExcelImportTasks(args) {
    return ApiBase.pollingGet(`/user/show_excel_import_tasks`, args);
  },
  confirmLeadExcelImportTask(args) {
    const { leadExcelImportTaskId } = args;
    return ApiBase.put(`/user/confirm_excel_import_task/${leadExcelImportTaskId}`, args);
  },
  getLeadCsvOverwriteTasks(args) {
    return ApiBase.pollingGet(`/user/show_csv_overwrite_tasks`, args);
  },
  confirmLeadCsvOverwriteTask(args) {
    const { leadCsvOverwriteTaskId } = args;
    return ApiBase.put(`/user/confirm_csv_overwrite_task/${leadCsvOverwriteTaskId}`, args);
  },
  getLeadExcelOverwriteTasks(args) {
    return ApiBase.pollingGet(`/user/show_excel_overwrite_tasks`, args);
  },
  confirmLeadExcelOverwriteTask(args) {
    const { leadExcelOverwriteTaskId } = args;
    return ApiBase.put(`/user/confirm_excel_overwrite_task/${leadExcelOverwriteTaskId}`, args);
  },
  createLeadExportTask(args) {
    return ApiBase.post(`/user/lead_export_tasks`, args);
  },
  getLeadExportTasks(args) {
    return ApiBase.pollingGet(`/user/lead_export_tasks`, args);
  },
  getLeadDeleteTasks(args) {
    return ApiBase.pollingGet(`/user/show_lead_delete_tasks`, args);
  },
  confirmLeadDeleteTask(args) {
    const { leadDeleteTaskId } = args;
    return ApiBase.put(`/user/confirm_lead_delete_task/${leadDeleteTaskId}`, args);
  },
  getLeadsCsv(args) {
    const { leadExportTaskId } = args;
    return ApiBase.get(`/user/lead_export_tasks/${leadExportTaskId}/download`);
  },
};
