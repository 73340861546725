import Encoding from 'encoding-japanese';

export default class EncodingService {
  // charset: SJIS, UNICODE, UTF8
  convertStringToBlob(str, charset) {
    const charArray = Encoding.convert(Encoding.stringToCode(str), { to: charset });
    const blob = new Blob([new Uint8Array(charArray)], { type: 'text/csv' });
    return blob;
  }
  encodeArrayBufferToBlob(array) {
    const blob = new Blob([new Uint8Array(array)], { type: 'text/csv' });
    return blob;
  }
}
