/* tslint:disable */
/* eslint-disable */
/**
 * openapi
 * bales cloud
 *
 * The version of the OpenAPI document: 1.0
 * Contact: test@example.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { MailAttachment } from '../models';
// @ts-ignore
import { MailAttachmentPostBody } from '../models';
/**
 * MailAttachmentApi - axios parameter creator
 * @export
 */
export const MailAttachmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * メール添付ファイル一覧取得API
         * @summary メール添付ファイル一覧取得API
         * @param {Array<number>} [ids] ID一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailAttachments: async (ids?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/mail_attachments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)

            if (ids) {
                localVarQueryParameter['ids[]'] = ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * メール添付ファイル情報作成API
         * @summary メール添付ファイル情報作成API
         * @param {MailAttachmentPostBody} [mailAttachmentPostBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMailAttachment: async (mailAttachmentPostBody?: MailAttachmentPostBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/mail_attachments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mailAttachmentPostBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MailAttachmentApi - functional programming interface
 * @export
 */
export const MailAttachmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MailAttachmentApiAxiosParamCreator(configuration)
    return {
        /**
         * メール添付ファイル一覧取得API
         * @summary メール添付ファイル一覧取得API
         * @param {Array<number>} [ids] ID一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMailAttachments(ids?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MailAttachment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMailAttachments(ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * メール添付ファイル情報作成API
         * @summary メール添付ファイル情報作成API
         * @param {MailAttachmentPostBody} [mailAttachmentPostBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postMailAttachment(mailAttachmentPostBody?: MailAttachmentPostBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailAttachment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postMailAttachment(mailAttachmentPostBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MailAttachmentApi - factory interface
 * @export
 */
export const MailAttachmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MailAttachmentApiFp(configuration)
    return {
        /**
         * メール添付ファイル一覧取得API
         * @summary メール添付ファイル一覧取得API
         * @param {Array<number>} [ids] ID一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailAttachments(ids?: Array<number>, options?: any): AxiosPromise<Array<MailAttachment>> {
            return localVarFp.getMailAttachments(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * メール添付ファイル情報作成API
         * @summary メール添付ファイル情報作成API
         * @param {MailAttachmentPostBody} [mailAttachmentPostBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMailAttachment(mailAttachmentPostBody?: MailAttachmentPostBody, options?: any): AxiosPromise<MailAttachment> {
            return localVarFp.postMailAttachment(mailAttachmentPostBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MailAttachmentApi - interface
 * @export
 * @interface MailAttachmentApi
 */
export interface MailAttachmentApiInterface {
    /**
     * メール添付ファイル一覧取得API
     * @summary メール添付ファイル一覧取得API
     * @param {Array<number>} [ids] ID一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailAttachmentApiInterface
     */
    getMailAttachments(ids?: Array<number>, options?: AxiosRequestConfig): AxiosPromise<Array<MailAttachment>>;

    /**
     * メール添付ファイル情報作成API
     * @summary メール添付ファイル情報作成API
     * @param {MailAttachmentPostBody} [mailAttachmentPostBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailAttachmentApiInterface
     */
    postMailAttachment(mailAttachmentPostBody?: MailAttachmentPostBody, options?: AxiosRequestConfig): AxiosPromise<MailAttachment>;

}

/**
 * Request parameters for getMailAttachments operation in MailAttachmentApi.
 * @export
 * @interface MailAttachmentApiGetMailAttachmentsRequest
 */
export interface MailAttachmentApiGetMailAttachmentsRequest {
    /**
     * ID一覧
     * @type {Array<number>}
     * @memberof MailAttachmentApiGetMailAttachments
     */
    readonly ids?: Array<number>
}

/**
 * Request parameters for postMailAttachment operation in MailAttachmentApi.
 * @export
 * @interface MailAttachmentApiPostMailAttachmentRequest
 */
export interface MailAttachmentApiPostMailAttachmentRequest {
    /**
     * 
     * @type {MailAttachmentPostBody}
     * @memberof MailAttachmentApiPostMailAttachment
     */
    readonly mailAttachmentPostBody?: MailAttachmentPostBody
}

/**
 * MailAttachmentApi - object-oriented interface
 * @export
 * @class MailAttachmentApi
 * @extends {BaseAPI}
 */
export class MailAttachmentApi extends BaseAPI implements MailAttachmentApiInterface {
    /**
     * メール添付ファイル一覧取得API
     * @summary メール添付ファイル一覧取得API
     * @param {MailAttachmentApiGetMailAttachmentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailAttachmentApi
     */
    public getMailAttachments(requestParameters: MailAttachmentApiGetMailAttachmentsRequest = {}, options?: AxiosRequestConfig) {
        return MailAttachmentApiFp(this.configuration).getMailAttachments(requestParameters.ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * メール添付ファイル情報作成API
     * @summary メール添付ファイル情報作成API
     * @param {MailAttachmentApiPostMailAttachmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailAttachmentApi
     */
    public postMailAttachment(requestParameters: MailAttachmentApiPostMailAttachmentRequest = {}, options?: AxiosRequestConfig) {
        return MailAttachmentApiFp(this.configuration).postMailAttachment(requestParameters.mailAttachmentPostBody, options).then((request) => request(this.axios, this.basePath));
    }
}
