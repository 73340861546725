<template>
  <BPopover
    v-model="filterItemPopoverVisible"
    placement="right"
    :width="popoverWidth"
    trigger="click"
    full
    :disabled="readonly"
    @hide="handleHide"
    @show="handleShow"
  >
    <template #reference>
      <span>
        <div
          class="filter-item"
          :class="{ 'popopver-show': filterItemPopoverVisible }"
          @mouseover="handleItemMouseover"
          @mouseleave="handleItemMouseleave"
        >
          <BBtn
            v-if="removeButtonVisible && !readonly"
            class="remove-button"
            size="mini"
            fab
            outline
            @click.stop="handleRemoveButtonClick"
          >
            <BIcon size="mini">
              close
            </BIcon>
          </BBtn>
          <BListItem>
            <template #header>
              <div class="title">
                {{ title }}
              </div>
              <div class="filter-type">
                {{ filterTypeForDisplay }}
              </div>
            </template>
            <div
              v-loading="loading"
              class="display-value"
            >
              {{ displayValue }}
            </div>
          </BListItem>
        </div>
      </span>
    </template>
    <div
      class="filter-item-popover"
      @click.stop
    >
      <div class="filter-item-popover-header">
        <div class="title fw-bold">
          {{ title }}
        </div>
        <div
          class="filter-type-select-container"
        >
          <BSelect
            v-model="filterType"
            :items="filterTypes"
            :teleported="false"
          />
        </div>
      </div>
      <div class="filter-item-popover-body">
        <slot />
      </div>
      <div class="filter-item-popover-footer">
        <BLayout
          align-center
          justify-center
        >
          <BBtn
            class="close-button"
            size="mini"
            text
            @click="handleCloseButtonClick"
          >
            {{ $t('general.close.text') }}
          </BBtn>
          <BBtn
            class="apply-button"
            size="mini"
            type="primary"
            :disabled="!valid"
            @click="handleApplyButtonClick"
          >
            {{ $t('general.apply') }}
          </BBtn>
        </BLayout>
      </div>
    </div>
  </BPopover>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import type { TFilterParam, TFilterType } from '@/composable/user/leadList/lead-filter';
import { useFilterItemSelect, useFilterParams, useFilterTypes } from '@/composable/user/leadList/lead-filter';

type TSize = 'middle';
type TProps = {
  filterItemKey: string;
  title: string;
  displayValue: string;
  filterParam: TFilterParam | undefined;
  valid?: boolean;
  size?: TSize;
  readonly?: boolean;
};
const props = withDefaults(defineProps<TProps>(), {
  valid: true,
  size: 'middle',
});
const emit = defineEmits<{
  'popoverShow': [];
  'search': [];
  'cancel': [];
}>();
const filterType = defineModel<TFilterType>('filterType');

const { filterTypes, filterTypeForDisplay } = useFilterTypes(() => filterType.value);
const { filterItemPopoverVisible, closeFilterItemPopover } = useFilterItemSelect(() => props.filterItemKey, () => props.readonly);
const { removeFilterItemAndFetchLeadList } = useFilterParams();

const removeButtonVisible = ref(false);
const canceling = ref(false);

const loading = computed(() => props.displayValue === '');

// NOTE: 適宜sizeの選択肢を増やして対応させてください
const popoverWidth = computed(() => 260);

const handleShow = () => {
  canceling.value = false;
  emit('popoverShow');
};
const handleHide = () => {
  // NOTE: キャンセル・マニュアルでの非表示の両方で呼ばれるため、キャンセルの場合は無視する
  if (canceling.value) return;
  emit('search');
};
const handleApplyButtonClick = () => {
  emit('search');
  closeFilterItemPopover();
};
const handleCloseButtonClick = () => {
  canceling.value = true;
  emit('cancel');
  closeFilterItemPopover();
};
const handleItemMouseover = () => {
  removeButtonVisible.value = true;
};
const handleItemMouseleave = () => {
  removeButtonVisible.value = false;
};
const handleRemoveButtonClick = () => {
  removeFilterItemAndFetchLeadList(props.filterItemKey);
};
</script>

<style lang="scss" scoped>
.filter-item {
  position: relative;
  background: $bgcolor-base;
  border-radius: 4px;
  padding: $basespace-100 $basespace-200;
  margin-top: $basespace-300;
  margin-bottom: $basespace-200;
  margin-left: $basespace-100;
  cursor: pointer;
  border: 1px solid transparent;
  transition: $transition-base;
  outline: none;
  word-break: break-word;

  &:hover {
    border: 1px solid $bdcolor-base;
  }

  &.popopver-show {
    border: 1px solid $bdcolor-dark;
  }

  .title {
    font-weight: $font-weight-bold;
    padding-bottom: $basespace-50;
  }
  .filter-type {
    padding-bottom: $basespace-100;
  }
  .display-value.el-loading-parent--relative {
    height: 42px; // loadingの高さ
  }
  .remove-button {
    position: absolute;
    top: -13px;
    left: -13px;
    background-color: $bgcolor-white;
  }
}

.filter-item-popover {
  &-header {
    border-bottom: 1px solid $bdcolor-base;
    padding: $basespace-200 $basespace-500;

    .title{
      width: 100%;
      font-weight: $font-weight-bold;
    }
    .filter-type-select-container {
      margin-top: $basespace-200;
    }
  }

  &-body {
    max-height: 80vh;
    overflow-y: auto;
    padding: $basespace-300 $basespace-500;
  }

  &-footer {
    padding-top: 6px;
    padding-bottom: 10px;
    background-color: $bgcolor-base;
  }

  .close-button {
    @include m-fixed-width(96px);
    margin-right: 10px;
  }

  .apply-button {
    @include m-fixed-width(96px);
  }
}
</style>
