import { AxiosResponse } from 'axios';
import ApiService, { ApiArguments } from '@/api/api_service';
import { CustomFieldOptionDestroyTask, CustomFieldOptionDestroyTaskApi, CustomFieldOptionDestroyTaskApiPutCustomFieldOptionDestroyTaskRequest } from '@/api/openapi';

export class CustomFieldOptionDestroyTaskApiService extends ApiService {
  api = new CustomFieldOptionDestroyTaskApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true);
  }

  async getCustomFieldOptionDestroyTasks({
    options,
    errorHandlers,
  }: ApiArguments<undefined>): Promise<AxiosResponse<CustomFieldOptionDestroyTask[]>> {
    return this.checkResponse<CustomFieldOptionDestroyTask[]>(
      this.api.getCustomFieldOptionDestroyTasks(options),
      errorHandlers,
    );
  }

  async confirmCustomFieldOptionDestroyTask({
    request,
    options,
    errorHandlers,
  }: ApiArguments<CustomFieldOptionDestroyTaskApiPutCustomFieldOptionDestroyTaskRequest>): Promise<AxiosResponse<void>> {
    return this.checkResponse<void>(
      this.api.putCustomFieldOptionDestroyTask(request, options),
      errorHandlers,
    );
  }
}
