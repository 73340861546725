<template>
  <div
    id="sisenseApp"
    style="width: 100%;"
  >
    <div
      id="widget1"
      style="height: 400px; width: 35%; float: left; top: 0px; display: inline; margin-top:30px;"
    />
    <div
      id="filters"
      style="height: 400px; width: 25%; float: left; top: 80px; left: 0px; display: inline; margin-top:80px;"
    />
  </div>
</template>

<script lang="ts" setup>
import { onMounted } from 'vue';

onMounted(async () => {
  Sisense.connect(import.meta.env.VITE_SISENSE_URL)
    .then((app) => {
      app.dashboards.load('64ae4d2d4708eb0032b90ab4')
        .then((dash) => {
          dash.widgets.get('64ae4dd84708eb0032b90ac8').container = document.getElementById('widget1');
          dash.renderFilters(document.getElementById('filters'));
          dash.refresh();
        })
        .catch((e) => {
          console.error(e);
        });
    })
    .catch((e) => {
      console.error(e);
    });
});
</script>
