import Waiting from '@/store/waiting';

export default class ClientLimitSettingActions {
  constructor(Api, types) {
    const actions = {

      async getClientLimitSettingAction() {
        return await waiting.check('getClientLimitSettingAction', async () => {
          try {
            const res = await Api.getClientLimitSetting();
            return Object.values(res.data)[0];
          } catch (err) {
            throw err;
          }
        });
      },
    };
    const waiting = new Waiting(actions);
    this.actions = actions;
  }
}
