<template>
  <ElIcon :class="[classes]">
    <Component :is="name" />
  </ElIcon>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'default',
    },
    size: {
      type: String,
      default: 'small',
    },
    hover: Boolean,
  },
  computed: {
    classes () {
      return {
        'b-icon': true,
        'b-icon-default': this.type === 'default',
        'b-icon-white': this.type === 'white',
        'b-icon-gray': this.type === 'gray',
        'b-icon-primary': this.type === 'primary',
        'b-icon-success': this.type === 'success',
        'b-icon-error': this.type === 'error',
        'b-icon-warning': this.type === 'warning',
        'b-icon-mini': this.size === 'mini',
        'b-icon-small': this.size === 'small',
        'b-icon-medium': this.size === 'medium',
        'b-icon-large': this.size === 'large',
        'b-icon-extra-large': this.size === 'extra-large',
        'b-icon-super-extra-large': this.size === 'super-extra-large',
        'b-icon-hover': this.hover,
        'is-loading': this.name === 'Loading',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
  .b-icon {
    font-feature-settings: 'liga';
    font-size: 20px;
    line-height: 1;
    transition: $transition-base;
    color: rgba($textcolor-base, .6);
  }

  // --- Type
  .b-icon-default {
    color: $textcolor-base;
  }

  .b-icon-white {
    color: $textcolor-white;
  }

  .b-icon-gray {
    color: rgba($textcolor-light, .6);
  }

  .b-icon-primary {
    color: $basecolor-primary;
  }

  .b-icon-success {
    color: $basecolor-success;
  }

  .b-icon-error {
    color: $basecolor-error;
  }

  .b-icon-warning {
    color: $basecolor-warning;
  }

  .b-icon-mini {
    font-size: 12px;
  }

  .b-icon-small {
    font-size: 14px;
  }

  .b-icon-medium {
    font-size: 16px;
  }

  .b-icon-large {
    font-size: 20px;
  }

  .b-icon-extra-large {
    font-size: 24px;
  }

  .b-icon-super-extra-large {
    font-size: 80px;
  }

  .b-icon-hover {
    background: transparent;
    border: none;
    border-radius: 50%;
    padding: $basespace-50;
    &:hover {
      background-color: $bgcolor-dark;
    }
  }
  .el-icon {
    height: auto;
    width: auto;
  }
</style>
