<template>
  <BModal
    :modal-name="modalName"
    width="70%"
    height="85%"
  >
    <BHeightAdjuster>
      <BModalHeader
        :title="$t('general.select.of', { item: $t('quickText.title') })"
        @modal-close="handleCloseClick"
      />
      <BModalBody full>
        <QuickTextSelectTable
          :call-target-id="props.callTargetId"
          @select="handleSelect"
        />
      </BModalBody>
    </BHeightAdjuster>
  </BModal>
</template>

<script lang="ts" setup>
import { inject } from 'vue';
import { KEY_OF_MODAL } from '@/injection-keys';
import BHeightAdjuster from '@/plugins/biscuet-materials/components/atoms/BHeightAdjuster.vue';
import QuickTextSelectTable from './QuickTextSelectTable.vue';

type TEmits = {
  select: [content: string];
};
type TProps = {
  callTargetId: number;
  modalName: string;
};
const props = defineProps<TProps>();
const emit = defineEmits<TEmits>();

const modal = inject(KEY_OF_MODAL);

const handleCloseClick = () => {
  modal.hide(props.modalName);
};

const handleSelect = (content: string) => {
  emit('select', content);
  handleCloseClick();
};
</script>
