<template>
  <BLayout
    class="instance-list-row"
    align-center
  >
    <div
      class="instance-list-item checkbox"
      @click.stop
    >
      <BCheckbox
        v-model="checkboxValue"
        class="mt-0"
        :indeterminate="checkboxValue"
      />
    </div>
    <div
      v-for="column in columns"
      :key="column.key"
      class="instance-list-item instance-list-header"
      :style="{
        'width': column.width,
        'min-width': column.width,
        'max-width': column.width
      }"
    >
      <span>{{ toLabel(column.key) }}</span>
    </div>
    <div class="instance-list-item end-column" />
  </BLayout>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { columns } from './instance-list-header';

type TProps = {
  modelValue: boolean;
};
type TEmit = {
  'update:modelValue': [checked: boolean];
};

const props = defineProps<TProps>();
const emit = defineEmits<TEmit>();

const i18n = useI18n();
const checkboxValue = computed<boolean>({
  get() {
    return props.modelValue;
  },
  set(value: boolean) {
    emit('update:modelValue', value);
  },
});

const toLabel = (key: string) => {
  if (key === 'companyName') return i18n.t(`companyInfo.item.companyName`);
  if (key === 'fullName') return i18n.t(`callTargetInfo.item.name`);
  if (key === 'registrationDate') return i18n.t(`sequence.registrationDate`);
  if (key === 'status') return i18n.t(`sequence.status.title`);
  if (key === 'isOpportunityAssociated') return i18n.t(`sequence.isOpportunityAssociated`);
  if (key === 'lastLeadStage') return i18n.t(`leadRelatedInfo.lastLeadStageName`);
  if (key === 'numberOfDoneSteps') return i18n.t(`sequence.numberOfDoneSteps`);
  if (key === 'numberOfCurrentStepStays') return i18n.t(`sequence.numberOfCurrentStepStays`);
  if (key === 'owner') return i18n.t(`leadRelatedInfo.owner`);
};
</script>

<style lang="scss" scoped>
.checkbox {
  @include m-fixed-width(40px);
  z-index: 0;
}

.instance-list-row {
  transition: $transition-base;
  @include m-fixed-height(40px);

  .instance-list-item {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 $basespace-200;
    border-bottom: 1px solid $bdcolor-light;
    background-color: $bgcolor-white;
  }

  .instance-list-header {
    font-size: $fontsize-100;
    color: $textcolor-light;
  }

  .end-column {
    width: 100%;
  }
}
</style>
