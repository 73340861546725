import Waiting from '@/store/waiting';

export default class SendgridSubUserActions {
  constructor(Api, types) {
    const actions = {
      async getSendgridSubUsersAction({ commit }, args) {
        await waiting.check('getSendgridSubUsersAction', async () => {
          try {
            const res = await Api.getSendgridSubUsers(args);
            commit(types.SET_SENDGRID_SUB_USERS, res.data);
          } catch (err) {
            commit(types.CLEAR_SENDGRID_SUB_USERS);
            throw err;
          }
        });
      },
      async deleteSendgridSubUserAction({ commit, dispatch }, args) {
        await waiting.check('deleteSendgridSubUserAction', async () => {
          try {
            await Api.deleteSendgridSubUser(args);
            await dispatch('getSendgridSubUsersAction', args);
          } catch (err) {
            throw err;
          }
        });
      },
    };
    const waiting = new Waiting(actions);
    this.actions = actions;
  }
}
