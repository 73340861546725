import ApiBase from '@/api/base';

export default {
  getCallResultTypes (args) {
    return ApiBase.get(`/user/call_result_types`);
  },
  createCallResultType (args) {
    return ApiBase.post(`/user/call_result_types`, args);
  },
  deleteCallResultType (args) {
    return ApiBase.delete(`/user/call_result_types/${args.id}`, args);
  },
  // 単体更新
  updateCallResultType (args) {
    return ApiBase.put(`/user/call_result_types/${args.id}`, args);
  },
  // 一括更新
  updateCallResultTypes (args) {
    return ApiBase.put(`/user/call_result_types`, args);
  },
};
