import ApiService, { ApiArguments } from '@/api/api_service';
import ApiBase from '@/api/base';
import { CallResultApi } from '@/api/openapi';
import type { CallResult, CallResultApiGetCallResultRequest } from '@/api/openapi';

export class CallResultApiService extends ApiService {
  api = new CallResultApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true);
  }

  async getCallResult(
    {
      request,
      options,
      errorHandlers,
    }: ApiArguments<CallResultApiGetCallResultRequest> = {},
  ) {
    return this.checkResponse<CallResult>(
      this.api.getCallResult(request, options),
      errorHandlers,
    );
  }
}

export default {
  createCallResult (args) {
    const { callTargetId } = args;
    return ApiBase.post(`/user/call_targets/${callTargetId}/call_results`, args);
  },
  updateCallResult (args) {
    const { callResultId } = args;
    return ApiBase.put(`/user/call_results/${callResultId}`, args);
  },
  deleteCallResult (args) {
    const { callResultId } = args;
    return ApiBase.delete(`/user/call_results/${callResultId}`, args);
  },
};
