import actions from './actions';
import getters from './getters';
import { mutations } from './mutations';

const store = {
  namespaced: true,
  state: {
    authInfo: {
      companyId: '',
      accessKey: '',
    },
    currentState: {
      currentWidgetStatus: null,
    },
    isCalling: false,
    isInbound: false,
    callInfo: {
      startedAt: '',
      endedAt: '',
      url: '',
      connected: false,
    },
  },
  mutations,
  getters,
  actions,
};

export default store;
