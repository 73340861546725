<template>
  <FilterDetailPopover
    :title="title"
    :is-display-only="isDisplayOnly"
    :key-name="keyName"
    @hide="popoverHide"
    @show="popoverShow"
  >
    <template #reference>
      <BLayout
        align-center
      >
        <BListItem>
          <template #header>
            <BLayout
              justify-space-between
            >
              <div>{{ title }}</div>
            </BLayout>
          </template>
          <div>{{ value }}</div>
        </BListItem>
      </BLayout>
    </template>
    <div>
      <FilterItemRadio
        v-model:selected-item="selectedItem"
        :radio-items="radioItems"
      />
    </div>
  </FilterDetailPopover>
</template>

<script>
import FilterDetailPopover from '@/components/organisms/user/leadList/filter/FilterDetailPopover.vue';
import FilterItemRadio from '@/components/organisms/user/leadList/filter/FilterItemRadio.vue';

export default {
  components: {
    FilterDetailPopover,
    FilterItemRadio,
  },
  props: {
    leadListFilterParams: {
      type: Object,
      default () {
        return {};
      },
    },
    isDisplayOnly: {
      type: Boolean,
      default: false,
    },
    keyName: {
      type: String,
      require: true,
    },
  },
  emits: [
    'hide',
  ],
  data () {
    return {
      selectedItem: '',
    };
  },
  computed: {
    radioItems () {
      return Object.entries(this.$tm('filterBulkMailEvent')).map(([k, v]) => ({ 'displayValue': this.$rt(v), 'label': k }));
    },
    title () {
      return this.$t(`filterSelect.historyInfo.bulkMailItem.${this.keyName}`);
    },
    value() {
      if (!this.targetDetail) return '-';
      return this.radioItems.find(r => r.label === this.targetDetail)?.displayValue; 
    },
    target () {
      return this.leadListFilterParams[this.keyName];
    },
    targetDetail () {
      if (!this.target) return '';
      return this.target.inc.or;
    },
  },
  methods: {
    popoverShow() {
      this.selectedItem = this.targetDetail;
    },
    popoverHide() {
      this.$emit('hide', { inc: { or: this.selectedItem } });
    },
  },
};
</script>

<style lang="scss" scoped>
  .grouping-text {
    color: $basecolor-cold;
    &.is-include {
      color: $basecolor-hot;
    }
  }
</style>
