<template>
  <MultipleSelectFilterCommon
    :title="title"
    :filter-item-key="keyName"
    :options="options"
    :readonly="isDisplayOnly"
    :filter-param="filterParam"
  />
</template>

<script lang="ts" setup>
import { computed, onMounted } from 'vue';
import { useVuexFreeTags } from '@/composable/user/freeTag/free-tag';
import { useGeneralFilterItem } from '@/composable/user/leadList/lead-filter';
import type { TCandidates } from '../FilterItemCheckbox.vue';
import MultipleSelectFilterCommon from './MultipleSelectFilterCommon.vue';
import type { TPropsCommon, TFilterParam } from './MultipleSelectFilterCommon.vue';

type TTarget = (typeof TARGET_LIST)[keyof typeof TARGET_LIST];
type TProps = TPropsCommon & {
  leadListFilterParams: {
    [key: string]: TFilterParam;
  };
  // FIXME: 既存処理との差分吸収。本当は利用元から修正したい
  keyName: TTarget;
  isDisplayOnly?: boolean;
};

const props = withDefaults(defineProps<TProps>(), {
  isDisplayOnly: false,
});

const { filterParam } = useGeneralFilterItem<TFilterParam>(
  () => props.leadListFilterParams,
  () => props.keyName,
);

const { freeTagsCandidates, fetchAll: fetchFreeTags } = useVuexFreeTags();

// NOTE: 本当はComponentの種類を指定できるようにするとより汎用性があるのでそうしたい。
// 現状は、利用先にPropsを追加するのが困難なのでここで名称で判定することにして吸収しています。
const options = computed<TCandidates>(() => {
  switch (props.keyName) {
    case 'freeTags':
      return freeTagsCandidates.value;
    default:
      return [];
  }
});

onMounted(async () => {
  switch (props.keyName) {
    case 'freeTags':
      await fetchFreeTags();
      break;
    default:
      break;
  }
});
</script>

<script lang="ts">
export const TARGET_LIST = {
  freeTags: 'freeTags',
} as const;
</script>
