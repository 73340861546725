<template>
  <NumberFilterCommon
    :title="title"
    :value="value"
    :target="target"
    :key-name="keyName"
    :is-display-only="isDisplayOnly"
    :with-inc-exc="false"
    :with-no-input="false"
    :min="1"
  />
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import type { TFilterParam, TPropsCommon } from './NumberFilterCommon.vue';
import NumberFilterCommon from './NumberFilterCommon.vue';

type TProps = TPropsCommon & {
  leadListFilterParams?: { [key: string]: TFilterParam };
};
const props = defineProps<TProps>();

const target = computed(() => props.leadListFilterParams[props.keyName]);
</script>
