<template>
  <BLayout
    align-center
    justify-center
  >
    <BInput
      v-model="name"
      v-model:valid="valid"
      class="input-name mr-400"
      :placeholder="$t('leadStage.name')"
      :rules="[requiredRule]"
      :max-length="maxNameLength"
      @keypress-enter="handleSubmit"
    />
    <BLeadStageColorSelect
      v-model="colorName"
    />
    
    <BBtn
      type="primary"
      :disabled="!valid"
      :loading="$wait.is('createLeadStageWait')"
      @click="handleSubmit"
    >
      <span>{{ $t('general.append.text') }}</span>
    </BBtn>
  </BLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapWaitingActions } from 'vue-wait';
import errorHandler from '@/mixins/error_handler';
import inputValidation from '@/mixins/input_validation';
import { MAX_NAME_LENGTH } from './lead_stage';

type TData = {
  name: string;
  colorName: string;
  valid: boolean;
}

export default defineComponent({
  mixins: [inputValidation, errorHandler],
  emits: {
    afterCreate() {
      return true;
    },
  },
  data (): TData {
    return {
      name: '',
      colorName: 'uranus',
      valid: false,
    };
  },
  computed: {
    maxNameLength() {
      return MAX_NAME_LENGTH;
    },
  },
  methods: {
    ...mapWaitingActions('user', {
      createLeadStageAction: 'createLeadStageWait',
    }),
    async handleSubmit(): Promise<void> {
      await this.createLeadStageAction({
        request: {
          postLeadStageBody: {
            name: this.name,
            colorName: this.colorName,
          },
        },
      });
      this.$emit('afterCreate');
    },
  },
});
</script>

<style lang="scss" scoped>
:deep(.input-name.b-input-wrapper) {
  width: $setting-input-width;
  margin-right: 10px;
}
:deep(.color-select) {
  width: 140px;
  margin-right: 10px;
}
</style>
