import { StoreDataFetchManager } from '@/store/store-data-fetch-manager';
import Waiting from '@/store/waiting';

export default class NextActionActions {
  constructor (Api, types) {
    const actions = {
      async getNextActionsAction ({ commit }, args) {
        await waiting.checkWithArg('getNextActionsAction', args, async () => {
          try {
            const res = await storeDataFetchManager.fetch('getNextActionsAction', async () => await Api.getNextActions(args));
            commit(types.SET_NEXT_ACTIONS, Object.values(res.data)[0]);
          } catch (err) {
            commit(types.CLEAR_NEXT_ACTIONS);
            throw err;
          }
        });
      },
      async createNextActionAction ({ commit, dispatch, state }, args) {
        await waiting.check('createNextActionAction', async () => {
          await Api.createNextAction(args);
          try {
            const res = await Api.getNextActions(args);
            const nextActions = Object.values(res.data)[0];
            commit(types.SET_NEXT_ACTIONS, nextActions);
            // TODO NextAction時には一旦Taskを作成しない方針に(2021/11)
            // await dispatch('createNextActionSfTaskAction', {
            //   body: {
            //     callTargetId: args.callTargetId,
            //     targetId: createdNextAction.data.id,
            //   },
            // })
          } catch (err) {
            throw err;
          }
        });
      },
      async updateNextActionAction ({ commit }, args) {
        await waiting.check('updateNextActionAction', async () => {
          await Api.updateNextAction(args);
          try {
            const res = await Api.getNextActions(args);
            commit(types.SET_NEXT_ACTIONS, Object.values(res.data)[0]);
          } catch (err) {
            throw err;
          }
        });
      },
      async updateNextActionContentAction ({ commit }, args) {
        await waiting.check('updateNextActionContentAction', async () => {
          await Api.updateNextActionContent(args);
          try {
            const res = await Api.getNextActions(args);
            commit(types.SET_NEXT_ACTIONS, Object.values(res.data)[0]);
          } catch (err) {
            throw err;
          }
        });
      },
      async doneNextAction ({ commit }, args) {
        await waiting.check('doneNextAction', async () => {
          await Api.doneNextAction(args);
          try {
            const res = await Api.getNextActions(args);
            commit(types.SET_NEXT_ACTIONS, Object.values(res.data)[0]);
          } catch (err) {
            throw err;
          }
        });
      },
      async doneCancelNextAction ({ commit }, args) {
        await waiting.check('doneCancelNextAction', async () => {
          await Api.doneCancelNextAction(args);
          try {
            const res = await Api.getNextActions(args);
            commit(types.SET_NEXT_ACTIONS, Object.values(res.data)[0]);
          } catch (err) {
            throw err;
          }
        });
      },
      async deleteNextActionAction ({ commit }, args) {
        const { nextActionId } = args;
        await waiting.check('deleteNextActionAction', async () => {
          await Api.deleteNextAction({ nextActionId });
          try {
            const res = await Api.getNextActions(args);
            commit(types.SET_NEXT_ACTIONS, Object.values(res.data)[0]);
          } catch (err) {
            throw err;
          }
        });
      },
    };
    const waiting = new Waiting(actions);
    const storeDataFetchManager = new StoreDataFetchManager();
    this.actions = actions;
  }
}
