import COLOR_CODES from '@/const/color_codes';
import { TColor, TSymbolColor } from '../../types';

const colors: TColor<TSymbolColor>[] = [
  { value: 'moon', colorCode: COLOR_CODES.MOON },
  { value: 'gaia', colorCode: COLOR_CODES.GAIA },
  { value: 'venus', colorCode: COLOR_CODES.VENUS },
  { value: 'neptune', colorCode: COLOR_CODES.NEPTUNE },
  { value: 'uranus', colorCode: COLOR_CODES.URANUS },
  { value: 'mars', colorCode: COLOR_CODES.MARS },
  { value: 'sun', colorCode: COLOR_CODES.SUN },
];

export default colors;
