<template>
  <div class="confirm-drawer">
    <BPlaceholder
      v-if="isLoading"
      v-loading="true"
      height="50vh"
      element-loading-background="transparent"
    />
    <MailDrawerTemplate
      v-else
      :title="$t('mail.confirmContent')"
      :width="600"
    >
      <template #drawerHeaderAction>
        <template v-if="editable">
          <template v-if="submitType === 'send'">
            <BBtn
              size="small"
              type="primary"
              class="mr-100"
              outline
              :loading="isLoading"
              @click="handleSendImmediatelyClick"
            >
              <span>{{ $t('general.sendImmediatelyButton') }}</span>
            </BBtn>
            <BBtn
              size="small"
              type="primary"
              outline
              :loading="isLoading"
              @click="handleReserveClick"
            >
              <span>{{ $t('mail.reserve') }}</span>
              <BIcon>keyboard_arrow_right</BIcon>
            </BBtn>
          </template>
          <template v-else>
            <BTooltip
              top
              :content="draftErrorMessage"
              :disabled="draftErrorMessage === ''"
            >
              <BBtn
                size="small"
                type="primary"
                class="mr-100"
                outline
                :loading="isLoading"
                :disabled="draftErrorMessage !== ''"
                @click="handleSaveAsDraftClick"
              >
                <span>{{ $t('mail.saveAs.draft') }}</span>
              </BBtn>
            </BTooltip>
            <BBtn
              size="small"
              type="primary"
              outline
              :loading="isLoading"
              @click="handleSaveAsReserveClick"
            >
              <span>{{ $t('mail.saveAs.reserve') }}</span>
            </BBtn>
          </template>
        </template>
      </template>
      <template #drawerBody>
        <ConfirmDrawerBodyCommon
          :direct-mail="displayDirectMail"
          :sender-data="senderData"
          :is-available-salesforce-api-feature="false"
          :use-sent-data="useSentData"
        >
          <template
            v-if="step.sendToName"
            #mailTo
          >
            <BListItem class="list-item mb-600">
              <template #header>
                <div>{{ $t('directMail.sendTo') }}</div>
              </template>
              <span>{{ `${step.sendToName} <${step.sendToAddress}>` }}</span>
            </BListItem>
          </template>
          <BListItem
            v-if="doneAt"
            class="list-item mb-600"
          >
            <template #header>
              <div>{{ $t('nextAction.doneAt') }}</div>
            </template>
            <span>{{ doneAt }}</span>
          </BListItem>
          <BListItem
            v-else-if="step.immediately"
            class="list-item mb-600"
          >
            <template #header>
              <span>{{ $t('sequence.step.dateInterval') }}</span>
            </template>
            <span>{{ $t('general.sendImmediately') }}</span>
          </BListItem>
          <template v-else>
            <BListItem class="list-item mb-300">
              <template #header>
                <div>{{ $t('sequence.step.dateInterval') }}</div>
              </template>
              <span>{{ step.dateInterval }}日</span>
            </BListItem>
            <BListItem class="list-item mb-600">
              <template #header>
                <div>{{ $t('sequence.step.reservedTime') }}</div>
              </template>
              <span>{{ step.reservedTime }}</span>
            </BListItem>
          </template>
        </ConfirmDrawerBodyCommon>
      </template>
      <template #drawerFooter>
        <BLayout justify-center>
          <BBtn
            v-if="editable"
            class="mr-100"
            @click="handleBackToEdit"
          >
            <BIcon>keyboard_arrow_left</BIcon>
            <span>{{ $t('mail.backToEdit') }}</span>
          </BBtn>
          <BBtn
            class="mr-100"
            flat
            @click="handleClose"
          >
            {{ $t('general.close.text') }}
          </BBtn>
        </BLayout>
      </template>
    </MailDrawerTemplate>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import MailDrawerTemplate from '@/components/organisms/user/mail/common/drawer/MailDrawerTemplate.vue';
import ConfirmDrawerBodyCommon from '@/components/organisms/user/mail/direct/drawer/state/confirm/ConfirmDrawerBodyCommon.vue';
import { TSubmitType } from '@/composable/user/sequence/step-actions';
import { formatDateTime } from '@/utils/date-time';
import { SenderData } from '../../../mail/direct/drawer/state/types';
import { TFormDirectMailSequenceStep } from '../types';

type TProps = {
  step: TFormDirectMailSequenceStep;
  isLoading: boolean;
  editable: boolean;
  senderData: SenderData | null;
  submitType: TSubmitType;
};

const props = defineProps<TProps>();
const emit = defineEmits([
  'edit', 'saveAsDraft', 'saveAsReserve', 'reserve', 'close', 'open', 'sendImmediately',
]);

const i18n = useI18n();

const doneAt = computed<string>(() => formatDateTime(props.step.doneAt));
const displayDirectMail = computed(() => ({
  ...props.step,
  sentSubject: props.step.replacedSubject,
  sentContent: props.step.replacedContent,
  notificationSetting: {
    open: props.step.open,
    click: props.step.click,
  },
}));
const useSentData = computed<boolean>(() => displayDirectMail.value.sentSubject != null || displayDirectMail.value.sentContent != null);

const draftErrorMessage = computed(() => props.step.immediately ? i18n.t('mail.saveAs.draftErrorMessage') : '');

const handleSaveAsDraftClick = () => emit('saveAsDraft');
const handleSaveAsReserveClick = () => emit('saveAsReserve');
const handleReserveClick = () => emit('reserve');
const handleSendImmediatelyClick = () => emit('sendImmediately');

const handleBackToEdit = () => emit('edit');
const handleClose = () => emit('close');
</script>

<style lang="scss" scoped>
.confirm-drawer {
  height: 100%;
}
</style>
