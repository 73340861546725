import ApiService, { ApiArguments } from '@/api/api_service';
import {
  SisenseApi,
  PostSisenseUser201Response,
  SisenseApiPostSisenseUserRequest,
} from '@/api/openapi';

export class SisenseApiService extends ApiService {
  api = new SisenseApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true);
  }

  async postSisenseUser({
    request,
    options,
    errorHandlers,
  }: ApiArguments<SisenseApiPostSisenseUserRequest>) {
    return this.checkResponse<PostSisenseUser201Response>(
      this.api.postSisenseUser(request, options),
      errorHandlers,
    );
  }
}
