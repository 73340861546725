<template>
  <ClientOnly>
    <Vue3Lottie
      :animation-data="animationData"
      :auto-play="autoPlay"
      :loop="loop"
      :speed="speed"
      @on-complete="handleAnimation"
    />
  </ClientOnly>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import mailSendAsPaperPlane from '@/assets/lottie/mail-send-as-paper-plane.json';

const emit = defineEmits(['complete']);
const animationData = ref(mailSendAsPaperPlane);
const autoPlay = ref(true);
const loop = ref(false);
const speed = ref(1.8);
const handleAnimation = () => {
  emit('complete');
};
</script>
