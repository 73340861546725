<template>
  <BListItem class="mb-200">
    <template #header>
      <div class="block-title">
        <div class="mr-100">
          {{ title }}
        </div>
        <BSelectMenu
          v-bind="attributes"
          :data-test="dataTest"
          @click-append="handleClickAppend"
          @click-delete="handleClickDelete"
        />
      </div>
    </template>
    <div class="block-content">
      <VWait
        class="full-width"
        for="none"
        :visible="$wait.is(['getCallTargetWait'])"
      >
        <template #waiting>
          <BContentLoader
            :height="32"
            :width="230"
          >
            <rect
              x="0"
              y="8.5"
              rx="6"
              ry="6"
              width="160"
              height="12"
            />
          </BContentLoader>
        </template>
        <BLayout wrap>
          <BLabel
            v-for="item in selectedItems"
            :key="`custom-field-multi-option-${item.id}`"
            class="mr-100 mb-100"
            round
          >
            <span>{{ item.label }}</span>
            <template #right>
              <BBtn
                flat
                fit
                size="mini"
                class="close-button"
                @click="() => handleClickDelete(item.id)"
              >
                <BIcon
                  type="gray"
                  size="mini"
                >
                  close
                </BIcon>
              </BBtn>
            </template>
          </BLabel>
        </BLayout>
      </VWait>
    </div>
  </BListItem>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { CustomFieldValueApiService } from '@/api/user/resources/custom_field_value';

type TCustomFieldOption = {
  id: number;
  label: string;
};

type TProps = {
  callTargetId: number;
  customFieldId: number;
  title: string;
  loading: boolean;
  items: TCustomFieldOption[]; // [{ id: xxxx, label: xxxx }]
  dataTest: string;
};

const props = defineProps<TProps>();
const api = new CustomFieldValueApiService();

const model = defineModel<string[]>({ default: [] });

const selectedItems = computed(() =>
  props.items.filter(item => model.value.includes(item.label)),
);

const attributes = computed(() => {
  return {
    'candidates': props.items,
    'selectedIds': selectedItems.value.map(item => item.id),
    'loading': props.loading,
    'item-text': 'label',
  };
});

const handleClickAppend = async (customFieldOptionId: number) => {
  const apiArguments = getApiArguments(customFieldOptionId);
  await api.postCallTargetCustomFieldValue(apiArguments);

  const item = props.items.find(item => item.id === customFieldOptionId);
  model.value = model.value.concat(item.label);
};

const handleClickDelete = async (customFieldOptionId: number) => {
  const apiArguments = getApiArguments(customFieldOptionId);
  await api.deleteCallTargetCustomFieldValue(apiArguments);

  const item = props.items.find(item => item.id === customFieldOptionId);
  model.value = model.value.filter(v => v !== item.label);
};

const getApiArguments = (customFieldOptionId: number) => {
  return {
    request: {
      callTargetId: props.callTargetId,
      callTargetCustomFieldValueBody: {
        customFieldValue: {
          customFieldId: props.customFieldId,
          customFieldOptionId,
        },
      },
    },
  };
};
</script>

<style lang="scss" scoped>
.block-title {
  display: flex;
  align-items: center;
  width: 100%;
}

.block-content {
  .close-button {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
