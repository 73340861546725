export default {
  authInfo (state) {
    return state.authInfo;
  },
  currentState (state) {
    return state.currentState;
  },
  isCallable (state) {
    return state.currentState.currentWidgetStatus === true;
  },
  isCalling (state) {
    return state.isCalling;
  },
  isInbound (state) {
    return state.isInbound;
  },
  isOutboundCalling (state) {
    return state.isCalling && !state.isInbound;
  },
  callInfo (state) {
    return state.callInfo;
  },
};
