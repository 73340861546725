import ApiBase from '@/api/base';

export default {
  getClientLimitSetting (args) {
    const { clientId } = args;
    return ApiBase.get(`/admin/clients/${clientId}/client_limit_setting`, args);
  },
  updateClientLimitSetting (args) {
    const { clientId } = args;
    return ApiBase.put(`/admin/clients/${clientId}/client_limit_setting`, args);
  },
};
