<template>
  <div
    class="mail-list"
    :style="listStyle"
  >
    <div class="theader">
      <div class="table-row theader-row">
        <BSortText
          v-for="(cell, index) in headerList"
          :key="cell.key"
          class="table-cell header-cell"
          :style="getTableCellStyles({ width: columnWidth[index], index })"
          :item-text="cell.name"
          is-display-only
        />
      </div>
    </div>
    <div class="tbody">
      <div
        v-for="mailTemplate in mailTemplates"
        :key="mailTemplate.id"
        class="table-row tbody-row"
        @click="() => $emit('click', mailTemplate)"
      >
        <div
          v-for="(column, index) in columnNames"
          :key="column"
          class="table-cell"
          :style="getTableCellStyles({ width: columnWidth[index], index: index })"
        >
          <template v-if="column === 'name'">
            <div class="mb-50 fw-bold template-head-area">
              <div class="template-text-area">
                {{ getPropertyFallBackAltText(mailTemplate, "name") }}
              </div>
              <div class="campain-option-area">
                <BTooltip
                  v-if="canDuplicate"
                  top
                  :content="$t('general.duplicate.do')"
                >
                  <BCustomIcon
                    size="small"
                    icon-class="b-clone"
                    hover
                    @click.stop="() => $emit('duplicate', mailTemplate)"
                  />
                </BTooltip>
              </div>
            </div>
            <BStatus
              :status="mailTemplate.status"
              :status-name="$t(`mailTemplate.status.${mailTemplate.status}`)"
            />
          </template>
          <template v-else-if="column === 'createdAt'">
            {{ getDateTime(mailTemplate.createdAt) }}
          </template>
          <template v-else-if="column === 'updatedAt'">
            {{ getDateTime(mailTemplate.updatedAt) }}
          </template>
          <template v-else-if="column === 'createdUser'">
            <BUserTooltip
              :image-url="mailTemplate.createdUser.avatarUrl"
              :name="mailTemplate.createdUser.name"
              :user-id="mailTemplate.createdUser.id"
              :size="40"
            />
          </template>
          <template v-else-if="column === 'updatedUser'">
            <BUserTooltip
              :image-url="mailTemplate.updatedUser.avatarUrl"
              :name="mailTemplate.updatedUser.name"
              :user-id="mailTemplate.updatedUser.id"
              :size="40"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapWaitingActions } from 'vue-wait';
import { altText } from '@/utils/alt';
import { formatShorterDateTime } from '@/utils/date-time';

export default {
  name: 'MailTemplateList',
  props: {
    mailTemplates: {
      type: Array,
      default() {
        return [];
      },
    },
    canDuplicate: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    'click',
    'duplicate',
  ],
  data() {
    return {
      columnWidth: [400, 120, 120, 120, 120],
      columnNames: [
        'name',
        'createdAt',
        'updatedAt',
        'createdUser',
        'updatedUser',
      ],
    };
  },
  computed: {
    headerList() {
      return this.columnNames.map(column => {
        return {
          key: column,
          name: this.$t(`mailTemplate.${column}`),
        };
      });
    },
    listStyle() {
      const totalWidth = this.columnWidth.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
      );
      return `width: ${totalWidth}px; min-width: 100%; max-width: ${totalWidth}px;`;
    },
  },
  methods: {
    getTableCellStyles({ width, index }) {
      let style = `width: ${width}px; min-width: ${width}px; max-width: ${width}px;`;
      if (index === 0) style += 'position:sticky;left:0;z-index:1;';
      return style;
    },
    getPropertyFallBackAltText(obj, property) {
      return altText(obj?.[property]);
    },
    getDateTime(datetime) {
      return altText(formatShorterDateTime(datetime));
    },
  },
};
</script>

<style lang="scss" scoped>
.table-row {
  display: flex;
  align-items: center;
  padding-right: $basespace-400;
}

.table-cell {
  padding-right: $basespace-200;
  padding-left: 10px;
  min-width: 80px;
  background-color: white;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  &:not(:nth-child(-n + 3)) {
    text-align: center;
  }
  &:first-child {
    padding-left: 30px;
  }
}

.theader {
  height: 50px;
  position: sticky;
  top: 0;
  z-index: 2;
  cursor: text;
  &-row {
    height: inherit;
    background-color: white;
    border-bottom: 1px solid $bdcolor-base;
    padding-bottom: $basespace-100;
    color: $textcolor-light;
    font-size: $fontsize-100;
  }
}

.tbody {
  &-row {
    border-bottom: 1px solid $bdcolor-base;
    padding-top: $basespace-200;
    padding-bottom: $basespace-200;

    cursor: pointer;
    &:hover {
      background-color: $bgcolor-base;
    }
  }
}

.tbody-row:hover .table-cell {
  background-color: $bgcolor-base;
}

.template-head-area {
  display: flex;
  justify-content: space-between;
}

.template-text-area {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.header-cell:hover {
  font-weight: bold;
}
</style>
