<template>
  <div
    v-infinite-scroll="load"
    infinite-scroll-disabled="scrollDisabled"
    infinite-scroll-distance="20"
  >
    <BEmptyBox
      v-if="!existItems"
      class="empty"
      display-only
    >
      {{ $t('mailNotificationEvent.empty') }}
    </BEmptyBox>
    <MailNotificationItem
      v-for="item in filteredItems"
      :key="item.id"
      :item="item"
    />
    <BLayout
      v-if="scrollDisabled && $wait.is('infiniteLoadingWait')"
      class="py-200"
      justify-center
    >
      <BElementIcon name="Loading" />
    </BLayout>
  </div>
</template>

<script>
import MailNotificationItem from '@/components/organisms/user/mailNotification/MailNotificationItem.vue';

export default {
  components: {
    MailNotificationItem,
  },
  props: {
    items: {
      type: Array,
      default () {
        return [];
      },
    },
  },
  data () {
    return {
      scrollDisabled: false,
      displayCount: 0,
      initialDisplayCount: 20,
      increaseNumber: 10,
    };
  },
  computed: {
    existItems () {
      return this.items.length > 0;
    },
    path () {
      return this.$route.path.split('/')[1];
    },
    filteredItems () {
      return this.items.slice(0, this.displayCount);
    },
    resultLength () {
      return this.items.length;
    },
  },
  methods: {
    load () {
      if (this.items.length <= this.displayCount) return;
      this.$wait.start('infiniteLoadingWait');
      this.scrollDisabled = true;
      setTimeout(() => {
        if (this.displayCount + this.increaseNumber >= this.resultLength) {
          const remainingResultLength = this.resultLength - this.displayCount;
          this.displayCount += remainingResultLength;
        } else {
          this.displayCount += this.increaseNumber;
        }
        this.$wait.end('infiniteLoadingWait');
        this.scrollDisabled = false;
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
  .empty {
    color: $textcolor-light;
    padding: $basespace-600;
    margin: $basespace-600;
    height: 90px;
    font-size: 12px;
  }
</style>
