import Waiting from '@/store/waiting';
import SfBaseActions from './sf_base';

export default class SfContactActions extends SfBaseActions {
  constructor(Api, types) {
    super();
    const actions = {
      getSfContactAction: this.SalesForceBaseAction(({ commit }, args) => {
        return waiting.check('getSfContactAction', async () => {
          try {
            const result = await Api.getSfContact(args);
            return result.data.sfContact;
          } catch (err) {
            throw err;
          }
        });
      }),
    };
    const waiting = new Waiting(actions);
    this.actions = actions;
  }
}
