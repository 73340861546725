<template>
  <BModal
    :modal-name="modalName"
    width="680px"
    height="400px"
  >
    <BHeightAdjuster>
      <BModalHeader
        :title="title"
        :needs-close-btn="false"
        center
      />
      <BModalBody>
        <div class="modal-body">
          <div class="description">
            {{ $t('general.followingItemsCouldNotRegistered', { itemName: $t('callTarget.title') }) }}
          </div>
          <div class="error-list-container">
            <template 
              v-for="failedCallTarget in failedCallTargets"
            >
              <FailedCallTargetListItem
                v-for="errorMessage in failedCallTarget.errorMessages"
                :key="failedCallTarget.callTarget.id + errorMessage"
                class="error-list-item"
                :call-target-id="failedCallTarget.callTarget.id"
                :company-name="failedCallTarget.callTarget.companyName"
                :full-name="failedCallTarget.callTarget.fullName"
                :error-message="errorMessage"
              />
            </template>
          </div>
        </div>
      </BModalBody>
      <BModalFooter
        class="modal-footer"
      >
        <BBtn
          type="primary"
          @click="$modal.hide(modalName)"
        >
          {{ $t('general.ok') }}
        </BBtn>
      </BModalFooter>
    </BHeightAdjuster>
  </BModal>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { FailedCallTarget } from '@/api/openapi';
import FailedCallTargetListItem from '@/components/organisms/user/general/modal/FailedCallTargetListItem.vue';
import BHeightAdjuster from '@/plugins/biscuet-materials/components/atoms/BHeightAdjuster.vue';

type TProps = {
  title: string;
  failedCallTargets: FailedCallTarget[];
};

defineProps<TProps>();

const modalName = ref('failedCallTargetsModal');
</script>

<style lang="scss" scoped>
$description-height: 34px;
.description {
  height: $description-height;
  padding-bottom: 8px;
}
.error-list-container {
  padding-bottom: 10px;
  overflow-y: auto;
  height: calc(100% - #{$description-height});
  .error-list-item:nth-of-type(n + 2) {
    margin-top: 10px;
  }
}
.modal-footer {
  text-align: right;
}
.modal-body {
  height: 100%;
  display: flex;
  flex-direction: column;
}
:deep(.b-modal-body) {
  padding-bottom: 10px;
  padding-top: 10px;
}
</style>
