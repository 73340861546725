import Swal from 'sweetalert';

// FIXME: そのうち src/composable/message-dialog.ts にまるごと移行する
export const bitterAlert = {
  show(params) {
    const optionalButtonOptions = params?.optionalButtonOptions ?? {};
    const buttons = {
      cancel: params?.buttonsCancel ?? true,
      confirm: params?.buttonsConfirm ?? true,
      ...optionalButtonOptions,
    };

    return Swal({
      title: params?.title || '確認',
      text: params?.text || '',
      closeOnClickOutside: params?.closeOnClickOutside || false,
      buttons,
      className: params?.className || '',
    });
  },
};

export default {
  install(app) {
    /**
     * @duplicated instead of: import { bitterAlert } from '@/plugins/BBitterAlert'
     */
    app.config.globalProperties.$bitterAlert = bitterAlert;
  },
};
