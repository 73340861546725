<template>
  <div class="b-list-item">
    <div class="b-list-item-header">
      <slot name="header" />
    </div>
    <div class="b-list-item-content">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .b-list-item {
    width: 100%;

    &-header {
      font-size: $fontsize-100;
      color: $textcolor-light;
      margin-bottom: $basespace-50;
    }
  }
</style>
