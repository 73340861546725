<template>
  <BLayout
    class="lead-list-header"
    align-center
  >
    <div class="header-item checkbox">
      <BCheckbox
        v-model="checkboxValue"
        class="mt-0"
        :indeterminate="checkboxValue"
      />
    </div>
    <Draggable
      v-model="internalLeadListSelectedColumns"
      class="draggable"
      ghost-class="ghost"
      :item-key="element => element"
      handle=".handle"
      :animation="300"
    >
      <template #item="{ element: column }">
        <div
          class="header-item handle"
          :class="getClassNameByKey(column)"
          :style="getStyles(column)"
        >
          <BSortText
            v-if="isSortable(column)"
            :is-active="leadListSortKey === column"
            :order-by="leadListSortOrder"
            :item-text="getColumnName(column)"
            @order-by="handleSort(column, $event)"
          />
          <div v-else>
            {{ columnNames[column] }}
          </div>
        </div>
      </template>
    </Draggable>
    <div class="header-item end-column" />
  </BLayout>
</template>

<script>
import { mapWaitingActions } from 'vue-wait';
import Draggable from 'vuedraggable';
import { mapGetters } from 'vuex';
import camelToKebab from '@/utils/camelToKebab';

export default {
  components: {
    Draggable,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'update:modelValue',
  ],
  computed: {
    ...mapGetters('user', [
      'callTargets',
      'customFields',
    ]),
    ...mapGetters('userUi', [
      'leadListSelectedColumns',
      'leadListSortKey',
      'leadListSortOrder',
      'leadListSortableColumns',
      'leadListColumnWidths',
    ]),
    checkboxValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    internalLeadListSelectedColumns: {
      get () {
        return this.leadListSelectedColumns;
      },
      set (v) {
        this.setLeadListSelectedColumnsAction(v);
      },
    },
    columnNames () {
      // { key, name } にまとめる
      const columns = {};
      const categories = ['salesforceInfo', 'companyInfo', 'callTargetInfo', 'historyInfo', 'leadAdditionalInfo', 'lastActivityInfo', 'systemAdditionalInfo', 'duplicateInfo'];
      categories.forEach(category => {
        Object.keys(this.$tm(`filterSelect.${category}.column`)).forEach(key => {
          columns[key] = this.$t(`filterSelect.${category}.column.${key}`);
        });
      });
      this.customFields.forEach((customField) => {
        columns[customField.fieldKey] = customField.name;
      });
      return columns;
    },
    activeCallTargetIds () {
      return this.callTargets.map(callTarget => callTarget.callTargetId);
    },
  },
  methods: {
    ...mapWaitingActions('user', {
      getCallTargetsAction: { action: 'getCallTargetsAction', loader: 'getCallTargetsWait' },
    }),
    ...mapWaitingActions('userUi', {
      setLeadListSelectedColumnsAction: 'setLeadListSelectedColumnsWait',
      setLeadListSortKeyAction: 'setLeadListSortKeyWait',
      setLeadListSortOrderAction: 'setLeadListSortOrderWait',
    }),
    getSelectedSortBy (sortBy) {
      this.selectedSortBy = sortBy;
    },
    getOrderBy (orderBy) {
      this.orderBy = orderBy;
    },
    handleSort (sortKey, sortOrder) {
      this.setLeadListSortKeyAction(sortKey);
      this.setLeadListSortOrderAction(sortOrder);
      this.getCallTargetsAction();
    },
    getColumnName (key) {
      const isCustomField = key.indexOf('custom_field_') !== -1;
      if (isCustomField) {
        const customField = this.getCustomFieldByFieldKey(key);
        return customField == null ? '-' : customField.name;
      } else {
        return this.columnNames[key];
      }
    },
    getCustomFieldByFieldKey (fieldKey) {
      if (this.customFields == null) return null;
      const filteredCustomField = this.customFields.filter(item => item.fieldKey === fieldKey);
      return filteredCustomField.length === 0 ? null : filteredCustomField[0];
    },
    getClassNameByKey (key) {
      const isCustomField = key.indexOf('custom_field_') !== -1;
      if (isCustomField) {
        return 'selected-column';
      } else {
        return camelToKebab(key);
      }
    },
    getStyles (key) {
      const width = `${this.leadListColumnWidths[key]}px`;
      return `width: ${width}; min-width: ${width}; max-width: ${width};`;
    },
    isSortable (key) {
      const isCustomField = key.indexOf('custom_field_') !== -1;
      if (isCustomField) {
        const customField = this.getCustomFieldByFieldKey(key);
        return !!customField && customField.dataType !== 'multi_option';
      } else {
        return this.leadListSortableColumns.includes(key);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .lead-list-header {
    font-size: $fontsize-100;
    color: $textcolor-light;
    display: inline-flex;
    width: 100%;
    background-color: $bgcolor-white;
    position: sticky;
    top: 0;
    z-index: 1;
    padding-left: $basespace-400;

    .draggable {
      display: flex;
    }

    .header-item {
      display: flex;
      align-items: center;
      @include m-fixed-height(40px);
      // border-bottom: 1px solid $bdcolor-light;
      padding: 0 $basespace-200;
      background-color: $bgcolor-white;
      border-bottom: 1px solid $bdcolor-light;
    }

    .end-column {
      width: 100%;
    }
  }

  // TODO:共通化
  .checkbox {
    @include m-fixed-width(40px);
  }

  .selected-column {
    @include m-fixed-width(160px);
  }

  .handle {
    cursor: grab;

    &:hover {
      background-color: $bgcolor-base;
    }
  }

  .ghost {
    border-left: 1px solid $bdcolor-dark;
    background-color: $bgcolor-base;
  }
</style>
