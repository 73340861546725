<template>
  <Transition :name="transitionName">
    <div
      v-if="isOpen"
      class="b-modal b-modal-overlay"
      @mousedown.self="handleOverlayClick"
    >
      <div
        class="b-modal-content"
        :style="setStyles"
      >
        <slot />
      </div>
    </div>
  </Transition>
</template>

<script lang="ts" setup>
import { computed, inject, onUnmounted, ref, watch } from 'vue';
import { KEY_OF_MODAL } from '@/injection-keys';
import { TModalAction } from './index';

export type TBmodalDelegate = {
  shouldClose?: () => boolean | Promise<boolean>;
  beforeClose?: () => void; // NOTE: 時間差がない方がいいことが多いと思われるので、EventでなくDelegateにしている
};

type TProps = {
  modalName: string;
  width?: string
  height?: string
  full?: boolean
  delegate?: TBmodalDelegate
  scroll?: boolean
};
const props = withDefaults(defineProps<TProps>(), {
  width: '100%',
  height: '100%',
  full: false,
  delegate: null,
});

const emit = defineEmits(['openModalEvent']);

const fullStyles = {
  'width': '100%',
  'height': '100%',
  'max-width': '100%',
  'max-height': '100%',
  'border-radius': '0px',
};

const modalAction = inject<TModalAction>('modalAction');
const modal = inject(KEY_OF_MODAL);

const innerIsOpen = ref(false);

const isOpen = computed({
  get: () => innerIsOpen.value,
  set: (newValue) => {
    // モーダル表示時は背景のスクロール禁止
    const isHidden = newValue ? 'hidden' : 'visible';
    document.body.style.overflow = isHidden;
    document.documentElement.style.overflow = isHidden;

    innerIsOpen.value = newValue;
  },
});

const setStyles = computed(() =>
  props.full ? fullStyles : { width: props.width, height: props.height, overflow: props.scroll ? 'scroll' : 'hidden' },
);
const transitionName = computed(() => props.full ? 'fade' : 'modal');

const handleOpenEvent = () => {
  isOpen.value = true;
  emit('openModalEvent');
};
const handleCloseEvent = (targetModalName: string, oldValue: TModalAction) => {
  // NOTE: 判定の意図がよくわからないがとりあえずそのままにする
  if (props.modalName.split('.')[0] === targetModalName.split('.')[0]) {
    oldValue != null && props.delegate?.beforeClose?.(); // NOTE: これがないと、「モーダルを閉じるとき強制に再レンダリングした場合」に無限ループになる。
    isOpen.value = false;
  }
};
const handleOverlayClick = () => {
  if (props.delegate?.shouldClose == null) {
    closeModal();
    return;
  }
  const shouldClose = props.delegate.shouldClose();
  Promise.resolve(shouldClose).then((result) => {
    result && closeModal();
  });
};

const closeModal = () => {
  if (isOpen.value) modal.hide(props.modalName);
};

watch(modalAction, (newValue, oldValue) => {
  if (newValue == null) return;
  if (props.modalName !== newValue.modalName) return;
  // NOTE: 複数回変更が発火するので、変更がない場合は処理をスキップする
  if (newValue.modalName === oldValue?.modalName && newValue.action === oldValue?.action) return;

  if (newValue.action === 'show') handleOpenEvent();
  if (newValue.action === 'hide') handleCloseEvent(newValue.modalName, oldValue);
}, { immediate: true });

onUnmounted(() => {
  document.body.style.overflow = 'visible';
  document.documentElement.style.overflow = 'visible';
});
</script>

<style lang="scss" scoped>
.b-modal {
  &-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
  }

  &-content {
    background: $bgcolor-white;
    overflow: hidden;
    width: 80%;
    height: 80%;
    max-height: 95%;
    max-width: 95%;
    border-radius: 8px;
  }
}

.modal {
  &-enter-active {
    animation: modal 300ms;
  }
  &-leave-active {
    animation: modal 300ms reverse;
  }
}

@keyframes modal {
  0% {
    opacity: 0;
    transform: translateY(16px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
</style>
