<template>
  <BContentBox>
    <template #content>
      <div
        v-loading="salesforceSettingLoading"
        class="content"
      >
        <BLayout
          class="pl-600 pb-200 header"
          justify-center
          align-center
        >
          <BListItem class="column-content non-border radio-priority-area">
            <span>SF優先</span>
          </BListItem>
          <BListItem class="column-content non-border">
            <span>Salesforce項目</span>
          </BListItem>
          <div class="icon" />
          <BListItem class="column-content non-border radio-priority-area">
            <span>BC優先</span>
          </BListItem>
          <BListItem class="column-content non-border">
            <span>BALES CLOUD項目</span>
          </BListItem>
          <BListItem class="column-content non-border check-area">
            <span>SF→BC</span>
          </BListItem>
          <BListItem class="column-content non-border check-area">
            <span>SF←BC</span>
          </BListItem>
        </BLayout>
        <BLayout
          v-for="(value, bcKey) in mappingDisplayValue"
          :key="`${bcKey}-${changeBoxCounter}`"
          class="pl-600 pb-200"
          justify-center
          align-center
        >
          <BListItem class="radio-priority-area">
            <div
              :class="`column-content non-border radio-box
                radio-box-${value['bc_priority'] != true && value['sf_import'] ? 'active' : 'non-active'}`"
            >
              <BIcon
                class="radio"
                size="b-icon-small"
              >
                done
              </BIcon>
            </div>
          </BListItem>
          <BListItem>
            <div class="column-content non-border select-column">
              {{ sfFieldsMap[value['sf_column']] }}
            </div>
          </BListItem>
          <div class="icon">
            <BIcon
              class="icon-sync"
              size="b-icon-medium"
            >
              swap_horiz
            </BIcon>
          </div>
          <BListItem class="radio-priority-area">
            <div
              v-show="!('sf_import_only' in value && value['sf_import_only'] == true) && sharedObjectMapping[bcKey] == null"
              :class="`column-content non-border radio-box
                radio-box-${value['bc_priority'] ? 'active' : 'non-active'}`"
            >
              <BIcon
                class="radio"
                size="b-icon-small"
              >
                done
              </BIcon>
            </div>
          </BListItem>
          <BListItem>
            <div class="column-content non-border bc-label">
              {{ value['label'] ?? $t(`import.salesforce.mappings.${bcKey}`) }}
            </div>
          </BListItem>
          <BListItem class="check-area">
            <div
              :class="`column-content non-border check-box
                check-box-${value['sf_import'] ? 'active' : 'non-active'}`"
            >
              <BIcon
                class="check"
                size="b-icon-small"
              >
                done
              </BIcon>
            </div>
          </BListItem>
          <BListItem class="check-area">
            <div
              v-show="!('sf_import_only' in value && value['sf_import_only'] == true)"
              :class="`column-content non-border check-box
                check-box-${value['bc_import'] ? 'active' : 'non-active'}`"
            >
              <BIcon
                class="check"
                size="b-icon-small"
              >
                done
              </BIcon>
            </div>
          </BListItem>
        </BLayout>
      </div>
    </template>
  </BContentBox>
</template>

<script lang="ts" setup>
import { onMounted, ref, computed } from 'vue';
import { SfObjectMappingService } from '@/api/user/resources/salesforce/sf_object_mapping';

type TProps = {
  selectedObject?: string;
};

const props = withDefaults(defineProps<TProps>(), {
  selectedObject: '',
});
const emit = defineEmits(['back']);
const api = new SfObjectMappingService();
const mapping = ref({});
const sfFields = ref({});
const sfFieldsMap = ref({});
const sfMapInfo = ref({});
const sfColumnDataTypes = ref({});
const customFieldsDataTypes = ref({});
const sharedObjectMapping = ref({});
const salesforceSettingLoading = ref(true);
// v-forで選択したときに、同一のkeyの場合に書き換わらないケースががあるため
const changeBoxCounter = ref(0);

const loadSfFieldsMap = () => {
  const sfMap = [];
  for (const sfKey of Object.keys(sfFields.value)) {
    if (sfKey !== 'Id') {
      sfMap[sfKey] = sfFields.value[sfKey].label;
    }
  }
  sfFieldsMap.value = sfMap;
};

const mappingDisplayValue = computed(() => {
  const sfMap = {};
  for (const key of Object.keys(mapping.value)) {
    if (mapping.value[key].sf_column !== null) {
      sfMap[key] = mapping.value[key];
    }
  }

  return sfMap;
});


onMounted(async () => {
  if (props.selectedObject !== 'lead') {
    const contact = await api.getSfObjectMappings({ targetObject: 'contact' });
    const account = await api.getSfObjectMappings({ targetObject: 'account' });

    mapping.value = Object.assign(contact.data.sfObjectMappings.objectMappings, account.data.sfObjectMappings.objectMappings);
    sfFields.value = Object.assign(contact.data.sfObjectMappings.objectFields, account.data.sfObjectMappings.objectFields);
    sfMapInfo.value = Object.assign(contact.data.sfObjectMappings.sfMapInfo, account.data.sfObjectMappings.sfMapInfo);
    sfColumnDataTypes.value = Object.assign(contact.data.sfObjectMappings.sfColumnDataTypes, account.data.sfObjectMappings.sfColumnDataTypes);
    customFieldsDataTypes.value = Object.assign(contact.data.sfObjectMappings.customFieldsDataTypes, account.data.sfObjectMappings.customFieldsDataTypes);
    sharedObjectMapping.value = Object.assign(contact.data.sfObjectMappings.sharedObjectMapping, account.data.sfObjectMappings.sharedObjectMapping);
  } else {
    const lead = await api.getSfObjectMappings({ targetObject: props.selectedObject });
    mapping.value = lead.data.sfObjectMappings.objectMappings;
    sfFields.value = lead.data.sfObjectMappings.objectFields;
    sfMapInfo.value = lead.data.sfObjectMappings.sfMapInfo;
    sfColumnDataTypes.value = lead.data.sfObjectMappings.sfColumnDataTypes;
    customFieldsDataTypes.value = lead.data.sfObjectMappings.customFieldsDataTypes;
    sharedObjectMapping.value = lead.data.sfObjectMappings.sharedObjectMapping;
  }
  loadSfFieldsMap();
  salesforceSettingLoading.value = false;
});
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.object-name {
  font-weight: bold;
}

.column-content {
  width: 100%;
  padding: 0 10px;
  height: 40px;
  line-height: 40px;
  border-radius: 5px;
  border: 1px solid #E3E3E3;
}

.bc-label {
  overflow-y: scroll;
}

.select-column {
  width: 100%;
}

.non-border {
  border-width: 0;
}

.icon {
  display: flex;
  width: 20%;
  align-items: center;
  justify-content: center;
  font-size: 32px !important;
  color: $concrete-dark !important;
  padding: 0 12px;

  .icon-sync {
    color: $concrete-dark !important;
  }
}

.hr {
  border-bottom: 1px solid $bdcolor-base;
  margin-bottom: 8px;
}

.fotter {
  position: fixed;
  bottom: 0;
  right: 0;
  width: calc(100% - 310px);
  height: 63px;
  background-color: #F6F6F6;
  ;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-back {
  color: #231815;
  font-size: 18px;
  margin: 0 12px;
  padding: 4px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-save {
  width: 101px;
  height: 37px;
  border-radius: 4px;
  background: #018485;
  margin: 0 12px;
  color: #FFF;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notice {
  position: relative;
  width: 100%;
  height: 60px;
  padding: 12px 0;
  margin: 12px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFF;
}

.close {
  position: absolute;
  right: 0;
}
.header {
  color: $concrete-dark;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.target-object-name {
  font-weight: bold;
}

.check-area {
  width: 80px !important;
  margin: 0 20px;
  flex-shrink: 0;
  text-align: center;
}

.radio-priority-area {
  width: 60px !important;
  margin: 0;
  padding: 0;
  flex-shrink: 0;
  text-align: center;
}

.check-box {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  >.check {
    color: $textcolor-white;
  }

  &.check-box-active {
    background-color: $basecolor-success;
  }

  &.check-box-non-active {
    background-color: $concrete-dark;
  }
}

.radio-box {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border-radius: 50%;

  >.radio {
    color: $textcolor-white;
  }

  &.radio-box-active {
    background-color: $basecolor-success;
  }

  &.radio-box-non-active {
    background-color: $concrete-dark;
  }
}

.b-select {
  color: #231815;
  font-weight: normal;
}

.b-selected {
  color: var(--el-color-primary);
  font-weight: bold;
}
</style>
