import Waiting from '@/store/waiting';

export default class OpportunityActions {
  constructor(Api, types) {
    const actions = {
      async createOpportunityAction({ commit }, args) {
        return await waiting.check('createOpportunityAction', async () => {
          try {
            const res = await Api.createOpportunity(args);
            return res.data;
          } catch (err) {
            throw err;
          }
        });
      },
      async updateOpportunityAction({ commit }, args) {
        await waiting.check('updateOpportunityAction', async () => {
          try {
            const res = await Api.updateOpportunity(args);
            return res.data;
          } catch (err) {
            throw err;
          }
        });
      },
      async deleteOpportunityAction({ commit }, args) {
        await waiting.check('deleteOpportunityAction', async () => {
          await Api.deleteOpportunity(args);
          try {
            const res = await Api.getLeadHistories(args);
            commit(types.SET_LEAD_HISTORIES, Object.values(res.data)[0]);
          } catch (err) {
            throw err;
          }
        });
      },
    };
    const waiting = new Waiting(actions);
    this.actions = actions;
  }
}
