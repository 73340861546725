<template>
  <div
    v-loading="$wait.is('confirmLeadCsvOverwriteTaskWait')"
    class="lead-overwrite-status"
  >
    <ImportStatus
      v-for="task in leadCsvOverwriteTasks"
      :key="task.id"
      :target-task="task"
      :progress-message="$t('overwrite.csv.status.progress')"
      :error-message="$t('overwrite.csv.status.error')"
      :error-modal-title="$t('overwrite.csv.errorTitle')"
      :success-message="$t('overwrite.csv.status.success')"
      @confirm="() => confirmTask(task.id)"
      @success="$emit('success')"
    />
  </div>
</template>

<script>
import { mapWaitingActions } from 'vue-wait';
import { mapGetters } from 'vuex';
import ImportStatus from '@/components/organisms/user/leadImport/ImportStatus.vue';

export default {
  components: {
    ImportStatus,
  },
  emits: [
    'success',
  ],
  computed: {
    ...mapGetters('user', ['leadCsvOverwriteTasks']),
  },
  created() {
    this.getLeadCsvOverwriteTasksAction();
    this.timeObj = this.$setInterval(() => {
      this.getLeadCsvOverwriteTasksAction();
    }, 90000);
  },
  beforeUnmount() {
    this.$clearInterval(this.timeObj);
  },
  methods: {
    ...mapWaitingActions('user', {
      getLeadCsvOverwriteTasksAction: 'getLeadCsvOverwriteTasksWait',
      confirmLeadCsvOverwriteTaskAction: 'confirmLeadCsvOverwriteTaskWait',
    }),
    confirmTask(taskId) {
      this.confirmLeadCsvOverwriteTaskAction({ leadCsvOverwriteTaskId: taskId });
    },
  },
};
</script>

<style scoped lang="scss">
  .import-status:nth-last-child(n + 2) {
    border-bottom: 1px solid $concrete-light;
  }
</style>
