<template>
  <div class="b-editable-content-select">
    <BLayout
      v-if="internalEditing"
      row
      align-center
      justify-space-between
    >
      <BSelect
        v-bind="attributes"
        ref="bSelect"
        v-model="internalValueId"
        class="input-select mr-300"
        :data-test="dataTest"
        @change="handleChange"
      />
      <div
        v-if="!hideBtnGroup"
        class="btn-area"
      >
        <BBtn
          v-if="valueChanged"
          class="mr-50"
          size="small"
          fab
          flat
          :loading="loading"
          :data-test="`${dataTest}Btn`"
          @click="handleCheck"
        >
          <BIcon
            class="check-icon"
            size="small"
          >
            check
          </BIcon>
        </BBtn>
        <BBtn
          size="small"
          fab
          flat
          :disabled="loading"
          @click="handleClose"
        >
          <BIcon
            class="close-icon"
            size="small"
          >
            close
          </BIcon>
        </BBtn>
      </div>
    </BLayout>
    <div
      v-else
      class="content"
      :data-test="dataTest"
      @click="handleEdit"
    >
      {{ displayValue }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: '-',
    },
    value: {
      type: [String, Number],
      default: null,
    },
    required: Boolean,
    hyphen: Boolean,
    loading: Boolean,
    editing: Boolean,
    items: {
      type: Array,
      default () {
        return []; // [{ id: xxxx, label: xxxx }]
      },
    },
    dataTest: String,
    onChangeTrigger: {
      type: Boolean,
      default: false,
    },
    hideBtnGroup: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:editing',
    'check',
    'select',
  ],
  data () {
    return {
      newValueId: null,
      internalEditing: false,
    };
  },
  computed: {
    internalValueId: {
      get () {
        return this.newValueId;
      },
      set (newVal) {
        this.newValueId = newVal;
      },
    },
    valueChanged () {
      return this.valueId !== this.internalValueId;
    },
    valueId () {
      const item = this.items.find(item => item.label === this.value);
      return item ? item.id : null;
    },
    attributes () {
      return {
        'items': this.items,
        'item-value': 'id',
        'item-text': 'label',
        'fit': true,
        'clearable': true,
        'filterable': true,
        'placeholder': this.placeholder,
        'disabled': this.loading,
      };
    },
    displayValue () {
      if (this.hyphen) {
        return this.value || '-';
      } else {
        return this.value;
      }
    },
  },
  watch: {
    loading (newVal) {
      // loadingを渡さないと、save時にフォームが終了しない
      if (!newVal) { this.internalEditing = false; }
    },
    editing (newVal) {
      this.internalEditing = newVal;
      if (newVal) {
        this.internalValueId = this.valueId;
      }
    },
    internalEditing (newVal) {
      this.$emit('update:editing', newVal);
    },
  },
  created () {
    this.internalEditing = this.editing;
  },
  methods: {
    handleEdit () {
      this.internalEditing = true;
      this.$nextTick(() => {
        this.$refs.bSelect.focus();
      });
    },
    handleCheck () {
      this.$emit('check', this.internalValueId);
    },
    handleClose () {
      this.internalEditing = false;
    },
    handleChange (e) {
      if (this.onChangeTrigger) this.$emit('select', e);
    },
  },
};
</script>

<style lang="scss" scoped>
  .b-editable-content-select {
    width: 100%;
  }

  .content {
    width: 100%;
    border: 1px solid transparent;
    padding: $basespace-100 $basespace-100;
    margin-left: -$basespace-100;
    border-radius: 4px;
    transition: all 300ms $standard-easing;
    white-space: pre-wrap;
    word-wrap: break-word;

    &:hover {
      border: 1px solid $bdcolor-base;
      cursor: pointer;
    }
  }

  .input-select {
    margin-left: -$basespace-100;
  }

  .btn-area {
    display: flex;
    align-items: center;
    justify-content: flex-end
  }

  .check-icon {
    color: $basecolor-primary;
  }

  .close-icon {
    color: $textcolor-light;
  }
</style>
