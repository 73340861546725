import type { ButtonOptions } from 'sweetalert/typings/modules/options/buttons';
import { useI18n } from 'vue-i18n';
import { bitterAlert } from '@/plugins/BBitterAlert';

type TParams = {
  title?: string;
  message: string;
  closeOnClickOutside?: boolean;
};
type TConfirmWithOptionParams = TParams & {
  buttonsCancel?: string | boolean;
  confirmButtonText?: string;
  optionalButtonOptions?: Record<string, ButtonOptions>;
};
const useConfirmWithOptions = () => {
  const confirmWithOptions = async ({ title, message, optionalButtonOptions, buttonsCancel, closeOnClickOutside }: TConfirmWithOptionParams) => {
    return await bitterAlert.show({
      title,
      text: message,
      buttonsConfirm: false,
      buttonsCancel: buttonsCancel ?? false,
      closeOnClickOutside: closeOnClickOutside ?? true,
      optionalButtonOptions,
    }) as boolean;
  };
  return { confirmWithOptions };
};
const useConfirm = () => {
  const confirm = async ({ title, message }: TParams) => {
    return await bitterAlert.show({
      title,
      text: message,
    }) as boolean;
  };
  return { confirm };
};
const useAlert = () => {
  const alert = async ({ title, message }: TParams) => {
    return await bitterAlert.show({
      title,
      text: message,
      buttonsCancel: false,
    }) as boolean;
  };
  return { alert };
};
const useDeleteConfirm = () => {
  const i18n = useI18n();
  const { confirm } = useConfirm();
  const deleteConfirm = async () => {
    return await confirm({
      message: i18n.t('general.delete.message'),
    });
  };
  return { deleteConfirm };
};
const useUnsavedConfirm = () => {
  const i18n = useI18n();
  const { confirm } = useConfirm();
  const unsavedConfirm = async () => {
    return await confirm({
      message: i18n.t('general.alert.of', { target: i18n.t('general.unsavedValue'), action: i18n.t('general.clear') }),
    });
  };
  return { unsavedConfirm };
};

export {
  useConfirmWithOptions,
  useConfirm,
  useAlert,
  useDeleteConfirm,
  useUnsavedConfirm,
};
