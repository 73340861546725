import ApiBase from '@/api/base';

export default {
  getNextActions (args) {
    const { callTargetId } = args;
    return ApiBase.get(`/user/call_targets/${callTargetId}/next_actions`, args);
  },
  createNextAction (args) {
    const { callTargetId } = args;
    return ApiBase.post(`/user/call_targets/${callTargetId}/next_actions`, args);
  },
  updateNextAction (args) {
    const { nextActionId } = args;
    return ApiBase.put(`/user/next_actions/${nextActionId}`, args);
  },
  updateNextActionContent (args) {
    const { nextActionId, callTargetId } = args;
    return ApiBase.put(`/user/call_targets/${callTargetId}/next_actions/${nextActionId}/content`, args);
  },
  doneNextAction (args) {
    const { nextActionId, callTargetId } = args;
    return ApiBase.put(`/user/call_targets/${callTargetId}/next_actions/done/${nextActionId}`, args);
  },
  doneCancelNextAction (args) {
    const { nextActionId, callTargetId } = args;
    return ApiBase.put(`/user/call_targets/${callTargetId}/next_actions/cancel/${nextActionId}`, args);
  },
  deleteNextAction (args) {
    const { nextActionId } = args;
    return ApiBase.delete(`/user/next_actions/${nextActionId}`);
  },
};
