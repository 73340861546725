/* tslint:disable */
/* eslint-disable */
/**
 * openapi
 * bales cloud
 *
 * The version of the OpenAPI document: 1.0
 * Contact: test@example.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GetReceivedMails200Response } from '../models';
// @ts-ignore
import { ReceivedMail } from '../models';
/**
 * ReceivedMailApi - axios parameter creator
 * @export
 */
export const ReceivedMailApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 受信メール一覧取得API
         * @summary 受信メール取得API
         * @param {number} receivedMailId 受信メールID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReceivedMail: async (receivedMailId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'receivedMailId' is not null or undefined
            assertParamExists('getReceivedMail', 'receivedMailId', receivedMailId)
            const localVarPath = `/api/user/received_mails/{receivedMailId}`
                .replace(`{${"receivedMailId"}}`, encodeURIComponent(String(receivedMailId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 受信メール一覧取得API
         * @summary 受信メール一覧取得API
         * @param {number} [page] infinityLoading用ページ番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReceivedMails: async (page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/received_mails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 受信メールクリックAPI
         * @summary 受信メールクリックAPI
         * @param {number} receivedMailId 受信メールID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReceivedMailClick: async (receivedMailId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'receivedMailId' is not null or undefined
            assertParamExists('postReceivedMailClick', 'receivedMailId', receivedMailId)
            const localVarPath = `/api/user/received_mails/{receivedMailId}/click`
                .replace(`{${"receivedMailId"}}`, encodeURIComponent(String(receivedMailId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReceivedMailApi - functional programming interface
 * @export
 */
export const ReceivedMailApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReceivedMailApiAxiosParamCreator(configuration)
    return {
        /**
         * 受信メール一覧取得API
         * @summary 受信メール取得API
         * @param {number} receivedMailId 受信メールID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReceivedMail(receivedMailId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReceivedMail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReceivedMail(receivedMailId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 受信メール一覧取得API
         * @summary 受信メール一覧取得API
         * @param {number} [page] infinityLoading用ページ番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReceivedMails(page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetReceivedMails200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReceivedMails(page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 受信メールクリックAPI
         * @summary 受信メールクリックAPI
         * @param {number} receivedMailId 受信メールID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postReceivedMailClick(receivedMailId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postReceivedMailClick(receivedMailId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReceivedMailApi - factory interface
 * @export
 */
export const ReceivedMailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReceivedMailApiFp(configuration)
    return {
        /**
         * 受信メール一覧取得API
         * @summary 受信メール取得API
         * @param {number} receivedMailId 受信メールID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReceivedMail(receivedMailId: number, options?: any): AxiosPromise<ReceivedMail> {
            return localVarFp.getReceivedMail(receivedMailId, options).then((request) => request(axios, basePath));
        },
        /**
         * 受信メール一覧取得API
         * @summary 受信メール一覧取得API
         * @param {number} [page] infinityLoading用ページ番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReceivedMails(page?: number, options?: any): AxiosPromise<GetReceivedMails200Response> {
            return localVarFp.getReceivedMails(page, options).then((request) => request(axios, basePath));
        },
        /**
         * 受信メールクリックAPI
         * @summary 受信メールクリックAPI
         * @param {number} receivedMailId 受信メールID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReceivedMailClick(receivedMailId: number, options?: any): AxiosPromise<void> {
            return localVarFp.postReceivedMailClick(receivedMailId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReceivedMailApi - interface
 * @export
 * @interface ReceivedMailApi
 */
export interface ReceivedMailApiInterface {
    /**
     * 受信メール一覧取得API
     * @summary 受信メール取得API
     * @param {number} receivedMailId 受信メールID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceivedMailApiInterface
     */
    getReceivedMail(receivedMailId: number, options?: AxiosRequestConfig): AxiosPromise<ReceivedMail>;

    /**
     * 受信メール一覧取得API
     * @summary 受信メール一覧取得API
     * @param {number} [page] infinityLoading用ページ番号
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceivedMailApiInterface
     */
    getReceivedMails(page?: number, options?: AxiosRequestConfig): AxiosPromise<GetReceivedMails200Response>;

    /**
     * 受信メールクリックAPI
     * @summary 受信メールクリックAPI
     * @param {number} receivedMailId 受信メールID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceivedMailApiInterface
     */
    postReceivedMailClick(receivedMailId: number, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * Request parameters for getReceivedMail operation in ReceivedMailApi.
 * @export
 * @interface ReceivedMailApiGetReceivedMailRequest
 */
export interface ReceivedMailApiGetReceivedMailRequest {
    /**
     * 受信メールID
     * @type {number}
     * @memberof ReceivedMailApiGetReceivedMail
     */
    readonly receivedMailId: number
}

/**
 * Request parameters for getReceivedMails operation in ReceivedMailApi.
 * @export
 * @interface ReceivedMailApiGetReceivedMailsRequest
 */
export interface ReceivedMailApiGetReceivedMailsRequest {
    /**
     * infinityLoading用ページ番号
     * @type {number}
     * @memberof ReceivedMailApiGetReceivedMails
     */
    readonly page?: number
}

/**
 * Request parameters for postReceivedMailClick operation in ReceivedMailApi.
 * @export
 * @interface ReceivedMailApiPostReceivedMailClickRequest
 */
export interface ReceivedMailApiPostReceivedMailClickRequest {
    /**
     * 受信メールID
     * @type {number}
     * @memberof ReceivedMailApiPostReceivedMailClick
     */
    readonly receivedMailId: number
}

/**
 * ReceivedMailApi - object-oriented interface
 * @export
 * @class ReceivedMailApi
 * @extends {BaseAPI}
 */
export class ReceivedMailApi extends BaseAPI implements ReceivedMailApiInterface {
    /**
     * 受信メール一覧取得API
     * @summary 受信メール取得API
     * @param {ReceivedMailApiGetReceivedMailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceivedMailApi
     */
    public getReceivedMail(requestParameters: ReceivedMailApiGetReceivedMailRequest, options?: AxiosRequestConfig) {
        return ReceivedMailApiFp(this.configuration).getReceivedMail(requestParameters.receivedMailId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 受信メール一覧取得API
     * @summary 受信メール一覧取得API
     * @param {ReceivedMailApiGetReceivedMailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceivedMailApi
     */
    public getReceivedMails(requestParameters: ReceivedMailApiGetReceivedMailsRequest = {}, options?: AxiosRequestConfig) {
        return ReceivedMailApiFp(this.configuration).getReceivedMails(requestParameters.page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 受信メールクリックAPI
     * @summary 受信メールクリックAPI
     * @param {ReceivedMailApiPostReceivedMailClickRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceivedMailApi
     */
    public postReceivedMailClick(requestParameters: ReceivedMailApiPostReceivedMailClickRequest, options?: AxiosRequestConfig) {
        return ReceivedMailApiFp(this.configuration).postReceivedMailClick(requestParameters.receivedMailId, options).then((request) => request(this.axios, this.basePath));
    }
}
