<template>
  <div
    v-loading="$wait.is('confirmCustomFieldOptionDestroyTaskTaskWait')"
    class="confirm-custom-field-option-destroy-status"
  >
    <ImportStatus
      v-for="task in customFieldOptionDestroyTasks"
      :key="task.id"
      :target-task="task"
      :progress-message="$t('customFieldOption.destroyTask.status.progress', { customFieldName: task.customFieldName, customFieldOptionLabel: task.customFieldOptionLabel })"
      :error-message="$t('customFieldOption.destroyTask.status.error', { customFieldName: task.customFieldName, customFieldOptionLabel: task.customFieldOptionLabel })"
      :can-show-error-modal="false"
      :success-message="$t('customFieldOption.destroyTask.status.success', { customFieldName: task.customFieldName, customFieldOptionLabel: task.customFieldOptionLabel })"
      @confirm="() => confirmTask(task.id)"
    />
  </div>
</template>

<script lang="ts" setup>
import { AxiosResponse } from 'axios';
import { getCurrentInstance, onBeforeMount, onBeforeUnmount, ref } from 'vue';
import type { CustomFieldOptionDestroyTask } from '@/api/openapi';
import { CustomFieldOptionDestroyTaskApiService } from '@/api/user/resources/custom_field_option_destroy_task';
import ImportStatus from '@/components/organisms/user/leadImport/ImportStatus.vue';
import { useWait } from '@/composable/vue-wait';

const app = getCurrentInstance();
const {
  doActionWithWait,
} = useWait();
const api = new CustomFieldOptionDestroyTaskApiService();

const timeObj = ref<NodeJS.Timeout | null>(null);
const customFieldOptionDestroyTasks = ref<CustomFieldOptionDestroyTask[]>([]);

onBeforeMount(() => {
  fetchCustomFieldOptionDestroyTasks();
  timeObj.value = app.appContext.config.globalProperties.$setInterval(() => {
    fetchCustomFieldOptionDestroyTasks();
  }, 30000);
});
onBeforeUnmount(() => {
  app.appContext.config.globalProperties.$clearInterval(timeObj.value);
});

const fetchCustomFieldOptionDestroyTasks = async () => {
  const { data } = await doActionWithWait<AxiosResponse<CustomFieldOptionDestroyTask[]>>('getCustomFieldOptionDestroyTasksWait', async () => {
    return await api.getCustomFieldOptionDestroyTasks({});
  });

  customFieldOptionDestroyTasks.value = data;
};

const confirmTask = async (customFieldOptionDestroyTaskId: number) => {
  await doActionWithWait('confirmCustomFieldOptionDestroyTaskTaskWait', async () => {
    await api.confirmCustomFieldOptionDestroyTask({ request: { customFieldOptionDestroyTaskId } });
  });
  await fetchCustomFieldOptionDestroyTasks();
};
</script>
