<template>
  <BLayout align-center>
    <BBtn
      class="mx-300"
      size="small"
      fab
      flat
      data-test="closeCallTarget"
      @click="$emit('closeModal')"
    >
      <BIcon
        class="fs-400"
        size="small"
      >
        close
      </BIcon>
    </BBtn>
    <div class="target-close-button-line" />
    <BTooltip
      top
      :content="$t('callTarget.move.isEditing')"
      :disabled="editing"
    >
      <div>
        <BBtn
          class="mx-200"
          size="small"
          fab
          flat
          :disabled="prevDisabled || !prevCallTargetId"
          @click="moveToPreviousCallTarget"
        >
          <BIcon
            class="fs-400"
            size="small"
          >
            keyboard_arrow_left
          </BIcon>
        </BBtn>
      </div>
    </BTooltip>
    <BTooltip
      top
      :content="$t('callTarget.move.isEditing')"
      :disabled="editing"
    >
      <div>
        <BBtn
          class="mx-200"
          size="small"
          fab
          flat
          :disabled="nextDisabled || !nextCallTargetId"
          @click="moveToNextCallTarget"
        >
          <BIcon
            class="fs-400"
            size="small"
          >
            keyboard_arrow_right
          </BIcon>
        </BBtn>
      </div>
    </BTooltip>
  </BLayout>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { PaginationMeta } from '@/api/openapi';
import { TCallTarget, usePrevNext } from '@/composable/user/leadList/paging';

type TProps = {
  callTargetId: number;
  currentCallTargets: TCallTarget[]; // TODO: define type
  callTargetsMeta: PaginationMeta | null;
  pageLoading: boolean;
  disabled: boolean;
};
type TEmit = {
  closeModal: [];
  changeTargetPage: [callTargetId: number, page: number];
  changeTargetCallTarget: [callTargetId: number];
};

const props = defineProps<TProps>();
const emit = defineEmits<TEmit>();

const {
  currentPage,
  prevDisabled,
  nextDisabled,
  isPrevMovesAcrossPages,
  isNextMovesAcrossPages,
  prevCallTargetId,
  nextCallTargetId,
} = usePrevNext(
  () => props.pageLoading,
  () => props.disabled,
  () => props.callTargetId,
  () => props.currentCallTargets,
  () => props.callTargetsMeta,
);

const editing = computed(() => !props.disabled);

const moveToPreviousCallTarget = () => {
  if (isPrevMovesAcrossPages.value) { 
    emit('changeTargetPage', prevCallTargetId.value, currentPage.value - 1);
  } else {
    emit('changeTargetCallTarget', prevCallTargetId.value);
  }
};
const moveToNextCallTarget = () => {
  if (isNextMovesAcrossPages.value) {
    emit('changeTargetPage', nextCallTargetId.value, currentPage.value + 1);
  } else {
    emit('changeTargetCallTarget', nextCallTargetId.value);
  }
};
</script>

<style lang="scss" scoped>
  .target-close-button-line {
    border-right: 1px solid $bdcolor-base;
    height: 25px;
  }
</style>
