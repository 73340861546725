<template>
  <Component
    :is="tag"
    ref="el"
    class="b-slide-up-down"
    :class="{ active: active }"
  >
    <slot />
  </Component>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';

type TProps = {
  active?: boolean;
  duration?: number;
  tag?: string;
};

const props = withDefaults(defineProps<TProps>(), {
  active: false,
  duration: 500,
  tag: 'div',
});

const el = ref<HTMLElement | null>(null);
const maxHeight = ref<string | null>(props.active ? null : '0px');
const styleDuration = computed(() => `${props.duration}ms`);

watch(() => props.active, (active) => {
  toggle(active);
});

const toggle = (active: boolean) => {
  // NOTE: scrollHeightは動的に変わるため、直前のこのタイミングで取得している
  const beforeHeight = active ? 0 : el.value?.scrollHeight;
  const afterHeight = active ? el.value?.scrollHeight : 0;
  maxHeight.value = `${beforeHeight}px`;
  // NOTE: 上記css適用を待つ
  setTimeout(() => {
    maxHeight.value = `${afterHeight}px`;
  }, 50);
};
</script>

<style lang="scss" scoped>
.b-slide-up-down {
  overflow: hidden;
  transition-property: max-height;
  transition-duration: v-bind(styleDuration);
  max-height: v-bind(maxHeight);
}
</style>
