<template>
  <BDialog
    :is-visible="dialog"
    :width="width"
  >
    <slot name="form">
      <BLayout
        align-center
        justify-space-around
      >
        <span>Must insert form component!</span>
      </BLayout>
    </slot>
  </BDialog>
</template>

<script>
export default {
  name: 'FormDialog',
  props: {
    dialog: Boolean,
    width: [String, Number],
  },
};
</script>
