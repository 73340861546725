<template>
  <div>
    <div class="theader">
      <div class="table-row theader-row">
        <div
          v-for="header in headers"
          :key="header.key"
          class="table-cell header-cell"
        >
          {{ header.name }}
        </div>
      </div>
    </div>
    <VWait for="fetchSummary">
      <template #waiting>
        <BPlaceholder
          v-loading="true"
          element-loading-background="transparent"
        />
      </template>
      <div
        v-if="statusOverview"
        class="tbody"
      >
        <div class="table-row tbody-row">
          <div
            class="table-cell"
            :class="{ 'filtered': isFiltered(null) }"
            @click="handleStatusClick(null)"
          >
            <span>{{ statusOverviewAll }}</span>
          </div>
          <div
            class="table-cell"
            :class="{ 'filtered': isFiltered('ready') }"
            @click="handleStatusClick('ready')"
          >
            {{ statusOverview.numberOfReady }}
          </div>
          <div
            class="table-cell"
            :class="{ 'filtered': isFiltered('executing') }"
            @click="handleStatusClick('executing')"
          >
            {{ statusOverview.numberOfExecuting }}
          </div>
          <div
            class="table-cell"
            :class="{ 'filtered': isFiltered('completed') }"
            @click="handleStatusClick('completed')"
          >
            {{ statusOverview.numberOfCompleted }}
          </div>
          <div
            class="table-cell"
            :class="{ 'filtered': isFiltered('failed') }"
            @click="handleStatusClick('failed')"
          >
            {{ statusOverview.numberOfFailed }}
          </div>
          <div
            class="table-cell"
            :class="{ 'filtered': isFiltered('stopped') }"
            @click="handleStatusClick('stopped')"
          >
            {{ statusOverview.numberOfStopped }}
          </div>
        </div>
      </div>
    </VWait>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { Trigger, TriggerTarget, TriggerTargetStatus, TriggerTargetStatusOverview } from '@/api/openapi';
import { useSequenceMaster } from '@/composable/user/sequence/sequence-master';

type TEmits = {
  'update:modelValue': [value: TriggerTargetStatus | null]
};

const emit = defineEmits<TEmits>();
const status = defineModel<TriggerTargetStatus | null>();
const statusOverview = defineModel<TriggerTargetStatusOverview>('statusOverview');

const statusOverviewAll = computed(() =>
  Object.values(statusOverview.value).reduce((prev, current) => prev + current, 0),
);

const i18n = useI18n();
const headers = [
  { key: 'all', name: i18n.t('trigger.target.status.all') },
  { key: 'ready', name: i18n.t('trigger.target.status.enum.ready') },
  { key: 'executing', name: i18n.t('trigger.target.status.enum.executing') },
  { key: 'completed', name: i18n.t('trigger.target.status.enum.completed') },
  { key: 'failed', name: i18n.t('trigger.target.status.enum.failed') },
  { key: 'stopped', name: i18n.t('trigger.target.status.enum.stopped') },
];

const handleStatusClick = (value: TriggerTargetStatus | null) => {
  emit('update:modelValue', value);
};

const isFiltered = (value: TriggerTargetStatus | null) => status.value === value;
</script>

<style lang="scss" scoped>
.table-row {
  display: flex;
  align-items: center;
  padding-right: $basespace-400;

  .apply-to-lead-button {
    margin-left: auto;
  }
}

.table-cell {
  text-align: center;
  padding-right: $basespace-200;
  padding-left: 10px;
  min-width: 5rem;
  width: 5rem;
  background-color: white;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  &:first-child {
    padding-left: $basespace-600;
    width: calc(5rem + $basespace-600 - 10px);
    margin-left: -12px;
  }
}

.theader {
  &-row {
    background-color: white;
    border-bottom: 1px solid $bdcolor-base;
    padding-top: $basespace-200;
    padding-bottom: $basespace-200;
    color: $textcolor-light;
    font-size: $fontsize-100;
  }
}

.tbody {
  &-row {
    border-bottom: 1px solid $bdcolor-base;
    padding-top: $basespace-200;
    padding-bottom: $basespace-200;
    font-weight: bold;
    font-size: $fontsize-500;
  }
}

.filtered {
  color: $basecolor-primary;
}
</style>
