<template>
  <LeadHistoryCard v-bind="attributes">
    <template #date>
      <div>{{ historiedAt }}</div>
    </template>
    <BEditableContentTextarea
      v-model="content"
      class="freeaction-content"
      :placeholder="$t('freeAction.content')"
      @update:model-value="updateFreeAction"
      @click="setTargetHeaderMoveButtonDisable(true)"
      @cancel="setTargetHeaderMoveButtonDisable(false)"
    />
    <BLayout
      justify-end
      class="mt-50"
    >
      <BCopyButton
        :text="content"
        :title="$t('freeAction.content')"
      />
    </BLayout>
    <template #action>
      <BBtn
        v-if="isAvailableSalesforceApiFeature"
        class="mt-2 mr-2"
        size="small"
        fab
        flat
      >
        <BSfStatusIcon
          :connect-status="sfConnectedStatus"
          @open-sf-modal="openSfModal(sfFreeActionTaskName)"
        />
      </BBtn>
      <SfStatusModal
        v-if="isAvailableSalesforceApiFeature"
        :modal-name="sfFreeActionTaskName"
        :sf-related-link-info="freeAction.sfRelatedLinkInfo"
        :sf-exec-sync-btn="false"
        :resync-task-info="resyncTaskInfo"
        @synced="updateSynced"
      />
      <BBtn
        size="small"
        fab
        flat
        data-test="delete"
        @click.stop="$emit('click-delete', freeAction.id)"
      >
        <BIcon size="small">
          delete_forever
        </BIcon>
      </BBtn>
    </template>
  </LeadHistoryCard>
</template>

<script>
import { mapWaitingActions } from 'vue-wait';
import { mapGetters } from 'vuex';
import SfStatusModal from '@/components/organisms/user/general/modal/salesforce/SfStatusModal.vue';
import LeadHistoryCard from '@/components/organisms/user/lead/history/LeadHistoryCard.vue';
import { useAvailableFeatureCheck } from '@/composable/available-feature-check';
import SalesforceConst from '@/const/salesforce';
import errorHandler from '@/mixins/error_handler';
import queryParameter from '@/mixins/query_parameter';
import BSfStatusIcon from '@/plugins/biscuet-materials/components/molecules/BSfStatusIcon.vue';
import { formatShorterDateTime } from '@/utils/date-time';

export default {
  components: {
    LeadHistoryCard,
    SfStatusModal,
    BSfStatusIcon,
  },
  mixins: [queryParameter, errorHandler],
  props: {
    freeAction: Object,
    expand: Boolean,
  },
  emits: [
    'click-delete',
  ],
  setup() {
    const { isAvailableSalesforceApiFeature } = useAvailableFeatureCheck();
    return {
      isAvailableSalesforceApiFeature,
    };
  },
  data () {
    return {
      content: this.freeAction.content,
      sfConnectedStatusValue: this.freeAction.sfRelatedLinkInfo?.sfRelatedLinks[0]?.status,
    };
  },
  computed: {
    ...mapGetters('user', [
      'callTarget',
    ]),
    attributes () {
      return {
        'text': this.$t('freeAction.title'),
        'user': this.freeAction.user,
        'icon': 'add',
        'icon-type': 'default',
        'toggle': true,
        'expand': this.expand,
      };
    },
    historiedAt () {
      return formatShorterDateTime(this.freeAction.historiedAt);
    },
    sfConnectedStatus() {
      return this.sfConnectedStatusValue;
    },
    sfFreeActionTaskName() {
      return `sfTaskStatusFreeActionModal${this.freeAction.id}`;
    },
    resyncTaskInfo() {
      return {
        id: this.freeAction.id,
        callTargetId: this.callTarget.id,
        todoType: this.freeAction.type,
      };
    },
  },
  watch: {
    'freeAction.content' (newVal) {
      this.content = newVal;
    },
  },
  methods: {
    ...mapWaitingActions('user', {
      updateFreeActionAction: 'updateFreeActionAction',
      getCallTargetsBackgroundAction: { action: 'getCallTargetsAction', loader: 'getCallTargetsBackgroundWait' },
      getLeadHistoriesBackgroundAction: { action: 'getLeadHistoriesAction', loader: 'getLeadHistoriesBackgroundWait' },
    }),
    ...mapWaitingActions('userUi', {
      setTargetHeaderMoveButtonDisabledAction: 'setTargetHeaderMoveButtonDisabledWait',
    }),
    setTargetHeaderMoveButtonDisable (status) {
      this.setTargetHeaderMoveButtonDisabledAction(status);
    },
    updateFreeAction () {
      this.setTargetHeaderMoveButtonDisabledAction(false);
      this.updateFreeActionAction({
        freeActionId: this.freeAction.id,
        body: { freeAction: { ...this.freeAction, content: this.content } },
        errorHandlers: {
          422: this.defaultHandler,
        },
      }).then(() => {
        return this.getLeadHistoriesBackgroundAction({ callTargetId: this.callTarget.id });
      }).then(() => {
        return this.getCallTargetsBackgroundAction({ callListId: this.$route.params.id });
      });
    },
    openSfModal(modalName) {
      this.$modal.show(modalName);
    },
    updateSynced() {
      this.sfConnectedStatusValue = SalesforceConst.Synced;
    },
  },
};
</script>

<style lang="scss" scoped>
  .freeaction-content {
    margin-left: -$basespace-100;
    margin-right: -$basespace-100;
  }
</style>
