import { computed, ref } from 'vue';
import { SequenceBody } from '@/api/openapi';
import { TFormSequenceStep } from '@/components/organisms/user/general/sequence/types';
import { isSameObject } from '@/services/object-compare-service';
import { TObject } from '@/types';

const isSequenceChanged = async(before: SequenceBody, after: SequenceBody) => 
  !await isSameObject(
    before as unknown as TObject,
    after as unknown as TObject,
  );

const isSequenceStepChanged = async(before: TFormSequenceStep, after: TFormSequenceStep) => 
  !await isSameObject(
    before as unknown as TObject,
    after as unknown as TObject,
  );
const isSequenceStepsChanged = async (before: TFormSequenceStep[], after: TFormSequenceStep[]) => 
  before.length !== after.length
  || await Promise
    .all(before.map((beforeStep, index) => isSequenceStepChanged(beforeStep, after[index])))
    .then(values => values.some(isStepChanged => isStepChanged));

const useChanged = () => {
  const sequenceChanged = ref(false);
  const sequenceStepsChanged = ref(false);
  
  const needConfirmationToLeave = computed(() => sequenceChanged.value || sequenceStepsChanged.value);

  const handleUpdateSequenceChanged = (changed: boolean) => sequenceChanged.value = changed; 
  const handleUpdateSequenceStepsChanged = (changed: boolean) => sequenceStepsChanged.value = changed;

  return {
    handleUpdateSequenceChanged,
    handleUpdateSequenceStepsChanged,
    needConfirmationToLeave,
  };
};

export {
  isSequenceChanged,
  isSequenceStepsChanged,
  useChanged,
};
