/* tslint:disable */
/* eslint-disable */
/**
 * openapi
 * bales cloud
 *
 * The version of the OpenAPI document: 1.0
 * Contact: test@example.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateMfaSetting201Response } from '../models';
// @ts-ignore
import { GetMfaSetting200Response } from '../models';
// @ts-ignore
import { MfaSettingBody } from '../models';
/**
 * MfaSettingApi - axios parameter creator
 * @export
 */
export const MfaSettingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * MFA設定API
         * @summary MFA設定API
         * @param {MfaSettingBody} [mfaSettingBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMfaSetting: async (mfaSettingBody?: MfaSettingBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/mfa/mfa_setting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mfaSettingBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * MFA設定解除API
         * @summary MFA設定解除API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMfaSetting: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/mfa/mfa_setting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * MFA設定取得API
         * @summary MFA設定取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMfaSetting: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/mfa/mfa_setting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MfaSettingApi - functional programming interface
 * @export
 */
export const MfaSettingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MfaSettingApiAxiosParamCreator(configuration)
    return {
        /**
         * MFA設定API
         * @summary MFA設定API
         * @param {MfaSettingBody} [mfaSettingBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMfaSetting(mfaSettingBody?: MfaSettingBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateMfaSetting201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMfaSetting(mfaSettingBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * MFA設定解除API
         * @summary MFA設定解除API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMfaSetting(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMfaSetting(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * MFA設定取得API
         * @summary MFA設定取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMfaSetting(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMfaSetting200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMfaSetting(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MfaSettingApi - factory interface
 * @export
 */
export const MfaSettingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MfaSettingApiFp(configuration)
    return {
        /**
         * MFA設定API
         * @summary MFA設定API
         * @param {MfaSettingBody} [mfaSettingBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMfaSetting(mfaSettingBody?: MfaSettingBody, options?: any): AxiosPromise<CreateMfaSetting201Response> {
            return localVarFp.createMfaSetting(mfaSettingBody, options).then((request) => request(axios, basePath));
        },
        /**
         * MFA設定解除API
         * @summary MFA設定解除API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMfaSetting(options?: any): AxiosPromise<boolean> {
            return localVarFp.deleteMfaSetting(options).then((request) => request(axios, basePath));
        },
        /**
         * MFA設定取得API
         * @summary MFA設定取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMfaSetting(options?: any): AxiosPromise<GetMfaSetting200Response> {
            return localVarFp.getMfaSetting(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MfaSettingApi - interface
 * @export
 * @interface MfaSettingApi
 */
export interface MfaSettingApiInterface {
    /**
     * MFA設定API
     * @summary MFA設定API
     * @param {MfaSettingBody} [mfaSettingBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MfaSettingApiInterface
     */
    createMfaSetting(mfaSettingBody?: MfaSettingBody, options?: AxiosRequestConfig): AxiosPromise<CreateMfaSetting201Response>;

    /**
     * MFA設定解除API
     * @summary MFA設定解除API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MfaSettingApiInterface
     */
    deleteMfaSetting(options?: AxiosRequestConfig): AxiosPromise<boolean>;

    /**
     * MFA設定取得API
     * @summary MFA設定取得API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MfaSettingApiInterface
     */
    getMfaSetting(options?: AxiosRequestConfig): AxiosPromise<GetMfaSetting200Response>;

}

/**
 * Request parameters for createMfaSetting operation in MfaSettingApi.
 * @export
 * @interface MfaSettingApiCreateMfaSettingRequest
 */
export interface MfaSettingApiCreateMfaSettingRequest {
    /**
     * 
     * @type {MfaSettingBody}
     * @memberof MfaSettingApiCreateMfaSetting
     */
    readonly mfaSettingBody?: MfaSettingBody
}

/**
 * MfaSettingApi - object-oriented interface
 * @export
 * @class MfaSettingApi
 * @extends {BaseAPI}
 */
export class MfaSettingApi extends BaseAPI implements MfaSettingApiInterface {
    /**
     * MFA設定API
     * @summary MFA設定API
     * @param {MfaSettingApiCreateMfaSettingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MfaSettingApi
     */
    public createMfaSetting(requestParameters: MfaSettingApiCreateMfaSettingRequest = {}, options?: AxiosRequestConfig) {
        return MfaSettingApiFp(this.configuration).createMfaSetting(requestParameters.mfaSettingBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * MFA設定解除API
     * @summary MFA設定解除API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MfaSettingApi
     */
    public deleteMfaSetting(options?: AxiosRequestConfig) {
        return MfaSettingApiFp(this.configuration).deleteMfaSetting(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * MFA設定取得API
     * @summary MFA設定取得API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MfaSettingApi
     */
    public getMfaSetting(options?: AxiosRequestConfig) {
        return MfaSettingApiFp(this.configuration).getMfaSetting(options).then((request) => request(this.axios, this.basePath));
    }
}
