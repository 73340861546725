import Waiting from '@/store/waiting';
import SfBaseActions from './sf_base';

export default class SalesforceOpportunityActions extends SfBaseActions {
  constructor(Api, types) {
    super();
    const actions = {
      createSfOpportunityAction: this.SalesForceBaseAction(({ dispatch }, args) => {
        return waiting.check('createSfOpportunityAction', async () => {
          try {
            return await Api.createSfOpportunity({
              body: args,
            });
          } catch (err) {
            throw err;
          }
        });
      }),
    };
    const waiting = new Waiting(actions);
    this.actions = actions;
  }
}
