<template>
  <div class="b-editable-lead-stage-select">
    <BLayout
      v-if="editing"
      row
      align-center
      justify-space-between
    >
      <BLeadStageSelect
        ref="refSelect"
        v-model="selectedLeadStageId"
        :lead-stages="leadStages"
        :loading="loading"
      />
      <div
        v-if="!hideBtnGroup"
        class="btn-area"
      >
        <BBtn
          v-if="valueChanged"
          class="mr-50"
          size="small"
          fab
          flat
          :loading="loading"
          @click="handleCheck"
        >
          <BIcon
            class="check-icon"
            size="small"
          >
            check
          </BIcon>
        </BBtn>
        <BBtn
          size="small"
          fab
          flat
          :disabled="loading"
          @click="handleClose"
        >
          <BIcon
            class="close-icon"
            size="small"
          >
            close
          </BIcon>
        </BBtn>
      </div>
    </BLayout>
    <div
      v-else
      class="preview-container"
      @click="handleEdit"
    >
      <BLeadStagePreview
        :lead-stage="modelValue"
        :truncate-text="false"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, ref, watch } from 'vue';
import { LeadStage } from '@/api/openapi';

type TProps = {
  modelValue: LeadStage | null;
  leadStages: LeadStage[];
  afterCheck?: (newLeadStage: LeadStage | null) => Promise<void>;
  hideBtnGroup?: boolean;
};

const props = withDefaults(defineProps<TProps>(), {
  afterCheck: undefined,
  editing: false,
  hideBtnGroup: false,
});

const emit = defineEmits(['update:modelValue']);

const selectedLeadStage = (id: number | null): LeadStage | undefined => props.leadStages.find(ls => ls.id === id);
const getSelectedLeadStageId = (leadStage: LeadStage | null): number | null => leadStage ? leadStage.id : null;

const selectedLeadStageId = ref<number | null>(getSelectedLeadStageId(props.modelValue));
const editing = ref(false);
const loading = ref(false);
const refSelect = ref<HTMLElement>(null);

const valueChanged = computed<boolean>(() => {
  return selectedLeadStageId.value !== getSelectedLeadStageId(props.modelValue);
});
watch(props.modelValue, async (newVal) => {
  selectedLeadStageId.value = getSelectedLeadStageId(newVal);
}, { immediate: true });

const handleEdit = () => {
  editing.value = true;
  nextTick(() => refSelect.value.focus());
};
const handleCheck = async () => {
  loading.value = true;
  const newLeadStage = selectedLeadStage(selectedLeadStageId.value) ?? null;
  emit('update:modelValue', newLeadStage);
  try {
    if (props.afterCheck) {
      await props.afterCheck(newLeadStage);
    }
  } finally {
    loading.value = false;
  }
  editing.value = false;
};
const handleClose = () => editing.value = false;
</script>

<style lang="scss" scoped>
  .b-editable-lead-stage-select {
    width: 100%;
  }
  :deep(.b-select) {
    margin-right: 12px;
    margin-left: -$basespace-100;
  }
  .preview-container {
    width: 100%;
    border: 1px solid transparent;
    padding: 4px $basespace-100;
    margin-left: -$basespace-100;
    border-radius: 4px;
    white-space: pre-wrap;
    word-wrap: break-word;

    &:hover {
      border: 1px solid $bdcolor-base;
      cursor: pointer;
    }
  }
  .btn-area {
    display: flex;
    align-items: center;
    justify-content: flex-end
  }
  .check-icon {
    color: $basecolor-primary;
  }
  .close-icon {
    color: $textcolor-light;
  }
</style>
