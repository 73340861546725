<template>
  <div 
    v-loading="$wait.is(keyOfGetReserveItemsWait)" 
    class="reserve-item-area"
  >
    <div 
      v-for="directMail in reserveItems" 
      :key="`reserve-${directMail.id}`" 
      class="reserve-item-task"
      @click="() => handleDirectMailConfirm(directMail)"
    >
      <DirectMail
        :direct-mail="directMail"
        @after-delete="handleDirectMailReload"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ReserveDirectMail } from '@/api/openapi';
import { isRelatedToSequence } from '@/composable/user/directMail/direct-mail';
import DirectMail from './task/DirectMail.vue';

type TProps = {
  reserveItems: ReserveDirectMail[];
  keyOfGetReserveItemsWait: string;
};
type TEmits = {
  (event: 'click:directMailConfirm', directMail: ReserveDirectMail): void,
  (event: 'afterDeleteDirectMail'): void,
  (event: 'click:sequenceAction', sequenceInstanceId: number): void
};

defineProps<TProps>();

const emit = defineEmits<TEmits>();

const handleDirectMailConfirm = (directMail: ReserveDirectMail) => {
  if (isRelatedToSequence(directMail)) {
    emit('click:sequenceAction', directMail.sequenceInstanceId);
  } else {
    emit('click:directMailConfirm', directMail);
  }
};
const handleDirectMailReload = () => { emit('afterDeleteDirectMail'); };
</script>
