<template>
  <div
    :class="dispClasses"
    @click="$emit('click')"
  >
    <template v-if="tooltip">
      <BTooltip top>
        <BCustomIcon
          :class="iconClasses"
          :icon-class="iconClass"
          :size="iconSizeText"
        />
        <template #content>
          <span>{{ tooltip }}</span>
        </template>
      </BTooltip>
    </template>
    <template v-else>
      <BCustomIcon
        :class="iconClasses"
        :icon-class="iconClass"
        :size="iconSizeText"
        @click="$emit('click')"
      />
    </template>
    <div :class="textClasses">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    iconClass: {
      type: String,
      required: true,
    },
    tooltip: {
      type: String,
      default: '',
    },
    flex: {
      type: Boolean,
      default: false,
    },
    textSize: {
      type: String,
      default: 'small',
    },
    iconSize: {
      type: String,
      default: 'medium',
    },
    hover: {
      type: Boolean,
      default: false,
    },
    primary: {
      type: Boolean,
      default: false,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'click',
  ],
  computed: {
    dispClasses() {
      return {
        'icon-text-flex': this.flex,
        'icon-text-grid': !this.flex,
        'b-icon-text-hover': this.hover,
      };
    },
    textClasses() {
      return {
        'inner-text': true,
        'inner-text-mini': this.textSize == 'mini',
        'inner-text-small': this.textSize == 'small',
        'inner-text-large': this.textSize == 'large',
        'inner-text-primary': this.primary,
        'inner-text-tooltip': this.tooltip,
        'inner-text-small-tooltip': this.tooltip && this.textSize == 'small',
        'inner-text-large-tooltip': this.tooltip && this.textSize == 'large',
      };
    },
    iconClasses() {
      return {
        'b-icon': true,
        'icon-primary': this.primary,
        'icon-secondary': this.secondary,
      };
    },
    iconSizeText() {
      if (this.iconSize == 'mini') return 'mini';
      if (this.iconSize == 'small') return 'small';
      if (this.iconSize == 'large') return 'large';
      return 'medium';
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-text-flex {
  display: flex;
  align-items: center;
}
.icon-text-grid {
  display: grid;
  align-items: center;
}
.inner-text {
  text-align: center;
  line-height: $fontsize-200;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.inner-text-tooltip {
  padding-bottom: 2px;
}
.inner-text-small-tooltip,
.inner-text-large-tooltip {
  padding-bottom: 3px;
}
.inner-text-mini {
  font-size: 10px;
  line-height: 10px;
}
.inner-text-small {
  font-size: $fontsize-100;
  line-height: $fontsize-100;
}
.inner-text-large {
  font-size: $fontsize-300;
  line-height: $fontsize-300;
}
.inner-text-primary {
  color: $basecolor-primary;
}
.b-icon {
  color: $textcolor-base;
}
.icon-primary {
  color: $basecolor-primary;
}

.b-icon-text-hover {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: $basespace-50;
  border-radius: 4px;
  height: 40px;

  &:hover {
    background-color: $bgcolor-dark;
  }
}
</style>
