<template>
  <div class="b-editable-content">
    <template v-if="internalEditing">
      <div class="input-box">
        <div
          class="b-input-box"
          :class="classes"
        >
          <BInputDateTime
            v-model:date="date"
            v-model:time="time"
            class="input-area mr-100"
            :disabled="loading"
            clearable
            :data-test="dataTest"
          />
          <div class="btn-area">
            <BLayout align-center>
              <BBtn
                v-if="valueChanged"
                class="mr-50"
                size="small"
                fab
                flat
                :loading="loading"
                :data-test="`${dataTest}Btn`"
                @click="check"
              >
                <BIcon
                  class="check-icon"
                  size="small"
                >
                  check
                </BIcon>
              </BBtn>
              <BBtn
                size="small"
                fab
                flat
                :disabled="loading"
                @click="close"
              >
                <BIcon
                  class="close-icon"
                  size="small"
                >
                  close
                </BIcon>
              </BBtn>
            </BLayout>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div
        class="item input"
        :data-test="dataTest"
        @click="handleClickEdit"
      >
        <template v-if="value">
          {{ formattedValue }}
        </template>
        <template v-else>
          {{ hyphen ? '-' : '' }}
        </template>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { formatShorterDateTime, formatDateTime, isDateValid, mergeDateAndTime } from '@/utils/date-time';

export default defineComponent({
  props: {
    required: Boolean,
    hyphen: Boolean,
    loading: Boolean,
    editing: Boolean,
    value: {
      type: String,
      default: null,
    },
    dataTest: String,
  },
  emits: [
    'update:editing',
    'check',
    'cancel',
  ],
  data () {
    return {
      newValue: this.value,
      internalEditing: false,
      horiz: true,
      vert: false,
      valueChanged: false,
    };
  },
  computed: {
    classes () {
      return {
        'b-input-box-horiz': this.horiz,
        'b-input-box-vert': this.vert,
      };
    },
    date: {
      get () {
        return isDateValid(this.newValue) ? new Date(this.newValue) : null;
      },
      set (newVal) {
        if (this.newValue === newVal) return;

        this.valueChanged = true;
        if (this.newValue == null) {
          this.newValue = newVal;
        } else {
          this.newValue = mergeDateAndTime(newVal, this.newValue);
        }
      },
    },
    time: {
      get () {
        return isDateValid(this.newValue) ? new Date(this.newValue) : null;
      },
      set (newVal) {
        if (this.newValue === newVal) return;

        this.valueChanged = true;
        if (this.newValue == null) {
          this.newValue = newVal;
        } else {
          this.newValue = mergeDateAndTime(this.newValue, newVal);
        }
      },
    },
    formattedValue () {
      return formatShorterDateTime(this.value);
    },
  },
  watch: {
    loading (newVal) {
      // loadingを渡さないと、save時にフォームが終了しない
      if (!newVal) { this.internalEditing = false; }
    },
    editing (newVal) {
      this.internalEditing = newVal;
    },
    internalEditing (newVal) {
      this.$emit('update:editing', newVal);
    },
    value (newVal) {
      // MEMO: propsのvalueが変更された場合に検知できないため
      this.newValue = newVal;
    },
  },
  created () {
    this.internalEditing = this.editing;
  },
  methods: {
    handleClickEdit () {
      if (this.newValue == null) {
        this.newValue = formatDateTime(new Date());
        this.valueChanged = true;
      } else {
        this.valueChanged = false;
      }
      this.internalEditing = true;
    },
    check () {
      this.$emit('check', this.newValue);
    },
    close () {
      this.$emit('cancel');
      this.newValue = this.value;
      this.internalEditing = false;
    },
  },
});
</script>

<style lang="scss" scoped>
  .b-editable-content {
    width: 100%;
  }

  .item {
    width: 100%;
    border: 1px solid transparent;
    padding: $basespace-50 $basespace-100;
    margin-left: -$basespace-100;

    &:hover {
      border-color: $bdcolor-light;
      cursor: pointer;
    }

    &.input:hover {
      border-radius: 4px;
    }
  }

  .input-box {
    margin-left: -$basespace-100;
  }

  .b-input-box-vert {
    .input-area {
      margin-bottom: $basespace-200;
    }

    .btn-area {
      display: flex;
      justify-content: flex-end
    }
  }

  .b-input-box-horiz {
    display: flex;
    align-items: center;
  }

  .check-icon {
    color: $basecolor-primary;
  }

  .close-icon {
    color: $textcolor-light;
  }
</style>
