/* tslint:disable */
/* eslint-disable */
/**
 * openapi
 * bales cloud
 *
 * The version of the OpenAPI document: 1.0
 * Contact: test@example.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DirectMail } from '../models';
// @ts-ignore
import { GetDraftItems200Response } from '../models';
// @ts-ignore
import { GetReserveItems200Response } from '../models';
// @ts-ignore
import { PostPutDirectMailBody } from '../models';
// @ts-ignore
import { PutReserveBody } from '../models';
/**
 * DirectMailApi - axios parameter creator
 * @export
 */
export const DirectMailApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 個別メール配信予約解除API
         * @summary 個別メール配信予約解除API
         * @param {number} directMailId 個別メールID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelReservationDirectMail: async (directMailId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'directMailId' is not null or undefined
            assertParamExists('cancelReservationDirectMail', 'directMailId', directMailId)
            const localVarPath = `/api/user/direct_mails/{directMailId}/cancel_reservation`
                .replace(`{${"directMailId"}}`, encodeURIComponent(String(directMailId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 個別メール削除API
         * @summary 個別メール削除API
         * @param {number} directMailId 個別メールID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDirectMail: async (directMailId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'directMailId' is not null or undefined
            assertParamExists('deleteDirectMail', 'directMailId', directMailId)
            const localVarPath = `/api/user/direct_mails/{directMailId}`
                .replace(`{${"directMailId"}}`, encodeURIComponent(String(directMailId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 個別メール取得API
         * @summary 個別メール取得API
         * @param {number} directMailId 個別メールID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDirectMail: async (directMailId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'directMailId' is not null or undefined
            assertParamExists('getDirectMail', 'directMailId', directMailId)
            const localVarPath = `/api/user/direct_mails/{directMailId}`
                .replace(`{${"directMailId"}}`, encodeURIComponent(String(directMailId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 編集可能個別メール取得API
         * @summary 編集可能個別メール取得API
         * @param {number} callTargetId リードID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDraftItems: async (callTargetId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'callTargetId' is not null or undefined
            assertParamExists('getDraftItems', 'callTargetId', callTargetId)
            const localVarPath = `/api/user/call_targets/{callTargetId}/draft_items`
                .replace(`{${"callTargetId"}}`, encodeURIComponent(String(callTargetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 配信予約個別メール取得API
         * @summary 配信予約個別メール取得API
         * @param {number} callTargetId リードID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReserveItems: async (callTargetId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'callTargetId' is not null or undefined
            assertParamExists('getReserveItems', 'callTargetId', callTargetId)
            const localVarPath = `/api/user/call_targets/{callTargetId}/direct_mails/reserve_items`
                .replace(`{${"callTargetId"}}`, encodeURIComponent(String(callTargetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 個別メール作成API
         * @summary 個別メール作成API
         * @param {PostPutDirectMailBody} [postPutDirectMailBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDirectMail: async (postPutDirectMailBody?: PostPutDirectMailBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/direct_mails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postPutDirectMailBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 個別メール更新API
         * @summary 個別メール更新API
         * @param {number} directMailId 個別メールID
         * @param {PostPutDirectMailBody} [postPutDirectMailBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDirectMail: async (directMailId: number, postPutDirectMailBody?: PostPutDirectMailBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'directMailId' is not null or undefined
            assertParamExists('putDirectMail', 'directMailId', directMailId)
            const localVarPath = `/api/user/direct_mails/{directMailId}`
                .replace(`{${"directMailId"}}`, encodeURIComponent(String(directMailId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postPutDirectMailBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 個別メール配信予約API
         * @summary 個別メール配信予約API
         * @param {number} directMailId 個別メールID
         * @param {PutReserveBody} [putReserveBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reserveDirectMail: async (directMailId: number, putReserveBody?: PutReserveBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'directMailId' is not null or undefined
            assertParamExists('reserveDirectMail', 'directMailId', directMailId)
            const localVarPath = `/api/user/direct_mails/{directMailId}/reserve`
                .replace(`{${"directMailId"}}`, encodeURIComponent(String(directMailId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putReserveBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DirectMailApi - functional programming interface
 * @export
 */
export const DirectMailApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DirectMailApiAxiosParamCreator(configuration)
    return {
        /**
         * 個別メール配信予約解除API
         * @summary 個別メール配信予約解除API
         * @param {number} directMailId 個別メールID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelReservationDirectMail(directMailId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DirectMail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelReservationDirectMail(directMailId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 個別メール削除API
         * @summary 個別メール削除API
         * @param {number} directMailId 個別メールID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDirectMail(directMailId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDirectMail(directMailId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 個別メール取得API
         * @summary 個別メール取得API
         * @param {number} directMailId 個別メールID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDirectMail(directMailId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DirectMail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDirectMail(directMailId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 編集可能個別メール取得API
         * @summary 編集可能個別メール取得API
         * @param {number} callTargetId リードID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDraftItems(callTargetId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDraftItems200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDraftItems(callTargetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 配信予約個別メール取得API
         * @summary 配信予約個別メール取得API
         * @param {number} callTargetId リードID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReserveItems(callTargetId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetReserveItems200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReserveItems(callTargetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 個別メール作成API
         * @summary 個別メール作成API
         * @param {PostPutDirectMailBody} [postPutDirectMailBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDirectMail(postPutDirectMailBody?: PostPutDirectMailBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DirectMail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDirectMail(postPutDirectMailBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 個別メール更新API
         * @summary 個別メール更新API
         * @param {number} directMailId 個別メールID
         * @param {PostPutDirectMailBody} [postPutDirectMailBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putDirectMail(directMailId: number, postPutDirectMailBody?: PostPutDirectMailBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DirectMail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putDirectMail(directMailId, postPutDirectMailBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 個別メール配信予約API
         * @summary 個別メール配信予約API
         * @param {number} directMailId 個別メールID
         * @param {PutReserveBody} [putReserveBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reserveDirectMail(directMailId: number, putReserveBody?: PutReserveBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DirectMail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reserveDirectMail(directMailId, putReserveBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DirectMailApi - factory interface
 * @export
 */
export const DirectMailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DirectMailApiFp(configuration)
    return {
        /**
         * 個別メール配信予約解除API
         * @summary 個別メール配信予約解除API
         * @param {number} directMailId 個別メールID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelReservationDirectMail(directMailId: number, options?: any): AxiosPromise<DirectMail> {
            return localVarFp.cancelReservationDirectMail(directMailId, options).then((request) => request(axios, basePath));
        },
        /**
         * 個別メール削除API
         * @summary 個別メール削除API
         * @param {number} directMailId 個別メールID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDirectMail(directMailId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDirectMail(directMailId, options).then((request) => request(axios, basePath));
        },
        /**
         * 個別メール取得API
         * @summary 個別メール取得API
         * @param {number} directMailId 個別メールID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDirectMail(directMailId: number, options?: any): AxiosPromise<DirectMail> {
            return localVarFp.getDirectMail(directMailId, options).then((request) => request(axios, basePath));
        },
        /**
         * 編集可能個別メール取得API
         * @summary 編集可能個別メール取得API
         * @param {number} callTargetId リードID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDraftItems(callTargetId: number, options?: any): AxiosPromise<GetDraftItems200Response> {
            return localVarFp.getDraftItems(callTargetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 配信予約個別メール取得API
         * @summary 配信予約個別メール取得API
         * @param {number} callTargetId リードID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReserveItems(callTargetId: number, options?: any): AxiosPromise<GetReserveItems200Response> {
            return localVarFp.getReserveItems(callTargetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 個別メール作成API
         * @summary 個別メール作成API
         * @param {PostPutDirectMailBody} [postPutDirectMailBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDirectMail(postPutDirectMailBody?: PostPutDirectMailBody, options?: any): AxiosPromise<DirectMail> {
            return localVarFp.postDirectMail(postPutDirectMailBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 個別メール更新API
         * @summary 個別メール更新API
         * @param {number} directMailId 個別メールID
         * @param {PostPutDirectMailBody} [postPutDirectMailBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDirectMail(directMailId: number, postPutDirectMailBody?: PostPutDirectMailBody, options?: any): AxiosPromise<DirectMail> {
            return localVarFp.putDirectMail(directMailId, postPutDirectMailBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 個別メール配信予約API
         * @summary 個別メール配信予約API
         * @param {number} directMailId 個別メールID
         * @param {PutReserveBody} [putReserveBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reserveDirectMail(directMailId: number, putReserveBody?: PutReserveBody, options?: any): AxiosPromise<DirectMail> {
            return localVarFp.reserveDirectMail(directMailId, putReserveBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DirectMailApi - interface
 * @export
 * @interface DirectMailApi
 */
export interface DirectMailApiInterface {
    /**
     * 個別メール配信予約解除API
     * @summary 個別メール配信予約解除API
     * @param {number} directMailId 個別メールID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectMailApiInterface
     */
    cancelReservationDirectMail(directMailId: number, options?: AxiosRequestConfig): AxiosPromise<DirectMail>;

    /**
     * 個別メール削除API
     * @summary 個別メール削除API
     * @param {number} directMailId 個別メールID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectMailApiInterface
     */
    deleteDirectMail(directMailId: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 個別メール取得API
     * @summary 個別メール取得API
     * @param {number} directMailId 個別メールID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectMailApiInterface
     */
    getDirectMail(directMailId: number, options?: AxiosRequestConfig): AxiosPromise<DirectMail>;

    /**
     * 編集可能個別メール取得API
     * @summary 編集可能個別メール取得API
     * @param {number} callTargetId リードID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectMailApiInterface
     */
    getDraftItems(callTargetId: number, options?: AxiosRequestConfig): AxiosPromise<GetDraftItems200Response>;

    /**
     * 配信予約個別メール取得API
     * @summary 配信予約個別メール取得API
     * @param {number} callTargetId リードID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectMailApiInterface
     */
    getReserveItems(callTargetId: number, options?: AxiosRequestConfig): AxiosPromise<GetReserveItems200Response>;

    /**
     * 個別メール作成API
     * @summary 個別メール作成API
     * @param {PostPutDirectMailBody} [postPutDirectMailBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectMailApiInterface
     */
    postDirectMail(postPutDirectMailBody?: PostPutDirectMailBody, options?: AxiosRequestConfig): AxiosPromise<DirectMail>;

    /**
     * 個別メール更新API
     * @summary 個別メール更新API
     * @param {number} directMailId 個別メールID
     * @param {PostPutDirectMailBody} [postPutDirectMailBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectMailApiInterface
     */
    putDirectMail(directMailId: number, postPutDirectMailBody?: PostPutDirectMailBody, options?: AxiosRequestConfig): AxiosPromise<DirectMail>;

    /**
     * 個別メール配信予約API
     * @summary 個別メール配信予約API
     * @param {number} directMailId 個別メールID
     * @param {PutReserveBody} [putReserveBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectMailApiInterface
     */
    reserveDirectMail(directMailId: number, putReserveBody?: PutReserveBody, options?: AxiosRequestConfig): AxiosPromise<DirectMail>;

}

/**
 * Request parameters for cancelReservationDirectMail operation in DirectMailApi.
 * @export
 * @interface DirectMailApiCancelReservationDirectMailRequest
 */
export interface DirectMailApiCancelReservationDirectMailRequest {
    /**
     * 個別メールID
     * @type {number}
     * @memberof DirectMailApiCancelReservationDirectMail
     */
    readonly directMailId: number
}

/**
 * Request parameters for deleteDirectMail operation in DirectMailApi.
 * @export
 * @interface DirectMailApiDeleteDirectMailRequest
 */
export interface DirectMailApiDeleteDirectMailRequest {
    /**
     * 個別メールID
     * @type {number}
     * @memberof DirectMailApiDeleteDirectMail
     */
    readonly directMailId: number
}

/**
 * Request parameters for getDirectMail operation in DirectMailApi.
 * @export
 * @interface DirectMailApiGetDirectMailRequest
 */
export interface DirectMailApiGetDirectMailRequest {
    /**
     * 個別メールID
     * @type {number}
     * @memberof DirectMailApiGetDirectMail
     */
    readonly directMailId: number
}

/**
 * Request parameters for getDraftItems operation in DirectMailApi.
 * @export
 * @interface DirectMailApiGetDraftItemsRequest
 */
export interface DirectMailApiGetDraftItemsRequest {
    /**
     * リードID
     * @type {number}
     * @memberof DirectMailApiGetDraftItems
     */
    readonly callTargetId: number
}

/**
 * Request parameters for getReserveItems operation in DirectMailApi.
 * @export
 * @interface DirectMailApiGetReserveItemsRequest
 */
export interface DirectMailApiGetReserveItemsRequest {
    /**
     * リードID
     * @type {number}
     * @memberof DirectMailApiGetReserveItems
     */
    readonly callTargetId: number
}

/**
 * Request parameters for postDirectMail operation in DirectMailApi.
 * @export
 * @interface DirectMailApiPostDirectMailRequest
 */
export interface DirectMailApiPostDirectMailRequest {
    /**
     * 
     * @type {PostPutDirectMailBody}
     * @memberof DirectMailApiPostDirectMail
     */
    readonly postPutDirectMailBody?: PostPutDirectMailBody
}

/**
 * Request parameters for putDirectMail operation in DirectMailApi.
 * @export
 * @interface DirectMailApiPutDirectMailRequest
 */
export interface DirectMailApiPutDirectMailRequest {
    /**
     * 個別メールID
     * @type {number}
     * @memberof DirectMailApiPutDirectMail
     */
    readonly directMailId: number

    /**
     * 
     * @type {PostPutDirectMailBody}
     * @memberof DirectMailApiPutDirectMail
     */
    readonly postPutDirectMailBody?: PostPutDirectMailBody
}

/**
 * Request parameters for reserveDirectMail operation in DirectMailApi.
 * @export
 * @interface DirectMailApiReserveDirectMailRequest
 */
export interface DirectMailApiReserveDirectMailRequest {
    /**
     * 個別メールID
     * @type {number}
     * @memberof DirectMailApiReserveDirectMail
     */
    readonly directMailId: number

    /**
     * 
     * @type {PutReserveBody}
     * @memberof DirectMailApiReserveDirectMail
     */
    readonly putReserveBody?: PutReserveBody
}

/**
 * DirectMailApi - object-oriented interface
 * @export
 * @class DirectMailApi
 * @extends {BaseAPI}
 */
export class DirectMailApi extends BaseAPI implements DirectMailApiInterface {
    /**
     * 個別メール配信予約解除API
     * @summary 個別メール配信予約解除API
     * @param {DirectMailApiCancelReservationDirectMailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectMailApi
     */
    public cancelReservationDirectMail(requestParameters: DirectMailApiCancelReservationDirectMailRequest, options?: AxiosRequestConfig) {
        return DirectMailApiFp(this.configuration).cancelReservationDirectMail(requestParameters.directMailId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 個別メール削除API
     * @summary 個別メール削除API
     * @param {DirectMailApiDeleteDirectMailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectMailApi
     */
    public deleteDirectMail(requestParameters: DirectMailApiDeleteDirectMailRequest, options?: AxiosRequestConfig) {
        return DirectMailApiFp(this.configuration).deleteDirectMail(requestParameters.directMailId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 個別メール取得API
     * @summary 個別メール取得API
     * @param {DirectMailApiGetDirectMailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectMailApi
     */
    public getDirectMail(requestParameters: DirectMailApiGetDirectMailRequest, options?: AxiosRequestConfig) {
        return DirectMailApiFp(this.configuration).getDirectMail(requestParameters.directMailId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 編集可能個別メール取得API
     * @summary 編集可能個別メール取得API
     * @param {DirectMailApiGetDraftItemsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectMailApi
     */
    public getDraftItems(requestParameters: DirectMailApiGetDraftItemsRequest, options?: AxiosRequestConfig) {
        return DirectMailApiFp(this.configuration).getDraftItems(requestParameters.callTargetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 配信予約個別メール取得API
     * @summary 配信予約個別メール取得API
     * @param {DirectMailApiGetReserveItemsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectMailApi
     */
    public getReserveItems(requestParameters: DirectMailApiGetReserveItemsRequest, options?: AxiosRequestConfig) {
        return DirectMailApiFp(this.configuration).getReserveItems(requestParameters.callTargetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 個別メール作成API
     * @summary 個別メール作成API
     * @param {DirectMailApiPostDirectMailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectMailApi
     */
    public postDirectMail(requestParameters: DirectMailApiPostDirectMailRequest = {}, options?: AxiosRequestConfig) {
        return DirectMailApiFp(this.configuration).postDirectMail(requestParameters.postPutDirectMailBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 個別メール更新API
     * @summary 個別メール更新API
     * @param {DirectMailApiPutDirectMailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectMailApi
     */
    public putDirectMail(requestParameters: DirectMailApiPutDirectMailRequest, options?: AxiosRequestConfig) {
        return DirectMailApiFp(this.configuration).putDirectMail(requestParameters.directMailId, requestParameters.postPutDirectMailBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 個別メール配信予約API
     * @summary 個別メール配信予約API
     * @param {DirectMailApiReserveDirectMailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectMailApi
     */
    public reserveDirectMail(requestParameters: DirectMailApiReserveDirectMailRequest, options?: AxiosRequestConfig) {
        return DirectMailApiFp(this.configuration).reserveDirectMail(requestParameters.directMailId, requestParameters.putReserveBody, options).then((request) => request(this.axios, this.basePath));
    }
}
