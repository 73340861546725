import Api from '@/api/user';
import { SisenseApiService } from '@/api/user/resources/sisense';
import AuthService from './auth_service';

export default class UserService {
  constructor () {
    this.authService = new AuthService();
    this.sisenseApiService = new SisenseApiService();
  }
  async signinUser (email, password) {
    return this.authService.signinWithEmailAndPassword(email.trim(), password).then(() => {
      return this.signin();
    });
  }
  async signin () {
    return Api.signinUser().then((res) => {
      this.authService.changeRole(res.data.role);
    });
  }
  async signout () {
    try {
      await Api.signoutUser();
      await this.authService.signout();
      await this.signoutSisense();
    } catch (error) {
      alert(error.message);
      return Promise.reject(error);
    }
  }
  async signoutSisense () {
    const isauth = await this.sisenseApiService.isauth();
    const isauthJson = await isauth.json();
    if (isauthJson.isAuthenticated) {
      await this.sisenseApiService.logout();
    }
  }
}
