import ApiService, { ApiArguments } from '@/api/api_service';
import {
  TriggerEventFormApiGetTriggerEventFormsRequest,
  GetTriggerEventForms200Response,
  TriggerEventFormApi,
} from '@/api/openapi';

export class TriggerEventFormApiService extends ApiService {
  api = new TriggerEventFormApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true);
  }

  async getTriggerEventForms(
    {
      request,
      options,
      errorHandlers,
    }: ApiArguments<TriggerEventFormApiGetTriggerEventFormsRequest>,
  ) {
    return this.checkResponse<GetTriggerEventForms200Response>(
      this.api.getTriggerEventForms(request, options),
      errorHandlers,
    );
  }
}
