<template>
  <BContentLoader
    v-if="$wait.is('getSequenceInstancesWait')"
    :height="24"
    :width="230"
  >
    <rect
      x="0"
      y="8.5"
      rx="6"
      ry="6"
      width="160"
      height="12"
    />
  </BContentLoader>
  <template v-else-if="activeSequenceInstance">
    <div
      class="block-content sequence-instance-name"
      :class="{ 'stopped': isStoppedSequenceInstance }"
      @click="handleSequenceWidgetOpen"
    >
      <BLayout align-center>
        <!-- NOTE: Componentごと切り替えないと、アイコンの色が切り替わるタイミングがステータス変更と一致しなくなる -->
        <BCustomIcon
          v-if="isStoppedSequenceInstance"
          icon-class="b-sequence"
          type="error"
          size="medium"
        />
        <BCustomIcon
          v-else
          icon-class="b-sequence"
          type="success"
          size="medium"
        />
        <span class="ml-100">{{ activeSequenceInstance.name }}</span>
      </BLayout>
    </div>
    <div
      v-if="isAvailableSequenceFeature"
      class="more-menu"
    >
      <BMoreMenu left>
        <template #default>
          <BTooltip
            right
            :content="$t('mail.status.sending')"
            :disabled="!isSending"
          >
            <BBtn
              v-if="isRegisteredSequenceInstance"
              flat
              fit
              position="center"
              size="small"
              :disabled="isSending"
              @click="handleUpdateSequenceStatus('stop')"
            >
              {{ $t('general.stop.do') }}
            </BBtn>
          </BTooltip>
          <BTooltip
            right
            :content="$t('mail.status.sending')"
            :disabled="!isSending"
          >
            <BBtn
              v-if="isRestartable"
              flat
              fit
              position="center"
              size="small"
              :disabled="isSending"
              @click="handleUpdateSequenceStatus('restart')"
            >
              {{ $t('general.restart.do') }}
            </BBtn>
          </BTooltip>
          <BTooltip
            right
            :content="$t('mail.status.sending')"
            :disabled="!isSending"
          >
            <BBtn
              flat
              fit
              position="center"
              size="small"
              :disabled="isSending"
              @click="handleUpdateSequenceStatus('unregister')"
            >
              {{ $t('general.unregister.do') }}
            </BBtn>
          </BTooltip>
        </template>
      </BMoreMenu>
    </div>
  </template>
  <template v-else>
    <div class="block-content">
      <BLayout align-center>
        <BCustomIcon
          icon-class="b-sequence"
          type="default"
          size="medium"
        />
        <span class="ml-100">{{ $t('sequence.leadDetail.hasNoInstance') }}</span>
      </BLayout>
    </div>
    <div
      v-if="isAvailableSequenceFeature"
      class="more-menu"
    >
      <BMoreMenu
        v-model="isSequenceMenuOpen"
        left
      >
        <template #default>
          <BBtn
            flat
            fit
            position="center"
            size="small"
            @click="handleClickSequenceApply"
          >
            {{ $t('sequence.leadDetail.apply') }}
          </BBtn>
        </template>
      </BMoreMenu>
    </div>
  </template>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { ApiArguments } from '@/api/api_service';
import { SequenceInstance, SequenceStepInstance, SequenceStepInstanceApiGetCurrentSequenceStepInstanceRequest } from '@/api/openapi';
import { SequenceStepInstanceApiService } from '@/api/user/resources/sequence_step_instance';
import { useAvailableFeatureCheck } from '@/composable/available-feature-check';
import { isDirectMailStepExecutedForSequenceStepInstance } from '@/composable/user/sequence/post-put-body';
import { TSequenceAction } from '@/types';

type TProps = {
  activeSequenceInstance: SequenceInstance | undefined;
};
type TEmit = {
  (e: 'update:sequenceStatus', action: TSequenceAction): void;
  (e: 'click:sequenceApply'): void;
  (e: 'click:sequenceWidgetOpen'): void;
};

const props = defineProps<TProps>();
const emit = defineEmits<TEmit>();
const sequenceStepInstanceApi = new SequenceStepInstanceApiService();

const isSequenceMenuOpen = ref(false);

const { isAvailableSequenceFeature } = useAvailableFeatureCheck();

const isRegisteredSequenceInstance = computed(() =>
  props.activeSequenceInstance?.status === 'registered',
);
const isStoppedSequenceInstance = computed(() =>
  props.activeSequenceInstance?.status === 'stopped',
);

const isRestartable = computed(() => {
  return isStoppedSequenceInstance.value && currentSequenceStepInstance.value?.status !== 'failed';
});

const currentSequenceStepInstance = ref<SequenceStepInstance>();

const handleSequenceWidgetOpen = () => {
  if (!isAvailableSequenceFeature.value) return;
  emit('click:sequenceWidgetOpen');
};
const handleUpdateSequenceStatus = (action: TSequenceAction) => {
  if (!props.activeSequenceInstance || !isAvailableSequenceFeature.value) return;
  emit('update:sequenceStatus', action);
};
const handleClickSequenceApply = () => {
  if (!isAvailableSequenceFeature.value) return;
  isSequenceMenuOpen.value = false;
  emit('click:sequenceApply');
};

const isSending = computed(() => {
  return currentSequenceStepInstance.value ? isDirectMailStepExecutedForSequenceStepInstance(currentSequenceStepInstance.value) : false;
});

const fetchCurrentSequenceStepInstance = async (sequenceInstanceId: number) => {
  const apiArguments: ApiArguments<SequenceStepInstanceApiGetCurrentSequenceStepInstanceRequest> = {
    request: { sequenceInstanceId },
    errorHandlers: {
      404: notCurrentSequenceStepInstanceHandler,
    },
  };
  const res = await sequenceStepInstanceApi.getCurrentSequenceStepInstance(apiArguments);
  currentSequenceStepInstance.value = res.data;
};

const notCurrentSequenceStepInstanceHandler = () => {
  currentSequenceStepInstance.value = undefined;
};

watch(() => props.activeSequenceInstance?.id, async (sequenceInstanceId) => {
  if (!sequenceInstanceId) return;
  await fetchCurrentSequenceStepInstance(sequenceInstanceId);
}, { immediate: true });
</script>

<style lang="scss" scoped>
.sequence-instance-name {
  color: $basecolor-accent;
  font-weight: bold;
  cursor: pointer;

  &.stopped {
    color: $basecolor-error;
  }
}
</style>
