import ApiBase from '@/api/base';

export default {
  exportLeadObjects(args) {
    args.responseType = 'blob';
    return ApiBase.get(`/user/salesforce/export_salesforce_lead_objects`, args);
  },
  exportAccountObjects(args) {
    args.responseType = 'blob';
    return ApiBase.get(`/user/salesforce/export_salesforce_account_objects`, args);
  },
  exportContactObjects(args) {
    args.responseType = 'blob';
    return ApiBase.get(`/user/salesforce/export_salesforce_contact_objects`, args);
  },
};
