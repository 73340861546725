<template>
  <div class="sequence-leads-container">
    <InstancesSummary
      :sequence-master-id="sequenceMasterId"
      @click:apply-to-lead-button="handleApplyLeadButtonClick"
    />
    <InstanceList
      class="instance-list"
      :sequence-master-id="sequenceMasterId"
      :page="instanceListPage"
      @update:selected-sequence-instance-ids="handleSelectedSequenceInstanceIdsUpdate"
      @after:move-page="handleAfterMovePage"
    />
  </div>
</template>

<script lang="ts" setup>
import InstanceList from '@/components/organisms/user/sequenceLeads/InstanceList.vue';
import InstancesSummary from '@/components/organisms/user/sequenceLeads/InstancesSummary.vue';

type TEmits = {
  (event: 'after:moveInstanceListPage', value: number): void;
  (event: 'click:applyToLeadButton'): void;
  (event: 'update:selectedSequenceInstanceIds', values: number[]): void;
};

type TProps = {
  sequenceMasterId: number;
  instanceListPage: number;
};

defineProps<TProps>();
const emit = defineEmits<TEmits>();

const handleSelectedSequenceInstanceIdsUpdate = (values: number[]) => {
  emit('update:selectedSequenceInstanceIds', values);
};
const handleAfterMovePage = (value: number) => {
  emit('after:moveInstanceListPage', value);
};
const handleApplyLeadButtonClick = () => {
  emit('click:applyToLeadButton');
};
</script>

<style scoped lang="scss">
.sequence-leads-container {
  height: 100%;
  display: flex;
  flex-direction: column;

  .instance-list {
    flex-grow: 1;
    flex-basis: 0;
  }
}
</style>
