<template>
  <div class="mail-template-form">
    <FormDrawer
      v-if="isVisibleForm"
      v-model:mail-template="internalMailTemplate"
      v-model:is-disabled-reply-form="isDisabledReplyForm"
      :is-saved="isSaved"
      @to-confirm="toConfirm"
      @save-draft="saveDraft"
      @cancel="closeDrawer"
      @delete="deleteMailTemplate"
      @duplicate="$emit('duplicate', mailTemplate)"
      @change="internalMailTemplate = $event"
    />
    <ConfirmDrawer
      v-else-if="isVisibleConfirm"
      :mail-template="internalMailTemplate"
      @cancel="closeDrawer"
      @to-form="toForm"
      @complete="onComplete"
    />
  </div>
</template>

<script>
import { mapWaitingActions } from 'vue-wait';
import { CONTENT_DEFAULT } from '@/components/organisms/user/mail/common/drawer/state/form/form-values';
import ConfirmDrawer from '@/components/organisms/user/mailTemplate/ConfirmDrawer.vue';
import FormDrawer from '@/components/organisms/user/mailTemplate/FormDrawer.vue';
import errorHandler from '@/mixins/error_handler';

export default {
  name: 'MailTemplateForm',
  components: {
    FormDrawer,
    ConfirmDrawer,
  },
  mixins: [errorHandler],
  props: {
    mailTemplate: {
      type: Object,
      required: false,
      default: () => {},
    },
    isCreating: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    'duplicate',
    'mail-template-change',
    'change',
    'close',
    'cancel',
  ],
  data() {
    return {
      internalMailTemplate: this.mailTemplate || this.defaultMailTemplate(),
      isSaved: !this.isCreating,
      isVisibleForm: true,
      isVisibleConfirm: false,
      isDisabledReplyForm: this.mailTemplate?.id && !this.mailTemplate?.replyName && !this.mailTemplate?.replyAddress,
    };
  },
  watch: {
    internalMailTemplate: {
      handler(newVal, oldVal) {
        this.$emit('mail-template-change', newVal);
      },
      deep: true,
    },
  },
  methods: {
    ...mapWaitingActions('user', {
      updateMailTemplateAction: 'updateMailTemplateWait',
      createMailTemplateAction: 'createMailTemplateWait',
      deleteMailTemplateAction: 'deleteMailTemplateWait',
    }),
    defaultMailTemplate() {
      return {
        subject: '',
        content: CONTENT_DEFAULT,
      };
    },
    async saveDraft() {
      if (this.isSaved) {
        await this.updateMailTemplateAction({
          request: {
            mailTemplateId: this.internalMailTemplate.id,
            mailTemplateBody: this.makeRequestBody(),
          },
          errorHandlers: {
            422: this.defaultHandler,
          },
        });
      } else {
        this.internalMailTemplate = await this.createMailTemplateAction({
          request: {
            mailTemplateBody: this.makeRequestBody(),
          },
          errorHandlers: {
            422: this.defaultHandler,
          },
        });
        this.isSaved = true;
      }
      this.$nextTick(() => {
        this.$emit('change');
        this.$toast.show(
          this.$t('general.success.to', {
            action: this.$t('mailTemplate.saveDraft'),
          }),
        );
      });
    },
    async deleteMailTemplate() {
      await this.deleteMailTemplateAction({
        request: {
          mailTemplateId: this.internalMailTemplate.id,
        },
        errorHandlers: {
          422: this.defaultHandler,
        },
      });

      this.$emit('change');
      this.$toast.show(
        this.$t('general.success.to', {
          action: this.$t('mailTemplate.deleteMailTemplate'),
        }),
      );
      this.closeDrawer();
    },
    toForm() {
      this.isVisibleForm = true;
      this.isVisibleConfirm = false;
    },
    async toConfirm() {
      await this.saveDraft();
      this.isVisibleForm = false;
      this.isVisibleConfirm = true;
    },
    async onComplete() {
      await this.updateMailTemplateAction({
        request: {
          mailTemplateId: this.internalMailTemplate.id,
          mailTemplateBody: { status: 'complete' },
        },
        errorHandlers: {
          422: this.defaultHandler,
        },
      });
      this.$emit('change');
      this.$emit('close');
      this.$toast.show(this.$t('mailTemplate.completed'));
    },
    makeRequestBody() {
      return {
        name: this.internalMailTemplate.name,
        subject: this.internalMailTemplate.subject,
        content: this.internalMailTemplate.content,
        replyName: this.internalMailTemplate.replyName,
        replyAddress: this.internalMailTemplate.replyAddress,
        status: 'draft',
      };
    },
    closeDrawer() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
.mail-template-form {
  height: 100%;
}
</style>
