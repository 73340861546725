<template>
  <FilterDetailPopover
    ref="popover"
    v-model:is-include="isInclude"
    :title="title"
    :width="260"
    :is-switch="withIncExc"
    :is-display-only="isDisplayOnly"
    :key-name="keyName"
    :valid="isValid"
    is-custom
    @hide="hide"
    @show="popoverShow"
  >
    <template #reference>
      <BLayout align-center>
        <BListItem>
          <template #header>
            <BLayout justify-space-between>
              <div class="item-title">
                {{ title }}
              </div>
              <div
                v-if="withIncExc"
                class="grouping-text"
                :class="{ 'is-include': incExc === 'inc', 'inc-text': incExc === 'inc', 'exc-text': incExc === 'exc' }"
              >
                {{ groupingText }}
              </div>
            </BLayout>
          </template>
          <div>{{ displayValue }}</div>
        </BListItem>
      </BLayout>
    </template>
    <BCheckbox
      v-if="withNoInput"
      class="my-100"
      :model-value="checkNoInput"
      :label="blankItem.label"
      :value="blankItem.value"
      @change="changeCheckNoInput"
    />
    <div class="number-input-container">
      <BInput
        v-model="inputMinimum"
        v-model:valid="isInputMinimumValid"
        type="number"
        :placeholder="$t('customField.number.minimum', { min })"
        :disabled="checkNoInput"
        :min="min"
        validation
      />
      <span class="number-input-assist-text">{{ $t('general.from') }}</span>
    </div>
    <div class="number-input-container mt-300">
      <BInput
        v-model="inputMaximum"
        v-model:valid="isInputMaximumValid"
        type="number"
        :placeholder="$t('customField.number.maximum')"
        :disabled="checkNoInput"
      />
      <span class="number-input-assist-text">{{ $t('general.to') }}</span>
    </div>
  </FilterDetailPopover>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import type { BooleanString, NumberFilter, NumberFilterEmptyString } from '@/api/openapi';
import FilterDetailPopover from '@/components/organisms/user/leadList/filter/FilterDetailPopover.vue';
import { getIncExc } from '@/composable/user/leadList/lead-filter';
import { getInitialValue } from '@/mixins/filter_service/constants';

type TNumberValue = string | NumberFilterEmptyString;
export type TValue = [TNumberValue, TNumberValue, BooleanString];
export type TFilterParam = { inc: NumberFilter } | { exc: NumberFilter };
export type TPropsCommon = {
  title?: string;
  value?: TValue;
  keyName?: string;
  isDisplayOnly?: boolean;
};

type TProps = TPropsCommon & {
  target?: TFilterParam;
  withIncExc?: boolean;
  withNoInput?: boolean;
  min?: number;
};
type TEmit = {
  hide: [TFilterParam];
};

const props = withDefaults(defineProps<TProps>(), {
  title: '',
  value: () => getInitialValue('typeNumber') as TValue,
  keyName: '',
  isDisplayOnly: false,
  target: () => ({ inc: { or: getInitialValue('typeNumber') as TValue } }),
  withIncExc: true,
  withNoInput: true,
  min: 0,
});
const emit = defineEmits<TEmit>();

const i18n = useI18n();
const blankItem = { label: i18n.t('general.blankInput'), value: true };

const inputMinimum = ref<string | number | null>(null);
const inputMaximum = ref<string | number | null>(null);
const checkNoInput = ref(false);
const isInclude = ref(false);
const isInputMinimumValid = ref(true);
const isInputMaximumValid = ref(true);

const incExc = computed(() => getIncExc(props.target));
const targetDetail = computed(() => {
  return props.target?.[incExc.value]?.or || getInitialValue('typeNumber') as TValue;
});
const grouping = computed(() => isInclude.value ? 'inc' : 'exc' as const);
const isEmpty = computed(() => {
  return !Array.isArray(targetDetail.value)
    || targetDetail.value.length !== 3
    || isEmptyForNumberValue(targetDetail.value[0]) && isEmptyForNumberValue(targetDetail.value[1]) && !getBooleanEvenString(targetDetail.value[2]);
});
const groupingText = computed(() => {
  if (isEmpty.value) return '';
  return i18n.t(`general.filter.${incExc.value}`);
});
const displayValue = computed(() => {
  if (isEmpty.value) return '-';
  if (getBooleanEvenString(targetDetail.value[2])) return i18n.t('general.blankInput');
  const min = isEmptyForNumberValue(targetDetail.value[0]) ? props.min : targetDetail.value[0];
  const max = isEmptyForNumberValue(targetDetail.value[1]) ? i18n.t('general.max') : targetDetail.value[1];
  return i18n.t('general.range', { min, max });
});
const isValid = computed(() => {
  return isInputMinimumValid.value && isInputMaximumValid.value;
});

onMounted(() => {
  isInclude.value = getIncExc(props.target) !== 'exc';
});

const isEmptyForNumberValue = (num: string) => num === 'null';
const getBooleanEvenString = (val: string | boolean) => {
  if (val === 'true') {
    return true;
  } else if (val === 'false') {
    return false;
  } else if (typeof val === 'boolean') {
    return val;
  } else {
    return false;
  }
};
const popoverShow = () => {
  if (Array.isArray(targetDetail.value) && targetDetail.value.length === 3) {
    inputMinimum.value = isEmptyForNumberValue(targetDetail.value[0]) ? null : parseInt(targetDetail.value[0]);
    inputMaximum.value = isEmptyForNumberValue(targetDetail.value[1]) ? null : parseInt(targetDetail.value[1]);
    checkNoInput.value = getBooleanEvenString(targetDetail.value[2]);
  }
};
const hide = () => {
  emit('hide', { [grouping.value]: { or: makeQueryObject() } } as TFilterParam);
};
const makeQueryObject = () => {
  // フィルタの変更検知やクエリストリングのため、すべて文字列型として返す
  const min = isNaN(parseInt(inputMinimum.value?.toString())) ? 'null' : parseInt(inputMinimum.value?.toString()).toString();
  const max = isNaN(parseInt(inputMaximum.value?.toString())) ? 'null' : parseInt(inputMaximum.value?.toString()).toString();
  return [min, max, checkNoInput.value.toString()];
};
const changeCheckNoInput = (e: boolean) => {
  checkNoInput.value = e;
};
</script>

<style lang="scss" scoped>
  .grouping-text {
    color: $basecolor-cold;
    margin-left: 2px;

    &.is-include {
      color: $basecolor-hot;
    }

    &.inc-text {
      min-width: 24px;
    }

    &.exc-text {
      min-width: 48px;
    }
  }

  .number-input-container {
    display: flex;
    .b-input-wrapper {
      flex: 5;
    }
    .number-input-assist-text {
      flex: 1;
      text-align: center;
      line-height: 40px;
      padding-left: 4px;
    }
  }
</style>
