import { AxiosResponse } from 'axios';
import ApiService, { ApiArguments } from '@/api/api_service';
import {
  MfaSettingApi,
  MfaSettingApiCreateMfaSettingRequest,
  GetMfaSetting200Response,
  CreateMfaSetting201Response,
} from '@/api/openapi';

export class MfaSettingService extends ApiService {
  api = new MfaSettingApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true);
  }

  async getMfaSetting(
    apiArguments?: ApiArguments<undefined>,
  ): Promise<AxiosResponse<GetMfaSetting200Response>> {
    return this.checkResponse<GetMfaSetting200Response>(
      this.api.getMfaSetting(apiArguments?.options),
      apiArguments?.errorHandlers,
    );
  }

  async postMfaSetting({
    request,
    options,
    errorHandlers,
  }: ApiArguments<MfaSettingApiCreateMfaSettingRequest>) {
    return this.checkResponse<CreateMfaSetting201Response>(
      this.api.createMfaSetting(request, options),
      errorHandlers,
    );
  }

  async deleteMfaSetting(
    apiArguments?: ApiArguments<undefined>,
  ): Promise<AxiosResponse<boolean>> {
    return this.checkResponse<boolean>(
      this.api.deleteMfaSetting(apiArguments?.options),
      apiArguments?.errorHandlers,
    );
  }
}
