<template>
  <div>
    <span class="mr-50">{{ $t('leadView.TargetLeads') }}</span>
    <span>{{ target }}{{ $t('general.matter') }}</span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('user', ['callTargetsMeta']),
    target() {
      return this.callTargetsMeta.totalCount || 0;
    },
  },
};
</script>
