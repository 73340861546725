export default {
  install(app) {
    app.config.globalProperties.$intervals = [];
    app.config.globalProperties.$setInterval = (func, intervalMilliSec) => {
      if (import.meta.env.VITE_DISABLE_SET_INTERVAL === 'yes') {
        console.log(`[DISABLE_SET_INTERVAL] Check environment vars`);
        return null;
      }
      const id = setInterval(() => {
        if (document.visibilityState === 'visible') {
          console.log(`[RUN_SET_INTERVAL] Interval ${intervalMilliSec / 1000} sec`);
          func();
        } else {
          console.log(`[PAUSE_SET_INTERVAL] Interval ${intervalMilliSec / 1000} sec`);
        }
      }, intervalMilliSec);
      console.log(`[SET_INTERVAL] id: ${id}`);
      app.config.globalProperties.$intervals.push(id);
      return id;
    };
    app.config.globalProperties.$clearInterval = (id) => {
      console.log(`[UNSET_INTERVAL] id: ${id}`);
      clearInterval(id);
      app.config.globalProperties.$intervals = app.config.globalProperties.$intervals.filter(i => i !== id);
    };
    app.config.globalProperties.$clearAllIntervals = () => {
      console.log('[CLEAR_INTERVALS]');
      app.config.globalProperties.$intervals.forEach(clearInterval);
      app.config.globalProperties.$intervals = [];
    };
  },
};
