import Api from '@/api/admin';
import Waiting from '@/store/waiting';
import { types } from '../mutations';

const actions = {
  async getAdminsAction ({ commit }, args) {
    await waiting.check('getAdminsAction', async () => {
      try {
        const res = await Api.getAdmins(args);
        commit(types.SET_ADMINS, Object.values(res.data)[0]);
      } catch (err) {
        commit(types.CLEAR_ADMINS);
        throw err;
      }
    });
  },
  async createAdminAction ({ commit }, args) {
    await waiting.check('createAdminAction', async () => {
      await Api.createAdmin(args);
      try {
        const res = await Api.getAdmins(args);
        commit(types.SET_ADMINS, Object.values(res.data)[0]);
      } catch (err) {
        commit(types.CLEAR_ADMINS);
        throw err;
      }
    });
  },
  async updateAdminAction ({ commit }, args) {
    await waiting.check('updateAdminAction', async () => {
      await Api.updateAdmin(args);
      try {
        const res = await Api.getAdmins(args);
        commit(types.SET_ADMINS, Object.values(res.data)[0]);
      } catch (err) {
        commit(types.CLEAR_ADMINS);
        throw err;
      }
    });
  },
  async deleteAdminAction ({ commit }, args) {
    await waiting.check('deleteAdminAction', async () => {
      await Api.deleteAdmin(args);
      try {
        const res = await Api.getAdmins(args);
        commit(types.SET_ADMINS, Object.values(res.data)[0]);
      } catch (err) {
        commit(types.CLEAR_ADMINS);
        throw err;
      }
    });
  },
};

const waiting = new Waiting(actions);

export default actions;
