<template>
  <div class="b-textarea-box">
    <BTextarea
      v-bind="attributes"
      v-model="internalValue"
      :data-test="dataTest"
    />
    <BLayout
      class="btn-set"
      align-center
    >
      <BBtn
        v-if="valueChanged"
        class="mr-50"
        size="small"
        fab
        flat
        :loading="loading"
        @click="handleClickCheck"
      >
        <BIcon
          class="check-icon"
          size="small"
        >
          check
        </BIcon>
      </BBtn>
      <BBtn
        size="small"
        fab
        flat
        :disabled="loading"
        @click="handleClickClose"
      >
        <BIcon
          class="close-icon"
          size="small"
        >
          close
        </BIcon>
      </BBtn>
    </BLayout>
  </div>
</template>

<script>
// TODO: settleType実装してないので必要でしたらBInputBoxと同じように実装してください
export default {
  props: {
    modelValue: [String, Number],
    placeholder: String,
    loading: Boolean,
    dataTest: String,
    autosize: [Boolean, Object],
  },
  emits: [
    'update:modelValue',
    'check',
    'close',
  ],
  data () {
    return {
      valid: false,
      valueChanged: false,
    };
  },
  computed: {
    attributes () {
      return {
        autosize: this.autosize == null ? { minRows: 2, maxRows: 4 } : this.autosize, // `false`も設定値として有効なので、`== null`で判定する。
        resize: 'none',
        placeholder: this.placeholder,
        disabled: this.loading,
      };
    },
    internalValue: {
      get () {
        return this.modelValue;
      },
      set (newVal) {
        if (this.modelValue !== newVal) {
          this.valueChanged = true;
          this.$emit('update:modelValue', newVal);
        }
      },
    },
  },
  methods: {
    handleClickCheck () {
      this.$emit('check', this.internalValue);
    },
    handleClickClose () {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
  .btn-set {
    margin-top: $basespace-100;
    justify-content: flex-end;
  }

  .check-icon {
    color: $basecolor-primary;
  }

  .close-icon {
    color: $textcolor-light;
  }
</style>
