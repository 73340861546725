<template>
  <span
    class="color-preview"
    :style="style"
    :class="size"
    @click="$emit('click')"
  />
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { TSize } from '../../types';

type TProps = {
  size?: TSize;
  colorCode: string;
};

const props = withDefaults(defineProps<TProps>(), {
  size: 'medium',
});
defineEmits(['click']);

const style = computed(() => ({
  'background-color': props.colorCode,
}));
</script>

<style lang="scss" scoped>
.color-preview {
  border-radius: 50%;
  display: inline-block;

  &.micro {
    height: 8px;
    width: 8px;
  }
  &.small{
    height: 12px;
    width: 12px;
  }
  &.medium {
    height: 22px;
    width: 22px;
  }
  &.large {
    height: 36px;
    width: 36px;
  }
}
</style>
