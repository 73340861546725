<template>
  <div class="table-area">
    <div v-if="$wait.is('getClientsWait')">
      <BPlaceholder
        v-loading="true"
        height="60vh"
        element-loading-background="transparent"
      />
    </div>
    <div v-else>
      <ElTable
        :data="displayedClients"
        height="55vh"
        :default-sort="{prop: 'id', order: 'ascending'}"
        @current-change="moveToClientPage"
      >
        <ElTableColumn
          v-for="(header, index) in headers.slice(0,4)"
          :key="index"
          :prop="header.value"
          :label="header.text"
          sortable
          :width="columnsWidth[index]"
        />
        <ElTableColumn
          :prop="headers[4].value"
          :label="headers[4].text"
          width="90"
          header-align="center"
        >
          <template #default="clientProps">
            <BLayout justify-center>
              <BBtn
                fab
                text
                @click.stop="() => moveToLoginPage(clientProps.row)"
              >
                <BIcon>open_in_new</BIcon>
              </BBtn>
            </BLayout>
          </template>
        </ElTableColumn>
      </ElTable>
      <BLayout
        v-if="isExistFilteredClients"
        class="pt-300"
      >
        <BFlex
          :span="4"
          align-self-center
        >
          <BLayout justify-center>
            <span>{{ pageStart }} - {{ pageEnd }} / {{ totalCount }}</span>
          </BLayout>
        </BFlex>
        <BFlex :span="16">
          <BLayout justify-center>
            <BPagination
              v-model="paginationValue"
              :length="allPageCount"
              :total-visible="9"
            />
          </BLayout>
        </BFlex>
        <BFlex :span="4">
          <BSelect
            v-model="pageSize"
            class="mx-600"
            :items="pageSizeChoice"
          />
        </BFlex>
      </BLayout>
    </div>
  </div>
</template>

<script>
import lodash from 'lodash';
import { mapWaitingActions } from 'vue-wait';
import { mapGetters } from 'vuex';
import { formatShorterDateTime } from '@/utils/date-time';

export default {
  name: 'UsersList',
  props: {
    searchWord: String,
    currentPage: Number,
  },
  emits: [
    'change-current-page',
  ],
  data () {
    return {
      headers: [],
      pageSize: 20,
      pageSizeChoice: [
        { text: '20', value: 20 },
        { text: '35', value: 35 },
        { text: '50', value: 50 },
      ],
      columnsWidth: [60, 300, null, null],
    };
  },
  computed: {
    ...mapGetters('admin', [
      'clients',
    ]),
    filteredClients () {
      return this.clients.filter(client => {
        const word = this.searchWord.toLowerCase();
        return client.name.toLowerCase().includes(word) || client.slug.includes(word);
      });
    },
    displayedClients () {
      return this.filteredClients.slice(this.pageStart - 1, this.pageEnd).map(client => this.adjustDate(client));
    },
    pageStart () {
      return this.pageSize * (this.currentPage - 1) + 1;
    },
    pageEnd () {
      const defaultEnd = this.pageSize * this.currentPage;
      return Math.min(defaultEnd, this.totalCount);
    },
    totalCount () {
      return this.filteredClients.length;
    },
    allPageCount () {
      return Math.ceil(this.totalCount / this.pageSize);
    },
    isExistFilteredClients () {
      return this.totalCount !== 0;
    },
    paginationValue: {
      get () {
        return this.currentPage;
      },
      set (value) {
        this.$emit('change-current-page', value);
      },
    },
  },
  created () {
    this.headers = Object.entries(this.$tm('clientManagement.headers')).map(([k, v]) => { return { text: this.$rt(v), value: k }; });
    this.setBreadcrumbsAction([{ text: this.$t('clientManagement.title'), path: '' }]);
    this.getClientsAction();
  },
  methods: {
    ...mapWaitingActions('breadcrumbs', {
      setBreadcrumbsAction: 'setBreadcrumbsWait',
    }),
    ...mapWaitingActions('admin', {
      getClientsAction: 'getClientsWait',
    }),
    moveToClientPage (data) {
      this.$router.push({ name: 'AdminClient', params: { id: data.id } });
    },
    moveToLoginPage (data) {
      const splittedDomain = location.hostname.split('.');
      const loginPageUrl = `${location.protocol}//${data.slug}.${splittedDomain.slice(1).join('.')}/app/login`;
      window.open(loginPageUrl, '_blank');
    },
    adjustDate (object) {
      const adjustedObject = lodash.cloneDeep(object);
      adjustedObject.updatedAt = formatShorterDateTime(adjustedObject.updatedAt);
      return adjustedObject;
    },
  },
};
</script>

<style lang="scss" scoped>
  .table-area {
    height: 60vh;
  }
</style>
