<template>
  <BLayout
    class="lead-list-row"
    align-center
    :style="getStyles()"
  >
    <!-- NOTE: @click.stopは親要素以上のclickを発火させないためのもの。b-checkboxでclickが購読できないためここで対処 -->
    <div
      class="lead-list-item checkbox"
      :style="getStyles()"
      @click.stop
    >
      <BCheckbox
        v-if="modelValue"
        class="mt-0"
        :model-value="modelValue.checked"
        :value="modelValue.value"
        @change="handleCheckboxChange"
      />
    </div>
    <div
      v-for="column in leadListSelectedColumns"
      :key="column"
      class="lead-list-item"
      :class="getClassNameByKey(column)"
      :style="getStyles(column)"
    >
      <BPopover
        v-if="column === 'lastOpportunity'"
        placement="top"
        width="200"
        trigger="hover"
      >
        <template #reference>
          <BCustomIcon
            icon-class="b-fire"
            :type="getIconType(callTarget.lastOpportunity)"
          />
        </template>

        <div v-if="callTarget.lastOpportunity">
          <BListItem class="mb-200">
            <template #header>
              <span>{{ $t('opportunity.scheduled') }}</span><span>{{ getDateAndTimeWithFallBack(callTarget.lastOpportunity.startedAt) }}</span>
            </template>
          </BListItem>
          <BListItem class="mb-200">
            <template #header>
              <span>{{ $t('opportunity.executedAt') }}</span><span>{{ getDateAndTimeWithFallBack(callTarget.lastOpportunity.executedAt) }}</span>
            </template>
          </BListItem>
          <BListItem class="mb-200">
            <template #header>
              <span>{{ $t('opportunity.amount') }}</span><span>{{ getTextWithFallBack(callTarget.lastOpportunity.amount) }}</span>
            </template>
          </BListItem>
          <BListItem>
            <template #header>
              <span>{{ $t('opportunity.memo') }}</span>
            </template>
            <div class="truncate-text">
              {{ getTextWithFallBack(callTarget.lastOpportunity.memo) }}
            </div>
          </BListItem>
        </div>
        <div v-else>
          <span>{{ $t('opportunity.blank') }}</span>
        </div>
      </BPopover>
      <BLayout
        v-else-if="column === 'lastActivityTypeText'"
        align-center
        wrap
      >
        <span>{{ getTextWithFallBack(callTarget.lastActivityTypeText) }}</span>
      </BLayout>
      <BLayout v-else-if="column === 'lastActivityAt'">
        <div class="truncate-text">
          {{ getDateAndTimeWithFallBack(callTarget.lastActivityAt) }}
        </div>
      </BLayout>
      <BLayout
        v-else-if="column === 'lastActivityComment'"
        align-center
        wrap
      >
        <div class="truncate-text">
          {{ getTextWithFallBack(callTarget.lastActivityComment) }}
        </div>
      </BLayout>
      <BLayout
        v-else-if="column === 'lastCalledAt'"
        class="last-called-at"
        align-center
        wrap
      >
        <template v-if="callTarget.lastCalledAt == null">
          -
        </template>
        <template v-else>
          <div class="last-called-item">
            {{ getDateAndTimeWithFallBack(callTarget.lastCalledAt) }}
          </div>
          <div class="last-called-item">
            <span class="result-type">{{ getTextWithFallBack(callTarget.callResult) }}</span>
            <BPopover
              placement="top"
              width="200"
              trigger="hover"
            >
              <template #reference>
                <BIcon
                  :type="getIconType(callTarget.lastCalledComment)"
                >
                  mode_comment
                </BIcon>
              </template>
              <div class="break-word-pre-wrap">
                {{ callTarget.lastCalledComment || $t('callResult.comment.blank') }}
              </div>
            </BPopover>
          </div>
        </template>
      </BLayout>
      <BBtn
        v-else-if="column === 'operatorName'"
        fab
        flat
        @click.stop="openSetOperatorModal(callTarget.callTargetId, callTarget.operatorId)"
      >
        <BUserTooltip
          v-if="callTarget.operatorName"
          :image-url="callTarget.operatorAvatarUrl"
          :name="callTarget.operatorName"
          :user-id="callTarget.operatorId"
          :size="40"
        />
        <BEmptyBox
          v-else
          class="empty-box"
        >
          <BIcon size="small">
            add
          </BIcon>
        </BEmptyBox>
      </BBtn>
      <BSfStatusIcon
        v-else-if="column === 'connectStatus' && sfConnectedStatus"
        :connect-status="sfConnectedStatus"
        @open-sf-modal="openSfModal(callTarget.sfRelatedLinkInfo)"
      />
      <BNextActionLabel
        v-for="nextAction in callTarget.nextActions"
        v-else-if="column === 'nextActions' && callTarget.nextActions.length > 0"
        :key="`nextAction-${nextAction.id}`"
        class="next-action"
        :next-action="nextAction"
      />
      <div
        v-else-if="column === 'nextActions' && callTarget.nextActions.length === 0"
        class="next-action"
      >
        -
      </div>
      <div
        v-else-if="column === 'leadSources'"
        class="truncate-text"
      >
        {{ leadSourcesText }}
      </div>
      <div
        v-else-if="column === 'lastLeadStageName'"
        class="truncate-text"
      >
        <BLeadStageColorPreview
          :value="callTarget.lastLeadStageColorName"
          size="small"
          class="mr-50"
        />
        {{ getTextWithFallBack(getColumnValue(column)) }}
      </div>
      <div
        v-else-if="column === 'lastLeadStageStayDays'"
        class="truncate-text"
      >
        {{ callTarget.lastLeadStageStayDays }}
      </div>
      <div
        v-else-if="column === 'freeTags'"
        class="truncate-text"
      >
        {{ freeTagsText }}
      </div>
      <div
        v-else-if="column === 'callTargetCreatedAt'"
        class="truncate-text"
      >
        {{ callTargetCreatedAtText }}
      </div>
      <div
        v-else-if="column === 'companyUrl'"
        class="company-url truncate-text"
      >
        <span>{{ getTextWithFallBack(callTarget[column]) }}</span>
        <BTooltip
          top
          :content="$t('callTarget.search.url')"
        >
          <BIcon
            class="url-icon"
            size="mini"
            @click.stop="() => openUrl(callTarget[column])"
          >
            open_in_new
          </BIcon>
        </BTooltip>
      </div>
      <div
        v-else-if="column === 'isEmailDuplicated' && callTarget.isEmailDuplicated"
        class="truncate-text"
      >
        <DuplicateStateButton
          duplicate-key="duplicateEmail"
          :duplicate-value="callTarget.email"
        >
          {{ $t('general.isDuplicated') }}
        </DuplicateStateButton>
      </div>
      <div
        v-else-if="column === 'isEmailDuplicated' && !callTarget.isEmailDuplicated"
        class="truncate-text"
      >
        -
      </div>
      <div
        v-else
        class="truncate-text"
      >
        {{ getTextWithFallBack(getColumnValue(column)) }}
      </div>
    </div>
    <div
      class="lead-list-item end-column"
      :style="getStyles()"
    />
  </BLayout>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import BSfStatusIcon from '@/plugins/biscuet-materials/components/molecules/BSfStatusIcon.vue';
import { altText } from '@/utils/alt';
import camelToKebab from '@/utils/camelToKebab';
import { formatShorterDateTime } from '@/utils/date-time';

export default {
  components: {
    BSfStatusIcon,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    callTarget: {
      type: Object,
      required: true,
      default () {
        return {
          callTargetId: 0,
          number: 0,
          companyName: '',
          targetName: '',
          companyTel1: '',
          callResult: '',
          lastCalledAt: '',
          lastCalledComment: '',
          noteValue: '',
          nextActions: [],
          freeTags: [],
          customFieldValues: [],
          leadSources: [],
          lastActivityTypeText: '',
          lastActivityAt: '',
          lastActivityComment: '',
          callTargetCreatedAtText: '',
          sfRelatedLinkInfo: {},
          lastLeadStageStayDays: 0,
        };
      },
    },
  },
  emits: [
    'update:modelValue',
    'open-sf-modal',
    'open-set-operator-modal',
  ],
  computed: {
    ...mapGetters('user', [
      'callList',
      'customFields',
    ]),
    ...mapGetters('userUi', [
      'leadListSelectedColumns',
      'leadListColumnWidths',
    ]),
    sfConnectedStatus(){
      return this.callTarget.sfRelatedLinkInfo?.sfRelatedLinks[0].status;
    },
    leadSourcesText () {
      return this.getNamesFromArray(this.callTarget.leadSources);
    },
    freeTagsText () {
      return this.getNamesFromArray(this.callTarget.freeTags, { name: 'text' });
    },
    callTargetCreatedAtText () {
      return formatShorterDateTime(this.callTarget.createdAt);
    },
  },
  methods: {
    getNextActionIconName (nextAction) {
      switch (nextAction) {
        case 'sending_of_documents':
          return 'email';
        case 'guiding_to_seminar':
          return 'navigation';
        case 'make_an_appointment':
          return 'assignment_ind';
        case 'call_back':
          return 'call';
      }
    },
    openSfModal(sfRelatedLinkInfo) {
      this.$emit('open-sf-modal', sfRelatedLinkInfo);
    },
    openSetOperatorModal (callTargetId, operatorId) {
      this.$emit('open-set-operator-modal', callTargetId, operatorId);
    },
    getIconType (value) {
      return value ? 'primary' : 'gray';
    },
    getColumnValue (key) {
      const isCustomField = key.indexOf('custom_field_') !== -1;
      if (isCustomField) {
        return this.getCustomFieldValue(key);
      } else {
        return this.callTarget[key];
      }
    },
    getCustomFieldValue (fieldKey) {
      if (this.customFields == null) return null;

      const filteredCustomFieldValues = this.callTarget.customFieldValues.filter(value => value.fieldKey === fieldKey);
      if (filteredCustomFieldValues.length === 0) return null;

      let value = null;
      const customFieldValue = filteredCustomFieldValues[0];
      if (customFieldValue.dataType === 'multi_option') {
        value = filteredCustomFieldValues.map(v => v.fieldValue).join(' / ');
      } else if (customFieldValue.dataType === 'datetime') {
        value = formatShorterDateTime(customFieldValue.fieldValue);
      } else if (customFieldValue.fieldValue !== '') {
        value = customFieldValue.fieldValue;
      }
      return value;
    },
    getClassNameByKey (key) {
      const isCustomField = key.indexOf('custom_field_') !== -1;
      if (isCustomField) {
        return 'selected-column';
      } else {
        return camelToKebab(key);
      }
    },
    getNamesFromArray (array, { name = 'name' } = {}) {
      let text = '-';
      if (array.length > 0) {
        const names = array.map(item => item[name]);
        text = names.join(' / ');
      }
      return text;
    },
    getStyles (key) {
      // ネクストアクションの個数で行の高さが変わる
      const width = key == null ? '' : `${this.leadListColumnWidths[key]}px`;
      let styles = `width: ${width}; min-width: ${width}; max-width: ${width};`;
      // .lead-list-rowの高さと同じにすること
      let height = 60;
      if (this.callTarget.nextActions.length > 1) {
        const heightPerNextAction = 34;
        let nextActionHeight = 0;
        nextActionHeight = heightPerNextAction * (this.callTarget.nextActions.length - 1);
        height += nextActionHeight;
      }
      styles += `height: ${height}px !important; min-height: ${height}px !important; max-height: ${height}px !important;`;
      return styles;
    },
    openUrl (url) {
      window.open(url, '_blank');
    },
    getTextWithFallBack (str) {
      return altText(str);
    },
    getDateAndTimeWithFallBack (date) {
      return altText(formatShorterDateTime(date));
    },
    handleCheckboxChange() {
      this.$emit('update:modelValue', this.modelValue);
    },
  },
};
</script>

<style lang="scss" scoped>
  // MEMO:getStyles内も変更すること
  $lead-list-row-height: 60px;

  .is-phone-exists,
  .is-opportunity-exists,
  .is-comment-exists {
    color: $basecolor-primary;
  }

  .checkbox {
    @include m-fixed-width(40px);
    z-index: 0;
  }

  .lead-list-row {
    transition: $transition-base;
    cursor: pointer;
    @include m-fixed-height($lead-list-row-height);

    .lead-list-item {
      @include m-fixed-height($lead-list-row-height);
      display: flex;
      align-items: center;
      border-bottom: 1px solid $bdcolor-light;
      padding: 0 $basespace-200;
    }

    .end-column {
      width: 100%;
    }

    &:hover {
      background-color: $bgcolor-base;

      .lead-list-item {
        background-color: $bgcolor-base;
      }
    }
  }

  .next-actions {
    @include m-fixed-width(160px);
    padding-right: $basespace-200;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start !important;  // .lead-list-items のスタイル打ち消し
    .next-action {
      margin-left: 0 !important; // .lead-list-item のスタイル打ち消し
      &:nth-child(n + 2) {
        margin-top: 10px;
      }
    }
  }

  .company-url {
    position: relative;

    .url-icon {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: $bgcolor-base;
      padding-left: $basespace-100;
      visibility: hidden;
    }

    &:hover {
      .url-icon {
        visibility: visible;
      }
    }
  }

  .selected-column {
    @include m-fixed-width(160px);
  }

  .operator-name {
    .empty-box {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      padding: 11px;
    }
  }

  .no-content {
    color: $textcolor-light;
  }

  .last-called-at {
    @include m-fixed-width(100%);

    .last-called-item {
      @include m-fixed-width(100%);
      display: flex;
      align-items: center;

      .result-type {
        display: inline-block;
        max-width: 85%;
        margin-right: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
</style>
