<template>
  <div>
    <BDrawerMenu
      v-model="drawer"
      :title="$t('callResult.title')"
      :disabled-close="disabledClose"
      @cancel="handleCancel"
    >
      <InfoForm
        v-model:started-at="callResult.startedAt"
        v-model:ended-at="callResult.endedAt"
        v-model:miitel-result-url="callResult.miitelResultUrl"
        v-model:time-error="timeError"
        :zoom-phone-call-id="callResult.zoomPhoneCallId"
        class="mb-300"
      />
      <ResultForm
        v-model:connected="callResult.connected"
        v-model:connected-to="callResult.connectedTo"
        v-model:result="callResult.result"
      />
      <BDivider
        v-if="callResultFormItemCount"
        class="my-500"
      />
      <CustomActionForm
        v-model="callResult.customActionOptionValues"
        @after-fetch="callResultFormItemCount = $event"
      />
      <template v-if="!isEdit">
        <BDivider class="my-500" />
        <NextActionForm
          v-model:next-actions="callResult.nextActions"
          v-model:is-valid="isNextActionsValid"
          :call-target-id="callTargetId"
        />
      </template>
      <template #footer>
        <div class="footer">
          <BBtn
            text
            type="success"
            @click="handleQuickTextButtonClick"
          >
            <BIcon
              type="success"
              outlined
            >
              article
            </BIcon>
            <span class="ml-50 fw-bold">{{ $t('quickText.apply') }}</span>
          </BBtn>
          <BInput
            ref="commentTextarea"
            v-model="callResult.comment"
            type="textarea"
            class="mb-200"
            :placeholder="$t('callResult.comment.text')"
            :rows="11"
            :autosize="false"
            resize="none"
            copyable
          />
          <BLayout
            align-center
            justify-center
            class="mt-400"
          >
            <BBtn
              class="mr-300"
              text
              :disabled="waiting"
              @click="handleCancel"
            >
              {{ $t('general.cancel.text') }}
            </BBtn>
            <BBtn
              class="ml-300"
              type="primary"
              :loading="waiting"
              :disabled="saveButtonDisabled"
              data-test="saveCallResultBtn"
              @click="clickSaveButton"
            >
              {{ $t('general.save.text') }}
            </BBtn>
          </BLayout>
        </div>
      </template>
    </BDrawerMenu>
    <QuickTextSelectModal
      modal-name="quickTextSelectInCallResultForm"
      :call-target-id="callTargetId"
      @select="handleQuickTextSelect"
    />
  </div>
</template>

<script lang="ts">
import lodash from 'lodash';
import { computed, defineComponent, ref } from 'vue';
import { mapWaitingActions } from 'vue-wait';
import { mapGetters } from 'vuex';
import CustomActionForm from '@/components/organisms/user/call_target/menu/call_result/CustomActionForm.vue';
import InfoForm from '@/components/organisms/user/call_target/menu/call_result/InfoForm.vue';
import NextActionForm from '@/components/organisms/user/call_target/menu/call_result/NextActionForm.vue';
import ResultForm from '@/components/organisms/user/call_target/menu/call_result/ResultForm.vue';
import QuickTextSelectModal from '@/components/organisms/user/lead/modal/QuickTextSelectModal.vue';
import { useInsertText } from '@/composable/input';
import { useConfirmWithOptions } from '@/composable/message-dialog';
import errorHandler from '@/mixins/error_handler';
import BInput from '@/plugins/biscuet-materials/components/atoms/BInput.vue';
import { newDate } from '@/utils/date-time';

export default defineComponent({
  components: {
    ResultForm,
    InfoForm,
    NextActionForm,
    CustomActionForm,
    QuickTextSelectModal,
  },
  mixins: [errorHandler],
  props: {
    callTargetId: Number,
  },
  emits: ['after:update', 'after:create'],
  setup(_props) {
    const initCallResult = () => {
      return {
        id: null,
        callTargetId: null,
        number: null,
        connected: true,
        connectedTo: '',
        result: '',
        startedAt: null,
        endedAt: null,
        comment: '',
        nextActions: [],
        customActionOptionValues: [],
        miitelResultUrl: '',
        zoomPhoneCallId: null,
      };
    };
    const callResult = ref(initCallResult());
    const comment = computed({
      get: () => callResult.value.comment,
      set: (value) => {
        callResult.value.comment = value;
      },
    });
    const commentTextarea = ref<InstanceType<typeof BInput>>(null);
    const { insertText: insertTextToComment } = useInsertText(commentTextarea, comment);
    const { confirmWithOptions } = useConfirmWithOptions();

    return {
      insertTextToComment,
      callResult,
      commentTextarea,
      initCallResult,
      confirmWithOptions,
    };
  },
  data() {
    return { ...this.initialData(), callResultFormItemCount: 0 };
  },
  computed: {
    ...mapGetters('user', [
      'callTarget',
    ]),
    ...mapGetters('userUi', [
      'targetCallResultFormVisible',
      'targetCallResultFormNextActionId',
      'targetCallResultFormPhoneNumberKey',
      'selectedCallResult',
      'isCtiOutboundCalling',
    ]),
    ...mapGetters('miitel', {
      callInfo: 'callInfo',
      miitelIsInbound: 'isInbound',
    }),
    ...mapGetters('zoomPhone', {
      zoomPhoneCallInfo: 'callInfo',
    }),
    drawer: {
      get() {
        return this.targetCallResultFormVisible;
      },
      set(newVal) {
        if (this.targetCallResultFormVisible !== newVal)
          this.setTargetCallResultFormVisibleAction(newVal);
      },
    },
    waiting() {
      return this.$wait.is(['createCallResultWait', 'updateCallResultWait']);
    },
    disabledClose() {
      return this.isCtiOutboundCalling;
    },
    saveButtonDisabled() {
      return (
        !this.callResult.startedAt
          || !this.callResult.endedAt
          || this.callResult.connectedTo === ''
          || this.callResult.result === ''
          || this.timeError
          || this.isCtiOutboundCalling
          || !this.isNextActionsValid
      );
    },
    timeError() {
      if (
        this.callResult?.startedAt == null
          || this.callResult?.endedAt == null
      ) {
        return false;
      }

      return this.isTimeTwisted({
        startedAt: this.callResult?.startedAt,
        endedAt: this.callResult?.endedAt,
      });
    },
  },
  watch: {
    targetCallResultFormVisible(visible) {
      if (!visible) return;
      this.callResult.startedAt ??= newDate(); // CTIから得た内容を上書きしないように、nullishの場合のみ入れる
      this.callResult.endedAt ??= newDate(); // CTIから得た内容を上書きしないように、nullishの場合のみ入れる

      // selectedCallResultがある場合は編集中と判定
      if (Object.keys(this.selectedCallResult).length > 0) {
        this.isEdit = true;
        this.callResult = lodash.cloneDeep(this.selectedCallResult);
      }
    },
    callInfo: {
      deep: true,
      handler(newVal) {
        if (this.miitelIsInbound) {
          return;
        }
        this.callResult.startedAt = newVal.startedAt;
        this.callResult.endedAt = newVal.endedAt;
        this.callResult.miitelResultUrl = newVal.url;
        this.callResult.connected = newVal.connected;
      },
    },
    zoomPhoneCallInfo: {
      deep: true,
      handler(newVal) {
        if (newVal.direction !== 'outbound') {
          return;
        }
        this.callResult.startedAt = newVal.startedAt;
        this.callResult.endedAt = newVal.endedAt;
        this.callResult.zoomPhoneCallId = newVal.id;
        this.callResult.connected = !!newVal.connectedAt;
      },
    },
  },
  methods: {
    ...mapWaitingActions('user', {
      createCallResultAction: 'createCallResultWait',
      updateCallResultAction: 'updateCallResultWait',
      getCallTargetsBackgroundAction: {
        action: 'getCallTargetsAction',
        loader: 'getCallTargetsBackgroundWait',
      },
      getNextActionsBackgroundAction: {
        action: 'getNextActionsAction',
        loader: 'getNextActionsBackgroundWait',
      },
    }),
    ...mapWaitingActions('userUi', {
      setTargetCallResultFormVisibleAction:
          'setTargetCallResultFormVisibleWait',
      setSelectedCallResultAction: 'setSelectedCallResultWait',
      setTargetHeaderMoveButtonDisabledAction:
          'setTargetHeaderMoveButtonDisabledWait',
    }),
    clickSaveButton() {
      this.saveCallResult();
    },
    saveCallResult() {
      if (this.saveButtonDisabled) return;
      if (this.isEdit) {
        this.updateCallResult();
      } else {
        this.createCallResult();
      }
    },
    targetHeaderMoveButtonDisable() {
      this.setTargetHeaderMoveButtonDisabledAction(true);
    },
    async confirmToCompleteNextAction() { 
      if (this.targetCallResultFormNextActionId == null) return false;
      // NOTE: 現状シーケンス用のメッセージを表示している。今後の要件によって適宜修正ください
      const completing = await this.confirmWithOptions({
        title: this.$t('sequence.step.callResult.confirmToComplete.title'),
        message: this.$t('sequence.step.callResult.confirmToComplete.message'),
        optionalButtonOptions: {
          'notComplete': {
            text: this.$t('sequence.step.callResult.confirmToComplete.notComplete'),
            value: 'notComplete',
            className: 'swal-button--optional',
          },
          'complete': {
            text: this.$t('sequence.step.callResult.confirmToComplete.complete'),
            value: 'complete',
          },
        },
      });
      // NOTE: 戻り値がnull=キャンセルの場合はnullを返しておく
      if (completing == null) return null;
      return completing === 'complete';
    },
    async createCallResult() {
      try {
        const nextActionCompleting = await this.confirmToCompleteNextAction();
        // NOTE: キャンセルの場合は何もしない
        if (nextActionCompleting == null) return;

        await this.createCallResultAction({
          callTargetId: this.callTargetId,
          body: {
            callResult: {
              ...this.callResult,
              nextActionId: this.targetCallResultFormNextActionId,
              phoneNumberKey: this.targetCallResultFormPhoneNumberKey,
              nextActionCompleting,
            },
            nextActions: this.callResult.nextActions,
            customActionOptionValues: this.callResult.customActionOptionValues,
          },
          errorHandlers: {
            422: this.defaultHandler,
            404: this.defaultHandler,
          },
        });

        this.$emit('after:create', nextActionCompleting);
        this.closeDrawer();
      } catch (e) {
        this.$bitterAlert.show({
          title: this.$t('callResult.drawer.errorModalTitle'),
          text: e.response?.data?.errorMessage,
          buttonsCancel: false,
        });
      }
    },
    async updateCallResult() {
      await this.updateCallResultAction({
        callTargetId: this.callTargetId,
        callResultId: this.callResult.id,
        body: {
          callResult: this.callResult,
          nextActions: this.callResult.nextActions,
          customActionOptionValues: this.callResult.customActionOptionValues,
        },
        errorHandlers: {
          422: this.defaultHandler,
          404: this.defaultHandler,
        },
      });
      this.$emit('after:update');
      this.closeDrawer();
    },
    async handleCancel() {
      const check = await this.$bitterAlert.show({
        title: this.$t('general.confirm.text'),
        text: this.$t('general.alert.of', {
          target: this.$t('general.unsavedValue'),
          action: this.$t('general.clear'),
        }),
      });
      if (!check) {
        return;
      }
      this.closeDrawer();
    },
    closeDrawer() {
      this.drawer = false;
      this.setSelectedCallResultAction({});
      Object.assign(this.$data, this.initialData());
      this.callResult = structuredClone(this.initCallResult());
      this.setTargetHeaderMoveButtonDisabledAction(false);
    },
    initialData() {
      return {
        isEdit: false,
        isNextActionsValid: true,
      };
    },
    toDateInstance(date) {
      if (typeof date === 'string') {
        date = new Date(date);
      } else if (date instanceof Date) {
        // nothing to do
      } else {
        console.error('invalid type', date);
      }
      return date;
    },
    getTimeWithoutMilliSec(unixTime) {
      return Math.floor(unixTime / 1000);
    },
    isTimeTwisted({ startedAt, endedAt }) {
      startedAt = this.toDateInstance(startedAt);
      endedAt = this.toDateInstance(endedAt);
      return (
        this.getTimeWithoutMilliSec(startedAt.getTime())
          > this.getTimeWithoutMilliSec(endedAt.getTime())
      );
    },
    handleQuickTextButtonClick() {
      this.$modal.show('quickTextSelectInCallResultForm');
    },
    handleQuickTextSelect(content: string) {
      this.insertTextToComment(content);
    },
  },
});
</script>

<style lang="scss" scoped>
.disabledLabel{
  color: $textcolor-light;
}
.footer {
  background-color: $bgcolor-base;
  padding: $basespace-200 $basespace-400;
  border-top: 1px solid $bdcolor-light;
}
.with {
  left: -600px !important;
}
</style>
