import type { InjectionKey } from 'vue';
import VueWait from 'vue-wait';
import { TModal } from './plugins/BModal';
import { TToast } from './plugins/BToast';

// NOTE: Appレベルのprovideをする場合、ここにキーを定義する
// example) app.provide(KEY_OF_HOGE_HOGE, hogeHoge)
// @see https://vuejs.org/guide/typescript/composition-api.html#typing-provide-inject

const KEY_OF_TOAST = Symbol() as InjectionKey<TToast>;
const KEY_OF_MODAL = Symbol() as InjectionKey<TModal>;
const KEY_OF_WAIT = Symbol() as InjectionKey<VueWait>;
  
export {
  KEY_OF_TOAST,
  KEY_OF_MODAL,
  KEY_OF_WAIT,
};
