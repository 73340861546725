import ApiBase from '@/api/base';

export default {
  getBulkMails(args = {}) {
    const { currentPage } = args;
    args.params = { currentPage };
    return ApiBase.get(`/user/bulk_mails`, args);
  },
  getBulkMail(args) {
    const { bulkMailId } = args;
    return ApiBase.get(`/user/bulk_mails/${bulkMailId}`, args);
  },
  getBulkMailCampaignNames(args) {
    return ApiBase.get(`/user/sent_bulk_mail_names`, args);
  },
  createBulkMail(args) {
    return ApiBase.post(`/user/bulk_mails`, args);
  },
  updateBulkMail(args) {
    const { bulkMailId } = args;
    return ApiBase.put(`/user/bulk_mails/${bulkMailId}`, args);
  },
  deleteBulkMail(args) {
    const { bulkMailId } = args;
    return ApiBase.delete(`/user/bulk_mails/${bulkMailId}`, args);
  },
};
