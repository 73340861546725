// IMPORTANT: routerのimportでvue-componentのcssが読み込まれるので、前に読み込みたいcssはこれより上でimportする
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import { ApmVuePlugin } from '@elastic/apm-rum-vue';
import { initializeApp } from 'firebase/app';
import { createApp } from 'vue';
import { createVueWait } from 'vue-wait';
import { Vue3Lottie } from 'vue3-lottie';
import BBitterAlert from '@/plugins/BBitterAlert';

import BMaterials from '@/plugins/biscuet-materials';
import BModal from '@/plugins/BModal';
import BSetInterval from '@/plugins/BSetInterval';
import BStringify from '@/plugins/BStringify';
import BToast from '@/plugins/BToast';
import ElementPlus from '@/plugins/element';

import './lib/intercom';

import AuthService from '@/services/auth_service';
import App from './App.vue';
import { i18n } from './i18n';
import { KEY_OF_WAIT } from './injection-keys';
import bugsnagConfig from './lib/bugsnag';
import router from './router';
import store, { key } from './store';

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
};
initializeApp(firebaseConfig);

if (process.env.NODE_ENV === 'production') {
  Bugsnag.start({
    ...bugsnagConfig,
    plugins: [new BugsnagPluginVue()],
  });
}
const bugsnagVue = Bugsnag.getPlugin('vue');

const app = createApp(App);
app.use(ElementPlus)
  .use(BToast)
  .use(BModal)
  .use(BSetInterval)
  .use(BStringify)
  .use(BBitterAlert)
  .use(BMaterials)
  .use(router)
  .use(store, key)
  .use(createVueWait({
    componentName: 'VWait',
  }))
  .use(i18n)
  .use(bugsnagVue)
  .component('Vue3Lottie', Vue3Lottie);

app.provide(KEY_OF_WAIT, app.config.globalProperties.$wait);

if (import.meta.env.VITE_ELASTIC_APM_SECRET_TOKEN) {
  app.use(ApmVuePlugin, {
    router,
    config: {
      distributedTracingOrigins: [import.meta.env.VITE_API_ENDPOINT],
      serverUrl: import.meta.env.VITE_ELASTIC_APM_SERVER_URL,
      secretToken: import.meta.env.VITE_ELASTIC_APM_SECRET_TOKEN,
      serviceName: 'SaaSClient',
      environment: process.env.NODE_ENV,
    },
  });
}

export type Role = 'owner' | 'member' | 'admin_owner' | 'admin_member'
const authService = new AuthService();
app.config.globalProperties.$can = (role: Role) => {
  return authService.currentRole() === role;
};
if (process.env.NODE_ENV !== 'production') {
  app.config.errorHandler = (err, _instance, info) => {
    // NOTE: エラーを捕まえて処理しておく
    console.error('error handle:', err, info);
  };
}

app.mount('#app');
