<template>
  <div>
    <BCard
      class="filter-select-card"
      small
    >
      <BInput
        v-model="searchText"
        class="mb-300"
        :placeholder="$t('filterSelect.placeholder')"
      />
      <div v-if="isMatching">
        <div
          v-if="filteredSystemInfo.length > 0"
          class="company-info"
        >
          <div class="sub-title">
            {{ $t('filterSelect.systemInfo.title') }}
          </div>
          <div
            v-for="([key, value]) in filteredSystemInfo"
            :key="key"
            class="select-item"
          >
            <BCheckbox
              class="mb-100"
              :model-value="currentState[key]"
              :label="value"
              @change="newValue => changeState(newValue, key)"
            />
          </div>
        </div>
        <div
          v-if="filteredCompanyInfo.length > 0"
          class="company-info"
        >
          <div class="sub-title">
            {{ $t('filterSelect.companyInfo.title') }}
          </div>
          <div
            v-for="([key, value]) in filteredCompanyInfo"
            :key="key"
            class="select-item"
          >
            <BCheckbox
              class="mb-100"
              :model-value="currentState[key]"
              :label="value"
              @change="newValue => changeState(newValue, key)"
            />
          </div>
        </div>
        <div
          v-if="filteredCallTargetInfo.length > 0"
          class="call-target-info pt-200"
        >
          <div class="sub-title">
            {{ $t('filterSelect.callTargetInfo.title') }}
          </div>
          <div
            v-for="([key, value]) in filteredCallTargetInfo"
            :key="key"
            class="select-item"
          >
            <BCheckbox
              class="mb-100"
              :model-value="currentState[key]"
              :label="value"
              @change="newValue => changeState(newValue, key)"
            />
          </div>
        </div>
        <div
          v-if="filteredHistoryInfo.length > 0"
          class="history-info pt-200"
        >
          <div class="sub-title">
            {{ $t('filterSelect.historyInfo.title') }}
          </div>
          <div
            v-for="([key, value]) in filteredHistoryInfo"
            :key="key"
            class="select-item"
          >
            <BCheckbox
              class="mb-100"
              :model-value="currentState[key]"
              :label="value"
              @change="newValue => changeState(newValue, key)"
            />
          </div>
        </div>
        <div
          v-if="filteredLeadAdditionalInfo.length > 0"
          class="lead-additional-info pt-200"
        >
          <div class="sub-title">
            {{ $t('filterSelect.leadAdditionalInfo.title') }}
          </div>
          <div
            v-for="([key, value]) in filteredLeadAdditionalInfo"
            :key="key"
            class="select-item"
          >
            <BCheckbox
              class="mb-100"
              :model-value="currentState[key]"
              :label="value"
              @change="newValue => changeState(newValue, key)"
            />
          </div>
        </div>
        <VWait for="getCustomFieldsWait">
          <div
            v-if="filteredCustomFields.length > 0"
            class="custom-field pt-200"
          >
            <div class="sub-title">
              {{ $t('filterSelect.customField.title') }}
            </div>
            <div
              v-for="item in filteredCustomFields"
              :key="item.fieldKey"
              class="select-item"
            >
              <BCheckbox
                class="mb-100"
                :model-value="currentCustomFieldState[item.fieldKey]"
                :label="item.name"
                @change="newValue => changeState(newValue, item.fieldKey)"
              />
            </div>
          </div>
        </VWait>
        <div
          v-if="filteredNextActions.length > 0"
          class="next-action pt-200"
        >
          <div class="sub-title">
            {{ $t('filterSelect.nextActions.title') }}
          </div>
          <div
            v-for="([key, value]) in filteredNextActions"
            :key="key"
            class="select-item"
          >
            <BCheckbox
              class="mb-100"
              :model-value="currentState[key]"
              :label="value"
              @change="newValue => changeState(newValue, key)"
            />
          </div>
        </div>
        <div
          v-if="filteredDuplicateInfo.length > 0"
          class="duplicate-info pt-200"
        >
          <div class="sub-title">
            {{ $t('filterSelect.duplicateInfo.title') }}
          </div>
          <div
            v-for="([key, value]) in filteredDuplicateInfo"
            :key="key"
            class="select-item"
          >
            <BCheckbox
              class="mb-100"
              :model-value="currentState[key]"
              :label="value"
              @change="newValue => changeState(newValue, key)"
            />
          </div>
        </div>
      </div>
      <div
        v-else
        class="text-annotation"
      >
        {{ $t('general.noCandidate') }}
      </div>
    </BCard>
  </div>
</template>

<script>
import { mapWaitingActions } from 'vue-wait';
import { mapGetters } from 'vuex';
import { useFilterParams } from '@/composable/user/leadList/lead-filter';
import filterService from '@/mixins/filter_service/index';

export default {
  name: 'FilterSelectCard',
  mixins: [filterService],
  setup() {
    const { removeFilterItemAndFetchLeadList, removeMultipleFilterItem } = useFilterParams();
    return {
      removeFilterItemAndFetchLeadList,
      removeMultipleFilterItem,
    };
  },
  data () {
    return {
      searchText: '',
    };
  },
  computed: {
    ...mapGetters('user', [
      'customFields',
    ]),
    ...mapGetters('userUi', [
      'leadListFilterParams',
      'filterControlPanelVisible',
    ]),
    filterItemList () {
      const systemInfo = Object.keys(this.$tm('filterSelect.systemInfo.item'));
      const companyInfoItem = Object.keys(this.$tm('filterSelect.companyInfo.item'));
      const callTargetInfo = Object.keys(this.$tm('filterSelect.callTargetInfo.item'));
      const historyInfo = Object.keys(this.$tm('filterSelect.historyInfo.item'));
      const leadAdditionalInfo = Object.keys(this.$tm('filterSelect.leadAdditionalInfo.item'));
      const nextActions = Object.keys(this.$tm('filterSelect.nextActions.item'));
      const duplicateInfo = Object.keys(this.$tm('filterSelect.duplicateInfo.item'));

      return companyInfoItem.concat(systemInfo, callTargetInfo, historyInfo, leadAdditionalInfo, nextActions, duplicateInfo);
    },
    selectedFilterItems () {
      const items = [];
      Object.entries(this.leadListFilterParams).forEach(([key, value]) => {
        if (key === 'customFieldItems') {
          items.push(...Object.keys(value));
        } else {
          items.push(key);
        }
      });
      return items;
    },
    isMatching () {
      return this.filteredCompanyInfo.length > 0
             || this.filteredCallTargetInfo.length > 0
             || this.filteredHistoryInfo.length > 0
             || this.filteredLeadAdditionalInfo.length > 0
             || this.filteredCustomFields.length > 0
             || this.filteredNextActions.length > 0
             || this.filteredSystemInfo.length > 0
             || this.filteredDuplicateInfo.length > 0;
    },
    filteredSystemInfo () {
      return this.filteredItems('filterSelect.systemInfo.item');
    },
    filteredCompanyInfo () {
      return this.filteredItems('filterSelect.companyInfo.item');
    },
    filteredCallTargetInfo () {
      return this.filteredItems('filterSelect.callTargetInfo.item');
    },
    filteredHistoryInfo () {
      return this.filteredItems('filterSelect.historyInfo.item');
    },
    filteredLeadAdditionalInfo () {
      return this.filteredItems('filterSelect.leadAdditionalInfo.item');
    },
    filteredCustomFields () {
      return this.customFields.filter(item => {
        return item.name.includes(this.searchText);
      });
    },
    filteredNextActions () {
      return this.filteredItems('filterSelect.nextActions.item');
    },
    filteredDuplicateInfo () {
      return this.filteredItems('filterSelect.duplicateInfo.item');
    },
    currentState () {
      // カスタム情報以外の項目でフィルタがすでに選択されているかをチェック
      const obj = {};
      this.filterItemList.forEach(key => {
        obj[key] = this.selectedFilterItems.includes(key);
      });
      return obj;
    },
    currentCustomFieldState () {
      // カスタム情報でフィルタがすでに選択されているかをチェック
      const obj = {};
      this.customFields.forEach(customField => {
        obj[customField.fieldKey] = this.selectedFilterItems.includes(customField.fieldKey);
      });
      return obj;
    },
  },
  watch: {
    selectedFilterItems: {
      handler(v) {
        const shouldPanelVisible = Array.isArray(v) && v.length > 0;

        if (shouldPanelVisible !== this.filterControlPanelVisible) {
          this.setFilterControlPanelVisibleAction(shouldPanelVisible);
        }
      },
      deep: true,
    },
    filterControlPanelVisible (newVal) {
      if (newVal) this.getCustomFieldsAction();
    },
  },
  methods: {
    ...mapWaitingActions('user', {
      getCustomFieldsAction: 'getCustomFieldsWait',
      getCallTargetsAction: 'getCallTargetsWait',
    }),
    ...mapWaitingActions('userUi', {
      setLeadListFilterParamsAction: 'setLeadListFilterParamsWait',
      setFilterControlPanelVisibleAction: 'setFilterControlPanelVisibleWait',
    }),
    changeState (value, key) {
      if (this.isMultiple(key)) {
        const keys = this.getMultipleFilterKey(key);
        value === true ? this.addMultipleFilterItem(keys) : this.removeMultipleFilterItem(keys);
      } else {
        // mixin の addFilterItem もしくは removeFilterItem を呼び出す
        value === true ? this.addFilterItem(key) : this.removeFilterItemAndFetchLeadList(key);
      }
    },
    isMultiple(key) {
      return Object.keys(this.getMultipleFilterKey()).includes(key);
    },
    filteredItems(i18nKey) {
      return Object.entries(this.$tm(i18nKey))
        .map(([key, msg]) => [key, this.$rt(msg)])
        .filter(([, value]) => {
          return value.includes(this.searchText);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  .sub-title {
    font-size: 12px;
    color: $textcolor-light;
    padding-bottom: $basespace-50;
    border-bottom: 1px solid $bdcolor-base;
    margin-bottom: $basespace-100;
  }
</style>
