// NOTE: 最終的にuseI18nに置き換える。mixinで使っている都合上、一旦importしている
import { i18nGlobal as i18n } from '@/i18n';
import { testUrl } from '@/utils/regexp';

const useInputValidations = () => {
  const requiredRule = (validateValue: string | number | null): string | true => {
    if (validateValue == null) return i18n.t('validation.required');

    const value = validateValue.toString();
    if (value.length === 0) return i18n.t('validation.required');
  
    const pattern = /^\s+$/;
    return !pattern.test(value) || i18n.t('validation.blankText');
  };

  const urlRule = (value: string): string | true => {
    const trimmedValue = trimValue(value);
    if (!trimmedValue) return true;

    return testUrl(trimmedValue) || i18n.t('validation.url');
  };

  const emailRule = (value: unknown): string | true => {
    const trimmedValue = trimValue(value);
    if (!trimmedValue) return true;

    const validEmailRule = emailTest(trimmedValue);
    if (validEmailRule !== true) return i18n.t('validation.wrongEmail');

    return lowercaseTest(value as string) || i18n.t('validation.uppercaseEmail');
  };

  const phoneRule = (value: string): string | true => {
    const trimmedValue = trimValue(value);
    if (!trimmedValue) return true;

    const pattern = /^\d(\d|-)+$/;
    const halfWidthNumber = trimmedValue.replace(/[０-９]/g, (number) => String.fromCharCode(number.charCodeAt(0) - 65248));
    return pattern.test(halfWidthNumber) || i18n.t(`validation.tel`);
  };

  const numberRule = (value: string): string | true => {
    if (!value) return true;
    const num = Number(value);
    return !isNaN(num) || i18n.t(`validation.number`);
  };

  const trimValue = (value: unknown): string => {
    if (typeof value !== 'string') return '';
    return value.trim();
  };
  
  const emailTest = (str: string): boolean => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(str);
  };

  const lowercaseTest = (str: string): boolean => {
    const pattern = /[A-Z]/;
    return !pattern.test(str);
  };

  const urlWithoutQueryRule = (value: string): string | true => {
    const result = urlRule(value);
    if (result !== true) return result;

    return !value.includes('?') || i18n.t('validation.urlWithoutQuery');
  };

  return {
    requiredRule,
    urlRule,
    emailRule,
    phoneRule,
    numberRule,
    // TODO: これ以下は最終的にmixinの移植が済んだら、exportを消す
    trimValue,
    emailTest,
    lowercaseTest,
    urlWithoutQueryRule,
  };
};

export {
  useInputValidations,
};
