import { rejectablize } from '@/utils/promise';

export class StoreDataFetchManager {
  rejectables = {};

  fetch<T>(key: string, apiExecutor: () => Promise<T>): Promise<T> {
    const rejectable = this.rejectables[key];
    if (rejectable) {
      rejectable.reject('cancel fetch process. cause: fetch process has reruned.');
    }
    this.rejectables[key] = rejectablize(apiExecutor());
    return this.rejectables[key].promise();
  }
}
