/* tslint:disable */
/* eslint-disable */
/**
 * openapi
 * bales cloud
 *
 * The version of the OpenAPI document: 1.0
 * Contact: test@example.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApplySequenceMaster200Response } from '../models';
// @ts-ignore
import { ApplySequenceMasterBody } from '../models';
// @ts-ignore
import { ChangeSequenceMasterOwnerBody } from '../models';
// @ts-ignore
import { CreateMfaSetting201Response } from '../models';
// @ts-ignore
import { GetSequenceInstanceOverviews200Response } from '../models';
// @ts-ignore
import { GetSequenceMaster200Response } from '../models';
// @ts-ignore
import { GetSequenceMasters200Response } from '../models';
// @ts-ignore
import { PostSequenceMasterBody } from '../models';
/**
 * SequenceMasterApi - axios parameter creator
 * @export
 */
export const SequenceMasterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * シーケンスリード適用API
         * @summary シーケンスリード適用API
         * @param {ApplySequenceMasterBody} [applySequenceMasterBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applySequenceMaster: async (applySequenceMasterBody?: ApplySequenceMasterBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/sequence/masters/apply`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applySequenceMasterBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * シーケンスマスターの所有者変更API
         * @summary シーケンスマスターの所有者変更API
         * @param {ChangeSequenceMasterOwnerBody} [changeSequenceMasterOwnerBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeSequenceMasterOwner: async (changeSequenceMasterOwnerBody?: ChangeSequenceMasterOwnerBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/sequence/masters/change_owner`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeSequenceMasterOwnerBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * シーケンスマスタ削除API
         * @summary シーケンスマスタ削除API
         * @param {number} sequenceMasterId シーケンスマスターID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSequenceMaster: async (sequenceMasterId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sequenceMasterId' is not null or undefined
            assertParamExists('deleteSequenceMaster', 'sequenceMasterId', sequenceMasterId)
            const localVarPath = `/api/user/sequence/masters/{sequenceMasterId}`
                .replace(`{${"sequenceMasterId"}}`, encodeURIComponent(String(sequenceMasterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * シーケンスインスタンス一覧取得API
         * @summary シーケンスインスタンス一覧取得API
         * @param {number} sequenceMasterId シーケンスマスターID
         * @param {number} [page] infinityLoading用ページ番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSequenceInstanceOverviews: async (sequenceMasterId: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sequenceMasterId' is not null or undefined
            assertParamExists('getSequenceInstanceOverviews', 'sequenceMasterId', sequenceMasterId)
            const localVarPath = `/api/user/sequence/masters/{sequenceMasterId}/instances/overviews`
                .replace(`{${"sequenceMasterId"}}`, encodeURIComponent(String(sequenceMasterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * シーケンスマスター取得API
         * @summary シーケンスマスター取得API
         * @param {number} sequenceMasterId シーケンスマスターID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSequenceMaster: async (sequenceMasterId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sequenceMasterId' is not null or undefined
            assertParamExists('getSequenceMaster', 'sequenceMasterId', sequenceMasterId)
            const localVarPath = `/api/user/sequence/masters/{sequenceMasterId}`
                .replace(`{${"sequenceMasterId"}}`, encodeURIComponent(String(sequenceMasterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * シーケンスマスタ一覧取得API
         * @summary シーケンスマスタ一覧取得API
         * @param {number} [currentPage] リードリストでの現在のページネーション
         * @param {Array<number>} [callTargetIds] リードIds
         * @param {number} [ownerId] 所有者ID
         * @param {Array<string>} [fields] 取得フィールド
         * @param {boolean} [noLimit] 件数制限なし
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSequenceMasters: async (currentPage?: number, callTargetIds?: Array<number>, ownerId?: number, fields?: Array<string>, noLimit?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/sequence/masters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)

            if (currentPage !== undefined) {
                localVarQueryParameter['currentPage'] = currentPage;
            }

            if (callTargetIds) {
                localVarQueryParameter['callTargetIds'] = callTargetIds;
            }

            if (ownerId !== undefined) {
                localVarQueryParameter['ownerId'] = ownerId;
            }

            if (fields) {
                localVarQueryParameter['fields[]'] = fields;
            }

            if (noLimit !== undefined) {
                localVarQueryParameter['noLimit'] = noLimit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * シーケンスマスタの作成API
         * @summary シーケンスマスタの作成API
         * @param {PostSequenceMasterBody} [postSequenceMasterBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSequenceMaster: async (postSequenceMasterBody?: PostSequenceMasterBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/sequence/masters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postSequenceMasterBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * シーケンスマスター更新API
         * @summary シーケンスマスター更新API
         * @param {number} sequenceMasterId シーケンスマスターID
         * @param {PostSequenceMasterBody} [postSequenceMasterBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putSequenceMaster: async (sequenceMasterId: number, postSequenceMasterBody?: PostSequenceMasterBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sequenceMasterId' is not null or undefined
            assertParamExists('putSequenceMaster', 'sequenceMasterId', sequenceMasterId)
            const localVarPath = `/api/user/sequence/masters/{sequenceMasterId}`
                .replace(`{${"sequenceMasterId"}}`, encodeURIComponent(String(sequenceMasterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postSequenceMasterBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SequenceMasterApi - functional programming interface
 * @export
 */
export const SequenceMasterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SequenceMasterApiAxiosParamCreator(configuration)
    return {
        /**
         * シーケンスリード適用API
         * @summary シーケンスリード適用API
         * @param {ApplySequenceMasterBody} [applySequenceMasterBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applySequenceMaster(applySequenceMasterBody?: ApplySequenceMasterBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplySequenceMaster200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applySequenceMaster(applySequenceMasterBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * シーケンスマスターの所有者変更API
         * @summary シーケンスマスターの所有者変更API
         * @param {ChangeSequenceMasterOwnerBody} [changeSequenceMasterOwnerBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeSequenceMasterOwner(changeSequenceMasterOwnerBody?: ChangeSequenceMasterOwnerBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeSequenceMasterOwner(changeSequenceMasterOwnerBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * シーケンスマスタ削除API
         * @summary シーケンスマスタ削除API
         * @param {number} sequenceMasterId シーケンスマスターID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSequenceMaster(sequenceMasterId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSequenceMaster(sequenceMasterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * シーケンスインスタンス一覧取得API
         * @summary シーケンスインスタンス一覧取得API
         * @param {number} sequenceMasterId シーケンスマスターID
         * @param {number} [page] infinityLoading用ページ番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSequenceInstanceOverviews(sequenceMasterId: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSequenceInstanceOverviews200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSequenceInstanceOverviews(sequenceMasterId, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * シーケンスマスター取得API
         * @summary シーケンスマスター取得API
         * @param {number} sequenceMasterId シーケンスマスターID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSequenceMaster(sequenceMasterId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSequenceMaster200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSequenceMaster(sequenceMasterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * シーケンスマスタ一覧取得API
         * @summary シーケンスマスタ一覧取得API
         * @param {number} [currentPage] リードリストでの現在のページネーション
         * @param {Array<number>} [callTargetIds] リードIds
         * @param {number} [ownerId] 所有者ID
         * @param {Array<string>} [fields] 取得フィールド
         * @param {boolean} [noLimit] 件数制限なし
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSequenceMasters(currentPage?: number, callTargetIds?: Array<number>, ownerId?: number, fields?: Array<string>, noLimit?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSequenceMasters200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSequenceMasters(currentPage, callTargetIds, ownerId, fields, noLimit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * シーケンスマスタの作成API
         * @summary シーケンスマスタの作成API
         * @param {PostSequenceMasterBody} [postSequenceMasterBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSequenceMaster(postSequenceMasterBody?: PostSequenceMasterBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateMfaSetting201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSequenceMaster(postSequenceMasterBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * シーケンスマスター更新API
         * @summary シーケンスマスター更新API
         * @param {number} sequenceMasterId シーケンスマスターID
         * @param {PostSequenceMasterBody} [postSequenceMasterBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putSequenceMaster(sequenceMasterId: number, postSequenceMasterBody?: PostSequenceMasterBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putSequenceMaster(sequenceMasterId, postSequenceMasterBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SequenceMasterApi - factory interface
 * @export
 */
export const SequenceMasterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SequenceMasterApiFp(configuration)
    return {
        /**
         * シーケンスリード適用API
         * @summary シーケンスリード適用API
         * @param {ApplySequenceMasterBody} [applySequenceMasterBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applySequenceMaster(applySequenceMasterBody?: ApplySequenceMasterBody, options?: any): AxiosPromise<ApplySequenceMaster200Response> {
            return localVarFp.applySequenceMaster(applySequenceMasterBody, options).then((request) => request(axios, basePath));
        },
        /**
         * シーケンスマスターの所有者変更API
         * @summary シーケンスマスターの所有者変更API
         * @param {ChangeSequenceMasterOwnerBody} [changeSequenceMasterOwnerBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeSequenceMasterOwner(changeSequenceMasterOwnerBody?: ChangeSequenceMasterOwnerBody, options?: any): AxiosPromise<void> {
            return localVarFp.changeSequenceMasterOwner(changeSequenceMasterOwnerBody, options).then((request) => request(axios, basePath));
        },
        /**
         * シーケンスマスタ削除API
         * @summary シーケンスマスタ削除API
         * @param {number} sequenceMasterId シーケンスマスターID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSequenceMaster(sequenceMasterId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSequenceMaster(sequenceMasterId, options).then((request) => request(axios, basePath));
        },
        /**
         * シーケンスインスタンス一覧取得API
         * @summary シーケンスインスタンス一覧取得API
         * @param {number} sequenceMasterId シーケンスマスターID
         * @param {number} [page] infinityLoading用ページ番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSequenceInstanceOverviews(sequenceMasterId: number, page?: number, options?: any): AxiosPromise<GetSequenceInstanceOverviews200Response> {
            return localVarFp.getSequenceInstanceOverviews(sequenceMasterId, page, options).then((request) => request(axios, basePath));
        },
        /**
         * シーケンスマスター取得API
         * @summary シーケンスマスター取得API
         * @param {number} sequenceMasterId シーケンスマスターID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSequenceMaster(sequenceMasterId: number, options?: any): AxiosPromise<GetSequenceMaster200Response> {
            return localVarFp.getSequenceMaster(sequenceMasterId, options).then((request) => request(axios, basePath));
        },
        /**
         * シーケンスマスタ一覧取得API
         * @summary シーケンスマスタ一覧取得API
         * @param {number} [currentPage] リードリストでの現在のページネーション
         * @param {Array<number>} [callTargetIds] リードIds
         * @param {number} [ownerId] 所有者ID
         * @param {Array<string>} [fields] 取得フィールド
         * @param {boolean} [noLimit] 件数制限なし
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSequenceMasters(currentPage?: number, callTargetIds?: Array<number>, ownerId?: number, fields?: Array<string>, noLimit?: boolean, options?: any): AxiosPromise<GetSequenceMasters200Response> {
            return localVarFp.getSequenceMasters(currentPage, callTargetIds, ownerId, fields, noLimit, options).then((request) => request(axios, basePath));
        },
        /**
         * シーケンスマスタの作成API
         * @summary シーケンスマスタの作成API
         * @param {PostSequenceMasterBody} [postSequenceMasterBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSequenceMaster(postSequenceMasterBody?: PostSequenceMasterBody, options?: any): AxiosPromise<CreateMfaSetting201Response> {
            return localVarFp.postSequenceMaster(postSequenceMasterBody, options).then((request) => request(axios, basePath));
        },
        /**
         * シーケンスマスター更新API
         * @summary シーケンスマスター更新API
         * @param {number} sequenceMasterId シーケンスマスターID
         * @param {PostSequenceMasterBody} [postSequenceMasterBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putSequenceMaster(sequenceMasterId: number, postSequenceMasterBody?: PostSequenceMasterBody, options?: any): AxiosPromise<void> {
            return localVarFp.putSequenceMaster(sequenceMasterId, postSequenceMasterBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SequenceMasterApi - interface
 * @export
 * @interface SequenceMasterApi
 */
export interface SequenceMasterApiInterface {
    /**
     * シーケンスリード適用API
     * @summary シーケンスリード適用API
     * @param {ApplySequenceMasterBody} [applySequenceMasterBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceMasterApiInterface
     */
    applySequenceMaster(applySequenceMasterBody?: ApplySequenceMasterBody, options?: AxiosRequestConfig): AxiosPromise<ApplySequenceMaster200Response>;

    /**
     * シーケンスマスターの所有者変更API
     * @summary シーケンスマスターの所有者変更API
     * @param {ChangeSequenceMasterOwnerBody} [changeSequenceMasterOwnerBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceMasterApiInterface
     */
    changeSequenceMasterOwner(changeSequenceMasterOwnerBody?: ChangeSequenceMasterOwnerBody, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * シーケンスマスタ削除API
     * @summary シーケンスマスタ削除API
     * @param {number} sequenceMasterId シーケンスマスターID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceMasterApiInterface
     */
    deleteSequenceMaster(sequenceMasterId: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * シーケンスインスタンス一覧取得API
     * @summary シーケンスインスタンス一覧取得API
     * @param {number} sequenceMasterId シーケンスマスターID
     * @param {number} [page] infinityLoading用ページ番号
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceMasterApiInterface
     */
    getSequenceInstanceOverviews(sequenceMasterId: number, page?: number, options?: AxiosRequestConfig): AxiosPromise<GetSequenceInstanceOverviews200Response>;

    /**
     * シーケンスマスター取得API
     * @summary シーケンスマスター取得API
     * @param {number} sequenceMasterId シーケンスマスターID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceMasterApiInterface
     */
    getSequenceMaster(sequenceMasterId: number, options?: AxiosRequestConfig): AxiosPromise<GetSequenceMaster200Response>;

    /**
     * シーケンスマスタ一覧取得API
     * @summary シーケンスマスタ一覧取得API
     * @param {number} [currentPage] リードリストでの現在のページネーション
     * @param {Array<number>} [callTargetIds] リードIds
     * @param {number} [ownerId] 所有者ID
     * @param {Array<string>} [fields] 取得フィールド
     * @param {boolean} [noLimit] 件数制限なし
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceMasterApiInterface
     */
    getSequenceMasters(currentPage?: number, callTargetIds?: Array<number>, ownerId?: number, fields?: Array<string>, noLimit?: boolean, options?: AxiosRequestConfig): AxiosPromise<GetSequenceMasters200Response>;

    /**
     * シーケンスマスタの作成API
     * @summary シーケンスマスタの作成API
     * @param {PostSequenceMasterBody} [postSequenceMasterBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceMasterApiInterface
     */
    postSequenceMaster(postSequenceMasterBody?: PostSequenceMasterBody, options?: AxiosRequestConfig): AxiosPromise<CreateMfaSetting201Response>;

    /**
     * シーケンスマスター更新API
     * @summary シーケンスマスター更新API
     * @param {number} sequenceMasterId シーケンスマスターID
     * @param {PostSequenceMasterBody} [postSequenceMasterBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceMasterApiInterface
     */
    putSequenceMaster(sequenceMasterId: number, postSequenceMasterBody?: PostSequenceMasterBody, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * Request parameters for applySequenceMaster operation in SequenceMasterApi.
 * @export
 * @interface SequenceMasterApiApplySequenceMasterRequest
 */
export interface SequenceMasterApiApplySequenceMasterRequest {
    /**
     * 
     * @type {ApplySequenceMasterBody}
     * @memberof SequenceMasterApiApplySequenceMaster
     */
    readonly applySequenceMasterBody?: ApplySequenceMasterBody
}

/**
 * Request parameters for changeSequenceMasterOwner operation in SequenceMasterApi.
 * @export
 * @interface SequenceMasterApiChangeSequenceMasterOwnerRequest
 */
export interface SequenceMasterApiChangeSequenceMasterOwnerRequest {
    /**
     * 
     * @type {ChangeSequenceMasterOwnerBody}
     * @memberof SequenceMasterApiChangeSequenceMasterOwner
     */
    readonly changeSequenceMasterOwnerBody?: ChangeSequenceMasterOwnerBody
}

/**
 * Request parameters for deleteSequenceMaster operation in SequenceMasterApi.
 * @export
 * @interface SequenceMasterApiDeleteSequenceMasterRequest
 */
export interface SequenceMasterApiDeleteSequenceMasterRequest {
    /**
     * シーケンスマスターID
     * @type {number}
     * @memberof SequenceMasterApiDeleteSequenceMaster
     */
    readonly sequenceMasterId: number
}

/**
 * Request parameters for getSequenceInstanceOverviews operation in SequenceMasterApi.
 * @export
 * @interface SequenceMasterApiGetSequenceInstanceOverviewsRequest
 */
export interface SequenceMasterApiGetSequenceInstanceOverviewsRequest {
    /**
     * シーケンスマスターID
     * @type {number}
     * @memberof SequenceMasterApiGetSequenceInstanceOverviews
     */
    readonly sequenceMasterId: number

    /**
     * infinityLoading用ページ番号
     * @type {number}
     * @memberof SequenceMasterApiGetSequenceInstanceOverviews
     */
    readonly page?: number
}

/**
 * Request parameters for getSequenceMaster operation in SequenceMasterApi.
 * @export
 * @interface SequenceMasterApiGetSequenceMasterRequest
 */
export interface SequenceMasterApiGetSequenceMasterRequest {
    /**
     * シーケンスマスターID
     * @type {number}
     * @memberof SequenceMasterApiGetSequenceMaster
     */
    readonly sequenceMasterId: number
}

/**
 * Request parameters for getSequenceMasters operation in SequenceMasterApi.
 * @export
 * @interface SequenceMasterApiGetSequenceMastersRequest
 */
export interface SequenceMasterApiGetSequenceMastersRequest {
    /**
     * リードリストでの現在のページネーション
     * @type {number}
     * @memberof SequenceMasterApiGetSequenceMasters
     */
    readonly currentPage?: number

    /**
     * リードIds
     * @type {Array<number>}
     * @memberof SequenceMasterApiGetSequenceMasters
     */
    readonly callTargetIds?: Array<number>

    /**
     * 所有者ID
     * @type {number}
     * @memberof SequenceMasterApiGetSequenceMasters
     */
    readonly ownerId?: number

    /**
     * 取得フィールド
     * @type {Array<string>}
     * @memberof SequenceMasterApiGetSequenceMasters
     */
    readonly fields?: Array<string>

    /**
     * 件数制限なし
     * @type {boolean}
     * @memberof SequenceMasterApiGetSequenceMasters
     */
    readonly noLimit?: boolean
}

/**
 * Request parameters for postSequenceMaster operation in SequenceMasterApi.
 * @export
 * @interface SequenceMasterApiPostSequenceMasterRequest
 */
export interface SequenceMasterApiPostSequenceMasterRequest {
    /**
     * 
     * @type {PostSequenceMasterBody}
     * @memberof SequenceMasterApiPostSequenceMaster
     */
    readonly postSequenceMasterBody?: PostSequenceMasterBody
}

/**
 * Request parameters for putSequenceMaster operation in SequenceMasterApi.
 * @export
 * @interface SequenceMasterApiPutSequenceMasterRequest
 */
export interface SequenceMasterApiPutSequenceMasterRequest {
    /**
     * シーケンスマスターID
     * @type {number}
     * @memberof SequenceMasterApiPutSequenceMaster
     */
    readonly sequenceMasterId: number

    /**
     * 
     * @type {PostSequenceMasterBody}
     * @memberof SequenceMasterApiPutSequenceMaster
     */
    readonly postSequenceMasterBody?: PostSequenceMasterBody
}

/**
 * SequenceMasterApi - object-oriented interface
 * @export
 * @class SequenceMasterApi
 * @extends {BaseAPI}
 */
export class SequenceMasterApi extends BaseAPI implements SequenceMasterApiInterface {
    /**
     * シーケンスリード適用API
     * @summary シーケンスリード適用API
     * @param {SequenceMasterApiApplySequenceMasterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceMasterApi
     */
    public applySequenceMaster(requestParameters: SequenceMasterApiApplySequenceMasterRequest = {}, options?: AxiosRequestConfig) {
        return SequenceMasterApiFp(this.configuration).applySequenceMaster(requestParameters.applySequenceMasterBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * シーケンスマスターの所有者変更API
     * @summary シーケンスマスターの所有者変更API
     * @param {SequenceMasterApiChangeSequenceMasterOwnerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceMasterApi
     */
    public changeSequenceMasterOwner(requestParameters: SequenceMasterApiChangeSequenceMasterOwnerRequest = {}, options?: AxiosRequestConfig) {
        return SequenceMasterApiFp(this.configuration).changeSequenceMasterOwner(requestParameters.changeSequenceMasterOwnerBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * シーケンスマスタ削除API
     * @summary シーケンスマスタ削除API
     * @param {SequenceMasterApiDeleteSequenceMasterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceMasterApi
     */
    public deleteSequenceMaster(requestParameters: SequenceMasterApiDeleteSequenceMasterRequest, options?: AxiosRequestConfig) {
        return SequenceMasterApiFp(this.configuration).deleteSequenceMaster(requestParameters.sequenceMasterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * シーケンスインスタンス一覧取得API
     * @summary シーケンスインスタンス一覧取得API
     * @param {SequenceMasterApiGetSequenceInstanceOverviewsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceMasterApi
     */
    public getSequenceInstanceOverviews(requestParameters: SequenceMasterApiGetSequenceInstanceOverviewsRequest, options?: AxiosRequestConfig) {
        return SequenceMasterApiFp(this.configuration).getSequenceInstanceOverviews(requestParameters.sequenceMasterId, requestParameters.page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * シーケンスマスター取得API
     * @summary シーケンスマスター取得API
     * @param {SequenceMasterApiGetSequenceMasterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceMasterApi
     */
    public getSequenceMaster(requestParameters: SequenceMasterApiGetSequenceMasterRequest, options?: AxiosRequestConfig) {
        return SequenceMasterApiFp(this.configuration).getSequenceMaster(requestParameters.sequenceMasterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * シーケンスマスタ一覧取得API
     * @summary シーケンスマスタ一覧取得API
     * @param {SequenceMasterApiGetSequenceMastersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceMasterApi
     */
    public getSequenceMasters(requestParameters: SequenceMasterApiGetSequenceMastersRequest = {}, options?: AxiosRequestConfig) {
        return SequenceMasterApiFp(this.configuration).getSequenceMasters(requestParameters.currentPage, requestParameters.callTargetIds, requestParameters.ownerId, requestParameters.fields, requestParameters.noLimit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * シーケンスマスタの作成API
     * @summary シーケンスマスタの作成API
     * @param {SequenceMasterApiPostSequenceMasterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceMasterApi
     */
    public postSequenceMaster(requestParameters: SequenceMasterApiPostSequenceMasterRequest = {}, options?: AxiosRequestConfig) {
        return SequenceMasterApiFp(this.configuration).postSequenceMaster(requestParameters.postSequenceMasterBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * シーケンスマスター更新API
     * @summary シーケンスマスター更新API
     * @param {SequenceMasterApiPutSequenceMasterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceMasterApi
     */
    public putSequenceMaster(requestParameters: SequenceMasterApiPutSequenceMasterRequest, options?: AxiosRequestConfig) {
        return SequenceMasterApiFp(this.configuration).putSequenceMaster(requestParameters.sequenceMasterId, requestParameters.postSequenceMasterBody, options).then((request) => request(this.axios, this.basePath));
    }
}
