import { AxiosResponse } from 'axios';
import ApiService, { ApiArguments } from '@/api/api_service';
import { GetLeadMergeTasks200Response } from '@/api/openapi';
import { LeadMergeTaskApi, LeadMergeTaskApiPutLeadMergeTaskRequest } from '@/api/openapi/apis/lead-merge-task-api';

export class LeadMergeTaskApiService extends ApiService {
  api = new LeadMergeTaskApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true);
  }

  async getLeadMergeTasks({
    options,
    errorHandlers,
  }: ApiArguments<undefined>): Promise<AxiosResponse<GetLeadMergeTasks200Response>> {
    return this.checkResponse<GetLeadMergeTasks200Response>(
      this.api.getLeadMergeTasks(options),
      errorHandlers,
    );
  }

  async putLeadMergeTask({
    request,
    options,
    errorHandlers,
  }: ApiArguments<LeadMergeTaskApiPutLeadMergeTaskRequest>): Promise<AxiosResponse<void>> {
    return this.checkResponse<void>(
      this.api.putLeadMergeTask(request, options),
      errorHandlers,
    );
  }
}
