import ApiService, { ApiArguments } from '@/api/api_service';
import {
  GetTriggerTargets200Response,
  TriggerTargetApi,
  TriggerTargetApiGetTriggerTargetsRequest,
} from '@/api/openapi';

export class TriggerTargetApiService extends ApiService {
  api = new TriggerTargetApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true);
  }

  async getTriggerTargets(
    {
      request,
      options,
      errorHandlers,
    }: ApiArguments<TriggerTargetApiGetTriggerTargetsRequest>,
  ) {
    return this.checkResponse<GetTriggerTargets200Response>(
      this.api.getTriggerTargets(request, options),
      errorHandlers,
    );
  }
}
