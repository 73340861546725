/* tslint:disable */
/* eslint-disable */
/**
 * openapi
 * bales cloud
 *
 * The version of the OpenAPI document: 1.0
 * Contact: test@example.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CallTargetSfSyncBody } from '../models';
// @ts-ignore
import { CreateTask200Response } from '../models';
// @ts-ignore
import { GetCallTarget200Response } from '../models';
// @ts-ignore
import { GetMailRemindItems200Response } from '../models';
// @ts-ignore
import { GetNextPageFirstId200Response } from '../models';
// @ts-ignore
import { MergeCallTargetsBody } from '../models';
/**
 * CallTargetApi - axios parameter creator
 * @export
 */
export const CallTargetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 単一CallTargetのsf同期API
         * @summary 単一CallTargetのsf同期
         * @param {number} callTargetId リードID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        callTargetSfSync: async (callTargetId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'callTargetId' is not null or undefined
            assertParamExists('callTargetSfSync', 'callTargetId', callTargetId)
            const localVarPath = `/api/user/call_targets/{call_target_id}/sf_sync`
                .replace(`{${"call_target_id"}}`, encodeURIComponent(String(callTargetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 単体リード情報取得API
         * @summary 単体リード情報取得API
         * @param {number} callTargetId リードID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCallTarget: async (callTargetId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'callTargetId' is not null or undefined
            assertParamExists('getCallTarget', 'callTargetId', callTargetId)
            const localVarPath = `/api/user/call_targets/{call_target_id}`
                .replace(`{${"call_target_id"}}`, encodeURIComponent(String(callTargetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * メールリマインド情報取得API
         * @summary メールリマインド情報取得API
         * @param {number} [userId] ユーザーID
         * @param {boolean} [draft] 下書き
         * @param {boolean} [reserved] 配信予約
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailRemindItems: async (userId?: number, draft?: boolean, reserved?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/call_targets/mail_remind_items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (draft !== undefined) {
                localVarQueryParameter['draft'] = draft;
            }

            if (reserved !== undefined) {
                localVarQueryParameter['reserved'] = reserved;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 「前へ」で使うID取得処理API
         * @summary 「前へ」で使うID取得処理API
         * @param {string} [lf] リードリストのフィルターパラメータ
         * @param {string} [sortKey] リードリストのソートキー
         * @param {number} [currentDisplayNumber] リードリストで表示するリード数
         * @param {number} [currentPage] リードリストでの現在のページネーション
         * @param {string} [sortOrder] リードリストのソート順
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNextPageFirstId: async (lf?: string, sortKey?: string, currentDisplayNumber?: number, currentPage?: number, sortOrder?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/call_targets/next_page_first_id`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)

            if (lf !== undefined) {
                localVarQueryParameter['lf'] = lf;
            }

            if (sortKey !== undefined) {
                localVarQueryParameter['sortKey'] = sortKey;
            }

            if (currentDisplayNumber !== undefined) {
                localVarQueryParameter['currentDisplayNumber'] = currentDisplayNumber;
            }

            if (currentPage !== undefined) {
                localVarQueryParameter['currentPage'] = currentPage;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 「次へ」で使うID取得処理API
         * @summary 「次へ」で使うID取得処理API
         * @param {string} [lf] リードリストのフィルターパラメータ
         * @param {string} [sortKey] リードリストのソートキー
         * @param {number} [currentDisplayNumber] リードリストで表示するリード数
         * @param {number} [currentPage] リードリストでの現在のページネーション
         * @param {string} [sortOrder] リードリストのソート順
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreviousPageLastId: async (lf?: string, sortKey?: string, currentDisplayNumber?: number, currentPage?: number, sortOrder?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/call_targets/previous_page_last_id`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)

            if (lf !== undefined) {
                localVarQueryParameter['lf'] = lf;
            }

            if (sortKey !== undefined) {
                localVarQueryParameter['sortKey'] = sortKey;
            }

            if (currentDisplayNumber !== undefined) {
                localVarQueryParameter['currentDisplayNumber'] = currentDisplayNumber;
            }

            if (currentPage !== undefined) {
                localVarQueryParameter['currentPage'] = currentPage;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リード重複統合API
         * @summary リード重複統合API
         * @param {MergeCallTargetsBody} [mergeCallTargetsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mergeCallTargets: async (mergeCallTargetsBody?: MergeCallTargetsBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/call_targets/merge`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mergeCallTargetsBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CallTargetApi - functional programming interface
 * @export
 */
export const CallTargetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CallTargetApiAxiosParamCreator(configuration)
    return {
        /**
         * 単一CallTargetのsf同期API
         * @summary 単一CallTargetのsf同期
         * @param {number} callTargetId リードID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async callTargetSfSync(callTargetId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CallTargetSfSyncBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.callTargetSfSync(callTargetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 単体リード情報取得API
         * @summary 単体リード情報取得API
         * @param {number} callTargetId リードID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCallTarget(callTargetId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCallTarget200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCallTarget(callTargetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * メールリマインド情報取得API
         * @summary メールリマインド情報取得API
         * @param {number} [userId] ユーザーID
         * @param {boolean} [draft] 下書き
         * @param {boolean} [reserved] 配信予約
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMailRemindItems(userId?: number, draft?: boolean, reserved?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMailRemindItems200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMailRemindItems(userId, draft, reserved, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 「前へ」で使うID取得処理API
         * @summary 「前へ」で使うID取得処理API
         * @param {string} [lf] リードリストのフィルターパラメータ
         * @param {string} [sortKey] リードリストのソートキー
         * @param {number} [currentDisplayNumber] リードリストで表示するリード数
         * @param {number} [currentPage] リードリストでの現在のページネーション
         * @param {string} [sortOrder] リードリストのソート順
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNextPageFirstId(lf?: string, sortKey?: string, currentDisplayNumber?: number, currentPage?: number, sortOrder?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNextPageFirstId200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNextPageFirstId(lf, sortKey, currentDisplayNumber, currentPage, sortOrder, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 「次へ」で使うID取得処理API
         * @summary 「次へ」で使うID取得処理API
         * @param {string} [lf] リードリストのフィルターパラメータ
         * @param {string} [sortKey] リードリストのソートキー
         * @param {number} [currentDisplayNumber] リードリストで表示するリード数
         * @param {number} [currentPage] リードリストでの現在のページネーション
         * @param {string} [sortOrder] リードリストのソート順
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPreviousPageLastId(lf?: string, sortKey?: string, currentDisplayNumber?: number, currentPage?: number, sortOrder?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNextPageFirstId200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPreviousPageLastId(lf, sortKey, currentDisplayNumber, currentPage, sortOrder, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リード重複統合API
         * @summary リード重複統合API
         * @param {MergeCallTargetsBody} [mergeCallTargetsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mergeCallTargets(mergeCallTargetsBody?: MergeCallTargetsBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateTask200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mergeCallTargets(mergeCallTargetsBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CallTargetApi - factory interface
 * @export
 */
export const CallTargetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CallTargetApiFp(configuration)
    return {
        /**
         * 単一CallTargetのsf同期API
         * @summary 単一CallTargetのsf同期
         * @param {number} callTargetId リードID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        callTargetSfSync(callTargetId: number, options?: any): AxiosPromise<CallTargetSfSyncBody> {
            return localVarFp.callTargetSfSync(callTargetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 単体リード情報取得API
         * @summary 単体リード情報取得API
         * @param {number} callTargetId リードID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCallTarget(callTargetId: number, options?: any): AxiosPromise<GetCallTarget200Response> {
            return localVarFp.getCallTarget(callTargetId, options).then((request) => request(axios, basePath));
        },
        /**
         * メールリマインド情報取得API
         * @summary メールリマインド情報取得API
         * @param {number} [userId] ユーザーID
         * @param {boolean} [draft] 下書き
         * @param {boolean} [reserved] 配信予約
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailRemindItems(userId?: number, draft?: boolean, reserved?: boolean, options?: any): AxiosPromise<GetMailRemindItems200Response> {
            return localVarFp.getMailRemindItems(userId, draft, reserved, options).then((request) => request(axios, basePath));
        },
        /**
         * 「前へ」で使うID取得処理API
         * @summary 「前へ」で使うID取得処理API
         * @param {string} [lf] リードリストのフィルターパラメータ
         * @param {string} [sortKey] リードリストのソートキー
         * @param {number} [currentDisplayNumber] リードリストで表示するリード数
         * @param {number} [currentPage] リードリストでの現在のページネーション
         * @param {string} [sortOrder] リードリストのソート順
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNextPageFirstId(lf?: string, sortKey?: string, currentDisplayNumber?: number, currentPage?: number, sortOrder?: string, options?: any): AxiosPromise<GetNextPageFirstId200Response> {
            return localVarFp.getNextPageFirstId(lf, sortKey, currentDisplayNumber, currentPage, sortOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 「次へ」で使うID取得処理API
         * @summary 「次へ」で使うID取得処理API
         * @param {string} [lf] リードリストのフィルターパラメータ
         * @param {string} [sortKey] リードリストのソートキー
         * @param {number} [currentDisplayNumber] リードリストで表示するリード数
         * @param {number} [currentPage] リードリストでの現在のページネーション
         * @param {string} [sortOrder] リードリストのソート順
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreviousPageLastId(lf?: string, sortKey?: string, currentDisplayNumber?: number, currentPage?: number, sortOrder?: string, options?: any): AxiosPromise<GetNextPageFirstId200Response> {
            return localVarFp.getPreviousPageLastId(lf, sortKey, currentDisplayNumber, currentPage, sortOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * リード重複統合API
         * @summary リード重複統合API
         * @param {MergeCallTargetsBody} [mergeCallTargetsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mergeCallTargets(mergeCallTargetsBody?: MergeCallTargetsBody, options?: any): AxiosPromise<CreateTask200Response> {
            return localVarFp.mergeCallTargets(mergeCallTargetsBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CallTargetApi - interface
 * @export
 * @interface CallTargetApi
 */
export interface CallTargetApiInterface {
    /**
     * 単一CallTargetのsf同期API
     * @summary 単一CallTargetのsf同期
     * @param {number} callTargetId リードID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CallTargetApiInterface
     */
    callTargetSfSync(callTargetId: number, options?: AxiosRequestConfig): AxiosPromise<CallTargetSfSyncBody>;

    /**
     * 単体リード情報取得API
     * @summary 単体リード情報取得API
     * @param {number} callTargetId リードID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CallTargetApiInterface
     */
    getCallTarget(callTargetId: number, options?: AxiosRequestConfig): AxiosPromise<GetCallTarget200Response>;

    /**
     * メールリマインド情報取得API
     * @summary メールリマインド情報取得API
     * @param {number} [userId] ユーザーID
     * @param {boolean} [draft] 下書き
     * @param {boolean} [reserved] 配信予約
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CallTargetApiInterface
     */
    getMailRemindItems(userId?: number, draft?: boolean, reserved?: boolean, options?: AxiosRequestConfig): AxiosPromise<GetMailRemindItems200Response>;

    /**
     * 「前へ」で使うID取得処理API
     * @summary 「前へ」で使うID取得処理API
     * @param {string} [lf] リードリストのフィルターパラメータ
     * @param {string} [sortKey] リードリストのソートキー
     * @param {number} [currentDisplayNumber] リードリストで表示するリード数
     * @param {number} [currentPage] リードリストでの現在のページネーション
     * @param {string} [sortOrder] リードリストのソート順
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CallTargetApiInterface
     */
    getNextPageFirstId(lf?: string, sortKey?: string, currentDisplayNumber?: number, currentPage?: number, sortOrder?: string, options?: AxiosRequestConfig): AxiosPromise<GetNextPageFirstId200Response>;

    /**
     * 「次へ」で使うID取得処理API
     * @summary 「次へ」で使うID取得処理API
     * @param {string} [lf] リードリストのフィルターパラメータ
     * @param {string} [sortKey] リードリストのソートキー
     * @param {number} [currentDisplayNumber] リードリストで表示するリード数
     * @param {number} [currentPage] リードリストでの現在のページネーション
     * @param {string} [sortOrder] リードリストのソート順
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CallTargetApiInterface
     */
    getPreviousPageLastId(lf?: string, sortKey?: string, currentDisplayNumber?: number, currentPage?: number, sortOrder?: string, options?: AxiosRequestConfig): AxiosPromise<GetNextPageFirstId200Response>;

    /**
     * リード重複統合API
     * @summary リード重複統合API
     * @param {MergeCallTargetsBody} [mergeCallTargetsBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CallTargetApiInterface
     */
    mergeCallTargets(mergeCallTargetsBody?: MergeCallTargetsBody, options?: AxiosRequestConfig): AxiosPromise<CreateTask200Response>;

}

/**
 * Request parameters for callTargetSfSync operation in CallTargetApi.
 * @export
 * @interface CallTargetApiCallTargetSfSyncRequest
 */
export interface CallTargetApiCallTargetSfSyncRequest {
    /**
     * リードID
     * @type {number}
     * @memberof CallTargetApiCallTargetSfSync
     */
    readonly callTargetId: number
}

/**
 * Request parameters for getCallTarget operation in CallTargetApi.
 * @export
 * @interface CallTargetApiGetCallTargetRequest
 */
export interface CallTargetApiGetCallTargetRequest {
    /**
     * リードID
     * @type {number}
     * @memberof CallTargetApiGetCallTarget
     */
    readonly callTargetId: number
}

/**
 * Request parameters for getMailRemindItems operation in CallTargetApi.
 * @export
 * @interface CallTargetApiGetMailRemindItemsRequest
 */
export interface CallTargetApiGetMailRemindItemsRequest {
    /**
     * ユーザーID
     * @type {number}
     * @memberof CallTargetApiGetMailRemindItems
     */
    readonly userId?: number

    /**
     * 下書き
     * @type {boolean}
     * @memberof CallTargetApiGetMailRemindItems
     */
    readonly draft?: boolean

    /**
     * 配信予約
     * @type {boolean}
     * @memberof CallTargetApiGetMailRemindItems
     */
    readonly reserved?: boolean
}

/**
 * Request parameters for getNextPageFirstId operation in CallTargetApi.
 * @export
 * @interface CallTargetApiGetNextPageFirstIdRequest
 */
export interface CallTargetApiGetNextPageFirstIdRequest {
    /**
     * リードリストのフィルターパラメータ
     * @type {string}
     * @memberof CallTargetApiGetNextPageFirstId
     */
    readonly lf?: string

    /**
     * リードリストのソートキー
     * @type {string}
     * @memberof CallTargetApiGetNextPageFirstId
     */
    readonly sortKey?: string

    /**
     * リードリストで表示するリード数
     * @type {number}
     * @memberof CallTargetApiGetNextPageFirstId
     */
    readonly currentDisplayNumber?: number

    /**
     * リードリストでの現在のページネーション
     * @type {number}
     * @memberof CallTargetApiGetNextPageFirstId
     */
    readonly currentPage?: number

    /**
     * リードリストのソート順
     * @type {string}
     * @memberof CallTargetApiGetNextPageFirstId
     */
    readonly sortOrder?: string
}

/**
 * Request parameters for getPreviousPageLastId operation in CallTargetApi.
 * @export
 * @interface CallTargetApiGetPreviousPageLastIdRequest
 */
export interface CallTargetApiGetPreviousPageLastIdRequest {
    /**
     * リードリストのフィルターパラメータ
     * @type {string}
     * @memberof CallTargetApiGetPreviousPageLastId
     */
    readonly lf?: string

    /**
     * リードリストのソートキー
     * @type {string}
     * @memberof CallTargetApiGetPreviousPageLastId
     */
    readonly sortKey?: string

    /**
     * リードリストで表示するリード数
     * @type {number}
     * @memberof CallTargetApiGetPreviousPageLastId
     */
    readonly currentDisplayNumber?: number

    /**
     * リードリストでの現在のページネーション
     * @type {number}
     * @memberof CallTargetApiGetPreviousPageLastId
     */
    readonly currentPage?: number

    /**
     * リードリストのソート順
     * @type {string}
     * @memberof CallTargetApiGetPreviousPageLastId
     */
    readonly sortOrder?: string
}

/**
 * Request parameters for mergeCallTargets operation in CallTargetApi.
 * @export
 * @interface CallTargetApiMergeCallTargetsRequest
 */
export interface CallTargetApiMergeCallTargetsRequest {
    /**
     * 
     * @type {MergeCallTargetsBody}
     * @memberof CallTargetApiMergeCallTargets
     */
    readonly mergeCallTargetsBody?: MergeCallTargetsBody
}

/**
 * CallTargetApi - object-oriented interface
 * @export
 * @class CallTargetApi
 * @extends {BaseAPI}
 */
export class CallTargetApi extends BaseAPI implements CallTargetApiInterface {
    /**
     * 単一CallTargetのsf同期API
     * @summary 単一CallTargetのsf同期
     * @param {CallTargetApiCallTargetSfSyncRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CallTargetApi
     */
    public callTargetSfSync(requestParameters: CallTargetApiCallTargetSfSyncRequest, options?: AxiosRequestConfig) {
        return CallTargetApiFp(this.configuration).callTargetSfSync(requestParameters.callTargetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 単体リード情報取得API
     * @summary 単体リード情報取得API
     * @param {CallTargetApiGetCallTargetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CallTargetApi
     */
    public getCallTarget(requestParameters: CallTargetApiGetCallTargetRequest, options?: AxiosRequestConfig) {
        return CallTargetApiFp(this.configuration).getCallTarget(requestParameters.callTargetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * メールリマインド情報取得API
     * @summary メールリマインド情報取得API
     * @param {CallTargetApiGetMailRemindItemsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CallTargetApi
     */
    public getMailRemindItems(requestParameters: CallTargetApiGetMailRemindItemsRequest = {}, options?: AxiosRequestConfig) {
        return CallTargetApiFp(this.configuration).getMailRemindItems(requestParameters.userId, requestParameters.draft, requestParameters.reserved, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 「前へ」で使うID取得処理API
     * @summary 「前へ」で使うID取得処理API
     * @param {CallTargetApiGetNextPageFirstIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CallTargetApi
     */
    public getNextPageFirstId(requestParameters: CallTargetApiGetNextPageFirstIdRequest = {}, options?: AxiosRequestConfig) {
        return CallTargetApiFp(this.configuration).getNextPageFirstId(requestParameters.lf, requestParameters.sortKey, requestParameters.currentDisplayNumber, requestParameters.currentPage, requestParameters.sortOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 「次へ」で使うID取得処理API
     * @summary 「次へ」で使うID取得処理API
     * @param {CallTargetApiGetPreviousPageLastIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CallTargetApi
     */
    public getPreviousPageLastId(requestParameters: CallTargetApiGetPreviousPageLastIdRequest = {}, options?: AxiosRequestConfig) {
        return CallTargetApiFp(this.configuration).getPreviousPageLastId(requestParameters.lf, requestParameters.sortKey, requestParameters.currentDisplayNumber, requestParameters.currentPage, requestParameters.sortOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リード重複統合API
     * @summary リード重複統合API
     * @param {CallTargetApiMergeCallTargetsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CallTargetApi
     */
    public mergeCallTargets(requestParameters: CallTargetApiMergeCallTargetsRequest = {}, options?: AxiosRequestConfig) {
        return CallTargetApiFp(this.configuration).mergeCallTargets(requestParameters.mergeCallTargetsBody, options).then((request) => request(this.axios, this.basePath));
    }
}
