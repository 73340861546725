import { AxiosResponse } from 'axios';
import { ActionTree } from 'vuex';
import { ApiArguments } from '@/api/api_service';
import { GetCallTarget200Response, CallTargetApiGetCallTargetRequest } from '@/api/openapi/index';
import { CallTargetApiService } from '@/api/user/resources/call_target';
import { StoreDataFetchManager } from '@/store/store-data-fetch-manager';
import { RootState, UserState } from '@/store/types';
import { types } from '@/store/user/mutations';
import Waiting from '@/store/waiting';

function leadListFilterParams() {
  return 'userUi/leadListFilterParams';
}
function leadListPageParams() {
  return 'userUi/leadListPageParams';
}
function leadListSortKey() {
  return 'userUi/leadListSortKey';
}
function leadListSortOrder() {
  return 'userUi/leadListSortOrder';
}

export const actions: ActionTree<UserState, RootState> = {
  getCallTargetAction: async (
    { commit },
    apiArguments: ApiArguments<CallTargetApiGetCallTargetRequest>,
  ) => {
    await waiting.checkWithArg('getCallTargetAction', apiArguments, async () => {
      try {
        const res = await storeDataFetchManager.fetch<AxiosResponse<GetCallTarget200Response>>('getLeadHistoriesAction', async () => {
          const api = new CallTargetApiService();
          return await api.getCallTarget(apiArguments);
        });
        commit(types.SET_CALL_TARGET, res.data.callTarget);
      } catch (err) {
        commit(types.CLEAR_CALL_TARGET);
        throw err;
      }
    });
    return true;
  },
};

const waiting = new Waiting(actions);
const storeDataFetchManager = new StoreDataFetchManager();

export default class CallTargetActions {
  actions: any;
  constructor(Api, types) {
    const actions = {
      async getCallTargetsAction({ commit, rootGetters, dispatch }, args: any = {}) {
        try {
          args.params = Object.assign(args.params || {}, {
            lf: rootGetters[leadListFilterParams()],
            ...rootGetters[leadListPageParams()],
            sortKey: rootGetters[leadListSortKey()],
            sortOrder: rootGetters[leadListSortOrder()],
          });
          const res = await Api.getCallTargets(args);
          commit(types.SET_CALL_TARGETS, res.data.callTargets);
          await dispatch('getAssignedUserCandidatesAction');
        } catch (err) {
          commit(types.CLEAR_CALL_TARGETS);
          throw err;
        }
      },
      async getCallTargetsMetaAction(
        { commit, rootGetters, dispatch },
        args: any = {},
      ) {
        try {
          args.params = Object.assign(args.params || {}, {
            lf: rootGetters[leadListFilterParams()],
            ...rootGetters[leadListPageParams()],
            sortKey: rootGetters[leadListSortKey()],
            sortOrder: rootGetters[leadListSortOrder()],
          });
          const res = await Api.getCallTargetsMeta(args);
          commit(types.SET_CALL_TARGETS_META, res.data);
        } catch (err) {
          commit(types.CLEAR_CALL_TARGETS_META);
          throw err;
        }
      },
      async clearCallTargetAction({ commit }, args) {
        await waiting.check('clearCallTargetAction', () => {
          commit(types.CLEAR_CALL_TARGET);
        });
      },
      async createCallTargetAction({ commit, rootGetters }, args: any = {}) {
        await waiting.check('createCallTargetAction', async () => {
          await Api.createCallTarget(args);
          try {
            args.params = Object.assign(args.params || {}, {
              lf: rootGetters[leadListFilterParams()],
              ...rootGetters[leadListPageParams()],
            });
            const res = await Api.getCallTargets(args);
            commit(types.SET_CALL_TARGETS, Object.values(res.data)[0]);
          } catch (err) {
            throw err;
          }
        });
      },
      async updateCallTargetAction({ commit, rootGetters }, args: any = {}) {
        await waiting.check('updateCallTargetAction', async () => {
          await Api.updateCallTarget(args);
          // NOTE: 他と異なり単体のリロード
          try {
            args.params = Object.assign(args.params || {}, {
              lf: rootGetters[leadListFilterParams()],
              ...rootGetters[leadListPageParams()],
            });
            const { id, options, errorHandlers } = args;
            const request = { callTargetId: id };
            const api = new CallTargetApiService();
            const res = await api.getCallTarget({ request, options, errorHandlers });
            commit(types.SET_CALL_TARGET, res.data.callTarget);
          } catch (err) {
            throw err;
          }
        });
      },
      async deleteCallTargetAction({ commit, rootGetters }, args: any = {}) {
        await waiting.check('deleteCallTargetAction', async () => {
          await Api.deleteCallTarget(args);
          try {
            args.params = Object.assign(args.params || {}, {
              lf: rootGetters[leadListFilterParams()],
              ...rootGetters[leadListPageParams()],
            });
            const res = await Api.getCallTargets(args);
            commit(types.SET_CALL_TARGETS, Object.values(res.data)[0]);
          } catch (err) {
            throw err;
          }
        });
      },
      async duplicateCallTargetAction({ commit }, args = {}) {
        return await waiting.check('duplicateCallTargetAction', async () => {
          return await Api.duplicateCallTarget(args);
        });
      },
      async assignOperatorAction({ commit }, args) {
        await waiting.check('assignOperatorAction', async () => {
          await Api.assignOperator(args);
        });
      },
      async withdrawOperatorsAction({ commit }, args) {
        await waiting.check('withdrawOperatorsAction', async () => {
          await Api.withdrawOperators(args);
        });
      },
      async fulltextSearchAction({ commit }, args) {
        await waiting.check('fulltextSearchAction', async () => {
          try {
            const res = await Api.fulltextSearch(args);
            commit(
              types.SET_FULLTEXT_SEARCH_RESULT,
              Object.values(res.data)[0],
            );
          } catch (err) {
            commit(types.CLEAR_FULLTEXT_SEARCH_RESULT);
            throw err;
          }
        });
      },
      async clearFulltextSearchAction({ commit }, args) {
        await waiting.check('clearFulltextSearchAction', () => {
          commit(types.CLEAR_FULLTEXT_SEARCH_RESULT);
        });
      },
      async updateCustomFieldValueAction({ commit, rootGetters }, args: any = {}) {
        await waiting.check('updateCustomFieldValueAction', async () => {
          await Api.updateCustomFieldValue(args);
          try {
            args.params = Object.assign(args.params || {}, {
              lf: rootGetters[leadListFilterParams()],
              ...rootGetters[leadListPageParams()],
            });
            const { id, options, errorHandlers } = args;
            const request = { callTargetId: id };
            const api = new CallTargetApiService();
            const res = await api.getCallTarget({ request, options, errorHandlers });
            commit(types.SET_CALL_TARGET, res.data.callTarget);
          } catch (err) {
            throw err;
          }
        });
      },
      async getLeadHistoriesAction({ commit }, args) {
        await waiting.checkWithArg('getLeadHistoriesAction', args, async () => {
          try {
            const res = await storeDataFetchManager.fetch<any>('getLeadHistoriesAction', async () => await Api.getLeadHistories(args));
            commit(types.SET_LEAD_HISTORIES, Object.values(res.data)[0]);
          } catch (err) {
            commit(types.CLEAR_LEAD_HISTORIES);
            throw err;
          }
        });
      },
      async getBusinessCategoryCandidatesAction({ commit }, args) {
        await waiting.check('getBusinessCategoryCandidatesAction', async () => {
          try {
            const res = await Api.getBusinessCategoryCandidates(args);
            commit(
              types.SET_BUSINESS_CATEGORY_CANDIDATES,
              Object.values(res.data)[0],
            );
          } catch (err) {
            commit(types.CLEAR_BUSIMESS_CATEGORY_CANDIDATES);
            throw err;
          }
        });
      },
      async getPositionCandidatesAction({ commit }, args) {
        await waiting.check('getPositionCandidatesAction', async () => {
          try {
            const res = await Api.getPositionCandidates(args);
            commit(types.SET_POSITION_CANDIDATES, Object.values(res.data)[0]);
          } catch (err) {
            commit(types.CLEAR_POSITION_CANDIDATES);
            throw err;
          }
        });
      },
      async getDepartmentCandidatesAction({ commit }, args) {
        await waiting.check('getDepartmentCandidatesAction', async () => {
          try {
            const res = await Api.getDepartmentCandidates(args);
            commit(types.SET_DEPARTMENT_CANDIDATES, Object.values(res.data)[0]);
          } catch (err) {
            commit(types.CLEAR_DEPARTMENT_CANDIDATES);
            throw err;
          }
        });
      },
      async getAssignedUserCandidatesAction({ commit }) {
        await waiting.check('getAssignedUserCandidatesAction', async () => {
          try {
            const res = await Api.getAssignedUserCandidates();
            commit(
              types.SET_ASSIGNED_USER_CANDIDATES,
              Object.values(res.data)[0],
            );
          } catch (err) {
            commit(types.CLEAR_ASSIGNED_USER_CANDIDATES);
            throw err;
          }
        });
      },
      async getCallTargetsCountAction({ commit }, args = {}) {
        try {
          const res = await Api.getCallTargetsCount(args);
          commit(types.SET_CALL_TARGETS_COUNT, res.data.callTargetsCount);
        } catch (err) {
          commit(types.CLEAR_CALL_TARGETS_COUNT);
          throw err;
        }
      },
      async deleteAllCallTargetsAction({ commit, rootGetters }, args: any = {}) {
        await waiting.check('deleteAllCallTargetsAction', async () => {
          await Api.deleteAllCallTargets(args);
          try {
            args.params = Object.assign(args.params || {}, {
              lf: rootGetters[leadListFilterParams()],
              ...rootGetters[leadListPageParams()],
            });
            const res = await Api.getCallTargets(args);
            commit(types.SET_CALL_TARGETS, Object.values(res.data)[0]);
          } catch (err) {
            throw err;
          }
        });
      },
    };
    const waiting = new Waiting(actions);
    const storeDataFetchManager = new StoreDataFetchManager();
    this.actions = actions;
  }
}
