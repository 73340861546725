<template>
  <CommonTask
    :content="nextAction.content"
    :owner="nextAction.owner"
    :title="nextAction.name"
    :date-time="nextAction.reservedAt"
    :is-sequence="isSequenceAction"
    :is-deletable="isDeletable"
    :is-content-editable="isContentEditable"
    :link-to-text="linkToText"
    @click:delete="handleDelete"
    @click:editor="handleClickEditor"
    @update:content="handleUpdateContent"
  >
    <template #action>
      <BBtn
        size="mini"
        fab
        @click.stop="handleDone"
      >
        <BIcon size="mini">
          check
        </BIcon>
      </BBtn>
    </template>
    <template #status>
      <BPriorityPreview
        :priority="nextAction.priority"
        size="micro"
      />
    </template>
  </CommonTask>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { NextAction } from '@/api/openapi';
import { useNextActionComputed } from '@/composable/user/nextAction/next-action';
import CommonTask from './CommonTask.vue';

type TProps = {
  nextAction: NextAction;
};
type TEmit = {
  'click:done': [nextAction: NextAction];
  'click:delete': [nextAction: NextAction];
  'update': [nextAction: NextAction];
  'click:editor': [];
};

const props = defineProps<TProps>();
const emit = defineEmits<TEmit>();

const i18n = useI18n();
const { isSequenceAction } = useNextActionComputed(props.nextAction);

const isDeletable = computed(() => !isSequenceAction.value);
const isContentEditable = computed(() => !isSequenceAction.value);
const linkToText = computed(() => isSequenceAction.value ? i18n.t('general.confirm.do') : i18n.t('general.edit.do'));

const handleDone = () => {
  emit('click:done', props.nextAction);
};
const handleDelete = () => {
  emit('click:delete', props.nextAction);
};
const handleUpdateContent = (value: string) => {
  emit('update', {
    ...props.nextAction,
    content: value,
  });
};
const handleClickEditor = () => {
  emit('click:editor');
};
</script>
