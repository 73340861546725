<template>
  <BCard
    class="assign-operator-control"
    :class="classes"
  >
    <BContainer full>
      <BLayout
        align-center
        justify-center
      >
        <div class="mr-300">
          {{ $t(`operatorAssign.selected`) }}<span class="fs-500 fw-bold mr-100 ml-100">{{ selectedCallTargetIds.length }}</span>{{ $t(`operatorAssign.matter`) }}
        </div>
        <BSelect
          v-model="selectedOperatorId"
          class="mr-400 operator-assign-select"
          :items="filteredActiveUsers"
          item-text="name"
          item-value="id"
          :placeholder="$t('operatorAssign.select')"
          filterable
        />
        <BBtn
          type="primary"
          :loading="isLoading"
          :disabled="isAssignDisabled || isLoading"
          @click="submitAssignOperator"
        >
          {{ $t(`operatorAssign.assign`) }}
        </BBtn>
      </BLayout>
    </BContainer>
  </BCard>
</template>

<script>
import { mapWaitingActions } from 'vue-wait';
import { mapGetters } from 'vuex';
import { useUsers } from '@/composable/user/user/users';

export default {
  props: {
    callTargets: {
      type: Array,
      default () {
        return [];
      },
    },
    selectedCallTargetIds: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  setup () {
    const { activeUsers } = useUsers();
    return {
      activeUsers,
    };
  },
  data () {
    return {
      selectedOperatorId: null,
    };
  },
  computed: {
    ...mapGetters('user', [
      'users',
    ]),
    activeCallTargetIds () {
      return this.callTargets.map(callTarget => callTarget.callTargetId);
    },
    isAssignDisabled () {
      return this.selectedOperatorId == null;
    },
    isWithdrawDisabled () {
      return this.selectedCallTargetIds.length === 0;
    },
    filteredActiveUsers () {
      const addData = [
        {
          name: this.$t('operatorAssign.unassigned'),
          id: 0,
        },
      ];
      return addData.concat(this.activeUsers);
    },
    classes () {
      return {
        'footer-open': this.selectedCallTargetIds.length > 0,
      };
    },
    isLoading () {
      return this.$wait.is(['assignOperatorWait', 'withdrawOperatorsWait']);
    },
  },
  methods: {
    ...mapWaitingActions('user', {
      assignOperatorAction: 'assignOperatorWait',
      withdrawOperatorsAction: 'withdrawOperatorsWait',
      getCallTargetsBackgroundAction: { action: 'getCallTargetsAction', loader: 'getCallTargetsBackgroundWait' },
    }),
    submitAssignOperator () {
      if (this.selectedOperatorId !== 0) {
        this.assignOperator();
      } else {
        this.withdrawOperators();
      }
    },
    async assignOperator () {
      try {
        await this.assignOperatorAction({
          body: {
            operatorId: this.selectedOperatorId,
            callTargetIds: this.selectedCallTargetIds,
          },
        });
        this.$toast.show(this.$t('general.done.of', { action: this.$t('operatorAssign.of', { item: this.$t('home.member') }) }));
      } catch (e) {
        this.$toast.show({
          message: this.$t('general.fail.to', { action: this.$t('operatorAssign.assign') }),
          type: 'error',
        });
      }
      this.getCallList();
    },
    async withdrawOperators () {
      try {
        await this.withdrawOperatorsAction({ body: { callTargetIds: this.selectedCallTargetIds } });
        this.$toast.show(this.$t('general.done.of', { action: `${this.$t('operatorAssign.assign')}${this.$t('general.withdraw.text')}` }));
      } catch (e) {
        this.$toast.show({
          message: this.$t('general.fail.to', { action: `${this.$t('operatorAssign.assign')}${this.$t('general.withdraw.text')}` }),
          type: 'error',
        });
      }
      this.getCallList();
    },
    async getCallList () {
      // UX改善のため、データ再読込時に全体ローディングしないようになっている
      // organismsでの処理は、必ず初期データがある前提なので、backgroundのみにしている
      // TODO: データ更新のタイミングなど戦略を決める
      this.$toast.show({ message: this.$t('callList.update.loading'), type: 'info' });
      await this.getCallTargetsBackgroundAction();
      this.$toast.show({ message: this.$t('callList.update.done'), type: 'success' });
    },
  },
};
</script>

<style lang="scss" scoped>
  .assign-operator-control.card {
    display: none;
    box-shadow: $boxshadow-footer;
    padding: $basespace-300 0;

    .operator-assign-select {
      width: 20rem;
    }

    &.footer-open {
      display: block;
    }
  }
</style>
