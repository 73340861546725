import { AxiosResponse } from 'axios';
import ApiService, { ApiArguments } from '@/api/api_service';
import { CustomFieldDestroyTask, CustomFieldDestroyTaskApi, CustomFieldDestroyTaskApiPutCustomFieldDestroyTaskRequest } from '@/api/openapi';

export class CustomFieldDestroyTaskApiService extends ApiService {
  api = new CustomFieldDestroyTaskApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true);
  }

  async getCustomFieldDestroyTasks({
    options,
    errorHandlers,
  }: ApiArguments<undefined>): Promise<AxiosResponse<CustomFieldDestroyTask[]>> {
    return this.checkResponse<CustomFieldDestroyTask[]>(
      this.api.getCustomFieldDestroyTasks(options),
      errorHandlers,
    );
  }

  async confirmCustomFieldDestroyTask({
    request,
    options,
    errorHandlers,
  }: ApiArguments<CustomFieldDestroyTaskApiPutCustomFieldDestroyTaskRequest>): Promise<AxiosResponse<void>> {
    return this.checkResponse<void>(
      this.api.putCustomFieldDestroyTask(request, options),
      errorHandlers,
    );
  }
}
