<template>
  <div
    v-loading="$wait.is('confirmLeadMergeTasksWait')"
    class="lead-merge-status"
  >
    <ImportStatus
      v-for="task in leadMergeTasks"
      :key="task.id"
      :target-task="task"
      :can-show-error-modal="false"
      @confirm="() => confirmTask(task.id)"
      @success="$emit('success')"
    >
      <template #progressMessage>
        {{ $t('duplicatedList.status.progressMessage1') }}
        <LeadLink
          :call-target-id="task.callTargetId"
          tag="span"
        >
          <BBtn
            type="warning"
            class="link-to-call-target b-btn-progress"
            btn-type="button"
            outline
          >
            {{ formatCallTargetName(task) }}
          </BBtn>
        </LeadLink>
        {{ $t('duplicatedList.status.progressMessage2') }}
      </template>
      <template #errorMessage>
        {{ makeCreateDateMessage(task) }}
        {{ $t('duplicatedList.status.errorMessage1') }}
        <LeadLink
          :call-target-id="task.callTargetId"
          tag="span"
        >
          <BBtn
            type="error"
            class="link-to-call-target"
            btn-type="button"
            outline
          >
            {{ formatCallTargetName(task) }}
          </BBtn>
        </LeadLink>
        {{ $t('duplicatedList.status.errorMessage2') }}
      </template>
      <template #successMessage>
        {{ makeCreateDateMessage(task) }}
        {{ $t('duplicatedList.status.successMessage1') }}
        <LeadLink
          :call-target-id="task.callTargetId"
          tag="span"
        >
          <BBtn
            type="success"
            class="link-to-call-target"
            btn-type="button"
            outline
          >
            {{ formatCallTargetName(task) }}
          </BBtn>
        </LeadLink>
        {{ $t('duplicatedList.status.successMessage2') }}
      </template>
    </ImportStatus>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapWaitingActions } from 'vue-wait';
import { mapGetters } from 'vuex';
import { LeadMergeTask } from '@/api/openapi';
import { LeadMergeTaskApiService } from '@/api/user/resources/lead_merge_task';
// NOTE: importではないので気になるところだが...
import LeadLink from '@/components/organisms/user/general/LeadLink.vue';
import ImportStatus, { makeCreateDateMessage } from '@/components/organisms/user/leadImport/ImportStatus.vue';

type TData = {
  timeObj: typeof setTimeout | null;
  leadMergeTasks: LeadMergeTask[];
}

export default defineComponent({
  components: {
    ImportStatus,
    LeadLink,
  },
  emits: [
    'success',
  ],
  data(): TData {
    return {
      timeObj: null,
      leadMergeTasks: [],
    };
  },
  computed: {
    ...mapGetters('userUi', ['doReloadLeadMergeStatus']),
    apiService(): LeadMergeTaskApiService {
      return new LeadMergeTaskApiService();
    },
  },
  watch: {
    doReloadLeadMergeStatus(newVal: boolean): void {
      if (newVal) {
        this.loadLeadMergeTasks();
        this.setDoReloadLeadMergeStatusAction(false);
      }
    },
  },
  created() {
    this.loadLeadMergeTasks();
    this.timeObj = this.$setInterval(() => {
      this.loadLeadMergeTasks();
    }, 30000);
  },
  beforeUnmount() {
    this.$clearInterval(this.timeObj);
  },
  methods: {
    ...mapWaitingActions('userUi', {
      setDoReloadLeadMergeStatusAction: 'setDoReloadLeadMergeStatusActionWait',
    }),
    async loadLeadMergeTasks(): Promise<void> {
      const { data } = await this.apiService.getLeadMergeTasks({});
      const loadedLeadMergeTasks = data.leadMergeTasks ?? [];
      // NOTE: 完了しているものがあれば、通知を出す
      const hasFinishedTask = this.leadMergeTasks.some(task =>
        !task.finishedAt
        && !!loadedLeadMergeTasks.find(lTask => lTask.id === task.id)?.finishedAt,
      );
      if (hasFinishedTask) {
        this.$toast.show({
          message: this.$t('duplicatedList.status.finishedMessage'),
          type: 'success',
        });
      }
      this.leadMergeTasks = loadedLeadMergeTasks;
    },
    async confirmTask(leadMergeTaskId: number): Promise<void> {
      this.$wait.start('confirmLeadMergeTasksWait');
      await this.apiService.putLeadMergeTask({ request: { leadMergeTaskId } });
      await this.loadLeadMergeTasks();
      this.$wait.end('confirmLeadMergeTasksWait');
    },
    makeCreateDateMessage(leadMergeTask: LeadMergeTask): string {
      return makeCreateDateMessage(leadMergeTask);
    },
    formatCallTargetName(leadMergeTask: LeadMergeTask): string {
      return leadMergeTask.callTargetName || '-';
    },
  },
});
</script>

<style scoped lang="scss">
  .import-status:nth-last-child(n + 2) {
    border-bottom: 1px solid $concrete-light;
  }

  :deep(.b-btn.link-to-call-target) {
    height: 32px;
    line-height: 32px;
    padding-right: 16px;
    padding-left: 16px;
  }

  :deep(.b-btn-warning.b-btn-progress) {
    color: $textcolor-base;
    // NOTE: 視差の調整のためボーダー分大きくしている
    border-color: $bgcolor-white;

    &.is-outline {
      color: $textcolor-base;
      i {
        color: $textcolor-base;
      }

      &:not(.is-disabled):not(.is-loading):hover {
        color: $textcolor-base;
        border-color: $basecolor-warning;
        i {
          color: $textcolor-base;
        }
      }
    }

    &.is-text {
      color: $textcolor-base;
    }
  }
</style>
