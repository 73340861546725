<template>
  <div class="sequence-step-with-connector-container">
    <BTooltip
      bottom-end
      :show-arrow="false"
      :content="$t('sequence.step.skippedStep')"
      :disabled="!isSkipped"
    >
      <BTodoCard
        v-model:is-menu-open="internalIsMenuOpen"
        class="step-card"
        :class="{ 'disabled': isSkipped, 'current-step': isRunning, 'failed': isFailed, 'sending': isSending }"
        :is-done="isDone"
        :title="title"
        :content="content"
        :actions="actions"
        :expandable-html-content="expandableHtmlContent"
        :icon="icon"
        v-on="cardHandlers"
      >
        <template #sub-content>
          <template v-if="mailAggregates != null">
            <div class="step-aggregates">
              <StepAggregate
                :title="$t('sequence.mailAggregates.all')"
                :count="mailAggregates.numberOfAll"
              />
              <StepAggregate
                :title="$t('sequence.mailAggregates.sent')"
                :count="mailAggregates.numberOfSent"
                :rate="mailAggregates.sentPerAll"
              />
              <StepAggregate
                :title="$t('sequence.mailAggregates.opened')"
                :count="mailAggregates.numberOfOpened"
                :rate="mailAggregates.openedPerSent"
              />
              <StepAggregate
                :title="$t('sequence.mailAggregates.clicked')"
                :count="mailAggregates.numberOfClicked"
                :rate="mailAggregates.clickedPerSent"
              />
              <StepAggregate
                :title="$t('sequence.mailAggregates.replied')"
                :count="mailAggregates.numberOfReplied"
                :rate="mailAggregates.repliedPerSent"
              />
              <StepAggregate
                :title="$t('sequence.mailAggregates.failed')"
                :count="mailAggregates.numberOfFailed"
                :rate="mailAggregates.failedPerAll"
              />
            </div>
          </template>
          <template v-if="callAggregates != null">
            <div class="step-aggregates">
              <StepAggregate
                :title="$t('sequence.callAggregates.all')"
                :count="callAggregates.numberOfAll"
              />
              <StepAggregate
                :title="$t('sequence.callAggregates.connected')"
                :count="callAggregates.numberOfConnected"
                :rate="callAggregates.connectedPerAll"
              />
              <StepAggregate
                :title="$t('sequence.callAggregates.assignedConnected')"
                :count="callAggregates.numberOfAssignedConnected"
                :rate="callAggregates.assignedConnectedPerAll"
              />
              <StepAggregate
                :title="$t('sequence.callAggregates.hearingSuccess')"
                :count="callAggregates.numberOfHearingSuccess"
                :rate="callAggregates.hearingSuccessPerAll"
              />
            </div>
          </template>
        </template>
      </BTodoCard>
    </BTooltip>
    <BConnector
      :menus="connectorMenus"
      :add-button-position="addButtonPosition"
      :disabled-add-button="disabledAddButton"
      :add-button-disabled-tooltip="$t('sequence.step.limitMessage')"
      @click:add-button="handleAddClick"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { NextActionTypeOrDefaultType } from '@/api/user/models/next_action_types';
import { getDisplayContent, getDisplaySubContent, isCallActionType, isDirectMailActionType, isDirectMailStepExecuted } from '@/composable/user/sequence/post-put-body';
import { getCallAggregates, getMailAggregates } from '@/composable/user/sequence/sequence-step-masters';
import { useSequenceStepComputedProperty } from '@/composable/user/sequence/step-computed-property';
import { TMenu } from '@/plugins/biscuet-materials/components/molecules/connector';
import StepAggregate from './StepAggregate.vue';
import type { TActionWithDisabled, TFormSequenceStep, TStepEventType, TStepType } from './types';

type TProps = {
  sequenceStep: TFormSequenceStep;
  needsAddButton: boolean;
  isMenuOpen: boolean;
  disabledAddButton: boolean;
  nextActionType?: NextActionTypeOrDefaultType;
  actions: TActionWithDisabled[];
  connectorMenus: TMenu<TStepType>;
};

type TEmits = {
  (event: 'click:insertNextAction'): void;
  (event: 'click:insertCallAction'): void;
  (event: 'click:insertDirectMail'): void;
  (event: TStepEventType): void;
  (event: 'update:isMenuOpen', value: boolean): void;
};

const props = withDefaults(defineProps<TProps>(), {
  nextActionType: undefined,
});
const emit = defineEmits<TEmits>();

const addButtonPosition = computed(() => props.needsAddButton ? 'middle' : 'none');
const expandableHtmlContent = computed(() => getDisplaySubContent(props.sequenceStep));

const { title, isSkipped } = useSequenceStepComputedProperty(() => props.sequenceStep);
const content = computed(() => getDisplayContent(props.sequenceStep));
const icon = computed(() => {
  if (isCallActionType(props.sequenceStep.actionType)) return 'b-call-call';
  if (isDirectMailActionType(props.sequenceStep.actionType)) return 'b-mail';
  return 'b-next-action';
});

const mailAggregates = computed(() => getMailAggregates(props.sequenceStep));
const callAggregates = computed(() => getCallAggregates(props.sequenceStep));

const internalIsMenuOpen = computed({
  get() {
    return props.isMenuOpen;
  },
  set(newValue) {
    emit('update:isMenuOpen', newValue);
  },
});
const isRunning = computed(() => props.sequenceStep.status === 'running');
const isFailed = computed(() => props.sequenceStep.status === 'failed');
const isSending = computed(() => isRunning.value && isDirectMailStepExecuted(props.sequenceStep));
const isDone = computed(() => props.sequenceStep.status === 'done');

// NOTE: 任意のイベントをhandle＆発火
const cardHandlers = computed(() =>
  Object.fromEntries(
    props.actions
      .filter(({ disabled }) => !disabled)
      .map(({ event }) => [event, () => emit(event)]),
  ),
);

const handleAddClick = (stepType: TStepType) => {
  if (props.disabledAddButton) return;
  switch (stepType) {
    case 'direct_mail':
      emit('click:insertDirectMail');
      break;
    case 'call_action':
      emit('click:insertCallAction');
      break;
    case 'next_action':
      emit('click:insertNextAction');
      break;
  }
};
</script>

<style lang="scss" scoped>
$disabled-opacity: 0.4;
.sequence-step-with-connector-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .step-card {
    width: 100%;

    .step-aggregates {
      display: flex;
      flex-wrap: nowrap;
      padding-right: $basespace-100;

      .step-aggregate {
        padding-right: $basespace-50;
        :deep(.step-aggregate-name) {
          background: $bgcolor-dark;
          border-radius: 4px;
          border: 1px solid transparent;
          font-size: $fontsize-100;
          color: $textcolor-light;
          word-break: keep-all;
          min-width: 65px;
          padding: 0 $basespace-50;
          text-align: center;
        }
        :deep(.step-aggregate-value) {
          font-size: $fontsize-100;
          text-align: center;
          color: $textcolor-base;
          margin-top: 2px;

          .step-aggregate-rate {
            color: $textcolor-light;
          }
        }
      }
    }
  }
  .disabled {
    opacity: $disabled-opacity;
  }
  .card-border {
    &.current-step {
      border-color: $basecolor-accent;
    }
    &.sending {
      border-color: $bdcolor-warning;
    }
    &.failed {
      border-color: $basecolor-error;
    }
  }
}
</style>
