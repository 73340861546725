import Waiting from '@/store/waiting';

export default class MailVerification {
  constructor(Api, types) {
    const actions = {
      async createMailVerificationAction({ dispatch }, args) {
        await waiting.check('createMailVerificationAction', async () => {
          try {
            await Api.createMailVerification(args);
            await dispatch('getMailVerificationAction', args);
          } catch (err) {
            throw err;
          }
        });
      },
      async getMailVerificationAction({ commit }, args) {
        await waiting.check('getMailVerificationAction', async () => {
          try {
            const res = await Api.getMailVerification(args);
            commit(types.SET_MAIL_VERIFICATIONS, Object.values(res.data)[0]);
          } catch (err) {
            throw err;
          }
        });
      },
      async getMailVerificationBalanceAction({ commit }, args) {
        await waiting.check('getMailVerificationBalanceAction', async () => {
          try {
            const res = await Api.getMailVerificationBalance(args);
            commit(types.SET_MAIL_VERIFICATION_BALANCE, Object.values(res.data)[0].balance);
          } catch (err) {
            throw err;
          }
        });
      },
      async reflectMailVerificationToUnsubscribeAction({ dispatch }, args) {
        await waiting.check('reflectMailVerificationToUnsubscribeAction', async () => {
          try {
            await Api.reflectMailVerificationToUnsubscribe(args);
            await dispatch('getMailVerificationAction', args);
          } catch (err) {
            throw err;
          }
        });
      },
    };
    const waiting = new Waiting(actions);
    this.actions = actions;
  }
}
