import { nextTick, ref, watch } from 'vue';

// FIXME: Props, Emitsの方も共通化したかったが、これらが現状importした型や交差型を受け付けないのでとりあえずベタ書き
// 3.3系で対応されているようではある。
// @see https://github.com/vuejs/core/issues/4294#issuecomment-1511189577
// type TModalProps<TPartialFormSequenceStep> = {
//   sequenceStep?: TPartialFormSequenceStep;
//   isOpen?: boolean;
// };
// type TModalEmits<TFormSequenceStep> = {
//   (e: 'click:save', sequenceStep: TFormSequenceStep): void;
//   (e: 'click:cancel'): void;
// };

const useStepModal = <TFormSequenceStep>(
  getIsOpen: () => boolean,
  makeFormSequenceStep: () => TFormSequenceStep,
) => {
  const internalIsOpen = ref(getIsOpen());
  const internalSequenceStep = ref<TFormSequenceStep>();

  const resetInternalSequenceStep = (): void => {
    internalSequenceStep.value = makeFormSequenceStep();
  };

  watch(getIsOpen, (newValue) => {
    nextTick(() => {
      resetInternalSequenceStep();
      internalIsOpen.value = newValue;
    });
  });

  return {
    internalIsOpen,
    internalSequenceStep,
    resetInternalSequenceStep,
  };
};

export {
  useStepModal,
};
