import auth from './resources/auth';
import availableFeature from './resources/available_feature';
import boxilLeadImportSetting from './resources/boxil_lead_import_setting';
import bulkMail from './resources/bulk_mail';
import callAnalytics from './resources/call_analytics';
import callList from './resources/call_list';
import callResult from './resources/call_result';
import callResultType from './resources/call_result_type';
import callTarget from './resources/call_target';
import callTargetNote from './resources/call_target_note';
import client from './resources/client';
import client_limit_setting from './resources/client_limit_setting';
import currentUser from './resources/current_user';
import customField from './resources/custom_field';
import fileStorageAccess from './resources/file_storage_access';
import freeAction from './resources/free_action';
import freeTag from './resources/free_tag';
import leadSource from './resources/lead_source';
import leadView from './resources/lead_view';
import mail from './resources/mail';
import mailNotification from './resources/mail_notification';
import miitelAuth from './resources/miitel_auth';
import nextAction from './resources/next_action';
import nextActionType from './resources/next_action_type';
import operator from './resources/operator';
import opportunity from './resources/opportunity';
import revision from './resources/revision';
import rlsCheck from './resources/rls_check';
import salesforceSetting from './resources/salesforce/salesforce_setting';
import sfAccount from './resources/salesforce/sf_account';
import sfContact from './resources/salesforce/sf_contact';
import sfExportObjectField from './resources/salesforce/sf_export_object_field';
import sfLead from './resources/salesforce/sf_lead';
import salesforceOpportunity from './resources/salesforce/sf_opportunity';
import sfRelatedLead from './resources/salesforce/sf_related_lead';
import sfReport from './resources/salesforce/sf_report';
import sfTask from './resources/salesforce/sf_task';
import sfUser from './resources/salesforce/sf_user';
import user from './resources/user';

const apis = Object.assign({},
  currentUser,
  auth,
  user,
  client,
  client_limit_setting,
  freeTag,
  callList,
  customField,
  callTarget,
  operator,
  callTargetNote,
  callResult,
  callResultType,
  nextAction,
  nextActionType,
  miitelAuth,
  opportunity,
  freeAction,
  callAnalytics,
  revision,
  leadSource,
  leadView,
  mail,
  bulkMail,
  availableFeature,
  mailNotification,
  rlsCheck,
  fileStorageAccess,
  salesforceOpportunity,
  salesforceSetting,
  sfAccount,
  sfContact,
  sfUser,
  sfTask,
  sfLead,
  sfRelatedLead,
  boxilLeadImportSetting,
  sfReport,
  sfExportObjectField,
);

export default apis;
