<template>
  <OAuthCallback
    :service-name="$t('oauthCallback.service.salesforce')"
    wait-loader="createSalesforceSettingWait"
    :success="success"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import SalesforceSettingApi from '@/api/user/resources/salesforce/salesforce_setting';
import { useQueryParameter } from '@/composable/query-parameter';
import OAuthCallback from './OAuthCallback.vue';

export default defineComponent({
  components: {
    OAuthCallback,
  },
  setup() {
    const { param: code } = useQueryParameter<string>('code');

    return { code };
  },
  data() {
    return {
      loading: true,
      success: true,
    };
  },
  created() {
    this.handleCreateSetting(this.code);
  },
  methods: {
    async handleCreateSetting(code) {
      await SalesforceSettingApi.createSalesforceSetting({ body: { code, origin: location.host } }).then((result) => {
        this.success = result.data;
      }).catch((error) => {
        console.error(error);
      });
    },
  },
});
</script>
