<template>
  <div class="b-free-tag">
    <BLabel round>
      <BLayout
        class="b-free-tag-text"
        align-center
      >
        <span>{{ tag.text }}</span>
      </BLayout>
      <template
        v-if="update || close"
        #right
      >
        <div
          v-if="update"
          class="icon-update"
          @click="updateFreeTag"
        >
          <BIcon size="small">
            edit
          </BIcon>
        </div>
        <div
          v-else-if="close"
          class="icon-close"
          @click="deleteFreeTag"
        >
          <BIcon size="small">
            close
          </BIcon>
        </div>
      </template>
    </BLabel>
  </div>
</template>

<script>
export default {
  props: {
    tag: {
      type: Object,
      default () {
        return {
          // id: xxx
          // text: xxx
        };
      },
    },
    close: Boolean,
    update: Boolean,
  },
  emits: [
    'delete',
    'update',
  ],
  methods: {
    deleteFreeTag () {
      if (!this.close) { return; }
      this.$emit('delete', this.tag.id);
    },
    updateFreeTag () {
      if (!this.update) { return; }
      this.$emit('update', this.tag.id, this.tag.text);
    },
  },
};
</script>

<style lang="scss" scoped>
  .b-free-tag {
    display: inline-block;
  }

  .icon-tag {
    margin-right: 2px;
  }

  .icon-close {
    cursor: pointer;
    transition: $standard-easing;
    opacity: .5;

    &:hover {
      opacity: 1;
    }
  }
</style>
