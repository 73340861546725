<template>
  <BMenu
    v-model="internalValue"
    :placement="placement"
    :width="width"
    :trigger="trigger"
    :z-index100="zIndex100"
  >
    <template #activator>
      <slot name="activator">
        <BIcon
          hover
          :type="type"
          :size="size"
          @click.prevent=""
        >
          more_vert
        </BIcon>
      </slot>
    </template>
    <div class="menu-items">
      <slot />
    </div>
  </BMenu>
</template>

<script>
export default {
  name: 'BMoreMenu',
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    type: String,
    size: {
      type: String,
      default: 'medium',
    },
    width: [Number, String],
    placement: {
      type: String,
      default: 'bottom-start',
    },
    trigger: {
      type: String,
      default: 'click',
    },
    zIndex100: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:modelValue',
  ],
  computed: {
    internalValue: {
      get() {
        return this.modelValue;
      },
      set(newVal) {
        this.$emit('update:modelValue', newVal);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
  .menu-items {
    background-color: $bgcolor-white;
    min-width: 80px;
    padding: $basespace-100 0;
    border: 1px solid $bdcolor-base;
  }
</style>
