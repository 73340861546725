import { AxiosResponse } from 'axios';
import ApiService, { ApiArguments } from '@/api/api_service';
import { CustomFieldsApi } from '@/api/openapi/apis/custom-fields-api';

export class CustomFieldsApiService extends ApiService {
  api = new CustomFieldsApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true);
  }

  async import({
    options,
    errorHandlers,
  }): Promise<AxiosResponse<void>> {
    return this.checkResponse<void>(
      this.api.customFieldsImport(options),
      errorHandlers,
    );
  }
}
