<template>
  <div v-loading="$wait.is(['getClientSendgridAuthWait', 'deleteClientSendgridAuthWait', 'getClientSendgridApiKeyWait'])">
    <BListItem class="mb-300">
      <template #header>
        <span>{{ $t('general.apiKey') }}</span>
      </template>
      <BInput
        v-model="apiKey"
        disabled
      />
    </BListItem>
    <BListItem class="mb-300">
      <template #header>
        <span>{{ $t('sendgridSetting.domain') }}</span>
      </template>
      <BInput
        v-model="domain"
        disabled
      />
    </BListItem>
    <template v-if="isAdminDeveloper">
      <BDivider class="my-200" />
      <div class="developer-feature">
        {{ $t('sendgridSetting.developerFeature') }}
      </div>
      <BSelect
        v-model="internalSendGridKey.id"
        :items="sendgridApiKeyItems"
        item-text="text"
        item-value="value"
        fit
      />
      <BBtn
        class="mt-700"
        type="primary"
        @click="handleDecisive"
      >
        {{ $t('general.decisive') }}
      </BBtn>

      <BLayout justify-center>
        <BPopover
          v-model="testPopoverOpen"
          placement="right"
          width="520"
          trigger="click"
        >
          <template #reference>
            <BBtn
              class="mr-200"
              type="primary"
              outline
              :disabled="!saveValid || isSaveLoading || testPopoverOpen"
            >
              {{ $t('mail.sendTest') }}
            </BBtn>
          </template>
          <div>
            <BListItem class="list-item mt-300">
              <template #header>
                <div>{{ $t('mail.to_address') }}</div>
              </template>
              <BInput
                v-model="testAddress"
                v-model:valid="testAddressValid"
                :placeholder="$t('mail.mailAddress')"
                :rules="[requiredRule, emailRule]"
                :max-length="255"
              />
            </BListItem>
            <MailContentEditor
              class="mb-600"
              :subject="testSubject"
              :content="testContent"
              :enable-placeholder="false"
              @edit-subject="editSubject"
              @edit-content="editContent"
            />
            <BLayout
              class="mt-500"
              justify-center
            >
              <BBtn
                class="mr-100"
                flat
                size="small"
                :loading="isSendTestLoading"
                @click="testPopoverOpen = false"
              >
                {{ $t('general.cancel.text') }}
              </BBtn>
              <BBtn
                type="primary"
                size="small"
                :loading="isSendTestLoading"
                :disabled="!testAddressValid"
                @click="handleSendTest"
              >
                {{ $t('mail.send') }}
              </BBtn>
            </BLayout>
          </div>
        </BPopover>
        <BBtn
          v-if="isEdit"
          type="primary"
          @click="handleDelete"
        >
          {{ $t('general.delete.text') }}
        </BBtn>
        <BBtn
          v-else
          class="mt-200"
          type="primary"
          :disabled="!saveValid || isSendTestLoading"
          :loading="isSaveLoading"
          @click="handleSave"
        >
          {{ $t('general.save.text') }}
        </BBtn>
      </BLayout>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Api from '@/api/admin';
import MailContentEditor from '@/components/organisms/user/mail/common/drawer/state/form/MailContentEditor.vue';
import { useSendgridSubUser } from '@/composable/admin/sendgrid_sub_users';
import inputValidation from '@/mixins/input_validation';

export default defineComponent({
  components: {
    MailContentEditor,
  },
  mixins: [inputValidation],
  props: {
    sendgridAuth: {
      type: Object,
      require: true,
    },
    sendgridApiKeys: {
      type: Array,
      required: true,
    },
    clientSendgridApiKey: {
      type: Object,
      require: true,
    },
    isAdminDeveloper: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'update:client-sendgrid-api-key',
    'save',
    'decisive',
    'delete',
  ],
  setup(props) {
    const { fetchSendgridSubUser, sendgridSubUser } = useSendgridSubUser(() => props.clientSendgridApiKey?.sendgridSubUserId);

    return {
      fetchSendgridSubUser,
      sendgridSubUser,
    };
  },
  data () {
    return {
      apiKey: '',
      testPopoverOpen: false,
      testAddress: '',
      testAddressValid: false,
      testSubject: '',
      testContent: '',
    };
  },
  computed: {
    internalSendGridKey: {
      get () {
        return this.clientSendgridApiKey;
      },
      set (v) {
        this.$emit('update:client-sendgrid-api-key', v);
      },
    },
    saveValid () {
      return this.apiKey?.length > 0;
    },
    isSaveLoading () {
      return this.$wait.is('updateClientSendgridAuthWait');
    },
    isSendTestLoading () {
      return this.$wait.is('testSendWait');
    },
    isEdit () {
      return this.sendgridAuth.hasOwnProperty('apiKey');
    },
    sendgridApiKeyItems () {
      return this.sendgridApiKeys.map(candidate => {
        return { text: candidate.name, value: candidate.id };
      });
    },
    domain() {
      return this.sendgridSubUser?.domain ?? '';
    },
  },
  watch: {
    sendgridAuth: {
      deep: true,
      handler (newVal) {
        this.apiKey = newVal.apiKey;
      },
    },
    clientSendgridApiKey: {
      deep: true,
      async handler(newVal) {
        if (newVal == null) return;
        await this.fetchSendgridSubUser();
      },
    },
  },
  created () {
    this.initialMailForm();
  },
  methods: {
    editSubject (value) {
      this.testSubject = value;
    },
    editContent (value) {
      this.testContent = value;
    },
    initialMailForm () {
      this.testAddress = '';
      this.testSubject = '【設定テスト】Admin画面メール送信テスト【Biscuet】';
      this.testContent = '<div>[これはBiscuetのシステムから送信されています。]</div><div></div><div>お世話になります、Biscuetです。</div><div></div><div>これはBiscuetのAdmin画面から、SendGridの設定が正しく完了しているかを確認するためのテスト送信メールになります。</div><div></div><div>本メールが届いていれば、対象のアカウントでのSendGrid設定が正常に完了していることを示しています。</div><div></div><div></div><div>※身に覚えがない場合は、本メールは削除してください。</div><div></div><div>----------------------------------------------------------------------</div><div>スマートキャンプ株式会社</div><div>Biscuetシステム</div><div> 連絡先：biscuet@smartcamp.co.jp</div><div> ----------------------------------------------------------------------</div>';
    },
    async handleSave () {
      if (!this.saveValid) return;

      const sendgridAuth = {
        apiKey: this.apiKey,
      };
      this.$emit('save', sendgridAuth);
    },
    async handleDecisive () {
      this.$emit('decisive', this.internalSendGridKey.id);
    },
    async handleSendTest () {
      this.$wait.start('testSendWait');
      try {
        await Api.testSend({ body: { to: this.testAddress, subject: this.testSubject, content: this.testContent, apiKey: this.apiKey } });
        this.$toast.show(this.$t('mail.testMailSendText'));
        this.initialMailForm();
        this.testPopoverOpen = false;
      } finally {
        this.$wait.end('testSendWait');
      }
    },
    async handleDelete () {
      const check = await this.$bitterAlert.show({
        title: this.$t('general.confirm.text'),
        text: this.$t('general.confirm.of', { action: this.$t('general.delete.text') }),
      });
      if (!check) { return; }
      this.$emit('delete');
    },
  },
});
</script>

<style scoped>
  .developer-feature {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 400;
  }
</style>
