import { AxiosResponse } from 'axios';
import ApiService, { ApiArguments } from '@/api/api_service';
import {
  GoogleSettingApi,
  GoogleSettingApiCreateGoogleSettingRequest,
  GetGoogleSetting200Response,
  GetGoogleAuthorizationUrl200Response,
} from '@/api/openapi';

export class GoogleSettingService extends ApiService {
  api = new GoogleSettingApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true);
  }

  async getGoogleSetting(
    apiArguments?: ApiArguments<undefined>,
  ): Promise<AxiosResponse<GetGoogleSetting200Response>> {
    return this.checkResponse<GetGoogleSetting200Response>(
      this.api.getGoogleSetting(apiArguments?.options),
      apiArguments?.errorHandlers,
    );
  }

  async deleteGoogleSetting(
    apiArguments?: ApiArguments<undefined>,
  ): Promise<AxiosResponse<boolean>> {
    return this.checkResponse<boolean>(
      this.api.deleteGoogleSetting(apiArguments?.options),
      apiArguments?.errorHandlers,
    );
  }

  async createGoogleSetting(
    apiArguments?: ApiArguments<GoogleSettingApiCreateGoogleSettingRequest>,
  ): Promise<AxiosResponse<boolean>> {
    return this.checkResponse<boolean>(
      this.api.createGoogleSetting(apiArguments?.request, apiArguments?.options),
      apiArguments?.errorHandlers,
    );
  }

  async getGoogleAuthorizationUrl(
    apiArguments?: ApiArguments<undefined>,
  ): Promise<AxiosResponse<GetGoogleAuthorizationUrl200Response>> {
    return this.checkResponse<GetGoogleAuthorizationUrl200Response>(
      this.api.getGoogleAuthorizationUrl(apiArguments?.options),
      apiArguments?.errorHandlers,
    );
  }
}
