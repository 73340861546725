<template>
  <div class="import-status">
    <template v-if="isProgress">
      <BLayout
        class="progress"
        align-center
        justify-center
      >
        <div class="message">
          <slot name="progressMessage">
            {{ progressMessage }}
          </slot>
        </div>
      </BLayout>
    </template>
    <template v-else-if="isError">
      <BLayout
        class="failure"
        align-center
        justify-center
      >
        <span class="message">
          <slot name="errorMessage">{{ errorMessageAndDate }}</slot>
        </span>
        <BBtn
          v-if="canShowErrorModal"
          class="ml-300"
          type="error"
          outline
          @click="openErrorDetailModal"
        >
          {{ $t('general.detail') }}
        </BBtn>
        <BBtn
          class="clear-btn"
          type="white"
          size="small"
          flat
          fab
          @click="$emit('confirm')"
        >
          <BIcon size="small">
            clear
          </BIcon>
        </BBtn>
      </BLayout>
      <BModal
        modal-name="ErrorDetailModal"
        full
      >
        <BModalHeader
          :title="errorModalTitle"
          center
          @modal-close="$modal.hide('ErrorDetailModal')"
        />
        <BModalBody>
          <ErrorConfirm
            :import-errors="internalImportErrors"
            :import-error="internalImportError"
          />
        </BModalBody>
      </BModal>
    </template>
    <template v-else-if="isSuccess">
      <BLayout
        class="success"
        align-center
        justify-center
      >
        <span class="message">
          <slot name="successMessage">{{ successMessageAndDate }}</slot>
        </span>
        <BBtn
          class="clear-btn"
          type="white"
          size="small"
          flat
          fab
          @click="$emit('confirm')"
        >
          <BIcon size="small">
            clear
          </BIcon>
        </BBtn>
      </BLayout>
    </template>
  </div>
</template>

<script>
import lodash from 'lodash';
import ErrorConfirm from '@/components/organisms/user/leadImport/ErrorConfirm.vue';
import { i18nGlobal as i18n } from '@/i18n';
import { altText } from '@/utils/alt';
import { formatShorterDateTime } from '@/utils/date-time';

const getDateWithDateAndTimeFilter = (date) => {
  return altText(formatShorterDateTime(date));
};
export const makeCreateDateMessage = (targetTask) => i18n.t('general.createdDate', { date: getDateWithDateAndTimeFilter(targetTask.createdAt) }); 

export default {
  components: {
    ErrorConfirm,
  },
  props: {
    targetTask: Object,
    progressMessage: String,
    errorMessage: String,
    errorModalTitle: String,
    successMessage: String,
    canShowErrorModal: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    'confirm',
    'success',
  ],
  data () {
    return {
      internalImportError: null,
      internalImportErrors: null,
    };
  },
  computed: {
    isProgress () {
      return !!this.targetTask.id && !this.targetTask.finishedAt;
    },
    isError () {
      return !!this.targetTask.id && !!this.targetTask.errorData;
    },
    isSuccess () {
      const existTargetTask = typeof this.targetTask.id === 'number';
      const existFinishedAt = typeof this.targetTask.finishedAt === 'string' && this.targetTask.finishedAt.length > 0;
      const noError = this.targetTask.errorData == null;
      return  existTargetTask && existFinishedAt && noError;
    },
    // response.errorData.errorsが存在する場合
    // Model以外のRuntimeError(e.g. 上限やSidekiqなど)
    importError () {
      if (!this.targetTask.errorData) return null;
      if (typeof this.targetTask.errorData !== 'string') return null;
      return this.targetTask.errorData;
    },
    importErrors () {
      if (!this.targetTask.errorData) return null;
      if (!Array.isArray(this.targetTask.errorData)) return null;
      return this.targetTask.errorData;
    },
    errorMessageAndDate () {
      return makeCreateDateMessage(this.targetTask) + this.errorMessage;
    },
    successMessageAndDate () {
      return makeCreateDateMessage(this.targetTask) + this.successMessage;
    },
  },
  watch: {
    isSuccess (val) {
      if (val === true) {
        this.$emit('success');
      }
    },
  },
  methods: {
    openErrorDetailModal () {
      this.internalImportError = lodash.cloneDeep(this.importError);
      this.internalImportErrors = lodash.cloneDeep(this.importErrors);
      this.$modal.show('ErrorDetailModal');
    },
  },
};
</script>

<style lang="scss" scoped>
.import-status {
  position: relative;
  text-align: center;
  white-space: pre-wrap;
  
  .clear-btn {
    position: absolute;
    right: $basespace-400;
  }

  .success {
    padding: $basespace-200 0;
    background-color: $basecolor-success;
    @include m-fixed-height(60px);

    .message {
      color: $textcolor-white;
    }
  }
  .progress {
    padding: $basespace-400 0;
    background-color: $basecolor-warning;
    @include m-fixed-height(60px);

    .message {
      animation: Flash 3s infinite;
    }
  }
  .failure {
    padding: $basespace-200 0;
    background-color: $basecolor-error;
    @include m-fixed-height(60px);

    .message {
      color: $textcolor-white;
    }
  }
}

@keyframes Flash {
  50% {
    opacity: 0.1;
  }
}
</style>
