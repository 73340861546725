import { AxiosResponse } from 'axios';
import ApiService, { ApiArguments } from '@/api/api_service';
import {
  SequenceInstance,
  SequenceInstanceApi,
  SequenceInstanceApiGetSequenceInstanceRequest,
  SequenceInstanceApiGetSequenceInstancesRequest,
  SequenceInstanceApiRestartSequenceInstancesRequest,
  SequenceInstanceApiStopSequenceInstancesRequest,
  SequenceInstanceApiUnregisterSequenceInstancesRequest,
} from '@/api/openapi';
import { SequenceInstances } from '@/api/user/models/sequence_instances';

export class SequenceInstanceApiService extends ApiService {
  api = new SequenceInstanceApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true);
  }

  async getSequenceInstances({
    request,
    options,
    errorHandlers,
  }: ApiArguments<SequenceInstanceApiGetSequenceInstancesRequest>): Promise<SequenceInstances> {
    const res = await this.checkResponse<SequenceInstance[]>(
      this.api.getSequenceInstances(request, options),
      errorHandlers,
    );
    return new SequenceInstances(...res.data);
  }

  async getSequenceInstance({
    request,
    options,
    errorHandlers,
  }: ApiArguments<SequenceInstanceApiGetSequenceInstanceRequest>): Promise<AxiosResponse<SequenceInstance>> {
    return this.checkResponse<SequenceInstance>(
      this.api.getSequenceInstance(request, options),
      errorHandlers,
    );
  }

  async restartSequenceInstances({
    request,
    options,
    errorHandlers,
  }: ApiArguments<SequenceInstanceApiRestartSequenceInstancesRequest>): Promise<AxiosResponse<void>> {
    return this.checkResponse<void>(
      this.api.restartSequenceInstances(request, options),
      errorHandlers,
    );
  }

  async unregisterSequenceInstances({
    request,
    options,
    errorHandlers,
  }: ApiArguments<SequenceInstanceApiUnregisterSequenceInstancesRequest>): Promise<AxiosResponse<void>> {
    return this.checkResponse<void>(
      this.api.unregisterSequenceInstances(request, options),
      errorHandlers,
    );
  }

  async stopSequenceInstances({
    request,
    options,
    errorHandlers,
  }: ApiArguments<SequenceInstanceApiStopSequenceInstancesRequest>): Promise<AxiosResponse<void>> {
    return this.checkResponse<void>(
      this.api.stopSequenceInstances(request, options),
      errorHandlers,
    );
  }
}
