<template>
  <nav class="navbar">
    <slot />
  </nav>
</template>

<script>
export default {
  name: 'NavBar',
};
</script>

<style lang="scss" scoped>
  .navbar {
    @include m-fixed-width(100%);
    @include m-fixed-height($navbar-height);
    display: flex;
    align-items: center;
    padding: 10px $basespace-600 10px;
  }
</style>
