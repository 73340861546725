<template>
  <div>
    <BLayout class="section">
      <BFlex
        class="mt-500"
        :span="4"
      >
        {{ $t('overwrite.confirm.statusBarName') }}
      </BFlex>
      <BFlex
        class="mt-500"
        :span="16"
      >
        <div class="progress-bar">
          <div
            class="progress"
            :style="`width: ${progressRatio}%;`"
          />
        </div>
      </BFlex>
      <BFlex :span="2">
        <BListItem class="text-center">
          <template #header>
            <span>{{ $t('overwrite.confirm.updateTargetCount') }}</span>
          </template>
          <div class="valid-row-count">
            {{ validCallTargetCount }}
          </div>
        </BListItem>
      </BFlex>
      <BFlex :span="2">
        <BListItem class="text-center">
          <template #header>
            <span>{{ $t('overwrite.confirm.totalImportCount') }}</span>
          </template>
          <div class="total-import-count">
            {{ importCallTargetCount }}
          </div>
        </BListItem>
      </BFlex>
    </BLayout>
    <BDivider class="my-400" />
    <div
      v-if="invalidHeaders.length !== 0"
      class="invalid-headers-message"
    >
      <BPopover
        placement="bottom"
        width="600"
        trigger="hover"
      >
        <template #reference>
          <BLayout
            align-center
            justify-center
          >
            <span>{{ `${$t('overwrite.confirm.invalid')} (${invalidHeaders.length})` }}</span>
          </BLayout>
        </template>
        <div
          v-for="(header, i) in invalidHeaders"
          :key="`invalidHeader-${i}`"
          class="invalid-header-list"
        >
          <span class="header-column-number">{{
            `${header.column_number + 1}( ${toExcelLikeColumnName(header.column_number + 1)} )${$t(
              'overwrite.confirm.row'
            )}`
          }}</span><span>{{ `「${header.text}」` }}</span>
        </div>
      </BPopover>
    </div>
    <BListItem>
      <template #header>
        <span>{{ $t(`overwrite.confirm.updateItem`) }}</span>
      </template>
      <div
        v-for="validHeader in validHeadersWithoutId"
        :key="validHeader.column_number"
        class="mt-200 mb-300"
      >
        {{ validHeader.text }}
      </div>
    </BListItem>
  </div>
</template>

<script>
export default {
  props: {
    invalidHeaders: Array, // e.g. [{text: "システム付与情報  全体ID", column_number: 1}]
    validHeaders: Array, // e.g. [{text: "システム付与情報  全体ID", column_number: 1}]
    importCallTargetCount: Number,
    validCallTargetCount: Number,
  },
  computed: {
    validHeadersWithoutId() {
      if (this.validHeaders.length === 0) return [];
      const validHeadersWithoutId = [];
      this.validHeaders.forEach((h) => {
        if (h.value !== 'call_target_id') {
          validHeadersWithoutId.push(h);
        }
      });
      return validHeadersWithoutId;
    },
    progressRatio() {
      if (this.importCallTargetCount === 0) return 0;
      return this.validCallTargetCount / this.importCallTargetCount * 100;
    },
  },
  methods: {
    toExcelLikeColumnName(num) {
      let ret = '';
      for (let a = 1, b = 26; (num -= a) >= 0; a = b, b *= 26) {
        ret = String.fromCharCode(parseInt(num % b / a) + 65) + ret;
      }
      return ret;
    },
  },
};
</script>

<style lang="scss" scoped>
  .invalid-headers-message {
    color: $basecolor-error;
    font-size: $fontsize-300;
    font-weight: 600;
    padding: $basespace-200 0;
    margin: $basespace-400 0 $basespace-200;
  }

  .invalid-header-list {
    line-height: 2;
    padding: $basespace-100 0;
  }

  .header-column-number {
    @include m-fixed-width(100px);
    display: inline-block;
  }

  .progress-bar {
    border: 1px solid $bdcolor-base;
    background-color: $bgcolor-base;
    height: 100%;
    .progress {
      background-color: #59c9a8;
      height: 100%;
    }
  }
</style>
