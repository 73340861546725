<template>
  <div>
    <BListItem class="mb-100">
      <template #header>
        <span>{{ $t('callResult.callDate') }}</span>
      </template>
      <BDatePicker
        v-bind="dateAttributes"
        v-model="internalDate"
      />
    </BListItem>
    <BLayout
      align-center
      justify-center
      class="mb-2"
    >
      <BListItem class="mr-100">
        <template #header>
          <span>{{ $t('callResult.startTime') }}</span>
        </template>
        <BTimePicker
          v-bind="timeAttributes"
          v-model="internalStartedAt"
        />
      </BListItem>
      <BListItem class="ml-100">
        <template #header>
          <span>{{ $t('callResult.endTime') }}</span>
        </template>
        <BTimePicker
          v-bind="timeAttributes"
          v-model="internalEndedAt"
        />
      </BListItem>
    </BLayout>
    <div
      v-if="timeError"
      class="mt-300 mb-200"
    >
      <p class="error-text">
        {{ $t('callResult.timeError') }}
      </p>
    </div>
    <div
      v-if="miitelResultUrl"
      class="miitel-url mt-200"
    >
      <img
        class="miitel-logo"
        :src="miitelIcon"
      ><a
        class="miitel-text"
        :href="miitelResultUrl"
        target="_blank"
        rel="noopener noreferrer"
      >{{ miitelResultUrl }}</a>
    </div>
    <div
      v-if="zoomPhoneCallId"
      class="zoom-phone-call-id mt-200"
    >
      Zoom Phone Call ID: {{ zoomPhoneCallId }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import miitelIcon from '@/assets/miitel.png';
import { mergeDateAndTime, convertToDate } from '@/utils/date-time';

export default defineComponent({
  props: {
    startedAt: [Date, String],
    endedAt: [Date, String],
    miitelResultUrl: String,
    zoomPhoneCallId: String,
    timeError: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:startedAt',
    'update:endedAt',
  ],
  setup() {
    return {
      miitelIcon,
    };
  },
  data() {
    return {
      // NOTE: startedAtを同期的に扱う必要があるため保持する
      cachedStartedAt: this.startedAt,
    };
  },
  computed: {
    internalStartedAt: {
      get(): Date {
        // NOTE: this.startedAtは空にならない前提だが、もし空になっても何らかのDateを埋める
        // これをやらないと日付・時間のフォームがまとめて動かなくなるため
        return this.startedAt ? new Date(this.startedAt) : new Date();
      },
      set(newVal: Date | string | '' | null) {
        // NOTE: startedAtを消すと日付部分も消えてしまいUIが操作不可となるため、消せないようにする
        if (!newVal) {
          return;
        }
        const value = convertToDate(newVal);
        this.$emit('update:startedAt', value);
        this.cachedStartedAt = value;
      },
    },
    internalEndedAt: {
      get() {
        // MEMO: Miitel, Zoom PhoneでendedAtがfalsyになりうる
        if (this.endedAt) {
          return new Date(this.endedAt);
        } else {
          return '';
        }
      },
      set(newVal) {
        if (!newVal) {
          this.$emit('update:endedAt', '');
          return;
        }
        const date = mergeDateAndTime(
          this.cachedStartedAt,
          newVal,
        );
        this.$emit('update:endedAt', date);
      },
    },
    internalDate: {
      get() {
        return this.internalStartedAt;
      },
      set(newVal) {
        // NOTE: internalDateを消すとDateが不正になり時間部分も消えてしまうため、消せないようにする
        if (!newVal) {
          return;
        }
        const startedAt = mergeDateAndTime(
          newVal,
          this.internalStartedAt,
        ) || '';
        this.cachedStartedAt = startedAt;
        this.internalStartedAt = startedAt;
        this.internalEndedAt = mergeDateAndTime(
          newVal,
          this.internalEndedAt,
        ) || '';
      },
    },
    internalTimeRange: {
      get() {
        return [this.internalStartedAt, this.internalEndedAt];
      },
      set(newVal) {
        this.internalStartedAt = newVal[0];
        this.internalEndedAt = newVal[1];
      },
    },
    dateAttributes() {
      return {
        type: 'date',
        format: 'MM/DD',
        clearable: false,
      };
    },
    timeAttributes() {
      return {
        format: 'HH:mm',
        clearable: false,
      };
    },
  },
  watch: {
    startedAt: {
      immediate: true,
      handler (newVal) {
        this.cachedStartedAt = newVal;
      },
    },
  },
});
</script>

<style lang="scss" scoped>
  .miitel-url, .zoom-phone-call-id {
    display: flex;
    align-items: center;
    font-size: $fontsize-100;
    word-break: break-all;
  }

  .miitel-url {
    .miitel-text {
      text-decoration: none;
      color: $textcolor-light;

      &:hover {
        opacity: 0.5;
      }
    }

    .miitel-logo {
      height: 12px;
      margin-right: $basespace-200;
    }
  }

  .error-text {
    color: $basecolor-error;
  }
</style>
