<template>
  <BContent
    v-if="isAvailableTriggerFeature"
    v-loading="loading"
  >
    <template #navbar>
      <BLayout
        align-start
        justify-start
      >
        <span class="fs-400 fw-bold">{{ $t('trigger.title') }}</span>
      </BLayout>
      <BLayout
        column
        class="trigger-switch"
      >
        <BLayout
          v-if="editable"
          align-center
          justify-space-between
        >
          <span class="fs-100">{{ $t(`trigger.status`) }}</span>
          <BSwitch
            :status="triggerStatus"
            :before-change="handleToggleStatus"
            color="accent"
          />
        </BLayout>
        <BPopover
          v-else
          placement="top"
          width="240"
          trigger="hover"
        >
          <template #reference>
            <BLayout
              align-center
              justify-space-between
              class="trigger-switch-disabled"
            >
              <span class="fs-100">{{ $t(`trigger.status`) }}</span>
              <BSwitch
                :status="triggerStatus"
                color="accent"
              />
            </BLayout>
          </template>
          <div>{{ $t('trigger.statusChangeOnlyOwner') }}</div>
        </BPopover>
        <BLayout
          v-if="trigger?.statusChangedAt"
          justify-space-between
          class="text-annotation trigger-switch-annotation"
        >
          <span class="fs-100">
            <template v-if="triggerStatus">
              {{ $t(`trigger.statusChangedAt`) }}:
            </template>
            <template v-else>
              {{ $t(`trigger.statusStoppedAt`) }}:
            </template>
          </span>
          <span class="fs-100">{{ formatShorterDateTime(trigger.statusChangedAt) }}</span>
        </BLayout>
      </BLayout>
    </template>
    <template #footer>
      <TriggerFooter
        :can-save="canSave"
        :loading="loading"
        :need-confirmation-to-leave="needConfirmationToLeave"
        @click:save="handleClickSave"
      />
    </template>
    <template #waiting>
      <BPlaceholder
        v-loading="true"
        element-loading-background="transparent"
      />
    </template>
    <TriggerForm
      v-model="triggerBody"
      wait-for="getTriggerWait"
    />
    <BTabs
      v-model="activeName"
      class="tabs"
      :label-items="tabLabelItems"
      :before-leave="handleTabBeforeLeave"
      @click:tab="handleTabClick"
    >
      <template #action>
        <div class="trigger-container">
          <div class="trigger-container--inner">
            <div class="scrollable-container">
              <div class="scrollable-container--inner">
                <TriggerEvent
                  :trigger-event="triggerEventBody"
                  :editable="editable"
                  @click:trigger-event="handleSelectTriggerEvent"
                />
                <TriggerAction
                  v-for="(triggerActionBody) in triggerActionBodies"
                  :key="triggerActionBody.sortOrder"
                  :trigger-action="triggerActionBody"
                  :editable="editable"
                  @click:trigger-action="handleSelectTriggerAction"
                />
                <TriggerAction
                  :key="triggerActionSortOrderMax"
                  :editable="editable"
                  @click:trigger-action="handleSelectTriggerAction"
                />
                <div class="end">
                  <TriggerActionEnd />
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </BTabs>
    <div class="side-menu-container">
      <TriggerEventForm
        v-model="selectedTriggerEvent"
        v-model:mode="triggerEventFormMode"
        v-model:is-open="isTriggerEventFormOpen"
        :editable="editable"
        @click:cancel="handleCancelTriggerEvent"
        @click:trigger-event="handleSelectTriggerEvent"
        @update:model-value="handleSaveTriggerEvent"
      />
    </div>
    <div class="side-menu-container">
      <TriggerActionForm
        v-model="selectedTriggerAction"
        v-model:mode="triggerActionFormMode"
        v-model:is-open="isTriggerActionFormOpen"
        :editable="editable"
        @click:cancel="handleCancelTriggerAction"
        @click:trigger-action="handleSelectTriggerAction"
        @update:model-value="handleSaveTriggerAction"
      />
    </div>
  </BContent>
</template>

<script lang="ts" setup>
import lodash from 'lodash';
import { computed, inject, onMounted, ref, watch, getCurrentInstance } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Trigger, TriggerBody, TriggerStatusBody, TriggerEventBody, TriggerActionBody, TriggerStatus } from '@/api/openapi';
import { TriggerApiService } from '@/api/user/resources/trigger';
import TriggerActionEnd from '@/components/organisms/user/general/trigger/TriggerActionEnd.vue';
import TriggerAction from '@/components/organisms/user/trigger/TriggerAction.vue';
import TriggerActionForm from '@/components/organisms/user/trigger/triggerActionForm/TriggerActionForm.vue';
import TriggerEvent from '@/components/organisms/user/trigger/TriggerEvent.vue';
import TriggerEventForm from '@/components/organisms/user/trigger/triggerEventForm/TriggerEventForm.vue';
import TriggerFooter from '@/components/organisms/user/trigger/TriggerFooter.vue';
import TriggerForm from '@/components/organisms/user/trigger/TriggerForm.vue';
import { TTriggerActionFormMode, TTriggerEventFormMode, TriggerActionBodyExt } from '@/components/organisms/user/trigger/types';
import { useAvailableFeatureCheck } from '@/composable/available-feature-check';
import { useErrorHandler } from '@/composable/error-handler';
import { useUrlLinkBtabsHandlers } from '@/composable/url-link-b-tabs';
import { useCurrentUser } from '@/composable/user/user/users';
import { useWait } from '@/composable/vue-wait';
import { i18nGlobal as i18n } from '@/i18n';
import { KEY_OF_TOAST } from '@/injection-keys';
import { formatShorterDateTime } from '@/utils/date-time';

const { isAvailableTriggerFeature } = useAvailableFeatureCheck();
const toast = inject(KEY_OF_TOAST);
const route = useRoute();
const router = useRouter();
const { currentUser, fetchCurrentUserPromise } = useCurrentUser();
const {
  wait,
  doActionWithWait,
} = useWait();
const {
  redirectToNotFoundPage,
} = useErrorHandler();

const api = new TriggerApiService();

onMounted(async () => {
  await doActionWithWait('onMountedWait', async () => {
    await fetchCurrentUserPromise;
    await fetchTrigger();
  });
});

const activeName = ref('action');
const triggerId = parseInt(route.params.id as string);
const trigger = ref<Trigger>();
const triggerBody = ref<TriggerBody>({
  name: '',
  ownerId: null,
});
const triggerStatus = ref<boolean>();
const triggerEventBody = ref<TriggerEventBody | null>(null);
const triggerActionBodies = ref<TriggerActionBody[]>([]);
const canSave = ref(false);
const app = getCurrentInstance();

const selectedTriggerEvent = ref<TriggerEventBody | null | undefined>(undefined);
const triggerEventFormMode = ref<TTriggerEventFormMode | null | undefined>(undefined);
const isTriggerEventFormOpen = computed(() => selectedTriggerEvent.value !== undefined);

const selectedTriggerAction = ref<TriggerActionBodyExt | null | undefined>(undefined);
const triggerActionFormMode = ref<TTriggerActionFormMode | null | undefined>(undefined);
const isTriggerActionFormOpen = computed(() => selectedTriggerAction.value !== undefined);

const tabLabelItems = [
  { label: i18n.t('trigger.action'), slotName: 'action' },
  { label: i18n.t('trigger.lead'), slotName: 'lead', route: { name: 'TriggerLeads', params: { id: triggerId } } },
];

watch(trigger, (newValue) => {
  if (newValue) {
    triggerBody.value.name = newValue.name;
    triggerBody.value.ownerId = newValue.owner?.id || null;
    triggerStatus.value = newValue.status === TriggerStatus.Active;
  }
});
watch([
  () => triggerBody.value.name,
  () => isTriggerEventFormOpen.value,
  () => isTriggerActionFormOpen.value,
], ([
  newTriggerBodyName,
  newIsTriggerEventFormOpen,
  newIsTriggerActionFormOpen,
]) => {
  canSave.value = !!newTriggerBodyName
    && !newIsTriggerEventFormOpen
    && !newIsTriggerActionFormOpen
    && editable.value;
});

const loading = computed(() => wait.is(['onMountedWait', 'getTriggerWait', 'postTriggerWait', 'putTriggerWait', 'putTriggerStatusWait']));
const triggerActionSortOrderMax = computed(() => {
  if (triggerActionBodies.value.length < 1) return 1;
  return triggerActionBodies.value.map((a) => a.sortOrder).reduce((a, b) => Math.max(a, b)) + 1;
});

const needConfirmationToLeave = computed(() => {
  const orgName = trigger.value?.name ?? '';
  return orgName !== triggerBody.value.name;
});

const editable = computed(() => {
  const isOwnerUser = app.appContext.config.globalProperties.$can('owner');
  const isNewTrigger = !trigger.value || !trigger.value?.id;
  const isTriggerOwner = !!trigger.value?.owner?.id && trigger.value?.owner?.id === currentUser.value?.id;
  return isOwnerUser && (isNewTrigger || isTriggerOwner);
});

const {
  handleTabClick,
  handleTabBeforeLeave,
} = useUrlLinkBtabsHandlers(tabLabelItems, needConfirmationToLeave);

const fetchTrigger = async () => {
  if (!triggerId) return;

  await doActionWithWait('getTriggerWait', async () => {
    const { data } = await api.getTrigger({
      request: {
        triggerId,
      },
      errorHandlers: {
        404: redirectToNotFoundPage,
      },
    });
    trigger.value = data.trigger;
    triggerEventBody.value = data.trigger.triggerEvent;
    triggerActionBodies.value = data.trigger.triggerActions.map((triggerAction) => {
      const { id, actionType, actionDetail, sortOrder, approveUser } = triggerAction;
      return {
        id,
        actionType,
        actionDetail,
        sortOrder,
        approveUserId: approveUser?.id,
      } as TriggerActionBody;
    });
  });
};

const handleSelectTriggerEvent = (triggerEvent: TriggerEventBody, mode: TTriggerEventFormMode) => {
  selectedTriggerEvent.value = undefined;
  selectedTriggerAction.value = undefined;
  setTimeout(() => {
    selectedTriggerEvent.value = triggerEvent;
    triggerEventFormMode.value = mode;
  }, 200);
};

const handleSelectTriggerAction = (triggerAction: TriggerActionBodyExt, mode: TTriggerActionFormMode) => {
  selectedTriggerEvent.value = undefined;
  selectedTriggerAction.value = undefined;
  setTimeout(() => {
    selectedTriggerAction.value = triggerAction;
    triggerActionFormMode.value = mode;
  }, 200);
};

const handleCancelTriggerEvent = () => {
  selectedTriggerEvent.value = undefined;
};

const handleCancelTriggerAction = () => {
  selectedTriggerAction.value = undefined;
};

const handleSaveTriggerEvent = (triggerEvent: TriggerEventBody) => {
  triggerEventBody.value = triggerEvent;
  selectedTriggerEvent.value = undefined;
};

const handleSaveTriggerAction = (triggerAction: TriggerActionBodyExt) => {
  if (triggerAction.appendIndex > 0) {
    // 追加する (sortOrderの間)
    const newTriggerActionBodies = lodash.cloneDeep(triggerActionBodies.value);
    newTriggerActionBodies.splice(triggerAction.appendIndex - 1, 0, triggerAction);
    newTriggerActionBodies.forEach((e, i) => e.sortOrder = i + 1);
    triggerActionBodies.value = newTriggerActionBodies;
  } else if (triggerAction.sortOrder) {
    // 更新する
    triggerActionBodies.value = triggerActionBodies.value.map((triggerActionBody) =>
      triggerActionBody.sortOrder == triggerAction.sortOrder ? triggerAction : triggerActionBody,
    );
  } else {
    // 追加する (sortOrderの一番後ろ)
    triggerAction.sortOrder = triggerActionSortOrderMax.value;
    triggerActionBodies.value.push(triggerAction);
  }
  selectedTriggerAction.value = undefined;
};

const handleToggleStatus = async () => {
  await doActionWithWait('putTriggerStatusWait', async () => {
    await api.putTriggerStatus({
      request: {
        triggerId,
        putTriggerStatusRequest: {
          trigger: {
            status: triggerStatus.value ? TriggerStatus.Inactive : TriggerStatus.Active,
          },
        },
      },
    });
  });
  await fetchTrigger();
};

const handleClickSave = async () => {
  let newTriggerId: number;

  if (!canSave.value) return;
  
  if (triggerId) {
    await doActionWithWait('putTriggerWait', async () => {
      await api.putTrigger({
        request: {
          triggerId,
          postTriggerRequest: {
            trigger: triggerBody.value,
            triggerEvent: triggerEventBody.value,
            triggerActions: triggerActionBodies.value,
          },
        },
      });
    });
  } else {
    await doActionWithWait('postTriggerWait', async () => {
      const { data } = await api.postTrigger({
        request: {
          postTriggerRequest: {
            trigger: {
              ...triggerBody.value,
              ownerId: currentUser.value.id,
            },
            triggerEvent: triggerEventBody.value,
            triggerActions: triggerActionBodies.value,
          },
        },
      });
      newTriggerId = data.id;
    });
  }

  toast.show(
    i18n.t('general.done.withTarget', {
      action: i18n.t('general.save.text'),
      target: i18n.t('trigger.title'),
    }),
  );

  if (triggerId) {
    await fetchTrigger();
  } else {
    router.replace({ name: 'Trigger', params: { id: newTriggerId } });
  }
};
</script>

<style lang="scss" scoped>
.trigger-switch {
  max-width: 125px;

  .trigger-switch-disabled {
    opacity: 0.4;
  }

  .trigger-switch-annotation {
    align-items: baseline;
  }
}

.tabs {
  height: calc(100% - 50px - 48px);
}

.trigger-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #F5F7FA;

  &--inner {
    position: relative;
    flex: 1;
    height: calc(100% - 74px);

    .scrollable-container {
      padding: 40px 30px;
      height: 100%;
      overflow-y: auto;

      &--inner {
        width: 400px;

        .end {
          text-align: center;
        }
      }
    }
  }
}

.side-menu-container {
  position: fixed;
  height: calc(100% - $header-height);
  right: 0;
  top: $header-height;
  z-index: 8;
}

:deep(.el-tabs__header) {
  margin-bottom: 0;

  .el-tabs__nav-scroll {
    padding: 0 $basespace-600;
  }
}

:deep(.el-tabs__content) {
  height: calc(100% - 40px);

  .el-tab-pane {
    height: 100%;
  }
}
</style>
