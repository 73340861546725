<template>
  <LeadLink
    :call-target-id="callTargetId"
    tag="div"
    class="reminder-item"
  >
    <div class="time">
      {{ callBackReservedAt }}
    </div>
    <BPopover
      placement="right"
      width="200"
      trigger="hover"
    >
      <template #reference>
        <div class="w-full">
          <div class="company-name">
            {{ item.companyName }}
          </div>
          <div class="reminder-item-row">
            <BLabel
              class="type-label"
              round
              type="warning"
            >
              <span>{{ item.actionType.name }}</span>
            </BLabel>
            <BPriorityPreview
              :priority="item.priority"
              size="small"
            />
          </div>
        </div>
      </template>
      <BListItem>
        <template #header>
          <span>{{ $t('nextAction.content') }}</span>
        </template>
        <span>{{ item.content || '-' }}</span>
      </BListItem>
    </BPopover>
  </LeadLink>
</template>

<script>
import LeadLink from '@/components/organisms/user/general/LeadLink.vue';
import queryParameter from '@/mixins/query_parameter';
import { formatShortTime } from '@/utils/date-time';

export default {
  components: {
    LeadLink,
  },
  mixins: [queryParameter],
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    callBackReservedAt() {
      return formatShortTime(this.item.callBackReservedAt);
    },
    callTargetId() {
      return this.item.id;
    },
  },
};
</script>

<style lang="scss" scoped>
  .reminder-item {
    display: flex;
    color: $textcolor-base;
    text-decoration: none;
    padding: $basespace-200;
    height: 74px;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid $bdcolor-light;

    &:hover {
      background-color: $bgcolor-base;
    }

    .reminder-item-row {
      margin-top: 7px;
      display: flex;
      align-items: center;
      gap: 22px;
    }
  }

  .time {
    color: $textcolor-light;
    font-size: $fontsize-200;
    width: 36px;
    min-width: 36px;
    margin: 0 $basespace-400 0 $basespace-600;
  }

  .type-label {
    min-width: 62px;
    height: 19px;
  }

  .company-name {
    max-width: 266px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>
