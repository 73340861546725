import Waiting from '@/store/waiting';

export default class MailActions {
  constructor(Api, types) {
    const actions = {
      async sendAction({ commit }, args) {
        await waiting.check('sendAction', async () => {
          try {
            const res = await Api.send(args);
            return res;
          } catch (err) {
            throw err;
          }
        });
      },
      async testSendAction({ commit }, args) {
        await waiting.check('testSendAction', async () => {
          try {
            await Api.testSend(args);
          } catch (err) {
            throw err;
          }
        });
      },
      async generateContentByOpenaiAction({ commit, dispatch }, args) {
        return await waiting.check('generateContentByOpenaiAction', async () => {
          try {
            const res = await Api.generateContentByOpenai(args);
            return res;
          } catch (err) {
            throw err;
          }
        });
      },
      async generateContentByOpenSearchAction({ commit, dispatch }, args) {
        return await waiting.check('generateContentByOpenSearchAction', async () => {
          try {
            const res = await Api.generateContentByOpenSearch(args);
            return res;
          } catch (err) {
            throw err;
          }
        });
      },
      async sendDirectAction({ commit }, args) {
        await waiting.check('sendDirectAction', async () => {
          try {
            await Api.sendDirect(args);
          } catch (err) {
            throw err;
          }
        });
      },
      async sendBulkAction({ commit }, args) {
        await waiting.check('sendBulkAction', async () => {
          try {
            await Api.sendBulk(args);
          } catch (err) {
            throw err;
          }
        });
      },
      async reserveBulkAction({ commit }, args) {
        await waiting.check('reserveBulkAction', async () => {
          try {
            await Api.reserveBulk(args);
          } catch (err) {
            throw err;
          }
        });
      },
      async mailLeadCountAction({ commit }, args) {
        await waiting.check('mailLeadCountAction', async () => {
          try {
            const res = await Api.mailLeadCount(args);
            commit(types.SET_MAIL_LEAD_COUNT, Object.values(res.data)[0]);
          } catch (err) {
            throw err;
          }
        });
      },
    };
    const waiting = new Waiting(actions);
    this.actions = actions;
  }
}
