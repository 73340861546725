<template>
  <div>
    <span>{{ text }}</span>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { PaginationMeta } from '@/api/openapi';

type TProps = {
  currentPage: number;
  pagingMeta: PaginationMeta;
};

const props = defineProps<TProps>();

const text = computed(() => {
  const { totalCount, limitValue } = props.pagingMeta;
  let from = (props.currentPage - 1) * limitValue + 1;
  if (totalCount === 0) from = 0;
  const to = Math.min(from + limitValue - 1, totalCount);
  return `${from} - ${to} / ${totalCount}`;
});
</script>
