<template>
  <div class="trigger-leads-container">
    <InstancesSummary
      v-model="selectedStatus"
      :status-overview="statusOverview"
      @update:model-value="handleChangeStatus"
    />
    <InstanceList
      v-model="triggerTargets"
      :pagination-meta="paginationMeta"
      :page="page"
      class="instance-list"
      @update:selected-trigger-target-ids="handleSelectedTriggerTargetIdsUpdate"
      @after:move-page="handleAfterMovePage"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import { Trigger, TriggerTarget, TriggerTargetStatus, PaginationMeta, TriggerTargetStatusOverview, TriggerTargetApiGetTriggerTargetsRequest } from '@/api/openapi';
import { TriggerTargetApiService } from '@/api/user/resources/trigger_target';
import LeadLink from '@/components/organisms/user/general/LeadLink.vue';
import InstanceList from '@/components/organisms/user/trigger/InstanceList.vue';
import InstancesSummary from '@/components/organisms/user/trigger/InstancesSummary.vue';
import { TCheck, useCheckAll } from '@/composable/check-all';
import { useWait } from '@/composable/vue-wait';

type TEmits = {
  'after:moveInstanceListPage': [value: number]
  'update:selectedTriggerTargetIds': [values: number[]];
};

const emit = defineEmits<TEmits>();

const {
  initializeChecks,
} = useCheckAll();

const trigger = defineModel<Trigger>();
const ownerId = defineModel<number | null>('ownerId', { default: null });
const page = defineModel<number | null>('page', { default: 1 });

const triggerTargets = ref<TriggerTarget[]>([]);
const paginationMeta = ref<PaginationMeta>(null);
const statusOverview = ref<TriggerTargetStatusOverview>(null);
const selectedStatus = ref<TriggerTargetStatus | null>(null);

watch([
  () => trigger.value,
  () => page.value,
  () => ownerId.value,
  () => selectedStatus.value,
], ([
  newTrigger,
]) => {
  if (newTrigger) fetchTriggerTargets();
});

const api = new TriggerTargetApiService();

const {
  doActionWithWait,
} = useWait();

const fetchTriggerTargets = async () => {
  if (!trigger.value?.id) return;

  await doActionWithWait('fetchTriggerTargetsWait', async () => {
    const request = {
      triggerId: trigger.value.id,
      currentPage: page.value,
    };
    if (ownerId.value) {
      request.ownerId = ownerId.value;
    }
    if (selectedStatus.value) {
      request.status = selectedStatus.value;
    }
    const { data: { triggerTargets: triggerTargetsRes, meta, overview } } = await api.getTriggerTargets({ request });
    triggerTargets.value = triggerTargetsRes;
    paginationMeta.value = meta;
    statusOverview.value = overview;
  });
};

const handleSelectedTriggerTargetIdsUpdate = (values: number[]) => {
  emit('update:selectedTriggerTargetIds', values);
};
const handleAfterMovePage = (value: number) => {
  emit('after:moveInstanceListPage', value);
};
</script>

<style scoped lang="scss">
.trigger-leads-container {
  height: 100%;
  display: flex;
  flex-direction: column;

  .instance-list {
    flex-grow: 1;
    flex-basis: 0;
  }
}
</style>
