<template>
  <div class="zoom-phone">
    <div
      v-show="isVisible"
      id="zoom-phone-container"
      :class="classes"
    >
      <BLayout
        class="zoom-phone-header"
        align-center
      >
        <BTooltip
          top
          :content="$t('general.close.text')"
          :disabled="isOutboundCalling"
        >
          <BBtn
            :disabled="isOutboundCalling"
            size="small"
            fab
            text
            @click="closeWidget"
          >
            <BIcon size="small">
              close
            </BIcon>
          </BBtn>
        </BTooltip>
        <BBtn
          size="small"
          fab
          text
          @click="minimizeWidget"
        >
          <BIcon
            v-if="!isMinimize"
            size="small"
          >
            minimize
          </BIcon>
          <BIcon
            v-else
            size="small"
          >
            maximize
          </BIcon>
        </BBtn>
      </BLayout>
      <div
        class="zoom-phone-content"
        :hidden="isMinimize"
      >
        <iframe
          id="zoom-embeddable-phone-iframe"
          src="https://applications.zoom.us/integration/phone/embeddablephone/home" 
          allow="clipboard-read; clipboard-write https://applications.zoom.us"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapWaitingActions } from 'vue-wait';
import { mapGetters } from 'vuex';
import { useActiveCti } from '@/composable/active-cti';
import { initZoomPhone } from '@/services/zoom_phone_service';

type TData = {
  isMinimize: boolean;
}

export default defineComponent({
  setup() {
    const { activeCti } = useActiveCti(true);
    return {
      activeCti,
    };
  },
  data (): TData {
    return {
      isMinimize: false,
    };
  },
  computed: {
    ...mapGetters('zoomPhone', [
      'isOutboundCalling',
    ]),
    ...mapGetters('userUi', [
      'zoomPhoneWidgetVisible',
      'isTargetMenuVisible',
      'targetMailFormVisible',
    ]),
    isVisible: {
      get(): boolean {
        return this.zoomPhoneWidgetVisible;
      },
      set(newVal: boolean) {
        if (this.isVisible !== newVal) this.setZoomPhoneWidgetVisibleAction(newVal);
        if (newVal === true) {
          this.handleOpenWidget();
        }
      },
    },
    classes () {
      return {
        'menu-open': this.isTargetMenuVisible,
        'mail-open': this.targetMailFormVisible,
        'minimize': this.isMinimize,
      };
    },
  },
  watch: {
    isOutboundCalling (newVal) {
      if (newVal && this.isVisible) {
        // this.setTargetHeaderMoveButtonDisabledAction(true)
        this.setTargetCallResultFormVisibleAction(true);
      }
    },
  },
  mounted() {
    if (this.activeCti === 'zoom') {
      initZoomPhone();
      this.handleVisibility();
    }
  },
  methods: {
    ...mapWaitingActions('userUi', {
      setZoomPhoneWidgetVisibleAction: 'setZoomPhoneWidgetVisibleWait',
      setTargetCallResultFormVisibleAction: 'setTargetCallResultFormVisibleWait',
    }),
    handleVisibility () {
      document.addEventListener('visibilitychange', () => {
        if (this.isVisible && document.visibilityState === 'hidden' && !this.isOutboundCalling) {
          this.isVisible = false;
        }
      });
    },
    handleOpenWidget(): void {
      if (this.canOpenWidget()) this.isVisible = true;
    },
    canOpenWidget(): boolean {
      if (this.activeCti !== 'zoom') {
        this.$bitterAlert.show({
          title: this.$t('general.miitel'),
          text: this.$t('miitel.setupError'),
          closeOnClickOutside: true,
          buttonsCancel: false,
        });
        this.isVisible = false;
        return false;
      } else {
        return true;
      }
    },
    closeWidget(): void {
      if (this.isOutboundCalling) return;

      this.isVisible = false;
      this.isMinimize = false;
    },
    minimizeWidget () {
      this.isMinimize = !this.isMinimize;
    },
  },
});
</script>

<style lang="scss" scoped>
$embed-height: 650px;
$embed-width: 350px;
$header-height: 48px; 

#zoom-phone-container {
  position: absolute;
  // NOTE: -2pxは下部に余白ができないようにするための調整
  bottom: -2px;
  right: 100%;
  max-height: 100%;
  height: calc(#{$embed-height} + #{$header-height});
  z-index: 2;
  box-shadow: $boxshadow-base;
  transition: all 400ms $standard-easing;

  &.menu-open {
    transition: all 600ms $standard-easing;
    right: calc(100% + 320px);
    // NOTE: 個別メールフォームが出てる時はZoomPhone隠す
    &.mail-open {
      right: 0;
    }
  }

  &.minimize {
    top: calc(100% - #{$header-height});

    .zoom-phone {
      &-content {
        display: none;
      }
    }
  }
}

.zoom-phone {
  &-header {
    width: 100%;
    // NOTE: BLayoutで0固定のため上書きが必要
    min-width: $embed-width !important;
    background: rgba($concrete, 0.8);
    padding: $basespace-100;
  }

  &-content {
    background: $bgcolor-dark;
    // NOTE: 4pxはスクロール表示を調整するための余白
    max-height: calc(100% - #{$header-height} + 4px);
    overflow-y: auto;
  }
}

#zoom-embeddable-phone-iframe {
  height: $embed-height;
  width: $embed-width;
  border: none;
}
</style>
