import { isObject } from '@/utils/object';

// null, undefined, 空文字, 空配列, 空オブジェクトを判定する関数
export const isEmptyValue = (val) => {
  const isNull = val === null;
  const isUndefined = typeof val === 'undefined';
  const isEmptyString = typeof val === 'string' && val.length === 0;
  const isEmptyArray = Array.isArray(val) && val.length === 0;
  const isEmptyObject = isObject(val) && Object.keys(val).length === 0;

  return isNull || isUndefined || isEmptyString || isEmptyArray || isEmptyObject;
};
