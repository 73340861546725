import ApiBase from '@/api/base';

export default {
  getUsers (args) {
    const { clientId } = args;
    return ApiBase.get(`/admin/clients/${clientId}/users`);
  },
  createUser (args) {
    const { clientId } = args;
    return ApiBase.post(`/admin/clients/${clientId}/users`, args);
  },
  updateUser (args) {
    const { id } = args;
    return ApiBase.put(`/admin/users/${id}`, args);
  },
};
