<template>
  <VWait
    for="fetchDuplicateSequenceMaster"
  >
    <EditableSequence
      :sequence-body="sequenceBody"
      @update="handleUpdate"
    />
  </VWait>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { SequenceBody } from '@/api/openapi';
import { useSequenceMaster } from '@/composable/user/sequence/sequence-master';
import EditableSequence from '../general/sequence/EditableSequence.vue';

type TProps = {
  duplicatedSequenceMasterId?: number;
};
type TEmit = {
  'update': [sequenceBody: SequenceBody];
};

const props = defineProps<TProps>();
const emit = defineEmits<TEmit>();

const i18n = useI18n();
const {
  sequenceMaster: duplicateSequenceMaster,
  fetchSequenceMaster: fetchDuplicateSequenceMaster,
} = useSequenceMaster(props.duplicatedSequenceMasterId);
if (props.duplicatedSequenceMasterId) {
  fetchDuplicateSequenceMaster('fetchDuplicateSequenceMaster');
}

const sequenceBody = computed<SequenceBody>(() => {
  const name = duplicateSequenceMaster.value
    ? i18n.t('sequence.duplicatedName', { originalName: duplicateSequenceMaster.value.name })
    : '';

  return {
    name,
    // TODO: API側で設定しているのでownerId不要だが、型で必須になっているので一旦undefinedを渡している。型を修正したら削除する。
    ownerId: undefined,
  };
});


const handleUpdate = (sequenceBody: SequenceBody) => {
  emit('update', sequenceBody);
};
</script>
