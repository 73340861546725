import { StoreDataFetchManager } from '@/store/store-data-fetch-manager';
import Waiting from '@/store/waiting';

export default class OperatorActions {
  constructor(Api, types) {
    const actions = {
      async createOperatorAction ({ commit }, args) {
        try {
          await waiting.check('createOperatorAction', async () => {
            await Api.createOperator(args);
          });
        } catch (error) {
          throw error;
        }
      },
      async getOperatorAction ({ commit }, args){
        try {
          await waiting.checkWithArg('getOperatorAction', args, async () =>  {
            const res = await storeDataFetchManager.fetch('getOperatorAction', async () => await Api.getOperator(args));
            if (res.data.operator) {
              commit(types.SET_CALL_TARGET_OPERATOR, res.data.operator);
            } else {
              commit(types.CLEAR_CALL_TARGET_OPERATOR);
            }
          });
        } catch (error) {
          throw error;
        }
      },
      async withdrawOperatorAction ({ commit }, args) {
        try {
          await waiting.check('withdrawOperatorAction', async () => {
            await Api.withdrawOperator(args);
            commit(types.CLEAR_CALL_TARGET_OPERATOR);
          });
        } catch (error) {
          throw error;
        }
      },
    };
    const waiting = new Waiting(actions);
    const storeDataFetchManager = new StoreDataFetchManager();
    this.actions = actions;
  }

}
