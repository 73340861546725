import { readonly, ref } from 'vue';
import { GoogleSetting } from '@/api/openapi';
import { GoogleSettingService } from '@/api/user/resources/google_setting';

const useGoogleSetting = () => {
  const googleSetting = ref<GoogleSetting>(null);

  const fetchGoogleSetting = async () => {
    const api = new GoogleSettingService();
    const res = await api.getGoogleSetting();
    googleSetting.value = res.data.googleSetting;
  };
  
  return {
    googleSetting: readonly(googleSetting),
    fetchGoogleSetting,
  };
};

export {
  useGoogleSetting,
};
