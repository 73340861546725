const components = {};
const importAll = (r) => {
  for (const key in r) {
    const componentName = key.match(/.+\/(.+)\.vue$/)[1];
    const module = r[key];
    components[componentName] = module.default;
  }
};

importAll(import.meta.glob('./components/**/*.vue', { eager: true }));

export default {
  install (Vue) {
    Object.keys(components).forEach(key => {
      Vue.component(key, components[key]);
    });
  },
};
