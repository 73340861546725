<template>
  <div class="select-user">
    <BSelect
      v-model="selectedUserId"
      :items="userItems"
      :clearable="clearable"
      :placeholder="placeholder"
      item-text="text"
      item-value="value"
      filterable
      class="type-select"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useUsers } from '@/composable/user/user/users';
import { TOptions } from '../atoms/BSelect.vue';

// NOTE: 多分このComponentはsrc/components/organisms以下に置く方が正しい
type TProps = {
  modelValue: number | undefined;
  placeholder?: string;
  useAll?: boolean;
  useAllLabel?: string;
  clearable?: boolean;
  onlyActiveUsers?: boolean;
};
type TEmit = {
  'update:modelValue': [modelValue: number | undefined];
};

const props = withDefaults(defineProps<TProps>(), {
  placeholder: undefined,
  useAll: true,
  clearable: false,
  onlyActiveUsers: false,
});

const emit = defineEmits<TEmit>();
const VALUE_ALL = 'all';
const i18n = useI18n();

const selectedUserId = computed({
  get() {
    return props.useAll && props.modelValue === undefined ? VALUE_ALL : props.modelValue;
  },
  set(value: number | typeof VALUE_ALL) {
    emit('update:modelValue', value === VALUE_ALL ? undefined : value);
  },
});

const { users, activeUsers } = useUsers();
const userItems = computed(() => {
  const userItems: TOptions = props.useAll ? [{ text: props.useAllLabel ?? i18n.t('draftMailReminder.allOwner'), value: VALUE_ALL }] : [];
  const targetUsers = props.onlyActiveUsers ? activeUsers : users;
  return userItems.concat(
    targetUsers.value.map((candidate) => {
      return { text: candidate.name, value: candidate.id };
    }),
  );
});
</script>

<style lang="scss" scoped>
.select-user {
  position: sticky;
  left: 0;
  .type-select {
    width: 160px;
  }
}
</style>
