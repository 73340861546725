import Waiting from '@/store/waiting';

export default class FreeTagActions {
  constructor (Api, types) {
    const actions = {
      async getFreeTagsAction ({ commit }, args) {
        await waiting.check('getFreeTagsAction', async () => {
          try {
            const res = await Api.getFreeTags(args);
            commit(types.SET_FREE_TAGS, Object.values(res.data)[0]);
          } catch (err) {
            commit(types.CLEAR_FREE_TAGS);
            throw err;
          }
        });
      },
      async createFreeTagAction ({ commit }, args) {
        await waiting.check('createFreeTagAction', async () => {
          await Api.createFreeTag(args);
          try {
            const res = await Api.getFreeTags(args);
            commit(types.SET_FREE_TAGS, Object.values(res.data)[0]);
          } catch (err) {
            throw err;
          }
        });
      },
      async updateFreeTagAction ({ commit }, args) {
        await waiting.check('updateFreeTagAction', async () => {
          await Api.updateFreeTag(args);
          try {
            const res = await Api.getFreeTags(args);
            commit(types.SET_FREE_TAGS, Object.values(res.data)[0]);
          } catch (err) {
            throw err;
          }
        });
      },
      async deleteFreeTagAction ({ commit }, args) {
        await waiting.check('deleteFreeTagAction', async () => {
          await Api.deleteFreeTag(args);
          try {
            const res = await Api.getFreeTags(args);
            commit(types.SET_FREE_TAGS, Object.values(res.data)[0]);
          } catch (err) {
            throw err;
          }
        });
      },
    };
    const waiting = new Waiting(actions);
    this.actions = actions;
  }
}
