import { StoreDataFetchManager } from '@/store/store-data-fetch-manager';
import Waiting from '@/store/waiting';

export default class CallTargetFreeTagActions {
  constructor (Api, types) {
    const actions = {
      async getCallTargetFreeTagsAction ({ commit }, args) {
        await waiting.checkWithArg('getCallTargetFreeTagsAction', args, async () => {
          try {
            const res = await storeDataFetchManager.fetch('getCallTargetFreeTagsAction', async () => await Api.getCallTargetFreeTags(args));
            commit(types.SET_CALL_TARGET_FREE_TAGS, Object.values(res.data)[0]);
          } catch (err) {
            commit(types.CLEAR_CALL_TARGET_FREE_TAGS);
            throw err;
          }
        });
      },
      async createCallTargetFreeTagAction ({ commit }, args) {
        await waiting.check('createCallTargetFreeTagAction', async () => {
          await Api.createCallTargetFreeTag(args);
          try {
            const res = await Api.getCallTargetFreeTags(args);
            commit(types.SET_CALL_TARGET_FREE_TAGS, Object.values(res.data)[0]);
          } catch (err) {
            throw err;
          }
        });
      },
      async updateCallTargetFreeTagAction ({ commit }, args) {
        await waiting.check('updateCallTargetFreeTagAction', async () => {
          await Api.updateCallTargetFreeTag(args);
          try {
            const res = await Api.getCallTargetFreeTags(args);
            commit(types.SET_CALL_TARGET_FREE_TAGS, Object.values(res.data)[0]);
          } catch (err) {
            throw err;
          }
        });
      },
      async appendCallTargetFreeTagAction ({ commit }, args) {
        await waiting.check('appendCallTargetFreeTagAction', async () => {
          await Api.appendCallTargetFreeTag(args);
          try {
            const res = await Api.getCallTargetFreeTags(args);
            commit(types.SET_CALL_TARGET_FREE_TAGS, Object.values(res.data)[0]);
          } catch (err) {
            throw err;
          }
        });
      },
      async deleteCallTargetFreeTagAction ({ commit }, args) {
        await waiting.check('deleteCallTargetFreeTagAction', async () => {
          await Api.deleteCallTargetFreeTag(args);
          try {
            const res = await Api.getCallTargetFreeTags(args);
            commit(types.SET_CALL_TARGET_FREE_TAGS, Object.values(res.data)[0]);
          } catch (err) {
            throw err;
          }
        });
      },
    };
    const waiting = new Waiting(actions);
    const storeDataFetchManager = new StoreDataFetchManager();
    this.actions = actions;
  }
}
