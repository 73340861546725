import { computed, readonly, ref, toRaw } from 'vue';
import { useI18n } from 'vue-i18n';
import { TriggerActionForm, TriggerActionActionTypeWithGroup, TriggerActionActionType } from '@/api/openapi';
import { TriggerActionFormApiService } from '@/api/user/resources/trigger_action_form';
import { TriggerActionTypeApiService } from '@/api/user/resources/trigger_action_type';
import { TriggerActionBodyExt } from '@/components/organisms/user/trigger/types';
import { useWait } from '@/composable/vue-wait';

const useTriggerAction = (needsInitialFetch = () => true) => {
  const i18n = useI18n();

  const actionTypes = ref<TriggerActionActionTypeWithGroup[]>([]);

  const groupedActionTypes = computed(() => {
    return actionTypes.value.reduce((acc, actionType) => {
      let group = acc.find((group) => group.key === actionType.group);
      if (!group) {
        group = {
          key: actionType.group,
          label: i18n.t(`trigger.actionGroups.${actionType.group}`),
          options: [],
        };
        acc.push(group);
      }
      group.options.push({
        value: actionType.actionType,
        name: i18n.t(`trigger.actionTypes.${actionType.actionType}`),
      });
      return acc;
    }, []);
  });

  const fetchActionTypes = async () => {
    const api = new TriggerActionTypeApiService();
    const { data } = await api.getTriggerActionTypes();
    actionTypes.value = data.triggerActionTypes;
  };

  const makeInitialTriggerAction = (triggerAction: TriggerActionBodyExt): TriggerActionBodyExt => {
    return {
      approveUserId: null,
      actionType: null,
      actionDetail: {},
      sortOrder: null,
      ...structuredClone(toRaw(triggerAction) || {}),
    };
  };

  if (needsInitialFetch()) {
    fetchActionTypes();
  }

  return {
    groupedActionTypes: readonly(groupedActionTypes),
    makeInitialTriggerAction,
  };
};

const useTriggerActionForm = () => {
  const { doActionWithWait, wait } = useWait();

  const forms = ref<TriggerActionForm[]>([]);
  const fetchForms = async (actionType: TriggerActionActionType) => {
    await doActionWithWait('fetchTriggerActionFormsWait', async () => {
      const api = new TriggerActionFormApiService();
      const { data } = await api.getTriggerActionForms({
        request: {
          actionType,
        },
      });
      forms.value = data.triggerActionForms;
    });
  };

  const loading = computed(() => {
    return wait.is('fetchTriggerActionFormsWait');
  });

  return {
    loading: readonly(loading),
    forms,
    fetchForms,
  };
};

export {
  useTriggerAction,
  useTriggerActionForm,
};
