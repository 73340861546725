<template>
  <div class="setting-title">
    {{ $t('mailSignature.title') }}
  </div>
  <div class="explain">
    {{ $t('mailSignature.explain') }}
  </div>
  <BLayout
    v-loading="loading || saving"
    column
    class="form"
  >
    <BListItem>
      <div class="activation-input">
        <BCheckbox
          v-model="mailSignature.isActive"
          :label="$t('mailSignature.activeationInputLable')"
        />
      </div>
    </BListItem>
    <BListItem class="mt-200">
      <!-- メール署名はそのまま本文に付与するため、各行（または全体）を<div>で囲む（Quillは勝手にやってくれているので、リプレースする場合は注意） -->
      <MailBodyEditor
        v-model:content="mailSignature.content"
        :enable-placeholder="false"
        class="mail-body-editor"
      />
    </BListItem>
    <BListItem class="mt-300">
      <BBtn
        type="primary"
        :disabled="loading"
        :loading="saving"
        @click="save"
      >
        {{ $t('general.save.text') }}
      </BBtn>
    </BListItem>
  </BLayout>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue';
import { MailSignatureBody } from '@/api/openapi';
import { MailSignatureApiService } from '@/api/user/resources/mail_signature';
import MailBodyEditor from '@/components/organisms/user/mail/common/drawer/state/form/MailBodyEditor.vue';
import { useWait } from '@/composable/vue-wait';

const { doActionWithWait, wait } = useWait();

const defaultMailsignature = {
  isActive: false,
  content: '',
};

const mailSignature = ref<MailSignatureBody>(defaultMailsignature);

const fetchData = async () => {
  doActionWithWait('fetchMailSignature', async () => {
    const api = new MailSignatureApiService();
    const { data } = await api.getMailSignatureMe({
      errorHandlers: {
        404: () => {
          mailSignature.value = defaultMailsignature;
        },
      },
    });
    mailSignature.value = data;
  });
};

onMounted(() => {
  fetchData();
});

const save = () => {
  doActionWithWait('saveMailSignature', async () => {
    const api = new MailSignatureApiService();
    await api.putMailSignatureMe({
      request: {
        mailSignatureBody: mailSignature.value,
      },
    });
  });
};

const loading = computed(() => {
  return wait.is('fetchMailSignature');
});

const saving = computed(() => {
  return wait.is('saveMailSignature');
});
</script>

<style lang="scss" scoped>
.activation-input {
  width: fit-content;
}

.mail-body-editor {
  height: 400px;
  width: 900px;
  border: 1px solid $concrete;
  border-radius: 5px;
  transition: all 0.3s ease;

  &:focus-within {
    border: 1px solid $bdcolor-active;
  }
}
</style>
