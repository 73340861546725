<template>
  <VWait
    for="fetchDuplicatedSequenceStepMasters"
    class="sequence-steps-container"
  >
    <EditableSequenceStepMasters
      :sequence-step-masters="sequenceStepMasters"
      :sequence-owner-id="sequenceOwnerId"
      @update="handleUpdate"
    />
  </VWait>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { SequenceStepMaster } from '@/api/openapi';
import { useSequenceStepMasters } from '@/composable/user/sequence/sequence-step-masters';
import EditableSequenceStepMasters from '../general/sequence/EditableSequenceStepMasters.vue';
import { TFormSequenceStep } from '../general/sequence/types';

type TProps = {
  duplicatedSequenceMasterId?: number;
  sequenceOwnerId: number;
};
type TEmit = {
  'update': [sequenceSteps: TFormSequenceStep[]];
};
const props = defineProps<TProps>();
const emit = defineEmits<TEmit>();

const {
  sequenceStepMasters: duplicatedSequenceStepMasters,
  fetchSequenceStepMasters: fetchDuplicatedSequenceStepMasters,
} = useSequenceStepMasters(props.duplicatedSequenceMasterId);

const sequenceStepMasters = computed<SequenceStepMaster[]>(() =>
  duplicatedSequenceStepMasters.value.map(step => ({
    ...step,
    id: null,
    deletable: true,
    mailAggregates: null,
    callAggregates: null,
  } as SequenceStepMaster)),
);

if (props.duplicatedSequenceMasterId) {
  fetchDuplicatedSequenceStepMasters('fetchDuplicatedSequenceStepMasters');
}

const handleUpdate = (sequenceSteps: TFormSequenceStep[]) => {
  emit('update', sequenceSteps);
};
</script>

<style scoped lang="scss">
.sequence-steps-container {
  height: 100%;
}
</style>
