<template>
  <div class="trigger-event-container">
    <TriggerEventCard
      :trigger-event="triggerEvent"
      :editable="editable"
      @click:setting="handleSettingBtnClick"
    />
  </div>
</template>

<script lang="ts" setup>
import { TriggerEventBody } from '@/api/openapi';
import TriggerEventCard from '@/components/organisms/user/trigger/TriggerEventCard.vue';
import { TTriggerEventFormMode } from './types';

type TProps = {
  triggerEvent: TriggerEventBody | null;
  editable: boolean;
};
type TEmit = {
  'click:triggerEvent': [triggerEvent: TriggerEventBody, mode: TTriggerEventFormMode];
};
const props = defineProps<TProps>();
const emit = defineEmits<TEmit>();

const handleSettingBtnClick = (mode: TTriggerEventFormMode) => {
  emit('click:triggerEvent', props.triggerEvent || {
    leadViewId: null,
    eventType: null,
    filterParams: {},
    eventDetail: {},
  }, mode);
};
</script>
