/* tslint:disable */
/* eslint-disable */
/**
 * openapi
 * bales cloud
 *
 * The version of the OpenAPI document: 1.0
 * Contact: test@example.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface PostSisenseUserBody
 */
export interface PostSisenseUserBody {
    /**
     * メールアドレス
     * @type {string}
     * @memberof PostSisenseUserBody
     */
    'email': string;
    /**
     * 権限
     * @type {string}
     * @memberof PostSisenseUserBody
     */
    'role': PostSisenseUserBodyRoleEnum;
}

export const PostSisenseUserBodyRoleEnum = {
    Designer: 'designer',
    Viewer: 'viewer'
} as const;

export type PostSisenseUserBodyRoleEnum = typeof PostSisenseUserBodyRoleEnum[keyof typeof PostSisenseUserBodyRoleEnum];


