<template>
  <div
    v-if="!revisionCheck"
    class="update-notification"
  >
    <BCard
      small
      border
      shadow
    >
      <BLayout
        align-center
        column
      >
        <div class="icon-area">
          <BIcon class="b-icon-primary mr-100">
            new_releases
          </BIcon>
          <div>{{ $t('updateNotification.message') }}</div>
        </div>
        <BBtn
          size="small"
          type="primary"
          @click="reload"
        >
          <span>{{ $t('general.update') }}</span>
        </BBtn>
      </BLayout>
    </BCard>
  </div>
</template>

<script>
import { mapWaitingActions } from 'vue-wait';
import { mapGetters } from 'vuex';

export default {
  name: 'UpdateNotification',
  computed: {
    ...mapGetters('user', ['revisionCheck']),
  },
  created() {
    this.getRevisionIdAction();
    this.timerObj = this.$setInterval(() => {
      this.getRevisionIdAction();
    }, 30000);
  },
  beforeUnmount() {
    this.$clearInterval(this.timerObj);
  },
  methods: {
    ...mapWaitingActions('user', {
      getRevisionIdAction: 'getRevisionIdWait',
    }),
    reload() {
      window.location.reload(true);
    },
  },
};
</script>

<style lang="scss" scoped>
  .update-notification {
    position: fixed;
    top: $basespace-400;
    width: 400px;
    left: calc(50% - 400px / 2);
    z-index: 10000;
  }

  .icon-area {
    font-size: $fontsize-100;
    text-align: center;
    margin-bottom: $basespace-100;
    display: flex;
  }
</style>
