import { AxiosResponse } from 'axios';
import { ActionTree } from 'vuex';
import { ApiArguments } from '@/api/api_service';
import {
  ApplySequenceMaster200Response,
  GetSequenceMasters200Response,
  SequenceMasterApiApplySequenceMasterRequest,
  SequenceMasterApiGetSequenceMastersRequest,
} from '@/api/openapi';
import { SequenceMasterApiService } from '@/api/user/resources/sequence_master';
import { RootState, UserState } from '@/store/types';
import Waiting from '@/store/waiting';


const actions: ActionTree<UserState, RootState> = { 
  getSequenceMastersAction: async (
    _context,
    apiArguments?: ApiArguments<SequenceMasterApiGetSequenceMastersRequest>,
  ): Promise<AxiosResponse<GetSequenceMasters200Response>> =>
    await waiting.check('getSequenceMastersAction', async () => {
      const api = new SequenceMasterApiService();
      return await api.getSequenceMasters(apiArguments);
    }),
};

const waiting = new Waiting(actions);

export default actions;
