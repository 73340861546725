import messages from '@intlify/unplugin-vue-i18n/messages';
import { createI18n } from 'vue-i18n';

/**
 * `legacy: false` では、this.$tc, this.$teがなぜか存在しない
 * 使用する際は、これを直接importして `i18n.te` のように使用すること
 * @see https://github.com/smartcamp/bales-crm/pull/3825#issuecomment-1590712680
 */
export const i18n = createI18n({
  locale: import.meta.env.VITE_I18N_LOCALE || 'ja',
  legacy: false,
  fallbackLocale: import.meta.env.VITE_I18N_FALLBACK_LOCALE || 'en',
  messages,
});

export const i18nGlobal = i18n.global;
