<template>
  <BModal
    :modal-name="modalName"
    :delegate="bModalDelegate"
    width="800px"
    height="670px"
    @open-modal-event="handleOpen"
  >
    <BHeightAdjuster>
      <BModalHeader
        :title="$t('callList.export.title')"
        @modal-close="handleClose"
      />
      <BModalBody>
        <div class="export-message mb-600">
          <span>{{ $t('export.modalMessage.first') }}</span><span class="fw-bold">{{
            $t('export.modalMessage.middle', {
              num: callTargetsMeta.totalCount
            })
          }}</span><span>{{ $t('export.modalMessage.last') }}</span>
        </div>
        <div class="export-type">
          <div class="file-type">
            <p>{{ $t('export.fileType.title') }}</p>
            <div class="radio-group">
              <template
                v-for="file in fileTypes"
                :key="file"
              >
                <BRadio
                  v-if="file === 'excel'"
                  v-model="selectedFileType"
                  :label="file"
                  class="mb-100 radio-item"
                >
                  {{ $t(`export.fileType.${file}`) }}
                </BRadio>
                <BTooltip
                  v-else
                  top
                >
                  <template #content>
                    {{ $t(`export.tooltip.${file}`) }}
                  </template>
                  <BRadio
                    v-model="selectedFileType"
                    :label="file"
                    class="mb-100 radio-item"
                  >
                    {{ $t(`export.fileType.${file}`) }}
                  </BRadio>
                </BTooltip>
              </template>
            </div>
          </div>
          <div class="data-type mt-400">
            <p>{{ $t('export.dataType.title') }}</p>
            <div class="radio-group">
              <template
                v-for="data in dataTypes"
                :key="data"
              >
                <BTooltip
                  v-if="data === 'full'"
                  top
                >
                  <template #content>
                    <div class="text-center">
                      <span>{{ $t(`export.tooltip.full.first`) }}</span><br>
                      <span>{{ $t(`export.tooltip.full.second`) }}</span>
                    </div>
                  </template>
                  <BRadio
                    v-model="selectedDataType"
                    class="mb-100 radio-item"
                    :label="data"
                  >
                    {{
                      $t(`export.dataType.${data}`)
                    }}
                  </BRadio>
                </BTooltip>
                <BRadio
                  v-else
                  v-model="selectedDataType"
                  class="mb-100 radio-item"
                  :label="data"
                >
                  {{
                    $t(`export.dataType.${data}`)
                  }}
                </BRadio>
              </template>
            </div>
          </div>
        </div>
        <div class="export-button mt-600">
          <BBtn
            type="primary"
            :loading="$wait.is('createLeadExportTaskWait')"
            :disabled="disableExportBtn"
            @click="handleStartExport"
          >
            {{ $t('export.start') }}
          </BBtn>
        </div>
        <div class="count mt-200">
          <p>
            {{ $t('export.count') + '　' }}
            <span class="target">{{ `${target}${$t('general.matter')}` }}</span>
            / {{ `${callTargetsCount}${$t('general.matter')}` }}
          </p>
        </div>
        <div class="export-history">
          <div class="export-history-list header">
            <div class="create-item">
              {{ $t('export.history.headers.createdAt') }}
            </div>
            <div class="file-name-item">
              {{ $t('export.history.headers.fileName') }}
            </div>
            <div class="export-item">
              {{ $t('export.history.headers.download') }}
            </div>
          </div>
          <TransitionGroup
            name="list"
            tag="div"
          >
            <div
              v-for="item in leadExportTasks"
              :key="`export-task-${item.id}`"
              class="export-history-list"
            >
              <div class="create-item">
                {{ getTimeWithDateAndTimeFilter(item.createdAt) }}
              </div>
              <div class="file-name-item">
                {{ fileName(item.s3ObjectKey) }}
              </div>
              <div class="export-item">
                <BTooltip
                  v-if="item.errorData"
                  top
                >
                  <template #content>
                    <div class="text-center">
                      <span>{{ $t(`export.tooltip.failed.first`) }}</span><br>
                      <span>{{ $t(`export.tooltip.failed.second`) }}</span>
                    </div>
                  </template>
                  <div class="item failed" />
                </BTooltip>
                <div
                  v-else-if="item.finishedAt"
                  class="item done"
                />
                <BTooltip
                  v-else
                  top
                >
                  <template #content>
                    {{ $t(`export.tooltip.inProgress`) }}
                  </template>
                  <div class="item" />
                </BTooltip>
                <!-- TODO: b-btnのdisabled時に付与される opacity 属性がヘッダーのstickyと相性が悪いのでb-btnをやめる -->
                <BBtn
                  :type="item.finishedAt ? 'primary' : ''"
                  text
                  :disabled="!!item.errorData || !item.finishedAt"
                  @click="() => download(item.s3ObjectKey)"
                >
                  {{ getFileType(item.fileType) }}
                </BBtn>
              </div>
            </div>
          </TransitionGroup>
        </div>
      </BModalBody>
    </BHeightAdjuster>
  </BModal>
</template>

<script>
import swal from 'sweetalert';
import { mapWaitingActions } from 'vue-wait';
import { mapGetters } from 'vuex';
import Api from '@/api/user';
import errorHandler from '@/mixins/error_handler';
import { altText } from '@/utils/alt';
import { formatShorterDateTime } from '@/utils/date-time';

export default {
  mixins: [errorHandler],
  data() {
    return {
      intervalId: null,
      fileTypes: ['excel', 'utf-8'],
      dataTypes: ['standard', 'full'],
      selectedFileType: 'excel',
      selectedDataType: 'standard',
      bModalDelegate: {
        beforeClose: this.beforeClose,
      },
    };
  },
  computed: {
    ...mapGetters('user', ['leadExportTasks', 'callTargetsMeta', 'callTargetsCount']),
    ...mapGetters('userUi', ['leadListFilterParams']),
    modalName() {
      return 'LeadExportModal';
    },
    disableExportBtn() {
      const LIMIT_EXPORT_FULL_SIZE = 2000;
      const isLimitOver = LIMIT_EXPORT_FULL_SIZE < this.callTargetsMeta.totalCount;
      return (
        this.$wait.is('getLeadExportTasksWait')
          || this.isFull && isLimitOver
      );
    },
    target() {
      if (this.callTargetsMeta == null) return '-';
      return this.callTargetsMeta.totalCount;
    },
    isFull() {
      return this.selectedDataType === this.dataTypes[1];
    },
  },
  methods: {
    ...mapWaitingActions('user', {
      createLeadExportTaskAction: 'createLeadExportTaskWait',
      getLeadExportTasksAction: 'getLeadExportTasksWait',
      getLeadExportTasksBackgroundAction: {
        action: 'getLeadExportTasksAction',
        loader: 'getLeadExportTasksBackgroundWait',
      },
      getLeadsCsvAction: 'getLeadsCsvWait',
      getCallTargetsCountAction: 'getCallTargetsCountWait',
    }),
    async handleOpen() {
      await Promise.all([
        this.getLeadExportTasksAction(),
        this.getCallTargetsCountAction(),
      ]);
      this.intervalId = this.$setInterval(() => {
        this.getLeadExportTasksBackgroundAction();
      }, 3000);
    },
    resetState () {
      this.selectedFileType = 'excel';
      this.selectedDataType = 'standard';
    },
    beforeClose() {
      this.$clearInterval(this.intervalId);
      this.resetState();
    },
    handleClose() {
      this.$modal.hide(this.modalName);
    },
    async handleStartExport() {
      await this.createLeadExportTaskAction({
        body: { lf: this.leadListFilterParams, full: this.isFull, fileType: this.selectedFileType },
        errorHandlers: {
          400: this.defaultHandler,
        },
      });
    },
    async download(s3ObjectKey) {
      try {
        const result = await Api.createExportFileStorageAccessUrl({
          body: { s3ObjectKey },
        });
        const link = document.createElement('a');
        link.href = result.data.url;
        link.click();
      } catch (err) {
        this.$bitterAlert.show({
          text: err.message,
          buttonsCancel: false,
        });
      }
    },
    fileName(s3ObjectKey) {
      if (typeof s3ObjectKey !== 'string') return '';
      return s3ObjectKey.split('/').pop();
    },
    getTimeWithDateAndTimeFilter(time) {
      return altText(formatShorterDateTime(time));
    },
    getFileType(fileType) {
      if (fileType == null) return '';
      switch (fileType) {
        case 'excel':
        case 'utf-8':
          return this.$t(`export.fileType.${fileType}`);
        default:
          return '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .export-message {
    color: $textcolor-light;
  }

  .export-type {
    width: 350px;
    margin: 0 auto;
    .radio-group {
      display: flex;
      justify-content: space-between;
    }

    .radio-item {
      width: 150px;
    }
  }

  .export-button {
    text-align: center;
  }

  .export-history {
    @include m-fixed-width(550px);
    max-height: 182px;
    margin: $basespace-400 auto 0;
    overflow: auto;
  }

  .export-history-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.header {
      position: sticky;
      top: 0;
      height: 30px;
      z-index: 2;
      background: $paper;
      margin-bottom: $basespace-100;
      color: $textcolor-light;
    }
  }

  .file-name-item {
    @include m-fixed-width(250px);
    padding-right: $basespace-100;
  }

  .export-item {
    @include m-fixed-width(150px);
    padding-right: $basespace-100;
    display: flex;
    background: $paper;
  }

  .status-item {
    @include m-fixed-width(150px);
    padding-right: $basespace-100;
  }

  .create-item {
    @include m-fixed-width(110px);
  }

  .disable-delimiter {
    color: $textcolor-light;
  }

  .item {
    display: flex;
    align-items: center;

    &::before {
      content: '';
      border-radius: 50%;
      width: 10px;
      height: 10px;
      margin-right: 8px;
      background-color: $basecolor-warning;
    }

    &.done::before {
      background-color: $basecolor-success;
    }

    &.failed::before {
      background-color: $basecolor-error;
    }
  }

  .count {
    text-align: center;
    border-radius: $basespace-100;
    border-bottom: 1px solid $bdcolor-base;

    p {
      padding: 14px 0;
      margin-bottom: 0;
    }
  }

  .target {
    font-weight: bolder;
    font-size: larger;
  }

  .old-history-title {
    font-weight: bolder;
  }

  :deep(.el-radio__input.is-checked .el-radio__inner) {
    border-color: $basecolor-primary;
    background: $basecolor-primary;
  }
</style>
