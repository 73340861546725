/* tslint:disable */
/* eslint-disable */
/**
 * openapi
 * bales cloud
 *
 * The version of the OpenAPI document: 1.0
 * Contact: test@example.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PostSendgridApiSettingRequest } from '../models';
// @ts-ignore
import { SendgridSubUser } from '../models';
/**
 * SendgridSubUserApi - axios parameter creator
 * @export
 */
export const SendgridSubUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Sendgridサブユーザー取得API
         * @summary Sendgridサブユーザー取得API
         * @param {number} sendgridSubUserId sendgridSubUser ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSendgridSubUser: async (sendgridSubUserId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendgridSubUserId' is not null or undefined
            assertParamExists('getSendgridSubUser', 'sendgridSubUserId', sendgridSubUserId)
            const localVarPath = `/api/admin/sendgrid_sub_users/{sendgridSubUserId}`
                .replace(`{${"sendgridSubUserId"}}`, encodeURIComponent(String(sendgridSubUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sendgrid API設定作成API
         * @summary Sendgrid API設定作成API
         * @param {PostSendgridApiSettingRequest} [postSendgridApiSettingRequest] Sendgrid API設定登録APIのリクエストボディ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSendgridApiSetting: async (postSendgridApiSettingRequest?: PostSendgridApiSettingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/sendgrid_sub_users/create_sendgrid_api_settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postSendgridApiSettingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SendgridSubUserApi - functional programming interface
 * @export
 */
export const SendgridSubUserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SendgridSubUserApiAxiosParamCreator(configuration)
    return {
        /**
         * Sendgridサブユーザー取得API
         * @summary Sendgridサブユーザー取得API
         * @param {number} sendgridSubUserId sendgridSubUser ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSendgridSubUser(sendgridSubUserId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SendgridSubUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSendgridSubUser(sendgridSubUserId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sendgrid API設定作成API
         * @summary Sendgrid API設定作成API
         * @param {PostSendgridApiSettingRequest} [postSendgridApiSettingRequest] Sendgrid API設定登録APIのリクエストボディ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSendgridApiSetting(postSendgridApiSettingRequest?: PostSendgridApiSettingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSendgridApiSetting(postSendgridApiSettingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SendgridSubUserApi - factory interface
 * @export
 */
export const SendgridSubUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SendgridSubUserApiFp(configuration)
    return {
        /**
         * Sendgridサブユーザー取得API
         * @summary Sendgridサブユーザー取得API
         * @param {number} sendgridSubUserId sendgridSubUser ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSendgridSubUser(sendgridSubUserId: number, options?: any): AxiosPromise<SendgridSubUser> {
            return localVarFp.getSendgridSubUser(sendgridSubUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * Sendgrid API設定作成API
         * @summary Sendgrid API設定作成API
         * @param {PostSendgridApiSettingRequest} [postSendgridApiSettingRequest] Sendgrid API設定登録APIのリクエストボディ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSendgridApiSetting(postSendgridApiSettingRequest?: PostSendgridApiSettingRequest, options?: any): AxiosPromise<void> {
            return localVarFp.postSendgridApiSetting(postSendgridApiSettingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SendgridSubUserApi - interface
 * @export
 * @interface SendgridSubUserApi
 */
export interface SendgridSubUserApiInterface {
    /**
     * Sendgridサブユーザー取得API
     * @summary Sendgridサブユーザー取得API
     * @param {number} sendgridSubUserId sendgridSubUser ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SendgridSubUserApiInterface
     */
    getSendgridSubUser(sendgridSubUserId: number, options?: AxiosRequestConfig): AxiosPromise<SendgridSubUser>;

    /**
     * Sendgrid API設定作成API
     * @summary Sendgrid API設定作成API
     * @param {PostSendgridApiSettingRequest} [postSendgridApiSettingRequest] Sendgrid API設定登録APIのリクエストボディ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SendgridSubUserApiInterface
     */
    postSendgridApiSetting(postSendgridApiSettingRequest?: PostSendgridApiSettingRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * Request parameters for getSendgridSubUser operation in SendgridSubUserApi.
 * @export
 * @interface SendgridSubUserApiGetSendgridSubUserRequest
 */
export interface SendgridSubUserApiGetSendgridSubUserRequest {
    /**
     * sendgridSubUser ID
     * @type {number}
     * @memberof SendgridSubUserApiGetSendgridSubUser
     */
    readonly sendgridSubUserId: number
}

/**
 * Request parameters for postSendgridApiSetting operation in SendgridSubUserApi.
 * @export
 * @interface SendgridSubUserApiPostSendgridApiSettingRequest
 */
export interface SendgridSubUserApiPostSendgridApiSettingRequest {
    /**
     * Sendgrid API設定登録APIのリクエストボディ
     * @type {PostSendgridApiSettingRequest}
     * @memberof SendgridSubUserApiPostSendgridApiSetting
     */
    readonly postSendgridApiSettingRequest?: PostSendgridApiSettingRequest
}

/**
 * SendgridSubUserApi - object-oriented interface
 * @export
 * @class SendgridSubUserApi
 * @extends {BaseAPI}
 */
export class SendgridSubUserApi extends BaseAPI implements SendgridSubUserApiInterface {
    /**
     * Sendgridサブユーザー取得API
     * @summary Sendgridサブユーザー取得API
     * @param {SendgridSubUserApiGetSendgridSubUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SendgridSubUserApi
     */
    public getSendgridSubUser(requestParameters: SendgridSubUserApiGetSendgridSubUserRequest, options?: AxiosRequestConfig) {
        return SendgridSubUserApiFp(this.configuration).getSendgridSubUser(requestParameters.sendgridSubUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sendgrid API設定作成API
     * @summary Sendgrid API設定作成API
     * @param {SendgridSubUserApiPostSendgridApiSettingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SendgridSubUserApi
     */
    public postSendgridApiSetting(requestParameters: SendgridSubUserApiPostSendgridApiSettingRequest = {}, options?: AxiosRequestConfig) {
        return SendgridSubUserApiFp(this.configuration).postSendgridApiSetting(requestParameters.postSendgridApiSettingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
