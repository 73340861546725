<template>
  <BModal
    :modal-name="modalName"
    :width="modalWidth"
    :height="modalHeight"
  >
    <BHeightAdjuster>
      <BModalHeader
        :title="$t('leadView.title')"
        @modal-close="$modal.hide(modalName)"
      />
      <BModalBody v-loading="$wait.is('createLeadViewWait')">
        <div class="message mb-600">
          {{ $t('leadView.modalMessage') }}
        </div>
        <BLayout
          class="mt-600"
          column
        >
          <BListItem>
            <template #header>
              <div>{{ $t('leadView.name' ) }}</div>
            </template>
            <BInput
              v-model="leadViewName"
              v-model:valid="valid"
              autofocus
              :rules="[requiredRule]"
              @keypress-enter="handleCreateLeadView"
            />
          </BListItem>
          <BListItem class="mt-200">
            <template #header>
              <div>{{ $t('leadView.description' ) }}</div>
            </template>
            <BTextarea
              v-model="leadViewDescription"
              resize="none"
              :autosize="{ minRows: 4, maxRows: 10 }"
            />
          </BListItem>
        </BLayout>
      </BModalBody>
      <BModalFooter>
        <BLayout
          class="mt-200"
          justify-center
        >
          <BBtn
            type="primary"
            :disabled="!valid"
            :loading="$wait.is('createLeadViewWait')"
            @click="handleCreateLeadView"
          >
            {{ $t('general.save.text') }}
          </BBtn>
        </BLayout>
      </BModalFooter>
    </BHeightAdjuster>
  </BModal>
</template>

<script>
import { mapWaitingActions } from 'vue-wait';
import { mapGetters } from 'vuex';
import errorHandler from '@/mixins/error_handler';
import inputValidation from '@/mixins/input_validation';
import queryParameter from '@/mixins/query_parameter';

export default {
  mixins: [inputValidation, errorHandler, queryParameter],
  data () {
    return {
      valid: false,
      leadViewName: '',
      leadViewDescription: '',
      modalWidth: '580px',
      modalHeight: '580px',
    };
  },
  computed: {
    ...mapGetters('user', [
      'leadViews',
    ]),
    ...mapGetters('userUi', [
      'leadListFilterParams',
      'leadListSelectedColumns',
      'leadListSortKey',
      'leadListSortOrder',
    ]),
    modalName () {
      return 'leadViewCreateModal';
    },
  },
  methods: {
    ...mapWaitingActions('user', {
      createLeadViewAction: 'createLeadViewWait',
      setLeadViewAction: 'setLeadViewWait',
    }),
    async handleCreateLeadView () {
      const displayColumns = this.$JSON.stringify(this.leadListSelectedColumns);
      const response = await this.createLeadViewAction({
        body: {
          name: this.leadViewName,
          description: this.leadViewDescription,
          filterParams: { lf: this.leadListFilterParams },
          displayColumns,
          sortKey: this.leadListSortKey,
          sortOrder: this.leadListSortOrder,
        },
        errorHandlers: {
          422: this.defaultHandler,
        },
      });
      if (response.leadView) {
        this.setLeadViewAction(response.leadView);
        this.setQueryParameter({ leadViewId: response.leadView.id });
        this.$toast.show({ message: this.$t('general.done.of', { action: this.$t('leadView.saveMessage') }) });
      }
      this.$modal.hide(this.modalName);
      this.leadViewName = '';
      this.leadViewDescription = '';
    },
  },
};
</script>

<style lang="scss" scoped>
  .message {
    color: $textcolor-light;
  }
</style>
