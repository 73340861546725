<template>
  <div class="step-end-container">
    <div class="icon">
      <BBtn
        fab
        type="success"
        class="step-end-button"
      >
        <BCustomIcon
          icon-class="b-fire"
          size="medium"
          type="success"
          class="step-end-icon increment-priority"
        />
      </BBtn>
    </div>
    <div class="text">
      {{ $t('sequence.step.endText') }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.step-end-container .step-end-button .step-end-icon.increment-priority {
  color: $basecolor-success;
}
.icon {
  margin-bottom: 4px;
  pointer-events: none;
}
.text {
  font-size: $fontsize-100;
}
</style>
