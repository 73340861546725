<template>
  <BIconWithActionMenu
    :width="900"
    :badge-count="mailNotificationCount"
    :auto-fetch-interval="30000"
    @auto-fetch-interval-passed="getMailNotificationCountAction"
    @after-open="handleAfterOpen"
  >
    <template #icon>
      <BIcon>email</BIcon>
    </template>
    <div class="mail-notification-header">
      <div class="mail-notification-header-item header-title">
        {{ $t('mailNotificationEvent.title') }}
      </div>
    </div>
    <div class="mail-notification-body">
      <VWait
        for="getMailNotificationWait"
        :style="{ height: '100%' }"
      >
        <template #waiting>
          <BPlaceholder
            v-loading="true"
            height="50vh"
            element-loading-background="transparent"
          />
        </template>
        <MailNotificationTimeline
          :v-loading="$wait.is('getMailNotificationAction')"
          :items="mailNotifications"
        />
      </VWait>
    </div>
  </BIconWithActionMenu>
</template>

<script>
import { mapWaitingActions } from 'vue-wait';
import { mapGetters } from 'vuex';
import MailNotificationTimeline from '@/components/organisms/user/mailNotification/MailNotificationTimeline.vue';

export default {
  components: {
    MailNotificationTimeline,
  },
  computed: {
    ...mapGetters('user', ['mailNotifications', 'mailNotificationCount']),
  },
  created() {
    this.getMailNotificationCountAction();
  },
  methods: {
    ...mapWaitingActions('user', {
      getMailNotificationAction: 'getMailNotificationWait',
      getMailNotificationCountAction: 'getMailNotificationCountWait',
      postMailNotificationConfirmedAction: 'postMailNotificationConfirmedWait',
    }),
    async handleAfterOpen() {
      await this.getMailNotificationAction();
      this.postMailNotificationConfirmedAction();
    },
  },
};
</script>

<style lang="scss" scoped>
  .mail-notification-header {
    display: flex;
    width: 100%;
    height: 70px;
    border-bottom: 1px solid $bdcolor-base;
    padding: 0 $basespace-200;

    &-item {
      margin: $basespace-250 0 $basespace-250 $basespace-600;

      &.header-title {
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        margin: $basespace-500 $basespace-500 0 50px;
      }
    }
  }

  .mail-notification-body {
    @include m-fixed-height(510px);
    overflow-y: auto;
  }

  .mail-notification-title {
    text-align: center;
    font-size: $fontsize-300;
    font-weight: 600;
    margin-bottom: $basespace-200;
  }

  .mail-notification-footer {
    position: absolute;
    bottom: 0;
    display: flex;
    width: 100%;
    height: 60px;
    justify-content: space-around;
    background: $bgcolor-base;

    .footer-item {
      line-height: 60px;
    }
  }
</style>
