<template>
  <VWait
    for="fetchSequenceStepMasters"
    class="sequence-steps-container"
  >
    <EditableSequenceSteps
      v-model:is-menu-opens="isMenuOpens"
      :sequence-steps="internalSequenceSteps"
      :enabled-to-add-steps="false"
      :steps-actions-with-disabled="stepsActionsWithDisabled"
      v-on="stepHandlers"
    />
  </VWait>
  <div class="side-menu-container">
    <SequenceNextActionForm
      :sequence-step="(formSelectedSequenceStep as TPartialFormNextActionSequenceStep)"
      :is-open="isNextActionFormOpen"
      @click:cancel="handleFormCancel"
      @click:save="handleFormSave"
    />
  </div>
  <div class="side-menu-container">
    <SequenceCallActionForm
      :sequence-step="(formSelectedSequenceStep as TPartialFormCallActionSequenceStep)"
      :is-open="isCallActionFormOpen"
      @click:cancel="handleFormCancel"
      @click:save="handleFormSave"
    />
  </div>
  <div class="side-menu-container">
    <SequenceDirectMailForm
      mode="form"
      :sequence-step="(formSelectedSequenceStep as TPartialFormDirectMailSequenceStep)"
      :is-open="isDirectMailFormOpen"
      :with-sender="false"
      @click:cancel="handleFormCancel"
      @click:save-as-draft="handleFormSaveAsDraft"
      @click:save-as-reserve="handleFormSaveAsReserve"
    />
  </div>
</template>

<script lang="ts" setup>
import EditableSequenceSteps from '@/components/organisms/user/general/sequence/EditableSequenceSteps.vue';
import SequenceCallActionForm from '@/components/organisms/user/general/sequence/SequenceCallActionForm.vue';
import SequenceDirectMailForm from '@/components/organisms/user/general/sequence/SequenceDirectMailForm.vue';
import SequenceNextActionForm from '@/components/organisms/user/general/sequence/SequenceNextActionForm.vue';
import {
  TFormSequenceStep,
  TPartialFormDirectMailSequenceStep,
  TPartialFormCallActionSequenceStep,
  TPartialFormNextActionSequenceStep,
  TStepHandlers,
} from '@/components/organisms/user/general/sequence/types';
import { useSequenceStepActions, useStepEditing, useStepForm } from '@/composable/user/sequence/step-actions';
import { useSequenceStepMasterApplyActions } from '@/composable/user/sequence/step-menu-actions';

type TProps = {
  sequenceStepMasterData: TFormSequenceStep[];
};
type TEmit = {
  'update': [sequenceSteps: TFormSequenceStep[]];
};

const props = defineProps<TProps>();
const emit = defineEmits<TEmit>();

const { getStepsActions } = useSequenceStepMasterApplyActions();
const stepHandlers: TStepHandlers = {
  'click:edit': (index: number) => handleClickEdit(index),
  'click:sendImmediately': (index: number) => handleImmediatelyClick(index),
  'click:startImmediately': (index: number) => handleImmediatelyClick(index),
  'click:cancelSendImmediately': (index: number) => handleImmediatelyClick(index),
  'click:cancelStartImmediately': (index: number) => handleImmediatelyClick(index),
};

const handleUpdate = async (steps: TFormSequenceStep[]) => {
  emit('update', steps);
};

const {
  internalSequenceSteps,
  isMenuOpens,
  stepsActionsWithDisabled,
  closeMenu,
} = useSequenceStepActions(
  () => props.sequenceStepMasterData,
  getStepsActions,
  handleUpdate,
);
const {
  isNextActionFormOpen,
  isCallActionFormOpen,
  isDirectMailFormOpen,
  openStepForm,
  closeStepForm,
  saveStep,
  saveStepAsDraft,
  saveStepAsReserve,
  formSelectedSequenceStep,
} = useStepForm();

const { openStepFormToEdit } = useStepEditing(internalSequenceSteps, openStepForm);

const handleClickEdit = (index: number) => {
  closeMenu(index),
  openStepFormToEdit(index);
};
const handleFormCancel = () => {
  closeStepForm();
};
const handleFormSave = (item: TFormSequenceStep) => {
  saveStep(item);
  closeStepForm();
};
const handleFormSaveAsDraft = (item: TFormSequenceStep) => {
  saveStepAsDraft(item);
  closeStepForm();
};
const handleFormSaveAsReserve = (item: TFormSequenceStep) => {
  saveStepAsReserve(item);
  closeStepForm();
};
const handleImmediatelyClick = (index: number) => {
  closeMenu(index);
  // NOTE: 変更されたメニュー文言がトランジション中に見えるのを防ぐため間を空ける
  setTimeout(async () => {
    internalSequenceSteps.value[index].immediately = !internalSequenceSteps.value[index].immediately;
  }, 500);
};
</script>

<style scoped lang="scss">
.side-menu-container {
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
}
</style>
