<template>
  <BModalBody v-loading="$wait.is(['getCallTargetsWait', 'getCallTargetsMetaWait'])">
    <BDivider class="my-200" />
    <BLayout>
      <VWait
        class="lead-view-area"
        for="none"
        :class="{ loaded: !$wait.is('getLeadViewActionWait') }"
        :visible="$wait.is('getLeadViewActionWait')"
      >
        <template #waiting>
          <BContentLoader>
            <rect
              x="0"
              y="0"
              rx="3"
              ry="3"
              width="150"
              height="10"
            />
          </BContentLoader>
        </template>
        <BCustomIcon
          icon-class="b-list"
        />
        <LeadViewSelectPanel
          class="ml-300"
          list-height="55vh"
          @after-set-lead-view="fetch"
        />
      </VWait>
    </BLayout>
    <BLayout
      column
      class="layout-height"
    >
      <ListPageControl>
        <FilterSelect />
        <ListColumnSelect
          :except-info="['duplicateInfo']"
          :except-columns="['operatorName']"
        />
      </ListPageControl>
      <FilterControlPanel />
      <div
        v-if="callTargets.length > 0"
        class="lead-list-area"
      >
        <LeadTable
          :call-targets="callTargets"
          :call-targets-meta="callTargetsMeta"
          @update:selected-call-target-ids="handleSelectedCallTargetIdsUpdate"
        />
      </div>
      <BEmptyBox
        v-else
        class="mt-500 mb-600 pt-500 pb-500"
        display-only
      >
        {{ $t('callTarget.notMatched') }}
      </BEmptyBox>
    </BLayout>
  </BModalBody>
  <BModalFooter>
    <BLayout class="mt-200">
      <BLayout
        align-center
        justify-center
      >
        <div class="mr-300">
          {{ $t('general.select.text') }}
          <span class="fs-500 fw-bold mr-100 ml-100">{{ callTargetIds.length }}</span>
          {{ $t('general.matter') }}
        </div>
        <SelectSequenceOwner
          v-model="selectedOwnerId"
          class="mr-400"
        />
        <BBtn
          type="primary"
          :loading="isLoading"
          :disabled="isAssignDisabled || isLoading"
          @click="handelApply"
        >
          {{ $t('general.toNext') }}
        </BBtn>
      </BLayout>
    </BLayout>
  </BModalFooter>
</template>

<script>
import lodash from 'lodash';
import { defineComponent } from 'vue';
import { mapWaitingActions } from 'vue-wait';
import { mapGetters } from 'vuex';
import FilterControlPanel from '@/components/organisms/user/leadList/filter/FilterControlPanel.vue';
import FilterSelect from '@/components/organisms/user/leadList/filter/FilterSelect.vue';
import LeadTable from '@/components/organisms/user/leadList/LeadTable.vue';
import ListColumnSelect from '@/components/organisms/user/leadList/ListColumnSelect.vue';
import ListPageControl from '@/components/organisms/user/leadList/ListPageControl.vue';
import LeadViewSelectPanel from '@/components/organisms/user/LeadViewSelectPanel.vue';
import { useSequenceStepMasters } from '@/composable/user/sequence/sequence-step-masters';
import SelectSequenceOwner from '../../general/sequence/SelectSequenceOwner.vue';

export default defineComponent({
  components: {
    LeadViewSelectPanel,
    LeadTable,
    FilterSelect,
    ListColumnSelect,
    ListPageControl,
    FilterControlPanel,
    SelectSequenceOwner,
  },
  props: {
    sequenceMasterId: {
      type: Number,
      required: true,
    },
  },
  emits: [
    'afterApply',
    'selectCallTargetIds',
  ],
  setup(props) {
    const {
      sequenceStepMasters,
      fetchSequenceStepMasters,
    } = useSequenceStepMasters(props.sequenceMasterId);

    fetchSequenceStepMasters('fetchSequenceStepMasters');

    return {
      sequenceStepMasters,
    };
  },
  data () {
    return {
      tabLabelItems: [
        { label: this.$t('sequence.lead'), slotName: 'lead' },
      ],
      callTargetIds: [],
      failedCallTargets: [],
      selectedOwnerId: undefined,
    };
  },
  computed: {
    ...mapGetters('user', [
      'currentUser',
      'callTargets',
      'callTargetsMeta',
    ]),
    ...mapGetters('userUi', [
      'leadListFilterParams',
    ]),
    isAssignDisabled() {
      return this.callTargetIds.length === 0;
    },
    isLoading() {
      return this.$wait.is('applySequenceMasterWait');
    },
  },
  watch: {
    page() {
      this.fetch();
    },
    displayNumber() {
      this.fetch();
    },
  },
  beforeUnmount() {
    this.reset();
  },
  mounted() {
    this.reset();
    this.fetch();
  },
  methods: {
    ...mapWaitingActions('user', {
      getCallTargetsAction: 'getCallTargetsWait',
      getCallTargetsMetaAction: 'getCallTargetsMetaWait',
      clearLeadViewAction: 'clearLeadViewAction',
    }),
    ...mapWaitingActions('userUi', {
      setLeadListSelectedColumnsAction: 'setLeadListSelectedColumnsWait',
      setLeadListFilterParamsAction: 'setLeadListFilterParamsAction',
      clearLeadListFilterParamsAction: 'clearLeadListFilterParamsAction',
      clearLeadListSortKeyAction: 'clearLeadListSortKeyAction',
      clearLeadListSortOrderAction: 'clearLeadListSortOrderAction',
      resetLeadListSelectedColumnsAction: 'resetLeadListSelectedColumnsAction',
    }),
    handleSelectedCallTargetIdsUpdate(selectedCallTargetIds) {
      this.callTargetIds = selectedCallTargetIds;
    },
    fetch() {
      const filterParams = lodash.cloneDeep(this.leadListFilterParams);
      filterParams.sequence_latest_statuses =  {
        exc: { or: ['registered', 'stopped'] },
      };
      this.setLeadListSelectedColumnsAction([
        'companyName',
        'targetName',
        'lastActivityAt',
        'lastActivityTypeText',
        'callTargetCreatedAt',
        'operatorName',
        'email',
      ]);
      this.setLeadListFilterParamsAction(filterParams);
      Promise.all([
        this.getCallTargetsAction(),
        this.getCallTargetsMetaAction(),
      ]);
    },
    reset() {
      this.callTargetIds = [];
      this.resetLeadListSelectedColumnsAction();
      this.clearLeadListFilterParamsAction();
      this.clearLeadListSortKeyAction();
      this.clearLeadListSortOrderAction();
      this.clearLeadViewAction();
    },
    handelApply() {
      this.$emit('selectCallTargetIds', this.callTargetIds, this.selectedOwnerId);
    },
  },
});
</script>

<style lang="scss" scoped>
.lead-list-area {
  @include m-fixed-height(calc(100% - 60px));
  overflow-y: auto;
}

.b-modal-body {
  padding: 0;
}

.b-modal-footer {
  @include m-fixed-height(80px);
  border-top: 1px solid $bdcolor-light;
}
.layout-height{
  min-height: 800px;
}

:deep(.el-tabs__nav-wrap) {
  padding-left: 20px;
}
.lead-view-area {
  width: 100%;
  margin-bottom: 10px;
  margin-left: 30px;

  &.loaded {
    display: flex;
  }
}
:deep(.select-user .type-select) {
  width: 20rem;
}
</style>
