<template>
  <ElTabs
    v-model="activeName"
    :tab-position="position"
    :type="type"
    :before-leave="beforeLeave"
    @tab-click="handleTabClick"
  >
    <ElTabPane
      v-for="(labelItem, index) in labelItems"
      :key="index"
      :label="labelItem.label"
      :name="labelItem.slotName"
    >
      <slot :name="labelItem.slotName" />
    </ElTabPane>
  </ElTabs>
</template>

<script lang="ts" setup>
import type { TabsPaneContext } from 'element-plus';
import { ref, watch } from 'vue';

type TElTabsType = '' | 'card' | 'border-card';

export type TLableItem = {
  label: string;
  slotName: string;
};

type TProps = {
  modelValue?: string;
  position?: string;
  type?: TElTabsType;
  labelItems?: TLableItem[];
  beforeLeave?: (activeName: string) => void;
};
type TEmit = {
  (event: 'update:modelValue', activeName: string): void;
  (event: 'click:tab', tabName: string): void;
  (event: 'before:leave', tabName: string): void;
};

const props = withDefaults(defineProps<TProps>(), {
  modelValue: '',
  position: 'top',
  type: '',
  labelItems: () => [],
  beforeLeave: () => true,
});
const emit = defineEmits<TEmit>();

const activeName = ref(props.modelValue || props.labelItems[0]?.slotName || '');
watch(activeName, (value) => {
  emit('update:modelValue', value);
});

const handleTabClick = (pane: TabsPaneContext) => {
  emit('click:tab', `${pane.paneName}`);
};
</script>

<style lang="scss" scoped>
.el-tabs--left {
  :deep(.el-tabs__header) {
    &.is-left {
      margin-right: 28px
    }
  }
}
:deep(.el-tabs__item) {
  &.is-active{
    font-weight: 600;
    color: $basecolor-primary;
  }
  &:hover {
    opacity: 0.5;
  }
}
:deep(.el-tabs__active-bar) {
  background-color: $basecolor-primary;
}
</style>
