<template>
  <FilterDetailPopover
    ref="popover"
    v-model:is-include="isInclude"
    :title="title"
    :width="260"
    is-switch
    :is-display-only="isDisplayOnly"
    :key-name="fieldKey"
    is-custom
    @hide="hide"
    @show="popoverShow"
  >
    <template #reference>
      <BLayout align-center>
        <BListItem>
          <template #header>
            <BLayout justify-space-between>
              <div class="item-title">
                {{ title }}
              </div>
              <div
                class="grouping-text"
                :class="{ 'is-include': incExc === 'inc', 'inc-text': incExc === 'inc', 'exc-text': incExc === 'exc' }"
              >
                {{ groupingText }}
              </div>
            </BLayout>
          </template>
          <div>{{ displayValue }}</div>
        </BListItem>
      </BLayout>
    </template>
    <div>
      <BCheckbox
        v-model="noInput"
        class="mt-100 mb-100"
        :label="$t('general.blankInput')"
      />
      <BInput
        ref="bInput"
        v-model="internalCustomFieldItem"
        :placeholder="$t('general.search.placeholder')"
        :disabled="noInput"
      />
    </div>
  </FilterDetailPopover>
</template>

<script>
import lodash from 'lodash';
import { mapGetters } from 'vuex';
import FilterDetailPopover from '@/components/organisms/user/leadList/filter/FilterDetailPopover.vue';
import { getIncExc } from '@/composable/user/leadList/lead-filter';

const NO_INPUT_SIGNATURE = '___no_input___';

export default {
  components: {
    FilterDetailPopover,
  },
  props: {
    title: String,
    value: String,
    customFieldItem: {
      type: Object,
      default () {
        return { inc: { or: null } };
      },
    },
    fieldKey: {
      type: String,
      require: true,
    },
    isDisplayOnly: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'hide',
  ],
  data () {
    return {
      internalCustomFieldItem: null,
      isInclude: getIncExc(this.target) !== 'exc',
    };
  },
  computed: {
    ...mapGetters('user', [
      'customFields',
    ]),
    ...mapGetters('userUi', [
      'leadListFilterParams',
    ]),
    blankItem () {
      return { label: this.$t('general.blankInput'), value: -1 };
    },
    groupingText () {
      if (this.target == null || this.targetDetail.length === 0) return;
      return this.$t(`general.filter.${this.incExc}`);
    },
    target () {
      return this.customFieldItem;
    },
    targetDetail () {
      if (this.target == null) return [];
      return this.target[this.incExc].or;
    },
    incExc () {
      return getIncExc(this.target);
    },
    grouping () {
      return this.isInclude ? 'inc' : 'exc';
    },
    noInput: {
      get () {
        return this.internalCustomFieldItem === NO_INPUT_SIGNATURE;
      },
      set (v) {
        this.internalCustomFieldItem = v === true ? NO_INPUT_SIGNATURE : '';
      },
    },
    displayValue () {
      return this.value === NO_INPUT_SIGNATURE ? this.$t('general.blankInput') : this.value;
    },
  },
  methods: {
    popoverShow () {
      this.internalCustomFieldItem = lodash.cloneDeep(this.target[this.incExc].or);
      // MEMO: フィルター選択後、そのままInputを開いたときにフォーカスが外れるので
      setTimeout(() => this.$refs.bInput.forceFocus(), 200);
      this.isInclude = this.incExc !== 'exc';
    },
    hide () {
      this.$emit('hide', { [this.grouping]: { or: this.internalCustomFieldItem } });
    },
  },
};
</script>

<style lang="scss" scoped>
  .grouping-text {
    color: $basecolor-cold;
    margin-left: 2px;

    &.is-include {
      color: $basecolor-hot;
    }

    &.inc-text {
      min-width: 24px;
    }

    &.exc-text {
      min-width: 48px;
    }
  }
</style>
