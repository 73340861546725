<template>
  <div
    class="b-expansion-panel"
    :class="{ active: isActive }"
  >
    <div class="b-expansion-panel-header">
      <BIcon
        class="header-icon"
        @click="isActive = !isActive"
      >
        arrow_drop_down
      </BIcon>
      <div class="header-content">
        <slot name="header" />
      </div>
    </div>
    <BSlideUpDown
      class="b-expansion-panel-content"
      :active="isActive"
    >
      <slot />
    </BSlideUpDown>
  </div>
</template>

<script>
export default {
  props: {
    expand: Boolean,
  },
  data () {
    return {
      isActive: this.expand,
    };
  },
  watch: {
    expand (value) {
      this.isActive = value;
    },
  },
};
</script>

<style lang="scss" scoped>
  .b-expansion-panel {
    &.active > .b-expansion-panel-header {
      .header-icon {
        transform: rotate(0deg);
      }
    }
  }

  .b-expansion-panel-header {
    display: flex;
    align-items: center;

    .header-icon {
      transition: all 300ms $standard-easing;
      cursor: pointer;
      transform: rotate(-90deg);
      font-size: $fontsize-500;
      width: 20px;

      &:hover {
        opacity: .5;
      }
    }

    .header-content {
      width: calc(100% - 20px);
    }
  }

  .b-expansion-panel-content {
    padding: $basespace-100 0 0 $basespace-400;
  }
</style>
