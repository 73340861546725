/* tslint:disable */
/* eslint-disable */
/**
 * openapi
 * bales cloud
 *
 * The version of the OpenAPI document: 1.0
 * Contact: test@example.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateMfaSetting201Response } from '../models';
// @ts-ignore
import { GetTrigger200Response } from '../models';
// @ts-ignore
import { GetTriggers200Response } from '../models';
// @ts-ignore
import { PostTriggerRequest } from '../models';
// @ts-ignore
import { PutTriggerStatusRequest } from '../models';
/**
 * TriggerApi - axios parameter creator
 * @export
 */
export const TriggerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * トリガー削除API
         * @summary トリガー削除API
         * @param {number} triggerId トリガーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTrigger: async (triggerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'triggerId' is not null or undefined
            assertParamExists('deleteTrigger', 'triggerId', triggerId)
            const localVarPath = `/api/user/triggers/{triggerId}`
                .replace(`{${"triggerId"}}`, encodeURIComponent(String(triggerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * トリガー取得API
         * @summary トリガー取得API
         * @param {number} triggerId トリガーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrigger: async (triggerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'triggerId' is not null or undefined
            assertParamExists('getTrigger', 'triggerId', triggerId)
            const localVarPath = `/api/user/triggers/{triggerId}`
                .replace(`{${"triggerId"}}`, encodeURIComponent(String(triggerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * トリガー一覧取得API
         * @summary トリガー一覧取得API
         * @param {number} [currentPage] リードリストでの現在のページネーション
         * @param {number} [ownerId] 所有者ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTriggers: async (currentPage?: number, ownerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/triggers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)

            if (currentPage !== undefined) {
                localVarQueryParameter['currentPage'] = currentPage;
            }

            if (ownerId !== undefined) {
                localVarQueryParameter['ownerId'] = ownerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * トリガー作成API
         * @summary トリガー作成API
         * @param {PostTriggerRequest} [postTriggerRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTrigger: async (postTriggerRequest?: PostTriggerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/triggers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postTriggerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * トリガー更新API
         * @summary トリガー更新API
         * @param {number} triggerId トリガーID
         * @param {PostTriggerRequest} [postTriggerRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTrigger: async (triggerId: number, postTriggerRequest?: PostTriggerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'triggerId' is not null or undefined
            assertParamExists('putTrigger', 'triggerId', triggerId)
            const localVarPath = `/api/user/triggers/{triggerId}`
                .replace(`{${"triggerId"}}`, encodeURIComponent(String(triggerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postTriggerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * トリガー実行設定API
         * @summary トリガー実行設定API
         * @param {number} triggerId トリガーID
         * @param {PutTriggerStatusRequest} [putTriggerStatusRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTriggerStatus: async (triggerId: number, putTriggerStatusRequest?: PutTriggerStatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'triggerId' is not null or undefined
            assertParamExists('putTriggerStatus', 'triggerId', triggerId)
            const localVarPath = `/api/user/triggers/{triggerId}/status`
                .replace(`{${"triggerId"}}`, encodeURIComponent(String(triggerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putTriggerStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TriggerApi - functional programming interface
 * @export
 */
export const TriggerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TriggerApiAxiosParamCreator(configuration)
    return {
        /**
         * トリガー削除API
         * @summary トリガー削除API
         * @param {number} triggerId トリガーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTrigger(triggerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTrigger(triggerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * トリガー取得API
         * @summary トリガー取得API
         * @param {number} triggerId トリガーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTrigger(triggerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTrigger200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTrigger(triggerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * トリガー一覧取得API
         * @summary トリガー一覧取得API
         * @param {number} [currentPage] リードリストでの現在のページネーション
         * @param {number} [ownerId] 所有者ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTriggers(currentPage?: number, ownerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTriggers200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTriggers(currentPage, ownerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * トリガー作成API
         * @summary トリガー作成API
         * @param {PostTriggerRequest} [postTriggerRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postTrigger(postTriggerRequest?: PostTriggerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateMfaSetting201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postTrigger(postTriggerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * トリガー更新API
         * @summary トリガー更新API
         * @param {number} triggerId トリガーID
         * @param {PostTriggerRequest} [postTriggerRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putTrigger(triggerId: number, postTriggerRequest?: PostTriggerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putTrigger(triggerId, postTriggerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * トリガー実行設定API
         * @summary トリガー実行設定API
         * @param {number} triggerId トリガーID
         * @param {PutTriggerStatusRequest} [putTriggerStatusRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putTriggerStatus(triggerId: number, putTriggerStatusRequest?: PutTriggerStatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putTriggerStatus(triggerId, putTriggerStatusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TriggerApi - factory interface
 * @export
 */
export const TriggerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TriggerApiFp(configuration)
    return {
        /**
         * トリガー削除API
         * @summary トリガー削除API
         * @param {number} triggerId トリガーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTrigger(triggerId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTrigger(triggerId, options).then((request) => request(axios, basePath));
        },
        /**
         * トリガー取得API
         * @summary トリガー取得API
         * @param {number} triggerId トリガーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrigger(triggerId: number, options?: any): AxiosPromise<GetTrigger200Response> {
            return localVarFp.getTrigger(triggerId, options).then((request) => request(axios, basePath));
        },
        /**
         * トリガー一覧取得API
         * @summary トリガー一覧取得API
         * @param {number} [currentPage] リードリストでの現在のページネーション
         * @param {number} [ownerId] 所有者ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTriggers(currentPage?: number, ownerId?: number, options?: any): AxiosPromise<GetTriggers200Response> {
            return localVarFp.getTriggers(currentPage, ownerId, options).then((request) => request(axios, basePath));
        },
        /**
         * トリガー作成API
         * @summary トリガー作成API
         * @param {PostTriggerRequest} [postTriggerRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTrigger(postTriggerRequest?: PostTriggerRequest, options?: any): AxiosPromise<CreateMfaSetting201Response> {
            return localVarFp.postTrigger(postTriggerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * トリガー更新API
         * @summary トリガー更新API
         * @param {number} triggerId トリガーID
         * @param {PostTriggerRequest} [postTriggerRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTrigger(triggerId: number, postTriggerRequest?: PostTriggerRequest, options?: any): AxiosPromise<void> {
            return localVarFp.putTrigger(triggerId, postTriggerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * トリガー実行設定API
         * @summary トリガー実行設定API
         * @param {number} triggerId トリガーID
         * @param {PutTriggerStatusRequest} [putTriggerStatusRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTriggerStatus(triggerId: number, putTriggerStatusRequest?: PutTriggerStatusRequest, options?: any): AxiosPromise<void> {
            return localVarFp.putTriggerStatus(triggerId, putTriggerStatusRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TriggerApi - interface
 * @export
 * @interface TriggerApi
 */
export interface TriggerApiInterface {
    /**
     * トリガー削除API
     * @summary トリガー削除API
     * @param {number} triggerId トリガーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TriggerApiInterface
     */
    deleteTrigger(triggerId: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * トリガー取得API
     * @summary トリガー取得API
     * @param {number} triggerId トリガーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TriggerApiInterface
     */
    getTrigger(triggerId: number, options?: AxiosRequestConfig): AxiosPromise<GetTrigger200Response>;

    /**
     * トリガー一覧取得API
     * @summary トリガー一覧取得API
     * @param {number} [currentPage] リードリストでの現在のページネーション
     * @param {number} [ownerId] 所有者ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TriggerApiInterface
     */
    getTriggers(currentPage?: number, ownerId?: number, options?: AxiosRequestConfig): AxiosPromise<GetTriggers200Response>;

    /**
     * トリガー作成API
     * @summary トリガー作成API
     * @param {PostTriggerRequest} [postTriggerRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TriggerApiInterface
     */
    postTrigger(postTriggerRequest?: PostTriggerRequest, options?: AxiosRequestConfig): AxiosPromise<CreateMfaSetting201Response>;

    /**
     * トリガー更新API
     * @summary トリガー更新API
     * @param {number} triggerId トリガーID
     * @param {PostTriggerRequest} [postTriggerRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TriggerApiInterface
     */
    putTrigger(triggerId: number, postTriggerRequest?: PostTriggerRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * トリガー実行設定API
     * @summary トリガー実行設定API
     * @param {number} triggerId トリガーID
     * @param {PutTriggerStatusRequest} [putTriggerStatusRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TriggerApiInterface
     */
    putTriggerStatus(triggerId: number, putTriggerStatusRequest?: PutTriggerStatusRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * Request parameters for deleteTrigger operation in TriggerApi.
 * @export
 * @interface TriggerApiDeleteTriggerRequest
 */
export interface TriggerApiDeleteTriggerRequest {
    /**
     * トリガーID
     * @type {number}
     * @memberof TriggerApiDeleteTrigger
     */
    readonly triggerId: number
}

/**
 * Request parameters for getTrigger operation in TriggerApi.
 * @export
 * @interface TriggerApiGetTriggerRequest
 */
export interface TriggerApiGetTriggerRequest {
    /**
     * トリガーID
     * @type {number}
     * @memberof TriggerApiGetTrigger
     */
    readonly triggerId: number
}

/**
 * Request parameters for getTriggers operation in TriggerApi.
 * @export
 * @interface TriggerApiGetTriggersRequest
 */
export interface TriggerApiGetTriggersRequest {
    /**
     * リードリストでの現在のページネーション
     * @type {number}
     * @memberof TriggerApiGetTriggers
     */
    readonly currentPage?: number

    /**
     * 所有者ID
     * @type {number}
     * @memberof TriggerApiGetTriggers
     */
    readonly ownerId?: number
}

/**
 * Request parameters for postTrigger operation in TriggerApi.
 * @export
 * @interface TriggerApiPostTriggerRequest
 */
export interface TriggerApiPostTriggerRequest {
    /**
     * 
     * @type {PostTriggerRequest}
     * @memberof TriggerApiPostTrigger
     */
    readonly postTriggerRequest?: PostTriggerRequest
}

/**
 * Request parameters for putTrigger operation in TriggerApi.
 * @export
 * @interface TriggerApiPutTriggerRequest
 */
export interface TriggerApiPutTriggerRequest {
    /**
     * トリガーID
     * @type {number}
     * @memberof TriggerApiPutTrigger
     */
    readonly triggerId: number

    /**
     * 
     * @type {PostTriggerRequest}
     * @memberof TriggerApiPutTrigger
     */
    readonly postTriggerRequest?: PostTriggerRequest
}

/**
 * Request parameters for putTriggerStatus operation in TriggerApi.
 * @export
 * @interface TriggerApiPutTriggerStatusRequest
 */
export interface TriggerApiPutTriggerStatusRequest {
    /**
     * トリガーID
     * @type {number}
     * @memberof TriggerApiPutTriggerStatus
     */
    readonly triggerId: number

    /**
     * 
     * @type {PutTriggerStatusRequest}
     * @memberof TriggerApiPutTriggerStatus
     */
    readonly putTriggerStatusRequest?: PutTriggerStatusRequest
}

/**
 * TriggerApi - object-oriented interface
 * @export
 * @class TriggerApi
 * @extends {BaseAPI}
 */
export class TriggerApi extends BaseAPI implements TriggerApiInterface {
    /**
     * トリガー削除API
     * @summary トリガー削除API
     * @param {TriggerApiDeleteTriggerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TriggerApi
     */
    public deleteTrigger(requestParameters: TriggerApiDeleteTriggerRequest, options?: AxiosRequestConfig) {
        return TriggerApiFp(this.configuration).deleteTrigger(requestParameters.triggerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * トリガー取得API
     * @summary トリガー取得API
     * @param {TriggerApiGetTriggerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TriggerApi
     */
    public getTrigger(requestParameters: TriggerApiGetTriggerRequest, options?: AxiosRequestConfig) {
        return TriggerApiFp(this.configuration).getTrigger(requestParameters.triggerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * トリガー一覧取得API
     * @summary トリガー一覧取得API
     * @param {TriggerApiGetTriggersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TriggerApi
     */
    public getTriggers(requestParameters: TriggerApiGetTriggersRequest = {}, options?: AxiosRequestConfig) {
        return TriggerApiFp(this.configuration).getTriggers(requestParameters.currentPage, requestParameters.ownerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * トリガー作成API
     * @summary トリガー作成API
     * @param {TriggerApiPostTriggerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TriggerApi
     */
    public postTrigger(requestParameters: TriggerApiPostTriggerRequest = {}, options?: AxiosRequestConfig) {
        return TriggerApiFp(this.configuration).postTrigger(requestParameters.postTriggerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * トリガー更新API
     * @summary トリガー更新API
     * @param {TriggerApiPutTriggerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TriggerApi
     */
    public putTrigger(requestParameters: TriggerApiPutTriggerRequest, options?: AxiosRequestConfig) {
        return TriggerApiFp(this.configuration).putTrigger(requestParameters.triggerId, requestParameters.postTriggerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * トリガー実行設定API
     * @summary トリガー実行設定API
     * @param {TriggerApiPutTriggerStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TriggerApi
     */
    public putTriggerStatus(requestParameters: TriggerApiPutTriggerStatusRequest, options?: AxiosRequestConfig) {
        return TriggerApiFp(this.configuration).putTriggerStatus(requestParameters.triggerId, requestParameters.putTriggerStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
