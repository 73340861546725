<template>
  <div class="lead-target-form">
    <BEditableLeadStageSelect
      :model-value="leadStage"
      :lead-stages="leadStages"
      :after-check="handleUpdate"
    />
  </div>
</template>

<script setup lang="ts">
import { inject } from 'vue';
import { useStore } from 'vuex';
import { LeadStage } from '@/api/openapi';
import { KEY_OF_WAIT } from '@/injection-keys';
import { key } from '@/store/index';

type TProps = {
  callTargetId: number;
  leadStage: LeadStage | null;
  leadStages: LeadStage[];
};

const props = defineProps<TProps>();
const emit = defineEmits(['change']);
const store = useStore(key);
const wait = inject(KEY_OF_WAIT);

const handleUpdate = async (newLeadStage: LeadStage | null) => {
  if (!newLeadStage) return;

  wait.start('postCallTargetLeadStageWait');
  await store.dispatch('user/postCallTargetLeadStageAction', {
    callTargetId: props.callTargetId,
    leadStage: newLeadStage,
  });
  wait.end('postCallTargetLeadStageWait');
  emit('change');

  wait.start('getLeadHistoriesWait');
  await store.dispatch('user/getLeadHistoriesAction', {
    callTargetId: props.callTargetId,
  });
  wait.end('getLeadHistoriesWait');
};
</script>

<style lang="scss" scoped>
  .lead-target-form {
    width: 100%;
  }
</style>
