import ApiBase from '@/api/base';

export type AvailableFeatures = {
  feature: {
    bulk_mail: boolean;
    direct_mail: boolean;
    intercom: boolean;
    salesforce_link: boolean;
    analytics: boolean;
    mail_template: boolean;
    sequence: boolean;
  };
  betaFeature: {
    bulk_mail: boolean;
    direct_mail: boolean;
    intercom: boolean;
    salesforce_link: boolean;
    analytics: boolean;
    mail_template: boolean;
    sequence: boolean;
  };
};

export default {
  getAvailableFeatures () {
    return ApiBase.get('/user/available_features');
  },
};

