<template>
  <BLayout
    class="instance-list-row"
    align-center
  >
    <div
      class="instance-list-item checkbox"
      @click.stop
    >
      <BCheckbox
        class="mt-0"
        :model-value="modelValue.checked"
        :value="modelValue.value"
        @change="handleCheckboxChange"
      />
    </div>
    <div
      v-for="column in columns"
      :key="column.key"
      class="instance-list-item"
      :style="{
        'width': column.width,
        'min-width': column.width,
        'max-width': column.width,
      }"
    >
      <span
        v-if="column.key == 'companyName'"
        class="truncate-text"
      >
        {{ callTarget.companyName }}
      </span>
      <span
        v-else-if="column.key == 'fullName'"
        class="truncate-text"
      >
        {{ altText(callTarget.fullName) }}
      </span>
      <span
        v-else-if="column.key == 'createdAt'"
      >
        {{ toYYYYMMDD(triggerTarget.createdAt) }}
      </span>
      <span
        v-else-if="column.key == 'event'"
      >
        {{ triggerTarget.event }}
      </span>
      <span
        v-else-if="column.key == 'statusChangedAt'"
      >
        {{ toYYYYMMDD(triggerTarget.statusChangedAt) }}
      </span>
      <div
        v-else-if="column.key == 'status'"
      >
        <TriggerTargetStatusPreview
          :value="(triggerTarget.status)"
          size="small"
          class="mr-50"
        />
      </div>
      <div
        v-else-if="column.key == 'owner'"
      >
        <BUserTooltip
          v-if="callTarget.user"
          :image-url="callTarget.user.avatarUrl"
          :name="callTarget.user.name"
          :user-id="callTarget.user.id"
          :size="40"
        />
        <span v-else>{{ altText('') }}</span>
      </div>
      <div
        v-else-if="column.key == 'approveUser'"
      >
        <BUserTooltip
          v-if="approveUser"
          :image-url="approveUser.avatarUrl"
          :name="approveUser.name"
          :user-id="approveUser.id"
          :size="40"
        />
        <span v-else>{{ altText('') }}</span>
      </div>
    </div>
    <div class="instance-list-item end-column" />
  </BLayout>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { TriggerTarget } from '@/api/openapi';
import TriggerTargetStatusPreview from '@/components/organisms/user/general/trigger/TriggerTargetStatusPreview.vue';
import { TCheck } from '@/composable/check-all';
import { toYYYYMMDD } from '@/services/date-format-service';
import { altText } from '@/utils/alt';
import { columns } from './instance-list-header';

type TProps = {
  modelValue: TCheck;
  triggerTarget: TriggerTarget;
};
type TEmit = {
  'update:modelValue': [check: TCheck];
};

const props = defineProps<TProps>();
const emit = defineEmits<TEmit>();

const handleCheckboxChange = () => emit('update:modelValue', props.modelValue);

const callTarget = computed(() => {
  return props.triggerTarget.callTarget || {
    companyName: null,
    fullName: null,
    leadStage: null,
    user: {
      id: null,
      avatarUrl: null,
      name: '',
    },
  };
});

const approveUser = computed(() => {
  return props.triggerTarget.approveUser || {
    id: null,
    avatarUrl: null,
    name: '',
  };
});
</script>

<style lang="scss" scoped>
.checkbox {
  @include m-fixed-width(40px);
  z-index: 0;
}

.instance-list-row {
  transition: $transition-base;
  cursor: pointer;
  @include m-fixed-height(60px);

  .instance-list-item {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 $basespace-200;
    border-bottom: 1px solid $bdcolor-light;

    span {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .end-column {
    width: 100%;
  }

  &:hover {
    background-color: $bgcolor-base;

    .instance-list-item {
      background-color: $bgcolor-base;
    }
  }
}
</style>
