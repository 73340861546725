/* tslint:disable */
/* eslint-disable */
/**
 * openapi
 * bales cloud
 *
 * The version of the OpenAPI document: 1.0
 * Contact: test@example.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { UserSenderEmailConfirmBody } from '../models';
// @ts-ignore
import { UserSenderEmailPrepareBody } from '../models';
/**
 * UserSenderEmailApi - axios parameter creator
 * @export
 */
export const UserSenderEmailApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 送信元メールアドレス確認API
         * @summary 送信元メールアドレス確認API
         * @param {UserSenderEmailConfirmBody} [userSenderEmailConfirmBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserSenderEmailConfirm: async (userSenderEmailConfirmBody?: UserSenderEmailConfirmBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/user_sender_emails/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userSenderEmailConfirmBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 送信元メールアドレス更新API
         * @summary 送信元メールアドレス更新API
         * @param {UserSenderEmailPrepareBody} [userSenderEmailPrepareBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserSenderEmailPrepare: async (userSenderEmailPrepareBody?: UserSenderEmailPrepareBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/user_sender_emails/prepare`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userSenderEmailPrepareBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserSenderEmailApi - functional programming interface
 * @export
 */
export const UserSenderEmailApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserSenderEmailApiAxiosParamCreator(configuration)
    return {
        /**
         * 送信元メールアドレス確認API
         * @summary 送信元メールアドレス確認API
         * @param {UserSenderEmailConfirmBody} [userSenderEmailConfirmBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUserSenderEmailConfirm(userSenderEmailConfirmBody?: UserSenderEmailConfirmBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUserSenderEmailConfirm(userSenderEmailConfirmBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 送信元メールアドレス更新API
         * @summary 送信元メールアドレス更新API
         * @param {UserSenderEmailPrepareBody} [userSenderEmailPrepareBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUserSenderEmailPrepare(userSenderEmailPrepareBody?: UserSenderEmailPrepareBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUserSenderEmailPrepare(userSenderEmailPrepareBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserSenderEmailApi - factory interface
 * @export
 */
export const UserSenderEmailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserSenderEmailApiFp(configuration)
    return {
        /**
         * 送信元メールアドレス確認API
         * @summary 送信元メールアドレス確認API
         * @param {UserSenderEmailConfirmBody} [userSenderEmailConfirmBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserSenderEmailConfirm(userSenderEmailConfirmBody?: UserSenderEmailConfirmBody, options?: any): AxiosPromise<void> {
            return localVarFp.postUserSenderEmailConfirm(userSenderEmailConfirmBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 送信元メールアドレス更新API
         * @summary 送信元メールアドレス更新API
         * @param {UserSenderEmailPrepareBody} [userSenderEmailPrepareBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserSenderEmailPrepare(userSenderEmailPrepareBody?: UserSenderEmailPrepareBody, options?: any): AxiosPromise<void> {
            return localVarFp.postUserSenderEmailPrepare(userSenderEmailPrepareBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserSenderEmailApi - interface
 * @export
 * @interface UserSenderEmailApi
 */
export interface UserSenderEmailApiInterface {
    /**
     * 送信元メールアドレス確認API
     * @summary 送信元メールアドレス確認API
     * @param {UserSenderEmailConfirmBody} [userSenderEmailConfirmBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSenderEmailApiInterface
     */
    postUserSenderEmailConfirm(userSenderEmailConfirmBody?: UserSenderEmailConfirmBody, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 送信元メールアドレス更新API
     * @summary 送信元メールアドレス更新API
     * @param {UserSenderEmailPrepareBody} [userSenderEmailPrepareBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSenderEmailApiInterface
     */
    postUserSenderEmailPrepare(userSenderEmailPrepareBody?: UserSenderEmailPrepareBody, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * Request parameters for postUserSenderEmailConfirm operation in UserSenderEmailApi.
 * @export
 * @interface UserSenderEmailApiPostUserSenderEmailConfirmRequest
 */
export interface UserSenderEmailApiPostUserSenderEmailConfirmRequest {
    /**
     * 
     * @type {UserSenderEmailConfirmBody}
     * @memberof UserSenderEmailApiPostUserSenderEmailConfirm
     */
    readonly userSenderEmailConfirmBody?: UserSenderEmailConfirmBody
}

/**
 * Request parameters for postUserSenderEmailPrepare operation in UserSenderEmailApi.
 * @export
 * @interface UserSenderEmailApiPostUserSenderEmailPrepareRequest
 */
export interface UserSenderEmailApiPostUserSenderEmailPrepareRequest {
    /**
     * 
     * @type {UserSenderEmailPrepareBody}
     * @memberof UserSenderEmailApiPostUserSenderEmailPrepare
     */
    readonly userSenderEmailPrepareBody?: UserSenderEmailPrepareBody
}

/**
 * UserSenderEmailApi - object-oriented interface
 * @export
 * @class UserSenderEmailApi
 * @extends {BaseAPI}
 */
export class UserSenderEmailApi extends BaseAPI implements UserSenderEmailApiInterface {
    /**
     * 送信元メールアドレス確認API
     * @summary 送信元メールアドレス確認API
     * @param {UserSenderEmailApiPostUserSenderEmailConfirmRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSenderEmailApi
     */
    public postUserSenderEmailConfirm(requestParameters: UserSenderEmailApiPostUserSenderEmailConfirmRequest = {}, options?: AxiosRequestConfig) {
        return UserSenderEmailApiFp(this.configuration).postUserSenderEmailConfirm(requestParameters.userSenderEmailConfirmBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 送信元メールアドレス更新API
     * @summary 送信元メールアドレス更新API
     * @param {UserSenderEmailApiPostUserSenderEmailPrepareRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSenderEmailApi
     */
    public postUserSenderEmailPrepare(requestParameters: UserSenderEmailApiPostUserSenderEmailPrepareRequest = {}, options?: AxiosRequestConfig) {
        return UserSenderEmailApiFp(this.configuration).postUserSenderEmailPrepare(requestParameters.userSenderEmailPrepareBody, options).then((request) => request(this.axios, this.basePath));
    }
}
