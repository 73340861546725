<template>
  <div class="b-date-picker">
    <ElDatePicker
      v-bind="attributes"
      v-model="internalValue"
    />
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: [Date, String],
      default: null,
    },
    readonly: Boolean,
    disabled: Boolean,
    size: {
      type: String,
      validator (val) {
        return ['small', 'default', 'large'].includes(val);
      },
      default: 'default',
    },
    editable: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      // year/month/date/dates/datetime/ week/datetimerange/daterange/ monthrange
      default: 'date',
    },
    format: {
      type: String,
      default: 'YYYY-MM-DD',
    },
  },
  emits: [
    'update:modelValue',
  ],
  computed: {
    internalValue: {
      get () {
        return this.modelValue;
      },
      set (newVal) {
        if (this.modelValue !== newVal) this.$emit('update:modelValue', newVal);
      },
    },
    attributes () {
      return {
        readonly: this.readonly,
        disabled: this.disabled,
        size: this.size,
        editable: this.editable,
        clearable: this.clearable,
        placeholder: this.placeholder,
        type: this.type,
        format: this.format,
        align: 'right',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
  .b-date-picker {
    width: 100%;

    :deep(.el-input__inner) {
      padding-right: $basespace-200;
    }
  }

  :deep(.el-date-editor) {
    --el-date-editor-width: 100%
  }

  :deep(.el-input__wrapper) {
    padding: 0 8px;
    .el-input__inner {
      padding: 0;
    }
    .clear-icon {
      margin: 0;
    }
  }
</style>
