<template>
  <div class="modal-content">
    <div class="sub-title">
      <p>{{ $t('duplicatedList.confirm.subTitle') }}</p>
    </div>
    <BModalBody :style="{ padding: 0 }">
      <div class="confirm-lead-items">
        <div class="tbody">
          <div
            v-for="{groupKey, key, label, displayValue} in confirmLeadItems"
            :key="`${groupKey}.${key}`"
            class="table-row tbody-row"
          >
            <div class="table-cell header-cell truncate-text">
              {{ label }}
            </div>
            <div class="table-cell truncate-text">
              {{ displayValue || '-' }}
            </div>
          </div>
        </div>
      </div>
    </BModalBody>
    <BModalFooter>
      <BLayout
        class="mt-200"
        justify-center
      >
        <BBtn
          type="primary"
          :loading="loading"
          @click="$emit('click-merge')"
        >
          <span>{{ $t('duplicatedList.confirm.doMerge') }}</span>
        </BBtn>
      </BLayout>
    </BModalFooter>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { TLeadItemSelectedValues, TSelectedLead, makeLeadItems } from './selectLeadItem';

export default defineComponent({
  props: {
    selectedLeads: {
      type: Array as PropType<TSelectedLead[]>,
    },
    leadItemSelectedValues: {
      type: Object as PropType<TLeadItemSelectedValues>,
    },
    loading: {
      type: Boolean,
    },
  },
  emits: [
    'click-merge',
  ],
  computed: {
    ...mapGetters('user', [
      'customFields',
    ]),
    confirmLeadItems() {
      return makeLeadItems(this.selectedLeads, this.customFields).map(({ groupKey, key, label, radioValues }) => {
        const callTargetId = this.leadItemSelectedValues[groupKey][key];
        const displayValue = radioValues.find(v => v.label === callTargetId)?.displayValue;
        return {
          groupKey,
          key,
          label,
          displayValue: groupKey === 'master' && key === 'id' ? `ID：${callTargetId}` : displayValue,
        };
      });
    },
  },
});
</script>

<style lang="scss" scoped>
  .sub-title {
    color: $basecolor-error;
  }
  .table-cell.header-cell {
    flex: 1;
  }
  .table-cell {
    flex: 3;
    padding: 4px 12px 4px 10px;
  }
  .table-row {
    padding: 12px 24px;
  }
</style>
