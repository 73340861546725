/* tslint:disable */
/* eslint-disable */
/**
 * openapi
 * bales cloud
 *
 * The version of the OpenAPI document: 1.0
 * Contact: test@example.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateMailAttachmentPresignedUrlRequest } from '../models';
// @ts-ignore
import { CreateOutgoingMailAttachmentAccessUrl200Response } from '../models';
// @ts-ignore
import { CreateOutgoingMailAttachmentAccessUrlRequest } from '../models';
// @ts-ignore
import { CreateZoomPhoneRecordingPresignedUrlRequest } from '../models';
// @ts-ignore
import { FileStrageAccess } from '../models';
/**
 * FileStrageAccessApi - axios parameter creator
 * @export
 */
export const FileStrageAccessApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * メール添付ファイル取得用のPresignedUrl作成API
         * @summary メール添付ファイル取得用のPresignedUrl作成API
         * @param {CreateMailAttachmentPresignedUrlRequest} [createMailAttachmentPresignedUrlRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMailAttachmentPresignedUrl: async (createMailAttachmentPresignedUrlRequest?: CreateMailAttachmentPresignedUrlRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/file_storage_access/create_mail_attachment_access_url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMailAttachmentPresignedUrlRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * メール添付ファイルアップロード用のPresignedUrl作成API
         * @summary メール添付ファイルアップロード用のPresignedUrl作成API
         * @param {CreateOutgoingMailAttachmentAccessUrlRequest} [createOutgoingMailAttachmentAccessUrlRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOutgoingMailAttachmentAccessUrl: async (createOutgoingMailAttachmentAccessUrlRequest?: CreateOutgoingMailAttachmentAccessUrlRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/file_storage_access/create_outgoing_mail_attachment_access_url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOutgoingMailAttachmentAccessUrlRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ZoomPhone音声ファイル取得用のPresignedUrl作成API
         * @summary ZoomPhone音声ファイル取得用のPresignedUrl作成API
         * @param {CreateZoomPhoneRecordingPresignedUrlRequest} [createZoomPhoneRecordingPresignedUrlRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createZoomPhoneRecordingPresignedUrl: async (createZoomPhoneRecordingPresignedUrlRequest?: CreateZoomPhoneRecordingPresignedUrlRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/file_storage_access/create_zoom_phone_recording_presigned_url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createZoomPhoneRecordingPresignedUrlRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileStrageAccessApi - functional programming interface
 * @export
 */
export const FileStrageAccessApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileStrageAccessApiAxiosParamCreator(configuration)
    return {
        /**
         * メール添付ファイル取得用のPresignedUrl作成API
         * @summary メール添付ファイル取得用のPresignedUrl作成API
         * @param {CreateMailAttachmentPresignedUrlRequest} [createMailAttachmentPresignedUrlRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMailAttachmentPresignedUrl(createMailAttachmentPresignedUrlRequest?: CreateMailAttachmentPresignedUrlRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileStrageAccess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMailAttachmentPresignedUrl(createMailAttachmentPresignedUrlRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * メール添付ファイルアップロード用のPresignedUrl作成API
         * @summary メール添付ファイルアップロード用のPresignedUrl作成API
         * @param {CreateOutgoingMailAttachmentAccessUrlRequest} [createOutgoingMailAttachmentAccessUrlRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOutgoingMailAttachmentAccessUrl(createOutgoingMailAttachmentAccessUrlRequest?: CreateOutgoingMailAttachmentAccessUrlRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateOutgoingMailAttachmentAccessUrl200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOutgoingMailAttachmentAccessUrl(createOutgoingMailAttachmentAccessUrlRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ZoomPhone音声ファイル取得用のPresignedUrl作成API
         * @summary ZoomPhone音声ファイル取得用のPresignedUrl作成API
         * @param {CreateZoomPhoneRecordingPresignedUrlRequest} [createZoomPhoneRecordingPresignedUrlRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createZoomPhoneRecordingPresignedUrl(createZoomPhoneRecordingPresignedUrlRequest?: CreateZoomPhoneRecordingPresignedUrlRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileStrageAccess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createZoomPhoneRecordingPresignedUrl(createZoomPhoneRecordingPresignedUrlRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FileStrageAccessApi - factory interface
 * @export
 */
export const FileStrageAccessApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileStrageAccessApiFp(configuration)
    return {
        /**
         * メール添付ファイル取得用のPresignedUrl作成API
         * @summary メール添付ファイル取得用のPresignedUrl作成API
         * @param {CreateMailAttachmentPresignedUrlRequest} [createMailAttachmentPresignedUrlRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMailAttachmentPresignedUrl(createMailAttachmentPresignedUrlRequest?: CreateMailAttachmentPresignedUrlRequest, options?: any): AxiosPromise<FileStrageAccess> {
            return localVarFp.createMailAttachmentPresignedUrl(createMailAttachmentPresignedUrlRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * メール添付ファイルアップロード用のPresignedUrl作成API
         * @summary メール添付ファイルアップロード用のPresignedUrl作成API
         * @param {CreateOutgoingMailAttachmentAccessUrlRequest} [createOutgoingMailAttachmentAccessUrlRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOutgoingMailAttachmentAccessUrl(createOutgoingMailAttachmentAccessUrlRequest?: CreateOutgoingMailAttachmentAccessUrlRequest, options?: any): AxiosPromise<CreateOutgoingMailAttachmentAccessUrl200Response> {
            return localVarFp.createOutgoingMailAttachmentAccessUrl(createOutgoingMailAttachmentAccessUrlRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * ZoomPhone音声ファイル取得用のPresignedUrl作成API
         * @summary ZoomPhone音声ファイル取得用のPresignedUrl作成API
         * @param {CreateZoomPhoneRecordingPresignedUrlRequest} [createZoomPhoneRecordingPresignedUrlRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createZoomPhoneRecordingPresignedUrl(createZoomPhoneRecordingPresignedUrlRequest?: CreateZoomPhoneRecordingPresignedUrlRequest, options?: any): AxiosPromise<FileStrageAccess> {
            return localVarFp.createZoomPhoneRecordingPresignedUrl(createZoomPhoneRecordingPresignedUrlRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileStrageAccessApi - interface
 * @export
 * @interface FileStrageAccessApi
 */
export interface FileStrageAccessApiInterface {
    /**
     * メール添付ファイル取得用のPresignedUrl作成API
     * @summary メール添付ファイル取得用のPresignedUrl作成API
     * @param {CreateMailAttachmentPresignedUrlRequest} [createMailAttachmentPresignedUrlRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileStrageAccessApiInterface
     */
    createMailAttachmentPresignedUrl(createMailAttachmentPresignedUrlRequest?: CreateMailAttachmentPresignedUrlRequest, options?: AxiosRequestConfig): AxiosPromise<FileStrageAccess>;

    /**
     * メール添付ファイルアップロード用のPresignedUrl作成API
     * @summary メール添付ファイルアップロード用のPresignedUrl作成API
     * @param {CreateOutgoingMailAttachmentAccessUrlRequest} [createOutgoingMailAttachmentAccessUrlRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileStrageAccessApiInterface
     */
    createOutgoingMailAttachmentAccessUrl(createOutgoingMailAttachmentAccessUrlRequest?: CreateOutgoingMailAttachmentAccessUrlRequest, options?: AxiosRequestConfig): AxiosPromise<CreateOutgoingMailAttachmentAccessUrl200Response>;

    /**
     * ZoomPhone音声ファイル取得用のPresignedUrl作成API
     * @summary ZoomPhone音声ファイル取得用のPresignedUrl作成API
     * @param {CreateZoomPhoneRecordingPresignedUrlRequest} [createZoomPhoneRecordingPresignedUrlRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileStrageAccessApiInterface
     */
    createZoomPhoneRecordingPresignedUrl(createZoomPhoneRecordingPresignedUrlRequest?: CreateZoomPhoneRecordingPresignedUrlRequest, options?: AxiosRequestConfig): AxiosPromise<FileStrageAccess>;

}

/**
 * Request parameters for createMailAttachmentPresignedUrl operation in FileStrageAccessApi.
 * @export
 * @interface FileStrageAccessApiCreateMailAttachmentPresignedUrlRequest
 */
export interface FileStrageAccessApiCreateMailAttachmentPresignedUrlRequest {
    /**
     * 
     * @type {CreateMailAttachmentPresignedUrlRequest}
     * @memberof FileStrageAccessApiCreateMailAttachmentPresignedUrl
     */
    readonly createMailAttachmentPresignedUrlRequest?: CreateMailAttachmentPresignedUrlRequest
}

/**
 * Request parameters for createOutgoingMailAttachmentAccessUrl operation in FileStrageAccessApi.
 * @export
 * @interface FileStrageAccessApiCreateOutgoingMailAttachmentAccessUrlRequest
 */
export interface FileStrageAccessApiCreateOutgoingMailAttachmentAccessUrlRequest {
    /**
     * 
     * @type {CreateOutgoingMailAttachmentAccessUrlRequest}
     * @memberof FileStrageAccessApiCreateOutgoingMailAttachmentAccessUrl
     */
    readonly createOutgoingMailAttachmentAccessUrlRequest?: CreateOutgoingMailAttachmentAccessUrlRequest
}

/**
 * Request parameters for createZoomPhoneRecordingPresignedUrl operation in FileStrageAccessApi.
 * @export
 * @interface FileStrageAccessApiCreateZoomPhoneRecordingPresignedUrlRequest
 */
export interface FileStrageAccessApiCreateZoomPhoneRecordingPresignedUrlRequest {
    /**
     * 
     * @type {CreateZoomPhoneRecordingPresignedUrlRequest}
     * @memberof FileStrageAccessApiCreateZoomPhoneRecordingPresignedUrl
     */
    readonly createZoomPhoneRecordingPresignedUrlRequest?: CreateZoomPhoneRecordingPresignedUrlRequest
}

/**
 * FileStrageAccessApi - object-oriented interface
 * @export
 * @class FileStrageAccessApi
 * @extends {BaseAPI}
 */
export class FileStrageAccessApi extends BaseAPI implements FileStrageAccessApiInterface {
    /**
     * メール添付ファイル取得用のPresignedUrl作成API
     * @summary メール添付ファイル取得用のPresignedUrl作成API
     * @param {FileStrageAccessApiCreateMailAttachmentPresignedUrlRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileStrageAccessApi
     */
    public createMailAttachmentPresignedUrl(requestParameters: FileStrageAccessApiCreateMailAttachmentPresignedUrlRequest = {}, options?: AxiosRequestConfig) {
        return FileStrageAccessApiFp(this.configuration).createMailAttachmentPresignedUrl(requestParameters.createMailAttachmentPresignedUrlRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * メール添付ファイルアップロード用のPresignedUrl作成API
     * @summary メール添付ファイルアップロード用のPresignedUrl作成API
     * @param {FileStrageAccessApiCreateOutgoingMailAttachmentAccessUrlRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileStrageAccessApi
     */
    public createOutgoingMailAttachmentAccessUrl(requestParameters: FileStrageAccessApiCreateOutgoingMailAttachmentAccessUrlRequest = {}, options?: AxiosRequestConfig) {
        return FileStrageAccessApiFp(this.configuration).createOutgoingMailAttachmentAccessUrl(requestParameters.createOutgoingMailAttachmentAccessUrlRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ZoomPhone音声ファイル取得用のPresignedUrl作成API
     * @summary ZoomPhone音声ファイル取得用のPresignedUrl作成API
     * @param {FileStrageAccessApiCreateZoomPhoneRecordingPresignedUrlRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileStrageAccessApi
     */
    public createZoomPhoneRecordingPresignedUrl(requestParameters: FileStrageAccessApiCreateZoomPhoneRecordingPresignedUrlRequest = {}, options?: AxiosRequestConfig) {
        return FileStrageAccessApiFp(this.configuration).createZoomPhoneRecordingPresignedUrl(requestParameters.createZoomPhoneRecordingPresignedUrlRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
