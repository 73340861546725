<template>
  <div
    v-if="visible"
    class="items"
  >
    <div
      v-for="(item, index) in items"
      :key="`${index}`"
    >
      <div class="removable-item">
        <span>{{ uniqueKey ? item[uniqueKey] : item }}</span>
        <BIcon
          size="small"
          class="close-icon"
          @click="() => removeItem(index)"
        >
          close
        </BIcon>
      </div>
    </div>
    <div
      v-if="!isMax"
      class="add-item-container"
    >
      <div class="item-input-container">
        <input
          v-model="editingItem"
          class="item-input"
          :type="inputType"
          :placeholder="placeholder"
          @keydown.enter="addItem"
          @keydown.space="addItem"
          @focus="isEditing = true"
          @blur="isEditing = false"
        >
        <BIcon
          v-if="!noNeedDisplayValidationIcon"
          size="small"
          :type="validationStatus.type"
          class="item-validate-icon"
        >
          {{ validationStatus.icon }}
        </BIcon>
      </div>
      <div class="add-item-button-container">
        <BBtn
          type="primary"
          size="small"
          :disabled="!itemsValid || !editingItemValid"
          @click="addItem"
        >
          {{ $t(`general.append.text`) }}
        </BBtn>
      </div>
    </div>
  </div>
</template>

<script>
import inputValidation from '@/mixins/input_validation';

export default {
  mixins: [inputValidation],
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
    uniqueKey: {
      type: String,
      required: true,
    },
    maxItemCount: {
      type: Number,
      default: 0,
    },
    needUniqueness: {
      type: Boolean,
      default: true,
    },
    // itemのバリデーションルールに関するString[]
    itemValidateRules: {
      type: Array,
      default: () => [],
    },
    inputType: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  emits: [
    'add-item',
    'remove-item',
  ],
  data () {
    return {
      editingItem: '',
      isEditing: false,
    };
  },
  computed: {
    isMax () {
      return this.maxItemCount !== 0 && this.maxItemCount < this.items.length;
    },
    isNotUniqueness () {
      const alreadyExist = this.items.some((cc) => {
        const compared = this.uniqueKey ? cc[this.uniqueKey] : cc;
        return compared === this.editingItem;
      });
      return this.needUniqueness === true && alreadyExist;
    },
    itemsValid () {
      let result = true;

      // 重複なしバリデーション
      if (this.isNotUniqueness) result = false;

      // 個数バリデーション
      if (this.isMax) result = false;

      return result;
    },
    editingItemValid () {
      let result = true;

      // 存在バリデーション
      if (this.editingItem.length === 0) result = false;

      // propsで指定されたルール群をバリデーション
      this.itemValidateRules.forEach((rule) => {
        switch (rule) {
          case 'email':
            if (!this.emailTest(this.editingItem)) result = false;
            break;
          default:
            break;
        }
      });

      return result;
    },
    validationStatus () {
      if (this.editingItem.length === 0) {
        return { type: 'error', icon: 'check_circle_outline' };
      }

      if (this.itemsValid && this.editingItemValid) {
        return { type: 'success', icon: 'check_circle' };
      } else {
        return { type: 'error', icon: 'check_circle' };
      }
    },
    noNeedDisplayValidationIcon () {
      return this.editingItem.length === 0 && !this.isEditing;
    },
  },
  methods: {
    addItem () {
      if (!this.editingItemValid || !this.itemsValid) return false;

      this.$emit('add-item', this.editingItem);
      this.editingItem = '';
    },
    removeItem (index) {
      this.$emit('remove-item', index);
    },
  },
};
</script>

<style lang="scss" scoped>
  .items {
    .removable-item {
      display: inline-block;
      background-color: $concrete-light;
      padding: 4px 8px;
      margin-top: 4px;
      margin-right: 8px;
      margin-bottom: 4px;
      border-radius: 16px;
      border: 1px solid $concrete;

      .close-icon {
        margin-left: 6px;
        cursor: pointer;
        &:hover {
          color: $concrete-dark;
        }
      }
    }

    .item-input {
      width: 100%;
      padding-top: 16px;
      padding-bottom: 4px;
      height: 32px;
      border: 0;
      &:focus {
        outline: none;
      }
      &::placeholder {
        color: var(--el-text-color-placeholder);
      }
    }

    .item-validate-icon {
      margin-top: 6px;
      margin-right: 8px;
    }
  }

  .add-item-container {
    display: flex;
    justify-content: space-between;
    .item-input-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      transition: all 0.3s ease;
      border-bottom: 1px solid $bdcolor-base;
      &:focus-within {
        border-bottom: 1px solid $bdcolor-active;
      }
      & {
        margin-right: 16px;
      }
    }

    .add-item-button-container {
      margin-top: 8px;
    }
  }
</style>
