<template>
  <div
    v-if="isDisplayOnly"
  >
    {{ sequenceStepMasterName(model) }}
  </div>
  <BSelect
    v-else
    v-model="model"
    v-model:valid="isValid"
    :items="options"
    :required="required"
    item-text="name"
    item-value="id"
    fit
    filterable
  />
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { PartialSequenceStepMaster } from '@/api/openapi';
import { SequenceStepMasterApiService } from '@/api/user/resources/sequence_step_master';
import { TWithDaysPartialSequenceStepMaster, TStepType } from '@/components/organisms/user/general/sequence/types';
import { useMakeSequenceStepTitle, getStepType } from '@/composable/user/sequence/step-computed-property';
import { useWithDaysSequenceSteps } from '@/composable/user/sequence/steps-computed-property';
import { useWait } from '@/composable/vue-wait';

type Condition = {
  stepType: TStepType;
};

type TItem = TWithDaysPartialSequenceStepMaster & {
  name: string;
};

type TProps = {
  sequenceMasterId: number | null;
  condition: Condition;
  required: boolean;
  isDisplayOnly: boolean;
};
const props = defineProps<TProps>();

const model = defineModel<number | null>();
const isValid = defineModel<boolean>('isValid', { default: false });

const { doActionWithWait } = useWait();

const { makeSequenceStepTitle } = useMakeSequenceStepTitle();
const { withDaysSequenceSteps } = useWithDaysSequenceSteps<TItem, TWithDaysPartialSequenceStepMaster>(
  () => sequenceStepMasters.value,
  (withDaysStep) => {
    return {
      ...withDaysStep,
      name: makeSequenceStepTitle(withDaysStep),
    };
  },
);
// NOTE: バックエンドでフィルタリングすると日数が変わるため、フロントエンドでフィルタリングする
const options = computed(() => {
  return withDaysSequenceSteps.value.filter((step) => getStepType(step) === props.condition.stepType);
});

const sequenceStepMasters = ref<PartialSequenceStepMaster[]>([]);
const loadSequenceStepMasters = async () => {
  const api = new SequenceStepMasterApiService();
  await doActionWithWait('fetchSequenceMasters', async () => {
    const { data } = await api.getSequenceStepMastersBySpecifyingFields({
      request: {
        sequenceMasterId: props.sequenceMasterId,
        fields: ['sort_order', 'action_type', 'date_interval'],
      },
    });
    sequenceStepMasters.value = data;
  });
};

watch(() => props.sequenceMasterId, async (newValue, oldValue) => {
  if (oldValue != null) model.value = null; // シーケンスマスターが変更されたら、シーケンスステップをリセットする必要がある。（未選択の場合は何もしなくていい）
  if (newValue) {
    await loadSequenceStepMasters();
  }
}, { immediate: true });

const sequenceStepMasterName = (sequenceStepMasterId: number) => {
  return options.value.find((option) => option.id === sequenceStepMasterId)?.name;
};
</script>
