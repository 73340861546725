<template>
  <div class="signin-container">
    <div
      v-loading="$wait.is('USER_SIGNIN_WAIT')"
      class="signin-card"
    >
      <div class="signin-card-inner">
        <div class="signin-message">
          {{ $t('auth.adminPageMessage') }}
        </div>
        <div class="signin-form">
          <BBtn
            class="signin-btn"
            type="primary"
            :loading="loading"
            @click.prevent="signin"
          >
            <span>{{ $t('auth.signin') }}</span>
            <template #loader>
              <span class="custom-loader">
                <BIcon type="white">cached</BIcon>
              </span>
            </template>
          </BBtn>
        </div>
        <div
          v-if="!isProduction"
          v-show="false"
          class="signin-form"
          data-test="dummy-form"
        >
          <div class="mb-200">
            <BInput
              v-model="email"
              :rules="[requiredRule, emailRule]"
              :placeholder="$t('auth.email')"
              validate-on-blur
              color="focus"
              data-test="email"
            />
          </div>
          <div class="mb-200">
            <BInput
              v-model="password"
              :rules="[requiredRule]"
              type="password"
              name="password"
              :placeholder="$t('auth.password')"
              validate-on-blur
              color="focus"
              data-test="password"
              @click:append="passwordShow = !passwordShow"
              @keypress-enter="dummySignin"
            />
          </div>
          <div class="pb-200">
            <BBtn
              type="primary"
              data-test="signin-btn"
              :loading="dummyLoading"
              @click.prevent="dummySignin"
            >
              <span>{{ $t('auth.signin') }}</span>
            </BBtn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import inputValidation from '@/mixins/input_validation';
import AdminService from '@/services/admin_service';

export default defineComponent({
  mixins: [inputValidation],
  data() {
    return {
      title: 'Welcome, Admin',
      message: 'Signin with Google',
      loading: false,
      dummyLoading: false,
      email: '',
      password: '',
    };
  },
  computed: {
    isProduction() {
      return process.env.NODE_ENV === 'production';
    },
  },
  created() {
    this.adminService = new AdminService();
  },
  methods: {
    async signin() {
      // TODO: 例外処理
      this.loading = true;

      try {
        await this.adminService.signinAdmin();
        const destination = this.$route.query.dest_location ? this.$route.query.dest_location : { name: 'AdminClients' };
        this.$router.push(destination);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async dummySignin() {
      this.dummyLoading = true;
      try {
        await this.adminService.dummySigninAdmin(this.email, this.password);
        const destination = this.$route.query.dest_location ? this.$route.query.dest_location : { name: 'AdminClients' };
        this.$router.push(destination);
      } catch (error) {
        console.log(error);
      } finally {
        this.dummyLoading = false;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.signin-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $bgcolor-base;
  min-height: 100vh;
}

.signin-card {
  background-color: rgba($paper, 0.9);
  width: 420px;
  height: 320px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: $boxshadow-base;
}

.signin-card-inner {
  width: 240px;
  margin: 60px auto;
}

.signin-message {
  text-align: center;
  font-weight: bold;
  font-size: $fontsize-700;
  margin-bottom: $basespace-600;
}

.signin-btn {
  width: 100%;
  margin-top: $basespace-600;
  margin-bottom: $basespace-200;
}
</style>
