<template>
  <div class="content-box">
    <div class="content">
      <slot name="content" />
    </div>
    <div
      v-if="isFooter"
      class="footer"
    >
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BContentBox',
  props: {
    title: String,
    isFooter: Boolean,
  },
};
</script>

<style lang="scss" scoped>
  .content-box {
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;

    .content {
      padding: 22px 22px $basespace-600 $basespace-600;
      overflow-y: auto;
      flex-grow: 1;
    }

    .footer {
      padding: 0 $basespace-600 $basespace-600;
      height: 109px;
    }
  }
</style>
