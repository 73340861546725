<template>
  <div
    ref="bIconWithActionMenuContainer"
    class="b-icon-with-action-menu-container"
  >
    <BBadge :value="badgeCount">
      <BBtn
        fab
        flat
        size="small"
        @click="handleIconClick"
      >
        <slot name="icon" />
      </BBtn>
    </BBadge>
    <Transition name="fade-in-linear">
      <div
        v-if="isOpen"
        class="content"
        :style="{ width: `${width}px` }"
      >
        <BCard
          full
        >
          <slot />
        </BCard>
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { onBeforeMount, onBeforeUnmount, getCurrentInstance, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { key } from '@/store/index';

type TProps = {
  badgeCount?: number;
  width?: number;
  autoFetchInterval?: number;
};

type TEmit = {
  (e: 'autoFetchIntervalPassed'): void;
  (e: 'afterOpen'): void;
  (e: 'afterClose'): void;
};

const props = withDefaults(defineProps<TProps>(), {
  width: 580,
});
const emit = defineEmits<TEmit>();

const store = useStore(key);

const bIconWithActionMenuContainer = ref<HTMLElement | null>(null);
const isOpen = ref(false);

watch(() => isOpen.value, (newVal) => {
  if (newVal) {
    emit('afterOpen');
    document.removeEventListener('click', handleDocumentClick, true);
    document.addEventListener('click', handleDocumentClick, true);
  } else {
    emit('afterClose');
    document.removeEventListener('click', handleDocumentClick, true);
  }
});

const app = getCurrentInstance();
let timerObj: number;

onBeforeMount(() => {
  if (!props.autoFetchInterval) return;
  timerObj = app.appContext.config.globalProperties.$setInterval(() => {
    emit('autoFetchIntervalPassed');
  }, props.autoFetchInterval);
});

onBeforeUnmount(() => {
  app.appContext.config.globalProperties.$clearInterval(timerObj);
});

const handleIconClick = () => {
  isOpen.value = !isOpen.value;
};

const handleDocumentClick = (e: any) => {
  // MEMO: 個別に管理しないと、同時に開いていた場合やタイミングによって閉じたいものが閉じないことがあるかもしれないが、現状はこのケースになることはなさそうなので、個別管理はしないようにした。
  if (store.getters['userUi/isDisabledBIconWithActionMenuClose']) return;
  if (bIconWithActionMenuContainer.value?.contains(e.target)
    || !bIconWithActionMenuContainer.value
  ) return;
  close();
};

const close = () => {
  isOpen.value = false;
};

defineExpose({
  close,
});
</script>

<style lang="scss" scoped>
.b-icon-with-action-menu-container {
  position: relative;

  .content {
    position: fixed;
    top: #{$header-height + 4px};
    background: $paper;
    border: 1px solid $bdcolor-base;
    box-shadow: $boxshadow-base;
    text-align: justify;
    line-height: 1.4;
  }
}
</style>
