<template>
  <div class="action-end-container">
    <div class="icon">
      <BBtn
        fab
        type="success"
        class="action-end-button"
      >
        <BCustomIcon
          icon-class="b-fire"
          size="medium"
          type="success"
          class="action-end-icon increment-priority"
        />
      </BBtn>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.action-end-container {
  .action-end-button {
    .action-end-icon.increment-priority {
      color: $basecolor-success;
    }
    .icon {
      margin-bottom: 4px;
      pointer-events: none;
    }
    .text {
      font-size: $fontsize-100;
    }
  }
}
</style>
