<template>
  <BHeightAdjuster>
    <BModalHeader
      :title="userName"
      @modal-close="closeModal"
    />
    <BModalBody class="analytics-graph">
      <template v-if="labels.length">
        <div class="graph-row">
          <div class="graph-label">
            {{ $t('callAnalytics.graph.chartDataTime') }}
          </div>
          <BVerticalBarChart
            class="graph"
            :chart-data="chartDataTime"
            :options="options()"
          />
        </div>
        <div class="graph-row">
          <div class="graph-label">
            {{ $t('callAnalytics.graph.chartDataResultCount') }}
          </div>
          <BVerticalBarChart
            class="graph"
            :chart-data="chartDataResultCount"
            :options="options(1)"
          />
        </div>
        <div class="graph-row">
          <div class="graph-label">
            {{ $t('callAnalytics.graph.chartDataResultAmount') }}
          </div>
          <BVerticalBarChart
            class="graph"
            :chart-data="chartDataResultAmount"
            :options="options()"
          />
        </div>
        <div class="graph-row">
          <div class="graph-label">
            {{ $t('callAnalytics.graph.chartDataResultRate') }}
          </div>
          <BVerticalBarChart
            class="graph"
            :chart-data="chartDataResultRate"
            :options="options()"
          />
        </div>
        <div class="graph-row">
          <div class="graph-label">
            {{ $t('callAnalytics.graph.chartDataParCall') }}
          </div>
          <BVerticalBarChart
            class="graph"
            :chart-data="chartDataParCall"
            :options="options()"
          />
        </div>
        <div class="graph-row">
          <div class="graph-label">
            {{ $t('callAnalytics.graph.chartDataParHour') }}
          </div>
          <BVerticalBarChart
            class="graph"
            :chart-data="chartDataParHour"
            :options="options()"
          />
        </div>
        <div class="graph-row">
          <div class="graph-label">
            {{ $t('callAnalytics.graph.chartDataParReach') }}
          </div>
          <BVerticalBarChart
            class="graph"
            :chart-data="chartDataParReach"
            :options="options(1)"
          />
        </div>
      </template>
      <template v-else>
        {{ $t('callAnalytics.graph.unable') }}
      </template>
    </BModalBody>
  </BHeightAdjuster>
</template>

<script>
const BACKGROUND_COLORS = ['rgba(54, 162, 235, 0.5)', 'rgba(153, 102, 255, 0.5)', 'rgba(75, 192, 192, 0.5)'];
const BORDER_COLORS = ['rgba(54, 162, 235)', 'rgba(153, 102, 255)', 'rgba(75, 192, 192)'];
const BORDER_WIDTH = 1;

export default {
  components: {},
  props: {
    userName: {
      type: String,
      default: '',
    },
    labels: {
      type: Array,
      default: () => [],
    },
    datam: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    tableData () {
      return {
        operatingTime: {
          name: '稼働時間',
          data: this.datam.map(x => 
            x.operatingTime,
          ),
        },
        callTime: {
          name: '通話時間',
          data: this.datam.map(x => 
            x.callTime,
          ),
        },
        reachCallTime: {
          name: 'リーチ率',
          data: this.datam.map(x => 
            x.reachCallTime,
          ),
        },
        callCount: {
          name: 'コール数',
          data: this.datam.map(x => 
            x.callCount,
          ),
        },
        totalOpportunityAmout: {
          name: '合計商談化金額',
          data: this.datam.map(x => 
            x.totalOpportunityAmout,
          ),
        },
        reachCount: {
          name: 'リーチ数',
          data: this.datam.map(x => 
            x.reachCount,
          ),
        },
        opportunityCount: {
          name: '商談化件数',
          data: this.datam.map(x => 
            x.opportunityCount,
          ),
        },
        reachCountParCallCount: {
          name: 'リーチ率(/コール数)',
          data: this.datam.map(x => 
            x.reachCount * 100 / x.callCount || 0,
          ),
        },
        opportunityCountParReachCount: {
          name: '商談化数(/リーチ数)',
          data: this.datam.map(x => 
            x.opportunityCount * 100 / x.reachCount || 0,
          ),
        },
        opportunityCountParCallCount: {
          name: '商談化数(/コール数)',
          data: this.datam.map(x => 
            x.opportunityCount * 100 / x.callCount || 0,
          ),
        },
        operatingTimeParCall: {
          name: '稼働時間(/コール)',
          data: this.datam.map(x => 
            x.operatingTime / x.callCount || 0,
          ),
        },
        callTimeParCall: {
          name: '通話時間(/コール)',
          data: this.datam.map(x => 
            x.callTime / x.callCount || 0,
          ),
        },
        callCountParHour: {
          name: 'コール数(/1時間)',
          data: this.datam.map(x => 
            x.callCount / (x.operatingTime / 3600) || 0,
          ),
        },
        callTimeParReach: {
          name: '通話時間(/リーチ)',
          data: this.datam.map(x => 
            x.reachCallTime / x.reachCount || 0,
          ),
        },
      };
    },
    chartDataTime () {
      return {
        labels: this.labels,
        datasets: [{
          label: this.tableData.operatingTime.name,
          data: this.tableData.operatingTime.data.map(seconds => Math.ceil(seconds / 60 * 10) / 10),
          backgroundColor: BACKGROUND_COLORS[0],
          borderColor: BORDER_COLORS[0],
          borderWidth: BORDER_WIDTH,
        },
        {
          label: this.tableData.callTime.name,
          data: this.tableData.callTime.data.map(seconds => Math.ceil(seconds / 60 * 10) / 10),
          backgroundColor: BACKGROUND_COLORS[1],
          borderColor: BORDER_COLORS[1],
          borderWidth: BORDER_WIDTH,
        }],
      };
    },
    chartDataResultCount () {
      return {
        labels: this.labels,
        datasets: [{
          label: this.tableData.callCount.name,
          data: this.tableData.callCount.data,
          backgroundColor: BACKGROUND_COLORS[0],
          borderColor: BORDER_COLORS[0],
          borderWidth: BORDER_WIDTH,
        },
        {
          label: this.tableData.reachCount.name,
          data: this.tableData.reachCount.data,
          backgroundColor: BACKGROUND_COLORS[1],
          borderColor: BORDER_COLORS[1],
          borderWidth: BORDER_WIDTH,
        },
        {
          label: this.tableData.opportunityCount.name,
          data: this.tableData.opportunityCount.data,
          backgroundColor: BACKGROUND_COLORS[2],
          borderColor: BORDER_COLORS[2],
          borderWidth: BORDER_WIDTH,
        }],
      };
    },
    chartDataResultAmount () {
      return {
        labels: this.labels,
        datasets: [{
          label: this.tableData.totalOpportunityAmout.name,
          data: this.tableData.totalOpportunityAmout.data,
          backgroundColor: BACKGROUND_COLORS[0],
          borderColor: BORDER_COLORS[0],
          borderWidth: BORDER_WIDTH,
        }],
      };
    },
    chartDataResultRate () {
      return {
        labels: this.labels,
        datasets: [{
          label: this.tableData.reachCountParCallCount.name,
          data: this.tableData.reachCountParCallCount.data,
          backgroundColor: BACKGROUND_COLORS[0],
          borderColor: BORDER_COLORS[0],
          borderWidth: BORDER_WIDTH,
        },
        {
          label: this.tableData.opportunityCountParReachCount.name,
          data: this.tableData.opportunityCountParReachCount.data,
          backgroundColor: BACKGROUND_COLORS[1],
          borderColor: BORDER_COLORS[1],
          borderWidth: BORDER_WIDTH,
        },
        {
          label: this.tableData.opportunityCountParCallCount.name,
          data: this.tableData.opportunityCountParCallCount.data,
          backgroundColor: BACKGROUND_COLORS[2],
          borderColor: BORDER_COLORS[2],
          borderWidth: BORDER_WIDTH,
        }],
      };
    },
    chartDataParCall () {
      return {
        labels: this.labels,
        datasets: [{
          label: this.tableData.operatingTimeParCall.name,
          data: this.tableData.operatingTimeParCall.data.map(seconds => Math.ceil(seconds / 60 * 10) / 10),
          backgroundColor: BACKGROUND_COLORS[0],
          borderColor: BORDER_COLORS[0],
          borderWidth: BORDER_WIDTH,
        },
        {
          label: this.tableData.callTimeParCall.name,
          data: this.tableData.callTimeParCall.data.map(seconds => Math.ceil(seconds / 60 * 10) / 10),
          backgroundColor: BACKGROUND_COLORS[1],
          borderColor: BORDER_COLORS[1],
          borderWidth: BORDER_WIDTH,
        }],
      };
    },
    chartDataParHour () {
      return {
        labels: this.labels,
        datasets: [{
          label: this.tableData.callCountParHour.name,
          data: this.tableData.callCountParHour.data.map(hours => Math.ceil(hours * 10) / 10),
          backgroundColor: BACKGROUND_COLORS[0],
          borderColor: BORDER_COLORS[0],
          borderWidth: BORDER_WIDTH,
        }],
      };
    },
    chartDataParReach () {
      return {
        labels: this.labels,
        datasets: [{
          label: this.tableData.callTimeParReach.name,
          data: this.tableData.callTimeParReach.data.map(seconds => Math.ceil(seconds / 60 * 10) / 10),
          backgroundColor: BACKGROUND_COLORS[0],
          borderColor: BORDER_COLORS[0],
          borderWidth: BORDER_WIDTH,
        }],
      };
    },
  },
  methods: {
    options (stepSize = null) {
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          labels: {
            boxWidth: 12,
            fontSize: 10,
          },
        },
        tooltips: {
          mode: 'nearest',
          position: 'nearest',
          callbacks: {
            label (tooltipItem, data) {
              return data.datasets[tooltipItem.datasetIndex].label + tooltipItem.value;
            },
          },
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              stepSize,
            },
          }],
          xAxes: [{
            maxBarThickness: 100,
          }],
        },
      };
    },
    closeModal () {
      this.$modal.hide('analytics-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
.analytics-graph {
  padding: 20px;
}

.graph-row {
  display: flex;
  align-items: center;
  padding: 40px 0;
}

.graph-label {
  flex-basis: 80px;
  flex-shrink: 0;
  text-align: center;
}

.graph {
  flex-basis: 400px;
  flex-shrink: 1;
  flex-grow: 1;
  position: relative;
  height: 300px;
}
</style>
