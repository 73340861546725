import ApiService, { ApiArguments } from '@/api/api_service';
import {
  SfReportImportSettingsApi,
  SfReportImportSettingsApiSfReportImportSettingsDestoryRequest,
  SfReportImportSettingsApiSfReportImportSettingsDisableRequest,
  SfReportImportSettingsApiSfReportImportSettingsEnableRequest,
} from '@/api/openapi';

export class SfReportImportSettingsService extends ApiService {
  api = new SfReportImportSettingsApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    // openapiで作ったやつはtrue
    super(true);
  }

  async sfReportImportSettingsDestory({
    request,
    options,
    errorHandlers,
  }: ApiArguments<SfReportImportSettingsApiSfReportImportSettingsDestoryRequest>) {
    return this.checkResponse<void>(
      this.api.sfReportImportSettingsDestory(request, options),
      errorHandlers,
    );
  }

  async sfReportImportSettingsDisable({
    request,
    options,
    errorHandlers,
  }: ApiArguments<SfReportImportSettingsApiSfReportImportSettingsDisableRequest>) {
    return this.checkResponse<void>(
      this.api.sfReportImportSettingsDisable(request, options),
      errorHandlers,
    );
  }

  async sfReportImportSettingsEnable({
    request,
    options,
    errorHandlers,
  }: ApiArguments<SfReportImportSettingsApiSfReportImportSettingsEnableRequest>) {
    return this.checkResponse<void>(
      this.api.sfReportImportSettingsEnable(request, options),
      errorHandlers,
    );
  }
}
