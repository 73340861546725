<template>
  <div class="lead-source-form">
    <BSelectMenu
      v-bind="attributes"
      data-test="leadSourceFormMenu"
      @click-append="handleClickAppend"
      @click-delete="handleClickDelete"
    />
  </div>
</template>

<script>
import { mapWaitingActions } from 'vue-wait';

export default {
  props: {
    selectedIds: {
      type: Array,
      default () {
        return [];
      },
    },
    candidates: Array,
    callTargetId: Number,
    loading: Boolean,
  },
  emits: [
    'change',
  ],
  computed: {
    attributes () {
      return {
        candidates: this.candidates,
        selectedIds: this.selectedIds,
        loading: this.loading,
      };
    },
  },
  methods: {
    ...mapWaitingActions('user', {
      appendCallTargetLeadSourceAction: 'appendCallTargetLeadSourceWait',
      deleteCallTargetLeadSourceAction: 'deleteCallTargetLeadSourceWait',
    }),
    async handleClickAppend (leadSourceId) {
      await this.appendCallTargetLeadSourceAction({
        id: this.callTargetId,
        body: { leadSourceId },
      });
      this.$emit('change');
    },
    async handleClickDelete (leadSourceId) {
      await this.deleteCallTargetLeadSourceAction({
        id: this.callTargetId,
        body: { leadSourceId },
      });
      this.$emit('change');
    },
  },
};
</script>
