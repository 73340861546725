<template>
  <BHeightAdjuster>
    <BModalBody>
      <div class="setting-message">
        <BLayout wrap>
          <span>{{ $t('freeTag.settingMessage') }}</span>
        </BLayout>
      </div>
      <div class="list-content">
        <div v-loading="$wait.is('deleteFreeTagWait') || $wait.is('getFreeTagsWait')">
          <div class="item-content">
            <BLayout
              v-for="freeTag in freeTags"
              :key="freeTag.id"
              class="item-row py-100"
              align-center
              justify-space-between
            >
              <BEditableContent
                class="mr-100"
                :value="freeTag.text"
                :placeholder="$t('freeTag.text')"
                required
                :loading="$wait.is('updateFreeTagWait')"
                @check="handleUpdate($event, freeTag.id)"
              />
              <BMoreMenu>
                <BBtn
                  flat
                  fit
                  size="small"
                  @click="() => deleteFreeTag(freeTag)"
                >
                  {{ $t('freeTag.delete') }}
                </BBtn>
              </BMoreMenu>
            </BLayout>
            <div v-if="freeTags.length === 0">
              <BEmptyBox class="pt-600 pb-600">
                <slot name="text">
                  {{ $t('freeTag.empty') }}
                </slot>
              </BEmptyBox>
            </div>
          </div>
        </div>
      </div>
    </BModalBody>
    <BModalFooter>
      <BLayout
        class="footer"
        align-center
        justify-center
      >
        <BInput
          v-model="inputName"
          v-model:valid="createValid"
          class="input-name mr-400"
          :placeholder="$t('freeTag.text')"
          :rules="[requiredRule]"
          :disabled="$wait.is('createFreeTagWait')"
          @keypress-enter="createFreeTag"
        />
        <BBtn
          type="primary"
          :disabled="!createValid"
          :loading="$wait.is('createFreeTagWait')"
          @click="createFreeTag"
        >
          <span>{{ $t('freeTag.create') }}</span>
        </BBtn>
      </BLayout>
    </BModalFooter>
  </BHeightAdjuster>
</template>

<script>
import { mapWaitingActions } from 'vue-wait';
import { mapGetters } from 'vuex';
import errorHandler from '@/mixins/error_handler';
import inputValidation from '@/mixins/input_validation';

export default {
  mixins: [inputValidation, errorHandler],
  props: {
    id: {
      type: Number,
      required: false,
    },
  },
  data () {
    return {
      inputName: '',
      createValid: false,
    };
  },
  computed: {
    ...mapGetters('user', [
      'freeTags',
    ]),
  },
  created () {
    this.getFreeTagsAction();
  },
  methods: {
    ...mapWaitingActions('user', {
      createFreeTagAction: 'createFreeTagWait',
      updateFreeTagAction: 'updateFreeTagWait',
      deleteFreeTagAction: 'deleteFreeTagWait',
      getFreeTagsAction: 'getFreeTagsWait',
    }),
    async createFreeTag () {
      const freeTag = { text: this.inputName };
      await this.createFreeTagAction({
        body: { freeTag },
        errorHandlers: { 422: this.defaultHandler },
      });
      this.inputName = '';
    },
    async deleteFreeTag (freeTag) {
      const check = await this.$bitterAlert.show({
        title: this.$t('freeTag.title'),
        text: `${this.$t('general.confirm.actionWithTarget', { target: freeTag.text, action: this.$t('general.delete.text') })}\n\n${this.$t('freeTag.deleteMessage')}`,
      });
      if (!check) { return; }
      await this.deleteFreeTagAction({
        id: freeTag.id,
      });
    },
    async handleUpdate (text, freeTagId) {
      await this.updateFreeTagAction({
        id: freeTagId,
        body: { text },
        errorHandlers: { 422: this.defaultHandler },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.b-modal-body) {
  margin-top: 20px;
  padding: 0 22px 20px;
  .list-content {
    overflow-y: auto;
    .item-content {
      height: calc(100vh - 320px);
    }
    .item-row {
      margin: 0;
      @media only screen and (min-width: 959px) {
        width: 50%;
      }
      .item {
        margin: 0;
      }
      .b-input-box {
        margin-left: 8px;
      }
    }
  }
}

.setting-message {
  color: $textcolor-light;
  margin-bottom: $basespace-400;
  white-space: pre-line;
  margin-left: $basespace-100;
}

.footer {
  padding-top: $basespace-300;
  .input-name {
    width: $setting-input-width;
  }
}
</style>
