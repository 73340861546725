/* tslint:disable */
/* eslint-disable */
/**
 * openapi
 * bales cloud
 *
 * The version of the OpenAPI document: 1.0
 * Contact: test@example.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
/**
 * SfReportImportSettingsApi - axios parameter creator
 * @export
 */
export const SfReportImportSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * sfの定期実行の削除API
         * @summary sfの定期実行の削除
         * @param {number} sfReportImportSettingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sfReportImportSettingsDestory: async (sfReportImportSettingId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sfReportImportSettingId' is not null or undefined
            assertParamExists('sfReportImportSettingsDestory', 'sfReportImportSettingId', sfReportImportSettingId)
            const localVarPath = `/api/user/salesforce/sf_report_import_settings/${sfReportImportSettingId}`
                .replace(`{${"sfReportImportSettingId"}}`, encodeURIComponent(String(sfReportImportSettingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * sfの定期実行の無効化API
         * @summary sfの定期実行の無効化
         * @param {number} sfReportImportSettingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sfReportImportSettingsDisable: async (sfReportImportSettingId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sfReportImportSettingId' is not null or undefined
            assertParamExists('sfReportImportSettingsDisable', 'sfReportImportSettingId', sfReportImportSettingId)
            const localVarPath = `/api/user/salesforce/sf_report_import_settings/${sfReportImportSettingId}/disable`
                .replace(`{${"sfReportImportSettingId"}}`, encodeURIComponent(String(sfReportImportSettingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * sfの定期実行の有効化
         * @summary sfの定期実行の有効化
         * @param {number} sfReportImportSettingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sfReportImportSettingsEnable: async (sfReportImportSettingId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sfReportImportSettingId' is not null or undefined
            assertParamExists('sfReportImportSettingsEnable', 'sfReportImportSettingId', sfReportImportSettingId)
            const localVarPath = `/api/user/salesforce/sf_report_import_settings/${sfReportImportSettingId}/enable`
                .replace(`{${"sfReportImportSettingId"}}`, encodeURIComponent(String(sfReportImportSettingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SfReportImportSettingsApi - functional programming interface
 * @export
 */
export const SfReportImportSettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SfReportImportSettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * sfの定期実行の削除API
         * @summary sfの定期実行の削除
         * @param {number} sfReportImportSettingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sfReportImportSettingsDestory(sfReportImportSettingId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sfReportImportSettingsDestory(sfReportImportSettingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * sfの定期実行の無効化API
         * @summary sfの定期実行の無効化
         * @param {number} sfReportImportSettingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sfReportImportSettingsDisable(sfReportImportSettingId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sfReportImportSettingsDisable(sfReportImportSettingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * sfの定期実行の有効化
         * @summary sfの定期実行の有効化
         * @param {number} sfReportImportSettingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sfReportImportSettingsEnable(sfReportImportSettingId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sfReportImportSettingsEnable(sfReportImportSettingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SfReportImportSettingsApi - factory interface
 * @export
 */
export const SfReportImportSettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SfReportImportSettingsApiFp(configuration)
    return {
        /**
         * sfの定期実行の削除API
         * @summary sfの定期実行の削除
         * @param {number} sfReportImportSettingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sfReportImportSettingsDestory(sfReportImportSettingId: number, options?: any): AxiosPromise<void> {
            return localVarFp.sfReportImportSettingsDestory(sfReportImportSettingId, options).then((request) => request(axios, basePath));
        },
        /**
         * sfの定期実行の無効化API
         * @summary sfの定期実行の無効化
         * @param {number} sfReportImportSettingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sfReportImportSettingsDisable(sfReportImportSettingId: number, options?: any): AxiosPromise<void> {
            return localVarFp.sfReportImportSettingsDisable(sfReportImportSettingId, options).then((request) => request(axios, basePath));
        },
        /**
         * sfの定期実行の有効化
         * @summary sfの定期実行の有効化
         * @param {number} sfReportImportSettingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sfReportImportSettingsEnable(sfReportImportSettingId: number, options?: any): AxiosPromise<void> {
            return localVarFp.sfReportImportSettingsEnable(sfReportImportSettingId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SfReportImportSettingsApi - interface
 * @export
 * @interface SfReportImportSettingsApi
 */
export interface SfReportImportSettingsApiInterface {
    /**
     * sfの定期実行の削除API
     * @summary sfの定期実行の削除
     * @param {number} sfReportImportSettingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SfReportImportSettingsApiInterface
     */
    sfReportImportSettingsDestory(sfReportImportSettingId: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * sfの定期実行の無効化API
     * @summary sfの定期実行の無効化
     * @param {number} sfReportImportSettingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SfReportImportSettingsApiInterface
     */
    sfReportImportSettingsDisable(sfReportImportSettingId: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * sfの定期実行の有効化
     * @summary sfの定期実行の有効化
     * @param {number} sfReportImportSettingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SfReportImportSettingsApiInterface
     */
    sfReportImportSettingsEnable(sfReportImportSettingId: number, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * Request parameters for sfReportImportSettingsDestory operation in SfReportImportSettingsApi.
 * @export
 * @interface SfReportImportSettingsApiSfReportImportSettingsDestoryRequest
 */
export interface SfReportImportSettingsApiSfReportImportSettingsDestoryRequest {
    /**
     * 
     * @type {number}
     * @memberof SfReportImportSettingsApiSfReportImportSettingsDestory
     */
    readonly sfReportImportSettingId: number
}

/**
 * Request parameters for sfReportImportSettingsDisable operation in SfReportImportSettingsApi.
 * @export
 * @interface SfReportImportSettingsApiSfReportImportSettingsDisableRequest
 */
export interface SfReportImportSettingsApiSfReportImportSettingsDisableRequest {
    /**
     * 
     * @type {number}
     * @memberof SfReportImportSettingsApiSfReportImportSettingsDisable
     */
    readonly sfReportImportSettingId: number
}

/**
 * Request parameters for sfReportImportSettingsEnable operation in SfReportImportSettingsApi.
 * @export
 * @interface SfReportImportSettingsApiSfReportImportSettingsEnableRequest
 */
export interface SfReportImportSettingsApiSfReportImportSettingsEnableRequest {
    /**
     * 
     * @type {number}
     * @memberof SfReportImportSettingsApiSfReportImportSettingsEnable
     */
    readonly sfReportImportSettingId: number
}

/**
 * SfReportImportSettingsApi - object-oriented interface
 * @export
 * @class SfReportImportSettingsApi
 * @extends {BaseAPI}
 */
export class SfReportImportSettingsApi extends BaseAPI implements SfReportImportSettingsApiInterface {
    /**
     * sfの定期実行の削除API
     * @summary sfの定期実行の削除
     * @param {SfReportImportSettingsApiSfReportImportSettingsDestoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SfReportImportSettingsApi
     */
    public sfReportImportSettingsDestory(requestParameters: SfReportImportSettingsApiSfReportImportSettingsDestoryRequest, options?: AxiosRequestConfig) {
        return SfReportImportSettingsApiFp(this.configuration).sfReportImportSettingsDestory(requestParameters.sfReportImportSettingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * sfの定期実行の無効化API
     * @summary sfの定期実行の無効化
     * @param {SfReportImportSettingsApiSfReportImportSettingsDisableRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SfReportImportSettingsApi
     */
    public sfReportImportSettingsDisable(requestParameters: SfReportImportSettingsApiSfReportImportSettingsDisableRequest, options?: AxiosRequestConfig) {
        return SfReportImportSettingsApiFp(this.configuration).sfReportImportSettingsDisable(requestParameters.sfReportImportSettingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * sfの定期実行の有効化
     * @summary sfの定期実行の有効化
     * @param {SfReportImportSettingsApiSfReportImportSettingsEnableRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SfReportImportSettingsApi
     */
    public sfReportImportSettingsEnable(requestParameters: SfReportImportSettingsApiSfReportImportSettingsEnableRequest, options?: AxiosRequestConfig) {
        return SfReportImportSettingsApiFp(this.configuration).sfReportImportSettingsEnable(requestParameters.sfReportImportSettingId, options).then((request) => request(this.axios, this.basePath));
    }
}
