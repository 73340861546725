<template>
  <div class="sisense-user-form">
    <h3 class="mb-300">
      {{ $t('sisense.createUser') }}
    </h3>
    <BListItem class="mb-300">
      <template #header>
        <span>{{ $t('sisense.email') }}</span>
      </template>
      <BInput
        v-model="email"
        v-model:valid="emailValid"
        :rules="[emailRule]"
        :placeholder="$t('userManagement.placeholder.email')"
        required
        validation
      />
    </BListItem>
    <BListItem class="mb-600">
      <template #header>
        <span>{{ $t('sisense.role') }}</span>
      </template>
      <BSelect
        v-model="role"
        v-model:valid="roleValid"
        :items="roles"
        required
      />
    </BListItem>
    <BLayout justify-center>
      <BBtn
        type="primary"
        :disabled="!formValid"
        @click="handleCreateClick"
      >
        {{ $t('general.create.text') }}
      </BBtn>
    </BLayout>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { SisenseApiService } from '@/api/admin/resources/sisense';
import { PostSisenseUserBodyRoleEnum } from '@/api/openapi';
import { useErrorHandler } from '@/composable/error-handler';
import { useInputValidations } from '@/composable/input-validations';
import { useAlert } from '@/composable/message-dialog';
import { useWait } from '@/composable/vue-wait';
import { TOptions } from '@/plugins/biscuet-materials/components/atoms/BSelect.vue';

type TProps = {
  clientId: number;
};
const props = defineProps<TProps>();

const emailValid = ref(false);
const roleValid = ref(false);
const formValid = computed(() => emailValid.value && roleValid.value);

const email = defineModel<string>('email');
const role = defineModel<PostSisenseUserBodyRoleEnum>('role');

const i18n = useI18n();
const { doActionWithWait } = useWait();
const { alert } = useAlert();
const { emailRule } = useInputValidations();
const { defaultHandler } = useErrorHandler();
const api = new SisenseApiService();

const roles: TOptions = Object.values(PostSisenseUserBodyRoleEnum).map((role) => ({
  text: i18n.t(`sisense.roles.${role}`),
  value: role,
}));

const handleCreateClick = async () => {
  await doActionWithWait('createSisenseUserWait', async () => {
    const { data: { groupCreated } } = await api.postSisenseUser({
      request: {
        clientId: props.clientId,
        postSisenseUserBody: {
          email: email.value,
          role: role.value,
        },
      },
      errorHandlers: {
        500: defaultHandler,
      },
    });
    await alert({
      title: i18n.t('sisense.createUser'),
      message: groupCreated ? i18n.t('sisense.createUserAndGroupSuccess') : i18n.t('sisense.createUserSuccess'),
    });
  });
};
</script>
