<template>
  <BMenu
    v-model="isOpen"
  >
    <template #activator>
      <BBtn
        flat
        fab
        size="small"
        @click="focusInput"
      >
        <BIcon>search</BIcon>
      </BBtn>
    </template>
    <template #default>
      <BCard
        class="result-card"
        full
      >
        <div class="result-header">
          <BInput
            ref="input"
            v-model="inputSearchText"
            class="search-input"
            :placeholder="$t('search.placeholder')"
            flat
            @update:model-value="handleInput"
            @keypress-enter="search"
          />
          <BIcon
            class="search-input-icon"
            hover
            @click="search"
          >
            search
          </BIcon>
          <Transition name="slide-x-reverse">
            <div
              v-if="!isLoading && resultVisibled"
              class="search-result-length-text"
            >
              <BTooltip
                top
                :content="$t('search.maxResultMessage')"
                :value="isMaxResult"
              >
                <div><span class="text-annotation mr-100">{{ $t('general.target') }}</span><span class="fs-400 fw-bold">{{ fulltextSearchResult.length }}</span></div>
              </BTooltip>
            </div>
          </Transition>
        </div>
        <div
          v-if="inputSearchText && resultVisibled"
          v-loading="isLoading"
          class="result-body"
        >
          <div
            v-if="!isLoading"
            v-infinite-scroll="load"
            infinite-scroll-disabled="scrollDisabled"
          >
            <div
              v-if="fulltextSearchResult.length === 0"
              class="empty-result-content"
            >
              <div class="fs-400 fw-bold text-center break-word-pre-wrap py-400">
                {{ `${$t('search.notFound', { text: inputSearchText })}` }}
              </div>
              <div class="text-center pb-300">
                {{ $t('search.notFoundMessage') }}
              </div>
            </div>
            <div v-else>
              <div
                v-for="count in displayCount"
                :key="`search-result-${count}`"
                class="result-content hover-effect"
              >
                <LeadLink
                  class="result-content-inner"
                  :call-target-id="fulltextSearchResult[count - 1].id"
                  @click="clear"
                >
                  <div class="fw-bold truncate-text">
                    {{ fulltextSearchResult[count - 1].companyName }}
                  </div>
                  <div
                    v-for="(result, index) in fulltextSearchResult[count - 1].matchResults"
                    :key="index"
                    class="fs-100 py-100 break-word-pre-wrap"
                  >
                    <BListItem>
                      <template #header>
                        <span>{{ result.title }}</span>
                      </template>
                      <span>{{ result.pre }}</span>
                      <span class="match-text">{{ result.target }}</span>
                      <span>{{ result.after }}</span>
                    </BListItem>
                  </div>
                </LeadLink>
              </div>
            </div>
            <BLayout
              v-if="!scrollDisabled && $wait.is('infiniteLoadingWait')"
              class="py-200"
              justify-center
            >
              <BElementIcon name="Loading" />
            </BLayout>
          </div>
        </div>
      </BCard>
    </template>
  </BMenu>
</template>

<script>
import { mapWaitingActions } from 'vue-wait';
import { mapGetters } from 'vuex';
import LeadLink from '@/components/organisms/user/general/LeadLink.vue';
import { SHORTCUT_KEY, useShortcutKey } from '@/composable/shortcut-key';

export default {
  components: {
    LeadLink,
  },
  setup() {
    const { setCallback: setShortcutKeyCallback } = useShortcutKey([SHORTCUT_KEY.Ctrl, SHORTCUT_KEY.k]);

    return { setShortcutKeyCallback };
  },
  data () {
    return {
      inputSearchText: '',
      resultVisibled: false,
      isOpen: false,
      displayCount: 0,
      initialDisplayCount: 20,
      increaseNumber: 10,
    };
  },
  computed: {
    ...mapGetters('user', [
      'fulltextSearchResult',
    ]),
    isLoading () {
      return this.$wait.is('fulltextSearchWait');
    },
    scrollDisabled () {
      return this.displayCount >= this.resultLength;
    },
    resultLength () {
      return this.fulltextSearchResult.length;
    },
    isMaxResult () {
      return this.resultLength === 500;
    },
  },
  mounted() {
    this.setShortcutKeyCallback(() => {
      this.handleShortKey();
    });
  },
  beforeUnmount () {
    this.clear();
  },
  methods: {
    ...mapWaitingActions('user', {
      fulltextSearchAction: 'fulltextSearchWait',
      clearFulltextSearchAction: 'clearFulltextSearchWait',
    }),
    async search () {
      this.resultVisibled = true;
      if (this.inputSearchText.length === 0) return;
      await this.fulltextSearchAction({ textQuery: this.inputSearchText });
      if (this.resultLength < this.initialDisplayCount) {
        this.displayCount = this.resultLength;
      } else {
        this.displayCount = this.initialDisplayCount;
      }
    },
    clear () {
      this.resultVisibled = false;
      this.isOpen = false;
      this.inputSearchText = '';
      this.displayCount = 0;
      this.clearFulltextSearchAction();
    },
    handleInput () {
      if (this.resultVisibled) {
        this.resultVisibled = false;
        this.displayCount = 0;
        this.clearFulltextSearchAction();
      }
    },
    load () {
      this.$wait.start('infiniteLoadingWait');
      // 何も設定していないと、infinite scrollが唐突に出現するため少しだけloadingをさせています。
      setTimeout(() => {
        if (this.displayCount + this.increaseNumber >= this.resultLength) {
          const remainingResultLength = this.resultLength - this.displayCount;
          this.displayCount += remainingResultLength;
        } else {
          this.displayCount += this.increaseNumber;
        }
        this.$wait.end('infiniteLoadingWait');
      }, 200);
    },
    handleShortKey () {
      this.isOpen = !this.isOpen;
      this.focusInput();
    },
    focusInput () {
      setTimeout(() => {
        this.$refs.input.forceFocus();
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
  .result-card {
    @include m-fixed-width(600px);
  }

  .result-header {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 $basespace-600;
    @include m-fixed-height(60px);

    .search-input-icon {
      position: absolute;
      top: 17px;
      left: $basespace-600;
      cursor: pointer;
    }

    .search-input {
      padding-left: $basespace-500;
      padding-right: 60px;
    }

    .search-result-length-text {
      position: absolute;
      top: 15px;
      right: $basespace-600;
      transition: $transition-base;
    }
  }

  .result-body {
    height: 100%;
    min-height: 100px;
    max-height: calc(580px - 60px);
    overflow-y: auto;
    border-top: 1px solid $bdcolor-base;
  }

  .empty-result-content {
    padding: $basespace-200 $basespace-600;
  }

  .result-content {
    padding: $basespace-200 $basespace-600;
    border-bottom: 1px solid $bdcolor-base;
    position: relative;

    &:last-child {
      border-bottom: none;
    }

    &-inner {
      text-decoration: none;
      color: $textcolor-base;
    }

    .match-text {
      background-color: rgba($basecolor-warning, 0.4);
      padding: 2px;
    }
  }
</style>
