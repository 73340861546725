import { ActionTree } from 'vuex';
import { ApiArguments } from '@/api/api_service';
import {
  LeadStage,
  LeadStageApiDeleteLeadStageRequest,
  LeadStageApiPostLeadStageRequest,
  LeadStageApiPutLeadStageRequest,
} from '@/api/openapi';
import { LeadStageApiService } from '@/api/user/resources/lead_stage';
import { RootState, UserState } from '@/store/types';
import { types } from '@/store/user/mutations';
import Waiting from '@/store/waiting';

const actions: ActionTree<UserState, RootState> = {
  getLeadStagesAction: async (
    { commit },
    apiArguments?: ApiArguments<undefined>,
  ) => {
    await waiting.check('getLeadStagesAction', async () => {
      try {
        const api = new LeadStageApiService();
        const res = await api.getLeadStages(apiArguments);
        commit(types.SET_LEAD_STAGES, res.data);
      } catch (err) {
        commit(types.CLEAR_LEAD_STAGES);
        throw err;
      }
    });
    return true;
  },
  createLeadStageAction: async (
    _context,
    apiArguments: ApiArguments<LeadStageApiPostLeadStageRequest>,
  ) => {
    await waiting.check('createLeadStageAction', async () => {
      const api = new LeadStageApiService();
      return await api.createLeadStage(apiArguments);
    });
    return true;
  },
  updateLeadStageAction: async (
    _context,
    apiArguments: ApiArguments<LeadStageApiPutLeadStageRequest>,
  ) => {
    await waiting.check('updateLeadStageAction', async () => {
      const api = new LeadStageApiService();
      return await api.updateLeadStage(apiArguments);
    });
    return true;
  },
  deleteLeadStageAction: async (
    _context,
    apiArguments: ApiArguments<LeadStageApiDeleteLeadStageRequest>,
  ) => {
    await waiting.check('deleteLeadStageAction', async () => {
      const api = new LeadStageApiService();
      return await api.deleteLeadStage(apiArguments);
    });
    return true;
  },
  postCallTargetLeadStageAction: async (
    { commit },
    { callTargetId, leadStage }: {
      callTargetId: number;
      leadStage: LeadStage;
    },
  ) => {
    await waiting.check('postCallTargetLeadStage', async () => {
      const api = new LeadStageApiService();
      await api.postCallTargetLeadStage({
        request: {
          callTargetId,
          postCallTargetLeadStageBody: {
            leadStageId: leadStage.id,
          },
        },
      }).then(() => {
        commit(types.SET_CALL_TARGET_LEAD_STAGE, leadStage);
      });
      return true;
    });
  },
};

const waiting = new Waiting(actions);

export default actions;
