import ApiBase from '@/api/base';

export default {
  getOperator (call_target_id) {
    const  id  = call_target_id;
    return ApiBase.get(`/user/call_target/${id}/operator`);
  },
  createOperator (args) {
    return ApiBase.post(`/user/call_target/operator`, args);
  },
  withdrawOperator (call_target_id) {
    const id  = call_target_id;
    return ApiBase.post(`/user/call_target/${id}/withdraw_operator`);
  },
};
