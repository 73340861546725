<template>
  <BHeightAdjuster>
    <BModalHeader
      :title="title"
      center
      @modal-close="hide"
    />
    <BModalBody
      v-if="currentState === state.importForm"
      v-loading="$wait.is(waitName)"
      :element-loading-text="loadingText"
    >
      <div class="import-message mb-400">
        <p class="sheet-name-description">
          {{ $t('leadList.import.sheetName') }}
        </p>
      </div>
      <BFileUploadArea
        ref="fileUploadArea"
        class="mt-400"
        :accept-data-type="acceptDataType"
        @upload="checkUploadOverwriteData"
      />
    </BModalBody>
    <template v-if="currentState === state.confirm">
      <BModalBody v-loading="$wait.is(waitName)">
        <LeadOverwriteConfirm
          v-bind="overwriteImportConfirmProps"
          @save="uploadOverwriteData"
        />
      </BModalBody>
      <BModalFooter>
        <BLayout justify-center>
          <BBtn
            type="primary"
            :loading="$wait.is(waitName)"
            :disabled="validCallTargetCount === 0"
            @click="uploadOverwriteData"
          >
            {{ $t(`callList.upload.dateUpdate`) }}
          </BBtn>
        </BLayout>
      </BModalFooter>
    </template>
    <BModalBody v-if="currentState === state.completed">
      <div class="done">
        <div class="loader" />
        <div class="title">
          {{ $t(`callList.upload.accept.title`) }}
        </div>
      </div>
    </BModalBody>
  </BHeightAdjuster>
</template>

<script>
import { mapWaitingActions } from 'vue-wait';
import { mapGetters } from 'vuex';
import ApiBase from '@/api/base';
import Api from '@/api/user';
import LeadOverwriteConfirm from '@/components/organisms/user/leadList/modal/LeadOverwriteConfirm.vue';
import errorHandler from '@/mixins/error_handler';
import EncodingService from '@/services/encoding_service';

export default {
  components: {
    LeadOverwriteConfirm,
  },
  mixins: [errorHandler],
  props: {
    title: {
      type: String,
      default: '',
    },
    modalName: {
      type: String,
      default: 'CsvOverwriteModal',
    },
    acceptDataType: {
      type: String,
      default: '.csv',
    },
  },
  data() {
    return {
      currentState: 0,
      selectedLeadSourceId: null,
      updateCandidateTargets: {},
      newCandidateTargets: {},
      invalidHeaders: [],
      validHeaders: [],
      importCallTargetCount: 0,
      validCallTargetCount: 0,
      timeId: null,
      loadingText: '',
    };
  },
  computed: {
    ...mapGetters('user', ['leadSources']),
    state() {
      return {
        importForm: 0,
        comfirm: 1,
        completed: 2,
      };
    },
    waitName() {
      return 'getLeadCsvOverwriteTasksWaitOnModal';
    },
    overwriteImportConfirmProps() {
      return {
        invalidHeaders: this.invalidHeaders,
        validHeaders: this.validHeaders,
        importCallTargetCount: this.importCallTargetCount,
        validCallTargetCount: this.validCallTargetCount,
      };
    },
  },
  watch: {
    currentState(value) {
      if (value === this.state.completed) {
        setTimeout(() => this.hide(), 2000);
      }
    },
  },
  methods: {
    ...mapWaitingActions('user', {
      getLeadCsvOverwriteTasksAction: 'getLeadCsvOverwriteTasksWaitOnModal',
      getLeadExcelOverwriteTasksAction: 'getLeadExcelOverwriteTasksWaitOnModal',
    }),
    ...mapWaitingActions('userUi', {
      addPendingCsvOverwriteTaskIdAction: 'addPendingCsvOverwriteTaskIdAction',
      addPendingExcelOverwriteTaskIdAction: 'addPendingExcelOverwriteTaskIdAction',
    }),
    show() {
      this.$modal.show(this.modalName);
    },
    hide() {
      this.currentState = this.state.importForm;
      this.$modal.hide(this.modalName);
    },
    checkUploadOverwriteData(file) {
      this.changeLoadingText(this.$t(`callList.upload.uploadMessage`));
      const separatedFileName = file.name.split('.');
      const importFileExtension = `.${separatedFileName[separatedFileName.length - 1]}`;

      if (importFileExtension !== this.acceptDataType) {
        this.$bitterAlert.show({
          text: this.$t('import.errors.extension'),
          buttonsCancel: false,
        });
        return;
      }
      this.$wait.start(this.waitName);

      const reader = new FileReader();
      reader.onload = async (e) => {
        const blob = new EncodingService().encodeArrayBufferToBlob(e.target.result, 'UTF8');

        // 単位はbyte, 2MBを指定
        const MAX_FILE_SIZE = 2000000;
        if (blob.size > MAX_FILE_SIZE) {
          this.onFileUploadFailed();
          this.$wait.end(this.waitName);
          this.overFileSizeError(this.$t('general.maxFileSize'));
          return false;
        }
        const result = await Api.createUpdateFileStorageAccessUrl({
          body: { fileName: file.name },
        }).catch((err) => {
          this.onFileUploadFailed();
          this.$wait.end(this.waitName);
          throw err;
        });

        const formData = new FormData();
        formData.append('file', file);
        formData.append('key', result.data.key);

        await ApiBase.unauthorizedPut(result.data.url, {
          body: file,
          errorHandlers: {
            403: this.fileUploadErrorHandler,
          },
        }).catch((err) => {
          this.onFileUploadFailed();
          this.$wait.end(this.waitName);
          throw err;
        });

        this.changeLoadingText(this.$t(`callList.upload.fileCheckMessage`));
        formData.append('leadSourceId', this.selectedLeadSourceId);
        this.formData = formData;

        const checkDataApi = this.acceptDataType === '.csv' ? Api.checkOverwriteCsvData : Api.checkOverwriteExcelData;

        try {
          const result = await checkDataApi({
            body: formData,
            errorHandlers: {
              400: this.fileLengthErrorHandler,
              422: this.defaultHandler,
              500: this.defaultHandler,
            },
          });

          const jobManagerId = result.data.jobManagerId;
          this.checkOverwriteDataResult(jobManagerId);
          this.timeId = this.$setInterval(() => {
            this.checkOverwriteDataResult(jobManagerId);
          }, 3000);
        } catch {
          this.$wait.end(this.waitName);
          this.onFileUploadFailed();
        }
      };
      reader.readAsArrayBuffer(file);
    },
    async checkOverwriteDataResult(jobManagerId) {
      try {
        const checkOverwriteTaskApi
            = this.acceptDataType === '.csv' ? Api.getLeadCsvCheckOverwriteTasks : Api.getLeadExcelCheckOverwriteTasks;
        const res = await checkOverwriteTaskApi({
          jobManagerId,
        });

        if (res?.data?.status === 'success') {
          const result = res.data.result;
          this.invalidHeaders = result.invalid_header;
          this.validHeaders = result.valid_header;
          this.importCallTargetCount = result.import_call_target_count;
          this.validCallTargetCount = result.valid_call_target_count;
          this.currentState = this.state.confirm;
          this.$clearInterval(this.timeId);
          this.$wait.end(this.waitName);
        } else if (res?.data?.status === 'error') {
          this.$bitterAlert.show({
            title: this.$t('general.error'),
            text: res?.data?.result?.message || this.$t('apiError.badRequest'),
            closeOnClickOutside: true,
            buttonsCancel: false,
          });
          this.$clearInterval(this.timeId);
          this.onFileUploadFailed();
          this.$wait.end(this.waitName);
        }
      } catch (e) {
        this.$wait.end(this.waitName);
        this.$clearInterval(this.timeId);
        this.onFileUploadFailed();
      }
    },
    uploadOverwriteData() {
      this.$wait.start(this.waitName);
      const overwriteApi = this.acceptDataType === '.csv' ? Api.overwriteCsvData : Api.overwriteExcelData;
      const tasksAction
          = this.acceptDataType === '.csv' ? this.getLeadCsvOverwriteTasksAction : this.getLeadExcelOverwriteTasksAction;
      const addTaskAction
          = this.acceptDataType === '.csv'
            ? this.addPendingCsvOverwriteTaskIdAction
            : this.addPendingExcelOverwriteTaskIdAction;

      overwriteApi({ body: this.formData })
        .then((res) => {
          addTaskAction(res.data.taskId);
          return tasksAction();
        })
        .then(() => {
          this.currentState = this.state.completed;
        })
        .finally(() => {
          this.$wait.end(this.waitName);
        });
    },
    onFileUploadFailed() {
      this.$refs.fileUploadArea.clear();
      this.formData = null;
    },
    overFileSizeError(fileSize) {
      this.$bitterAlert.show({
        title: this.$t('general.error'),
        text: this.$t('apiError.message.overFileSize', { size: fileSize }),
        closeOnClickOutside: true,
        buttonsCancel: false,
      });
    },
    changeLoadingText(changeText) {
      this.loadingText = changeText;
    },
  },
};
</script>

<style lang="scss" scoped>
  .lead-import-image {
    display: block;
    width: 280px;
    margin: 60px auto 0;
  }

  .done {
    .title {
      text-align: center;
      text-color: $textcolor-light;
      margin-bottom: $basespace-400;
    }
    .comment {
      text-align: center;
      text-color: grey;
    }
    .loader,
    .loader:before,
    .loader:after {
      border-radius: 50%;
      width: 2.5em;
      height: 2.5em;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      -webkit-animation: load7 1.8s infinite ease-in-out;
      animation: load7 1.8s infinite ease-in-out;
    }
    .loader {
      color: $basecolor-primary;
      font-size: 10px;
      margin: 80px auto;
      position: relative;
      text-indent: -9999em;
      -webkit-transform: translateZ(0);
      -ms-transform: translateZ(0);
      transform: translateZ(0);
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
    }
    .loader:before,
    .loader:after {
      content: '';
      position: absolute;
      top: 0;
    }
    .loader:before {
      left: -3.5em;
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }
    .loader:after {
      left: 3.5em;
    }
    @-webkit-keyframes load7 {
      0%,
      80%,
      100% {
        box-shadow: 0 2.5em 0 -1.3em;
      }
      40% {
        box-shadow: 0 2.5em 0 0;
      }
    }
    @keyframes load7 {
      0%,
      80%,
      100% {
        box-shadow: 0 2.5em 0 -1.3em;
      }
      40% {
        box-shadow: 0 2.5em 0 0;
      }
    }
  }

  .import-message {
    text-align: center;
    color: $textcolor-light;
    background-color: $bgcolor-base;
    padding: $basespace-300 $basespace-200;
    border-radius: 4px;
  }

  .sheet-name-description {
    margin-bottom: 0;
  }
</style>
