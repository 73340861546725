<template>
  <div class="theader">
    <div class="table-row theader-row">
      <BSortText
        v-for="cell in headers"
        :key="cell.key"
        class="table-cell header-cell"
        :class="cell.class"
        :item-text="cell.name"
        is-display-only
      />
      <div class="table-cell menu" />
    </div>
  </div>
  <div class="tbody">
    <div
      v-for="quickText in quickTexts"
      :key="quickText.id"
      class="table-row tbody-row"
      @click="() => handleRowClick(quickText.id)"
    >
      <div
        v-for="column in headers"
        :key="column.key"
        class="table-cell"
        :class="column.class"
      >
        <template v-if="column.key === 'name'">
          {{ quickText.name }}
          <BStatus
            class="mt-50"
            :status="quickText.status"
            :status-name="$t(`general.status.${quickText.status}`)"
          />
        </template>
        <template v-if="column.key === 'createdAt'">
          {{ formatShorterDateTime(quickText.createdAt) }}
        </template>
        <template v-if="column.key === 'updatedAt'">
          {{ formatShorterDateTime(quickText.updatedAt) }}
        </template>
        <template v-else-if="column.key === 'createdUser'">
          <BUserTooltip
            v-if="quickText.createdUser"
            :image-url="quickText.createdUser.avatarUrl"
            :name="quickText.createdUser.name"
            :user-id="quickText.createdUser.id"
            :size="40"
          />
        </template>
        <template v-else-if="column.key === 'updatedUser'">
          <BUserTooltip
            v-if="quickText.updatedUser"
            :image-url="quickText.updatedUser.avatarUrl"
            :name="quickText.updatedUser.name"
            :user-id="quickText.updatedUser.id"
            :size="40"
          />
        </template>
      </div>
      <div
        class="table-cell menu"
        @click.stop
      >
        <BMoreMenu
          z-index100
        >
          <div>
            <BBtn
              flat
              fit
              size="small"
              @click="() => handleDeleteButtonClick(quickText.id)"
            >
              {{ $t('general.delete.text') }}
            </BBtn>
          </div>
        </BMoreMenu>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { QuickText } from '@/api/openapi';
import { QuickTextApiService } from '@/api/user/resources/quick_text';
import { useDeleteConfirm } from '@/composable/message-dialog';
import { useWait } from '@/composable/vue-wait';
import { formatShorterDateTime } from '@/utils/date-time';

type TProps = {
  quickTexts: QuickText[];
};
type TEmit = {
  'afterDelete': [];
  'click:row': [id: number];
};

defineProps<TProps>();
const emit = defineEmits<TEmit>();

const i18n = useI18n();
const { deleteConfirm } = useDeleteConfirm();
const api = new QuickTextApiService();
const { doActionWithWait } = useWait();

const headers = [
  { key: 'name', name: i18n.t('quickText.name'), class: ['name-container'] },
  { key: 'createdAt', name: i18n.t('general.createdAt'), class: ['date-time'] },
  { key: 'updatedAt', name: i18n.t('general.updatedAt'), class: ['date-time'] },
  { key: 'createdUser', name: i18n.t('general.createdUser'), class: ['user'] },
  { key: 'updatedUser', name: i18n.t('general.updatedUser'), class: ['user'] },
];

const handleRowClick = (id: number) => emit('click:row', id);
const handleDeleteButtonClick = async (quickTextId: number) => {
  const ok = await deleteConfirm();
  if (!ok) return;
  await doActionWithWait('deleteQuickTextWait', async () => {
    await api.deleteQuickText({
      request: { quickTextId },
    });
  });
  emit('afterDelete');
};
</script>

<style lang="scss" scoped>
.table-row {
  align-items: center;
  padding-right: $basespace-400;
  display: inline-flex;
  min-width: 100%;
}

.table-cell {
  @include m-fixed-width(50px);
  padding-right: $basespace-200;
  padding-left: 10px;
  background-color: white;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  &:first-child {
    padding-left: 30px;
  }

  &.name-container {
    @include m-fixed-width(400px);
  }

  &.date-time {
    @include m-fixed-width(10rem);
  }
  &.user {
    justify-content: center;
    @include m-fixed-width(80px);
    :deep(.b-avatar) {
      justify-content: center;
    }
  }
  &.menu {
    width: 100%;
  }
}

.theader {
  height: 50px;
  position: sticky;
  top: 0;
  z-index: 2;
  cursor: text;
  &-row {
    height: inherit;
    background-color: white;
    border-bottom: 1px solid $bdcolor-base;
    padding-bottom: $basespace-100;
    color: $textcolor-light;
    font-size: $fontsize-100;
  }
}

.tbody-row {
  border-bottom: 1px solid $bdcolor-base;
  padding-top: $basespace-200;
  padding-bottom: $basespace-200;

  cursor: pointer;
  &:hover {
    background-color: $bgcolor-base;

    .table-cell {
      background-color: $bgcolor-base;
    }
  }
}
</style>
