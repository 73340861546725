<template>
  <BFlex
    v-loading="settingLoading"
    class="oauth-callback"
  >
    <template v-if="!settingLoading">
      <div v-if="success">
        <BAlert
          :text="$t('oauthCallback.linkComplete', {service_name: serviceName})"
          class="success"
          type="success"
          show-icon
        />
      </div>
      <div v-else>
        <BAlert
          :text="$t('oauthCallback.linkFailure', {service_name: serviceName})"
          class="alert"
          type="error"
          show-icon
        />
      </div>
      <div class="mt-600">
        <BBtn
          :disabled="settingLoading"
          :loading="settingLoading"
          @click="toHome"
        >
          {{ $t('oauthCallback.toTopPage') }}
        </BBtn>
      </div>
    </template>
  </BFlex>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    serviceName: {
      type: String,
      require: true,
    },
    waitLoader: {
      type: String,
      require: true,
    },
    success: {
      type: Boolean,
      require: true,
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    settingLoading() {
      return this.$wait.is([this.waitLoader]);
    },
  },
  methods: {
    toHome(): void {
      this.$router.push('Home');
    },
  },
});
</script>

<style lang="scss" scoped>
  .oauth-callback {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // NOTE: 要素の高さの約半分が50px
    margin-top: calc(50vh - 50px);
  }
</style>
