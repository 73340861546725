/* tslint:disable */
/* eslint-disable */
/**
 * openapi
 * bales cloud
 *
 * The version of the OpenAPI document: 1.0
 * Contact: test@example.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { LeadStage } from '../models';
// @ts-ignore
import { PostCallTargetLeadStageBody } from '../models';
// @ts-ignore
import { PostLeadStageBody } from '../models';
// @ts-ignore
import { PutLeadStageBody } from '../models';
/**
 * LeadStageApi - axios parameter creator
 * @export
 */
export const LeadStageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * リードステージ削除API
         * @summary リードステージ削除API
         * @param {number} leadStageId リードステージID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLeadStage: async (leadStageId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'leadStageId' is not null or undefined
            assertParamExists('deleteLeadStage', 'leadStageId', leadStageId)
            const localVarPath = `/api/user/lead_stages/{leadStageId}`
                .replace(`{${"leadStageId"}}`, encodeURIComponent(String(leadStageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リードステージ一覧取得API
         * @summary リードステージ一覧取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLeadStages: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/lead_stages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リード詳細リードステージ更新API
         * @summary リード詳細リードステージ更新API
         * @param {number} callTargetId リードID
         * @param {PostCallTargetLeadStageBody} [postCallTargetLeadStageBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCallTargetLeadStage: async (callTargetId: number, postCallTargetLeadStageBody?: PostCallTargetLeadStageBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'callTargetId' is not null or undefined
            assertParamExists('postCallTargetLeadStage', 'callTargetId', callTargetId)
            const localVarPath = `/api/user/call_targets/{call_target_id}/update_lead_stage`
                .replace(`{${"call_target_id"}}`, encodeURIComponent(String(callTargetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postCallTargetLeadStageBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リードステージ作成API
         * @summary リードステージ作成API
         * @param {PostLeadStageBody} [postLeadStageBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLeadStage: async (postLeadStageBody?: PostLeadStageBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/lead_stages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postLeadStageBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リードステージ更新API
         * @summary リードステージ更新API
         * @param {number} leadStageId リードステージID
         * @param {PutLeadStageBody} [putLeadStageBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putLeadStage: async (leadStageId: number, putLeadStageBody?: PutLeadStageBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'leadStageId' is not null or undefined
            assertParamExists('putLeadStage', 'leadStageId', leadStageId)
            const localVarPath = `/api/user/lead_stages/{leadStageId}`
                .replace(`{${"leadStageId"}}`, encodeURIComponent(String(leadStageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putLeadStageBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LeadStageApi - functional programming interface
 * @export
 */
export const LeadStageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LeadStageApiAxiosParamCreator(configuration)
    return {
        /**
         * リードステージ削除API
         * @summary リードステージ削除API
         * @param {number} leadStageId リードステージID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLeadStage(leadStageId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLeadStage(leadStageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リードステージ一覧取得API
         * @summary リードステージ一覧取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLeadStages(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LeadStage>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLeadStages(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リード詳細リードステージ更新API
         * @summary リード詳細リードステージ更新API
         * @param {number} callTargetId リードID
         * @param {PostCallTargetLeadStageBody} [postCallTargetLeadStageBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCallTargetLeadStage(callTargetId: number, postCallTargetLeadStageBody?: PostCallTargetLeadStageBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCallTargetLeadStage(callTargetId, postCallTargetLeadStageBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リードステージ作成API
         * @summary リードステージ作成API
         * @param {PostLeadStageBody} [postLeadStageBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postLeadStage(postLeadStageBody?: PostLeadStageBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadStage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postLeadStage(postLeadStageBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リードステージ更新API
         * @summary リードステージ更新API
         * @param {number} leadStageId リードステージID
         * @param {PutLeadStageBody} [putLeadStageBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putLeadStage(leadStageId: number, putLeadStageBody?: PutLeadStageBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadStage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putLeadStage(leadStageId, putLeadStageBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LeadStageApi - factory interface
 * @export
 */
export const LeadStageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LeadStageApiFp(configuration)
    return {
        /**
         * リードステージ削除API
         * @summary リードステージ削除API
         * @param {number} leadStageId リードステージID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLeadStage(leadStageId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteLeadStage(leadStageId, options).then((request) => request(axios, basePath));
        },
        /**
         * リードステージ一覧取得API
         * @summary リードステージ一覧取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLeadStages(options?: any): AxiosPromise<Array<LeadStage>> {
            return localVarFp.getLeadStages(options).then((request) => request(axios, basePath));
        },
        /**
         * リード詳細リードステージ更新API
         * @summary リード詳細リードステージ更新API
         * @param {number} callTargetId リードID
         * @param {PostCallTargetLeadStageBody} [postCallTargetLeadStageBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCallTargetLeadStage(callTargetId: number, postCallTargetLeadStageBody?: PostCallTargetLeadStageBody, options?: any): AxiosPromise<void> {
            return localVarFp.postCallTargetLeadStage(callTargetId, postCallTargetLeadStageBody, options).then((request) => request(axios, basePath));
        },
        /**
         * リードステージ作成API
         * @summary リードステージ作成API
         * @param {PostLeadStageBody} [postLeadStageBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLeadStage(postLeadStageBody?: PostLeadStageBody, options?: any): AxiosPromise<LeadStage> {
            return localVarFp.postLeadStage(postLeadStageBody, options).then((request) => request(axios, basePath));
        },
        /**
         * リードステージ更新API
         * @summary リードステージ更新API
         * @param {number} leadStageId リードステージID
         * @param {PutLeadStageBody} [putLeadStageBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putLeadStage(leadStageId: number, putLeadStageBody?: PutLeadStageBody, options?: any): AxiosPromise<LeadStage> {
            return localVarFp.putLeadStage(leadStageId, putLeadStageBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LeadStageApi - interface
 * @export
 * @interface LeadStageApi
 */
export interface LeadStageApiInterface {
    /**
     * リードステージ削除API
     * @summary リードステージ削除API
     * @param {number} leadStageId リードステージID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadStageApiInterface
     */
    deleteLeadStage(leadStageId: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * リードステージ一覧取得API
     * @summary リードステージ一覧取得API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadStageApiInterface
     */
    getLeadStages(options?: AxiosRequestConfig): AxiosPromise<Array<LeadStage>>;

    /**
     * リード詳細リードステージ更新API
     * @summary リード詳細リードステージ更新API
     * @param {number} callTargetId リードID
     * @param {PostCallTargetLeadStageBody} [postCallTargetLeadStageBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadStageApiInterface
     */
    postCallTargetLeadStage(callTargetId: number, postCallTargetLeadStageBody?: PostCallTargetLeadStageBody, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * リードステージ作成API
     * @summary リードステージ作成API
     * @param {PostLeadStageBody} [postLeadStageBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadStageApiInterface
     */
    postLeadStage(postLeadStageBody?: PostLeadStageBody, options?: AxiosRequestConfig): AxiosPromise<LeadStage>;

    /**
     * リードステージ更新API
     * @summary リードステージ更新API
     * @param {number} leadStageId リードステージID
     * @param {PutLeadStageBody} [putLeadStageBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadStageApiInterface
     */
    putLeadStage(leadStageId: number, putLeadStageBody?: PutLeadStageBody, options?: AxiosRequestConfig): AxiosPromise<LeadStage>;

}

/**
 * Request parameters for deleteLeadStage operation in LeadStageApi.
 * @export
 * @interface LeadStageApiDeleteLeadStageRequest
 */
export interface LeadStageApiDeleteLeadStageRequest {
    /**
     * リードステージID
     * @type {number}
     * @memberof LeadStageApiDeleteLeadStage
     */
    readonly leadStageId: number
}

/**
 * Request parameters for postCallTargetLeadStage operation in LeadStageApi.
 * @export
 * @interface LeadStageApiPostCallTargetLeadStageRequest
 */
export interface LeadStageApiPostCallTargetLeadStageRequest {
    /**
     * リードID
     * @type {number}
     * @memberof LeadStageApiPostCallTargetLeadStage
     */
    readonly callTargetId: number

    /**
     * 
     * @type {PostCallTargetLeadStageBody}
     * @memberof LeadStageApiPostCallTargetLeadStage
     */
    readonly postCallTargetLeadStageBody?: PostCallTargetLeadStageBody
}

/**
 * Request parameters for postLeadStage operation in LeadStageApi.
 * @export
 * @interface LeadStageApiPostLeadStageRequest
 */
export interface LeadStageApiPostLeadStageRequest {
    /**
     * 
     * @type {PostLeadStageBody}
     * @memberof LeadStageApiPostLeadStage
     */
    readonly postLeadStageBody?: PostLeadStageBody
}

/**
 * Request parameters for putLeadStage operation in LeadStageApi.
 * @export
 * @interface LeadStageApiPutLeadStageRequest
 */
export interface LeadStageApiPutLeadStageRequest {
    /**
     * リードステージID
     * @type {number}
     * @memberof LeadStageApiPutLeadStage
     */
    readonly leadStageId: number

    /**
     * 
     * @type {PutLeadStageBody}
     * @memberof LeadStageApiPutLeadStage
     */
    readonly putLeadStageBody?: PutLeadStageBody
}

/**
 * LeadStageApi - object-oriented interface
 * @export
 * @class LeadStageApi
 * @extends {BaseAPI}
 */
export class LeadStageApi extends BaseAPI implements LeadStageApiInterface {
    /**
     * リードステージ削除API
     * @summary リードステージ削除API
     * @param {LeadStageApiDeleteLeadStageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadStageApi
     */
    public deleteLeadStage(requestParameters: LeadStageApiDeleteLeadStageRequest, options?: AxiosRequestConfig) {
        return LeadStageApiFp(this.configuration).deleteLeadStage(requestParameters.leadStageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リードステージ一覧取得API
     * @summary リードステージ一覧取得API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadStageApi
     */
    public getLeadStages(options?: AxiosRequestConfig) {
        return LeadStageApiFp(this.configuration).getLeadStages(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リード詳細リードステージ更新API
     * @summary リード詳細リードステージ更新API
     * @param {LeadStageApiPostCallTargetLeadStageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadStageApi
     */
    public postCallTargetLeadStage(requestParameters: LeadStageApiPostCallTargetLeadStageRequest, options?: AxiosRequestConfig) {
        return LeadStageApiFp(this.configuration).postCallTargetLeadStage(requestParameters.callTargetId, requestParameters.postCallTargetLeadStageBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リードステージ作成API
     * @summary リードステージ作成API
     * @param {LeadStageApiPostLeadStageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadStageApi
     */
    public postLeadStage(requestParameters: LeadStageApiPostLeadStageRequest = {}, options?: AxiosRequestConfig) {
        return LeadStageApiFp(this.configuration).postLeadStage(requestParameters.postLeadStageBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リードステージ更新API
     * @summary リードステージ更新API
     * @param {LeadStageApiPutLeadStageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadStageApi
     */
    public putLeadStage(requestParameters: LeadStageApiPutLeadStageRequest, options?: AxiosRequestConfig) {
        return LeadStageApiFp(this.configuration).putLeadStage(requestParameters.leadStageId, requestParameters.putLeadStageBody, options).then((request) => request(this.axios, this.basePath));
    }
}
