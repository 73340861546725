/* tslint:disable */
/* eslint-disable */
/**
 * openapi
 * bales cloud
 *
 * The version of the OpenAPI document: 1.0
 * Contact: test@example.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GetTriggerActionForms200Response } from '../models';
/**
 * TriggerActionFormApi - axios parameter creator
 * @export
 */
export const TriggerActionFormApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * トリガーアクションフォーム属性一覧取得API
         * @summary トリガーアクションフォーム属性一覧取得API
         * @param {string} actionType アクションタイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTriggerActionForms: async (actionType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'actionType' is not null or undefined
            assertParamExists('getTriggerActionForms', 'actionType', actionType)
            const localVarPath = `/api/user/trigger_action_forms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)

            if (actionType !== undefined) {
                localVarQueryParameter['actionType'] = actionType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TriggerActionFormApi - functional programming interface
 * @export
 */
export const TriggerActionFormApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TriggerActionFormApiAxiosParamCreator(configuration)
    return {
        /**
         * トリガーアクションフォーム属性一覧取得API
         * @summary トリガーアクションフォーム属性一覧取得API
         * @param {string} actionType アクションタイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTriggerActionForms(actionType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTriggerActionForms200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTriggerActionForms(actionType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TriggerActionFormApi - factory interface
 * @export
 */
export const TriggerActionFormApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TriggerActionFormApiFp(configuration)
    return {
        /**
         * トリガーアクションフォーム属性一覧取得API
         * @summary トリガーアクションフォーム属性一覧取得API
         * @param {string} actionType アクションタイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTriggerActionForms(actionType: string, options?: any): AxiosPromise<GetTriggerActionForms200Response> {
            return localVarFp.getTriggerActionForms(actionType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TriggerActionFormApi - interface
 * @export
 * @interface TriggerActionFormApi
 */
export interface TriggerActionFormApiInterface {
    /**
     * トリガーアクションフォーム属性一覧取得API
     * @summary トリガーアクションフォーム属性一覧取得API
     * @param {string} actionType アクションタイプ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TriggerActionFormApiInterface
     */
    getTriggerActionForms(actionType: string, options?: AxiosRequestConfig): AxiosPromise<GetTriggerActionForms200Response>;

}

/**
 * Request parameters for getTriggerActionForms operation in TriggerActionFormApi.
 * @export
 * @interface TriggerActionFormApiGetTriggerActionFormsRequest
 */
export interface TriggerActionFormApiGetTriggerActionFormsRequest {
    /**
     * アクションタイプ
     * @type {string}
     * @memberof TriggerActionFormApiGetTriggerActionForms
     */
    readonly actionType: string
}

/**
 * TriggerActionFormApi - object-oriented interface
 * @export
 * @class TriggerActionFormApi
 * @extends {BaseAPI}
 */
export class TriggerActionFormApi extends BaseAPI implements TriggerActionFormApiInterface {
    /**
     * トリガーアクションフォーム属性一覧取得API
     * @summary トリガーアクションフォーム属性一覧取得API
     * @param {TriggerActionFormApiGetTriggerActionFormsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TriggerActionFormApi
     */
    public getTriggerActionForms(requestParameters: TriggerActionFormApiGetTriggerActionFormsRequest, options?: AxiosRequestConfig) {
        return TriggerActionFormApiFp(this.configuration).getTriggerActionForms(requestParameters.actionType, options).then((request) => request(this.axios, this.basePath));
    }
}
