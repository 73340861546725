import { CONTENT_DEFAULT } from '@/components/organisms/user/mail/common/drawer/state/form/form-values';
import { TFormDirectMailCommon } from './types';

const getCommonDefault = (): TFormDirectMailCommon => ({
  subject: '',
  content: CONTENT_DEFAULT,
  open: true,
  click: true,
  sendCc: [],
  sendBcc: [],
  mailAttachmentIds: [],
});

export {
  getCommonDefault,
};

