export default {
  currentUser(state) {
    return state.currentUser;
  },
  admin(state) {
    return state.admin;
  },
  admins(state) {
    return state.admins;
  },
  user(state) {
    return state.user;
  },
  users(state) {
    return state.users;
  },
  client(state) {
    return state.client;
  },
  clients(state) {
    return state.clients;
  },
  clientLimitSetting(state) {
    return state.clientLimitSetting;
  },
  clientAvailableFeatures(state) {
    return state.clientAvailableFeatures;
  },
  clientSendgridAuth(state) {
    return state.clientSendgridAuth;
  },
  mailVerificationInfo(state) {
    return state.mailVerificationInfo;
  },
  mailVerificationBalance(state) {
    return state.mailVerificationBalance;
  },
  sendgridSubUsers(state) {
    return state.sendgridSubUsers;
  },
  sendgridApiKeys(state) {
    return state.sendgridApiKeys;
  },
  clientSendgridApiKey(state) {
    return state.clientSendgridApiKey;
  },
};
