import { AxiosResponse } from 'axios';
import ApiService, { ApiArguments } from '@/api/api_service';
import ApiBase from '@/api/base';
import {
  UserProfileApi,
  UserProfileApiPutCurrentUserRequest,
  UserProfileApiPostCurrentUserAvatarImageRequest,
} from '@/api/openapi';

export class CurrentUserApiService extends ApiService {
  api = new UserProfileApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true);
  }

  async updateCurrentUser({
    request,
    options,
    errorHandlers,
  }: ApiArguments<UserProfileApiPutCurrentUserRequest>): Promise<AxiosResponse<void>> {
    return this.checkResponse<void>(
      this.api.putCurrentUser(request, options),
      errorHandlers,
    );
  }

  async updateCurrentUserAvatarImage({
    request,
    options,
    errorHandlers,
  }: ApiArguments<UserProfileApiPostCurrentUserAvatarImageRequest>): Promise<AxiosResponse<void>> {
    return this.checkResponse<void>(
      this.api.postCurrentUserAvatarImage(request, options),
      errorHandlers,
    );
  }
}

export default {
  getCurrentUser (args) {
    return ApiBase.get(`/user/me`, args);
  },
};
