<template>
  <div>
    <BModal
      :modal-name="modalName"
      width="70%"
      height="70%"
    >
      <BHeightAdjuster>
        <BModalHeader
          :title="$t('trigger.eventSetting.filterParams')"
          @modal-close="handleClose"
        />
        <BModalBody
          full
        >
          <template #waiting>
            <BPlaceholder
              v-loading="true"
              height="100%"
              element-loading-background="transparent"
            />
          </template>
          <div class="lead-filter-modal-body">
            <FilterSelectCard
              class="flex-2"
            />
            <template
              v-if="Object.keys(filterParams).length > 0"
            >
              <FilterControlPanelBody
                :lead-list-filter-params="filterParams"
                :use-store-lead-list="false"
                class="flex-3"
              />
            </template>
            <template
              v-else
            >
              <div class="empty flex-3">
                {{ $t('trigger.eventSetting.emptyFilterParams') }}
              </div>
            </template>
          </div>
        </BModalBody>
      </BHeightAdjuster>
    </BModal>
  </div>
</template>

<script lang="ts" setup>
import FilterControlPanelBody from '@/components/organisms/user/leadList/filter/FilterControlPanelBody.vue';
import FilterSelectCard from '@/components/organisms/user/leadList/filter/FilterSelectCard.vue';

type TProps = {
  filterParams: object;
  modalName: string;
};
type TEmits = {
  'close': [];
};

defineProps<TProps>();
const emit = defineEmits<TEmits>();

const handleClose = () => {
  emit('close');
};
</script>

<style lang="scss" scoped>
  .lead-filter-modal-body {
    display: flex;

    .flex-2 {
      flex: 2;
    }
    .flex-3 {
      flex: 3;
    }
    .empty {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $textcolor-light;
      background-color: $bgcolor-base;
      padding: $basespace-300 $basespace-200;
      margin: 20px 28px;
      border-radius: 10px;
    }
    :deep(.filter-select-card) {
      height: calc(70vh - 90px);
      padding: 20px 40px;
      overflow: auto;
    }
    :deep(.panel-body) {
      height: calc(70vh - 90px);
      padding: 20px 40px;
      overflow: auto;
    }
  }

  .b-modal-header {
    border-bottom: 1px solid $bdcolor-light;
  }
  .b-modal-footer {
    @include m-fixed-height(80px);
    border-top: 1px solid $bdcolor-light;
  }
</style>
