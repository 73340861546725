<template>
  <BPopover
    v-model="visible"
    placement="right"
    :width="width"
    trigger="click"
    full
    :disabled="isDisplayOnly"
    @hide="emitHide"
    @show="emitShow"
  >
    <template #reference>
      <span>
        <div
          class="filter-card"
          :class="classes"
          @mouseover="showRemoveButton"
          @mouseleave="hideRemoveButton"
        >
          <BBtn
            v-if="isShowingRemoveButton && !isDisplayOnly"
            class="remove-button"
            size="mini"
            fab
            outline
            @click.stop="removeFilterItemAndFetchLeadList(keyName)"
          >
            <BIcon size="mini">
              close
            </BIcon>
          </BBtn>
          <slot name="reference" />
        </div>
      </span>
    </template>
    <div class="detail-popover">
      <div class="detail-popover-header">
        <div class="header-title fw-bold">
          {{ title }}
        </div>
        <div
          v-if="isSwitch"
          class="header-switch"
        >
          <FilterSwitchBtn
            v-model="internalIsInclude"
            :true-text="$t('general.filter.inc')"
            :false-text="$t('general.filter.exc')"
          />
        </div>
      </div>
      <div class="detail-popover-body">
        <slot />
      </div>
      <div class="detail-popover-footer">
        <BLayout
          align-center
          justify-center
        >
          <BBtn
            class="close-button"
            size="mini"
            text
            @click="closeFilterDetail"
          >
            {{ $t('general.close.text') }}
          </BBtn>
          <BBtn
            class="apply-button"
            size="mini"
            type="primary"
            :disabled="!valid"
            @click="hide"
          >
            {{ $t('general.apply') }}
          </BBtn>
        </BLayout>
      </div>
    </div>
  </BPopover>
</template>

<script>
import { mapWaitingActions } from 'vue-wait';
import { mapGetters } from 'vuex';
import FilterSwitchBtn from '@/components/organisms/user/FilterSwitchBtn.vue';
import { useFilterParams } from '@/composable/user/leadList/lead-filter';
import filterService from '@/mixins/filter_service/index';

export default {
  name: 'FilterDetailPopover',
  components: {
    FilterSwitchBtn,
  },
  mixins: [filterService],
  props: {
    title: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
      default: 260,
    },
    isSwitch: Boolean,
    isInclude: Boolean,
    isDisplayOnly: Boolean,
    keyName: {
      type: String,
      default: '',
    },
    isCustom: {
      type: Boolean,
      default: false,
    },
    valid: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    'update:isInclude',
    'hide',
    'show',
  ],
  setup() {
    const { removeFilterItemAndFetchLeadList } = useFilterParams();
    return {
      removeFilterItemAndFetchLeadList,
    };
  },
  data () {
    return {
      disableEmitHide: false,
      isShowingRemoveButton: false,
    };
  },
  computed: {
    ...mapGetters('userUi', [
      'selectedFilterDetailKeyName',
      'leadListFilterParams',
    ]),
    visible: {
      get () {
        return !this.isDisplayOnly && this.selectedFilterDetailKeyName === this.keyName;
      },
      set (newVal) {
        if (this.isDisplayOnly) return;

        if (newVal) {
          this.setSelectedFilterDetailKeyNameAction(this.keyName);
        } else if (this.selectedFilterDetailKeyName === this.keyName) {
          this.setSelectedFilterDetailKeyNameAction(null);
        } else {
          // 条件に合わない場合他のpopoverが表示されてるだけなのでは何もしない 
        }
      },
    },
    internalIsInclude: {
      get () {
        return this.isInclude;
      },
      set (newVal) {
        this.$emit('update:isInclude', newVal);
      },
    },
    classes () {
      return {
        'is-popover-show': this.visible,
      };
    },
  },
  methods: {
    ...mapWaitingActions('user', {
      getCallTargetsAction: 'getCallTargetsWait',
    }),
    ...mapWaitingActions('userUi', {
      setSelectedFilterDetailKeyNameAction: 'setSelectedFilterDetailKeyNameWait',
      setLeadListFilterParamsAction: 'setLeadListFilterParamsWait',
    }),
    closeFilterDetail () {
      this.disableEmitHide = true;
      this.visible = false;
    },
    hide () {
      this.visible = false;
    },
    emitHide () {
      if (this.disableEmitHide) return false;
      this.$emit('hide');
    },
    emitShow () {
      this.disableEmitHide = false;
      this.$emit('show');
    },
    showRemoveButton () {
      this.isShowingRemoveButton = true;
    },
    hideRemoveButton () {
      this.isShowingRemoveButton = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  .filter-card {
    position: relative;
    background: $bgcolor-base;
    border-radius: 4px;
    padding: $basespace-100 $basespace-200;
    margin-top: $basespace-300;
    margin-bottom: $basespace-200;
    margin-left: $basespace-100;
    cursor: pointer;
    border: 1px solid transparent;
    transition: $transition-base;
    outline: none;
    word-break: break-word;

    &:hover {
      border: 1px solid $bdcolor-base;
    }

    &.is-popover-show {
      border: 1px solid $bdcolor-dark;
    }
  }

  .detail-popover-header {
    border-bottom: 1px solid $bdcolor-base;
    padding: $basespace-200 $basespace-500;
  }

  .header-title {
    width: 100%;
  }

  .header-switch {
    margin-top: $basespace-200;
  }

  .detail-popover-body {
    max-height: 80vh;
    overflow-y: auto;
    padding: $basespace-300 $basespace-500;
  }

  .detail-popover-footer {
    padding-top: 6px;
    padding-bottom: 10px;
    background-color: $bgcolor-base;

    .close-button {
      @include m-fixed-width(96px);
      margin-right: 10px;
    }

    .apply-button {
      @include m-fixed-width(96px);
    }
  }

  .remove-button {
    position: absolute;
    top: -13px;
    left: -13px;
    background-color: $bgcolor-white;
  }
</style>
