export const types = {
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  SET_ADMIN: 'SET_ADMIN',
  SET_ADMINS: 'SET_ADMINS',
  SET_USER: 'SET_USER',
  SET_USERS: 'SET_USERS',
  SET_CLIENT: 'SET_CLIENT',
  SET_CLIENTS: 'SET_CLIENTS',
  SET_CLIENT_LIMIT_SETTING: 'SET_CLIENT_LIMIT_SETTING',
  SET_CLIENT_AVAILABLE_FEATURE: 'SET_CLIENT_AVAILABLE_FEATURE',
  SET_CLIENT_SENDGRID_AUTH: 'SET_CLIENT_SENDGRID_AUTH',
  SET_MAIL_VERIFICATIONS: 'SET_MAIL_VERIFICATIONS',
  SET_MAIL_VERIFICATION_BALANCE: 'SET_MAIL_VERIFICATION_BALANCE',
  SET_SENDGRID_SUB_USERS: 'SET_SENDGRID_SUB_USERS',
  SET_SENDGRID_API_KEYS: 'SET_SENDGRID_API_KEYS',
  SET_CLIENT_SENDGRID_API_KEY: 'SET_CLIENT_SENDGRID_API_KEY',
  CLEAR_CURRENT_USER: 'CLEAR_CURRENT_USER',
  CLEAR_ADMIN: 'CLEAR_ADMIN',
  CLEAR_ADMINS: 'CLEAR_ADMINS',
  CLEAR_USER: 'CLEAR_USER',
  CLEAR_USERS: 'CLEAR_USERS',
  CLEAR_CLIENT: 'CLEAR_CLIENT',
  CLEAR_CLIENTS: 'CLEAR_CLIENTS',
  CLEAR_CLIENT_LIMIT_SETTING: 'CLEAR_CLIENT_LIMIT_SETTING',
  CLEAR_SENDGRID_SUB_USERS: 'CLEAR_SENDGRID_SUB_USERS',
  CLEAR_CLIENT_SENDGRID_API_KEY: 'CLEAR_CLIENT_SENDGRID_API_KEY',
  CLEAR_SENDGRID_API_KEYS: 'CLEAR_SENDGRID_API_KEYS',
};

const set = {
  [types.SET_CURRENT_USER](state, data) {
    state.currentUser = data;
  },
  [types.SET_ADMIN](state, data) {
    state.admin = data;
  },
  [types.SET_ADMINS](state, data) {
    state.admins = data;
  },
  [types.SET_USER](state, data) {
    state.user = data;
  },
  [types.SET_USERS](state, data) {
    state.users = data;
  },
  [types.SET_CLIENT](state, data) {
    state.client = data;
  },
  [types.SET_CLIENTS](state, data) {
    state.clients = data;
  },
  [types.SET_CLIENT_LIMIT_SETTING](state, data) {
    state.clientLimitSetting = data;
  },
  [types.SET_CLIENT_AVAILABLE_FEATURE](state, data) {
    state.clientAvailableFeatures = data;
  },
  [types.SET_CLIENT_SENDGRID_AUTH](state, data) {
    state.clientSendgridAuth = data;
  },
  [types.SET_MAIL_VERIFICATIONS](state, data) {
    state.mailVerificationInfo = data;
  },
  [types.SET_MAIL_VERIFICATION_BALANCE](state, data) {
    state.mailVerificationBalance = data;
  },
  [types.SET_SENDGRID_SUB_USERS](state, data) {
    state.sendgridSubUsers = data;
  },
  [types.SET_SENDGRID_API_KEYS](state, data) {
    state.sendgridApiKeys = data;
  },
  [types.SET_CLIENT_SENDGRID_API_KEY](state, data) {
    state.clientSendgridApiKey = data;
  },
};

const clear = {
  [types.CLEAR_CURRENT_USER](state) {
    state.currentUser = {};
  },
  [types.CLEAR_ADMIN](state) {
    state.admin = {};
  },
  [types.CLEAR_ADMINS](state) {
    state.admins = [];
  },
  [types.CLEAR_USER](state) {
    state.user = {};
  },
  [types.CLEAR_USERS](state) {
    state.users = [];
  },
  [types.CLEAR_CLIENT](state) {
    state.client = {};
  },
  [types.CLEAR_CLIENTS](state) {
    state.clients = [];
  },
  [types.CLEAR_CLIENT_LIMIT_SETTING](state) {
    state.clientLimitSetting = {};
  },
  [types.CLEAR_SENDGRID_SUB_USERS](state) {
    state.sendgridSubUsers = [];
  },
  [types.CLEAR_SENDGRID_API_KEYS](state) {
    state.sendgridApiKeys = [];
  },
  [types.CLEAR_CLIENT_SENDGRID_API_KEY](state) {
    state.clientSendgridApiKey = {};
  },
};

export const mutations = Object.assign({}, set, clear);
