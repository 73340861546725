<template>
  <BContent
    v-loading="loading"
  >
    <template #navbar>
      <BLayout
        align-start
        justify-start
      >
        <span class="fs-400 fw-bold">{{ $t('sequence.title') }}</span>
      </BLayout>
    </template>
    <template #footer>
      <SequenceFooter
        :can-save="canSave"
        :loading="loading"
        need-confirmation-to-leave
        @click:save="handleClickSave"
      />
    </template>
    <Sequence
      v-if="!waiting"
      :duplicated-sequence-master-id="duplicateId"
      @update="handleUpdateSequence"
    />
    <BTabs
      class="tabs"
      :label-items="tabLabelItems"
    >
      <template #step>
        <SequenceSteps
          v-if="!waiting"
          :duplicated-sequence-master-id="duplicateId"
          :sequence-owner-id="sequenceOwnerId"
          @update="handleUpdateSequenceSteps"
        />
      </template>
    </BTabs>
  </BContent>
</template>

<script lang="ts" setup>
import { AxiosResponse } from 'axios';
import { computed, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { PostSequenceMaster201Response } from '@/api/openapi';
import { SequenceMasterApiService } from '@/api/user/resources/sequence_master';
import SequenceFooter from '@/components/organisms/user/general/sequence/SequenceFooter.vue';
import Sequence from '@/components/organisms/user/sequenceNew/Sequence.vue';
import SequenceSteps from '@/components/organisms/user/sequenceNew/SequenceSteps.vue';
import { useQueryParameter } from '@/composable/query-parameter';
import { usePostPutBody } from '@/composable/user/sequence/post-put-body';
import { useCurrentUser } from '@/composable/user/user/users';
import { useWait } from '@/composable/vue-wait';
import { KEY_OF_TOAST } from '@/injection-keys';

const toast = inject(KEY_OF_TOAST);
const router = useRouter();
const i18n = useI18n();
const api = new SequenceMasterApiService();
const { currentUser, fetchCurrentUser } = useCurrentUser();
const sequenceOwnerId = ref<number>();

onMounted(async () => {
  await fetchCurrentUser();
  sequenceOwnerId.value = currentUser.value.id;
});

const waiting = computed(() => sequenceOwnerId.value == null);

const {
  wait,
  doActionWithWait,
} = useWait();
const {
  getAndValidateParamAsInteger: getAndValidateDuplicateId,
} = useQueryParameter('duplicateId');

const {
  sequenceBodyData,
  sequenceStepsBodyData,
  canSave,
  handleUpdateSequence,
  handleUpdateSequenceSteps,
} = usePostPutBody();

const tabLabelItems = [
  { label: i18n.t('sequence.step.title'), slotName: 'step' },
];

const loading = computed(() =>
  wait.is(['fetchDuplicateSequenceMaster', 'fetchDuplicateSequenceStepMasters', 'putSequenceMaster']),
);
const duplicateId = getAndValidateDuplicateId(i18n.t('sequence.message.duplicateIdError'));

const handleClickSave = async () => {
  if (!canSave.value) return;

  const { data: { id } } = await doActionWithWait<AxiosResponse<PostSequenceMaster201Response>>('postSequenceMaster', async () => {
    return await api.postSequenceMaster({
      request: {
        postSequenceMasterBody: {
          sequence: sequenceBodyData.value,
          sequenceSteps: sequenceStepsBodyData.value,
        },
      },
    });
  });

  toast.show(
    i18n.t('general.done.withTarget', { 
      action: i18n.t('general.save.text'),
      target: i18n.t('sequence.title'),
    }),
  );
  router.push({ name: 'Sequence', params: { id } });
};
</script>

<style lang="scss" scoped>
.tabs {
  // NOTE: シーケンスマスタ、フッター分引く
  height: calc(100% - 50px - 48px);
}
:deep(.el-tabs__header) {
  margin-bottom: 0;

  .el-tabs__nav-scroll {
    padding: 0 $basespace-600;
  }
}
:deep(.el-tabs__content) {
  // NOTE: header分引く
  height: calc(100% - 40px);

  .el-tab-pane {
    height: 100%;
  }
}
</style>
