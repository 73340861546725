<template>
  <i
    v-bind="fallThroughAttrs"
    :class="[iconClass, classes]"
  >
    <slot />
  </i>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    iconClass: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'default',
    },
    size: String,
    bold: Boolean,
    hover: Boolean,
    disabled: Boolean,
  },
  computed: {
    classes () {
      return {
        'b-icon': true,
        'b-icon-default': this.type === 'default',
        'b-icon-white': this.type === 'white',
        'b-icon-gray': this.type === 'gray' || this.disabled,
        'b-icon-primary': this.type === 'primary',
        'b-icon-accent': this.type === 'accent',
        'b-icon-success': this.type === 'success',
        'b-icon-error': this.type === 'error',
        'b-icon-warning': this.type === 'warning',
        'b-icon-ocean': this.type === 'ocean',
        'b-icon-mini': this.size === 'mini',
        'b-icon-small': this.size === 'small',
        'b-icon-medium': this.size === 'medium',
        'b-icon-large': this.size === 'large',
        'b-icon-extra-large': this.size === 'extra-large',
        'b-icon-xx-large': this.size === 'xx-large',
        'b-icon-hover': this.hover,
        'is-bold': this.bold,
        'biscuet-icons': true,
        'is-disabled': this.disabled,
      };
    },
    fallThroughAttrs() {
      // NOTE: vue3 から $attrs にリスナーが含まれるようになったので、リスナーを含めるか判定する
      const fallThrough = Object.entries(this.$attrs).filter(([key, value]) =>
        !this.disabled || typeof value !== 'function' || !key.startsWith('on'),
      );
      return Object.fromEntries(fallThrough);
    },
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: 'icomoon';
  src:  url('@/assets/fonts/icomoon.eot?a62jk');
  src:  url('@/assets/fonts/icomoon.eot?a62jk#iefix') format('embedded-opentype'),
        url('@/assets/fonts/icomoon.ttf?a62jk') format('truetype'),
        url('@/assets/fonts/icomoon.woff?a62jk') format('woff'),
        url('@/assets/fonts/icomoon.svg?a62jk#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="biscuet-icons"], [class*="biscuet-icons"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.b-graph-bar-modern:before {
  content: "\e922";
}
.b-call-analytics:before {
  content: "\e921";
}
.b-fire:before {
  content: "\e920";
}
.b-template:before {
  content: "\e923";
}
.b-clone:before {
  content: "\e925";
}
.b-sf-failure:before {
  content: "\e91f";
}
.b-sf-pending:before {
  content: "\e92a";
}
.b-sf-success:before {
  content: "\e92b";
}
.b-call-add:before {
  content: "\e900";
}
.b-next-action:before {
  content: "\e901";
}
.b-memo:before {
  content: "\e902";
}
.b-logout:before {
  content: "\e903";
}
.b-filter:before {
  content: "\e904";
}
.b-column:before {
  content: "\e905";
}
.b-search:before {
  content: "\e906";
}
.b-bell:before {
  content: "\e907";
}
.b-arrow-up:before {
  content: "\e908";
}
.b-arrow-right:before {
  content: "\e909";
}
.b-arrow-down:before {
  content: "\e90a";
}
.b-arrow-left:before {
  content: "\e90b";
}
.b-arrow-top-full:before {
  content: "\e90c";
}
.b-arrow-right-full:before {
  content: "\e90d";
}
.b-arrow-down-full:before {
  content: "\e90e";
}
.b-arrow-left-full:before {
  content: "\e90f";
}
.b-add:before {
  content: "\e910";
}
.b-close:before {
  content: "\e911";
}
.b-more:before {
  content: "\e912";
}
.b-mail:before {
  content: "\e913";
}
.b-mail-send:before {
  content: "\e914";
}
.b-mail-block:before {
  content: "\e915";
}
.b-home:before {
  content: "\e916";
}
.b-graph-bar:before {
  content: "\e917";
}
.b-import:before {
  content: "\e918";
}
.b-update:before {
  content: "\e919";
}
.b-export:before {
  content: "\e91a";
}
.b-list:before {
  content: "\e91b";
}
.b-list-update:before {
  content: "\e91c";
}
.b-list-new:before {
  content: "\e91d";
}
.b-form:before {
  content: "\e91e";
}
.b-horizontal-line:before {
  content: "\e924";
}
.b-setting:before {
  content: "\e926";
}
.b-question:before {
  content: "\e927";
}
.b-call-call:before {
  content: "\e928";
}
.b-sequence:before {
  content: "\e929";
}
.b-stage-cycle:before {
  content: "\e92c";
}
.b-mail-draft:before {
  content: "\e92d";
}
.b-mail-return:before {
  content: "\e92e";
}
.b-mail-remind:before {
  content: "\e92f";
}

.b-icon {
    display: inline-flex;
    align-items: center;
    font-feature-settings: 'liga';
    font-size: 20px;
    justify-content: center;
    line-height: 1;
    transition: $transition-base;
    color: rgba($textcolor-base, .6);
    vertical-align: text-bottom;

    &.is-bold {
      font-weight: 600;
    }
  }

  // --- Type
  .b-icon-default {
    color: $textcolor-base;
  }

  .b-icon-white {
    color: $textcolor-white;
  }

  .b-icon-gray {
    color: rgba($textcolor-light, .6);
  }

  .b-icon-primary {
    color: $basecolor-primary;
  }

  .b-icon-accent {
    color: $basecolor-accent;
  }

  .b-icon-success {
    color: $basecolor-success;
  }

  .b-icon-error {
    color: $basecolor-error;
  }

  .b-icon-warning {
    color: $basecolor-warning;
  }

  .b-icon-ocean {
    color: $basecolor-secondary;
  }

  // --- Size
  .b-icon-mini {
    font-size: 12px;
  }

  .b-icon-small {
    font-size: 16px;
  }

  .b-icon-medium {
    font-size: 18px;
  }

  .b-icon-large {
    font-size: 20px;
  }

  .b-icon-extra-large {
    font-size: 24px;
  }

  .b-icon-xx-large {
    font-size: 36px;
  }

  .b-icon-hover {
    background: transparent;
    border: none;
    border-radius: 4px;
    padding: $basespace-50;
    cursor: pointer;
    &:hover {
      background-color: $bgcolor-dark;
    }
  }

  .is-disabled {
    cursor: not-allowed;
  }
</style>
