import { useErrorHandler } from '@/composable/error-handler';

const handler = useErrorHandler();
/**
 * @duplicated use src/composable/error-handler.ts
 */
const mixin = {
  methods: {
    // MEMO: サーバー側のエラーをそのままswalする
    defaultHandler(e) {
      handler.defaultHandler(e);
    },
    fileLengthErrorHandler() {
      handler.fileLengthErrorHandler();
    },
    fileUploadErrorHandler() {
      handler.fileUploadErrorHandler();
    },
  },
};

export default mixin;
