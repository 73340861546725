type TFilterType = 'typeArray' | 'typeBoolean' | 'typeString' | 'typeNumber';
const getInitialValue = (filterType: TFilterType): (string | number)[] | null | string => {
  switch (filterType) {
    case 'typeArray':
      return [];
    case 'typeBoolean':
      return null;
    case 'typeString':
      return '';
    case 'typeNumber':
    // TODO: そのうち文字列型で扱うのをやめたい
      return ['null', 'null', 'false'];
    default:
      console.error('invalid filterType. filterType = ', filterType);
      return '';
  }
};

// どの key がどのタイプのフィルタを持つか定義する
const filterKeyTypes: Record<string, TFilterType> = {
  'companyItemsCompanyName': 'typeString',
  'companyItemsCompanyTel': 'typeString',
  'companyItemsCompanyAddress': 'typeString',
  'companyItemsBusinessCategory': 'typeArray',
  'companyItemsNumberOfEmployees': 'typeArray',
  'targetItemsDepartment': 'typeArray',
  'targetItemsPosition': 'typeArray',
  'targetItemsEmail': 'typeBoolean',
  'targetItemsEmailText': 'typeString',
  'targetItemsName': 'typeString',
  'targetItemsTel': 'typeString',
  'callResults': 'typeArray',
  'lastCallCalledAt': 'typeArray',
  'numberOfCall': 'typeArray',
  'opportunitiesPresent': 'typeBoolean',
  'opportunitiesCreatedAt': 'typeArray',
  'opportunitiesStartedAt': 'typeArray',
  'unsubscriptionPresent': 'typeBoolean',
  'bulkMails': 'typeArray',
  'bulkMailEvents': 'typeString',
  'lastLeadStage': 'typeArray',
  'lastLeadStageStayDays': 'typeArray',
  'assignedOperator': 'typeArray',
  'leadSources': 'typeArray',
  'leadSourcesRegisteredAt': 'typeArray',
  'freeTags': 'typeArray',
  'nextActionsActionType': 'typeArray',
  'nextActionsPriority': 'typeArray',
  'nextActionsReservedAt': 'typeArray',
  'nextActionsDoneAt': 'typeArray',
  'nextActionsPresent': 'typeBoolean',
  'nextActionsCompletedPresent': 'typeBoolean',
  'nextActionsOwner': 'typeArray',
  'nextActionsOwnerPresent': 'typeBoolean',
  'nextActionsDoneBy': 'typeArray',
  'callTargetsCreatedAt': 'typeArray',
  'sfRelatedLeadStatus': 'typeArray',
  'duplicateEmail': 'typeString',
  'sfCampaign': 'typeArray',
};

// 複数の key を内包する key を定義する
const multipleFilterKey = {
  'bulkMails': ['bulkMails', 'bulkMailEvents'],
  'leadSources': ['leadSources', 'leadSourcesRegisteredAt'],
};

export type {
  TFilterType,
};
export {
  getInitialValue,
  filterKeyTypes,
  multipleFilterKey,
};
