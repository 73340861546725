export const columns = [
  {
    key: 'companyName',
    width: '240px',
  },
  {
    key: 'fullName',
    width: '160px',
  },
  {
    key: 'createdAt',
    width: '160px',
  },
  {
    key: 'event',
    width: '240px',
  },
  {
    key: 'statusChangedAt',
    width: '160px',
  },
  {
    key: 'status',
    width: '160px',
  },
  {
    key: 'owner',
    width: '120px',
  },
  {
    key: 'approveUser',
    width: '120px',
  },
];
