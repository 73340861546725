<template>
  <FilterDetailPopover
    v-model:is-include="isInclude"
    :title="title"
    :is-switch="isSwitch"
    :is-display-only="isDisplayOnly"
    :key-name="keyName"
    @hide="popoverHide"
    @show="popoverShow"
  >
    <template #reference>
      <BLayout align-center>
        <BListItem>
          <template #header>
            <BLayout justify-space-between>
              <div>{{ title }}</div>
              <div
                class="grouping-text"
                :class="{ 'is-include': incExc === 'inc' }"
              >
                {{ groupingText }}
              </div>
            </BLayout>
          </template>
          <div>{{ value }}</div>
        </BListItem>
      </BLayout>
    </template>
    <div>
      <FilterItemCheckbox
        v-model:checked-item="checkedItem"
        :candidate-items="candidateItems"
        :is-search-text="isSearchText"
        no-blank-item
      />
    </div>
  </FilterDetailPopover>
</template>

<script>
import lodash from 'lodash';
import { mapWaitingActions } from 'vue-wait';
import { mapGetters } from 'vuex';
import FilterDetailPopover from '@/components/organisms/user/leadList/filter/FilterDetailPopover.vue';
import FilterItemCheckbox from '@/components/organisms/user/leadList/filter/FilterItemCheckbox.vue';
import { getIncExc } from '@/composable/user/leadList/lead-filter';

export default {
  components: {
    FilterDetailPopover,
    FilterItemCheckbox,
  },
  props: {
    leadListFilterParams: {
      type: Object,
      default () {
        return {};
      },
    },
    isDisplayOnly: {
      type: Boolean,
    },
    keyName: {
      type: String,
      require: true,
    },
  },
  emits: [
    'hide',
  ],
  data () {
    return {
      checkedItem: [],
      isInclude: true,
    };
  },
  computed: {
    ...mapGetters('user', [
      'leadStages',
    ]),
    isSwitch() {
      return false;
    },
    isSearchText() {
      return false;
    },
    candidateItems () {
      return this.leadStages.map(candidate => {
        return {
          label: candidate.name,
          value: candidate.id,
          labelPrefixComponent: 'BLeadStageColorPreview',
          labelPrefixAttributes: { size: 'small', value: candidate.colorName, class: 'mr-50' },
        };
      });
    },
    title () {
      return this.$t(`filterSelect.leadAdditionalInfo.item.${this.keyName}`);
    },
    value () {
      if (this.target == null || this.targetDetail.length === 0) return '-';
      const internalLeadStages = this.targetDetail.map((v) => this.getLeadStage(v));
      internalLeadStages.sort((a, b) => a?.sortOrder - b?.sortOrder);
      const values = internalLeadStages.map((leadStage) => {
        return leadStage == null ? this.$t('general.delete.done') : leadStage.name;
      });
      return values.join(' / ');
    },
    groupingText () {
      if (this.target == null || this.targetDetail.length === 0) return;
      return this.$t(`general.filter.${this.incExc}`);
    },
    target () {
      return this.leadListFilterParams[this.keyName];
    },
    targetDetail () {
      if (this.target == null) return [];
      return this.target[this.incExc].or.map(v => parseInt(v));
    },
    incExc () {
      return getIncExc(this.target);
    },
  },
  created () {
    this.getLeadStagesAction();
  },
  methods: {
    ...mapWaitingActions('user', {
      getLeadStagesAction: 'getLeadStagesWait',
    }),
    popoverShow () {
      this.getLeadStagesAction();
      this.checkedItem = lodash.cloneDeep(this.targetDetail);
      this.isInclude = this.incExc === 'inc';
    },
    popoverHide () {
      const grouping = this.isInclude ? 'inc' : 'exc';
      this.$emit('hide', { [grouping]: { or: this.checkedItem } });
    },
    getLeadStage (id) {
      return this.leadStages.find(p => p.id === id);
    },
  },
};
</script>

<style lang="scss" scoped>
  .grouping-text {
    color: $basecolor-cold;

    &.is-include {
      color: $basecolor-hot;
    }
  }
</style>
