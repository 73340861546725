<template>
  <div
    class="b-container"
    :class="classes"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: 'medium',
    },
    full: Boolean,
  },
  computed: {
    classes () {
      return {
        'b-container-medium': this.size === 'medium',
        'b-container-large': this.size === 'large',
        'is-full': this.full,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
  .b-container {
    margin: auto;
    padding-right: $basespace-600;
    padding-left: $basespace-600;

    &.is-full {
      padding: 0;
    }

    @media only screen and (max-width: 959px) {

      &.is-full {
        padding: 0;
      }
    }
  }

  .b-container-medium {
    // 960px + $basespace-600(32px)
    @include m-fixed-width(992px)
  }

  .b-container-large {
    width: 100%;
    padding-right: $basespace-600;
    padding-left: $basespace-600;

    &.is-full {
      padding: 0;
    }

  }
</style>
