import { AxiosResponse } from 'axios';
import { computed, ref, watch, Ref } from 'vue';
import { useStore } from 'vuex';
import { GetNextPageFirstId200Response } from '@/api/openapi';
import { PaginationMeta } from '@/api/openapi/models/pagination-meta';
import { CallTargetApiService } from '@/api/user/resources/call_target';
import { useWait } from '@/composable/vue-wait';
import { key } from '@/store/index';
import { TObject } from '@/types';

type TCallTarget = TObject & {
  callTargetId: number;
};

const usePaging = (
  getCurrentCallTargetId: () => number | null,
  tempCurrentCallTargets: Ref<TCallTarget[]>, // TODO: TCallTarget をリードの型に置き換える
  tempPaginationMeta: Ref<PaginationMeta | null>,
) => {
  const store = useStore(key);

  const isPaging = ref(false);

  const currentPage = ref<number>(1);
  const currentIndex = ref<number>(-1);

  const isCurrentListFirst = ref(false);
  const isCurrentListLast = ref(false);
  const isFirstPage = ref(false);
  const isLastPage = ref(false);

  const leadListFilterParams = computed(() => {
    const params = store.getters['userUi/leadListFilterParams'];
    return Object.keys(params).length > 0 ? JSON.stringify(params) : undefined;
  });
  const requestParameters = computed(() => ({
    lf: leadListFilterParams.value,
    ...store.getters['userUi/leadListPageParams'],
    sortKey: store.getters['userUi/leadListSortKey'],
    sortOrder: store.getters['userUi/leadListSortOrder'],
  }));

  /**
   * 各種パラメタ設定（処理タイミングの見通しを良くするため一律で各種パラメタを更新する）
   * @returns 値の設定をしたかどうか
   */
  const setParams = (currentCallTargetId: number, paginationMeta: PaginationMeta | null, currentCallTargets: TObject[]): boolean => {
    if (!store.getters['userUi/isLeadOpenByLeadList']) return false;
    if (paginationMeta == null || currentCallTargets.length < 1) return false;

    const currentIndexVal = currentCallTargets.findIndex(ct => ct.callTargetId === currentCallTargetId);
    if (currentIndexVal === -1) return false;

    // NOTE: currentPageはフロントから取らず、APIから取得した値のmetaから取得する（空になることがあるため）
    const currentPageVal = paginationMeta.currentPage || 1;

    isFirstPage.value = currentPageVal === 1;
    isLastPage.value = currentPageVal === paginationMeta.totalPages;

    isCurrentListFirst.value = currentIndexVal === 0;
    isCurrentListLast.value = currentIndexVal === currentCallTargets.length - 1;

    currentPage.value = currentPageVal;
    currentIndex.value = currentIndexVal;

    return true;
  };

  watch(() => ({
    currentCallTargetId: getCurrentCallTargetId(),
    currentCallTargets: tempCurrentCallTargets.value,
    paginationMeta: tempPaginationMeta.value,
  }), ({
    currentCallTargetId: newCurrentCallTargetId,
    currentCallTargets: newCurrentCallTargets,
    paginationMeta: newPaginationMeta,
  }) => {
    isPaging.value = setParams(newCurrentCallTargetId, newPaginationMeta, newCurrentCallTargets);

    if (!isPaging.value) {
      // NOTE: デフォルト値で初期化する
      currentIndex.value = -1;
      isCurrentListFirst.value = false;
      isCurrentListLast.value = false;
      isFirstPage.value = false;
      isLastPage.value = false;
    }
  }, { immediate: true });

  return {
    isPaging,
    currentPage,
    currentIndex,
    isCurrentListFirst,
    isCurrentListLast,
    isFirstPage,
    isLastPage,
    requestParameters,
  };
};

const usePrevNext = (
  getLoading: () => boolean,
  getDisabled: () => boolean,
  getCurrentCallTargetId: () => number | null,
  getCurrentCallTargets: () => TCallTarget[], // TODO: TCallTarget をリードの型に置き換える
  getPaginationMeta: () => PaginationMeta | null,
) => {
  const { doActionWithWait } = useWait();

  const fetchedPrevCallTargetId = ref<number | null>(null);
  const fetchedNextCallTargetId = ref<number | null>(null);
  
  // TODO: 暫定的に、ユースケースに合わせて対象のリードが変更された時のみリードリストの最新を取得するように変更。リードリストの仕様が確定した時点で見直すこと
  const tempCurrentCallTargets = ref<TCallTarget[]>([]);
  const tempPaginationMeta = ref<PaginationMeta | null>(null);
  watch(() => ({
    currentTargetId: getCurrentCallTargetId(),
    currentPage: getPaginationMeta()?.currentPage,
    loading: getLoading(),
  }), (newValue, oldValue) => {
    if (newValue.currentTargetId !== oldValue?.currentTargetId || newValue.currentPage !== oldValue?.currentPage) {
      fetchedNextCallTargetId.value = null;
      fetchedPrevCallTargetId.value = null;
    }
    if (newValue.loading) return;

    tempCurrentCallTargets.value = getCurrentCallTargets();
    tempPaginationMeta.value = getPaginationMeta();
  }, { immediate: true });

  const {
    isPaging,
    currentPage,
    currentIndex,
    isCurrentListFirst,
    isCurrentListLast,
    isFirstPage,
    isLastPage,
    requestParameters,
  } = usePaging(getCurrentCallTargetId, tempCurrentCallTargets, tempPaginationMeta);

  const FETCH_PREV_CALL_TARGET_ID = 'getPreviousPageLastIdWait';
  const FETCH_NEXT_CALL_TARGET_ID = 'getNextPageFirstIdWait'; 

  const buttonsDisabled = computed(() => getLoading() || getDisabled() || !isPaging.value);
  const prevDisabled = computed(() => buttonsDisabled.value || isFirstPage.value && isCurrentListFirst.value);
  const nextDisabled = computed(() => buttonsDisabled.value || isLastPage.value && isCurrentListLast.value);

  // TODO: 暫定修正と合わせ、リードリストから現在のリードが消えた時、次のリードは「同ページのリストの中にある」はずなのでページ遷移しない
  const isLeadExistsInCurrentPage = computed(() => getCurrentCallTargets().some(ct => ct.callTargetId === getCurrentCallTargetId()));
  const isPrevMovesAcrossPages = computed(() => fetchedPrevCallTargetId.value !== null);
  const isNextMovesAcrossPages = computed(() => fetchedNextCallTargetId.value !== null && isLeadExistsInCurrentPage.value);

  const prevCallTargetId = computed(() => {
    if (prevDisabled.value) return null;
    if (fetchedPrevCallTargetId.value) return fetchedPrevCallTargetId.value;
    // TODO: 暫定的に、ユースケースに合わせて対象のリードが変更された時のみリードリストの最新を取得するように変更。リードリストの仕様が確定した時点で見直すこと
    return tempCurrentCallTargets.value?.[currentIndex.value - 1]?.callTargetId;
  });
  const nextCallTargetId = computed(() => {
    if (nextDisabled.value) return null;
    if (fetchedNextCallTargetId.value) return fetchedNextCallTargetId.value;
    // TODO: 暫定的に、ユースケースに合わせて対象のリードが変更された時のみリードリストの最新を取得するように変更。リードリストの仕様が確定した時点で見直すこと
    return tempCurrentCallTargets.value?.[currentIndex.value + 1]?.callTargetId;
  });

  const fetchPrevCallTargetId = async (currentPage: number) => {
    const api = new CallTargetApiService();
    const { data: { id } } = await doActionWithWait<AxiosResponse<GetNextPageFirstId200Response>>(FETCH_PREV_CALL_TARGET_ID, async () => {
      return api.getPreviousPageLastId({ request: { ...requestParameters.value, currentPage } });
    });

    return id;
  };
  const fetchNextCallTargetId = async (currentPage: number) => {
    const api = new CallTargetApiService();
    const { data: { id } } = await doActionWithWait<AxiosResponse<GetNextPageFirstId200Response>>(FETCH_NEXT_CALL_TARGET_ID, async () => {
      return api.getNextPageFirstId({ request: { ...requestParameters.value, currentPage } });
    });

    return id;
  };

  watch(() => ({
    prevDisabled: prevDisabled.value,
    isCurrentListFirst: isCurrentListFirst.value,
    currentPage: currentPage.value,
  }), async (newValue) => {
    // TODO: 暫定制御
    if (fetchedPrevCallTargetId.value !== null) return;
    const { prevDisabled: newPrevDisabled, isCurrentListFirst: newIsCurrentListFirst, currentPage: newCurrentPage } = newValue;

    // 前のページから前のIDを取得
    if (newPrevDisabled) return;
    if (newIsCurrentListFirst) {
      fetchedPrevCallTargetId.value = await fetchPrevCallTargetId(newCurrentPage);
    } else {
      fetchedPrevCallTargetId.value = null;
    }
  }, { immediate: true });

  watch(() => ({
    nextDisabled: nextDisabled.value,
    isCurrentListLast: isCurrentListLast.value,
    currentPage: currentPage.value,
  }), async (newValue) => {
    // TODO: 暫定制御
    if (fetchedNextCallTargetId.value !== null) return;
    const { nextDisabled: newNextDisabled, isCurrentListLast: newIsCurrentListLast, currentPage: newCurrentPage } = newValue;

    // 次のページから次のIDを取得
    if (newNextDisabled) return;
    if (newIsCurrentListLast) {
      fetchedNextCallTargetId.value = await fetchNextCallTargetId(newCurrentPage);
    } else {
      fetchedNextCallTargetId.value = null;
    }
  }, { immediate: true });

  return {
    currentPage,
    prevDisabled,
    nextDisabled,
    isPrevMovesAcrossPages,
    isNextMovesAcrossPages,
    prevCallTargetId,
    nextCallTargetId,
  };
};

export type {
  TCallTarget,
};
export {
  usePrevNext,
};
