<template>
  <div
    class="priority-preview"
    :class="[size]"
  >
    <template v-if="priority != null">
      <BPriorityColorPreview
        :size="size"
        :priority="priority"
      />
      <span
        class="priority-name"
        :class="{'truncate-text': truncateText}"
      >{{ priorityName }}</span>
    </template>
    <span
      v-else
      class="placeholder"
      :class="{'truncate-text': truncateText}"
    >-</span>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { LeadStage, PriorityPriorityEnum } from '@/api/openapi';
import BPriorityColorPreview from './BPriorityColorPreview.vue';

export type TDisplayLeadStage = Pick<LeadStage, 'name' | 'colorName'>;
type TProps = {
  priority?: PriorityPriorityEnum;
  truncateText?: boolean;
  size?: 'micro' | 'small';
};

const props = withDefaults(defineProps<TProps>(), {
  priority: null,
  truncateText: true,
  size: 'small',
});

const i18n = useI18n();

const priorityName = computed(() => i18n.t(`general.priority.${props.priority}`));
</script>

<style lang="scss" scoped>
.priority-preview {
  display: flex;
  align-items: center;

  &.micro {
    font-size: $fontsize-100;
    line-height: $fontsize-100;
  }

  .color-preview {
    margin-right: 8px;
    &.micro {
      margin-right: 4px;
    }
  }
  .priority-name {
    flex: 1;
  }
  .placeholder {
    color: $concrete-dark;
  }
}
</style>
