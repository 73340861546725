<template>
  <ElPagination
    v-bind="attributes"
    v-model:current-page="internalValue"
  >
    <slot />
  </ElPagination>
</template>

<script>
export default {
  props: {
    modelValue: Number,
    length: Number,
    totalVisible: {
      type: [Number, String],
      default: 5,
    },
  },
  emits: [
    'update:modelValue',
  ],
  computed: {
    internalValue: {
      get() {
        return this.modelValue;
      },
      set(newVal) {
        if (this.modelValue !== newVal) this.$emit('update:modelValue', newVal);
      },
    },
    attributes () {
      return {
        'page-count': this.length,
        'pager-count': this.totalVisible,
        'background': true,
        'layout': 'prev, pager, next',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
  .el-pagination {
    --el-pagination-button-height: 28px;

    :deep(button) {
      min-width: 30px !important;
    }
  }
  :deep(.el-pager) {
    padding-left: 0;

    .is-active {
      font-weight: initial !important;
    }
  }
  :deep(.el-pager li) {
    transition: all 200ms $standard-easing;
    font-size: 1rem;
    font-weight: initial;
    min-width: 30px !important;
  }
</style>
