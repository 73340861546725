<template>
  <BLayout
    v-if="selectedSequenceInstanceIds.length > 0"
    align-center
    justify-center
    class="bulk-actions-footer"
  >
    <div class="mr-300">
      {{ $t('general.bulk.selected') }}<span class="fs-500 fw-bold mr-100 ml-100">{{ selectedSequenceInstanceIds.length }}</span>{{ $t('general.bulk.numberOf') }}
    </div>
    <BSelect
      v-model="selectedActionId"
      class="mr-400 bulk-action-select"
      :items="ACTIONS"
      item-text="name"
      item-value="id"
      :placeholder="$t('general.select.please')"
      filterable
    />
    <BBtn
      type="primary"
      :loading="loading"
      :disabled="bulkDisabled || loading"
      @click="handleDoBulkClick"
    >
      {{ $t('general.bulk.do') }}
    </BBtn>
  </BLayout>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { SequenceInstanceApiService } from '@/api/user/resources/sequence_instance';
import { useWait } from '@/composable/vue-wait';
import { bitterAlert } from '@/plugins/BBitterAlert';
import { TSequenceAction } from '@/types';

type TProps = {
  selectedSequenceInstanceIds: number[];
};
type TEmit = (event: 'afterBulkAction') => void;

const props = defineProps<TProps>();
const emit = defineEmits<TEmit>();

const i18n = useI18n();
const {
  wait,
  doActionWithWait,
} = useWait();
const api = new SequenceInstanceApiService();

const ACTIONS: { key: string, options: { id: TSequenceAction, name: string }[] }[] = [
  { key: 'section1', options: [
    { id: 'restart', name: i18n.t('sequence.instance.restart') },
    { id: 'stop', name: i18n.t('sequence.instance.stop') },
  ] },
  { key: 'section2', options: [
    { id: 'unregister', name: i18n.t('sequence.instance.unregister') },
  ] },
];

const selectedActionId = ref<TSequenceAction | null>(null);

const loading = computed<boolean>(() =>
  wait.is(['restartSequenceInstances', 'unregisterSequenceInstances', 'stopSequenceInstances']),
);
const bulkDisabled = computed<boolean>(() =>
  !selectedActionId.value || props.selectedSequenceInstanceIds.length === 0,
);

const handleDoBulkClick = async () => {
  const ok = await bitterAlert.show({
    title: i18n.t(`sequence.instance.${selectedActionId.value}`),
    text: i18n.t(`sequence.instance.bulkConfirmMessage.${selectedActionId.value}`),
  });
  if (!ok) return;

  if (selectedActionId.value === 'restart') {
    await restartSequenceInstances();
  } else if (selectedActionId.value === 'unregister') {
    await unregisterSequenceInstances();
  } else if (selectedActionId.value === 'stop') {
    await stopSequenceInstances();
  }

  emit('afterBulkAction');
};

const restartSequenceInstances = async () => {
  const { selectedSequenceInstanceIds: sequenceInstanceIds } = props;
  
  await doActionWithWait('restartSequenceInstances', async () => {
    await api.restartSequenceInstances({
      request: {
        bulkUpdateSequenceInstancesStatusBody: { sequenceInstanceIds },
      },
    });
  });
};
const unregisterSequenceInstances = async () => {
  const { selectedSequenceInstanceIds: sequenceInstanceIds } = props;
  
  await doActionWithWait('unregisterSequenceInstances', async () => {
    await api.unregisterSequenceInstances({
      request: {
        bulkUpdateSequenceInstancesStatusBody: { sequenceInstanceIds },
      },
    });
  });
};
const stopSequenceInstances = async () => {
  const { selectedSequenceInstanceIds: sequenceInstanceIds } = props;
  
  await doActionWithWait('stopSequenceInstances', async () => {
    await api.stopSequenceInstances({
      request: {
        bulkUpdateSequenceInstancesStatusBody: { sequenceInstanceIds },
      },
    });
  });
};
</script>

<style lang="scss" scoped>
.bulk-actions-footer {
  background-color: $bgcolor-white;
  box-shadow: $boxshadow-footer;
  padding: $basespace-300 0;

  .bulk-action-select {
    width: 165px;
    height: 42px;
  }
}
</style>
