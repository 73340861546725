type TBase64 = string;

const loadImage = (src: string): Promise<HTMLImageElement> => 
  new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = 'anonymous';
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (e) => reject(e));
    image.src = src;
  });

const cropImageToSquare = (image: HTMLImageElement): TBase64 => {
  const minimumLength = Math.min(image.width, image.height);
  const clipX = (image.width - minimumLength) * 0.5;
  const clipY = (image.height - minimumLength) * 0.5;

  return cropImage(image, clipX, clipY, minimumLength, minimumLength);
};

const resizeImage = (image: HTMLImageElement, maxLength: number): TBase64 => {
  const { canvas, ctx } = getCanvasContext();

  let dstWidth;
  let dstHeight;
  // 縦横比の計算
  if (image.width > image.height) {
    dstWidth = maxLength;
    dstHeight = image.height * maxLength / image.width;
  } else {
    dstHeight = maxLength;
    dstWidth = image.width * maxLength / image.height;
  }
  canvas.width = dstWidth;
  canvas.height = dstHeight;

  ctx.drawImage(
    image,
    0, 0, image.width, image.height,
    0, 0, dstWidth, dstHeight,
  );

  return canvas.toDataURL();
};

const convertBase64ToBlob = (base64: TBase64): Blob | null => {
  const bin = Buffer.from(base64.replace(/^.*,/, ''), 'base64');
  const buffer = new Uint8Array(bin.length);
  bin.forEach((e, i) => buffer[i] = e);

  try {
    const blob = new Blob([buffer.buffer], {
      type: 'image/png',
    });
    return blob;
  } catch (e) {
    return null;
  }
};

const convertBlobToUrl = (blob: Blob): string => URL.createObjectURL(blob);

const convertFileToBlob = (file: File): Blob => new Blob([file], { type: file.type });

const convertBlobToFile = (blob: Blob): File => new File([blob], 'sample.txt', { type: blob.type });


// --- private ----

const getCanvasContext = () => {
  const canvas = document.createElement('canvas');
  if (canvas.getContext === null) throw new Error('Failed to get canvas context');

  const ctx = canvas.getContext('2d');
  return {
    canvas,
    ctx,
  };
};

const cropImage = (image: HTMLImageElement, clipX: number, clipY: number, clipWidth: number, clipHeight: number): TBase64 => {
  const { canvas, ctx } = getCanvasContext();
  canvas.width = clipWidth;
  canvas.height = clipHeight;
  ctx.drawImage(
    image,
    clipX, clipY, clipWidth, clipHeight,
    0, 0, clipWidth, clipHeight,
  );

  return canvas.toDataURL();
};

export {
  loadImage,
  resizeImage,
  cropImageToSquare,
  convertBase64ToBlob,
  convertBlobToUrl,
  convertBlobToFile,
  convertFileToBlob,
};
