import { PriorityPriorityEnum } from '@/api/openapi';
import COLOR_CODES from '@/const/color_codes';
import { TColor } from '../../types';

const colors: TColor<PriorityPriorityEnum>[] = [
  { value: 'high', colorCode: COLOR_CODES.SUN },
  { value: 'middle', colorCode: COLOR_CODES.URANUS },
  { value: 'low', colorCode: COLOR_CODES.MOON },
];

export default colors;
