<template>
  <div class="sequence-apply-modal">
    <BModal
      :modal-name="modalName"
      width="1480px"
      height="880px"
      :delegate="bModalDelegate"
    >
      <BHeightAdjuster>
        <template v-if="step === 'select'">
          <BModalHeader
            :title="$t('sequence.title')"
            @modal-close="handleCloseClick"
          />
          <BModalBody full>
            <SequenceMasterSelect
              @select-sequence-master="handleSelectSequence"
            />
          </BModalBody>
        </template>
        <template v-if="step === 'apply'">
          <SequenceApply
            :call-target-ids="[callTargetId]"
            :sequence-master-id="selectedSequenceId"
            @click-close="handleCloseClick"
            @after-apply="handleAfterApply"
            @back-to-sequence-list="handleBackToSequenceList"
          />
        </template>
      </BHeightAdjuster>
    </BModal>
  </div>
</template>

<script lang="ts" setup>
import { inject, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { FailedCallTarget } from '@/api/openapi';
import { KEY_OF_TOAST } from '@/injection-keys';
import { bitterAlert } from '@/plugins/BBitterAlert';
import { TBmodalDelegate } from '@/plugins/BModal/BModal.vue';
import SequenceApply from '../../general/sequence/SequenceApply.vue';
import SequenceMasterSelect from './sequenceApply/SequenceMasterSelect.vue';

type TProps = {
  modalName: string;
  callTargetId: number;
};
type TStep = 'select' | 'apply';

defineProps<TProps>();
const emit = defineEmits(['close', 'afterApply']);

const i18n = useI18n();
const toast = inject(KEY_OF_TOAST);

const step = ref<TStep>('select');
const selectedSequenceId = ref<number>();

const beforeClose = () => {
  step.value = 'select';
};
const shouldClose = async (): Promise<boolean> => {
  return step.value == 'select' || await bitterAlert.show({
    text: i18n.t('sequence.leadDetail.confirmToCloseModal'),
  });
};
const bModalDelegate = ref<TBmodalDelegate>({
  shouldClose,
  beforeClose,
});

const handleCloseClick = async () => {
  await shouldClose() && closeModal();
};
const handleSelectSequence = (sequenceId: number) => {
  selectedSequenceId.value = sequenceId;
  step.value = 'apply';
};
const handleBackToSequenceList = () => {
  step.value = 'select';
};
const handleAfterApply = (failedCallTargets: FailedCallTarget[]) => {
  if (failedCallTargets.length) {
    bitterAlert.show({
      title: i18n.t('sequence.message.sequenceRegistrationFailure'),
      text: failedCallTargets.map(e => e.errorMessages).flat().join('\n'),
      buttonsCancel: false,
    });
    return;
  }
  toast.show(i18n.t('general.done.of', { action: i18n.t('sequence.applySequenceToLead') }));

  closeModal();
  emit('afterApply');
};

const closeModal = () => {
  emit('close');
};
</script>
