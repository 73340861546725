import { AxiosError } from 'axios';
import swal from 'sweetalert';
import { useRouter } from 'vue-router';
import { i18nGlobal as i18n } from '@/i18n';

/**
 * MEMO: サーバー側のエラーをそのままswalする
 */
const defaultHandler = (e: AxiosError) => {
  if (e.response?.data?.errorMessage) {
    swal(e.response.data.errorMessage);
  } else {
    swal(`422 Unprocessable Entity\n${i18n.t('apiError.unprocessableEntity')}`);
  }
};
const fileLengthErrorHandler = () => {
  swal({
    title: i18n.t('general.error'),
    text: i18n.t('apiError.message.overFileLengthError', { length: i18n.t('general.maxFileLength') }),
  });
};
const fileUploadErrorHandler = () => {
  swal({
    title: i18n.t('general.error'),
    text: i18n.t('apiError.message.fileUploadError'),
  });
};
const useErrorHandler = () => {
  const router = useRouter();

  const redirectToNotFoundPage = () => {
    router.push({ name: 'Error' });
  };

  return {
    defaultHandler,
    fileLengthErrorHandler,
    fileUploadErrorHandler,
    redirectToNotFoundPage,
  };
};

export {
  useErrorHandler,
};
