/* tslint:disable */
/* eslint-disable */
/**
 * openapi
 * bales cloud
 *
 * The version of the OpenAPI document: 1.0
 * Contact: test@example.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CustomActionOption } from '../models';
// @ts-ignore
import { PostCustomActionOptionBody } from '../models';
// @ts-ignore
import { PutCustomActionOptionBody } from '../models';
/**
 * CustomActionOptionApi - axios parameter creator
 * @export
 */
export const CustomActionOptionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * アクション項目選択肢削除API
         * @summary アクション項目選択肢削除API
         * @param {number} customActionOptionId アクション項目選択肢ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomActionOption: async (customActionOptionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customActionOptionId' is not null or undefined
            assertParamExists('deleteCustomActionOption', 'customActionOptionId', customActionOptionId)
            const localVarPath = `/api/user/custom_action_options/{customActionOptionId}`
                .replace(`{${"customActionOptionId"}}`, encodeURIComponent(String(customActionOptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アクション項目選択肢作成API
         * @summary アクション項目選択肢作成API
         * @param {number} customActionId アクション項目ID
         * @param {PostCustomActionOptionBody} [postCustomActionOptionBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCustomActionOption: async (customActionId: number, postCustomActionOptionBody?: PostCustomActionOptionBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customActionId' is not null or undefined
            assertParamExists('postCustomActionOption', 'customActionId', customActionId)
            const localVarPath = `/api/user/custom_actions/{customActionId}/custom_action_options`
                .replace(`{${"customActionId"}}`, encodeURIComponent(String(customActionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postCustomActionOptionBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アクション項目選択肢更新API
         * @summary アクション項目選択肢更新API
         * @param {number} customActionOptionId アクション項目選択肢ID
         * @param {PutCustomActionOptionBody} [putCustomActionOptionBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCustomActionOption: async (customActionOptionId: number, putCustomActionOptionBody?: PutCustomActionOptionBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customActionOptionId' is not null or undefined
            assertParamExists('putCustomActionOption', 'customActionOptionId', customActionOptionId)
            const localVarPath = `/api/user/custom_action_options/{customActionOptionId}`
                .replace(`{${"customActionOptionId"}}`, encodeURIComponent(String(customActionOptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putCustomActionOptionBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomActionOptionApi - functional programming interface
 * @export
 */
export const CustomActionOptionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomActionOptionApiAxiosParamCreator(configuration)
    return {
        /**
         * アクション項目選択肢削除API
         * @summary アクション項目選択肢削除API
         * @param {number} customActionOptionId アクション項目選択肢ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCustomActionOption(customActionOptionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCustomActionOption(customActionOptionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アクション項目選択肢作成API
         * @summary アクション項目選択肢作成API
         * @param {number} customActionId アクション項目ID
         * @param {PostCustomActionOptionBody} [postCustomActionOptionBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCustomActionOption(customActionId: number, postCustomActionOptionBody?: PostCustomActionOptionBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomActionOption>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCustomActionOption(customActionId, postCustomActionOptionBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アクション項目選択肢更新API
         * @summary アクション項目選択肢更新API
         * @param {number} customActionOptionId アクション項目選択肢ID
         * @param {PutCustomActionOptionBody} [putCustomActionOptionBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCustomActionOption(customActionOptionId: number, putCustomActionOptionBody?: PutCustomActionOptionBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCustomActionOption(customActionOptionId, putCustomActionOptionBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomActionOptionApi - factory interface
 * @export
 */
export const CustomActionOptionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomActionOptionApiFp(configuration)
    return {
        /**
         * アクション項目選択肢削除API
         * @summary アクション項目選択肢削除API
         * @param {number} customActionOptionId アクション項目選択肢ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomActionOption(customActionOptionId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCustomActionOption(customActionOptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * アクション項目選択肢作成API
         * @summary アクション項目選択肢作成API
         * @param {number} customActionId アクション項目ID
         * @param {PostCustomActionOptionBody} [postCustomActionOptionBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCustomActionOption(customActionId: number, postCustomActionOptionBody?: PostCustomActionOptionBody, options?: any): AxiosPromise<CustomActionOption> {
            return localVarFp.postCustomActionOption(customActionId, postCustomActionOptionBody, options).then((request) => request(axios, basePath));
        },
        /**
         * アクション項目選択肢更新API
         * @summary アクション項目選択肢更新API
         * @param {number} customActionOptionId アクション項目選択肢ID
         * @param {PutCustomActionOptionBody} [putCustomActionOptionBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCustomActionOption(customActionOptionId: number, putCustomActionOptionBody?: PutCustomActionOptionBody, options?: any): AxiosPromise<void> {
            return localVarFp.putCustomActionOption(customActionOptionId, putCustomActionOptionBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomActionOptionApi - interface
 * @export
 * @interface CustomActionOptionApi
 */
export interface CustomActionOptionApiInterface {
    /**
     * アクション項目選択肢削除API
     * @summary アクション項目選択肢削除API
     * @param {number} customActionOptionId アクション項目選択肢ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomActionOptionApiInterface
     */
    deleteCustomActionOption(customActionOptionId: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * アクション項目選択肢作成API
     * @summary アクション項目選択肢作成API
     * @param {number} customActionId アクション項目ID
     * @param {PostCustomActionOptionBody} [postCustomActionOptionBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomActionOptionApiInterface
     */
    postCustomActionOption(customActionId: number, postCustomActionOptionBody?: PostCustomActionOptionBody, options?: AxiosRequestConfig): AxiosPromise<CustomActionOption>;

    /**
     * アクション項目選択肢更新API
     * @summary アクション項目選択肢更新API
     * @param {number} customActionOptionId アクション項目選択肢ID
     * @param {PutCustomActionOptionBody} [putCustomActionOptionBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomActionOptionApiInterface
     */
    putCustomActionOption(customActionOptionId: number, putCustomActionOptionBody?: PutCustomActionOptionBody, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * Request parameters for deleteCustomActionOption operation in CustomActionOptionApi.
 * @export
 * @interface CustomActionOptionApiDeleteCustomActionOptionRequest
 */
export interface CustomActionOptionApiDeleteCustomActionOptionRequest {
    /**
     * アクション項目選択肢ID
     * @type {number}
     * @memberof CustomActionOptionApiDeleteCustomActionOption
     */
    readonly customActionOptionId: number
}

/**
 * Request parameters for postCustomActionOption operation in CustomActionOptionApi.
 * @export
 * @interface CustomActionOptionApiPostCustomActionOptionRequest
 */
export interface CustomActionOptionApiPostCustomActionOptionRequest {
    /**
     * アクション項目ID
     * @type {number}
     * @memberof CustomActionOptionApiPostCustomActionOption
     */
    readonly customActionId: number

    /**
     * 
     * @type {PostCustomActionOptionBody}
     * @memberof CustomActionOptionApiPostCustomActionOption
     */
    readonly postCustomActionOptionBody?: PostCustomActionOptionBody
}

/**
 * Request parameters for putCustomActionOption operation in CustomActionOptionApi.
 * @export
 * @interface CustomActionOptionApiPutCustomActionOptionRequest
 */
export interface CustomActionOptionApiPutCustomActionOptionRequest {
    /**
     * アクション項目選択肢ID
     * @type {number}
     * @memberof CustomActionOptionApiPutCustomActionOption
     */
    readonly customActionOptionId: number

    /**
     * 
     * @type {PutCustomActionOptionBody}
     * @memberof CustomActionOptionApiPutCustomActionOption
     */
    readonly putCustomActionOptionBody?: PutCustomActionOptionBody
}

/**
 * CustomActionOptionApi - object-oriented interface
 * @export
 * @class CustomActionOptionApi
 * @extends {BaseAPI}
 */
export class CustomActionOptionApi extends BaseAPI implements CustomActionOptionApiInterface {
    /**
     * アクション項目選択肢削除API
     * @summary アクション項目選択肢削除API
     * @param {CustomActionOptionApiDeleteCustomActionOptionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomActionOptionApi
     */
    public deleteCustomActionOption(requestParameters: CustomActionOptionApiDeleteCustomActionOptionRequest, options?: AxiosRequestConfig) {
        return CustomActionOptionApiFp(this.configuration).deleteCustomActionOption(requestParameters.customActionOptionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アクション項目選択肢作成API
     * @summary アクション項目選択肢作成API
     * @param {CustomActionOptionApiPostCustomActionOptionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomActionOptionApi
     */
    public postCustomActionOption(requestParameters: CustomActionOptionApiPostCustomActionOptionRequest, options?: AxiosRequestConfig) {
        return CustomActionOptionApiFp(this.configuration).postCustomActionOption(requestParameters.customActionId, requestParameters.postCustomActionOptionBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アクション項目選択肢更新API
     * @summary アクション項目選択肢更新API
     * @param {CustomActionOptionApiPutCustomActionOptionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomActionOptionApi
     */
    public putCustomActionOption(requestParameters: CustomActionOptionApiPutCustomActionOptionRequest, options?: AxiosRequestConfig) {
        return CustomActionOptionApiFp(this.configuration).putCustomActionOption(requestParameters.customActionOptionId, requestParameters.putCustomActionOptionBody, options).then((request) => request(this.axios, this.basePath));
    }
}
