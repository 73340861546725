<template>
  <div class="lead-history-card">
    <div class="lead-history-owner">
      <BUserTooltip
        v-if="isExistUser"
        :image-url="user.avatarUrl"
        :name="user.name"
        :user-id="user.id"
        :size="40"
      />
      <div
        v-else-if="isDisplaySystemUser"
        class="system-user"
      >
        <img
          class="system-user-img"
          :src="iconSrc"
        >
      </div>
      <div
        v-else-if="isEvent"
        class="event-reaction"
      >
        <img
          class="event-reaction-img"
          :src="iconSrc"
        >
      </div>
      <div
        v-else-if="isBoxilEvent"
        class="boxil-event"
      >
        <img
          class="boxil-event-img"
          :src="boxilIconSrc"
        >
      </div>
    </div>
    <div class="lead-history-card-content">
      <div
        class="lead-history-card-header"
        :class="classes"
        @click="internalDrawer = !internalDrawer"
      >
        <div
          class="lead-history-card-icon"
          :class="iconClass"
        >
          <template v-if="!isEvent || !isBoxilEvent">
            <BTooltip
              top
              :content="iconTooltip"
              :disabled="!iconTooltip"
            >
              <Component
                :is="iconComponent"
                class="icon"
                :size="iconSize"
                :icon-class="customIconClass"
                :icon="icon"
              >
                {{ icon }}
              </Component>
            </BTooltip>
          </template>
          <slot
            v-else
            name="icon"
          />
        </div>
        <div class="lead-history-card-info">
          <div class="info-text">
            {{ text }}
            <BTooltip
              top
              :content="status.name"
            >
              <BIcon
                v-if="hasStatus"
                class="status-icon"
                size="mini"
                :type="status.type"
              >
                {{ status.icon }}
              </BIcon>
            </BTooltip>
          </div>
          <div class="info-date">
            <slot name="date" />
          </div>
        </div>
        <div class="lead-history-card-sub ml-a">
          <slot name="action" />
        </div>
        <BIcon
          v-if="toggle"
          class="ml-300"
          :class="{ 'is-open': internalDrawer }"
        >
          arrow_drop_up
        </BIcon>
      </div>
      <div
        v-if="!canNotExpand"
        class="lead-history-expand-indicator"
      />
      <div
        v-show="internalDrawer"
        class="lead-history-card-body"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    iconComponent: {
      type: String,
      default: 'bIcon',
    },
    customIconClass: {
      type: String,
      default: '',
    },
    iconSize: {
      type: String,
      default: 'medium',
    },
    text: String,
    icon: String,
    iconTooltip: {
      type: String,
      default: undefined,
    },
    iconType: {
      type: String,
      default: 'default',
    },
    user: {
      type: Object,
      default () {
        return {};
      },
    },
    toggle: {
      type: Boolean,
      default: true,
    },
    expand: {
      type: Boolean,
      default: false,
    },
    canNotExpand: {
      type: Boolean,
      default: false,
    },
    isEvent: {
      type: Boolean,
      default: false,
    },
    isBoxilEvent: {
      type: Boolean,
      default: false,
    },
    status: {
      type: Object,
      default () {
        return {};
      },
    },
    eventEmotion: {
      type: String,
      required: false,
      default: null,
    },
    isDisplaySystemUser: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      drawer: false,
    };
  },
  computed: {
    classes () {
      return {
        'is-toggle': this.toggle,
      };
    },
    iconClass () {
      return `icon-${this.iconType}`;
    },
    internalDrawer: {
      get () {
        if (!this.toggle) return false;
        return this.drawer;
      },
      set (newVal) {
        this.drawer = newVal;
      },
    },
    hasStatus () {
      return Object.keys(this.status).length > 0;
    },
    isExistUser () {
      return !!this.user && Object.keys(this.user).length > 0;
    },
    iconSrc () {
      if (this.eventEmotion === 'positive') return new URL('@/assets/positive_reaction.svg', import.meta.url).href;
      if (this.eventEmotion === 'negative') return new URL('@/assets/negative_reaction.svg', import.meta.url).href;
      return new URL('@/assets/positive_reaction.svg', import.meta.url).href;
    },
    boxilIconSrc () {
      return new URL('@/assets/boxil-saas-logo.svg', import.meta.url).href;
    },
  },
  created () {
    // 20200202 MEMO: ヒストリーをお試しですべてopenにする
    // if (this.expand) this.internalDrawer = true
    this.internalDrawer = true;
  },
};
</script>

<style lang="scss" scoped>
  $lead-history-owner-width: 42.5px;
  $lead-history-owner-margin-right: 10px;
  .lead-history-card {
    display: flex;
    margin-bottom: 20px;

    &-content {
      width: calc(100% - ($lead-history-owner-width + $lead-history-owner-margin-right));
    }

    &-sub {
      display: flex;
      align-items: center;
    }
  }

  .lead-history-card-info {
    .info-text {
      margin-right: 12px;
      font-weight: 600;
    }

    .info-date {
      font-size: $fontsize-100;
      color: $textcolor-light;
    }
  }

  .lead-history-card-header {
    display: flex;
    align-items: center;
    padding: $basespace-100;
    transition: $transition-base;
    border-radius: 6px;

    &.is-toggle {
      cursor: pointer;

      &:hover {
        background-color: $bgcolor-dark;
      }
    }

    .is-open {
      transform: rotate(180deg);
    }
  }

  .lead-history-card-body {
    background-color: $bgcolor-white;
    border-radius: 6px;
    padding: $basespace-400;
  }

  .lead-history-card-icon {
    $size: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include m-fixed-width($size);
    @include m-fixed-height($size);
    border-radius: 4px;
    margin: 7px 15px 7px 7px;

    .icon {
      color: $textcolor-white;
    }

    &.icon-default {
      background-color: $bgcolor-base;

      .icon {
        color: $textcolor-base;
      }
    }

    &.icon-hot {
      background-color: $basecolor-hot;
    }

    &.icon-cold {
      background-color: $basecolor-cold;
    }

    &.icon-success {
      background-color: $basecolor-success;
    }

    &.icon-emphasis {
      background: $bgcolor-aurora;
    }

    &.icon-boxil {
      background-color: #30B0B0;
    }
  }

  .lead-history-card-content {
    background-color: white;
  }

  .lead-history-expand-indicator {
    width: 96.5%;
    height: 10px;
    margin: 0 auto;
    border-top: 1px solid $bdcolor-light;
  }

  .lead-history-owner {
    margin-top: 11px;
    margin-right: 10px;
    width: 42.5px;
    height: 42.5px;
  }

  .system-user {
    position: relative;
    width: 40px;
    height: 40px;
    .system-user-img {
      position: absolute;
      width: 60px;
      height: 60px;
      top: 0;
      margin-left: -10px;
      padding: 0;
    }
  }

  .event-reaction {
    position: relative;
    width: 40px;
    height: 40px;
    .event-reaction-img {
      position: absolute;
      width: 60px;
      height: 60px;
      top: 0;
      margin-left: -10px;
      padding: 0;
    }
  }

  .boxil-event {
    position: relative;
    width: 40px;
    height: 40px;
    .boxil-event-img {
      position: absolute;
      width: 40px;
      height: 40px;
      top: 0;
      padding: 0;
    }
  }

  .status-icon {
    margin-left: 5px;
    margin-bottom: 3px;
  }
</style>
