/* global FS */
import AuthService from '@/services/auth_service';
import Waiting from '@/store/waiting';

export default class CurrentUserActions {
  constructor(Api, types) {
    const actions = {
      async getCurrentUserAction({ commit }, args) {
        await waiting.check('getCurrentUserAction', async () => {
          try {
            const res = await Api.getCurrentUser(args);
            new AuthService().changeRole(res.data.currentUser.role);
            commit(types.SET_CURRENT_USER, Object.values(res.data)[0]);
          } catch (err) {
            commit(types.CLEAR_CURRENT_USER);
            throw err;
          }
        });
      },
    };
    const waiting = new Waiting(actions);
    this.actions = actions;
  }
}
